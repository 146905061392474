import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { Card, FormControlLabel, Grid, Icon, ListItemText, Radio, RadioGroup, Tooltip, tooltipClasses } from '@mui/material';
import { Formik, Form, ErrorMessage } from 'formik';

import SoftAvatar from 'components/SoftAvatar';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import { useCardroomContext } from 'features';

import playerAvatar from "assets/graphics/personas/player_256.png";
import DataBoundList from 'components/Elements/DataBound/List';
import PlayerRebateSelector from '../../RebateFees/Selector';
import AgentSelector from '../../Agent/Selector';



const AgencyPlayerClubList = ({ cardroom, agency, player, onSelectionChanged }) => {

    const [clubs, setClubs] = useState(player.clubs ? player.clubs : null);
    const [configurations, setConfigurations] = useState(null);

    const [selection, setSelection] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);


    function onNewSelection(item) {

        if (item) {
            setSelection(item.item);
            setSelectedIndex(item.index);
            if (onSelectionChanged) {
                onSelectionChanged(item.item, item.index);
            }
        }
        else {
            setSelectedIndex(-1);
        }
    }

    const [context, actions, features] = useCardroomContext(cardroom);

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "loading agency player clubs...", handleRetry)

    useEffect(() => {
        if (cardroom && agency && player && !clubs) { // || cardroomListData.length === 0) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("loading agency player clubs...")
            showProgress();
            // [HttpGet("{agencyId:Guid}/casino/{casinoId:Guid}/players/{playerId:Guid}/clubs", Name = "GetAgencyPlayerClubs")]
            execute("GET", apiConfig.club.endpoint + "/agency/" + agency.id + "/casino/" + cardroom.id + "/players/" + player.id + "/clubs").then((response) => {
                if (response) {

                    player.clubs = response;
                    setClubs(response);
                }
                //progressIndicatorProps.close();
            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load agency player clubs please try again...")
            });
        }

    }, [execute, cardroom, agency, player, clubs, retryCounter]);

    useEffect(() => {
        if (clubs && !configurations) {
            progressIndicatorProps.setMessage("loading agency player rebate config...")
            showProgress();
            //  [HttpGet("{agencyId:Guid}/casino/{casinoId:Guid}/players/{playerId:Guid}/schedules/rebate", Name = "GetAgencyPlayerRebateConfig")]
            execute("GET", apiConfig.club.endpoint + "/agency/" + agency.id + "/casino/" + cardroom.id + "/players/" + player.id + "/schedules/rebate").then((response) => {
                if (response) {
                    setConfigurations(response);
                }
                //progressIndicatorProps.close();
            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load agency player rebate config please try again...")
            });
        }
    }, [execute, clubs, configurations, retryCounter]);

    useEffect(() => {
        if (clubs && configurations) {
            //mrege config into clubs or vice versa
            const clubMap = [];

            for (var i = 0; i < clubs.length; i++) {
                if (!clubMap[clubs[i].id]) {
                    clubMap[clubs[i].id] = clubs[i];

                    clubs[i].rebateConfig = {
                        clubId: clubs[i].id,
                        playerId: player.id,
                        agencyId: agency.id,
                        schedule: null,
                        club: clubs[i]
                    };
                }
            }

            for (var i = 0; i < configurations.length; i++) {
                var c = clubMap[configurations[i].clubId];
                //alert(c);
                if (c) {
                    c.rebateConfig = configurations[i];
                    c.rebateConfig.club = c;
                }
            }

            progressIndicatorProps.close();
        }
    }, [clubs, configurations]);


    function buildListItem(item) {
        if (item.id) {
            return <ListItemText>
                <SoftTypography>
                    <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize">
                        <Icon color={item.rebateConfig && item.rebateConfig.schedule ? "success" : "warning"}>{item.rebateConfig && item.rebateConfig.schedule ? "check" : "warning_amber"}</Icon> {item.name}
                    </SoftTypography>
                </SoftTypography></ListItemText >;
        }
    }

    return (

        <SoftBox
            component="ul"
            display="flex"
            flexDirection="column"
            m={0} p={0}
            sx={{ listStyle: "none" }}>
            <SoftTypography
                component="label"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize">
                Player Club(s)</SoftTypography>
            <ProgressIndicator  {...progressIndicatorProps} />
            {!progressIndicatorProps.visible && clubs ?
                <DataBoundList data={clubs} valueName="id" textName="displayName" onChange={onNewSelection} selectedIndex={selectedIndex} onBuildListItem={buildListItem} maxHeight="375px" />
                : null}
        </SoftBox>

    );
};

const AgencyPlayerRebateConfigEditorForm = ({ cardroom, agency, club, player, config, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { playerId, clubId, rebateSchedule, rebateScheduleName } = formField;
    const { playerId: playerIdV,
        clubId: clubIdV,
        rebateSchedule: rebateScheduleV,
        rebateScheduleName: rebateScheduleNameV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditPlayer() {
        if (!context) return false;

        return true;
    }


    function onPlayerRebateSchedChanged(schedule) {
        setFieldValue(rebateSchedule.name, schedule && schedule.id ? schedule.id : "");
    }

    useEffect(() => {
        if (!formDataInitialized && config) {

            setFieldValue(playerId.name, config ? config.playerId : player ? player.id : "");

            setFieldValue(clubId.name, config ? config.clubId : club ? club.id : "");

            setFieldValue(rebateSchedule.name, config.schedule ? config.schedule.id : "none");

            setFieldValue(rebateScheduleName.name, config.schedule ? config.schedule.name : "[None]");

            setFormDataInitialized(true);
        }
    }, [formDataInitialized, config]);

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} justifyItems={"center"}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {clubId.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    {config && config.club ? config.club.name : null}
                                </SoftTypography>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    color="error">
                                    <ErrorMessage name={clubId.name} />
                                </SoftTypography>
                            </Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {rebateSchedule.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>

                            <Grid item xs={8}>
                                <PlayerRebateSelector cardroom={cardroom} agency={agency} value={rebateScheduleV} readonly={!canEditPlayer()}
                                    placeholder={rebateSchedule.placeholder} emptyValue={{ id: "none", "name": "None" }} onSelectionChanged={onPlayerRebateSchedChanged} type={"dropdown"} />
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    color="error">
                                    <ErrorMessage name={rebateSchedule.name} />
                                </SoftTypography>
                            </Grid>
                        </Grid>
                    </SoftBox>
                </Grid>

            </Grid >
        </SoftBox >
    </SoftBox >;
};

AgencyPlayerRebateConfigEditorForm.propTypes = {
    cardoom: PropTypes.object.isRequired,
    agency: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    config: PropTypes.object,
    formData: PropTypes.object.isRequired
};


const AgencyPlayerRebateConfigEditor = ({ cardroom, agency, player, config, onClose, onPlayerUpdated }) => {

    const { formId, formField } = form;
    const currentValidation = validations.editor;


    const [retryCounter, setRetryCounter] = useState(0);
    const [configRecord, setConfigRecord] = useState(null);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving player...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.club.scopes.write,
    });

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditPlayer() {
        if (!context) return false;

        return true;
    }

    const handleSubmit = (values, actions) => {

        if (false === canEditPlayer()) return;
        var cfg = {
            playerId: values.playerId,
            clubId: values.clubId,
            scheduleId: values.rebateSchedule == "none" ? "00000000-0000-0000-0000-000000000000" : values.rebateSchedule, //delete on empty
            scheduleName: values.rebateScheduleName
        };

        setConfigRecord(cfg);
    };


    useEffect(() => {
        if (configRecord) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving player configuration...")
            showProgress();


            //  [HttpPut("{agencyId:Guid}/casino/{casinoId:Guid}/players/{playerId:Guid}/clubs/{clubId:Guid}/schedules/rebate/{scheduleId:Guid}", Name = "SetPlayerRebateSchedule")]
            execute("PUT", apiConfig.club.endpoint + "/agency/" + agency.id + "/casino/" + cardroom.id + "/players/" + configRecord.playerId + "/clubs/" + configRecord.clubId + "/schedules/rebate/" + configRecord.scheduleId).then((response) => {
                if (response) {
                    if (response.status && response.errors) {
                        throw new Error(response.errors);
                    }

                    //TODO: update player rebate config for specific club --- this will depend on what shape player takes
                    var club = null;
                    if (player.clubs) {
                        for (var i = 0; i < player.clubs.length; i++) {
                            if (player.clubs[i].id === configRecord.clubId) {
                                club = player.clubs[i];
                                break;
                            }
                        }
                    }

                    if (club) {
                        if (!club.rebateConfig) {
                            club.rebateConfig = {};
                        }

                        club.rebateConfig.playerId = configRecord.playerId;
                        club.rebateConfig.clubId = configRecord.clubId;
                        club.rebateConfig.agencyId = agency.id;


                        if (response.id === "00000000-0000-0000-0000-000000000000") {
                            club.rebateConfig.schedule = null;
                        }
                        else {
                            club.rebateConfig.schedule = response;
                        }

                        /*
                        if (configRecord.scheduleId === "00000000-0000-0000-0000-000000000000") { //deleted
                            club.rebateConfig.schedule = null;
                        }
                        else {
                            if (!club.rebateConfig.schedule) club.rebateConfig.schedule = {};
                            club.rebateConfig.schedule.id = configRecord.scheduleId;
                            club.rebateConfig.schedule.name = configRecord.scheduleName;
                        }
                        */
                    }

                    raiseOnChanged(player);
                }

                setConfigRecord(null);
                progressIndicatorProps.close();
                raiseOnClose();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save player configuration, please try again...")
            });
        }

    }, [configRecord, execute, retryCounter]);


    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    function raiseOnChanged(p) {
        if (onPlayerUpdated) {
            onPlayerUpdated(p);
        }
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <AgencyPlayerRebateConfigEditorForm cardroom={cardroom} agency={agency} player={player} config={config} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting || !canEditPlayer()}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};

AgencyPlayerRebateConfigEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    agency: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,
    config: PropTypes.object,
    onPlayerUpdated: PropTypes.func,
    onClose: PropTypes.func
};

const AgencyPlayerRebateEditor = ({ cardroom, agency, player, config }) => {

    const [clubConfig, setClubConfig] = useState(null);

    function onSelectedClubChanged(cc, index) {
        if (cc && cc.rebateConfig) setClubConfig(cc.rebateConfig);
    }


    return <Grid container xs={12} >
        <Grid item xs={4} pr={2}>
            <AgencyPlayerClubList cardroom={cardroom} agency={agency} player={player} onSelectionChanged={onSelectedClubChanged} />
        </Grid>
        <Grid item xs={8}>
            <Card style={{ overflow: "auto" }}>
                {clubConfig ?
                    <AgencyPlayerRebateConfigEditor cardroom={cardroom} agency={agency} player={player} config={clubConfig} />
                    :
                    <Card sx={{ height: 275 }}>
                        <Grid container xs={12} justifyContent="center" alignItems="center">
                            <Grid item xs={12} textAlign={"center"}>

                                <SoftTypography
                                    component="h5"
                                    variant="caption"
                                    color="info">
                                    Select club from the list
                                </SoftTypography>

                            </Grid>
                        </Grid>
                    </Card>
                }
            </Card>
        </Grid>
    </Grid>;
};

AgencyPlayerRebateEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    agency: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    config: PropTypes.object
};


const AgencyPlayerPropertiesEditorForm = ({ cardroom, agency, club, player, config, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { playerId, clubId, rebateSchedule } = formField;
    const { playerId: playerIdV,
        clubId: clubIdV,
        rebateSchedule: rebateScheduleV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditPlayer() {
        if (!context) return false;

        return true;
    }


    useEffect(() => {
        if (!formDataInitialized && config) {

            setFieldValue(playerId.name, config ? config.playerId : player ? player.id : "");

            setFieldValue(clubId.name, config ? config.clubId : club ? club.id : "");

            setFieldValue(rebateSchedule.name, config.schedule ? config.schedule.id : "");

            setFormDataInitialized(true);
        }
    }, [formDataInitialized, config]);

    return <SoftBox >
        <SoftBox mt={2} >
            <Grid container spacing={1}>
                <Grid item xs={0} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={playerAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>

                <Grid item xs={12} sm={8} justifyItems={"center"}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {playerId.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    {player ? player.name : ""}
                                </SoftTypography>
                            </Grid>
                        </Grid>
                    </SoftBox>
                </Grid>

            </Grid >
        </SoftBox >
    </SoftBox >;
};

AgencyPlayerPropertiesEditorForm.propTypes = {
    cardoom: PropTypes.object.isRequired,
    agency: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    config: PropTypes.object,
    formData: PropTypes.object.isRequired
};


const AgencyPlayerPropertiesEditor = ({ cardroom, agency, player, config, onClose, onPlayerUpdated }) => {
    const { formId, formField } = form;
    const currentValidation = validations.editor;


    const [retryCounter, setRetryCounter] = useState(0);
    const [playerRecord, setPlayerRecord] = useState(null);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving player...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.club.scopes.write,
    });

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditPlayer() {
        if (!context) return false;

        return true;
    }

    const handleSubmit = (values, actions) => {

        if (false === canEditPlayer()) return;


    };



    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    function raiseOnChanged(p) {
        if (onPlayerUpdated) {
            onPlayerUpdated(p);
        }
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox style={{ minHeight: "235px" }}>
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <AgencyPlayerPropertiesEditorForm cardroom={cardroom} agency={agency} player={player} config={config} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />

                            {/**
                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting || !canEditPlayer()}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>
                             */}
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik >;
}

AgencyPlayerPropertiesEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    agency: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,
    config: PropTypes.object,
    onPlayerUpdated: PropTypes.func,
    onClose: PropTypes.func
};


const ClubPlayerPropertiesEditorForm = ({ cardroom, club, player, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { playerId, clubId, rebateSchedule, rebateScheduleName, agentId, superAgentId, canAssignAgent } = formField;
    const { playerId: playerIdV,
        clubId: clubIdV,
        rebateSchedule: rebateScheduleV,
        rebateScheduleName: rebateScheduleNameV,
        agentId: agentIdV,
        superAgentId: superAgentIdV,
        canAssignAgent: canAssignAgentV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditPlayer() {
        if (!context) return false;

        return true;
    }

    function canAssignAgents() {
        if (!context) return false;

        if (!club) return false;
        if (!club.app) return false;

        return (club.app.features & 2) > 0;
    }



    function onPlayerRebateSchedChanged(schedule) {
        setFieldValue(rebateSchedule.name, schedule && schedule.id ? schedule.id : "");
    }

    function onPlayerAgentChanged(a) {
        if (a) {
            setFieldValue(agentId.name, a.id);
        }
        else {
            setFieldValue(agentId.name, "none");
            setFieldValue(superAgentId.name, "none");
        }
    }
    function onPlayerSuperAgentChanged(sa) {
        if (sa) {
            setFieldValue(superAgentId.name, sa.id);
        }
        else {
            setFieldValue(superAgentId.name, "none");
        }
    }

    useEffect(() => {
        if (!formDataInitialized && player && club) {

            setFieldValue(playerId.name, player ? player.id : "");

            setFieldValue(clubId.name, club ? club.id : "");

            setFieldValue(rebateSchedule.name, player && player.rebateSchedule ? player.rebateSchedule.id : "none");

            setFieldValue(rebateScheduleName.name, player && player.rebateSchedule ? player.rebateSchedule.id : "[None]");

            setFieldValue(canAssignAgent.name, canAssignAgents());

            if (player && player.agent) {
                setFieldValue(agentId.name, player.agent.id ? player.agent.id : "none");

                if (player.agent.superAgent) {
                    setFieldValue(superAgentId.name, player.agent.superAgent.id ? player.agent.superAgent.id : "none");
                }
                else {
                    setFieldValue(superAgentId.name, "none");
                }
            }
            else {
                setFieldValue(agentId.name, "none");
                setFieldValue(superAgentId.name, "none");
            }



            setFormDataInitialized(true);
        }
    }, [formDataInitialized, player, club]);

    return <SoftBox>
        <SoftBox mt={2}>

            <Grid container spacing={1}>
                <Grid item xs={0} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={playerAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>

                <Grid item xs={12} sm={8} justifyItems={"center"}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        Player
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    {player ? player.name : null}
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        {canAssignAgents() ? <>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {agentId.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={8}>
                                    <AgentSelector cardroom={cardroom} club={club} value={agentIdV} readonly={!canAssignAgents()}
                                        placeholder={agentId.placeholder} emptyValue={{ id: "none", "name": "None" }} onSelectionChanged={onPlayerAgentChanged} type={"dropdown"} />
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={agentId.name} />
                                    </SoftTypography>
                                </Grid>
                            </Grid>

                            {agentIdV && agentIdV !== "none" ?
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize">
                                                {superAgentId.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <AgentSelector cardroom={cardroom} club={club} value={superAgentIdV} readonly={!canAssignAgents()}
                                            placeholder={superAgentId.placeholder} emptyValue={{ id: "none", "name": "None" }} onSelectionChanged={onPlayerSuperAgentChanged} type={"dropdown"} />
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            color="error">
                                            <ErrorMessage name={superAgentId.name} />
                                        </SoftTypography>
                                    </Grid>
                                </Grid> : null}
                        </> : null}


                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {rebateSchedule.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>

                            <Grid item xs={8}>
                                <PlayerRebateSelector cardroom={cardroom} club={club} value={rebateScheduleV} readonly={!canEditPlayer()}
                                    placeholder={rebateSchedule.placeholder} emptyValue={{ id: "none", "name": "None" }} onSelectionChanged={onPlayerRebateSchedChanged} type={"dropdown"} />
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    color="error">
                                    <ErrorMessage name={rebateSchedule.name} />
                                </SoftTypography>
                            </Grid>
                        </Grid>
                    </SoftBox>
                </Grid>

            </Grid >
        </SoftBox >
    </SoftBox >;

};

ClubPlayerPropertiesEditorForm.propTypes = {
    cardoom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired
};

const ClubPlayerPropertiesEditor = ({ cardroom, club, player, onClose, onPlayerUpdated }) => {
    const { formId, formField } = form;
    const currentValidation = validations.editor;


    const [retryCounter, setRetryCounter] = useState(0);
    const [playerRecord, setPlayerRecord] = useState(null);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving player...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.club.scopes.write,
    });

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditPlayer() {
        if (!context) return false;

        return true;

    }
    function raiseOnChanged(p) {
        if (onPlayerUpdated) onPlayerUpdated(p);
    }

    const handleSubmit = (values, actions) => {

        if (false === canEditPlayer()) return;

        var changes = {
            config: {
                scheduleId: values.rebateSchedule == "none" ? "00000000-0000-0000-0000-000000000000" : values.rebateSchedule, //delete on empty
                scheduleName: values.rebateScheduleName
            }
        };

        if (values.canAssignAgent === true) {

            //do this only if actaully have changes
            let hasChanges = false;

            if (player.agent && player.agent.id != values.agentId) hasChanges = true;
            if (!player.agent && values.agentId !== "none") hasChanges = true;

            if (player.agent) {
                if (player.agent.superAgent && player.agent.superAgent.id != values.superAgentId) hasChanges = true;
                if (!player.agent.superAgent && values.superAgentId !== "none") hasChanges = true;
            }

            if (hasChanges) {
                changes.player = {
                    id: values.playerId,
                    agentId: values.agentId == "none" ? null : values.agentId,
                    superAgentId: values.superAgentId == "none" ? null : values.superAgentId
                }

                if (!changes.player.agentId) changes.player.superAgentId = null;
            }
        }


        setPlayerRecord(changes);
    };


    useEffect(() => {
        if (playerRecord) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving player configuration...")
            showProgress();


            function onPlayerSaved(result) {
                if (result && playerRecord && playerRecord.config) {

                    //TODO: figure out how to update "player" .agent & .superAgent

                    execute("PUT", apiConfig.club.endpoint + "/club/" + club.id + "/casino/" + cardroom.id + "/players/" + player.id + "/schedules/rebate/" + playerRecord.config.scheduleId)
                        .then(onPlayerConfigSaved)
                        .catch(onPlayerConfigSaveError);

                }
                else {
                    onPlayerConfigSaved(null);
                }
            }
            function onPlayerSaveError(err) {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save player, please try again...");
            }

            function onPlayerConfigSaved(cfg) {

                if (cfg) {
                    if (cfg.status && cfg.errors) {
                        throw new Error(cfg.errors);
                    }

                    if (cfg.id === "00000000-0000-0000-0000-000000000000") {
                        player.rebateSchedule = null;
                    }
                    else {
                        player.rebateSchedule = cfg;
                    }
                    //agent = Object.assign(agent, response);
                    raiseOnChanged(player);
                }

                setPlayerRecord(null);
                progressIndicatorProps.close();
                raiseOnClose();
            }

            function onPlayerConfigSaveError(err) {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save player configuration, please try again...");
            }

            if (!playerRecord.player) {
                onPlayerSaved(true);
            }
            else {
                // are we clearing???

                let url = apiConfig.club.endpoint + "/player/casino/" + cardroom.id + "/club/" + club.id + "/player/" + player.id + "/agent";
                if (!playerRecord.player.agentId && !playerRecord.player.superAgentId) {
                    //clear agent
                    // [HttpDelete("casino/{casinoId:Guid}/club/{clubId:Guid}/player/{playerId:Guid}/agent", Name = "RemovePlayerFromAgent")]
                    execute("DELETE", url)
                        .then(onPlayerSaved)
                        .catch(onPlayerSaveError);
                }
                else {
                    //save agent

                    //[HttpPut("casino/{casinoId:Guid}/club/{clubId:Guid}/player/{playerId:Guid}/agent/{agentId:Guid}/superagent/{superAgentId:Guid}", Name = "AddPlayerToAgentAndSuperAgent")]

                    url += "/" + playerRecord.player.agentId;

                    if (playerRecord.player.superAgentId) {
                        url += "/superagent/" + playerRecord.player.superAgentId;
                    }

                    execute("PUT", url)
                        .then(onPlayerSaved)
                        .catch(onPlayerSaveError)
                }

            }
        }

    }, [playerRecord, execute, retryCounter]);


    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    function raiseOnChanged(p) {
        if (onPlayerUpdated) {
            onPlayerUpdated(p);
        }
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox style={{ minHeight: "235px" }}>
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <ClubPlayerPropertiesEditorForm cardroom={cardroom} club={club} player={player} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />


                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting || !canEditPlayer()}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>

                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik >;
};

ClubPlayerPropertiesEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,
    onPlayerUpdated: PropTypes.func,
    onClose: PropTypes.func
};


export { AgencyPlayerPropertiesEditor, AgencyPlayerRebateEditor, ClubPlayerPropertiesEditor };