import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';

import { Card, CardContent, Divider, Grid, Icon, Paper, Stack, Switch, Tooltip, styled, useMediaQuery, useTheme } from '@mui/material';

import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import defaultCasino from "assets/graphics/logos/default-casino.png";
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import whiteCurved from "assets/images/curved-images/white-curved.jpeg";

import PremiumServiceSubscriptionRequired from 'components/Elements/PremiumServiceRequried';
import { ReportAgents, ReportPlayers } from '../common';
import { useAgentCalculator, usePlayerCalculator } from '../common/calculator';
import { useReportDataLoader } from '../common/data';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import AdaptiveTab from 'components/Elements/AdaptiveTab';
import AdhocLine, { AdhocLineDialog } from '../AdhocLine';
import { useDialog } from 'components/Elements/Dialog/common';
import SoftButton from 'components/SoftButton';
import { FeatureRequiresPremiumSubDialog } from 'components/Elements/PremiumServiceRequried';
import { ReportValueRedacted } from 'components/Elements/PremiumServiceRequried';
import { useBookDataLoader } from 'layouts/Book/components';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import MiniStatisticsCard from 'components/Elements/MiniStatisticsCard';
import MiniActionCard from 'components/Elements/MiniActionCard';
import DataTable from 'components/Elements/DataTable';

const utilities = {
    zero: function () {
        return parseFloat(0).toFixed(2);
    },

    formatDecimalValue: function (v, currencyConverter, currency) {
        if (!v) return utilities.zero();

        let precision = 2;

        if (currencyConverter) {
            v = currencyConverter.convertFromUSD(v, currency);
            precision = currency.precision;
        }

        return v.toFixed(precision);
    }
};

const BookReportAgentPools = ({ cardroom, book, reportingPeriod, agent, playerPools, currencySymbol }) => {

    function formatDecimalValue(v) {
        return utilities.formatDecimalValue(v, null, null);
    }

    const [poolTable, setPoolTable] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading report players...", handleRetry);


    function prepareAgentPoolsForPresentation(agent, playerPools, table) {


        let rows = [];

        for (var i = 0; i < playerPools.length; i++) {

            rows.push({
                name: playerPools[i].name,
                displayNameNoCase: playerPools[i].name.toLowerCase(),
                playerCount: playerPools[i].playerCount,
                commission: playerPools[i].commissionEarned,
                makeup: playerPools[i].currentMakeupAmount,
                roMakeup: playerPools[i].currentMakeupAmount - playerPools[i].newlyEarnedMakeup,
                result: playerPools[i].wins + playerPools[i].losses,
                settlement: playerPools[i].wins + playerPools[i].losses + playerPools[i].commissionEarned,
                agent: agent,
                pool: playerPools[i]
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                return 0;
            });
        }

        table.rows = rows;

        return table;
    }


    function GetEmptySchema() {

        /*
        reportingPeriod.publicationStatus: 1 -- unpublished, 3 published
        reportingPeriodSnapshotId: "00000000-0000-0000-0000-000000000000"
        */

        const calcPrefix = reportingPeriod & reportingPeriod.publicationStatus === 3 ? "" : "Est. ";
        return {
            columns: [
                { Header: "Pool", accessor: "name", Cell: ({ value }) => (<TextCell value={value} />), },
                { Header: "Players", accessor: "playerCount", Cell: ({ value }) => <MoneyCell value={value} useColorScheme={false} prefix={""} /> },
                { Header: "Results", accessor: "result", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(value)} useColorScheme={true} prefix={currencySymbol} /> },
                { Header: calcPrefix + "Commission", accessor: "commission", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(value)} useColorScheme={true} prefix={currencySymbol} /> },
                { Header: "RO Makeup", accessor: "roMakeup", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(-1 * value)} useColorScheme={true} prefix={currencySymbol} /> },
                { Header: "New Makeup", accessor: "makeup", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(-1 * value)} useColorScheme={true} prefix={currencySymbol} /> },
                { Header: "Settlement", accessor: "settlement", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(-1 * value)} useColorScheme={true} prefix={currencySymbol} /> },

            ],
            rows: []
        };
    }

    useEffect(() => {
        if (agent && playerPools && !poolTable) {
            setPoolTable(prepareAgentPoolsForPresentation(agent, playerPools, GetEmptySchema()));
        }
    }, [agent, playerPools, poolTable]);

    return <Grid container justifyContent="center">
        <Grid item xs={12} >
            {poolTable ?
                <DataTable table={poolTable} canSearch={false} entriesPerPage={{ defaultValue: 100, visible: false }} pagination={{ color: "dark" }}
                    isHierarchical={false}
                /> : null}

        </Grid>
    </Grid>

}

const BookReportAgents = ({ cardroom, book, reportingPeriod, currency, currencyConverter }) => {

    function formatDecimalValue(v) {
        return utilities.formatDecimalValue(v, currencyConverter, currency);
    }

    function prepareAgentsForPresentation(agents, playerPools, table) {


        let rows = [];

        const poolMap = [];

        for (var i = 0; i < playerPools.length; i++) {
            if (!poolMap[playerPools[i].agentId]) {
                poolMap[playerPools[i].agentId] = {
                    pools: [],
                    wins: 0,
                    losses: 0,
                    makeup: 0,
                    newmakeup: 0,
                    commission: 0
                };
            }

            poolMap[playerPools[i].agentId].pools.push(playerPools[i]);

            poolMap[playerPools[i].agentId].wins += playerPools[i].wins;
            poolMap[playerPools[i].agentId].losses += playerPools[i].losses;
            poolMap[playerPools[i].agentId].makeup += playerPools[i].currentMakeupAmount;
            poolMap[playerPools[i].agentId].newmakeup += playerPools[i].newlyEarnedMakeup;
            poolMap[playerPools[i].agentId].commission += playerPools[i].commissionEarned;
        }


        for (var i = 0; i < agents.length; i++) {

            let agentPools = poolMap[agents[i].id];

            let agentImage = agents[i].name.substring(0, 1);

            rows.push({
                name: [agents[i].name, { image: agentImage }],
                displayName: agents[i].name,
                displayNameNoCase: agents[i].name.toLowerCase(),
                appAgentId: agents[i].appAgentId,
                playerCount: agents[i].playerCount,
                agentCount: agents[i].agentCount,
                poolCount: agentPools.pools.length,
                commission: agentPools.commission,
                makeup: agentPools.makeup,
                roMakeup: agentPools.makeup - agentPools.newmakeup,
                result: agentPools.wins + agentPools.losses,
                settlement: agentPools.wins + agentPools.losses + agentPools.commission,
                pools: agentPools.pools,
                agent: agents[i],
                details: <BookReportAgentPools cardroom={cardroom} book={book} reportingPeriod={reportingPeriod}
                    currencySymbol={currencySymbol} agent={agents[i]} playerPools={agentPools.pools} />
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function GetEmptySchema() {

        /*
        reportingPeriod.publicationStatus: 1 -- unpublished, 3 published
        reportingPeriodSnapshotId: "00000000-0000-0000-0000-000000000000"
        */

        const calcPrefix = reportingPeriod & reportingPeriod.publicationStatus === 3 ? "" : "Est. ";
        return {
            columns: [
                {
                    Header: "Agent",
                    accessor: "name",
                    Cell: ({ value: [name, data], row }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} participant={row && row.original && row.original.player ? row.original.player : null} />
                    ),
                },
                { Header: "Packages", accessor: "poolCount", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Results", accessor: "result", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(value)} useColorScheme={true} prefix={currencySymbol} /> },
                { Header: calcPrefix + "Commission", accessor: "commission", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(value)} useColorScheme={true} prefix={currencySymbol} /> },
                { Header: "RO Makeup", accessor: "roMakeup", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(-1 * value)} useColorScheme={true} prefix={currencySymbol} /> },
                { Header: "New Makeup", accessor: "makeup", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(-1 * value)} useColorScheme={true} prefix={currencySymbol} /> },
                { Header: "Settlement", accessor: "settlement", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(-1 * value)} useColorScheme={true} prefix={currencySymbol} /> },

            ],
            collapsibleDetailAccessor: "details",
            rows: []
        };
    }


    function renderMobileRow(columns, row, key, collapsibleDetailAccessor) {
        const color = "secondary";

        return <Card
            sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
                backgroundImage: gradients[color]
                    ? `${linearGradient(
                        rgba(gradients[color].main, 0.85),
                        rgba(gradients[color].state, 0.85)
                    )}, url(${whiteCurved})`
                    : `${linearGradient(
                        rgba(gradients[color].main, 0.85),
                        rgba(gradients[color].state, 0.85)
                    )}, url(${whiteCurved})`,
                backgroundSize: "cover",
                backgroundPosition: "50%",
                overflow: "visible",
            })}
        >
            <CardContent>
                <SoftBox pt={2} textAlign="center">
                    <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: row.original.agentName, fontWeight: "medium" }}
                        count={row.cells[0].value[0]}
                        icon={{ color: "dark", component: "support_agent" }}
                        direction={"left"}
                        useNumericFormatter={false}
                    />
                </SoftBox>

                < SoftBox pt={2} textAlign="center">
                    <Grid container ml="auto" mr="auto" xs={12}>
                        <Grid item xs={6} pr={0.25} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "Package Count", fontWeight: "medium" }}
                                count={{ text: row.original.poolCount, useNumericFormatter: false }}
                                icon={{ color: "dark", component: "groups" }}
                                direction={"left"}
                            />
                        </Grid>
                        <Grid item xs={6} pr={0.25} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "makeup", fontWeight: "medium" }}
                                count={{ text: formatDecimalValue(row.original.makeup), prefix: "$", useNumberFormatter: true, color: row.original.makeup > 0 ? "error" : "success" }}
                                icon={{ color: "dark", component: "savings" }}
                                direction={"right"}

                            />
                        </Grid>
                    </Grid>

                </SoftBox>

                < SoftBox pt={2} textAlign="center">
                    <Grid container ml="auto" mr="auto" xs={12}>
                        <Grid item xs={6} pr={0.25} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "Settlement", fontWeight: "medium" }}
                                count={{ text: formatDecimalValue(row.original.settlement), prefix: currencySymbol, useNumberFormatter: true, color: row.original.settlement > 0 ? "success" : "error" }}
                                icon={{ color: "dark", component: "balance" }}
                                direction={"left"}
                            />
                        </Grid>
                        <Grid item xs={6} pr={0.25} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "Commission", fontWeight: "medium" }}
                                count={{ text: formatDecimalValue(row.original.commission), prefix: currencySymbol, useNumberFormatter: true, color: row.original.commission > 0 ? "success" : "dark" }}
                                icon={{ color: "dark", component: "percent" }}
                                direction={"right"}

                            />
                        </Grid>
                    </Grid>

                </SoftBox>


            </CardContent>
        </Card >;
    }


    const switchToMobileOnSize = "md";

    const theme = useTheme();
    const isMobileUi = !useMediaQuery(theme.breakpoints.up(switchToMobileOnSize));

    const [retryCounter, setRetryCounter] = useState(0);

    const [context, actions, features] = useCardroomContext(cardroom);

    const [currencySymbol, setCurrencySymbol] = useState(null);

    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    useEffect(() => {
        if (true === isCurrencyMaskingEnabled()) {
            setCurrencySymbol("");
            return;
        }

        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency, currencyConverter]);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading report players...", handleRetry)


    const [agents, setAgents] = useState(null);
    const [playerPools, setPlayerPools] = useState(null);
    const [agentTable, setAgentTable] = useState(null);

    const bookRequest = useFetchWithMsal({
        scopes: apiConfig.book.scopes.read,
    });
    const bookDataLoader = useBookDataLoader();


    useEffect(() => {
        if (!agents) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading player agents...")
            showProgress();

            bookDataLoader.entity.loadAgents(bookRequest, cardroom, book, true, (response) => {
                if (response) {
                    setAgents(response);
                }

            }, (err) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load player agents, please try again...")
            });
        }
    }, [bookRequest.execute, agents, retryCounter]);

    useEffect(() => {
        if (agents && !playerPools) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading packages...")
            showProgress();

            bookDataLoader.entity.loadReportAgentPools(bookRequest, cardroom, book, reportingPeriod, true, (response) => {
                if (response) {
                    setPlayerPools(response);
                }

            }, (err) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load packages, please try again...")
            });
        }
    }, [bookRequest.execute, agents, playerPools, retryCounter]);

    useEffect(() => {
        if (agents && playerPools && !agentTable) {

            setAgentTable(prepareAgentsForPresentation(agents, playerPools, GetEmptySchema()));
            progressIndicatorProps.close();
        }

    }, [agents, playerPools, agentTable]);

    return <Card>
        <ProgressIndicator {...progressIndicatorProps} />
        {!progressIndicatorProps.visible && book ? <>
            <Grid container justifyContent="center">
                <Grid item xs={12} >

                    {agentTable ?
                        <DataTable table={agentTable} canSearch entriesPerPage={{ defaultValue: 10, visible: !isMobileUi }} pagination={{ color: "dark" }}
                            onRenderRow={renderMobileRow} useCustomRenderSize={switchToMobileOnSize} isHierarchical={true}
                        /> : null}

                </Grid>
            </Grid>
        </> : null}
    </Card>
};

const BookReportPlayers = ({ cardroom, book, reportingPeriod, currency, currencyConverter }) => {

    function formatDecimalValue(v) {
        return utilities.formatDecimalValue(v, currencyConverter, currency);
    }

    function createObjectMap(entities) {
        const map = [];
        if (entities) {
            for (var i = 0; i < entities.length; i++) {
                map[entities[i].id] = entities[i];
            }
        }
        return map;
    }

    function preparePlayersForPresentation(players, agents, playerPools, rebates, table) {

        const agentMap = createObjectMap(agents);
        const playerPoolMap = createObjectMap(playerPools);
        const rebateMap = createObjectMap(rebates);

        let rows = [];

        for (var i = 0; i < players.length; i++) {
            let playerImage = players[i].name.substring(0, 1);
            let agent = players[i].agent ? players[i].agent : (agentMap[players[i].agentId] ? agentMap[players[i].agentId] : null);
            let pool = players[i].playerPool ? players[i].playerPool : (playerPoolMap[players[i].agentPlayerPoolId] ? playerPoolMap[players[i].agentPlayerPoolId] : null);
            let rebate = rebateMap[players[i].rebateScheduleId] ? rebateMap[players[i].rebateScheduleId] : null;

            let result = players[i].results && players[i].results.length > 0 ? players[i].results[0] : null;
            let total = 0, rebateAmount = 0;
            if (result) {
                total = result.monday + result.tuesday + result.wednesday + result.thursday + result.friday + result.saturday + result.sunday;
            }

            let effectiveMakeup = players[i].currentMakeupAmount + total;
            if (effectiveMakeup < 0) effectiveMakeup = 0;

            rebateAmount = players[i].rebateEarned;

            rows.push({
                name: [players[i].name, { image: playerImage }],
                displayName: players[i].name,
                displayNameNoCase: players[i].name.toLowerCase(),
                nickname: players[i].nickname,
                appPlayerId: players[i].appPlayerId,
                agentName: agent ? agent.name : "None",
                agentId: agent ? agent.appAgentId : "None",
                poolName: pool ? pool.name : "None",
                agentNameAndPool: (agent ? (agent.name + " / " + (pool ? pool.name : "None")) : "None"),
                sourceAppName: players[i].app ? players[i].app.name : "None",
                rebateName: rebate ? rebate.name : "None",
                makeup: players[i].currentMakeupAmount,
                effectiveMakeup: effectiveMakeup,
                netResult: total,
                rebateAmount: rebateAmount,
                settlement: total + rebateAmount,
                player: players[i],
                rebate: rebate,
                agent: agent,
                pool: pool
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function GetEmptySchema() {


        const calcPrefix = reportingPeriod & reportingPeriod.publicationStatus === 3 ? "" : "Est. ";

        return {
            columns: [
                {
                    Header: "Player",
                    accessor: "name",
                    Cell: ({ value: [name, data], row }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} participant={row && row.original && row.original.player ? row.original.player : null} />
                    ),
                },
                { Header: "Player Id", accessor: "appPlayerId", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Agent / Package", accessor: "agentNameAndPool", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Results", accessor: "netResult", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(value)} useColorScheme={true} prefix={currencySymbol} /> },
                { Header: "Schedule", accessor: "rebateName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: calcPrefix + "Rebate", accessor: "rebateAmount", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(value)} useColorScheme={true} prefix={currencySymbol} /> },
                { Header: "RO Makeup", accessor: "makeup", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(value)} useColorScheme={true} prefix={currencySymbol} /> },
                { Header: calcPrefix + "Makeup", accessor: "effectiveMakeup", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(value)} useColorScheme={true} prefix={currencySymbol} /> },
                { Header: "Settlement", accessor: "settlement", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(-1 * value)} useColorScheme={true} prefix={currencySymbol} /> }
            ],
            rows: []
        };
    }


    function renderMobileRow(columns, row, key, collapsibleDetailAccessor) {
        const color = "secondary";

        const hasAgent = row.cells[4].value != "None";

        return <Card
            sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
                backgroundImage: gradients[color]
                    ? `${linearGradient(
                        rgba(gradients[color].main, 0.85),
                        rgba(gradients[color].state, 0.85)
                    )}, url(${whiteCurved})`
                    : `${linearGradient(
                        rgba(gradients[color].main, 0.85),
                        rgba(gradients[color].state, 0.85)
                    )}, url(${whiteCurved})`,
                backgroundSize: "cover",
                backgroundPosition: "50%",
                overflow: "visible",
            })}
        >
            <CardContent>
                <SoftBox pt={2} textAlign="center">
                    <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: row.cells[1].value, fontWeight: "medium" }}
                        count={row.cells[0].value[0]}
                        icon={{ color: "dark", component: "person" }}
                        direction={"left"}
                        useNumericFormatter={false}
                    />
                </SoftBox>


                < SoftBox pt={2} textAlign="center">
                    {hasAgent ?
                        <Grid container ml="auto" mr="auto" xs={12}>
                            <Grid item xs={6} pr={0.25} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                                <MiniActionCard
                                    bgColor="white"
                                    title={{ text: row.original.agentName, fontWeight: "medium" }}
                                    count={{ text: row.original.poolName, useNumericFormatter: false }}
                                    icon={{ color: "dark", component: "support_agent" }}
                                    direction={"left"}
                                />
                            </Grid>
                            <Grid item xs={6} pr={0.25} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                                <MiniActionCard
                                    bgColor="white"
                                    title={{ text: "makeup", fontWeight: "medium" }}
                                    count={{ text: formatDecimalValue(row.original.makeup), prefix: "$", useNumberFormatter: true, color: row.original.makeup > 0 ? "error" : "success" }}
                                    icon={{ color: "dark", component: "savings" }}
                                    direction={"right"}

                                />
                            </Grid>
                        </Grid>
                        : <Grid container ml="auto" mr="auto" xs={12}>

                            <Grid item xs={12} pr={0.25} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                                <MiniActionCard
                                    bgColor="white"
                                    title={{ text: "makeup", fontWeight: "medium" }}
                                    count={{ text: formatDecimalValue(row.original.makeup), prefix: "$", useNumberFormatter: true, color: row.original.makeup > 0 ? "error" : "success" }}
                                    icon={{ color: "dark", component: "savings" }}
                                    direction={"left"}

                                />
                            </Grid>
                        </Grid>}
                </SoftBox>

                < SoftBox pt={2} textAlign="center">
                    <Grid container ml="auto" mr="auto" xs={12}>
                        <Grid item xs={6} pr={0.25} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "Settlement", fontWeight: "medium" }}
                                count={{ text: formatDecimalValue(row.original.netResult), prefix: currencySymbol, useNumberFormatter: true, color: row.original.netResult > 0 ? "success" : "error" }}
                                icon={{ color: "dark", component: "balance" }}
                                direction={"left"}
                            />
                        </Grid>
                        <Grid item xs={6} pr={0.25} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "Rebate", fontWeight: "medium" }}
                                count={{ text: formatDecimalValue(row.original.rebateAmount), prefix: currencySymbol, useNumberFormatter: true, color: row.original.rebateAmount > 0 ? "success" : "dark" }}
                                icon={{ color: "dark", component: "price_check" }}
                                direction={"right"}

                            />
                        </Grid>
                    </Grid>

                </SoftBox>

            </CardContent>
        </Card >;
    }


    const switchToMobileOnSize = "md";

    const theme = useTheme();
    const isMobileUi = !useMediaQuery(theme.breakpoints.up(switchToMobileOnSize));

    const [retryCounter, setRetryCounter] = useState(0);

    const [context, actions, features] = useCardroomContext(cardroom);

    const [currencySymbol, setCurrencySymbol] = useState(null);

    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    useEffect(() => {
        if (true === isCurrencyMaskingEnabled()) {
            setCurrencySymbol("");
            return;
        }

        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency, currencyConverter]);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading report players...", handleRetry)


    const [players, setPlayers] = useState(null);
    const [rebates, setRebates] = useState(null);
    const [agents, setAgents] = useState(null);
    const [playerPools, setPlayerPools] = useState(null);
    const [playerTable, setPlayerTable] = useState(null);

    const bookRequest = useFetchWithMsal({
        scopes: apiConfig.book.scopes.read,
    });
    const bookDataLoader = useBookDataLoader();

    useEffect(() => {
        if (!players) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading report players...")
            showProgress();

            bookDataLoader.entity.loadReportPlayers(bookRequest, cardroom, book, reportingPeriod, true, (response) => {
                if (response) {
                    setPlayers(response);
                }
            }, (err) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load report players, please try again...")
            });
        }
    }, [bookRequest.execute, players, retryCounter]);

    useEffect(() => {
        if (players && !rebates) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading player rebate schedules...")
            showProgress();

            bookDataLoader.entity.loadPlayerRebateSchedules(bookRequest, cardroom, book, true, (response) => {
                if (response) {
                    setRebates(response);
                }

            }, (err) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load player rebate schedules, please try again...")
            });
        }
    }, [bookRequest.execute, players, rebates, retryCounter]);

    useEffect(() => {
        if (players && rebates && !agents) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading player agents...")
            showProgress();

            bookDataLoader.entity.loadAgents(bookRequest, cardroom, book, true, (response) => {
                if (response) {
                    setAgents(response);
                }

            }, (err) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load player agents, please try again...")
            });
        }
    }, [bookRequest.execute, players, agents, rebates, retryCounter]);

    useEffect(() => {
        if (players && rebates && agents && !playerPools) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading packages...")
            showProgress();

            bookDataLoader.entity.loadAgentsPlayerPools(bookRequest, cardroom, book, agents, true, (response) => {
                if (response) {
                    setPlayerPools(response);
                }

            }, (err) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load packages, please try again...")
            });
        }
    }, [bookRequest.execute, players, agents, rebates, playerPools, retryCounter]);

    useEffect(() => {
        if (players && agents && playerPools && rebates && !playerTable) {

            setPlayerTable(preparePlayersForPresentation(players, agents, playerPools, rebates, GetEmptySchema()));
            progressIndicatorProps.close();
        }

    }, [players, agents, playerPools, rebates, playerTable]);

    return <Card>
        <ProgressIndicator {...progressIndicatorProps} />
        {!progressIndicatorProps.visible && book ? <>
            <Grid container justifyContent="center">
                <Grid item xs={12}>

                    {playerTable ?
                        <DataTable table={playerTable} canSearch entriesPerPage={{ defaultValue: 10, visible: !isMobileUi }} pagination={{ color: "dark" }}
                            onRenderRow={renderMobileRow} useCustomRenderSize={switchToMobileOnSize}
                        /> : null}

                </Grid>
            </Grid>
        </> : null}
    </Card>

};
const BookReportSummary = ({ cardroom, book, summary, reportingPeriod, currency, currencyConverter }) => {

    const cardroomLogo = cardroom && cardroom.hasLogo ? apiConfig.images.endpoint + "/casino/" + cardroom.id + ".png" : defaultCasino;
    const appLogo = book && book.app ? apiConfig.images.endpoint + "/app/" + book.app.id + ".png" : null;

    function formatDecimalValue(v) {
        return utilities.formatDecimalValue(v, currencyConverter, currency);
    }

    const [retryCounter, setRetryCounter] = useState(0);

    const [context, actions, features] = useCardroomContext(cardroom);

    const [currencySymbol, setCurrencySymbol] = useState(null);

    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    useEffect(() => {
        if (true === isCurrencyMaskingEnabled()) {
            setCurrencySymbol("");
            return;
        }

        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency, currencyConverter]);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading report details...", handleRetry)


    return <Card>
        <ProgressIndicator {...progressIndicatorProps} />

        {!progressIndicatorProps.visible && book ? <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={8} >
                    <Card>
                        {/* Report header */}
                        {summary ?
                            <SoftBox p={3}>
                                <Grid container xs={12} justifyContent="space-between">
                                    {book.app ? <>
                                        <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                            <SoftBox width="100%" textAlign="left" mt={2}>
                                                <SoftBox textAlign="center" width="max-content">
                                                    <SoftBox component="img" src={appLogo} width="96px" style={{}} p={1} mb={1} />
                                                    <SoftTypography variant="h6" fontWeight="medium">
                                                        {book.app.name}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item sm={4} xs={12} >
                                            <SoftBox width="100%" textAlign={{ xs: "center", md: "center" }} mt={2}>
                                                <Tooltip title="Sum of daily play results" placement="top">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="uppercase">
                                                        Total Play
                                                    </SoftTypography>
                                                </Tooltip>
                                                <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="uppercase">
                                                    <MoneyCell value={summary ? formatDecimalValue(summary.totalWon + summary.totalLost) : 0} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }} >
                                            <SoftBox justifyContent="right" textAlign="right" mt={2}>
                                                <SoftBox ml="auto" textAlign="center" width="max-content">
                                                    <SoftBox component="img" src={cardroomLogo} width="96px" style={{}} p={1} mb={1} />
                                                    <SoftTypography variant="h6" fontWeight="medium">
                                                        {book ? book.name : null}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </SoftBox>
                                        </Grid></> : null}
                                </Grid>
                                <Grid container justifyContent="space-between" mt={2}>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                                <SoftBox mt={3}>
                                    {/* Row 1 */}

                                    <Grid container xs={12} justifyContent="space-between">
                                        <Grid item xs={12} sm={4}>
                                            <Grid container xs={12} justifyContent="space-between">
                                                <Grid item sm={12} textAlign={"left"}>
                                                    <Tooltip title="Number of players that won during the period" placement="top-start">
                                                        <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                            Wins
                                                        </SoftTypography>
                                                    </Tooltip>

                                                </Grid>
                                                <Grid item sm={12} textAlign={"left"}>
                                                    <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                        <MoneyCell value={summary ? summary.totalWins : 0} prefix={""} useColorScheme={false} />
                                                    </SoftTypography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Grid container xs={12} justifyContent="space-between">
                                                <Grid item sm={12} textAlign={"center"}>
                                                    <Tooltip title="Number of players that lost during the period" placement="top">
                                                        <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                            Losses
                                                        </SoftTypography>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item sm={12} textAlign={"center"}>
                                                    <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                        <MoneyCell value={summary ? summary.totalLosses : 0} prefix={""} useColorScheme={false} />
                                                    </SoftTypography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Grid container xs={12} justifyContent="space-between">
                                                <Grid item sm={12} textAlign={"right"}>
                                                    <Tooltip title="Total number of players that played during the period" placement="top-start">
                                                        <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                            Total
                                                        </SoftTypography>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item sm={12} textAlign={"right"}>
                                                    <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                        <MoneyCell value={summary ? summary.totalAttempts : 0} prefix={""} useColorScheme={false} />
                                                    </SoftTypography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                    <Grid container justifyContent="space-between" >
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>

                                    {/* Row 2 */}
                                    <Grid container xs={12} justifyContent="space-between">
                                        <Grid item xs={12} sm={4}>
                                            <Grid container xs={12} justifyContent="space-between">
                                                <Grid item sm={12} textAlign={"left"}>
                                                    <Tooltip title="Total amount won by all players during the period" placement="top-start">
                                                        <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                            Winnings
                                                        </SoftTypography>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item sm={12} textAlign={"left"}>
                                                    <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                        <MoneyCell value={summary ? formatDecimalValue(summary.totalWon) : 0} prefix={currencySymbol} />
                                                    </SoftTypography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Grid container xs={12} justifyContent="space-between">
                                                <Grid item sm={12} textAlign={"center"}>
                                                    <Tooltip title="Total amount paid out in rebates and commissions during this period" placement="top">
                                                        <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                            Rebates & Commission
                                                        </SoftTypography>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item sm={12} textAlign={"center"}>
                                                    <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                        <MoneyCell value={summary ? formatDecimalValue(-1 * (summary.commissionEarned + summary.rebatesEarned)) : 0} prefix={currencySymbol} />
                                                    </SoftTypography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <Grid container xs={12} justifyContent="space-between">
                                                <Grid item sm={12} textAlign={"right"}>
                                                    <Tooltip title="Total amount lost by all players during the period" placement="top">
                                                        <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                            Losses
                                                        </SoftTypography>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item sm={12} textAlign={"right"}>
                                                    <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                        <MoneyCell value={summary ? formatDecimalValue(summary.totalLost) : 0} prefix={currencySymbol} />
                                                    </SoftTypography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>


                                </SoftBox>

                            </SoftBox> : null}

                    </Card>
                </Grid>
            </Grid>
        </> : null}
    </Card>
};

const BookReport = ({ cardroom, book, reportingPeriod, summary, currency, currencyConverter }) => {

    const [missingService, setMissingService] = useState("feature");
    const [openPremiumSumDialog, premiumSubDialogProps] = useDialog();

    const [context, actions, features] = useCardroomContext(cardroom);

    function isAgentReportEnabled() {
        if (!context) return false;
        return context.isEnabled(features.club.reporting.agentReport);
    }

    function isPlayerReportEnabled() {
        if (!context) return false;
        return context.isEnabled(features.club.reporting.playerReport);
    }

    function isDetailedSummaryEnabled() {
        if (!context) return false;
        return context.isEnabled(features.club.reporting.summaryDetailReport);
    }


    const tabs = [];


    tabs.push({
        title: { text: "Summary", icon: "summarize" },
        header: null,
        body: <BookReportSummary cardroom={cardroom} book={book} summary={summary} reportingPeriod={reportingPeriod} currency={currency} currencyConverter={currencyConverter} />
    });


    tabs.push({
        title: { text: "Agents", icon: "support_agent" },
        header: null,
        body: <BookReportAgents cardroom={cardroom} book={book} reportingPeriod={reportingPeriod} currency={currency} currencyConverter={currencyConverter} />
    });

    tabs.push({
        title: { text: "Players", icon: "person" },
        header: null,
        body: <BookReportPlayers cardroom={cardroom} book={book} reportingPeriod={reportingPeriod} currency={currency} currencyConverter={currencyConverter} />
    });

    return <SoftBox pt={2}>
        <FeatureRequiresPremiumSubDialog {...premiumSubDialogProps} service={missingService} />
        <AdaptiveTab switchToMobileOnSize="md" tabs={tabs} />
    </SoftBox>;
};

BookReport.defaultProps = {

};

BookReport.propTypes = {
    cardroom: PropTypes.object.isRequired,
    book: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired,
    currencyConverter: PropTypes.object.isRequired
};

export default BookReport;