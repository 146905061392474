import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Grid } from '@mui/material';


import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Formik, Form, ErrorMessage } from "formik";
import FormField from "components/Elements/Forms/FormField";

import defaultTxAvatar from "assets/graphics/personas/dealer_256.png";
import { NumericFormat } from 'react-number-format';
import { useCardroomContext } from 'features';



const DealerDownForm = ({ cardroom, session, staff, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { type, amount, tipAmount, tableNo, taxRate, taxAmount, method, verified } = formField;
    const {
        type: typeV,
        amount: amountV,
        method: methodV,
        tipAmount: tipAmountV,
        taxRate: taxRateV,
        taxAmount: taxAmountV,
        tableNo: tableNoV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);
    useEffect(() => {
        if (!formDataInitialized) {

            setFieldValue(type.name, "down");

            setFieldValue(method.name, "credit");

            setFieldValue(taxRate.name, staff && staff.properties && staff.properties.withholdingRate ? staff.properties.withholdingRate : 0);

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    /*
    function onTipAmountChanged() {
        setFieldValue(taxAmount.name, Math.round(taxRateV * tipAmountV / 5) * 5);
    }
*/

    const [context, actions, features] = useCardroomContext(cardroom);

    function canOverrideTaxAmount() {
        if (!context) return false;
        return context.isAllowed(actions.casino.staff.payout.overrideWitheldAmount);
    }

    return <SoftBox minHeight="190px">
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={defaultTxAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {tableNo.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()}
                                    type={tableNo.type}
                                    name={tableNo.name}
                                    value={tableNoV}
                                    placeholder={tableNo.placeholder}
                                    error={errors.tableNo && touched.tableNo}
                                    success={tableNoV && tableNoV.toString().length > 0 && !errors.tableNo}
                                /></Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        Rake Collected
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()} autoFocus
                                    type={amount.type}
                                    name={amount.name}
                                    value={amountV}
                                    placeholder={amount.placeholder}
                                    error={errors.amount && touched.amount}
                                    success={amountV && amountV.toString().length > 0 && !errors.amount}
                                /></Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {tipAmount.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()}
                                    type={tipAmount.type}
                                    name={tipAmount.name}
                                    value={tipAmountV}
                                    placeholder={tipAmount.placeholder}
                                    error={errors.tipAmount && touched.tipAmount}
                                    success={tipAmountV && tipAmountV.toString().length > 0 && !errors.tipAmount}
                                /></Grid>
                        </Grid>
                       
                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox>

};



export default DealerDownForm;