
const form = {
    formId: "sportsbook-agent-form",
    formField: {
        agentId: {
            name: "agentId",
            type: "hidden"
        },        
        agentName: {
            name: "agentName",
            type: "text",
            label: "Name",
            placeholder: "agent name",
            errorMsg: "Agent name is required.",
        },
        currency: {
            name: "currency",
            type: "text",
            label: "Currency",
            placeholder: "agent currency",
            errorMsg: "Agent currency is required.",
        },
    },

};

export default form;
