import React from 'react';
import PropTypes from "prop-types";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { BootstrapDialog } from "components/Elements/Dialog/common"
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import AgentEditor from './Editor';
import PremiumServiceSubscriptionRequired from 'components/Elements/PremiumServiceRequried';
import SoftButton from 'components/SoftButton';
import { DialogActions } from '@mui/material';
import { useCardroomContext } from 'features';


const AgentEditorDialog = ({ open, close, cardroom, club, agent, onAgentUpdated }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    const [context, actions, features] = useCardroomContext(cardroom);

    function isAgentEditingEnabled() {
        if (!context) return false;

        return context.isEnabled(features.club.reporting.agentReport);
    }

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="lg">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.secondary.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Agent Editor: {agent ? agent.name : null}
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox mt={3}>
                {isAgentEditingEnabled() ?
                    <AgentEditor cardroom={cardroom} club={club} agent={agent} onClose={close} onAgentUpdated={onAgentUpdated} /> : <PremiumServiceSubscriptionRequired service="Agent Management" />}
            </SoftBox>
        </DialogContent>
        {!isAgentEditingEnabled() ? <DialogActions>
            <SoftButton onClick={close}>Close</SoftButton>
        </DialogActions> : null}
    </BootstrapDialog>;
}

AgentEditorDialog.defaultProps = {

};

AgentEditorDialog.propTypes = {
    cardroom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    agent: PropTypes.object.isRequired,
    onAgentUpdated: PropTypes.func
};

export default AgentEditorDialog;
