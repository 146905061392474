
import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';
import SoftTypography from 'components/SoftTypography';
import DataBoundList from 'components/Elements/DataBound/List';


const ClubFeeSelector = ({ cardroom, union, value, readonly, placeholder, emptyValue, onSelectionChanged, type, onBuildListItem, reload, maxHeight }) => {

    // alert(value);
    const [schedules, setSchedules] = useState(union.schedules ? union.schedules : null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "retrieving fee schedules...", handleRetry)

    const [availableOptions, setAvailableOptions] = useState(null);

    const [selectedValue, setSelectedValue] = useState(value ? value : emptyValue);
    const [selectedIndex, setSelectedIndex] = useState(null);

    function getSelectedIndex() {
        let dataSource = availableOptions; // getDataSource();

        if (!dataSource) return -1;
        if (!selectedValue) return -1;

        for (var i = 0; i < dataSource.length; i++) {
            if (dataSource[i].id == selectedValue.id || dataSource[i].id == selectedValue) {
                return i;
            }
        }

        return -1;
    }

    function findValueInDataSource(v) {
        let dataSource = getDataSource();

        if (!dataSource) return null;
        if (!v) return null;

        for (var i = 0; i < dataSource.length; i++) {
            if (dataSource[i].id == v.id || dataSource[i].id == v) {
                return dataSource[i];
            }
        }

        return -1;
    }

    function getDataSource() {

        if (!schedules) return null;

        let rows = [...schedules];

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });
        }

        if (emptyValue) {
            rows.unshift(emptyValue);
        }

        return rows;
    }

    function handleScheduleChange({ item, index }) {
        setSelectedIndex(null);
        setSelectedValue(item);

        raiseOnSelectionChanged(item);
    }

    function raiseOnSelectionChanged(item) {
        if (onSelectionChanged) {
            onSelectionChanged(item);
        }
    }

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    useEffect(() => {
        if (reload === true) {
            setSchedules(null);
            setSelectedIndex(-1);
        }
    }, [reload]);

    useEffect(() => {
        if (!schedules) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("retrieving club fee schedules...")
            showProgress();

            //[HttpGet("{unionId:Guid}/casino/{casinoId:Guid}/schedules", Name = "GetClubFeeSchedules")]
            execute("GET", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id + "/schedules/fees").then((response) => {
                if (response) {
                    setSchedules(response);
                    union.schedules = response;
                }
                progressIndicatorProps.close();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to retrieve club fee schedules, please try again...")
            });
        }

    }, [schedules, execute, retryCounter]);

    useEffect(() => {

        if (schedules) {
            setAvailableOptions(getDataSource());

            if (selectedValue && !selectedIndex) {

                setSelectedIndex(getSelectedIndex());
            }
        }

    }, [schedules, selectedIndex, selectedValue]);

    useEffect(() => {
        if (schedules && value) {
            setSelectedValue(findValueInDataSource(value));
        }
    }, [schedules, value]);

    useEffect(() => {
        if (selectedValue && selectedIndex < 0) {
            setSelectedIndex(getSelectedIndex());
        }
    }, [selectedIndex, selectedValue]);

    return <>
        <ProgressIndicator  {...progressIndicatorProps} />
        {!progressIndicatorProps.visible ? <>
            {readonly ?
                <SoftTypography
                    variant="caption"
                    fontWeight="regular"
                    color="dark"
                > {selectedValue ? selectedValue.name : "[None]"}
                </SoftTypography> : <>{availableOptions ?
                    <>
                        {type === "dropdown" ?
                            <DataBoundSelect placeholder={placeholder} data={availableOptions} valueName="id" textName="name" onChange={handleScheduleChange} size="medium" selectedIndex={selectedIndex} /> : null}
                        {type === "list" ? <DataBoundList data={availableOptions} valueName="id" textName="name" onChange={handleScheduleChange} selectedIndex={selectedIndex} onBuildListItem={onBuildListItem} maxHeight={maxHeight} /> : null}
                    </>
                    : null}
                </>
            }</> : null}
    </>;
};

ClubFeeSelector.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    readonly: PropTypes.bool,
    placeholder: PropTypes.string,
    emptyValue: PropTypes.object,
    onSelectionChanged: PropTypes.func,
    type: PropTypes.oneOf(["list", "dropdown"]),
    maxHeight: PropTypes.string
};

ClubFeeSelector.defaultProps = {
    readonly: false,
    placeholder: "please make a selection",
    emptyValue: { id: null, name: "None" },
    type: "dropdown",
    maxHeight: "200px"
};

export default ClubFeeSelector;