
import { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import FormField from "components/Elements/Forms/FormField";
import { useProgressIndicator } from "components/Elements/ProgressIndicator";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { Formik, Form, ErrorMessage } from 'formik';


import { ProgressIndicator } from "components/Elements/ProgressIndicator";
import SoftButton from "components/SoftButton";

import form from "./schemas/form";
import validations from "./schemas/validations";
import initialValues from "./schemas/initialValues";

import agentAvatar from "assets/graphics/personas/sports_agent_gpt.png";
import { useBookDataLoader } from "../..";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { apiConfig } from "config/apiConfig";


const BookAgentPropertiesEditorForm = ({ cardroom, book, agent, formData, dense }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { agentId, agentName, currency } = formField;
    const {
        agentId: agentIdV,
        agentName: agentNameV,
        currency: currencyV
    } = values;


    const [formDataInitialized, setFormDataInitialized] = useState(false);

    useEffect(() => {
        if (!formDataInitialized) {

            setFieldValue(agentId.name, agent ? agent.id : "");

            setFieldValue(agentName.name, agent ? agent.name : "");

            setFieldValue(currency.name, agent ? agent.currency : "");

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);


    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                {dense === true ? null :
                    <Grid item xs={12} sm={4} container justifyContent="center">
                        <SoftBox position="relative" height="max-content" mx="auto">
                            <SoftAvatar src={agentAvatar} size="xxl" variant="rounded" bgColor="dark" />
                        </SoftBox>
                    </Grid>}
                <Grid item xs={12} sm={dense === true ? 12 : 8}>
                    <SoftBox mb={2} textAlign="left">

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {agentName.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>

                            <Grid item xs={8}>

                                <FormField onFocus={e => e.currentTarget.select()}
                                    type={agentName.type}
                                    name={agentName.name}
                                    value={agentNameV}
                                    placeholder={agentName.placeholder}
                                    error={errors.agentName && touched.agentName}
                                    success={agentNameV && agentNameV.length > 0 && !errors.agentName}
                                />

                            </Grid>
                        </Grid>

                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox>;
}

const BookAgentPropertiesEditor = ({ cardroom, book, agent, onAgentUpdated, dense, onClose }) => {

    const { formId, formField } = form;
    const currentValidation = validations.edit;

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    function agentPropertiesUpdated(a) {
        if (onAgentUpdated) onAgentUpdated(a);
    }

    function getEditorForm(c, b, a, f) {
        return <BookAgentPropertiesEditorForm cardroom={c} book={b} agent={a} dense={dense} formData={f} />
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving agent properties...", handleRetry)

    const [pendingAgent, setPendingAgent] = useState(null);

    const handleSubmit = (values, actions) => {
        var a = Object.assign({}, agent);

        a.name = values.agentName;

        if (!a.currency) a.currency = "USD";

        setPendingAgent(a);
    };

    const bookRequest = useFetchWithMsal({
        scopes: apiConfig.book.scopes.write,
    });

    const bookDataLoader = useBookDataLoader();

    useEffect(() => {
        if (pendingAgent) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving agent properties...")
            showProgress();

            bookDataLoader.entity.saveAgent(bookRequest, cardroom, book, pendingAgent, (ag) => {
                if (ag) {
                    if (!agent) agent = ag
                    else agent = Object.assign(agent, ag);
                }

                setPendingAgent(null);

                agentPropertiesUpdated(agent);

                progressIndicatorProps.close();
            }, (ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save agent properties, please try again...")
            });
        }

    }, [bookRequest.execute, pendingAgent, retryCounter]);

    return !progressIndicatorProps.visible ? <>

        <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
                return <Form id={formId} autoComplete="off">

                    <SoftBox p={2} >
                        <SoftBox >
                            <ProgressIndicator {...progressIndicatorProps} />

                            {progressIndicatorProps.visible ? <></> : <>

                                {getEditorForm(cardroom, book, agent, {
                                    values,
                                    touched,
                                    formField,
                                    errors,
                                    setFieldValue,
                                    setFieldError,
                                    setFieldTouched,
                                    validateForm,
                                    resetForm
                                })}                               

                                <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                                    <div></div>

                                    <SoftButton
                                        variant="gradient"
                                        color="dark"
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        save
                                    </SoftButton>
                                </SoftBox>
                            </>

                            }

                        </SoftBox>
                    </SoftBox>

                </Form>
            }}
        </Formik></> : <ProgressIndicator {...progressIndicatorProps} />;
};


export {
    BookAgentPropertiesEditor
}