import buyin from "./form";


const {
    formField: {
        type,
        approvalAction,
        purchaseOrderId,
        buyerAccount,
        sellerAccount,
        quantity,
        itemType,
        estimatedCost,
        actualCost,
        note,
        deliverTo
    },
} = buyin;

const initialValues = {
    [type.name]: "",
    [approvalAction.name]: "",
    [quantity.name]: 0,
    [purchaseOrderId.name]: "",
    [buyerAccount.name]: "",
    [sellerAccount.name]: "",
    [itemType.name]: "chips",
    [estimatedCost.name]: 0,
    [actualCost.name]: 0,
    [note.name]: "",
    [deliverTo.name]: ""
};

export default initialValues;
