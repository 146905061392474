import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';


import ParticipantCell from "components/Elements/DataTable/components/Cells/ParticipantCell";
import MoneyCell from "components/Elements/DataTable/components/Cells/MoneyCell";
import PhoneCell from "components/Elements/DataTable/components/Cells/PhoneCell";
import TextCell from "components/Elements/DataTable/components/Cells/TextCell";
import ButtonCell from "components/Elements/DataTable/components/Cells/ButtonCell";

import { useDialog } from "components/Elements/Dialog/common";
import { AddStaffTransactionDialog } from "layouts/Cardroom/Session/components/Dialogs";
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';


import DataTable from "components/Elements/DataTable";
import { StaffSessionTransactions } from 'layouts/Cardroom/Participants/Staff/components/StaffTransactions';
import { StaffEditorDialog } from 'layouts/Cardroom/Participants/Staff/components/StaffEditor';
import { useCardroomContext } from 'features';

function convertSessionPlayersToTable(staff, table, cardroom, session, onTransactionUpdatedHandler) {
    var rows = [];

    if (staff && staff.length > 0) {
        for (var i = 0; i < staff.length; i++) {
            let staffName = staff[i].name;
            let staffPhone = staff[i].phoneNumber;
            let staffHasPhoto = staff[i].hasPhoto || (staff[i].properties && staff[i].properties.hasPhoto)
            //let creditLimit = 0;
            let ptype = 0;


            if (staff[i].properties) {
                ptype = staff[i].properties.participantType;
                staffName = staff[i].properties.name ? staff[i].properties.name : staffName;
                staffPhone = staff[i].properties.phoneNumber ? staff[i].properties.phoneNumber : staffPhone;
                staffHasPhoto = staff[i].properties.hasPhoto ? staff[i].properties.hasPhoto : staffHasPhoto;
                //creditLimit = staff[i].properties.creditLimit;

                if (staff[i].properties.nickName) staffName += " (" + staff[i].properties.nickName + ")";
            }

            if (staff[i].type) {
                ptype = staff[i].type;
            }

            let staffImage = staffHasPhoto ? apiConfig.images.endpoint + "/player/" + staff[i].id + ".jpg" : staffName.substring(0, 1);

            let ptypeStr = ptype < 2 ? "" : "|";
            if ((ptype & 2) > 0) ptypeStr += "host|";
            if ((ptype & 4) > 0) ptypeStr += "dealer|";
            if ((ptype & 8) > 0) ptypeStr += "chef|";
            if ((ptype & 16) > 0) ptypeStr += "valet|";
            if ((ptype & 32) > 0) ptypeStr += "security|";
            if ((ptype & 64) > 0) ptypeStr += "other|";

            let row = {
                id: staff[i].id,
                name: [staffName, { image: staffImage }], // format name from name & nickname
                phone: staffPhone,
                hasImage: staffHasPhoto,
                balance: staff[i].totalBuyinAmount - staff[i].borrowedAmount,
                roles: ptypeStr,
                details: <StaffSessionTransactions staff={staff[i]} cardroom={cardroom} session={session} disableTableChrome={true} onTransactionUpdated={onTransactionUpdatedHandler} />,
                staff: staff[i]
            };
            rows.push(row);
        }
    }

    if (rows.length > 0) {
        rows.sort(function (a, b) {
            let aName = a.name && a.name[0] ? a.name[0].toLowerCase() : a.name;
            let bName = b.name && b.name[0] ? b.name[0].toLowerCase() : b.name;

            if (aName < bName) { return -1; }
            if (aName > bName) { return 1; }
            return 0;
        });
    }

    table.rows = rows;

    return table;
}
function mergeStaffRecords(sstaff, pstaff) {
    const map = {};
    const result = [];

    //it's possible for sstaff to contain duped staff that have different masks, i.e mask 4 (dealer) and mask 5 (dealer & player)
    //we should merge sstaff into pstaff & if dupes are found, & mask

    if (sstaff) {

        for (var i = 0; i < pstaff.length; i++) {
            map[pstaff[i].id] = pstaff[i];
        }

        if (pstaff) {
            const fmap = [];

            for (var i = 0; i < sstaff.length; i++) {
                let pp = map[sstaff[i].id];
                if (pp) {
                    const mr = Object.assign(pp, sstaff[i]); //merged record

                    if (!fmap[mr.id]) {
                        result.push(mr);
                        fmap[mr.id] = mr;
                    }
                    else {
                        fmap[mr.id].type |= mr.type;
                    }
                }
            }
        }

    }
    return result;
}
function GetSessionStaffTableSchema(addHandler, participantClickHandler, session, canTransact, maskCurrency) {
    const prefix = maskCurrency === true ? "" : "$";

    const schema = {
        columns: [
            {
                Header: "name", accessor: "name", Cell: ({ value: [name, data], row }) => (
                    <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} onClick={participantClickHandler} checked={false} participant={row && row.original && row.original.staff ? row.original.staff : null} />
                ),
            },
            { Header: "phone", accessor: "phone", Cell: ({ value, row }) => row && row.original && row.original.staff && row.original.staff.isAnonymous ? <TextCell value="Anonymous" /> : <PhoneCell value={value} obfuscate={true} /> },
            { Header: "roles", accessor: "roles", Cell: ({ value }) => <TextCell value={value} /> },
            { Header: "balance", accessor: "balance", Cell: ({ value }) => <MoneyCell value={value} prefix={prefix} /> }
        ],
        collapsibleDetailAccessor: "details",
        rows: []
    };

    if (session && (session.status === 2 || session.status === 3) && canTransact) {
        schema.columns.push({ Header: "action", accessor: "id", Cell: ({ value, row }) => <ButtonCell id={row && row.original ? row.original : value} onButtonClicked={addHandler} icon="add_box" color="secondary" /> });
    }

    return schema;
}

function GetEmptySchema() {
    return {
        columns: [],
        rows: []
    }
}
const SessionStaff = ({ open, close, cardroom, session, onStaffStateChanged }) => {

    const handleAddStaffTransactionClick = (item) => {
        setCurrentStaff(item.staff);
        openAddStaffTxDialog();
    };

    const handleParticipantClick = (staff) => {
        if (staff) {
            setCurrentStaff(staff);
            openStaffEditorDialog();
        }
    };

    const [currentStaff, setCurrentStaff] = useState(null);

    const [openAddStaffTxDialog, addStaffDialogTxProps] = useDialog();
    const [openStaffEditorDialog, openStaffEditorDialogProps] = useDialog();

    const [staffTable, setStaffTable] = useState(GetEmptySchema());
    const [staff, setStaff] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);
    function handleProgressRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading session staff...", handleProgressRetry)

    const [sessionStaff, setSessionStaff] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);

    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    const casinoReq = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read
    });

    const peopleReq = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read
    });

    function canCreateTransaction() {
        if (!context) return false;
        return context.isAllowed(actions.casino.session.transaction.create);
    }

    const refreshSessionStaffData = () => {

        setSessionStaff(null);
        setStaff(null);

        if (onStaffStateChanged) {
            onStaffStateChanged();
        }

    };
    //load player sessions
    useEffect(() => {
        if (!sessionStaff) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading session staff...")
            showProgress();

            //{casinoId:Guid}/sessions/{sessionId:Guid}/participants/{type:int?}
            casinoReq.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/participants/254").then((response) => {
                if (response) {

                    if (response.length === 0) {
                        progressIndicatorProps.close();
                    }

                    setSessionStaff(response);
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load session staff, please try again...")
            });
        }
    }, [casinoReq.execute, sessionStaff, retryCounter])


    useEffect(() => {
        if (!staff && sessionStaff && sessionStaff.length > 0) {

            let list = [];
            for (var i = 0; i < sessionStaff.length; i++) {
                list.push(sessionStaff[i].id);
            }

            if (list.length > 0) {
                peopleReq.execute("PUT", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants", list).then((response) => {
                    if (response) {
                        setStaff(response);
                        setStaffTable(convertSessionPlayersToTable(mergeStaffRecords(sessionStaff, response), GetSessionStaffTableSchema(handleAddStaffTransactionClick, handleParticipantClick, session, canCreateTransaction(), isCurrencyMaskingEnabled()), cardroom, session, refreshSessionStaffData));
                        progressIndicatorProps.close();
                    }
                }).catch((ex) => {
                    progressIndicatorProps.setMode("errorWithRetry");
                    progressIndicatorProps.setMessage("Unable to load session staff, please try again...")
                });
            }
        }
    }, [peopleReq.execute, sessionStaff, staff])


    return (<>
        <ProgressIndicator {...progressIndicatorProps} />
        <AddStaffTransactionDialog {...addStaffDialogTxProps} cardroom={cardroom} session={session} staff={currentStaff} onAdded={refreshSessionStaffData} />
        <StaffEditorDialog {...openStaffEditorDialogProps} cardroom={cardroom} staff={currentStaff} />
        {!progressIndicatorProps.visible ? <DataTable table={staffTable} canSearch entriesPerPage={{ defaultValue: 10, visible: true }} isHierarchical={true} /> : null}
    </>);
}


export default SessionStaff;