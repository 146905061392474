import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";


import { Grid, Icon, Switch } from "@mui/material";
import FormSwitch from "components/Elements/Forms/FormSwitch";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { apiConfig } from "config/apiConfig";
import { useCardroomContext } from "features";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { ErrorMessage, Form, Formik } from 'formik';

import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { ProgressIndicator } from 'components/Elements/ProgressIndicator';
import SoftButton from 'components/SoftButton';
import { SupportedCurrencyList } from 'components/Elements/CurrencyConverter/currency';
import { CardMembershipSharp, LogoutSharp } from '@mui/icons-material';
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';

import currencyAvatar from "assets/graphics/casino/currency_exchage_256.png"



const CurrencySelector = ({ value, placeholder, readonly, onSelectionChanged, mode }) => {

    const currencies = SupportedCurrencyList;

    const [currencyList, setCurrencyList] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const [selectedCurrency, setSellectedCurrency] = useState(null);

    function buildCurrencyList() {
        const list = [];

        let idx = 0;
        for (var prop in currencies) {
            if (currencies.hasOwnProperty(prop) && (typeof currencies[prop] !== 'function')) {
                const text = currencies[prop].symbol + " (" + currencies[prop].name + ")";
                list.push({
                    id: currencies[prop].name,
                    text: text
                });
                if (currencies[prop].name === value) {
                    setSellectedCurrency(text);
                    setCurrentIndex(idx);
                }
                idx++;
            }
        }

        return list;
    }

    useEffect(() => {
        if (!currencyList) {
            setCurrencyList(buildCurrencyList());
        }
    }, [currencyList]);


    function onCurrencyChanged(item, index) {

        setCurrentIndex(index);

        if (onSelectionChanged) onSelectionChanged(item);
    }

    return readonly ? <SoftTypography component="label" variant="caption" fontWeight="bold">{selectedCurrency}</SoftTypography> :
        <DataBoundSelect placeholder={placeholder} data={currencyList} valueName="id" textName="text" onChange={onCurrencyChanged} size="medium" selectedIndex={currentIndex} />;


}

const CurrencySettingsEditorForm = ({ cardroom, union, club, agency, formData, dense }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { defaultCurrency, supportedCurrencies } = formField;
    const {
        defaultCurrency: defaultCurrencyV,
        supportedCurrencies: supportedCurrenciesV
    } = values;

    const [currencyList, setCurrencyList] = useState(null);
    const currencies = SupportedCurrencyList;

    const [formDataInitialized, setFormDataInitialized] = useState(false);

    const [context, actions, features] = useCardroomContext(cardroom);

    function isCurencyConversionEnabled() {
        if (!context) return false;

        return context.isEnabled(features.currency.conversion);
    }

    function canManageCurrencies() {
        if (!context) return false;

        return context.isAllowed(actions.cardroom.currency.manage);
    }

    function canSetDefaultCurrency() {
        if (!context) return false;

        return context.isAllowed(actions.cardroom.currency.setDefault);
    }

    function onDefaultCurrencyChanged(currency) {
        setFieldValue(defaultCurrency.name, currency.item.id);
    }

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    function buildCurrencyList(room) {
        const list = [];

        //create a list of turned on currencies

        let idx = 0;
        for (var prop in currencies) {
            // if (typeof model[property] == 'function') 
            if (currencies.hasOwnProperty(prop) && (typeof currencies[prop] !== 'function')) {
                list.push({
                    id: currencies[prop].name,
                    text: currencies[prop].symbol + " (" + currencies[prop].name + ")",
                    mask: currencies[prop].mask,
                    selected: false //todo: change from cardroom settings map
                });

                if (isDefaultCurrency(currencies[prop])) {
                    cardroom.currencies |= currencies[prop].mask;
                }

                // if (currencies[prop].name === value) setCurrentIndex(idx);
                idx++;
            }
        }

        return list;
    }

    useEffect(() => {
        if (!formDataInitialized && cardroom && (union || club || agency)) {

            var entity = union || club || agency;

            var dc = currencies.findByName(entity.currency);
            if (dc) {
                cardroom.currencies |= dc.mask;
            }

            setFieldValue(defaultCurrency.name, entity.currency);
            setFieldValue(supportedCurrencies.name, cardroom.currencies);

            setCurrencyList(buildCurrencyList(cardroom));

            setFormDataInitialized(true);
        }
    }, [formDataInitialized, cardroom, union, club, agency]);


    useEffect(() => {
        if (formDataInitialized && defaultCurrencyV) {

            var dc = currencies.findByName(defaultCurrencyV);
            if (dc) {
                setFieldValue(supportedCurrencies.name, supportedCurrenciesV | dc.mask);
            }

            setCurrenciesChanged(currenciesChanged + 1);
        }

    }, [formDataInitialized, defaultCurrencyV, supportedCurrenciesV]);

    const [currenciesChanged, setCurrenciesChanged] = useState(0);


    function isDefaultCurrency(currency) {
        return (currency.id == defaultCurrencyV);
    }

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                {dense === true ? null :
                    <Grid item xs={12} sm={4} container justifyContent="center">
                        <SoftBox position="relative" height="max-content" mx="auto">
                            <SoftAvatar src={currencyAvatar} size="xxl" variant="rounded" bgColor="dark" />
                        </SoftBox>
                    </Grid>}
                <Grid item xs={12} sm={dense === true ? 12 : 8}>
                    <SoftBox mb={2} textAlign="left">

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {defaultCurrency.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                {formDataInitialized ?
                                    <CurrencySelector readonly={!canSetDefaultCurrency()} placeholder={defaultCurrency.placeholder} value={defaultCurrencyV} onSelectionChanged={onDefaultCurrencyChanged} /> : "Loading currencies..."}

                                {errors.defaultCurrency && touched.defaultCurrency ?
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={defaultCurrency.name} />
                                    </SoftTypography> : null}
                            </Grid>
                        </Grid>

                        <Grid container xs={12} mt={1.25}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {supportedCurrencies.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container xs={12}>
                                    {currenciesChanged > 0 && currencyList ? currencyList.map((c) => {

                                        return currenciesChanged > 0 ? <Grid item whiteSpace={"nowrap"} xs={6} md={4} lg={3}>
                                            <FormSwitch label={c.text}
                                                name={supportedCurrencies.name}
                                                value={supportedCurrenciesV}
                                                mask={c.mask}
                                                variant="bit"
                                                readOnly={!canSetDefaultCurrency() || isDefaultCurrency(c)}
                                                {...formData} />
                                        </Grid> : null;

                                    }) : null}
                                </Grid>
                            </Grid>
                        </Grid>

                    </SoftBox>
                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox >;

};

CurrencySettingsEditorForm.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object,
    club: PropTypes.object,
    agency: PropTypes.object,
    formData: PropTypes.object.isRequired,
    dense: PropTypes.bool
};


const CurrencySettingsEditor = ({ cardroom, union, club, agency, dense }) => {
    const { formId, formField } = form;
    const currentValidation = validations.editor;

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving currency settings...", handleRetry)

    const [pendingConfig, setPendingConfig] = useState(null);
    const [pendingDefaultCurrency, setPendingDefaultCurrency] = useState(null);

    const casinoRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.write,
    });

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.write,
    });

    const [context, actions, features] = useCardroomContext(cardroom);

    function canManageCurrencies() {
        if (!context) return false;

        return context.isAllowed(actions.cardroom.currency.manage);
    }

    function onCanceled() {
        //reset???

    }

    const handleSubmit = (values, actions) => {

        //alert("submitting");

        if (false === canManageCurrencies()) return;

        const config = {
            defaultCurrency: values.defaultCurrency,
            supportedCurrencies: values.supportedCurrencies
        };

        //if (config.defaultCurrency) {
        //config.supportedCurrencies.push(config.defaultCurrency);
        //}

        setPendingConfig(config);
    };


    useEffect(() => {
        if (pendingConfig) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving currency settings...")
            showProgress();

            //  [HttpPut("{casinoId:Guid}/currency/{currency:long}", Name = "SetCasinoCurrencies")]
            casinoRequest.execute("PUT", apiConfig.casino.endpoint + "/" + cardroom.id + "/currency/" + pendingConfig.supportedCurrencies).then((response) => {
                if (response) {
                    //raiseOnTransactionCreated();

                    if (response.status && response.errors) {
                        throw new Error(response.errors);
                    }

                    cardroom.currencies = response;
                }


                let entity = union || club || agency;
                if (entity && entity.currency != pendingConfig.defaultCurrency) {
                    setPendingDefaultCurrency(pendingConfig.defaultCurrency);
                }
                else {
                    progressIndicatorProps.close(); //dissmiss only if we're already done
                    //raiseOnClose(false);
                }

                setPendingConfig(null); //clear payload


            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save currency settings, please try again...")
            });
        }

    }, [pendingConfig, casinoRequest.execute, retryCounter]);

    useEffect(() => {

        if (pendingDefaultCurrency) {

            let url = null, payload = null;
            if (union) {
                //[HttpPut("{unionId:Guid}/casino/{casinoId:Guid}", Name = "SetUnionDefaults")]
                url = apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id;
                payload = {
                    id: union.id,
                    tokenUnitCost: union.tokenUnitCost,
                    chipUnitCost: union.chipUnitCost,
                    unionManagerFee: union.unionManagerFee,
                    primaryClubId: union.primaryClubId,
                    currency: pendingDefaultCurrency,
                    defaultClubFeeSchedule: union.defaultClubFeeSchedule
                };
            }
            else if (club) {
                //  [HttpPut("{clubId:Guid}/casino/{casinoId:Guid}/config", Name = "SetClubConfig")]
                url = apiConfig.club.endpoint + "/club/" + club.id + "/casino/" + cardroom.id + "/config";
                payload = {
                    clubId: club.id,
                    clubManagerFee: club.clubManagerFee,
                    currency: pendingDefaultCurrency
                };
            }
            else if (agency) {
                // [HttpPut("{agencyId:Guid}/casino/{casinoId:Guid}", Name = "SetAgencyDefaults")]
                url = apiConfig.club.endpoint + "/agency/" + agency.id + "/casino/" + cardroom.id;
                payload = {
                    id: agency.id,
                    currency: pendingDefaultCurrency
                };
            }

            if (url && payload) {

                progressIndicatorProps.setMode("wait");
                progressIndicatorProps.setMessage("Saving default currency...")

                clubRequest.execute("PUT", url, payload).then((response) => {
                    if (response) {
                        //raiseOnTransactionCreated();

                        if (response.status && response.errors) {
                            throw new Error(response.errors);
                        }

                        let entity = union || club || agency;
                        if (entity) {
                            entity.currency = payload.currency;
                        }
                    }

                    setPendingDefaultCurrency(null);
                    progressIndicatorProps.close();

                }).catch((e) => {
                    progressIndicatorProps.setMode("errorWithRetry");
                    progressIndicatorProps.setMessage("Unable to save default currency, please try again...")
                });

            }

        }

    }, [clubRequest.execute, pendingDefaultCurrency, retryCounter]);


    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <CurrencySettingsEditorForm cardroom={cardroom} club={club} agency={agency} union={union} dense={dense} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={onCanceled}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting || !canManageCurrencies()}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};

CurrencySettingsEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object,
    club: PropTypes.object,
    agency: PropTypes.object,
    dense: PropTypes.bool
};



export default CurrencySettingsEditor;