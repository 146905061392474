
import { useEffect, useState } from "react";

import { Grid, Icon, ListItemIcon, ListItemText, Switch } from "@mui/material";
import FormField from "components/Elements/Forms/FormField";
import { useProgressIndicator } from "components/Elements/ProgressIndicator";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Form, Formik, ErrorMessage } from "formik";

import { ProgressIndicator } from "components/Elements/ProgressIndicator";
import SoftButton from "components/SoftButton";



import agentAvatar from "assets/graphics/personas/bettor_pool_gpt.png";
import { useCardroomContext } from "features";

import useFetchWithMsal from "hooks/useFetchWithMsal";
import { apiConfig } from "config/apiConfig";

import form from "./schemas/form";
import validations from "./schemas/validations";
import initialValues from "./schemas/initialValues";
import AgentFeeSelector from "layouts/Club/components/AgentFees/Selector";


const BookAgentPlayerPoolPropertiesEditorForm = ({ cardroom, book, agent, readonly, playerPool, dense, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { playerPoolId, poolName, commissionScheduleId, makeupAmount } = formField;
    const {
        playerPoolId: playerPoolIdV,
        poolName: poolNameV,
        commissionScheduleId: commissionScheduleIdV,
        makeupAmount: makeupAmountV
    } = values;

    const emptyEntity = { id: "none", "name": "None" };

    const [formDataInitialized, setFormDataInitialized] = useState(false);
    const [poolId, setPoolId] = useState(playerPool && playerPool.id ? playerPool.id : null);

    const [allowMakeupUpdate, setAllowMakeupUpdate] = useState(false);
    function allowMakeupToggleChanged() {
        let newVal = !allowMakeupUpdate;

        setAllowMakeupUpdate(newVal);
        if (false === newVal) {
            setFieldValue(makeupAmount.name, playerPool ? playerPool.currentMakeupAmount : 0);
        }
    }

    useEffect(() => {
        if (!formDataInitialized) {

            if (playerPool && playerPool.id) {
                setFieldValue(playerPoolId.name, playerPool.id);
                setFieldValue(poolName.name, playerPool.name);
                setFieldValue(makeupAmount.name, playerPool.currentMakeupAmount);
                setFieldValue(commissionScheduleId.name, playerPool.commissionSchedule ? playerPool.commissionSchedule.id : emptyEntity.id);
            }
            else {
                setFieldValue(playerPoolId.name, "");
                setFieldValue(poolName.name, "");
                setFieldValue(makeupAmount.name, 0);
                setFieldValue(commissionScheduleId.name, emptyEntity.id);
            }

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    useEffect(() => {
        if (formDataInitialized) {
            setFormDataInitialized(false);
        }
    }, [poolId, playerPool]);

    function onCommissionScheduleChanged(schedule) {
        setFieldValue(commissionScheduleId.name, (schedule || emptyEntity).id);
    }

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                {dense === true ? null :
                    <Grid item xs={12} sm={4} container justifyContent="center">
                        <SoftBox position="relative" height="max-content" mx="auto">
                            <SoftAvatar src={agentAvatar} size="xxl" variant="rounded" bgColor="dark" />
                        </SoftBox>
                    </Grid>}

                <Grid item xs={12} sm={dense === true ? 12 : 8}>
                    <SoftBox mb={2} textAlign="left">

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        Pool Id
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography
                                    variant="caption"
                                    textTransform="capitalize">
                                    {playerPoolIdV}
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {poolName.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>

                            <Grid item xs={8}>

                                <FormField onFocus={e => e.currentTarget.select()}
                                    type={poolName.type}
                                    name={poolName.name}
                                    value={poolNameV}
                                    placeholder={poolName.placeholder}
                                    error={errors.poolName && touched.poolName}
                                    success={poolNameV && poolNameV.length > 0 && !errors.poolName}
                                />

                            </Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {commissionScheduleId.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>

                            <Grid item xs={8}>
                                <AgentFeeSelector cardroom={cardroom} book={book} value={commissionScheduleIdV} readonly={readonly}
                                    placeholder={commissionScheduleId.placeholder} emptyValue={emptyEntity}
                                    onSelectionChanged={onCommissionScheduleChanged} type="dropdown" />

                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    color="error">
                                    <ErrorMessage name={commissionScheduleId.name} />
                                </SoftTypography>
                            </Grid>
                        </Grid>


                        <Grid container xs={12} mt={-2}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={.75} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Update Package Makeup
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>

                            <Grid item xs={8}>
                                <SoftBox textAlign="left" >
                                    <SoftBox display="flex" py={0.5} mb={0.25}>
                                        <SoftBox mt={0.25}>
                                            <Switch checked={allowMakeupUpdate} onChange={allowMakeupToggleChanged} />
                                        </SoftBox>
                                    </SoftBox>
                                </SoftBox>

                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {makeupAmount.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>

                            <Grid item xs={8}>

                                <FormField onFocus={e => e.currentTarget.select()}
                                    disabled={!allowMakeupUpdate}
                                    type={makeupAmount.type}
                                    name={makeupAmount.name}
                                    value={makeupAmountV}
                                    placeholder={makeupAmount.placeholder}
                                    error={errors.makeupAmount && touched.makeupAmount}
                                    success={makeupAmountV && makeupAmountV.toString().length > 0 && !errors.makeupAmount}
                                />

                            </Grid>
                        </Grid>
                    </SoftBox>
                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox >;

}

const BookAgentPlayerPoolPropertiesEditor = ({ cardroom, book, agent, playerPool, onPoolUpdated, dense }) => {

    const { formId, formField } = form;
    const currentValidation = validations.edit;

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    function poolPropertiesUpdated(pool) {
        if (onPoolUpdated) onPoolUpdated(pool);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving package properties...", handleRetry)

    const [pendingPlayerPool, setPendingPlayerPool] = useState(null);

    const handleSubmit = (values, actions) => {
        var p = Object.assign({}, playerPool);

        p.name = values.poolName;

        p.commissionSchedule = {
            id: "00000000-0000-0000-0000-000000000000"
        };

        if (values.commissionScheduleId) {

            if (values.commissionScheduleId !== "none") {

                if (!p.commissionSchedule) p.commissionSchedule = {};
                p.commissionSchedule.id = values.commissionScheduleId;
            }
        }

        if (!p.id) p.id = "00000000-0000-0000-0000-000000000000";

        p.currentMakeupAmount = values.makeupAmount;

        p.agentId = agent.id;
        p.sportsBookId = book.id;

        setPendingPlayerPool(p);
    };

    const bookRequest = useFetchWithMsal({
        scopes: apiConfig.book.scopes.write,
    });

    useEffect(() => {
        if (pendingPlayerPool) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving package properties...")
            showProgress();

            // [HttpPost("{bookId:Guid}/casino/{casinoId:Guid}/agents/{agentId:Guid}/pools", Name = "SaveAgentPlayerPool")]
            bookRequest.execute("POST", apiConfig.book.endpoint + "/agent/" + book.id + "/casino/" + cardroom.id + "/agents/" + agent.id + "/pools", pendingPlayerPool).then((pool) => {
                setPendingPlayerPool(null);
                poolPropertiesUpdated(pool);
                progressIndicatorProps.close();

            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save package properties, please try again...")
            });

        }

    }, [bookRequest.execute, pendingPlayerPool, retryCounter]);


    return !progressIndicatorProps.visible ? <>

        <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
                return <Form id={formId} autoComplete="off">

                    <SoftBox p={2} >
                        <SoftBox >
                            <ProgressIndicator {...progressIndicatorProps} />

                            {progressIndicatorProps.visible ? <></> : <>

                                <BookAgentPlayerPoolPropertiesEditorForm cardroom={cardroom} book={book} agent={agent} playerPool={playerPool} dense={dense} formData={{
                                    values,
                                    touched,
                                    formField,
                                    errors,
                                    setFieldValue,
                                    setFieldError,
                                    setFieldTouched,
                                    validateForm,
                                    resetForm
                                }} />


                                <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                                    <div></div>

                                    <SoftButton
                                        variant="gradient"
                                        color="dark"
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        save
                                    </SoftButton>
                                </SoftBox>
                            </>

                            }

                        </SoftBox>
                    </SoftBox>

                </Form>
            }}
        </Formik></> : <ProgressIndicator {...progressIndicatorProps} />;

};


export default BookAgentPlayerPoolPropertiesEditor;