
const form = {
    formId: "sportsbook-player-form",
    formField: {
        playerId: {
            name: "playerId",
            type: "hidden",
            errorMsg: "Player id is required"
        }, 
        playerAppId: {
            name: "playerAppId",
            type: "text",
            label: "Player App Id",
            placeholder: "player app id",
            errorMsg: "Player app id is required.",
        },   
        validatedPlayerId:{
            name: "validatedPlayerId",
            type: "hidden"
        },
        playerName: {
            name: "playerName",
            type: "text",
            label: "Player Name",
            placeholder: "player name",
            errorMsg: "Player name is required.",
        },        
        playerNickName: {
            name: "playerNickName",
            type: "text",
            label: "Player Nickname",
            placeholder: "player nickname",
            errorMsg: "Player nickname is required.",
        },        
        agentId: {
            name: "agentId",
            type: "text",
            label: "Agent",
            placeholder: "select player agent",
            errorMsg: "Agent is required.",
        },
        agentPlayerPoolId: {
            name: "agentPlayerPoolId",
            type: "text",
            label: "Package",
            placeholder: "agent package",
            errorMsg: "Agent package is required.",
        },
        rebateScheduleId: {
            name: "rebateScheduleId",
            type: "text",
            label: "Rebate Schedule",
            placeholder: "rebate schedule",
            errorMsg: "Rebate schedule pool is required.",
        },
        makeupAmount: {
            name: "makeupAmount",
            type: "number",
            label: "Makeup Amount",
            placeholder: "makeup amount",
            errorMsg: "Makeup amount is required and must be >= 0.",
        },
        referrerId: {
            name: "referrerId",
            type: "text",
            label: "Referrer",
            placeholder: "referrer",
            errorMsg: "referrer is required.",
        },        
        referredId: {
            name: "referredId",
            type: "text",
            label: "Referred",
            placeholder: "referred",
            errorMsg: "referred is required.",
        },        
        referralScheduleId: {
            name: "referralScheduleId",
            type: "text",
            label: "Referral Schedule",
            placeholder: "select referral schedule",
            errorMsg: "Referral schedule is required.",
        },
        referralScheduleName: {
            name: "referralScheduleName",
            type: "text",
            label: "Referral Schedule",
            placeholder: "select referral schedule",
            errorMsg: "Referral schedule is required.",
        },
        referrerType: {
            name: "referrerType",
            type: "hidden"
        },
        referredType: {
            name: "referredType",
            type: "hidden"
        }
    },

};

export default form;
