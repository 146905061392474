import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Grid } from '@mui/material';

import { ErrorMessage } from "formik";
import FormField from "components/Elements/Forms/FormField";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import expensesAvatar from "assets/graphics/casino/expenses_256.png";
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';
import { useCardroomContext } from 'features';

const AccountTransferForm = ({ cardroom, cardroomAccount, accounts, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { type, sourceAccount, targetAccount, participant, amount, expenseTxType, accountBalance, note } = formField;
    const {
        type: typeV,
        amount: amountV,
        accountBalance: accountBalanceV,
        note: noteV,
        sourceAccount: sourceAccountV,
        targetAccount: targetAccountV
    } = values;


    const [formDataInitialized, setFormDataInitialized] = useState(false);

    const [fromAccountHolderName, setFromAccountHolderName] = useState(null);
    const [toAccountHolderName, setToAccountHolderName] = useState(null);

    const [targetAccountList, setTargetAccountList] = useState(null);
    const [sourceAccountList, setSourceAccountList] = useState(null);

    const [selectedTargetAccountIndex, setSelectedTargetAccountIndex] = useState(-1);
    const [selectedSourceAccountIndex, setSelectedSourceAccountIndex] = useState(-1);

    const [context, actions, features] = useCardroomContext(cardroom);
    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }
    function onTargetAccountSelectionChanged({ item, index }) {
        if (item && item.account) {
            setFieldValue(targetAccount.name, item.account.id);
            setSelectedTargetAccountIndex(index);
            setToAccountHolderName(item.displayName);

            //setFieldValue(accountBalance.name, item.account.accountsReceivable - item.account.accountsPayable);

            setSourceAccountList(filterParticipants(accounts, item.account, false));
        }
    }

    function onSourceAccountSelectionChanged({ item, index }) {
        if (item && item.account) {
            setFieldValue(sourceAccount.name, item.account.id);
            setSelectedSourceAccountIndex(index);
            setFromAccountHolderName(item.displayName);

            //setFieldValue(accountBalance.name, item.account.accountsReceivable - item.account.accountsPayable);
            setTargetAccountList(filterParticipants(accounts, item.account, true));
        }
    }

    function onAmountChanging(value) {
        if (!formDataInitialized) return;
        //if (value <= markerBalanceV && markerBalanceV > 0 && methodV != "credit") {
        //    setFieldValue(method.name, "credit");
        //}
    }


    function filterParticipants(ds, excludeAccount, toAccounts) {
        if (!ds) return ds;
        const result = [];

        let cardRoomAccountAdded = false;

        let mask = 2 | 1024;
        if (toAccounts) mask |= 4096
        else mask |= 2048;

        for (var i = 0; i < ds.length; i++) {

            if (excludeAccount && excludeAccount.id === ds[i].id) continue;

            //we only want accounts that have a credit, as we can only pay those that we owe $ to...
            if ((ds[i].accountType & mask) > 0) {


                result.push({
                    id: ds[i].accountHolderId,
                    account: ds[i],
                    name: ds[i].name,
                    displayName: ds[i].accountType === 2 ? cardroom.name : ds[i].name
                });

                if (ds[i].accountType === 2 && ds[i].accountHolderId === cardroom.id) {
                    cardRoomAccountAdded = true;
                }
            }
        }

        let isExcludedAccountCardroomAccount = (excludeAccount && excludeAccount.accountType === 2 && excludeAccount.accountHolderId === cardroom.id);
        if (!cardRoomAccountAdded && !isExcludedAccountCardroomAccount) result.push({
            id: cardroomAccount.accountHolderId,
            account: cardroomAccount,
            name: cardroomAccount.name,
            displayName: cardroom.name
        });

        if (result.length > 0) {
            //sort by displayName

            result.sort(function (a, b) {
                if (a.displayName < b.displayName) { return -1; }
                if (a.displayName > b.displayName) { return 1; }
                return 0;
            });
        }

        return result;
    }

    function formatAmount(amount) {
        const prefix = isCurrencyMaskingEnabled() === true ? "" : "$";

        var delimiter = ","; // replace comma if desired
        var a = amount.toString().split('.', 2)
        var d = a[1];
        var i = parseInt(a[0]);
        if (isNaN(i)) { return ''; }
        var minus = '';
        if (i < 0) { minus = '-'; }
        i = Math.abs(i);
        var n = new String(i);
        var a = [];
        while (n.length > 3) {
            var nn = n.substring(n.length - 3);
            a.unshift(nn);
            n = n.substring(0, n.length - 3);
        }
        if (n.length > 0) { a.unshift(n); }
        n = a.join(delimiter);
        if (!d || d.length < 1) { amount = n; }
        else { amount = n + '.' + d; }
        amount = minus + prefix + amount;
        return amount;
    }

    useEffect(() => {
        if (!formDataInitialized) {

            setFieldValue(type.name, "transfer");

            setFieldValue(amount.name, 0);

            setFieldValue(sourceAccount.name, ""); //cardroomAccount.id)
           // setFromAccountHolderName(cardroom.name);
            setFieldValue(targetAccount.name, "");
            setFieldValue(note.name, "");

            setTargetAccountList(filterParticipants(accounts, null, true));
            setSourceAccountList(filterParticipants(accounts, null, false));

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={expensesAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        From Account
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <DataBoundSelect placeholder="transfer from" valueName="id" textName="displayName"
                                    data={sourceAccountList} selectedIndex={selectedSourceAccountIndex} onChange={onSourceAccountSelectionChanged} />

                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    color="error">
                                    <ErrorMessage name={sourceAccount.name} />
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        To Account
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <DataBoundSelect placeholder="transfer to" valueName="id" textName="displayName"
                                    data={targetAccountList} selectedIndex={selectedTargetAccountIndex} onChange={onTargetAccountSelectionChanged} />

                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    color="error">
                                    <ErrorMessage name={targetAccount.name} />
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        {targetAccountV ?
                            <>
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                {amount.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormField onFocus={e => e.currentTarget.select()} autoFocus
                                            type={amount.type}
                                            name={amount.name}
                                            value={amountV}
                                            validate={onAmountChanging}
                                            placeholder={amount.placeholder}
                                            error={errors.amount && touched.amount}
                                            success={amountV && amountV.toString().length > 0 && !errors.amount}
                                        /></Grid>
                                </Grid>

                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize">
                                                {note.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormField onFocus={e => e.currentTarget.select()}
                                            type={note.type}
                                            name={note.name}
                                            value={noteV}
                                            placeholder={note.placeholder}
                                            error={errors.note && touched.note}
                                            success={noteV && noteV.length > 0 && !errors.note}
                                        />
                                    </Grid>
                                </Grid>
                            </> : null}


                        <Grid container xs={12}>
                            <Grid item xs={12} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        color="info"
                                    >
                                        {fromAccountHolderName} will transfer {formatAmount(amountV)} to account {toAccountHolderName}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                        </Grid>

                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox>;
};


export default AccountTransferForm;