
import { useEffect, useState } from "react";

import { ProgressIndicator } from "components/Elements/ProgressIndicator";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { apiConfig } from "config/apiConfig";
import DataBoundSelect from "components/Elements/DataBound/Dropdown";
import DataBoundList from "components/Elements/DataBound/List";
import { useBookDataLoader } from "layouts/Book/components";
import { useProgressIndicator } from "components/Elements/ProgressIndicator";
import SoftTypography from "components/SoftTypography";
import { useNotificationDataLoader } from "..";

const NotificationGroupSelector = ({ cardroom, value, readonly, placeholder, emptyValue, onSelectionChanged, type, onBuildListItem, reload, maxHeight }) => {

    const [notificationGroups, setNotificationGroups] = useState(cardroom && cardroom.notificationGroups ? cardroom.notificationGroups : null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "retrieving notification groups...", handleRetry)


    const [availableOptions, setAvailableOptions] = useState(null);

    const [selectedValue, setSelectedValue] = useState(value ? value : emptyValue);
    const [selectedIndex, setSelectedIndex] = useState(null);

    function getSelectedIndex(dataSource) {
        if (!dataSource) dataSource = availableOptions; // getDataSource();

        if (!dataSource) return -1;
        if (!selectedValue) return -1;

        for (var i = 0; i < dataSource.length; i++) {
            if (dataSource[i].id == selectedValue.id || dataSource[i].id == selectedValue) {
                return i;
            }
        }

        return -1;
    }

    function findValueInDataSource(v) {
        let dataSource = getDataSource();

        if (!dataSource) return null;
        if (!v) return null;

        for (var i = 0; i < dataSource.length; i++) {
            if (dataSource[i].id == v.id || dataSource[i].id == v) {
                return dataSource[i];
            }
        }

        return -1;
    }

    function getDataSource() {

        if (!notificationGroups) return null;

        let rows = [...notificationGroups];

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });
        }

        if (emptyValue) {
            rows.unshift(emptyValue);
        }

        return rows;
    }

    function handleNotificationGroupChange({ item, index }) {
        setSelectedIndex(null);
        setSelectedValue(item);

        if (onSelectionChanged) {
            onSelectionChanged(item);
        }
    }


    const notificationDataLoader = useNotificationDataLoader();

    const notifyRequest = useFetchWithMsal({
        scopes: apiConfig.notification.scopes.read,
    });


    useEffect(() => {
        if (reload === true) {
            setNotificationGroups(null);
            setSelectedIndex(-1);
        }
    }, [reload]);

    useEffect(() => {
        if (!notificationGroups && cardroom) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("retrieving notification groups...")
            showProgress();


            notificationDataLoader.entity.loadNotificationGroups(notifyRequest, cardroom, false, (groups) => {
                if (groups) {
                    if (groups.status && groups.errors) {
                        throw new Error(groups.errors);
                    }

                    //add to cardroom.notificationGroups???
                    setNotificationGroups(groups);
                }
                progressIndicatorProps.close();
            }, (err) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to retrieve notification groups, please try again...")
            });


        }

    }, [notifyRequest.execute, notificationGroups, cardroom, retryCounter]);

    useEffect(() => {

        if (notificationGroups) {

            const data = getDataSource();
            setAvailableOptions(data);

            if (selectedValue && (!selectedIndex)) {
                var idx = getSelectedIndex(data);
                setSelectedIndex(idx);

                if (onSelectionChanged && idx >= 0) {
                    onSelectionChanged(data[idx]);
                }
            }
        }

    }, [notificationGroups, selectedIndex, selectedValue]);

    useEffect(() => {
        if (notificationGroups && value) {
            //find in datasource
            //handleAgentChange(findValueInDataSource(value));
            // setSelectedIndex(null); --this needs to be done sometimes
            setSelectedValue(findValueInDataSource(value));
        }

    }, [notificationGroups, value]);

    return <>
        <ProgressIndicator  {...progressIndicatorProps} />
        {!progressIndicatorProps.visible ? <>
            {readonly ?
                <SoftTypography
                    variant="caption"
                    fontWeight="regular"
                    color="dark"
                > {selectedValue ? selectedValue.name : "[None]"}
                </SoftTypography> : <>{availableOptions ?
                    <>
                        {type === "dropdown" ?
                            <DataBoundSelect placeholder={placeholder} data={availableOptions} valueName="id" textName="name" onChange={handleNotificationGroupChange} size="medium" selectedIndex={selectedIndex} /> : null}
                        {type === "list" ? <DataBoundList data={availableOptions} valueName="id" textName="name" onChange={handleNotificationGroupChange} selectedIndex={selectedIndex} onBuildListItem={onBuildListItem} maxHeight={maxHeight} /> : null}
                    </>
                    : null}
                </>
            }</> : null}
    </>;


};


export default NotificationGroupSelector;