
import React from 'react';
import PropTypes from "prop-types";
import SoftTypography from 'components/SoftTypography';
import { Card, DialogActions, DialogContent, DialogTitle, Divider, Grid, Icon, Link, Tooltip, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { BootstrapDialog } from '../Dialog/common';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import TelegramIcon from "@mui/icons-material/Telegram";

const FeatureRequiresPremiumSubDialog = ({ open, close, service }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xxl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Premium Service Required
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox m={1}>
                <Grid container spacing={1} xs={12}>
                    <Grid item xs={12}>
                        <PremiumServiceSubscriptionRequired service={service} />
                    </Grid>
                </Grid>
            </SoftBox>
        </DialogContent>
        <DialogActions>
            <SoftButton onClick={close} variant="outlined" color="secondary">
                close
            </SoftButton>
        </DialogActions>
    </BootstrapDialog >
};

const ReportValueRedacted = ({ }) => {
    return <Tooltip title="Premium subscription required" placement="top">
        <SoftTypography variant="h6" color="transparent" fontWeight="medium" textTransform="capitalize" style={{ color: "transparent", textShadow: "0 0 8px #000" }}>
            REDACTED
        </SoftTypography>
    </Tooltip>
}

const PremiumServiceSubscriptionRequired = ({ service }) => {
    return <Card sx={{ height: 375 }} p={1}>
        <Grid container xs={12} justifyContent="center" alignItems="center">
            <Grid item xs={12} textAlign={"center"}>

                <SoftTypography
                    component="h2"
                    color="info">
                    Premium subscription is required to access {service}
                </SoftTypography>
                <Divider />
                <SoftTypography
                    component="label"
                    variant="caption"
                    color="text" justifyContent="center" display="flex">
                    <a href="https://web.telegram.org/k/#@GamboolerSupport" target="_blank" rel="noopener" >
                        <SoftTypography
                            component="label"
                            variant="caption"
                            color="text">
                            <Link component="button">
                                Please contact your sales rep or reach out to @GamboolerSupport on <TelegramIcon /> Telegram
                            </Link>
                        </SoftTypography>
                    </a>
                </SoftTypography>

            </Grid>

        </Grid>
    </Card>
};

PremiumServiceSubscriptionRequired.propTypes = {
    service: PropTypes.string.isRequired
};

export default PremiumServiceSubscriptionRequired;

export { ReportValueRedacted, FeatureRequiresPremiumSubDialog };