import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { apiConfig } from 'config/apiConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';

import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";

import { Formik, Form } from "formik";
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import StaffPayoutForm from './PayoutForm';
import DealerDownForm from './DealerDownForm';
import RecordStaffTipsForm from './RecordStaffTipsForm';
import DefaultTransactionForm from './DefaultTransactionForm';


function getFormContent(type, cardroom, session, staff, formData) {
    switch (type) {
        case "down":
            return <DealerDownForm cardroom={cardroom} session={session} staff={staff} formData={formData} />;
        case "payout":
            return <StaffPayoutForm cardroom={cardroom} session={session} staff={staff} formData={formData} />;
        case "tips":
            return <RecordStaffTipsForm cardroom={cardroom} session={session} staff={staff} formData={formData} />;
        default:
            return <DefaultTransactionForm cardroom={cardroom} session={session} staff={staff} formData={formData} />
    }

}


const StaffTransactionForm = ({ type, cardroom, session, staff, onClose, onTransactionCreated }) => {

    const { formId, formField } = form;
    const currentValidation = validations[type];

    const [transactionSavePayload, setTransactionSavePayload] = useState(null);
    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving transaction...", handleRetry)

    const handleSubmit = (values, actions) => {

        function getPaymentType(method) {
            if (method === "cash") return 1;
            if (method === "electronic") return 2;
            if (method === "credit") return 3;
            return 0;
        }

        let list = [];

        if (values.type === "down") {
            //build down transactions, there should be 2, 1 for rake, 1 for tips

            //rake
            list.push({
                sessionId: session.id,
                paymentType: getPaymentType("credit"),
                type: 5,
                status: 2, //for cashouts, we should verify if cash or marker, pending if ewallt?
                amount: values.amount,
                signatureRequired: false,
                participantId: cardroom.id,
                particpantType: 32768, //house
                note: "Table " + values.tableNo
            });

            //tip
            if (values.tipAmount > 0) {
                list.push({
                    sessionId: session.id,
                    paymentType: getPaymentType("credit"),
                    type: 9, //tip
                    status: 2, //for cashouts, we should verify if cash or marker, pending if ewallt?
                    amount: values.tipAmount, // - values.taxAmount,
                    participantId: staff.id,
                    signatureRequired: false,
                    particpantType: 4, //dealer
                    note: "Table " + values.tableNo
                });

            }
        }
        else if (values.type === "tips") {
            //tip
            if (values.tipAmount > 0) {
                list.push({
                    sessionId: session.id,
                    paymentType: getPaymentType("credit"),
                    type: 9, //tip record
                    status: 2, //for cashouts, we should verify if cash or marker, pending if ewallt?
                    amount: values.tipAmount, // - values.taxAmount,
                    participantId: staff.id,
                    particpantType: staff.type & ~1, //remove player from mask, in case staff is also player
                    signatureRequired: false,
                    note: values.note
                });
            }
        }
        else if (values.type === "payout") {
            if (values.amount > 0 && values.markerBalance < 0) {

                if (values.taxAmount > 0) {
                    list.push({
                        sessionId: session.id,
                        paymentType: getPaymentType("credit"),
                        type: 4, //withholding
                        status: 2, //for cashouts, we should verify if cash or marker, pending if ewallt?
                        amount: values.taxAmount,
                        participantId: staff.id,
                        signatureRequired: false,
                        particpantType: staff.type & ~1, //dealer //remove player from mask, in case staff is also player
                        note: "Tax on " + values.taxableAmount + " tip"
                    });
                }

                list.push({
                    sessionId: session.id,
                    paymentType: getPaymentType(values.method),
                    type: 8, //balance xfer
                    status: 2, //verified
                    amount: parseFloat(values.amount) - parseFloat(values.taxAmount),
                    signatureRequired: false,
                    participantId: staff.id,
                    particpantType: staff.type & ~1, //remove player from mask, in case staff is also player
                    ePaymentVendor: values.provider === "other" ? values.providerOther : values.provider,
                    ePaymentVendorReferenceNo: values.providerRef ? values.providerRef : null,
                    ePaymentVendorReferenceImage: values.providerRefImage ? values.providerRefImage : null,
                    note: values.note
                });
            }
        }


        if (list.length > 0) {
            setTransactionSavePayload(list);
        }

    };

    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    function raiseOnTransactionCreated() {
        if (onTransactionCreated) {
            onTransactionCreated();
        }
    }

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.write,
    });

    useEffect(() => {

        if (transactionSavePayload) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving transaction...")
            showProgress();

            //[HttpPost("{casinoId:Guid}/sessions/{sessionId:Guid}/transactions", Name = "CreateSessionTransaction")]
            execute("POST", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/transactions", transactionSavePayload).then((response) => {
                if (response) {
                    raiseOnTransactionCreated();
                }
                //clear payload and close dialog...
                setTransactionSavePayload(null);
                progressIndicatorProps.close();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save transaction, please try again...")
            });

        }

    }, [execute, transactionSavePayload, retryCounter]);

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            {getFormContent(type, cardroom, session, staff, {
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            })}

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};

StaffTransactionForm.defaultProps = {
    type: "cashout",
};

StaffTransactionForm.propTypes = {
    type: PropTypes.oneOf([
        "cashout",
        "promo",
        "expense"
    ]).isRequired,
    cardroom: PropTypes.object //.isRequired
};

export default StaffTransactionForm;