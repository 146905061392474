import { useTheme } from "@emotion/react";
import { CardContent, DialogActions, DialogContent, DialogTitle, Divider, Grid, Icon, Paper, Tooltip, useMediaQuery } from "@mui/material";
import { BootstrapDialog } from "../Dialog/common";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { Card } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import InboxFolderItem from "./InboxFolderItem";
import SoftAlert from "components/SoftAlert";

const MessageItem = ({ cardroom, message, onRead, onUnRead, onSnoozed, onDeleted }) => {

    function getBackgroundColor(msg) {
        if (!msg) return "info";

        switch (msg.type) {
            case 1: return "info";
            case 2: return "warning";
            case 3: return "error";
            case 4: return "success";
        }

        return "secondary";
    }

    function getIcon(msg) {
        if (!msg) return "mail";

        switch (msg.type) {
            case 1: return "info";
            case 2: return "warning_amber";
            case 3: return "error_outline";
            case 4: return "check_circle_outline";
        }

        return "mail";
    }

    function getPriority(msg) {
        if (!msg) return "normal priority";

        switch (msg.priority) {
            case 1: return "low priority";
            case 2: return "normal priority";
            case 3: return "high priority";
            case 4: return "urgent priority";
        }

        return "normal priority";
    }

    function getPriorityColor(msg) {
        if (!msg) return "dark";

        switch (msg.priority) {
            case 1: return "primary";
            case 2: return "secondary";
            case 3: return "warning";
            case 4: return "error";
        }

        return "dark";
    }

    function snooze() {
        if (onSnoozed)
            onSnoozed(message);
    }
    function markAsRead() {
        if (onRead) onRead(message);
    }
    function markAsDeleted() {
        if (onDeleted) onDeleted(message);
    }
    function markAsUnRead() {
        if (onUnRead) onUnRead(message);
    }
    return <Paper fullWidth elevation={3}>

        <SoftBox fullWidth p={.75}>
            <SoftBox bgColor={getBackgroundColor(message)} textGradient={true} display="flex" justifyContent="space-between" alignItems="center" fullWidth p={0.5}>

                <SoftBox style={{ whiteSpace: "nowrap" }} display='flex' alignItems='center' justifyContent='center'>
                    <Icon color={"dark"} fontSize="large">{getIcon(message)}</Icon>
                    <SoftTypography component="label" variant="caption" fontWeight="bold" textTransform="capitalize" color="text">&nbsp;{message.title}</SoftTypography>
                </SoftBox>

                <SoftBox style={{ whiteSpace: "nowrap" }}>


                    {/*
                    <Tooltip title={getPriority(message)} placement="top" display="inline">
                        <SoftBox display="inline-flex" alignItems='center' justifyContent='center' pr={1.5} bgColor="light">
                            <Icon fontSize="small" color={getPriorityColor(message)}>emoji_flags</Icon>
                        </SoftBox></Tooltip>
*/}
                    {message.status !== 1 ? <>
                        <Tooltip title="mark as unread" placement="top">
                            <SoftButton iconOnly variant="outlined" color="dark"
                                onClick={markAsUnRead}>
                                <Icon >
                                    visibility
                                </Icon>
                            </SoftButton></Tooltip>&nbsp;</> : null}
                    {message.status !== 2 ? <><Tooltip title="mark as read" placement="top">
                        <SoftButton iconOnly variant="outlined" color="dark"
                            onClick={markAsRead}>
                            <Icon >
                                visibility_off
                            </Icon>
                        </SoftButton></Tooltip>&nbsp;</> : null}
                    {message.status !== 4 ? <><Tooltip title="mark as follow up" placement="top">
                        <SoftButton iconOnly variant="outlined" color="dark" onClick={snooze}>
                            <Icon>
                                watch_later
                            </Icon>
                        </SoftButton></Tooltip>&nbsp;</> : null}
                    {message.status !== 8 ? <><Tooltip title="mark as deleted" placement="top">
                        <SoftButton iconOnly variant="outlined" color="dark" onClick={markAsDeleted}>
                            <Icon>
                                delete
                            </Icon>
                        </SoftButton></Tooltip></> : null}

                </SoftBox>

            </SoftBox>

            <SoftTypography component="label" variant="caption" color={"text"}>{message.body}</SoftTypography>

        </SoftBox >
    </Paper >
};

const Inbox = ({ cardroom, messages, onRead, onUnRead, onSnoozed, onDeleted }) => {

    const [unreadMessages, setUnreadMessages] = useState([]);
    const [readMessages, setReadMessages] = useState([]);
    const [snoozedMessages, setSnoozedMessages] = useState([]);
    const [deletedMessages, setDeletedMessages] = useState([]);


    const [isUnreadSelected, setIsUnreadSelected] = useState(true);
    const [isReadSelected, setIsReadSelected] = useState(false);
    const [isSnoozedSelected, setIsSnoozedSelected] = useState(false);
    const [isDeletedSelected, setIsDeletedSelected] = useState(false);

    const [selectedFolder, setSelectedFolder] = useState(null);

    useEffect(() => {
        if (messages) {
            //const unread = [], read = [], snoozed = [], deleted = [];

            unreadMessages.splice(0, unreadMessages.length);
            readMessages.splice(0, readMessages.length);
            snoozedMessages.splice(0, snoozedMessages.length);
            deletedMessages.splice(0, deletedMessages.length);

            for (var i = 0; i < messages.length; i++) {

                let container = null;

                switch (messages[i].status) {
                    case 1:
                        container = unreadMessages;
                        break;
                    case 2:
                        container = readMessages;
                        break;
                    case 4:
                        container = snoozedMessages;
                        break;
                    case 8:
                        container = deletedMessages;
                        break;
                }

                if (container) {
                    container.push(messages[i]);
                }
            }
            //   setUnreadMessages(unread);
            //   setReadMessages(read);
            //   setSnoozedMessages(snoozed);
            //   setDeletedMessages(deleted);

            if (isUnreadSelected) { updateSelection(unreadMessages, setIsUnreadSelected); }
            else if (isReadSelected) { updateSelection(readMessages, setIsReadSelected); }
            else if (isSnoozedSelected) { updateSelection(snoozedMessages, setIsSnoozedSelected); }
            else if (isDeletedSelected) { updateSelection(deletedMessages, setIsDeletedSelected); }

            // updateSelection(unreadMessages, setIsUnreadSelected); //changes on timer
        }

    }, [messages]);

    function onUnreadClicked() {
        updateSelection(unreadMessages, setIsUnreadSelected);
    }
    function onReadClicked() {
        updateSelection(readMessages, setIsReadSelected);
    }
    function onSnoozedClicked() {
        updateSelection(snoozedMessages, setIsSnoozedSelected);
    }
    function onDeletedClicked() {
        updateSelection(deletedMessages, setIsDeletedSelected);
    }

    function updateSelection(folder, isSelectedSetter) {

        setIsUnreadSelected(false);
        setIsReadSelected(false);
        setIsSnoozedSelected(false);
        setIsDeletedSelected(false);

        if (isSelectedSetter) isSelectedSetter(true);

        setSelectedFolder(folder);
    }

    function onReadRequested(msg) {
        moveMessageToTargetContainer(readMessages, msg);

        if (onRead) onRead(msg);
    }
    function onUnReadRequested(msg) {
        moveMessageToTargetContainer(unreadMessages, msg);

        if (onUnRead) onUnRead(msg);
    }
    function onSnoozeRequested(msg) {
        moveMessageToTargetContainer(snoozedMessages, msg);

        if (onSnoozeRequested) onSnoozed(msg);
    }
    function onDeleteRequested(msg) {
        moveMessageToTargetContainer(deletedMessages, msg);

        if (onDeleted) onDeleted(msg);
    }

    function moveMessageToTargetContainer(targetContainer, msg) {
        if (!msg || !targetContainer) return;
        let srcContainer = null;

        switch (msg.status) {
            case 1:
                srcContainer = unreadMessages;
                break;
            case 2:
                srcContainer = readMessages;
                break;
            case 4:
                srcContainer = snoozedMessages;
                break;
            case 8:
                srcContainer = deletedMessages;
                break;
        }

        if (srcContainer && srcContainer != targetContainer) {

            const idx = srcContainer.indexOf(msg);
            if (idx > -1) {
                srcContainer.splice(idx, 1);
            }

            targetContainer.push(msg);
        }
    }

    return <Grid container xs={12}>
        <Grid item xs={12} sm={3} md={2}>
            <Grid container xs={12}>
                <Grid item xs={6} sm={12}>
                    {unreadMessages && unreadMessages.length >= 0 ?
                        <InboxFolderItem color="secondary" onClick={onUnreadClicked} selected={isUnreadSelected}
                            image={<Icon fontSize="small" className="text-white">mark_as_unread</Icon>}
                            title={"Unread"}
                            messageCount={unreadMessages.length} /> : null}
                </Grid>
                <Grid item xs={6} sm={12}>
                    {snoozedMessages && snoozedMessages.length >= 0 ?
                        <InboxFolderItem color="secondary" onClick={onSnoozedClicked} selected={isSnoozedSelected}
                            image={<Icon fontSize="small" className="text-white">snooze</Icon>}
                            title={"Snoozed"}
                            messageCount={snoozedMessages.length} /> : null}
                </Grid>
                <Grid item xs={6} sm={12}>
                    {readMessages && readMessages.length >= 0 ?
                        <InboxFolderItem color="secondary" onClick={onReadClicked} selected={isReadSelected}
                            image={<Icon fontSize="small" className="text-white">work_history</Icon>}
                            title={"Read"}
                            messageCount={readMessages.length} /> : null}
                </Grid>
                <Grid item xs={6} sm={12}>
                    {deletedMessages && deletedMessages.length >= 0 ?
                        <InboxFolderItem color="secondary" onClick={onDeletedClicked} selected={isDeletedSelected}
                            image={<Icon fontSize="small" className="text-white">delete</Icon>}
                            title={"Deleted"}
                            messageCount={deletedMessages.length} /> : null}
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
            <Card fullWidth>
                {selectedFolder ?
                    <CardContent>
                        {selectedFolder.map((m) => {
                            return <><MessageItem cardroom={cardroom} message={m} onRead={onReadRequested} onUnRead={onUnReadRequested} onSnoozed={onSnoozeRequested} onDeleted={onDeleteRequested} /><Divider /></>
                        })}
                    </CardContent> : null}
            </Card>
        </Grid>
    </Grid>
        ;
}

const UserInboxDialog = ({ open, close, cardroom, messages, onRead, onUnRead, onSnoozed, onDeleted }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xxl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Inbox
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox m={1}>
                <Grid container spacing={1} >
                    <Inbox cardroom={cardroom} messages={messages} onRead={onRead} onUnRead={onUnRead} onSnoozed={onSnoozed} onDeleted={onDeleted} />
                </Grid>
            </SoftBox>
        </DialogContent>
        <DialogActions>
            <SoftButton onClick={close} variant="outlined" color="secondary">
                close
            </SoftButton>
        </DialogActions>
    </BootstrapDialog >
};


export default UserInboxDialog;