
import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

// @mui material components
import Grid from "@mui/material/Grid";
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import DataTable from 'components/Elements/DataTable';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';
import PhoneCell from 'components/Elements/DataTable/components/Cells/PhoneCell';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import defaultCasino from "assets/graphics/logos/default-casino.png";
import DateCell from 'components/Elements/DataTable/components/Cells/DateCell';
import { useCardroomContext } from 'features';

const ExpensesWorksheet = ({ cardroom, session, onClose }) => {


    function convertExpensesToTable(expenses, participants, table, cardroom, session) {
        function getParticipantDisplayName(p) {
            if (!p) return "";

            let name = p.name;

            if (p.properties) {
                name = p.properties.name ? p.properties.name : name;
                if (p.properties.nickName) name += " (" + p.properties.nickName + ")";
            }

            return name;
        }

        function getParticipantPhoneNumber(p) {
            if (!p) return "";

            let phone = p.phoneNumber;

            if (p.properties) {
                phone = p.properties.phoneNumber ? p.properties.phoneNumber : phone;
            }

            return phone;
        }

        function getParticipantImage(p) {
            if (!p) return "";

            let hasPhoto = p.hasPhoto;

            if (p.properties) {
                hasPhoto = p.properties.hasPhoto ? p.properties.hasPhoto : hasPhoto;
            }

            let staffImage = hasPhoto ? apiConfig.images.endpoint + "/player/" + p.id + ".jpg" : p.displayName.substring(0, 1);


            return staffImage;
        }

        var rows = [];

        var pMap = [];
        if (participants && participants.length > 0) {
            for (var i = 0; i < participants.length; i++) {
                if (!pMap[participants[i].id]) {
                    pMap[participants[i].id] = participants[i];
                }
            }
        }

        if (expenses && expenses.length > 0) {
            for (var i = 0; i < expenses.length; i++) {
                if (expenses[i].status === 3) continue; //skip over canceled

                if (expenses[i].particpantType === 32768) {
                    //casino
                    expenses[i].participant = {
                        id: cardroom.id,
                        displayName: cardroom.name,
                        displayPhone: cardroom.phoneNumber,
                        avatar: cardroom.hasLogo ? apiConfig.images.endpoint + "/casino/" + cardroom.id + ".png" : defaultCasino
                    };
                }
                else if (pMap[expenses[i].participantId]) {
                    expenses[i].participant = pMap[expenses[i].participantId];
                    expenses[i].participant.displayName = getParticipantDisplayName(expenses[i].participant);
                    expenses[i].participant.displayPhone = getParticipantPhoneNumber(expenses[i].participant);
                    expenses[i].participant.avatar = getParticipantImage(expenses[i].participant);
                }


                let row = {
                    id: expenses[i].id,
                    name: [expenses[i].participant.displayName, { image: expenses[i].participant.avatar }], // format name from name & nickname
                    phone: expenses[i].participant.displayPhone,
                    amount: expenses[i].amount,
                    note: expenses[i].note,
                    createdOn: expenses[i].transactionDate,
                    transaction: expenses[i]
                };
                rows.push(row);
            }
        }


        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.createdOn < b.createdOn) { return -1; }
                if (a.createdOn > b.createdOn) { return 1; }

                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function GetExepenseTableSchema(maskCurrency) {
        const prefix = maskCurrency === true ? "" : "$";

        const schema = {
            columns: [
                {
                    Header: "name", accessor: "name", Cell: ({ value: [name, data] }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} />
                    ),
                },
                { Header: "phone", accessor: "phone", Cell: ({ value }) => <PhoneCell value={value} obfuscate={true} /> },
                { Header: "amount", accessor: "amount", Cell: ({ value }) => <MoneyCell value={value} prefix={prefix} /> },
                { Header: "created on", accessor: "createdOn", Cell: ({ value }) => <DateCell value={value + "Z"} format="dateAndTime" display="locale" /> },
                { Header: "note", accessor: "note", Cell: ({ value }) => <TextCell value={value} /> },
            ],
            collapsibleDetailAccessor: null, // "details",
            rows: []
        };

        return schema;
    }


    const [participants, setPartcipants] = useState(null);
    const [expenseTransactions, setExpenseTransactions] = useState(null);
    const [expenseTable, setExpenseTable] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);
    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    const casinoReq = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read
    });

    const peopleReq = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleProgressRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading session expenses...", handleProgressRetry)


    //load session participants
    useEffect(() => {
        if (!expenseTransactions) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading session expenses...")
            showProgress();

            //{casinoId:Guid}/sessions/{sessionId:Guid}/transactions/{type:int}", Name = "GetSessionTransactions")]
            casinoReq.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/transactions/7").then((response) => {
                if (response) {
                    if (response.length === 0) {
                        progressIndicatorProps.close();
                    }

                    setExpenseTransactions(response);
                }
            }).catch((ex) => {
                //setOverlayMessage("An error occured, please refresh");
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load session expenses, plese try again...");
            });
        }
    }, [casinoReq.execute, expenseTransactions, retryCounter])


    useEffect(() => {
        if (!participants && expenseTransactions && expenseTransactions.length > 0) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading participants' details...")

            let list = [];
            let map = [];
            for (var i = 0; i < expenseTransactions.length; i++) {
                if (expenseTransactions[i].status != 3 && expenseTransactions[i].participantId && !map[expenseTransactions[i].participantId]) {
                    list.push(expenseTransactions[i].participantId);
                    map[expenseTransactions[i].participantId] = true;
                }
            }

            if (list.length > 0) {
                peopleReq.execute("PUT", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants", list).then((response) => {
                    if (response) {
                        setPartcipants(response);
                        setExpenseTable(convertExpensesToTable(expenseTransactions, response, GetExepenseTableSchema(isCurrencyMaskingEnabled()), cardroom, session));
                        progressIndicatorProps.close();
                    }
                }).catch((ex) => {
                    //setOverlayMessage("An error occured, please refresh");
                    progressIndicatorProps.setMode("errorWithRetry");
                    progressIndicatorProps.setMessage("Unable to load participant details' details, plese try again...");
                });
            }
            else {
                progressIndicatorProps.close();
            }
        }
    }, [peopleReq.execute, expenseTransactions, participants, retryCounter])


    return <SoftBox width="100%">
        <SoftBox width="100%" display="flex" justifyContent="space-between">
            <Grid container xs={12}>
                <Grid item xs={12}>
                    <SoftBox mb={2} textAlign="center">
                        <ProgressIndicator {...progressIndicatorProps} />
                    </SoftBox>
                    {!progressIndicatorProps.visible ? <>
                        {!expenseTransactions || expenseTransactions.length === 0 ?
                            <SoftBox mb={2} textAlign="center">
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold">
                                    There are no expenses...
                                </SoftTypography>
                            </SoftBox>
                            : <SoftBox>
                                <DataTable table={expenseTable} canSearch entriesPerPage={{ defaultValue: 10, visible: true }} isHierarchical={false} />
                            </SoftBox>}
                        <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                            <div></div>
                            <SoftButton variant="gradient" color="dark" onClick={raiseOnClose}>
                                close
                            </SoftButton>
                        </SoftBox>
                    </> : null}
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox>;

};

export default ExpensesWorksheet;