
const form = {
    formId: "purchase-order-form",
    formField: {
        type: {
            name: "type",
            type: "hidden"
        },
        approvalAction: {
            name: "approvalAction",
            type: "text",
            errorMsg: "Action is required.",
        },
        purchaseOrderId: {
            name: "purchaseOrderId",
            type: "hidden"
        },
        buyerAccount: {
            name: "buyerAccount",
            type: "text",
            label: "Payer",
            placeholder: "Payer account",
            errorMsg: "Payer account is required.",
        },
        sellerAccount: {
            name: "sellerAccount",
            type: "text",
            label: "Payee",
            placeholder: "Payee account",
            errorMsg: "Payee account is required.",
        },
        quantity: {
            name: "quantity",
            label: "Quantity",
            type: "number",
            placeholder: "amount",
            errorMsg: "Amount must be greater than 0.",
        },
        itemType: {
            name: "itemType",
            label: "Request for",
            type: "text",
            placeholder: "types of items",
            errorMsg: "Item type is required",
        },
        deliverTo: {
            name: "deliverTo",
            label: "Deliver to",
            type: "text",
            placeholder: "deliver to player id",
            errorMsg: "Deliver to player id is required",
        },
        estimatedCost: {
            name: "estimatedCost",
            label: "Estimated Cost",
            type: "number",
            placeholder: "estimated cost",
            errorMsg: "",
        },
        actualCost: {
            name: "actualCost",
            label: "Actual Cost",
            type: "number",
            placeholder: "actual cost",
            errorMsg: "Amount is required",
        },
        note: {
            name: "note",
            label: "Note",
            type: "text",
            placeholder: "Note",
            errorMsg: "Note is required"
        }
    },

};

export default form;
