export const apiConfig = {
    casino: {
        endpoint: 'https://gambooler-casino-api.wonderfulrock-496476de.westus3.azurecontainerapps.io/Casino',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/cecb43df-e488-42f8-baaa-a31b58566716/Casino.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/cecb43df-e488-42f8-baaa-a31b58566716/Casino.User.Write'],
            manage: ['https://Gambooler.onmicrosoft.com/cecb43df-e488-42f8-baaa-a31b58566716/Casino.User.Manage']
        },
    },
    user: {
        endpoint: 'https://gambooler-user-api.wonderfulrock-496476de.westus3.azurecontainerapps.io/User',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/df9e14e2-5f39-4682-ac5b-426895742804/User.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/df9e14e2-5f39-4682-ac5b-426895742804/User.User.Write'],
            manage: ['https://Gambooler.onmicrosoft.com/df9e14e2-5f39-4682-ac5b-426895742804/User.User.Manage']
        }
    },
    account: {
        endpoint: 'https://gambooler-accounting-api.wonderfulrock-496476de.westus3.azurecontainerapps.io/account',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/d482e647-b408-4a81-8c65-8cfda96fd604/Account.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/d482e647-b408-4a81-8c65-8cfda96fd604/Account.User.Write'],
            manage: ['https://Gambooler.onmicrosoft.com/d482e647-b408-4a81-8c65-8cfda96fd604/Account.User.Manage']
        }
    },
    currency: {
        endpoint: 'https://gambooler-accounting-api.wonderfulrock-496476de.westus3.azurecontainerapps.io/currency',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/d482e647-b408-4a81-8c65-8cfda96fd604/Account.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/d482e647-b408-4a81-8c65-8cfda96fd604/Account.User.Write'],
            manage: ['https://Gambooler.onmicrosoft.com/d482e647-b408-4a81-8c65-8cfda96fd604/Account.User.Manage']
        }
    },
    people: {
        endpoint: 'https://gambooler-people-api.wonderfulrock-496476de.westus3.azurecontainerapps.io/People',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/11dcdc6c-da69-4fe9-aeb0-4355fe8c9029/People.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/11dcdc6c-da69-4fe9-aeb0-4355fe8c9029/People.User.Write'],
            manage: ['https://Gambooler.onmicrosoft.com/11dcdc6c-da69-4fe9-aeb0-4355fe8c9029/People.User.Manage']
        },
    },
    signature: {
        endpoint: 'https://gambooler-signature-api.wonderfulrock-496476de.westus3.azurecontainerapps.io/Signature',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/a428fb80-6844-461a-9ef6-42bea6ab1339/Signature.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/a428fb80-6844-461a-9ef6-42bea6ab1339/Signature.User.Write'],
            manage: ['https://Gambooler.onmicrosoft.com/a428fb80-6844-461a-9ef6-42bea6ab1339/Signature.User.Manage']
        },
    },
    club: {
        endpoint: 'https://gambooler-club-api.wonderfulrock-496476de.westus3.azurecontainerapps.io',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/47fe4f93-d59b-437b-b5c4-dda7616f59f3/Club.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/47fe4f93-d59b-437b-b5c4-dda7616f59f3/Club.User.Write'],
            manage: ['https://Gambooler.onmicrosoft.com/47fe4f93-d59b-437b-b5c4-dda7616f59f3/Club.User.Manage']
        },
    },
    book: {
        endpoint: 'https://gambooler-book-api.wonderfulrock-496476de.westus3.azurecontainerapps.io',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/316d1456-d50d-4a27-b465-22ae36ce3d67/SportsBook.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/316d1456-d50d-4a27-b465-22ae36ce3d67/SportsBook.User.Write'],
            manage: ['https://Gambooler.onmicrosoft.com/316d1456-d50d-4a27-b465-22ae36ce3d67/SportsBook.User.Manage']
        },
    },
    ingest: {
        endpoint: 'https://gambooler-club-ingestion.wonderfulrock-496476de.westus3.azurecontainerapps.io/Ingestion',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/46323466-aa8d-4cef-ac52-7313d6c23441/Club.Ingest.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/46323466-aa8d-4cef-ac52-7313d6c23441/Club.Ingest.User.Write'],
            manage: ['https://Gambooler.onmicrosoft.com/46323466-aa8d-4cef-ac52-7313d6c23441/Club.Ingest.User.Manage']
        },
    },
    notification: {
        endpoint: 'https://gambooler-notification-api.wonderfulrock-496476de.westus3.azurecontainerapps.io/notification',
        scopes: {
            read: ['https://Gambooler.onmicrosoft.com/d1603d51-5191-43ea-a2db-3df8dd82ccc1/Notification.User.Read'],
            write: ['https://Gambooler.onmicrosoft.com/d1603d51-5191-43ea-a2db-3df8dd82ccc1/Notification.User.Write'],
        },
    },
    images: {
        endpoint: 'https://gambooler.blob.core.windows.net/public'
    }
};