import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { agentId, feeSchedule, canEdit, agentName, agentNickname }
} = form;



const validations = {
    editor: Yup.object().shape({
        [agentId.name]: Yup.string().required(agentId.errorMsg),
        [feeSchedule.name]: Yup.string().required(feeSchedule.errorMsg),
        [agentName.name]: Yup.string().when([canEdit.name], {
            is: (canEditV) => canEditV,
            then: Yup.string().required(agentName.errorMsg)
        }),
    }),

};

export default validations;