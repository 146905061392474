import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';

import { DialogContent, DialogTitle, Grid, Icon, Link, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import SoftTypography from 'components/SoftTypography';
import { BootstrapDialog } from 'components/Elements/Dialog/common';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';

import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import SoftAvatar from 'components/SoftAvatar';
import FormField from 'components/Elements/Forms/FormField';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { Form, Formik } from 'formik';
import FormSwitch from 'components/Elements/Forms/FormSwitch';

import actionAvatar from "assets/graphics/actions/journal_256.png";



const AdhocLineEditorForm = ({ cardroom, union, line, formData, readonly }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { lineId, title, category, amount, splitWithSub } = formField;
    const { lineId: lineIdV,
        title: titleV,
        category: categoryV,
        amount: amountV,
        splitWithSub: splitWithSubV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditLine() {
        if (!context) return false;

        return true;
    }


    useEffect(() => {
        if (!formDataInitialized && line) {

            setFieldValue(lineId.name, line ? line.id : "00000000-0000-0000-0000-000000000000");
            setFieldValue(title.name, line ? line.title : "");
            setFieldValue(category.name, line ? line.category : "Expense");
            setFieldValue(amount.name, line ? line.amount : 0);
            setFieldValue(splitWithSub.name, line ? Boolean(line.splitWithSubEntity) : false)

            setFormDataInitialized(true);
        }
    }, [formDataInitialized, line]);

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={actionAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {category.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    {categoryV}
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        <Grid xs={12} container>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {title.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={readonly || !canEditLine()}
                                    type={title.type}
                                    name={title.name}
                                    value={titleV}
                                    placeholder={title.placeholder}
                                    error={errors.title && touched.title}
                                    success={titleV && titleV.length > 0 && !errors.title}
                                />
                            </Grid>
                        </Grid>

                        <Grid xs={12} container>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {amount.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={readonly || !canEditLine()}
                                    type={amount.type}
                                    name={amount.name}
                                    value={amountV}
                                    placeholder={amount.placeholder}
                                    error={errors.amount && touched.amount}
                                    success={amountV && amountV.toString().length > 0 && !errors.amount}
                                />
                            </Grid>
                        </Grid>

                        {cardroom.type == 3 && union && union.isVirtual == false ?
                            <Grid xs={12} container>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {splitWithSub.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    {formDataInitialized ?
                                        <FormSwitch readOnly={readonly || !canEditLine()}
                                            name={splitWithSub.name}
                                            variant="default"
                                            value={splitWithSubV}
                                            {...formData}
                                        /> : null}
                                </Grid>
                            </Grid> : null}

                    </SoftBox>
                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox >;
};

const AdhocLineEditor = ({ cardroom, club, union, agency, reportingPeriod, line, onClose, onLineEditComplete }) => {

    const { formId, formField } = form;
    const currentValidation = validations.editor;


    const [retryCounter, setRetryCounter] = useState(0);
    const [lineRecord, setLineRecord] = useState(null);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving adhoc line...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.club.scopes.write,
    });

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditAdhocLine() {
        if (!context) return false;

        if (cardroom.id != reportingPeriod.casinoId) return false;

        let entitymanagedById = null;

        switch (cardroom.type) {
            case 2:
                entitymanagedById = club.managedByCasinoId;
                break;
            case 3:
                entitymanagedById = union.managedByCasinoId;
                break;
            case 5:
                entitymanagedById = agency.managedByCasinoId;
                break;
        }

        if (cardroom.id != entitymanagedById) return false;

        if (line.ownedByReportId != reportingPeriod.id) return false;

        return context.isAllowed(actions.club.reports.adhoc.edit);
    }

    const handleSubmit = (values, actions) => {

        if (false === canEditAdhocLine()) return;


        var newLine = {
            id: values.lineId,
            title: values.title,
            category: values.category,
            amount: values.amount,
            splitWithSubEntity: values.splitWithSub
        };

        setLineRecord(newLine);
    };


    useEffect(() => {
        if (lineRecord) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving adhoc line...")
            showProgress();

            //[HttpPost("casino/{casinoId:Guid}/reports/{reportingPeriodId:Guid}/adhoc", Name = "SaveReportingLineItem")]
            execute("POST", apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/adhoc", lineRecord).then((response) => {
                if (response) {
                    if (response.status && response.errors) {
                        throw new Error(response.errors);
                    }

                    line = Object.assign(line, response);

                    raiseOnChanged(line);
                }

                setLineRecord(null);
                progressIndicatorProps.close();
                raiseOnClose();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save adhoc line, please try again...")
            });

        }

    }, [lineRecord, execute, retryCounter]);


    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    function raiseOnChanged(l) {
        if (onLineEditComplete) {
            onLineEditComplete(l);
        }
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off" >

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <AdhocLineEditorForm cardroom={cardroom} union={union} line={line} readonly={!canEditAdhocLine()} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting || !canEditAdhocLine()}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};

const AdhocLineDelete = ({ cardroom, club, union, agency, reportingPeriod, line, onClose, onLineDeleteComplete }) => {

    const [lineRecord, setLineRecord] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);
    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "deleting adhoc line...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.club.scopes.write,
    });

    const [context, actions, features] = useCardroomContext(cardroom);

    function canDeleteAdhocLine() {
        if (!context) return false;

        if (cardroom.id != reportingPeriod.casinoId) return false;

        return context.isAllowed(actions.club.reports.adhoc.delete);
    }

    useEffect(() => {
        if (lineRecord) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Deleteing adhoc line...")
            showProgress();

            //[HttpDelete("casino/{casinoId:Guid}/reports/{reportingPeriodId:Guid}/adhoc/{lineId:Guid}", Name = "DeleteReportingLineItem")]
            execute("DELETE", apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/adhoc/" + lineRecord.id, lineRecord).then((response) => {
                if (response) {
                    if (response.status && response.errors) {
                        throw new Error(response.errors);
                    }

                    raiseOnDeleted(line);
                }

                setLineRecord(null);
                progressIndicatorProps.close();
                raiseOnClose();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to delete adhoc line, please try again...")
            });

        }

    }, [lineRecord, execute, retryCounter]);

    function scheduleDelete() {
        setLineRecord(line);
    }

    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    function raiseOnDeleted(l) {
        if (onLineDeleteComplete) {
            onLineDeleteComplete(l);
        }
    }


    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={actionAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">
                        <SoftTypography
                            component="label"
                            variant="button"
                            fontWeight="bold"
                            textTransform="capitalize">
                            Are you sure you want to delete adhoc line "{line.title}"?
                        </SoftTypography>
                    </SoftBox>
                </Grid>
            </Grid >
            <Grid container xs={12} display={"flex"} justifyContent={"end"} spacing={1}>
                <Grid item>
                    <SoftButton variant="outlined" onClick={raiseOnClose} color="primary">No</SoftButton>
                </Grid>
                <Grid item>
                    <SoftButton variant="outlined" onClick={scheduleDelete} color="info" disabled={!canDeleteAdhocLine()}>Yes</SoftButton>
                </Grid>
            </Grid>
        </SoftBox >
    </SoftBox >;
}

const AdhocLineDialog = ({ open, close, cardroom, club, union, agency, reportingPeriod, line, action, onLineActionComplete }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen


    function onCloseInitiated() {
        close();
    }

    function onLineEditComplete(l) {
        if (onLineActionComplete) {
            onLineActionComplete(l, "edit");
        }

        close();
    }

    function onLineDeteleComplete(l) {
        if (onLineActionComplete) {
            onLineActionComplete(l, "delete");
        }

        close();
    }


    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={close}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    {action === "delete" ? "Delete adhoc line?" : "Adhoc Line Editor"}
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox m={1}>
                <Grid container xs={12} justifyItems={"center"} justifyContent={"center"}>
                    <Grid item xs={12}>
                        {action === "edit" || action === "add" ?
                            <AdhocLineEditor cardroom={cardroom} club={club} union={union} agency={agency} reportingPeriod={reportingPeriod} line={line} onClose={onCloseInitiated} onLineEditComplete={onLineEditComplete} /> : null}
                        {action === "delete" ?
                            <AdhocLineDelete cardroom={cardroom} club={club} union={union} agency={agency} reportingPeriod={reportingPeriod} line={line} onClose={onCloseInitiated} onLineDeleteComplete={onLineDeteleComplete} /> : null}
                    </Grid>
                </Grid>
            </SoftBox>
        </DialogContent>
    </BootstrapDialog >
}

const AdhocLine = ({ cardroom, union, club, agency, reportingPeriod, line, onLineEdit, onLineDelete }) => {


    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditLine() {
        return isAdhocActionAllowed(actions.club.reports.adhoc.edit);
    }

    function canDeleteLine() {
        return isAdhocActionAllowed(actions.club.reports.adhoc.delete);
    }

    function isAdhocActionAllowed(action) {
        if (!context) return false;

        if (cardroom.id != reportingPeriod.casinoId) return false;



        let entity = union || club || agency;
        let entitymanagedById = entity.managedByCasinoId;

        /*
        switch (cardroom.type) {
            case 2:
                entitymanagedById = club.managedByCasinoId;
                break;
            case 3:
                entitymanagedById = union.managedByCasinoId;
                break;
            case 5:
                entitymanagedById = agency.managedByCasinoId;
                break;
        }
        */

        if (cardroom.id != entitymanagedById) return false;

        if (line.ownedByReportId != reportingPeriod.id) return false;

        return context.isAllowed(action);
    }


    const lineElement = <Tooltip title="User entered adhoc expense" placement="top">
        <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
            {line.title}
        </SoftTypography>
    </Tooltip>;

    if (canEditLine()) {
        return <><Link onClick={() => onLineEdit(line)} href="javascript:void();" whiteSpace={"nowrap"}>
            <><Icon color="info">edit</Icon>&nbsp;{lineElement}</>
        </Link> {canDeleteLine() ? <Link onClick={() => onLineDelete(line)} href="javascript:void();" whiteSpace={"nowrap"}>
            &nbsp;&nbsp;<Icon color="error">clear</Icon></Link> : null}</>;
    }
    else {
        return lineElement;

    }


}


export default AdhocLine;

export {
    AdhocLineDialog
};