import React, { useState, useEffect } from 'react';

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import wavesWhite from "assets/images/shapes/waves-white.svg";

import { useDialog } from "components/Elements/Dialog/common";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import MiniActionCard from "components/Elements/MiniActionCard";

import { useCardroomContext } from 'features';
import UnionClubs from '../UnionClubs';
import UnionUnions from '../UnionUnions/inidex';
import UnionEditorDialog from '../SubUnion';
import ReportingPeriods from 'layouts/Club/components/Reports/History';
import CardroomLogo from 'components/Elements/CardroomLogo';
import ReportUploadDialog from 'layouts/Club/components/Reports/ReportUpload';
import AdaptiveTab from 'components/Elements/AdaptiveTab';

import RecordAccountTransactionDialog from 'layouts/Cardroom/Details/components/Accounting/Dialogs';
import SoftBadge from 'components/SoftBadge';
import { useCurrencyConverter } from 'components/Elements/CurrencyConverter/currency';
import CurrencyConverterWidget from 'components/Elements/CurrencyConverter';
import { SpaceBar } from '@mui/icons-material';
import AccountingAccountsCard from 'layouts/Cardroom/Details/components/Accounting/Accounts';
import AccountingTransactionsCard from 'layouts/Cardroom/Details/components/Accounting/Transactions/List';
import PurchaseOrderCard from 'layouts/Cardroom/Details/components/PurchaseOrders';
import PurchaseOrderDialog from 'layouts/Cardroom/Details/components/PurchaseOrders/Dialogs';



function UnionDetails({ cardroom }) {

    const [cardroomDetailsData, setCardroomDetailsData] = useState(null);
    const [union, setUnion] = useState(null);

    const [cardroomStats, setCardroomStats] = useState({});
    const [cardroomAccount, setCardroomAccount] = useState({});

    const cardroomRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    const peopleRequest = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading casino details...", handleRetry)

    const [context, actions, features] = useCardroomContext(cardroom);

    const [openCreateUnionDialog, createUnionDialogProps] = useDialog();
    const [openFileUploadDialog, fileUploadDialogProps] = useDialog();
    const [openRecordTxDialog, recordTxDialogProps] = useDialog();
    const [openPurchaseOrderTxDialog, openPurchaseOrderDialogProps] = useDialog();

    function refreshCardroomProperties() {
        setCardroomStats(null);
    }

    function refreshAccount() {
        setCardroomAccount(null);
    }

    function canManageSubunion() {
        if (!union || !context) return false;
        //add permission check

        if (union.isVirtual) return false;

        if ((union.features & 16) === 0) return false;

        return context.isAllowed(actions.club.subunion.edit);
    }

    function canUploadReport() {
        if (!union || !context) return false;
        //add permission check

        if (union.isVirtual) return false;

        if (!context.isEnabled(features.club.reporting.selfUpload)) return false;

        return context.isAllowed(actions.club.reports.upload);
    }

    function onSubUnionsChanged() {

    }
    function onFileUploaded() { }

    function isAccountFeatureEnabled() {
        if (!context) return false;
        if (!union) return false;

        return context.isEnabled(features.casino.accountServices, 0) || context.isEnabled(features.casino.advancedAccountServices, 0);
    }
    function isPoFeatureEnabled() {
        if (!context) return false;
        if (!union) return false;

        return context.isEnabled(features.casino.accountServicesPO, 0);
    }

    function canAddAccountTransaction() {
        if (false == isAccountFeatureEnabled()) return false;
        if (!context) return false;
        return context.isAllowed(actions.casino.account.transaction.add);
    }

    function canAddPurchaseOrder() {
        if (false == isPoFeatureEnabled()) return false;
        if (!context) return false;

        if (union.isVirtual === false) return false; //currently, nothing for union to request from anyone else...

        return context.isAllowed(actions.casino.account.purchaseOrder.add);
    }

    const [currencyConverter, currencies] = useCurrencyConverter(cardroom);
    const [currency, setCurrency] = useState(null);

    function onCurrencySelectionChanged(c) {
        setCurrency(c);
    }

    const [currencySymbol, setCurrencySymbol] = useState(null);

    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);    }

    useEffect(() => {
        if (true === isCurrencyMaskingEnabled()) {
            setCurrencySymbol("");
            return;
        }

        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency]);


    useEffect(() => {
        if (!cardroomDetailsData || cardroomDetailsData.id != cardroom.id) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading casino details...")
            showProgress();
            cardroomRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id).then((response) => {
                setCardroomDetailsData(response);
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load casino details, please try again...")
            });
        }
    }, [cardroomRequest.execute, cardroomDetailsData, retryCounter]) //cardroomListData 


    useEffect(() => {
        if (cardroomDetailsData && !union) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading union info...")
            showProgress();

            clubRequest.execute("GET", apiConfig.club.endpoint + "/union/casino/" + cardroom.id).then((response) => {
                if (response) {
                    setUnion(response);

                    let c = response.currency;
                    if (!c) c = "USD";
                    onCurrencySelectionChanged(currencyConverter.getCurrencyFrom(c));
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load union info, please try again...")
            });
        }
    }, [clubRequest.execute, union, cardroomDetailsData, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (cardroomDetailsData && union && !cardroomStats) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading casino stats...")
            showProgress();
            peopleRequest.execute("GET", apiConfig.people.endpoint + "/venue/" + cardroom.id).then((response) => {
                setCardroomStats(response);
                setCardroomAccount(null);
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load casino stats, please try again...")
            });
        }
    }, [peopleRequest.execute, cardroomStats, cardroomDetailsData, union, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (cardroomDetailsData && cardroomStats && union && (!cardroomAccount || cardroomAccount.accountHolderId != cardroom.id)) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading casino account...")
            showProgress();
            // [HttpGet("{entityId:Guid}/holders/{holderId:Guid}/accounts/{type:int}", Name = "GetEntityAccount")]
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/" + cardroom.id + "/accounts/2").then((response) => {
                setCardroomAccount(response);

                progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load casino account, please try again...")
            });
        }
    }, [accountRequest.execute, cardroomAccount, cardroomStats, cardroomDetailsData, union, retryCounter]) //cardroomListData 


    const tabs = [];

    tabs.push({
        title: { text: "Reports", icon: "summarize" },
        header: canUploadReport() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openFileUploadDialog}><Icon size="lg">cloud_upload</Icon>&nbsp;Upload File</SoftButton> : null,
        body: <> {canUploadReport() ? <ReportUploadDialog {...fileUploadDialogProps} cardroom={cardroom} onFileUploaded={onFileUploaded} /> : null}
            <ReportingPeriods cardroom={cardroom} union={union} audience="union" /></>
    });

    if (canManageSubunion()) {
        tabs.push({
            title: { text: "Unions", icon: "beach_access" },
            header: <SoftButton variant="outlined" size="small" color="dark" onClick={openCreateUnionDialog}><Icon size="lg">group_add</Icon>&nbsp;Create Union</SoftButton>,
            body: <> <UnionEditorDialog {...createUnionDialogProps} cardroom={cardroom} union={union} subunion={null} allowInvite={false} onUnionChanges={onSubUnionsChanged} />
                <UnionUnions cardroom={cardroom} union={union} /></>
        });
    }

    tabs.push({
        title: { text: "Clubs", icon: "nightlife" },
        header: null,
        body: <UnionClubs cardroom={cardroom} union={union} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} />
    });

    if (isPoFeatureEnabled()) {
        tabs.push({
            title: { text: "Requests", icon: "request_quote", badge: cardroomAccount && cardroomAccount.pendingPurchasOrderCount > 0 ? { content: cardroomAccount.pendingPurchasOrderCount, color: "warning" } : null },
            header: canAddPurchaseOrder() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openPurchaseOrderTxDialog}><Icon size="lg">receipt_long</Icon>&nbsp;New Request</SoftButton> : null,
            body:
                <Grid xs={12} container>
                    <Grid item xs={12}>
                        <PurchaseOrderCard cardroom={cardroomDetailsData} account={cardroomAccount} union={union} currency={currency} currencySymbol={currencySymbol}
                            currencyConverter={currencyConverter} onOrderCreatedOrChanged={refreshAccount} />
                    </Grid>
                    <Grid item xs={12}>
                        <CurrencyConverterWidget cardroom={cardroom} selectedCurrency={currency} onCurrencyChanged={onCurrencySelectionChanged} />
                    </Grid>
                </Grid>

        });
    }

    if (isAccountFeatureEnabled()) {
        tabs.push({
            title: { text: "Accounts", icon: "account_balance" },
            header: canAddAccountTransaction() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openRecordTxDialog}><Icon size="lg">receipt_long</Icon>&nbsp;Record Transaction</SoftButton> : null,
            body:
                <Grid xs={12} container>
                    <Grid item xs={12}>
                        <AccountingAccountsCard cardroom={cardroomDetailsData} account={cardroomAccount} union={union} currency={currency} currencySymbol={currencySymbol}
                            currencyConverter={currencyConverter} onAccountUpdated={refreshAccount} />
                    </Grid>
                    <Grid item xs={12}>
                        <CurrencyConverterWidget cardroom={cardroom} selectedCurrency={currency} onCurrencyChanged={onCurrencySelectionChanged} />
                    </Grid>
                </Grid>

        });
        tabs.push({
            title: { text: "Debt Service", icon: "payments", badge: cardroomAccount && cardroomAccount.pendingTransactionCount > 0 ? { content: cardroomAccount.pendingTransactionCount, color: "warning" } : null },
            header: canAddAccountTransaction() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openRecordTxDialog}><Icon size="lg">receipt_long</Icon>&nbsp;Record Transaction</SoftButton> : null,
            body:
                <Grid xs={12} container>
                    <Grid item xs={12}>
                        <AccountingTransactionsCard cardroom={cardroomDetailsData} account={cardroomAccount} union={union} currency={currency} currencySymbol={currencySymbol}
                            currencyConverter={currencyConverter} onAccountUpdated={refreshAccount} />
                    </Grid>
                    <Grid item xs={12}>
                        <CurrencyConverterWidget cardroom={cardroom} selectedCurrency={currency} onCurrencyChanged={onCurrencySelectionChanged} />
                    </Grid>
                </Grid>

        });
    }

    return <>
        {cardroomDetailsData && !progressIndicatorProps.visible ? <>

            {canAddAccountTransaction() && cardroomAccount ?
                <RecordAccountTransactionDialog {...recordTxDialogProps} cardroom={cardroom} account={cardroomAccount} union={union}
                    onAdded={refreshAccount} mode="venue" /> : null}

            {canAddPurchaseOrder() && cardroomAccount ?
                <PurchaseOrderDialog  {...openPurchaseOrderDialogProps} cardroom={cardroom} account={cardroomAccount} union={union}
                    onChanged={refreshAccount} action="create" /> : null}


            <SoftBox mb={3} p={1}>
                <SoftBox
                    position="relative"
                    bgColor="secondary"
                    py={3}
                    px={{ xs: 3, sm: 6, md: 1 }}
                    mt={3}
                    borderRadius="xl"
                    variant="gradient" >

                    <SoftBox
                        component="img"
                        src={wavesWhite}
                        alt="pattern-line"
                        width="100%"
                        position="absolute"
                        left="0"
                        top="0"
                        opacity={0.4}
                    />

                    <Grid container alignItems="center" position="relative">

                        <CardroomLogo cardroom={cardroom} union={union} canOpenProperties={true} />

                        <Grid item xs={12} lg={11}>
                            <Grid container>
                                <Grid item xs={6} lg={3} p={1} >
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "AR", fontWeight: "medium", tooltip: "Accounts Receivable: amount yet to be collected." }}
                                        count={{ text: cardroomAccount ? cardroomAccount.accountsReceivable : 0, prefix: currencySymbol, color: "warning", useNumberFormatter: true }}
                                        icon={{ component: "credit_card" }}
                                    />

                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "AP", fontWeight: "medium", tooltip: "Accounts Payable: amount yet to be paid out." }}
                                        count={{ text: cardroomAccount ? cardroomAccount.accountsPayable : 0, prefix: currencySymbol, color: "error", useNumberFormatter: true }}
                                        icon={{ component: "money" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Unions / Clubs", fontWeight: "medium" }}
                                        count={{ text: union ? union.unionCount.toLocaleString() + " / " + union.clubCount.toLocaleString() : "0 / 0", color: "success", useNumberFormatter: false }}
                                        icon={{ component: "beach_access" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Players / Agents", fontWeight: "medium" }}
                                        count={{ text: union ? union.playerCount.toLocaleString() + " / " + union.agentCount.toLocaleString() : "0 / 0", color: "success", useNumberFormatter: false }}
                                        icon={{ color: "info", component: "group" }}
                                    />

                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </SoftBox>
            </SoftBox>

            <AdaptiveTab switchToMobileOnSize="md" tabs={tabs} p={1} />

        </> : <ProgressIndicator {...progressIndicatorProps} />
        }
    </>;
}

export default UnionDetails;