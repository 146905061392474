import { apiConfig } from "config/apiConfig";


function loader(method, type) {
    this.method = method;
    this.type = type;
}

const loaderExec = {
    bookExecute: function (queue, request, cardroom, entity, enableCache, result, onsuccess, onerror) {
        if (!queue) {
            if (onsuccess) onsuccess(null);
            return;
        }

        if (!result) result = [];

        if (queue.length === 0) {
            if (onsuccess) onsuccess(result);
        }
        else {
            let l = queue.shift();
            if (l && l.method) {
                l.method(request, cardroom, entity, enableCache, (items) => {
                    let entities = [];
                    if (items) {
                        entities = [...result, ...items];
                    }
                    loaderExec.bookExecute(queue, request, cardroom, entity, enableCache, entities, onsuccess, onerror);

                }, (ex) => {
                    if (onerror) onerror(ex, l.type);
                });
            }
        }
    },
    playerExecute: function (queue, request, cardroom, entity, player, result, onsuccess, onerror) {
        if (!queue) {
            if (onsuccess) onsuccess(null);
            return;
        }

        if (!result) result = [];

        if (queue.length === 0) {
            if (onsuccess) onsuccess(result);
        }
        else {
            let l = queue.shift();
            if (l && l.method) {
                l.method(request, cardroom, entity, player, (items) => {
                    let entities = [];
                    if (items) {
                        entities = [...result, ...items];
                    }
                    loaderExec.playerExecute(queue, request, cardroom, entity, player, entities, onsuccess, onerror);

                }, (ex) => {
                    if (onerror) onerror(ex, l.type);
                });
            }
        }
    }
};


const bookDataLoader = {
    loadBook: function (request, cardroom, enableCache, onsuccess, onerror) {
        if (enableCache && cardroom && cardroom.book) {
            if (onsuccess) onsuccess(cardroom.book);
            return;
        }

        request.execute("GET", apiConfig.book.endpoint + "/sportsbook/casino/" + cardroom.id).then((book) => {
            if (book) {
                if (enableCache) cardroom.book = book;
                if (onsuccess) onsuccess(book);
            }
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    loadPlayers: function (request, cardroom, book, enableCache, onsuccess, onerror) {
        if (enableCache && book && book.players) {
            if (onsuccess) onsuccess(book.players);
            return;
        }

        request.execute("GET", apiConfig.book.endpoint + "/player/" + book.id + "/casino/" + cardroom.id + "/players").then((players) => {
            if (players) {
                if (enableCache) book.players = players;
                if (onsuccess) onsuccess(players);
            }
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    loadAgents: function (request, cardroom, book, enableCache, onsuccess, onerror) {
        if (enableCache && book && book.agents) {
            if (onsuccess) onsuccess(book.agents);
            return;
        }
        request.execute("GET", apiConfig.book.endpoint + "/agent/" + book.id + "/casino/" + cardroom.id + "/agents").then((agents) => {
            if (agents) {
                if (enableCache) book.agents = agents;
                if (onsuccess) onsuccess(agents);
            }
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    loadPlayerRebateSchedule: function (request, cardroom, book, player, onsuccess, onerror) {
        //  [HttpGet("{bookId:Guid}/casino/{casinoId:Guid}/players/{playerId:Guid}/rebate", Name = "GetPlayerRebateSchedule")]
        request.execute("GET", apiConfig.book.endpoint + "/player/" + book.id + "/casino/" + cardroom.id + "/players/" + player.id + "/rebate").then((pool) => {
            if (pool) {
                if (onsuccess) onsuccess(pool);
            }
        }).catch((ex) => {
            if (ex.name === 'SyntaxError' && ex.message === 'Unexpected end of JSON input') {
                if (onsuccess) onsuccess(null);
            } else {
                //decipher null response
                if (onerror) onerror(ex);
            }
        });
    },
    loadPlayerRebateSchedules: function (request, cardroom, book, enableCache, onsuccess, onerror) {
        if (enableCache && book && book.rebates) {
            if (onsuccess) onsuccess(book.rebates);
            return;
        }

        request.execute("GET", apiConfig.book.endpoint + "/sportsbook/" + book.id + "/casino/" + cardroom.id + "/schedules/rebate")
            .then((schedules) => {
                if (schedules) {
                    if (enableCache) book.rebates = schedules;
                    if (onsuccess) onsuccess(schedules);
                }
            }).catch((ex) => {
                if (ex.name === 'SyntaxError' && ex.message === 'Unexpected end of JSON input') {
                    if (onsuccess) onsuccess(null);
                } else {
                    if (onerror) onerror(ex);
                }
            });
    },
    loadAgentCommissionSchedules: function (request, cardroom, book, enableCache, onsuccess, onerror) {
        if (enableCache && book && book.commissions) {
            if (onsuccess) onsuccess(book.commissions);
            return;
        }

        request.execute("GET", apiConfig.book.endpoint + "/sportsbook/" + book.id + "/casino/" + cardroom.id + "/schedules/commission")
            .then((schedules) => {
                if (schedules) {
                    if (enableCache) book.commissions = schedules;
                    if (onsuccess) onsuccess(schedules);
                }
            }).catch((ex) => {
                if (ex.name === 'SyntaxError' && ex.message === 'Unexpected end of JSON input') {
                    if (onsuccess) onsuccess(null);
                } else {
                    if (onerror) onerror(ex);
                }
            });
    },
    loadAgentPlayerPools: function (request, cardroom, book, agent, enableCache, onsuccess, onerror) {
        if (enableCache && agent && agent.playerPools) {
            if (onsuccess) onsuccess(agent.playerPools);
            return;
        }

        request.execute("GET", apiConfig.book.endpoint + "/agent/" + book.id + "/casino/" + cardroom.id + "/agents/" + agent.id + "/pools").then((pools) => {
            if (pools) {
                if (enableCache) agent.playerPools = pools;
                if (onsuccess) onsuccess(pools);
            }
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    loadAgentsPlayerPools: function (request, cardroom, book, agents, enableCache, onsuccess, onerror) {
        if (enableCache && book && book.playerPools) {
            if (onsuccess) onsuccess(book.playerPools);
            return;
        }

        if (agents.length == 0) {
            if (onsuccess) onsuccess([]);
            return;
        }

        let pools = [];
        let requested = 0, completed = 0, errors = 0;

        for (var i = 0; i < agents.length; i++) {
            requested++;

            bookDataLoader.loadAgentPlayerPools(request, cardroom, book, agents[i], enableCache, (response) => {
                completed++;

                if (response && response.length > 0) pools = [...pools, ...response];
                notify();

            }, (err) => {
                errors++;
                completed++;

                notify();
            });
        }

        function notify() {

            if (requested === completed) {
                if (onsuccess) onsuccess(pools, errors);
            }
        }

    },
    saveAgent: function (request, cardroom, book, agent, onsuccess, onerror) {
        request.execute("POST", apiConfig.book.endpoint + "/agent/" + book.id + "/casino/" + cardroom.id + "/agents", agent).then((a) => {
            if (a) {
                if (onsuccess) onsuccess(a);
            }
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    saveAgentPool: function (request, cardroom, book, agent, pool, onsuccess, onerror) {
        request.execute("POST", apiConfig.book.endpoint + "/agent/" + book.id + "/casino/" + cardroom.id + "/agents/" + agent.id + "/pools", pool).then((pp) => {
            if (pp) {
                if (onsuccess) onsuccess(pp);
            }
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    loadEntities: function (request, cardroom, book, entities, enableCache, onsuccess, onerror) {
        const q = [];

        if ((entities & entitiesEnum.players) > 0) q.push(new loader(bookDataLoader.loadPlayers, "players"));
        if ((entities & entitiesEnum.agents) > 0) q.push(new loader(bookDataLoader.loadAgents, "agents"));

        loaderExec.bookExecute(q, request, cardroom, book, enableCache, null, onsuccess, onerror);
    },
    loadPlayerEntities: function (request, cardroom, book, player, entities, onsuccess, onerror) {
        const q = [];

        if ((entities & entitiesEnum.playerPool) > 0) q.push(new loader(bookDataLoader.loadPlayers, "package"));
        if ((entities & entitiesEnum.playerRebateSchedule) > 0) q.push(new loader(bookDataLoader.loadAgents, "rebate schedule"));

        loaderExec.playerExecute(q, request, cardroom, book, player, null, onsuccess, onerror);
    },
    loadReportSummary: function (request, cardroom, book, reportingPeriod, enableCache, onsuccess, onerror) {
        if (enableCache && reportingPeriod && reportingPeriod.summary) {
            if (onsuccess) onsuccess(reportingPeriod.summary);
            return;
        }

        // [HttpGet("{bookId:Guid}/casino/{casinoId:Guid}/reports/{reportingPeriodId:Guid}/summary", Name = "GetReportingPeriodSummary")]
        request.execute("GET", apiConfig.book.endpoint + "/report/" + book.id + "/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/summary").then((summary) => {
            if (summary) {
                if (enableCache) reportingPeriod.summary = summary;
                if (onsuccess) onsuccess(summary);
            }
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    loadReportPlayers: function (request, cardroom, book, reportingPeriod, enableCache, onsuccess, onerror) {
        if (enableCache && reportingPeriod && reportingPeriod.players) {
            if (onsuccess) onsuccess(reportingPeriod.players);
            return;
        }

        request.execute("GET", apiConfig.book.endpoint + "/report/" + book.id + "/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/players").then((players) => {
            if (players) {
                if (enableCache) reportingPeriod.players = players;
                if (onsuccess) onsuccess(players);
            }
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    loadReportAgentPools: function (request, cardroom, book, reportingPeriod, enableCache, onsuccess, onerror) {
        
        if (enableCache && reportingPeriod && reportingPeriod.playerPools) {
            if (onsuccess) onsuccess(reportingPeriod.playerPools);
            return;
        }

        request.execute("GET", apiConfig.book.endpoint + "/report/" + book.id + "/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/pools").then((pools) => {
            if (pools) {
                if (enableCache) reportingPeriod.playerPools = pools;
                if (onsuccess) onsuccess(pools);
            }
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    }
}

const entitiesEnum = {
    players: 1,
    agents: 2,
    playerPool: 4,
    playerRebateSchedule: 8
};

const useBookDataLoader = () => {
    return {
        entity: bookDataLoader,
        entities: entitiesEnum
    };
};

export {
    useBookDataLoader
}