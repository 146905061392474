import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { FormControlLabel, Grid, Radio, RadioGroup, Tooltip, tooltipClasses } from '@mui/material';
import { Formik, Form, ErrorMessage } from 'formik';
import FormField from 'components/Elements/Forms/FormField';
import SoftAvatar from 'components/SoftAvatar';
import FormSwitch from 'components/Elements/Forms/FormSwitch';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import { styled } from '@mui/material/styles';


import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import { useCardroomContext } from 'features';


const ClubFeeScheduleEditorForm = ({ cardroom, union, schedule, formData, dense }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { scheduleId, name, description, unionDuesRate, xmttDuesRate, chipOffsetRate, taxRebateRate, unionDuesBase, winTaxRate, loyaltyRebateRate, mttBackoffRate, excludeRevenueFromTax } = formField;
    const {
        scheduleId: scheduleIdV,
        name: nameV,
        description: descriptionV,
        unionDuesRate: unionDuesRateV,
        xmttDuesRate: xmttDuesRateV,
        chipOffsetRate: chipOffsetRateV,
        taxRebateRate: taxRebateRateV,
        unionDuesBase: unionDuesBaseV,
        winTaxRate: winTaxRateV,
        loyaltyRebateRate: loyaltyRebateRateV,
        mttBackoffRate: mttBackoffRateV,
        excludeRevenueFromTax: excludeRevenueFromTaxV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);


    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditSchedule() {
        if (!context) return false;

        if (canEditFeeSchedule() === false) return false;

        return context.isAllowed(actions.cardroom.fees.club.edit);
    }

    function canEditFeeSchedule() {
        if (!context) return false;

        // ClubFeeScheduleEdit = 1,
        if ((union.features & 1) === 0) return false;

        return true;
    }


    useEffect(() => {
        if (!formDataInitialized) {

            setFieldValue(unionDuesRate.name, schedule && schedule.id ? schedule.unionDuesRate * 100 : 0);
            setFieldValue(xmttDuesRate.name, schedule && schedule.id ? schedule.xMttDuesRate * 100 : 0);
            setFieldValue(chipOffsetRate.name, schedule && schedule.id ? schedule.chipoffsetRate * 100 : 0);
            setFieldValue(taxRebateRate.name, schedule && schedule.id ? schedule.taxRebateRate * 100 : 0);

            setFieldValue(mttBackoffRate.name, schedule && schedule.id ? schedule.mttBackoffRate * 100 : 0);
            
            setFieldValue(winTaxRate.name, schedule && schedule.id ? schedule.winTaxRate * 100 : 0);
            setFieldValue(unionDuesBase.name, schedule && schedule.id ? schedule.unionDuesBase : 0);

            setFieldValue(loyaltyRebateRate.name, schedule && schedule.id ? schedule.loyaltyRebateRate * 100 : 0);

            setFieldValue(name.name, schedule && schedule.id ? schedule.name : "");
            setFieldValue(description.name, schedule && schedule.id ? schedule.description : "");
            setFieldValue(scheduleId.name, schedule ? schedule.id : "");

            setFieldValue(excludeRevenueFromTax.name, schedule && !isNaN(schedule.excludeFromTax) ? schedule.excludeFromTax : 32);

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    useEffect(() => {
        if (schedule && formDataInitialized) {
            setFormDataInitialized(false);
        }
    }, [schedule, cardroom, union])

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                {dense === true ? null :
                    <Grid item xs={12} sm={4} container justifyContent="center">
                        <SoftBox position="relative" height="max-content" mx="auto">
                            <SoftAvatar src={null} size="xxl" variant="rounded" bgColor="dark" />
                        </SoftBox>
                    </Grid>}
                <Grid item xs={12} sm={dense === true ? 12 : 8}>

                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {name.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditSchedule()}
                                    type={name.type}
                                    name={name.name}
                                    value={nameV}
                                    placeholder={name.placeholder}
                                    error={errors.name && touched.name}
                                    success={nameV && nameV.length > 0 && !errors.name}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {description.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditSchedule()}
                                    type={description.type}
                                    name={description.name}
                                    value={descriptionV}
                                    placeholder={description.placeholder}
                                    error={errors.description && touched.description}
                                    success={descriptionV && descriptionV.length > 0 && !errors.description}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {unionDuesBase.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditSchedule()}
                                    type={unionDuesBase.type}
                                    name={unionDuesBase.name}
                                    value={unionDuesBaseV}
                                    placeholder={unionDuesBase.placeholder}
                                    error={errors.unionDuesBase && touched.unionDuesBase}
                                    success={unionDuesBaseV && unionDuesBaseV.toString().length > 0 && !errors.unionDuesBase}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {unionDuesRate.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditSchedule()}
                                    type={unionDuesRate.type}
                                    name={unionDuesRate.name}
                                    value={unionDuesRateV}
                                    placeholder={unionDuesRate.placeholder}
                                    error={errors.unionDuesRate && touched.unionDuesRate}
                                    success={unionDuesRateV && unionDuesRateV.toString().length > 0 && !errors.unionDuesRate}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {xmttDuesRate.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditSchedule()}
                                    type={xmttDuesRate.type}
                                    name={xmttDuesRate.name}
                                    value={xmttDuesRateV}
                                    placeholder={xmttDuesRate.placeholder}
                                    error={errors.xmttDuesRate && touched.xmttDuesRate}
                                    success={xmttDuesRateV && xmttDuesRateV.toString().length > 0 && !errors.xmttDuesRate}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {chipOffsetRate.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditSchedule()}
                                    type={chipOffsetRate.type}
                                    name={chipOffsetRate.name}
                                    value={chipOffsetRateV}
                                    placeholder={chipOffsetRate.placeholder}
                                    error={errors.chipOffsetRate && touched.chipOffsetRate}
                                    success={chipOffsetRateV && chipOffsetRateV.toString().length > 0 && !errors.chipOffsetRate}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {taxRebateRate.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditSchedule()}
                                    type={taxRebateRate.type}
                                    name={taxRebateRate.name}
                                    value={taxRebateRateV}
                                    placeholder={taxRebateRate.placeholder}
                                    error={errors.taxRebateRate && touched.taxRebateRate}
                                    success={taxRebateRateV && taxRebateRateV.toString().length > 0 && !errors.taxRebateRate}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {winTaxRate.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditSchedule()}
                                    type={winTaxRate.type}
                                    name={winTaxRate.name}
                                    value={winTaxRateV}
                                    placeholder={winTaxRate.placeholder}
                                    error={errors.winTaxRate && touched.winTaxRate}
                                    success={winTaxRateV && winTaxRateV.toString().length > 0 && !errors.winTaxRate}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {mttBackoffRate.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditSchedule()}
                                    type={mttBackoffRate.type}
                                    name={mttBackoffRate.name}
                                    value={mttBackoffRateV}
                                    placeholder={mttBackoffRate.placeholder}
                                    error={errors.mttBackoffRate && touched.mttBackoffRate}
                                    success={mttBackoffRateV && mttBackoffRateV.toString().length > 0 && !errors.mttBackoffRate}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {loyaltyRebateRate.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditSchedule()}
                                    type={loyaltyRebateRate.type}
                                    name={loyaltyRebateRate.name}
                                    value={loyaltyRebateRateV}
                                    placeholder={loyaltyRebateRate.placeholder}
                                    error={errors.loyaltyRebateRate && touched.loyaltyRebateRate}
                                    success={loyaltyRebateRateV && loyaltyRebateRateV.toString().length > 0 && !errors.loyaltyRebateRate}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {excludeRevenueFromTax.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                {formDataInitialized ?

                                    <Grid container xs={12}>
                                        <Grid item xs={3}>
                                            <SoftBox ml={0.5} mb={0.25}>
                                                <FormSwitch label="Cash" readOnly={!canEditSchedule()}
                                                    name={excludeRevenueFromTax.name}
                                                    value={excludeRevenueFromTaxV}
                                                    mask={1}
                                                    variant="bit"
                                                    {...formData} />
                                            </SoftBox>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <SoftBox ml={0.5} mb={0.25}>
                                                <FormSwitch label="SNG" readOnly={!canEditSchedule()}
                                                    name={excludeRevenueFromTax.name}
                                                    value={excludeRevenueFromTaxV}
                                                    mask={2}
                                                    variant="bit"
                                                    {...formData} />
                                            </SoftBox>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <SoftBox ml={0.5} mb={0.25}>
                                                <FormSwitch label="MTT" readOnly={!canEditSchedule()}
                                                    name={excludeRevenueFromTax.name}
                                                    value={excludeRevenueFromTaxV}
                                                    mask={4}
                                                    variant="bit"
                                                    {...formData} />
                                            </SoftBox>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <SoftBox ml={0.5} mb={0.25}>
                                                <FormSwitch label="OFC" readOnly={!canEditSchedule()}
                                                    name={excludeRevenueFromTax.name}
                                                    value={excludeRevenueFromTaxV}
                                                    mask={8}
                                                    variant="bit"
                                                    {...formData} />
                                            </SoftBox>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <SoftBox ml={0.5} mb={0.25}>
                                                <FormSwitch label="SPIN" readOnly={!canEditSchedule()}
                                                    name={excludeRevenueFromTax.name}
                                                    value={excludeRevenueFromTaxV}
                                                    mask={16}
                                                    variant="bit"
                                                    {...formData} />
                                            </SoftBox>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <SoftBox ml={0.5} mb={0.25}>
                                                <FormSwitch label="Leaderboard" readOnly={!canEditSchedule()}
                                                    name={excludeRevenueFromTax.name}
                                                    value={excludeRevenueFromTaxV}
                                                    mask={64}
                                                    variant="bit"
                                                    {...formData} />
                                            </SoftBox>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <SoftBox ml={0.5} mb={0.25}>
                                                <FormSwitch label="BBJ" readOnly={!canEditSchedule()}
                                                    name={excludeRevenueFromTax.name}
                                                    value={excludeRevenueFromTaxV}
                                                    mask={32}
                                                    variant="bit"
                                                    {...formData} />
                                            </SoftBox>
                                        </Grid>



                                    </Grid> : null}
                            </Grid>
                        </Grid>
                    </SoftBox>
                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox >;
};

ClubFeeScheduleEditorForm.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    dense: PropTypes.bool
};

const ClubFeeScheduleEditor = ({ cardroom, union, schedule, onClose, dense }) => {

    const { formId, formField } = form;
    const currentValidation = validations.editor;

    const [retryCounter, setRetryCounter] = useState(0);
    const [scheduleRecord, setScheduleRecord] = useState(null);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving commission schedule...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.club.scopes.write,
    });

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditSchedule() {
        if (!context) return false;

        if (canEditFeeSchedule() === false) return false;

        return context.isAllowed(actions.cardroom.fees.club.edit);
    }

    function canEditFeeSchedule() {
        if (!context) return false;

        // ClubFeeScheduleEdit = 1,
        if ((union.features & 1) === 0) return false;

        return true;
    }

    const handleSubmit = (values, actions) => {

        //alert("submitting");

        if (false === canEditSchedule()) return;

        //construct participant object
        const ns = Object.assign({}, schedule);

        //update schedule...
        if (!ns.id) ns.id = "00000000-0000-0000-0000-000000000000";

        ns.name = values.name;
        ns.description = values.description;
        ns.unionDuesRate = parseFloat(values.unionDuesRate) / 100;
        ns.xMttDuesRate = parseFloat(values.xmttDuesRate) / 100;
        ns.chipoffsetRate = parseFloat(values.chipOffsetRate) / 100;
        ns.taxRebateRate = parseFloat(values.taxRebateRate) / 100;
        ns.loyaltyRebateRate = parseFloat(values.loyaltyRebateRate) / 100;

        ns.mttBackoffRate = parseFloat(values.mttBackoffRate) / 100;

        ns.excludeFromTax = parseInt(values.excludeRevenueFromTax);

        ns.unionDuesBase = parseFloat(values.unionDuesBase);
        ns.winTaxRate = parseFloat(values.winTaxRate) / 100;

        setScheduleRecord(ns);
    };


    useEffect(() => {
        if (scheduleRecord) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving club fee schedule...")
            showProgress();

            //[HttpPost("{unionId:Guid}/casino/{casinoId:Guid}/schedules", Name = "SaveClubFeeSchedule")]
            execute("POST", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id + "/schedules/fees", scheduleRecord).then((response) => {
                if (response) {
                    //raiseOnTransactionCreated();
                    if (schedule) schedule = Object.assign(schedule, response);
                    else schedule = response;
                }

                setScheduleRecord(null);
                progressIndicatorProps.close();
                raiseOnClose(false);

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save club fee schedule, please try again...")
            });
        }

    }, [scheduleRecord, execute, retryCounter]);


    function onCanceled() {
        raiseOnClose(true);
    }

    function raiseOnClose(canceled) {
        if (onClose) {
            onClose(canceled);
        }
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <ClubFeeScheduleEditorForm cardroom={cardroom} union={union} schedule={schedule} dense={dense} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={onCanceled}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting || !canEditSchedule()}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};

ClubFeeScheduleEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    dense: PropTypes.bool
};

export default ClubFeeScheduleEditor;