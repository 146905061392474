import React from 'react';
import PropTypes from "prop-types";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { BootstrapDialog } from "components/Elements/Dialog/common"
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { AgencyPlayerPropertiesEditor, AgencyPlayerRebateEditor, ClubPlayerPropertiesEditor } from './Editor';
import AdaptiveTab from 'components/Elements/AdaptiveTab';
import { DialogActions } from '@mui/material';
import SoftButton from 'components/SoftButton';
import PremiumServiceSubscriptionRequired from 'components/Elements/PremiumServiceRequried';
import { useCardroomContext } from 'features';


const ClubPlayerEditorDialog = ({ open, close, cardroom, club, player, onPlayerUpdated }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    const [context, actions, features] = useCardroomContext(cardroom);

    function isPlayerEditingEnabled() {
        if (!context) return false;

        return context.isEnabled(features.club.reporting.playerReport);
    }

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="lg">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.secondary.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Player Editor: {player ? player.name : null}
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox mt={3}>
                {isPlayerEditingEnabled() ?
                    <ClubPlayerPropertiesEditor cardroom={cardroom} club={club} player={player} onClose={close} onPlayerUpdated={onPlayerUpdated} /> : <PremiumServiceSubscriptionRequired service="Player Management" />}
            </SoftBox>
        </DialogContent>
        {!isPlayerEditingEnabled() ? <DialogActions>
            <SoftButton onClick={close}>Close</SoftButton>
        </DialogActions> : null}
    </BootstrapDialog>;
}

ClubPlayerEditorDialog.defaultProps = {

};

ClubPlayerEditorDialog.propTypes = {
    cardroom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,
    onPlayerUpdated: PropTypes.func
};



const AgencyPlayerEditorDialog = ({ open, close, cardroom, agency, player, onPlayerUpdated }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    const [context, actions, features] = useCardroomContext(cardroom);
    function isPlayerEditingEnabled() {
        if (!context) return false;

        return context.isEnabled(features.club.reporting.playerReport);
    }

    const tabs = [];

    tabs.push({
        title: { text: "Properties", icon: "person" },
        header: null,
        body: <AgencyPlayerPropertiesEditor cardroom={cardroom} agency={agency} player={player} onClose={close} pane="basic" onPlayerUpdated={onPlayerUpdated} />
    });


    tabs.push({
        title: { text: "Rebate", icon: "price_check" },
        header: null,
        body: isPlayerEditingEnabled() ? <AgencyPlayerRebateEditor cardroom={cardroom} agency={agency} player={player} onClose={close} pane="rebate" onPlayerUpdated={onPlayerUpdated} /> : <PremiumServiceSubscriptionRequired service="Player Management" />
    });




    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="lg">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.secondary.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Player Editor: {player ? player.name : null}
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox mt={3}>
                <AdaptiveTab switchToMobileOnSize="md" tabs={tabs} p={1} />
            </SoftBox>
        </DialogContent>
        <DialogActions>
            <SoftButton onClick={close} color={"dark"} variant="outlined">Close</SoftButton>
        </DialogActions>
    </BootstrapDialog>;
}

AgencyPlayerEditorDialog.defaultProps = {

};

AgencyPlayerEditorDialog.propTypes = {
    cardroom: PropTypes.object.isRequired,
    agency: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,
    onPlayerUpdated: PropTypes.func
};

export { AgencyPlayerEditorDialog, ClubPlayerEditorDialog };
