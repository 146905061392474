
const form = {
    formId: "curency-settings-editor-form",
    formField: {
        defaultCurrency: {
            name: "defaultCurrency",
            label: "Default currency",
            type: "string",
            placeholder: "default currency",
            errorMsg: "Default currency is required",
        },
        supportedCurrencies: {
            name: "supportedCurrencies",
            label: "Currencies",
            type: "number",
            placeholder: "currencies",
            errorMsg: "Currencies is a required field",
        }
    },

};

export default form;
