import form from "./form";


const {
    formField: {
        agentId,
        feeSchedule,
        canEdit,
        agentName,
        agentNickname
    },
} = form;

const initialValues = {
    [agentId.name]: "",
    [feeSchedule.name]: "",
    [canEdit.name]: false,
    [agentName.name]: "",
    [agentNickname.name]: ""
};

export default initialValues;
