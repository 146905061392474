
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Grid } from '@mui/material';

import { ErrorMessage } from "formik";
import FormField from "components/Elements/Forms/FormField";
import FormSwitch from "components/Elements/Forms/FormSwitch";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import chipsAvatar from "assets/graphics/casino/cash_256.png";
import { NumericFormat } from 'react-number-format';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import FormImageField from 'components/Elements/Forms/FormImageField';
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';
import { useCardroomContext } from 'features';



const AccountTransactionReceiveForm = ({ cardroom, cardroomAccount, staff, players, clubs, agents, unions, defaults, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { type, sourceAccount, targetAccount, participant, amount, isPrePayment, method, accountBalance, note, provider, providerOther, providerRef, providerRefMethod, providerRefImage, } = formField;
    const {
        type: typeV,
        amount: amountV,
        method: methodV,
        accountBalance: accountBalanceV,
        provider: providerV,
        providerOther: providerOtherV,
        providerRef: providerRefV,
        verified: verifiedV,
        providerRefImage: providerRefImageV,
        providerRefMethod: providerRefMethodV,
        note: noteV,
        sourceAccount: sourceAccountV,
        targetAccount: targetAccountV,
        isPrePayment: isPrePaymentV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);
    const [accountListUpdatedNeeded, setAccountListUpdateNeeded] = useState(false);
    const [toAccountHolderName, setToAccountHolderName] = useState(null);
    const [participantType, setParticipantType] = useState(null);
    const [sourceAccountList, setSourceAccountList] = useState(null);
    const [selectedSourceAccountIndex, setSelectedSourceAccountIndex] = useState(-1);
    const [payerTypes, setPayerTypes] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [currencySymbol, setCurrencySymbol] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);
    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    function onPaymentMethodRadioGroupChanged(e, newValue) {
        setFieldValue(method.name, newValue);
    }

    function onVendorRefMethodRadioGroupChanged(e, newValue) {
        setFieldValue(providerRefMethod.name, newValue);
    }

    function onAmountChanging(value) {
        if (!formDataInitialized) return;
        //if (value <= markerBalanceV && markerBalanceV > 0 && methodV != "credit") {
        //    setFieldValue(method.name, "credit");
        //}
    }

    function onEWalletProviderChanged(e, newValue) {
        setFieldValue(provider.name, newValue);
    }
    function onTargetTypeChanged(e, newValue) {
        setParticipantType(newValue);

        let ds = null;

        switch (newValue) {
            case "player":
                ds = players;
                break;
            case "staff":
                ds = staff;
                break;
            case "club":
                ds = clubs;
                break;
            case "agent":
                ds = agents;
                break;
            case "union":
                ds = unions;
                break;
        }

        setSelectedSourceAccountIndex(-1);
        setSourceAccountList(filterParticipants(ds));
        setFieldValue(sourceAccount.name, "");
        setFieldValue(participant.name, "");
        setFieldValue(accountBalance.name, 0);
    }

    function filterParticipants(ds) {
        if (!ds) return ds;
        const result = [];

        for (var i = 0; i < ds.length; i++) {
            //we only want accounts that have a credit, as we can only pay those that we owe $ to...
            if (ds[i].account) {
                if (isPrePaymentV || (ds[i].account.accountsReceivable - ds[i].account.accountsPayable) < 0) {
                    result.push(ds[i]);
                }
            }
            else {
                console.warn("account missing, holder id: " + ds[i].id);
            }
        }

        return result;
    }

    function onSourceAccountSelectionChanged({ item, index }) {
        if (item && item.account) {
            setFieldValue(sourceAccount.name, item.account.id);
            setFieldValue(participant.name, item.displayName);
            setSelectedSourceAccountIndex(index);
            setFieldValue(accountBalance.name, item.account.accountsReceivable - item.account.accountsPayable);
        }
    }

    function getPaymentMethod(m) {
        if (m === 1) return "cash";
        if (m === 2) return "electronic";
        if (m === 5) return "electronic";
    }

    useEffect(() => {
        if (!formDataInitialized) {

            setCurrencySymbol(isCurrencyMaskingEnabled() ? "" : "$");

            setFieldValue(type.name, "collect");

            setFieldValue(amount.name, defaults && defaults.payment ? defaults.payment.amount : 0);


            //setFieldValue(targetAccount.name, cardroomAccount.id)
            //setToAccountHolderName(cardroom.name);

            var account = cardroomAccount;
            if (!account || account.accountHolderId != cardroom.id) {
                account = defaults.account;
            }
            //check again...
            if (!account || account.accountHolderId != cardroom.id) {
                account = null;
            }


            if (account) {
                setFieldValue(targetAccount.name, account.id)
                setToAccountHolderName(cardroom.name);
            }
            else {
                setFieldValue(targetAccount.name, null)
                setToAccountHolderName("<INVALID ACCOUNT>");
            }

            setFieldValue(sourceAccount.name, "");
            setFieldValue(participant.name, "");
            setFieldValue(note.name, "");

            const types = [];

            const methods = [];

            if (cardroom.type === 2 || cardroom.type === 3 || cardroom.type === 5 || cardroom.type === 6) {
                methods.push({ key: "electronic", value: "E-Wallet" });
                methods.push({ key: "cash", value: "Cash" });
            }
            else {
                methods.push({ key: "cash", value: "Cash" });
                methods.push({ key: "electronic", value: "E-Wallet" });
            }

            let methodIndex = 0;

            if (defaults && defaults.payment) {
                let m = getPaymentMethod(defaults.payment.paymentMethod);
                if (m) {
                    for (var i = 0; i < methods.length; i++) {
                        if (methods[i].key === m) {
                            methodIndex = i;
                            break;
                        }
                    }
                }

                setFieldValue(provider.name, defaults.payment.ePaymentVendorName ? defaults.payment.ePaymentVendorName : "");
                setFieldValue(providerRefMethod.name, defaults.payment.ePaymentVendorReferenceImage ? "image" : "number");
                setFieldValue(providerRefImage.name, defaults.payment.ePaymentVendorReferenceImage ? defaults.payment.ePaymentVendorReferenceImage : "");
                setFieldValue(providerRef.name, defaults.payment.ePaymentVendorReferenceNo ? defaults.payment.ePaymentVendorReferenceNo : "");

            }

            setFieldValue(isPrePayment.name, Boolean(defaults && defaults.prepayment));

            setFieldValue(method.name, methods[methodIndex].key);
            setPaymentMethods(methods);

            switch (cardroom.type) {
                case 1: //homegame
                    setParticipantType("player");

                    types.push({ key: "player", value: "Player" });
                    types.push({ key: "staff", value: "Staff" });
                    break;
                case 2: //club
                case 6: //book
                    setParticipantType("agent");

                    types.push({ key: "agent", value: "Agent" });
                    types.push({ key: "player", value: "Player" });

                    if (unions && unions.length > 0) {
                        types.push({ key: "union", value: "Union" });
                    }
                    break;
                case 3: //union -- do we need to differntiate between virtual and not???
                    if (unions && unions.length > 0) {
                        setParticipantType("union");

                        types.push({ key: "union", value: "Union" });
                        types.push({ key: "club", value: "Club" });
                    }
                    else {
                        setParticipantType("club");
                        types.push({ key: "club", value: "Club" });
                    }
                    break;
                case 5: //agency
                    setParticipantType("player");

                    types.push({ key: "player", value: "Player" });
                    types.push({ key: "agent", value: "Agent" });
                    break;
            }

            setPayerTypes(types);
            setAccountListUpdateNeeded(true);

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);


    useEffect(() => {
        if (accountListUpdatedNeeded) {

            let ds = null;
            switch (participantType) {
                case "player": //homegame
                    ds = players;
                    break;
                case "agent": //club
                    ds = agents;
                    break;
                case "union": //union 
                    ds = unions;
                    break;
                case "club": //agency
                    ds = clubs;
                    break;
            }

            setSourceAccountList(filterParticipants(ds));

            setAccountListUpdateNeeded(false);
        }

    }, [accountListUpdatedNeeded])

    function onIsPrePaymentChanged() {
        setAccountListUpdateNeeded(true);
    }

    function areMultipleParticipantTypeSupported() {
        return payerTypes && payerTypes.length > 1;
    }

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={chipsAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {isPrePayment.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftBox mb={0.5}>
                                    <FormSwitch
                                        name={isPrePayment.name}
                                        value={isPrePaymentV}
                                        onChange={onIsPrePaymentChanged}
                                        {...formData} />
                                </SoftBox>
                            </Grid>
                        </Grid>

                        {areMultipleParticipantTypeSupported() ?
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            Participant Type
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography pl={1.5}
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize">
                                        <RadioGroup row={true} value={participantType} onChange={onTargetTypeChanged}>
                                            {payerTypes ? payerTypes.map((pt) => {
                                                return <FormControlLabel value={pt.key} control={<Radio />} label={pt.value} />;
                                            }) : null}
                                        </RadioGroup>
                                    </SoftTypography>
                                </Grid>
                            </Grid> : null}


                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {sourceAccount.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <DataBoundSelect placeholder={sourceAccount.placeholder} valueName="id" textName="displayName"
                                    data={sourceAccountList} selectedIndex={selectedSourceAccountIndex} onChange={onSourceAccountSelectionChanged} />

                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    color="error">
                                    <ErrorMessage name={sourceAccount.name} />
                                </SoftTypography>
                            </Grid>
                        </Grid>



                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {targetAccount.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={.5}
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    {toAccountHolderName}
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        {sourceAccountV && (accountBalanceV < 0 || isPrePaymentV) ? <>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {amount.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField onFocus={e => e.currentTarget.select()} autoFocus
                                        type={amount.type}
                                        name={amount.name}
                                        value={amountV}
                                        validate={onAmountChanging}
                                        placeholder={amount.placeholder}
                                        error={errors.amount && touched.amount}
                                        success={amountV && amountV.toString().length > 0 && !errors.amount}
                                    /></Grid>
                            </Grid>

                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            Outstanding Debt
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>

                                    <SoftTypography pl={1.5}
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize">
                                        <MoneyCell value={parseFloat(accountBalanceV)} prefix={currencySymbol} useColorScheme={true} />
                                    </SoftTypography>

                                </Grid>
                            </Grid>


                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {method.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography pl={1.5}
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize">
                                        <RadioGroup row={false} value={methodV} onChange={onPaymentMethodRadioGroupChanged}>
                                            {paymentMethods.map((p) => {
                                                return <FormControlLabel value={p.key} control={<Radio />} label={p.value} />
                                            })}

                                        </RadioGroup>

                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            color="error">
                                            <ErrorMessage name={method.name} />
                                        </SoftTypography>
                                    </SoftTypography>

                                </Grid>
                            </Grid>

                            {methodV === "electronic" ? <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {provider.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography pl={1.5}
                                        component="label"
                                        variant="caption"
                                        textTransform="capitalize">
                                        <RadioGroup row value={providerV} onChange={onEWalletProviderChanged}>
                                            <FormControlLabel value="zelle" control={<Radio />} label="Zelle" />
                                            <FormControlLabel value="paypal" control={<Radio />} label="PayPal" />
                                            <FormControlLabel value="venmo" control={<Radio />} label="Venmo" />
                                            <FormControlLabel value="cashapp" control={<Radio />} label="CashApp" />
                                            <FormControlLabel value="apple" control={<Radio />} label="ApplePay" />
                                            <FormControlLabel value="crypto" control={<Radio />} label="Crypto" />
                                            <FormControlLabel value="other" control={<Radio />} label="Other" />
                                        </RadioGroup>

                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            color="error">
                                            <ErrorMessage name={provider.name} />
                                        </SoftTypography>
                                    </SoftTypography>
                                </Grid>
                            </Grid> : null}

                            {methodV === "electronic" && (providerV === "other" || providerV === "crypto") ? <Grid container xs={12}>
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize">
                                                {providerV === "crypto" ? "Crypto Name" : providerOther.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormField type={providerOther.type} onFocus={e => e.currentTarget.select()}
                                            name={providerOther.name}
                                            value={providerOtherV}
                                            placeholder={providerV === "crypto" ? "crypto name" : providerOther.placeholder}
                                            error={errors.providerOther && touched.providerOther}
                                            success={providerOtherV && providerOtherV.length > 0 && !errors.providerOther}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid> : null}


                            {methodV === "electronic" ? <>
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize">
                                                {providerRefMethod.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <SoftTypography
                                            variant="caption"
                                            textTransform="capitalize">
                                            <RadioGroup row={true} value={providerRefMethodV} onChange={onVendorRefMethodRadioGroupChanged} style={{ marginLeft: "0.75em" }}>
                                                <FormControlLabel value="number" control={<Radio />} label="Number" />
                                                <FormControlLabel value="image" control={<Radio />} label="Image" />
                                            </RadioGroup>
                                        </SoftTypography>
                                    </Grid>
                                </Grid>

                                {providerRefMethodV === "number" ?
                                    <Grid container xs={12}>
                                        <Grid item xs={4} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    {providerRef.label}
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <FormField type={providerRef.type} onFocus={e => e.currentTarget.select()}
                                                name={providerRef.name}
                                                value={providerRefV}
                                                placeholder={providerRef.placeholder}
                                                error={errors.providerRef && touched.providerRef}
                                                success={providerRefV && providerRefV.toString().length > 0 && !errors.providerRef}
                                            />

                                        </Grid>
                                    </Grid> : null}

                                {providerRefMethodV === "image" ?
                                    <Grid container xs={12}>
                                        <Grid item xs={4} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize">
                                                    {providerRefImage.label}
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <FormImageField name={providerRefImage.name} imgWidth="120px" {...formData} />
                                        </Grid>
                                    </Grid> : null}
                            </> : null}

                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {note.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField onFocus={e => e.currentTarget.select()}
                                        type={note.type}
                                        name={note.name}
                                        value={noteV}
                                        placeholder={note.placeholder}
                                        error={errors.note && touched.note}
                                        success={noteV && noteV.length > 0 && !errors.note}
                                    />
                                </Grid>
                            </Grid>
                        </> : null}

                        {selectedSourceAccountIndex > -1 && sourceAccountV && (accountBalanceV >= 0 && !isPrePaymentV) ? <Grid container xs={12}>
                            <Grid item xs={12} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        color="error"
                                        textTransform="capitalize"
                                    >
                                        Account does not have an outstandng balance
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                        </Grid> : null}
                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox>;

};



export default AccountTransactionReceiveForm;