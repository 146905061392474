/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts

import SetContext from "layouts/Context"
import CardroomPage from "layouts/Cardroom/Details";
import CardroomSessionPage from "layouts/Cardroom/Session";
import ParticipantPage from "layouts/Cardroom/Participants";
import ClubPage from "layouts/Club/Club";
import UnionPage from "layouts/Club/Union";

import Shop from "examples/Icons/Shop";
import ReportPage from "layouts/Club/Report";
import AgencyPage from "layouts/Club/Agency";
import BookPage from "layouts/Book";

const routes = [
    {
        type: "collapse",
        name: "Context",
        key: "context",
        icon: <Shop size="12px" />,
        collapse: [
            {
                name: "Context",
                key: "setcontext",
                route: "/context/acquire", //dashboards/default
                component: <SetContext />,
            }
        ],
    },
    {
        type: "collapse",
        name: "Dashboards",
        key: "dashboards",
        icon: <Shop size="12px" />,
        collapse: [
            {
                name: "Cardrooms",
                key: "cardroom",
                route: "/dashboards/cardroom", //dashboards/default
                component: <CardroomPage />,
            }
        ],
    },
    {
        type: "collapse",
        name: "Dashboards",
        key: "dashboards",
        icon: <Shop size="12px" />,
        collapse: [
            {
                name: "Session",
                key: "session",
                route: "/dashboards/cardroom/session", //dashboards/default
                component: <CardroomSessionPage />,
            }
        ],
    },
    {
        type: "collapse",
        name: "Dashboards",
        key: "dashboards",
        icon: <Shop size="12px" />,
        collapse: [
            {
                name: "Player",
                key: "player",
                route: "/dashboards/cardroom/player", //dashboards/default
                component: <ParticipantPage participantType="player" />,
            }
        ],
    },
    {
        type: "collapse",
        name: "Dashboards",
        key: "dashboards",
        icon: <Shop size="12px" />,
        collapse: [
            {
                name: "Staff",
                key: "staff",
                route: "/dashboards/cardroom/staff", //dashboards/default
                component: <ParticipantPage participantType="staff" />,
            }
        ],
    },

    {
        type: "collapse",
        name: "Dashboards",
        key: "dashboards",
        icon: <Shop size="12px" />,
        collapse: [
            {
                name: "Clubs",
                key: "club",
                route: "/dashboards/club",
                component: <ClubPage />,
            }
        ],
    },
    {
        type: "collapse",
        name: "Dashboards",
        key: "dashboards",
        icon: <Shop size="12px" />,
        collapse: [
            {
                name: "Unions",
                key: "union",
                route: "/dashboards/club/report",
                component: <ReportPage audience="club" />,
            }
        ],
    },
    {
        type: "collapse",
        name: "Dashboards",
        key: "dashboards",
        icon: <Shop size="12px" />,
        collapse: [
            {
                name: "Unions",
                key: "union",
                route: "/dashboards/union",
                component: <UnionPage />,
            }
        ],
    },
    {
        type: "collapse",
        name: "Dashboards",
        key: "dashboards",
        icon: <Shop size="12px" />,
        collapse: [
            {
                name: "Unions",
                key: "union",
                route: "/dashboards/union/report",
                component: <ReportPage audience="union" />,
            }
        ],
    },
    {
        type: "collapse",
        name: "Dashboards",
        key: "dashboards",
        icon: <Shop size="12px" />,
        collapse: [
            {
                name: "Agencies",
                key: "agency",
                route: "/dashboards/agency",
                component: <AgencyPage />,
            }
        ],
    },
    {
        type: "collapse",
        name: "Dashboards",
        key: "dashboards",
        icon: <Shop size="12px" />,
        collapse: [
            {
                name: "Agencies",
                key: "agencies",
                route: "/dashboards/agency/report",
                component: <ReportPage audience="agency" />,
            }
        ],
    },
    {
        type: "collapse",
        name: "Dashboards",
        key: "dashboards",
        icon: <Shop size="12px" />,
        collapse: [
            {
                name: "Agencies",
                key: "agency",
                route: "/dashboards/book",
                component: <BookPage />,
            }
        ],
    },
    {
        type: "collapse",
        name: "Dashboards",
        key: "dashboards",
        icon: <Shop size="12px" />,
        collapse: [
            {
                name: "Agencies",
                key: "agencies",
                route: "/dashboards/book/report",
                component: <ReportPage audience="sportsbook" />,
            }
        ],
    }

];

export default routes;
