import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { BootstrapDialog } from "components/Elements/Dialog/common"

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Card, Grid } from '@mui/material';
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';
import PurchaseOrderForm from '../Forms';


//const PurchaseOrderDetailDialog({ open, close, cardroom, purchaseOrder, onPOChanged }){ }

const PurchaseOrderDialog = ({ action, open, close, cardroom, account, union, club, agency, mode, po, onChanged }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    function onPurchaseOrderAdded() {
        if (onChanged) {
            onChanged();
        }
        close();
    }

    function onDialogClosureRequested() {
        close();
    }

    const dropDownData = [
        { id: 1, key: "create", text: "Create Request" },
        { id: 2, key: "manage", text: "Manage Request" }
    ];


    const [selectedTxTypeIndex, setSelectedTxTypeIndex] = useState(-1);
    const [selectedTxType, setSelectedTxType] = useState(null);

    useEffect(() => {
        //clear whenever dialog is opened...

        let idx = 0;
        if (action == "manage") idx = 1;

        setSelectedTxType(dropDownData[idx]);
        setSelectedTxTypeIndex(idx);
    }, [open]);


    function getDialogCaption() {
        if (action == "create") return "New Request";

        if (action == "manage") return "Manage Request";

        return "Request";
    }

    const dropDownSelectionChanged = (e) => {
        const item = e.item;
        const index = e.index;

        setSelectedTxTypeIndex(index);
        setSelectedTxType(item);
    }

    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}
            onClose={close}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="xl">
            <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.secondary.main }}>
                <SoftBox>
                    <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                        {getDialogCaption()}
                    </SoftTypography>
                </SoftBox>
            </DialogTitle>
            <DialogContent dividers>

                <Card style={{ minHeight: 200 }} mt={3}>
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <SoftBox pb={0} px={2} textAlign="center" lineHeight={1.25}>
                                <PurchaseOrderForm cardroom={cardroom} mode={mode} cardroomAccount={account}
                                    union={union} club={club} agency={agency} po={po}
                                    type={selectedTxType ? selectedTxType.key : null} onClose={onDialogClosureRequested} onPurchaseOrderCreated={onPurchaseOrderAdded} />
                            </SoftBox></Grid>
                    </Grid>
                </Card>
            </DialogContent>
        </BootstrapDialog>
    );
};

export default PurchaseOrderDialog;