import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Grid } from '@mui/material';

import { ErrorMessage } from "formik";
import FormField from "components/Elements/Forms/FormField";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import requestAvatar from "assets/graphics/actions/help_bell_256.png";
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';
import { useCardroomContext } from 'features';

const CreatePurchaseOrderForm = ({ cardroom, cardroomAccount, union, club, agency, staff, players, clubs, agents, unions, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { type, purchaseOrderId, buyerAccount, sellerAccount, quantity, itemType, estimatedCost, actualCost, note, deliverTo } = formField;
    const {
        type: typeV,
        purchaseOrderId: purchaseOrderIdV,
        buyerAccount: buyerAccountV,
        sellerAccount: sellerAccountV,
        quantity: quantityV,
        itemType: itemTypeV,
        estimatedCost: estimatedCostV,
        actualCost: actualCostV,
        note: noteV,
        deliverTo: deliverToV
    } = values;


    const [formDataInitialized, setFormDataInitialized] = useState(false);

    const [buyerAccountHolderName, setBuyerAccountHolderName] = useState(null);
    const [sellerAccountHolderName, setSellerAccountHolderName] = useState(null);

    const [participantType, setParticipantType] = useState(null);
    const [sellerAccountList, setSellerAccountList] = useState(null);
    const [selectedSellerAccountIndex, setSelectedSellerAccountIndex] = useState(-1);
    const [sellerTypes, setSellerTypes] = useState([]);
    const [currencySymbol, setCurrencySymbol] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);
    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    const [amountChanged, setAmountChanged] = useState(false);

    function onPurchaseOrderTypeChanged(e, newValue) {
        setFieldValue(itemType.name, newValue);
    }

    function onSellerAccountSelectionChanged({ item, index }) {
        if (item && item.account) {
            setFieldValue(sellerAccount.name, item.account.id);
            // setFieldValue(participant.name, item.displayName);
            setSellerAccountHolderName(item.displayName);
            setSelectedSellerAccountIndex(index);
            //setFieldValue(accountBalance.name, item.account.accountsReceivable - item.account.accountsPayable);
        }
    }

    function onAmountChanging() {
        //alert('changed');
        if (!formDataInitialized) return;

        setAmountChanged(true);
    }

    function onSellerTypeChanged(e, newValue) {

        setParticipantType(newValue);

        let ds = null;

        switch (newValue) {
            case "player":
                ds = players;
                break;
            case "staff":
                ds = staff;
                break;
            case "club":
                ds = clubs;
                break;
            case "agent":
                ds = agents;
                break;
            case "union":
                ds = unions;
                break;
        }

        setSelectedSellerAccountIndex(-1);
        setSellerAccountList(filterParticipants(ds));
        setFieldValue(sellerAccount.name, "");
        //setFieldValue(participant.name, "");
        //setFieldValue(accountBalance.name, 0);

    }

    function filterParticipants(ds) {
        if (!ds) return ds;
        const result = [];

        for (var i = 0; i < ds.length; i++) {
            //we only want accounts that have a credit, as we can only pay those that we owe $ to...

            if (ds[i].account) {
                result.push(ds[i]);
            }
            else {
                console.warn("account missing, holder id: " + ds[i].id);
            }
        }

        if (result.length > 0) {
            //sort by displayName

            result.sort(function (a, b) {
                if (a.displayName < b.displayName) { return -1; }
                if (a.displayName > b.displayName) { return 1; }
                return 0;
            });
        }

        return result;
    }

    function formatAmount(amount, prefix) {
        var delimiter = ","; // replace comma if desired
        var a = amount.toString().split('.', 2)
        var d = a[1];
        var i = parseInt(a[0]);
        if (isNaN(i)) { return ''; }
        var minus = '';
        if (i < 0) { minus = '-'; }
        i = Math.abs(i);
        var n = new String(i);
        var a = [];
        while (n.length > 3) {
            var nn = n.substring(n.length - 3);
            a.unshift(nn);
            n = n.substring(0, n.length - 3);
        }
        if (n.length > 0) { a.unshift(n); }
        n = a.join(delimiter);
        if (!d || d.length < 1) { amount = n; }
        else { amount = n + '.' + d; }
        amount = minus + prefix + amount;
        return amount;
    }

    function getTokenName() {

        let entity = union || club || agency;

        if (entity == union && entity.isVirtual === true) {
            if (unions && unions.length) {
                for (var i = 0; i < unions.length; i++) {
                    if (unions[i].isVirtual === false) {
                        entity = unions[i];
                        break;
                    }
                }
            }
        }

        if (entity != null) {
            return entity.app.tokenName + "s";
        }

        return "Token";
    }

    function getItemName(v) {
        if (v == "chips") return "chips";
        if (v == "tokens") return getTokenName().toLowerCase();

        return "items";
    }

    function getItemUnitCost(v) {
        if (v == "chips") {

            if (union) return union.chipUnitCost;

            return 0.05;
        }
        if (v == "tokens") {
            if (union) return union.tokenUnitCost;
            return 0.013890002;
        }

        return 1;
    }

    useEffect(() => {
        if (amountChanged && formDataInitialized) {
            setFieldValue(estimatedCost.name, (parseFloat(quantityV) * getItemUnitCost(itemTypeV)).toFixed(2));
            setAmountChanged(false);
        }
    }, [amountChanged, formDataInitialized]);

    useEffect(() => {
        if (!formDataInitialized) {

            setCurrencySymbol(isCurrencyMaskingEnabled() === true ? "" : "$");

            setFieldValue(type.name, "create");

            setFieldValue(quantity.name, 0);
            setFieldValue(estimatedCost.name, 0);
            setFieldValue(actualCost.name, 0);

            setFieldValue(buyerAccount.name, cardroomAccount.id)
            setBuyerAccountHolderName(cardroom.name);

            setFieldValue(sellerAccount.name, "");
            setSellerAccountHolderName(null);

            setFieldValue(note.name, "");

            setAmountChanged(false);

            const types = [];

            let ds = null;
            switch (cardroom.type) {
                case 1: //homegame --- CAN THIS EVEN BE ALLOWED?
                    setParticipantType("player");
                    ds = players;
                    types.push({ key: "player", value: "Player" });
                    types.push({ key: "staff", value: "Staff" });
                    break;
                case 2: //club
                    setParticipantType("union");
                    ds = unions;

                    types.push({ key: "union", value: "Union" });

                    //   if (agents && agents.length > 0)
                    //       types.push({ key: "agent", value: "Agent" });
                    //   if (players && players.length > 0)
                    //       types.push({ key: "player", value: "Player" });

                    break;
                case 3: //union -- do we need to differntiate between virtual and not???
                    if (unions && unions.length > 0) {
                        setParticipantType("union");
                        ds = unions;

                        //if current union is virtual, then value; Union, else "Umbrella Union"
                        types.push({ key: "union", value: "Union" });
                        //    types.push({ key: "club", value: "Club" });
                    }
                    //  else {
                    //      setParticipantType("club");
                    //      ds = clubs;
                    //      types.push({ key: "club", value: "Club" });
                    //  }
                    break;
                case 5: //agency
                    setParticipantType("club");
                    ds = clubs;

                    types.push({ key: "club", value: "Club" });
                    // types.push({ key: "player", value: "Player" });
                    // types.push({ key: "agent", value: "Agent" });
                    break;
            }

            setSellerTypes(types);
            setSellerAccountList(filterParticipants(ds));

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    function areMultipleParticipantTypeSupported() {
        return sellerTypes && sellerTypes.length > 1;
    }

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={requestAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {itemType.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={1.5}
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize">
                                    <RadioGroup row={true} value={itemTypeV} onChange={onPurchaseOrderTypeChanged}>
                                        <FormControlLabel value="chips" control={<Radio />} label="Chips" />
                                        <FormControlLabel value="tokens" control={<Radio />} label={getTokenName()} />
                                    </RadioGroup>

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={itemType.name} />
                                    </SoftTypography>
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        {itemTypeV ?
                            <>
                                {areMultipleParticipantTypeSupported() ?
                                    <Grid container xs={12}>
                                        <Grid item xs={4} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    Account Type
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <SoftTypography pl={1.5}
                                                component="label"
                                                variant="caption"
                                                textTransform="capitalize">
                                                <RadioGroup row={true} value={participantType} onChange={onSellerTypeChanged}>
                                                    {sellerTypes ? sellerTypes.map((pt) => {
                                                        return <FormControlLabel value={pt.key} control={<Radio />} label={pt.value} />;
                                                    }) : null}
                                                </RadioGroup>
                                            </SoftTypography>
                                        </Grid>
                                    </Grid> : null}



                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                Account
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <DataBoundSelect placeholder="account" valueName="id" textName="displayName"
                                            data={sellerAccountList} selectedIndex={selectedSellerAccountIndex} onChange={onSellerAccountSelectionChanged} />

                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            color="error">
                                            <ErrorMessage name={sellerAccount.name} />
                                        </SoftTypography>
                                    </Grid>
                                </Grid>

                                {sellerAccountV ?
                                    <>
                                        {itemTypeV === "tokens" ?
                                            <Grid container xs={12}>
                                                <Grid item xs={4} >
                                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize"
                                                        >
                                                            Deliver to player id
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <FormField onFocus={e => e.currentTarget.select()} autoFocus
                                                        type={deliverTo.type}
                                                        name={deliverTo.name}
                                                        value={deliverToV}
                                                        placeholder={deliverTo.placeholder}
                                                        error={errors.deliverTo && touched.deliverTo}
                                                        success={deliverToV && deliverToV.length > 0 && !errors.deliverTo}
                                                    /></Grid>
                                            </Grid> : null}


                                        <Grid container xs={12}>
                                            <Grid item xs={4} >
                                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                    >
                                                        {quantity.label}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <FormField onFocus={e => e.currentTarget.select()} autoFocus
                                                    type={quantity.type}
                                                    name={quantity.name}
                                                    value={quantityV}
                                                    validate={onAmountChanging}

                                                    placeholder={quantity.placeholder}
                                                    error={errors.quantity && touched.quantity}
                                                    success={quantityV && quantityV.toString().length > 0 && !errors.quantity}
                                                /></Grid>
                                        </Grid>

                                        <Grid container xs={12}>
                                            <Grid item xs={4} >
                                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                    >
                                                        {estimatedCost.label}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize">
                                                    {formatAmount(estimatedCostV, currencySymbol)}
                                                </SoftTypography>

                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    color="error">
                                                    <ErrorMessage name={estimatedCost.name} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>

                                        <Grid container xs={12}>
                                            <Grid item xs={4} >
                                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize">
                                                        {note.label}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <FormField onFocus={e => e.currentTarget.select()}
                                                    type={note.type}
                                                    name={note.name}
                                                    value={noteV}
                                                    placeholder={note.placeholder}
                                                    error={errors.note && touched.note}
                                                    success={noteV && noteV.length > 0 && !errors.note}
                                                />
                                            </Grid>
                                        </Grid>
                                    </> : null}

                            </> : null}

                        <Grid container xs={12}>
                            <Grid item xs={12} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        color="info"
                                    >
                                        {sellerAccountHolderName} will be requested to provide {buyerAccountHolderName} with {formatAmount(quantityV, "")} {getItemName(itemTypeV)}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                        </Grid>

                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox >;
};


export default CreatePurchaseOrderForm;