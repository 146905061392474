
import { apiConfig } from 'config/apiConfig'
import { Grid, Link } from "@mui/material";
import defaultCasino from "assets/graphics/logos/default-casino.png";
import SoftBox from "components/SoftBox";
import { useDialog } from '../Dialog/common';
import { CasinoSettingsDialog } from '../Dialog/settings';
import { useState, useEffect } from 'react';


const CardroomLogo = ({ cardroom, club, union, agency, book, canOpenProperties, customAction }) => {

    const [openCasinoPropertiesDialog, casinoPropertiesDialogProps] = useDialog();
    const [reloadCardroomLogo, setReloadCardroomLogo] = useState(false);

    function onCardroomPropertiesChanged() {
        setReloadCardroomLogo(true);
    }

    useEffect(() => {
        if (reloadCardroomLogo) {
            setReloadCardroomLogo(false);
        }
    }, [reloadCardroomLogo]);

    return <Grid item xs={12} lg={1}>
        {canOpenProperties ? <CasinoSettingsDialog {...casinoPropertiesDialogProps} cardroom={cardroom} club={club} union={union} agency={agency} book={book} onCardroomChanged={onCardroomPropertiesChanged} /> : null}
        <Link onClick={canOpenProperties ? openCasinoPropertiesDialog : customAction} style={{ cursor: "pointer" }}>

            <SoftBox textAlign="center">
                {reloadCardroomLogo ? null :
                    <SoftBox component="img"
                        src={cardroom && cardroom.hasLogo ? apiConfig.images.endpoint + "/casino/" + cardroom.id + ".png" : defaultCasino}
                        alt="cardroom logo"
                        display="flex"
                        width={{ lg: "82px", sm: "96px", xl: "96px" }}
                        ml="auto"
                        mr="auto" />}
            </SoftBox>
        </Link>
    </Grid>
};




export default CardroomLogo;