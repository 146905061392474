import form from "./form";


const {
    formField: {
        playerId,
        clubId,
        rebateSchedule,
        rebateScheduleName,
        agentId,
        superAgentId,
        canAssignAgent
    },
} = form;

const initialValues = {
    [playerId.name]: "",
    [clubId.name]: "",
    [rebateSchedule.name]: "",
    [rebateScheduleName.name]: "",
    [agentId.name]: "",
    [superAgentId.name]: "",
    [canAssignAgent.name]: false
};

export default initialValues;
