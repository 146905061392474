// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import Link from '@mui/material/Link';
import SoftButton from "components/SoftButton";



const ParticipantCell = ({ image, name, color, selectable, checked, participant, onClick }) => {

    function onLinkClicked() {
        if (onClick) {
            onClick(participant);
        }
    }

    const linkStyle = {};
    if (onClick) {
        linkStyle.cursor = "pointer";
    }
    return (<Link style={linkStyle} onClick={onLinkClicked} to="#">
        <SoftBox display="flex" alignItems="center" >
            {selectable ? <Checkbox defaultChecked={checked} /> : null}
            <SoftBox mr={1}>
                <SoftAvatar bgColor={color} src={image} alt={name} size="xs" />
            </SoftBox>
            <SoftTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }} style={{ whiteSpace: "nowrap" }}>
                {name}
            </SoftTypography>
        </SoftBox>
    </Link >
    );
}

// Setting default value for the props of CustomerCell
ParticipantCell.defaultProps = {
    image: "",
    color: "dark",
    checked: false,
    selectable: false
};

// Typechecking props for the CustomerCell
ParticipantCell.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
        "transparent",
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    selectable: PropTypes.bool,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    participant: PropTypes.object
};

export default ParticipantCell;
