import form from "./form";


const {
    formField: {
        defaultCurrency,
        supportedCurrencies
    },
} = form;

const initialValues = {
    [defaultCurrency.name]: "USD",
    [supportedCurrencies.name]: 1
};

export default initialValues;
