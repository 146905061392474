
import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';

import { Card, Divider, Grid, Icon, Paper, Stack, Switch, Tooltip, styled } from '@mui/material';

import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import defaultCasino from "assets/graphics/logos/default-casino.png";
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';

import PremiumServiceSubscriptionRequired from 'components/Elements/PremiumServiceRequried';
import { ReportAgents, ReportPlayers } from '../common';
import { useAgentCalculator, usePlayerCalculator, useUnionCalculator } from '../common/calculator';
import { useReportDataLoader } from '../common/data';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import AdaptiveTab from 'components/Elements/AdaptiveTab';
import AdhocLine, { AdhocLineDialog } from '../AdhocLine';
import { useDialog } from 'components/Elements/Dialog/common';
import SoftButton from 'components/SoftButton';
import { FeatureRequiresPremiumSubDialog } from 'components/Elements/PremiumServiceRequried';
import { ReportValueRedacted } from 'components/Elements/PremiumServiceRequried';



const utilities = {
    getValueInActiveCurrency: function (v, calculator, currency, currencyConverter) {
        if (!v) return calculator.zero();

        var v = currencyConverter.convertFromUSD(v, currency);

        return calculator.formatDecimalAsCurrency(v, currency.precision);
    }
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: "none"
}));

const ReportSummaryDetailCard = ({ cardroom, club, reportingPeriod, summary, legend, calculator, currency, currencySymbol, currencyConverter, onLineEdit, onLineDelete, isPremiumEnabled, showDetailedBreakdown }) => {

    function formatDecimalValue(v) {
        return calculator.formatDecimalAsCurrency(v);
    }

    function getValueInActiveCurrency(v) {
        return utilities.getValueInActiveCurrency(v, calculator, currency, currencyConverter);
    }

    return legend ? <Item style={{ maxWidth: "175px", backgroundColor: 'transparent', shadowOpacity: 0 }}>
        <Grid container xs={12} >

            <Grid xs={12} style={{ whiteSpace: "nowrap", borderTop: "1px solid black" }} textAlign="left" >
                <Tooltip title="Amount won or lost by all clubs in the union" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Winnings
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Amount of fees generated by all clubs in the union" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Fee
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left" >
                <Tooltip title="Amount earned (winnings + fees) by all clubs in the union" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="bold" textTransform="uppercase">
                        Earnings
                    </SoftTypography>
                </Tooltip>
            </Grid>

            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Number of players in the club that reported activity this reporting period" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Active Players
                    </SoftTypography>
                </Tooltip>
            </Grid>
            {showDetailedBreakdown ? <>
                <Grid xs={12} style={{ whiteSpace: "nowrap", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }} textAlign="left">
                    <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                        Union Dues Breakdown
                    </SoftTypography>
                </Grid>

                <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                    <Tooltip title="Effective union dues rate assessed by the union" placement="top">
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                            Union Dues Rate
                        </SoftTypography>
                    </Tooltip>
                </Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                    <Tooltip title="Fixed amount assessed as part of the union dues" placement="top">
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                            Union Dues (Base)
                        </SoftTypography>
                    </Tooltip>
                </Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                    <Tooltip title="Variable portion of union dues based on the Union Dues Rate" placement="top">
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                            Union Dues (Variable)
                        </SoftTypography>
                    </Tooltip>
                </Grid>
                {summary.mttBackoffRate > 0 ? <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                    <Tooltip title="Percentage of MTT Fees withheld" placement="top">
                        <SoftTypography variant="caption" color="secondary" fontWeight="bold" textTransform="uppercase">
                            MTT Backoff Rate
                        </SoftTypography>
                    </Tooltip>                </Grid> : null}

                <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                    <Tooltip title="Total union dues owed by the club" placement="top">
                        <SoftTypography variant="caption" color="secondary" fontWeight="bold" textTransform="uppercase">
                            Union Dues (Total)
                        </SoftTypography>
                    </Tooltip>
                </Grid>
            </> : null}

            <Grid xs={12} style={{ whiteSpace: "nowrap", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }} textAlign="left">
                <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                    Expenses
                </SoftTypography>
            </Grid>

            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Dues owed by the club to its union" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Union Dues
                    </SoftTypography>
                </Tooltip>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Dues owed based on XMTT Earnings (XMTT Dues Rate * XMTT Earnings)" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        XMTT Dues
                    </SoftTypography>
                </Tooltip>
            </Grid>
            {cardroom.type === 2 || cardroom.type === 5 || cardroom.type === 6 ? <>
                <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                    <Tooltip title="Commission paid to agents" placement="top">
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                            Agent Commissions
                        </SoftTypography>
                    </Tooltip>
                </Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                    <Tooltip title="Rebates paid back to players" placement="top">
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                            Player Rebates
                        </SoftTypography>
                    </Tooltip>
                </Grid>
            </> : null}

            {cardroom.type === 2 ? <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Club manager fees incurred during this reporting period" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Club Manager
                    </SoftTypography>
                </Tooltip>
            </Grid> : null}

            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Data reporting costs incurred during this reporting period" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Reporting
                    </SoftTypography>
                </Tooltip>
            </Grid>

            {summary.reportingResponisbleFor > 0 ? <>
                <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                    <Tooltip title="Data reporting costs to be collected from entities the club is responsible for" placement="top">
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                            Reporting Recovery
                        </SoftTypography>
                    </Tooltip>
                </Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                    <Tooltip title="Data reporting costs rebate resulting from entities club is responsible for" placement="top">
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                            Reporting Rebate
                        </SoftTypography>
                    </Tooltip>
                </Grid></> : null}


            {
                isPremiumEnabled && summary.adhoc && summary.adhoc.expenses ? <>
                    {summary.adhoc.expenses.map((expense) => {
                        return <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                            <AdhocLine cardroom={cardroom} club={club} reportingPeriod={reportingPeriod} line={expense} onLineEdit={onLineEdit} onLineDelete={onLineDelete} />
                        </Grid>

                    })}
                </> : null
            }

            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title={"Incurred expenses (" + (reportingPeriod && reportingPeriod.app ? reportingPeriod.app.tokenName : "token").toLowerCase() + " costs + Union Dues + XMTT Dues + Commissions Paid + Management & Reporting costs)"} placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="bold" textTransform="uppercase">
                        Total Expenses
                    </SoftTypography>
                </Tooltip>
            </Grid>


            {showDetailedBreakdown ? <>
                <Grid xs={12} style={{ whiteSpace: "nowrap", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }} textAlign="left">
                    <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                        ECO / Chip Offset Breakdown
                    </SoftTypography>
                </Grid>

                <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                    <Tooltip title="Chip offset recovery rate" placement="top">
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                            Chip Offset Rate
                        </SoftTypography>
                    </Tooltip>
                </Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                    <Tooltip title="Total chip offset amount" placement="top">
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                            Chip Offset Amount
                        </SoftTypography>
                    </Tooltip>
                </Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                    <Tooltip title="Ecosystem health tax/rebate rate" placement="top">
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                            Tax / Rebate Rate
                        </SoftTypography>
                    </Tooltip>
                </Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                    <Tooltip title="Total ecosystem health tax/rebate assessed" placement="top">
                        <SoftTypography variant="caption" color="secondary" fontWeight="bold" textTransform="uppercase">
                            Tax / Rebate Amount
                        </SoftTypography>
                    </Tooltip>
                </Grid>
            </> : null}



            <Grid xs={12} style={{ whiteSpace: "nowrap", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }} textAlign="left">
                <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                    Profit Calcs
                </SoftTypography>
            </Grid>

            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Total Expenses
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="Amount earned from referred clubs" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        Referrals Earned
                    </SoftTypography>
                </Tooltip>
            </Grid>

            {
                summary && summary.loyaltyRebate > 0 ?
                    <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                        <Tooltip title="Amount earned in loyalty dividends" placement="top">
                            <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                                Loyalty Dividend
                            </SoftTypography>
                        </Tooltip>
                    </Grid> : null
            }

            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Club Chip Offset
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Club Tax Rebate
                </SoftTypography>
            </Grid>

            {
                cardroom.type === 2 ? <>
                    <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                            Agents' Chip Offset
                        </SoftTypography>
                    </Grid>
                    <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                        <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                            Agents' Tax Rebate
                        </SoftTypography>
                    </Grid>
                    <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                        <Tooltip title="Total profit generated by the club" placement="top">
                            <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                                Total Profit
                            </SoftTypography>
                        </Tooltip>
                    </Grid>
                </> : null
            }
            <Grid xs={12} style={{ whiteSpace: "nowrap", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }} textAlign="left">
                <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                    Settlement Calcs
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Total Earnings
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Union Dues
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    XMTT Dues
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Club Chip Offset
                </SoftTypography>
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Club Tax / Rebate
                </SoftTypography>
            </Grid>


            {summary && summary.mttBackoffRate > 0 ? <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <Tooltip title="MTT Fees withheld" placement="top">
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                        MTT Backoff Amount
                    </SoftTypography>
                </Tooltip>
            </Grid> : null}

            <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                    Referrals Earned
                </SoftTypography>
            </Grid>
            {
                summary && summary.loyaltyRebate > 0 ?
                    <Grid xs={12} style={{ whiteSpace: "nowrap" }} textAlign="left">
                        <Tooltip title="Amount earned in loyalty dividends" placement="top">
                            <SoftTypography variant="caption" color="secondary" fontWeight="medium" textTransform="uppercase">
                                Loyalty Dividend
                            </SoftTypography>
                        </Tooltip>
                    </Grid> : null
            }
            <Grid xs={12} style={{ whiteSpace: "nowrap", borderTop: "1px solid black" }} textAlign="left">
                <Tooltip title="Total earnings + XMTT Dues + Chip Offset + Tax Rebate + Expenses" placement="top">
                    <SoftTypography variant="caption" color="error" fontWeight="bold" textTransform="uppercase">
                        Net Settlement
                    </SoftTypography>
                </Tooltip>
            </Grid>
        </Grid >
    </Item > : <Item style={{ minWidth: "125px", backgroundColor: 'transparent', shadowOpacity: 0 }}>
        <Grid container xs={12} >
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderTop: "1px solid black" }}><MoneyCell value={formatDecimalValue(summary.winnings)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.fees)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.totalEarnings)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={summary.playerCount} prefix="" useColorScheme={false} /></Grid>


            {showDetailedBreakdown ? <>
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }}> <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                    &nbsp;
                </SoftTypography></Grid>

                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={summary.unionDuesEffectiveRate * 100} prefix={""} suffix={"%"} useColorScheme={false} /></Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.unionDuesBaseValue)} prefix={currencySymbol} /></Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.unionDuesVariable)} prefix={currencySymbol} /></Grid>

                {summary.mttBackoffRate > 0 ? <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={summary.mttBackoffRate * 100} prefix={""} suffix="%" useColorScheme={false} /></Grid>
                    : null}


                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.unionDues)} prefix={currencySymbol} /></Grid>
            </> : null}

            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }}> <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                &nbsp;
            </SoftTypography></Grid>

            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.unionDues)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.xmttDues)} prefix={currencySymbol} /></Grid>
            {cardroom.type === 2 || cardroom.type === 5 || cardroom.type === 6 ? <>
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                    {isPremiumEnabled ? <MoneyCell value={getValueInActiveCurrency(summary.commissionPaid)} prefix={currencySymbol} /> : <ReportValueRedacted />}
                </Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                    {isPremiumEnabled ? <MoneyCell value={getValueInActiveCurrency(summary.rebatesPaid)} prefix={currencySymbol} /> : <ReportValueRedacted />}
                </Grid>
            </> : null}

            {cardroom.type === 2 ?
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                    {isPremiumEnabled ? <MoneyCell value={getValueInActiveCurrency(summary.manager)} prefix={currencySymbol} /> : <ReportValueRedacted />}
                </Grid>
                : null}

            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                {isPremiumEnabled ? <MoneyCell value={getValueInActiveCurrency(summary.reporting)} prefix={currencySymbol} /> : <ReportValueRedacted />}
            </Grid>

            {summary.reportingResponisbleFor > 0 ? <>
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                    {isPremiumEnabled ? <MoneyCell value={getValueInActiveCurrency(summary.reportingResponisbleFor)} prefix={currencySymbol} /> : <ReportValueRedacted />}
                </Grid>

                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                    {isPremiumEnabled ? <MoneyCell value={getValueInActiveCurrency(summary.reportingResponsibilityRebate)} prefix={currencySymbol} /> : <ReportValueRedacted />}
                </Grid></> : null}

            {isPremiumEnabled && summary.adhoc && summary.adhoc.expenses ? summary.adhoc.expenses.map((expense) => {
                return <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(expense.amount * -1)} prefix={currencySymbol} /></Grid>
            }) : null}

            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                {isPremiumEnabled ? <MoneyCell value={getValueInActiveCurrency(summary.totalExpenses)} prefix={currencySymbol} /> : <ReportValueRedacted />}
            </Grid>


            {showDetailedBreakdown ? <>
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }}> <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                    &nbsp;
                </SoftTypography></Grid>

                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={summary.chipOffsetEffectiveRate * 100} prefix={""} suffix={"%"} useColorScheme={false} /></Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.chipOffset)} prefix={currencySymbol} /></Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={summary.taxRebateEffectiveRate * 100} prefix={""} suffix={"%"} useColorScheme={false} /></Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.tax)} prefix={currencySymbol} /></Grid>
            </> : null}

            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }}> <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                &nbsp;
            </SoftTypography></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                {isPremiumEnabled ? <MoneyCell value={getValueInActiveCurrency(summary.totalExpenses)} prefix={currencySymbol} /> : <ReportValueRedacted />}
            </Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.referralBonus)} prefix={currencySymbol} /></Grid>
            {summary && summary.loyaltyRebate > 0 ?
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.loyaltyRebate)} prefix={currencySymbol} /></Grid> : null}

            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.chipOffset)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.tax)} prefix={currencySymbol} /></Grid>

            {cardroom.type === 2 ? <>
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                    {isPremiumEnabled ? <MoneyCell value={getValueInActiveCurrency(summary.chipOffsetAssessed)} prefix={currencySymbol} /> : <ReportValueRedacted />}
                </Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                    {isPremiumEnabled ? <MoneyCell value={getValueInActiveCurrency(summary.taxRebateAssessed)} prefix={currencySymbol} /> : <ReportValueRedacted />}
                </Grid>
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                    {isPremiumEnabled ? <MoneyCell value={getValueInActiveCurrency(summary.totalProfit)} prefix={currencySymbol} /> : <ReportValueRedacted />}
                </Grid>
            </> : null}
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderBottom: "1px solid #17c1e8", borderTop: "1px solid #17c1e8" }}> <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="uppercase">
                &nbsp;
            </SoftTypography></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.totalEarnings)} prefix={currencySymbol} /></Grid>

            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.unionDues)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.xmttDues)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.chipOffset)} prefix={currencySymbol} /></Grid>
            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.tax)} prefix={currencySymbol} /></Grid>

            {summary && summary.mttBackoffRate > 0 ?
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.mttBackoffAmount)} prefix={currencySymbol} /></Grid>
                : null}

            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.referralBonus)} prefix={currencySymbol} /></Grid>

            {summary && summary.loyaltyRebate > 0 ?
                <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right" }}><MoneyCell value={getValueInActiveCurrency(summary.loyaltyRebate)} prefix={currencySymbol} /></Grid> : null}

            <Grid xs={12} style={{ whiteSpace: "nowrap", textAlign: "right", borderTop: "1px solid black" }}><MoneyCell value={getValueInActiveCurrency(summary.settlement)} prefix={currencySymbol} /></Grid>
        </Grid>
    </Item >
};

const ReportSummaryDetail = ({ cardroom, club, reportingPeriod, summary, currency, currencyConverter, callback, isPremiumEnabled }) => {

    const cardroomLogo = cardroom && cardroom.hasLogo ? apiConfig.images.endpoint + "/casino/" + cardroom.id + ".png" : defaultCasino;
    const appLogo = club && club.app ? apiConfig.images.endpoint + "/app/" + club.app.id + ".png" : null;

    const [players, setPlayers] = useState(null);
    const [currencySymbol, setCurrencySymbol] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);

    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    useEffect(() => {
        if (true === isCurrencyMaskingEnabled()) {
            setCurrencySymbol("");
            return;
        }

        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency, currencyConverter]);

    const [retryCounter, setRetryCounter] = useState(0);
    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading report details...", handleRetry)

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const dataLoader = useReportDataLoader();

    useEffect(() => {
        if (!players) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading player details...")
            showProgress();

            dataLoader.loadPlayers(clubRequest, cardroom, reportingPeriod, summary, club, null, true, (response) => {
                //success
                if (response) {
                    setPlayers(response);
                }
            }, (ex) => {
                //error
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load player details, plese try again...");
            });
        }
    }, [clubRequest.execute, players, retryCounter]);


    useEffect(() => {
        if (reportingPeriod && players && summary) {
            calculateSummary(reportingPeriod, summary, players);

            //register callback...
            if (callback) {
                callback({
                    addNewLine: onAddNewLine
                });
            }

            progressIndicatorProps.close();
        }
    }, [players, summary, reportingPeriod]);


    const [openAdhocLineEditor, adhocLineEditorProps] = useDialog();
    const [currentAdhocLine, setCurrentAdhocLine] = useState(null);
    const [currentAdhocAction, setCurrentAdhocAction] = useState(null);

    function canEditAdhocLine() {

        if (!context) return false;

        if (cardroom.id != reportingPeriod.casinoId) return false;

        if (!club) return false; // club must be present

        if (cardroom.id != club.managedByCasinoId) return false;


        return context.isAllowed(actions.club.reports.adhoc.edit);
    }


    const agentCalculator = useAgentCalculator();
    const playerCalculator = usePlayerCalculator();
    const unionCalculator = useUnionCalculator();

    function calculateSummary(period, summary, players) {

        const agents = agentCalculator.getEffectiveAgentList(players, cardroom, null, false);


        function getUnionDuesEffectiveRate(clubSummary) {
            if (!clubSummary) return 0;
            return clubSummary.unionDuesRate;
        }

        function getUnionDuesBaseAmount(clubSummary) {
            if (!clubSummary) return 0;

            if (clubSummary.playerCount == 0) return 0;

            return (clubSummary.unionDuesBase) * -1;
        }

        function getUnionDuesVariableAmount(clubSummary) {

            if (!clubSummary) return 0;

            let fees = clubSummary.fees - unionCalculator.mttBackoff(clubSummary);

            return (getUnionDuesEffectiveRate(clubSummary) * fees * -1);
        }


        function getChipOffsetEffectiveRate(clubSummary) {
            if (!clubSummary) return 0;
            return clubSummary.chipOffsetRate;
        }


        function getTaxRebateEffectiveRate(clubSummary) {
            if (!clubSummary) return 0;
            return clubSummary.taxRebateRate;
        }

        function getWinTaxRateEffectiveRate(clubSummary) {
            if (!clubSummary) return 0;
            return clubSummary.winTaxRate;
        }

        const calcs = {
            agent: {
                commission: 0,
                chipOffset: 0,
                taxRebate: 0
            },
            player: {
                rebate: 0
            },
            tokens: {
                cost: 0
            }
        };

        for (var i = 0; i < players.length; i++) {
            calcs.player.rebate += playerCalculator.rebate(players[i]);
        }

        for (var i = 0; i < agents.length; i++) {

            let numbers = agentCalculator.playerAggregates(agents[i]);

            let tmp = agentCalculator.comission(agents[i], numbers);
            calcs.agent.commission += isNaN(tmp) ? 0 : tmp;

            tmp = agentCalculator.chipOffset(agents[i], numbers);
            calcs.agent.chipOffset += isNaN(tmp) ? 0 : tmp;

            tmp = agentCalculator.taxRebate(agents[i], numbers);
            calcs.agent.taxRebate += isNaN(tmp) ? 0 : tmp;
        }

        //categorize expenses
        summary.adhoc = agentCalculator.categorizeLineItems(period);
        let adhocExpenses = 0;
        if (summary.adhoc) {
            if (summary.adhoc.expenses) {
                for (var i = 0; i < summary.adhoc.expenses.length; i++) {
                    adhocExpenses += summary.adhoc.expenses[i].amount;
                }
            }
        }
        adhocExpenses = -1 * adhocExpenses;

        summary.totalEarnings = unionCalculator.earnings(summary);
        summary.unionDues = unionCalculator.unionDues(summary);

        summary.unionDuesBaseValue = getUnionDuesBaseAmount(summary);
        summary.unionDuesEffectiveRate = getUnionDuesEffectiveRate(summary);
        summary.unionDuesVariable = getUnionDuesVariableAmount(summary);

        summary.chipOffsetEffectiveRate = getChipOffsetEffectiveRate(summary);
        summary.taxRebateEffectiveRate = getTaxRebateEffectiveRate(summary);
        summary.winTaxEffectiveRate = getWinTaxRateEffectiveRate(summary);

        summary.mttBackoffAmount = -1 * unionCalculator.mttBackoff(summary);

        summary.xmttDues = unionCalculator.xmttDues(summary);

        //added managedByCasinoId check to ensure clubs don't see union reporting costs when viewing from union->club perspective -- may be an issue when clubs have their own reporting costs
        if (!summary.cost) {
            summary.reporting = -1 * (reportingPeriod.casinoId == cardroom.id && club.managedByCasinoId == cardroom.id ? reportingPeriod.cost : 0);
            summary.reportingResponisbleFor = 0;
            summary.reportingResponsibilityRebate = 0;
        }
        else {
            summary.reporting = -1 * summary.cost.base;
            summary.reportingResponisbleFor = summary.cost.responsibleFor;
            summary.reportingResponsibilityRebate = summary.cost.responsibilityRebate;
        }

        summary.chipOffset = unionCalculator.chipOffset(summary);
        summary.tax = unionCalculator.ecoTax(summary);

        summary.commissionPaid = -1 * calcs.agent.commission;
        summary.chipOffsetAssessed = -1 * calcs.agent.chipOffset;
        summary.taxRebateAssessed = -1 * calcs.agent.taxRebate;

        summary.rebatesPaid = -1 * calcs.player.rebate;

        //THE LOGIC IS CORRECT< however, when navigating from PARENT union to CHILD union, this value is modfied by the child union...
        //which is really odd since totals is a local variable....
        summary.mttOverlay = summary.mttOverlay * summary.feeRatio; // * totals.percentage;

        //totals.tokens.used = totalTokens * summary.feeRatio;
        //totals.tokens.cost = union.tokenUnitCost * totals.tokens.used;
        summary.manager = club.clubManagerFee * -1;

        summary.totalExpenses = adhocExpenses + summary.unionDues + summary.xmttDues;
        //add club/agency specific expenses only if report is being viewed by that club/agency
        //not sure if type is sufficient or we should be looking at cardroom.id vs reportingPeriod.ownerId
        if (cardroom.type == 2 || cardroom.type == 5 || cardroom.type == 6) {
            summary.totalExpenses += summary.reporting + summary.manager + calcs.tokens.cost + summary.commissionPaid + summary.rebatesPaid;
        }


        summary.totalProfit = summary.fees + summary.totalExpenses + summary.chipOffset + summary.tax + summary.chipOffsetAssessed + summary.taxRebateAssessed + summary.referralBonus;

        //union dues are alread NEGATIVE, so just add them
        summary.settlement = summary.totalEarnings + summary.unionDues + summary.xmttDues + summary.chipOffset + summary.tax + summary.referralBonus + summary.loyaltyRebate + summary.mttBackoffAmount;

    }

    function formatDecimalValue(v) {
        if (!v) return agentCalculator.zero();

        var v = currencyConverter.convertFromUSD(v, currency);

        return v.toFixed(currency.precision);
    }

    function onLineEditRequested(line) {
        if (!line) return;

        setCurrentAdhocAction("edit");
        setCurrentAdhocLine(line);
        openAdhocLineEditor();
    }

    function onLineDeleteRequested(line) {
        if (!line) return;

        setCurrentAdhocAction("delete");
        setCurrentAdhocLine(line);
        openAdhocLineEditor();
    }

    function onAdhocLineActionWasCompleted(line, action) {

        //look for line in period.adhocLineItems... if there, update the line, if not there add
        let wasFound = false;
        for (var i = 0; i < reportingPeriod.adhocLineItems.length; i++) {
            if (reportingPeriod.adhocLineItems[i].id === line.id) {

                if (action === "delete") {
                    reportingPeriod.adhocLineItems.splice(i, 1);
                }
                else {
                    reportingPeriod.adhocLineItems[i] = Object.assign(reportingPeriod.adhocLineItems[i], line);
                }
                wasFound = true;
                break;
            }
        }

        if (false == wasFound && action !== "delete") {
            reportingPeriod.adhocLineItems.push(line);
        }

        calculateSummary(reportingPeriod, summary, players);
        setCurrentAdhocLine(null);
        setCurrentAdhocAction(null);
    }

    function onAddNewLine() {
        var line = {
            id: "00000000-0000-0000-0000-000000000000",
            title: "",
            amount: 0,
            category: "Expense",
            ownedByReportId: reportingPeriod.id
        };

        setCurrentAdhocAction("add");
        setCurrentAdhocLine(line);
        openAdhocLineEditor();
    }

    const [showDetailedBreakdown, setShowDetailedBreakdown] = useState(false);

    function formatStartEndDates(start, end) {

        function formatDateForDisplay(dt) {
            const year = dt.getFullYear();
            const month = (dt.getMonth() + 1).toString().padStart(2, "0");
            const day = dt.getDate().toString().padStart(2, "0");

            return month + "/" + day;
        }

        let result = "";
        if (start) {
            let sdt = new Date(Date.parse(start));
            result += formatDateForDisplay(sdt); // sdt.toLocaleDateString();
        }

        if (end) {
            let edt = new Date(Date.parse(end));
            if (result) result += " - ";
            result += formatDateForDisplay(edt); //edt.toLocaleDateString();
        }

        return result;
    }

    function getReportingPeriodDate(period) {
        if (!period) return;

        return formatStartEndDates(period.startTime, period.endTime);
    }

    return <Card>
        {!progressIndicatorProps.visible && club && summary ? <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={8} >
                    <Card>
                        {/* Report header */}
                        <SoftBox p={3}>
                            <Grid container xs={12} justifyContent="space-between">
                                {club.app ? <>
                                    <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        <SoftBox width="100%" textAlign="left" mt={2}>
                                            <SoftBox textAlign="center" width="max-content">
                                                <SoftBox component="img" src={appLogo} width="96px" style={{}} p={1} mb={1} />
                                                <SoftTypography variant="h6" fontWeight="medium">
                                                    {club.app.name}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item sm={4} xs={12} >
                                        <SoftBox width="100%" textAlign={{ xs: "center", md: "center" }} mt={2}>
                                            <Tooltip title="Total Earnings - Union Dues + XMTT Dues + Chip Offset + Tax / Rebate + Referral Bonus" placement="top">
                                                <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="uppercase">
                                                    Settelment
                                                </SoftTypography>
                                            </Tooltip>
                                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="uppercase">
                                                <MoneyCell value={summary ? formatDecimalValue(summary.settlementAmount) : 0} prefix={currencySymbol} />
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }} >
                                        <SoftBox justifyContent="right" textAlign="right" mt={2}>
                                            <SoftBox ml="auto" textAlign="center" width="max-content">
                                                <SoftBox component="img" src={cardroomLogo} width="96px" style={{}} p={1} mb={1} />
                                                <SoftTypography variant="h6" fontWeight="medium">
                                                    {club ? club.name : null}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid></> : null}
                            </Grid>
                            <Grid container xs={12}>
                                <Grid item xs={12}>
                                    &nbsp;
                                </Grid>
                            </Grid>

                            {isPremiumEnabled && canEditAdhocLine() ? <AdhocLineDialog {...adhocLineEditorProps} cardroom={cardroom} club={club} reportingPeriod={reportingPeriod} line={currentAdhocLine} action={currentAdhocAction} onLineActionComplete={onAdhocLineActionWasCompleted} /> : null}

                            <Grid container xs={12}>
                                <Grid item xs={12} display="flex" justifyContent="space-between">
                                    <SoftBox whiteSpace={"nowrap"}>
                                        <SoftBox ml={1} display="inline">
                                            <SoftTypography component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize">
                                                Show Breakdowns
                                            </SoftTypography>
                                        </SoftBox>&nbsp;
                                        <SoftBox mt={0.25} mr={1} display="inline">
                                            <Switch checked={showDetailedBreakdown} onChange={() => setShowDetailedBreakdown(!showDetailedBreakdown)} />
                                        </SoftBox>

                                    </SoftBox>
                                    <SoftBox whiteSpace={"nowrap"}><SoftBox ml={1} display="inline">
                                        <SoftTypography component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize" mr={1}>
                                            {getReportingPeriodDate(reportingPeriod)}
                                        </SoftTypography>
                                    </SoftBox></SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container xs={12}>
                                <Grid item xs={12}>

                                    <Stack direction={"row"} mb={2} justifyContent={"center"} style={{ backgroundColor: 'transparent', shadowOpacity: 0 }}>
                                        <ReportSummaryDetailCard cardroom={cardroom} club={club} reportingPeriod={reportingPeriod} summary={summary} legend={true} onLineEdit={onLineEditRequested} onLineDelete={onLineDeleteRequested}
                                            calculator={agentCalculator} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} isPremiumEnabled={isPremiumEnabled} showDetailedBreakdown={showDetailedBreakdown} />
                                        <Stack direction={"row"}
                                            divider={<Divider orientation="vertical" flexItem />}
                                            spacing={1}
                                            style={{ overflowX: "auto" }}>
                                            <ReportSummaryDetailCard cardroom={cardroom} club={club} reportingPeriod={reportingPeriod} summary={summary} legend={false}
                                                calculator={agentCalculator} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} isPremiumEnabled={isPremiumEnabled} showDetailedBreakdown={showDetailedBreakdown} />
                                        </Stack>
                                    </Stack>

                                </Grid>
                            </Grid>


                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </> : <ProgressIndicator {...progressIndicatorProps} />}
        <Divider />
    </Card>;

}

const ReportSummary = ({ cardroom, club, reportingPeriod, summary, currency, currencyConverter }) => {
    const cardroomLogo = cardroom && cardroom.hasLogo ? apiConfig.images.endpoint + "/casino/" + cardroom.id + ".png" : defaultCasino;
    const appLogo = club && club.app ? apiConfig.images.endpoint + "/app/" + club.app.id + ".png" : null;

    const [retryCounter, setRetryCounter] = useState(0);

    const [context, actions, features] = useCardroomContext(cardroom);

    const [currencySymbol, setCurrencySymbol] = useState(null);

    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    useEffect(() => {
        if (true === isCurrencyMaskingEnabled()) {
            setCurrencySymbol("");
            return;
        }

        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency, currencyConverter]);


    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading report details...", handleRetry)


    const unionCalculator = useUnionCalculator();


    function zero() {
        return parseFloat(0).toFixed(2);
    }

    function calcEarnings() {
        if (!summary) return zero();
        return formatDecimalValue(unionCalculator.earnings(summary));
    }
    function calcUnionDues() {
        if (!summary) return zero();
        return formatDecimalValue(unionCalculator.unionDues(summary));
    }
    function calcXMTTDues() {
        if (!summary) return zero();
        return formatDecimalValue(unionCalculator.xmttDues(summary));
    }
    function calcChipOffset() {
        if (!summary) return zero();
        return formatDecimalValue(unionCalculator.chipOffset(summary));
    }
    function calcTaxRebate() {
        if (!summary) return zero();

        return formatDecimalValue(unionCalculator.ecoTax(summary));
    }

    function getDividend() {
        if (!summary) return zero();
        return formatDecimalValue(summary.loyaltyRebate);
    }

    function formatDecimalValue(v) {
        if (!v) return zero();

        var v = currencyConverter.convertFromUSD(v, currency);

        return v.toFixed(currency.precision);
    }

    //TODO: add tooltips to each heading explaing how it was put together
    return <Card>
        {!progressIndicatorProps.visible && club && summary ? <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={8} >
                    <Card>
                        {/* Report header */}
                        <SoftBox p={3}>
                            <Grid container xs={12} justifyContent="space-between">
                                {club.app ? <>
                                    <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        <SoftBox width="100%" textAlign="left" mt={2}>
                                            <SoftBox textAlign="center" width="max-content">
                                                <SoftBox component="img" src={appLogo} width="96px" style={{}} p={1} mb={1} />
                                                <SoftTypography variant="h6" fontWeight="medium">
                                                    {club.app.name}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item sm={4} xs={12} >
                                        <SoftBox width="100%" textAlign={{ xs: "center", md: "center" }} mt={2}>
                                            <Tooltip title="Total Earnings - Union Dues + XMTT Dues + Chip Offset + Tax / Rebate + Referral Bonus" placement="top">
                                                <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="uppercase">
                                                    Settelment
                                                </SoftTypography>
                                            </Tooltip>
                                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="uppercase">
                                                <MoneyCell value={summary ? formatDecimalValue(summary.settlementAmount) : 0} prefix={currencySymbol} />
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }} >
                                        <SoftBox justifyContent="right" textAlign="right" mt={2}>
                                            <SoftBox ml="auto" textAlign="center" width="max-content">
                                                <SoftBox component="img" src={cardroomLogo} width="96px" style={{}} p={1} mb={1} />
                                                <SoftTypography variant="h6" fontWeight="medium">
                                                    {club ? club.name : null}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid></> : null}
                            </Grid>
                            <Grid container justifyContent="space-between" mt={2}>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                            <SoftBox mt={3}>
                                {/* Row 1 */}
                                <Grid container xs={12} justifyContent="space-between">
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"left"}>
                                                <Tooltip title="Players net result" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Winnings
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"left"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={summary ? formatDecimalValue(summary.winnings) : 0} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"center"}>
                                                <Tooltip title="Fees generated by club" placement="top">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Fees
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"center"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={summary ? formatDecimalValue(summary.fees) : 0} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"right"}>
                                                <Tooltip title="Corss Union MTT Fees generated by club" placement="right-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        XMTT Fee
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"right"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={summary ? formatDecimalValue(summary.xmttFees) : 0} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent="space-between" >
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>

                                {/* Row 2 */}
                                <Grid container xs={12} justifyContent="space-between">
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"left"}>
                                                <Tooltip title="Corss Union MTT profit & loss" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        XMTT P&L
                                                    </SoftTypography>
                                                </Tooltip>

                                            </Grid>
                                            <Grid item sm={12} textAlign={"left"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={summary ? formatDecimalValue(summary.xmttPnL) : 0} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"center"}>
                                                <Tooltip title="Bad beat jackpot profit & loss" placement="top">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Jackpot P&L
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"center"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={summary ? formatDecimalValue(summary.jackpotPnL) : 0} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"right"}>
                                                <Tooltip title="Spin & Go Offset" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Spin N Go Offset
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"right"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={summary ? formatDecimalValue(summary.spinNGoOffset) : 0} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container xs={12} justifyContent="space-between">
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>

                                {/* Row 3 */}
                                <Grid container xs={12} justifyContent="space-between">
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"left"}>
                                                <Tooltip title="Winnings + Fees" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Total Earnings
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"left"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={calcEarnings()} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"center"}>
                                                <Tooltip title="Union Fee Rate * Total Fees" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Union Dues
                                                    </SoftTypography>
                                                </Tooltip>

                                            </Grid>
                                            <Grid item sm={12} textAlign={"center"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={calcUnionDues()} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"right"}>
                                                <Tooltip title="Tax / Rebate Rate * Total Earnings" placement="top">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Tax / Rebate
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"right"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={calcTaxRebate()} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>

                                <Grid container justifyContent="space-between">
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                                {/* Row 4 */}
                                <Grid container xs={12} justifyContent="space-between">

                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"left"}>
                                                <Tooltip title="XMTT Dues Rate * XMTT Fee" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        XMTT Dues
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"left"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={calcXMTTDues()} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"center"}>
                                                <Tooltip title="Chip Offset Rate * Adjusted Earnings" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Chip Offset
                                                    </SoftTypography>
                                                </Tooltip>

                                            </Grid>
                                            <Grid item sm={12} textAlign={"center"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={calcChipOffset()} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"right"}>
                                                <Tooltip title="Referral fees based on referred clubs' fees" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Referral Bonus
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"right"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={summary ? formatDecimalValue(summary.referralBonus) : 0} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent="space-between" >
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>

                                {/* Row 5 */}
                                <Grid container xs={12} justifyContent="space-between">
                                    <Grid item xs={12} sm={12}>
                                        {summary && summary.loyaltyRebate > 0 ?
                                            <Grid container xs={12} justifyContent="space-between">
                                                <Grid item sm={12} textAlign={"center"}>
                                                    <Tooltip title="Total Union Fees * Loyalty Dividend Rate" placement="top">
                                                        <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                            Loyalty Dividend
                                                        </SoftTypography>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item sm={12} textAlign={"center"}>
                                                    <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                        <MoneyCell value={getDividend()} prefix={currencySymbol} />
                                                    </SoftTypography>
                                                </Grid>
                                            </Grid> : null}
                                    </Grid>
                                </Grid>
                            </SoftBox>

                        </SoftBox>

                    </Card>
                </Grid>
            </Grid>
        </> : <ProgressIndicator {...progressIndicatorProps} />}
        <Divider />
    </Card>;
};

ReportSummary.propTypes = {
    cardroom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired
};

const ClubReport = ({ cardroom, club, reportingPeriod, summary, currency, currencyConverter }) => {

    const [missingService, setMissingService] = useState("feature");
    const [openPremiumSumDialog, premiumSubDialogProps] = useDialog();

    const [context, actions, features] = useCardroomContext(cardroom);

    function isAgentReportEnabled() {
        if (!context) return false;
        return context.isEnabled(features.club.reporting.agentReport);
    }

    function isPlayerReportEnabled() {
        if (!context) return false;
        return context.isEnabled(features.club.reporting.playerReport);
    }

    function isDetailedSummaryEnabled() {
        if (!context) return false;
        return context.isEnabled(features.club.reporting.summaryDetailReport);
    }

    const [summaryConfig, setSummaryConfig] = useState(null);

    function summaryCallBack(cfg) {
        setSummaryConfig(cfg);
    }

    function addNewAdhocLine() {
        if (!summaryConfig) return;
        if (!summaryConfig.addNewLine) return;
        summaryConfig.addNewLine();
    }

    function premiumFeatureRequired() {

        setMissingService("Adhoc Lines");
        openPremiumSumDialog();
    }

    function isAddNewAdhocLineAllowed() {
        if (!context) return false;
        return context.isAllowed(actions.club.reports.adhoc.add);
    }


    const tabs = [];

    tabs.push({
        title: { text: "Summary", icon: "summarize" },
        header: null,
        body: <ReportSummary cardroom={cardroom} club={club} reportingPeriod={reportingPeriod} summary={summary} currency={currency} currencyConverter={currencyConverter} />
    });

    tabs.push({
        title: { text: "Details", icon: "dashboard" },
        header: isAddNewAdhocLineAllowed() ? <SoftButton onClick={isDetailedSummaryEnabled() ? addNewAdhocLine : premiumFeatureRequired}><Icon>add</Icon>&nbsp;New Adhoc Line</SoftButton> : null,
        body: <ReportSummaryDetail cardroom={cardroom} club={club} reportingPeriod={reportingPeriod} summary={summary}
            currency={currency} currencyConverter={currencyConverter} callback={summaryCallBack} isPremiumEnabled={isDetailedSummaryEnabled()} />
    });


    tabs.push({
        title: { text: "Agents", icon: "support_agent" },
        header: null,
        body: isAgentReportEnabled() ?
            <ReportAgents cardroom={cardroom} club={club} reportingPeriod={reportingPeriod} summary={summary} currency={currency} currencyConverter={currencyConverter} />
            : <PremiumServiceSubscriptionRequired service="detailed agent reports" />
    });

    tabs.push({
        title: { text: "Players", icon: "person" },
        header: null,
        body: isPlayerReportEnabled() ?
            <ReportPlayers cardroom={cardroom} club={club} reportingPeriod={reportingPeriod} summary={summary} currency={currency} currencyConverter={currencyConverter} />
            : <PremiumServiceSubscriptionRequired service="detailed player reports" />
    });

    return <SoftBox pt={2}>
        <FeatureRequiresPremiumSubDialog {...premiumSubDialogProps} service={missingService} />
        <AdaptiveTab switchToMobileOnSize="md" tabs={tabs} />
    </SoftBox>;
};

ClubReport.defaultProps = {

};

ClubReport.propTypes = {
    cardroom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired,
    currencyConverter: PropTypes.object.isRequired
};

export default ClubReport;