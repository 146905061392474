import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { scheduleId, name, description, rebateRate, rebateThreshold, rebateScheduleScheme, lossOnly, makeupThreshold },
} = form;



const validations = {
    editor: Yup.object().shape({
        //[scheduleId.name]: Yup.string(),
        [name.name]: Yup.string().required(name.errorMsg),
        //[description.name]: Yup.string(),
        [rebateScheduleScheme.name]: Yup.number().oneOf([0, 1, 2]).required(rebateScheduleScheme.errorMsg),
        [rebateRate.name]: Yup.number().when([rebateScheduleScheme.name], {
            is: (scheduleV) => scheduleV > 0,
            then: Yup.number().min(0, "Rebate rate must be between 0 and 100%.").max(100, "Rebate rate must be between 0 and 100%.").required(rebateRate.errorMsg),
        }),
        [rebateThreshold.name]: Yup.number().when([rebateScheduleScheme.name], {
            is: (scheduleV) => scheduleV > 0,
            then: Yup.number().min(0, "Rebate exemption must be >= 0.").required(rebateThreshold.errorMsg),
        }),
        [makeupThreshold.name]: Yup.number().when([rebateScheduleScheme.name], {
            is: (scheduleV) => scheduleV > 0,
            then: Yup.number().min(0, "Max makeup must be >= 0.").required(makeupThreshold.errorMsg),
        }),
       //  [chipOffsetRate.name]: Yup.number().min(0, "Chip offset rate must be between 0 and 100%.").max(100, "Chip offset rate must be between 0 and 100%.").required(chipOffsetRate.errorMsg),
       // [taxRebateRate.name]: Yup.number().min(0, "Tax rebate rate must be between 0 and 100%.").max(100, "Tax rebate rate must be between 0 and 100%.").required(taxRebateRate.errorMsg),
    }),

};

export default validations;