import agentForm from "./form";


const {
    formField: {
        agentId,
        playerId,
        agentPlayerPoolId,
        rebateScheduleId,
        makeupAmount,
        playerAppId,
        playerName,
        playerNickName,
        validatedPlayerId,
        referrerId,
        referredId,
        referralScheduleId,
        referralScheduleName,
        referrerType,
        referredType
    },
} = agentForm;

const initialValues = {
    [agentId.name]: "",
    [playerId.name]: "",
    [agentPlayerPoolId.name]: "",
    [rebateScheduleId.name]: "",
    [makeupAmount.name]: 0,
    [playerAppId.name]: "",
    [playerName.name]: "",
    [playerNickName.name]: "",
    [validatedPlayerId.name]: "",
    [referrerId.name]: "",
    [referredId.name]: "",
    [referralScheduleId.name]: "",
    [referralScheduleName.name]: "",
    [referrerType.name]: 0,
    [referredType.name]: 0
};

export default initialValues;
