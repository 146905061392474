
const form = {
    formId: "union-defaults-editor-form",
    formField: {
        unionId: {
            name: "unionId",
            type: "hidden"
        },
        defaultClubFeeScheduleId: {
            name: "defaultClubFeeScheduleId",
            label: "Default Club fee Schedule",
            placeholder: "club fee schedule",
            errorMsg: "Default club fee schedule is required.",
            type: "string"
        },
        tokenUnitCost: {
            name: "tokenUnitCost",
            label: "Token Unit Cost",
            type: "number",
            placeholder: "token unit cost",
            errorMsg: "Token unit cost is required.",
        },
        chipUnitCost: {
            name: "chipUnitCost",
            label: "Chip Unit Cost",
            type: "number",
            placeholder: "chip unit cost",
            errorMsg: "Chip unit cost is required.",
        },
        unionManagerFee: {
            name: "unionManagerFee",
            label: "Union Manager Fee",
            type: "number",
            placeholder: "union manager fee",
            errorMsg: "Union manager fee is required",
        },
        primaryClubId:{
            name: "primaryClubId",
            label: "Primary club",
            type: "string",
            placeholder: "primary club",
            errorMsg: "Primary club id is required",
        }
    },

};

export default form;
