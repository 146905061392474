
import { useEffect, useState } from "react";

import { ProgressIndicator } from "components/Elements/ProgressIndicator";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { apiConfig } from "config/apiConfig";
import DataBoundSelect from "components/Elements/DataBound/Dropdown";
import DataBoundList from "components/Elements/DataBound/List";
import { useBookDataLoader } from "layouts/Book/components";
import { useProgressIndicator } from "components/Elements/ProgressIndicator";
import SoftTypography from "components/SoftTypography";

const BookAgentPlayerPoolSelector = ({ cardroom, book, agent, value, readonly, placeholder, emptyValue, onSelectionChanged, reload, type, onBuildListItem, maxHeight }) => {

    const [playerPools, setPlayerPools] = useState(agent && agent.playerPools ? agent.playerPools : null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "retrieving packages...", handleRetry)

    const [availableOptions, setAvailableOptions] = useState(null);

    const [selectedValue, setSelectedValue] = useState(value ? value : emptyValue);
    const [selectedIndex, setSelectedIndex] = useState(null);

    function getSelectedIndex(dataSource) {
        if (!dataSource) dataSource = availableOptions; // getDataSource();

        if (!dataSource) return -1;
        if (!selectedValue) return -1;

        for (var i = 0; i < dataSource.length; i++) {
            if (dataSource[i].id == selectedValue.id || dataSource[i].id == selectedValue) {
                return i;
            }
        }

        return -1;
    }

    function findValueInDataSource(v) {
        let dataSource = getDataSource();

        if (!dataSource) return null;
        if (!v) return null;

        for (var i = 0; i < dataSource.length; i++) {
            if (dataSource[i].id == v.id || dataSource[i].id == v) {
                return dataSource[i];
            }
        }

        return -1;
    }

    function getDataSource() {

        if (!playerPools) return null;

        let rows = [...playerPools];

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });
        }

        if (emptyValue) {
            rows.unshift(emptyValue);
        }

        return rows;
    }

    function handlePlayerPoolChange({ item, index }) {
        setSelectedIndex(null);
        setSelectedValue(item);

        if (onSelectionChanged) {
            onSelectionChanged(item);
        }
    }

    const bookRequest = useFetchWithMsal({
        scopes: apiConfig.book.scopes.read,
    });

    const bookDataLoader = useBookDataLoader();

    useEffect(() => {
        setPlayerPools(null);
        setSelectedIndex(-1);
    }, [agent]);


    useEffect(() => {
        if (!playerPools && agent) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("retrieving packages...")
            showProgress();

            bookDataLoader.entity.loadAgentPlayerPools(bookRequest, cardroom, book, agent, false, (pools) => {
                if (pools) {
                    setPlayerPools(pools);
                    if (agent) agent.playerPools = pools;
                }
                progressIndicatorProps.close();
            }, (err) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to retrieve packages, please try again...")
            });
        }

    }, [bookRequest.execute, playerPools, agent, retryCounter]);

    useEffect(() => {

        if (playerPools) {
            const data = getDataSource();
            setAvailableOptions(data);

            if (selectedValue && !selectedIndex) {
                var idx = getSelectedIndex(data);
                setSelectedIndex(idx);

                if (onSelectionChanged && idx >= 0) {
                    onSelectionChanged(data[idx]);
                }
            }
        }

    }, [playerPools, selectedIndex, selectedValue]);

    useEffect(() => {
        if (playerPools && value) {

            //find in datasource
            setSelectedValue(findValueInDataSource(value));
        }

    }, [playerPools, value]);

    useEffect(() => {
        setPlayerPools(null);

    }, [reload])

    return <>
        <ProgressIndicator  {...progressIndicatorProps} />
        {!progressIndicatorProps.visible ? <>
            {readonly ?
                <SoftTypography
                    variant="caption"
                    fontWeight="regular"
                    color="dark"
                > {selectedValue ? selectedValue.name : "[None]"}
                </SoftTypography> : <>{availableOptions ?
                    <>
                        {type === "dropdown" ?
                            <DataBoundSelect placeholder={placeholder} data={availableOptions} valueName="id" textName="name" onChange={handlePlayerPoolChange} size="medium" selectedIndex={selectedIndex} /> : null}
                        {type === "list" ? <DataBoundList data={availableOptions} valueName="id" textName="name" onChange={handlePlayerPoolChange} selectedIndex={selectedIndex} onBuildListItem={onBuildListItem} maxHeight={maxHeight} /> : null}
                    </>
                    : null}
                </>
            }</> : null}
    </>;


};


export default BookAgentPlayerPoolSelector;