
import { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'
import useFetchWithMsal from 'hooks/useFetchWithMsal';

const roles = {
    owner: 1,
    admin: 2,
    chipRunner: 4,
    cageManager: 8,
    reader: 16,
    parnter: 32,
    player: 64,
    intergration: 128
};

class action {
    constructor(id, mask, types) {
        this.id = id;
        this.mask = mask;
        this.types = types;
    }
}

const actions = () => {
    return {
        casino: {
            staff: {
                payout: {
                    overrideWitheldAmount: new action("casino.staff.payout.staff.overridewithholdings", roles.owner | roles.admin | roles.parnter, [1])
                }
            },
            participant: {
                add: new action("casino.participant.add", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1])
            },
            account: {
                transaction: {
                    add: new action("casino.account.transaction.add", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1, 2, 3, 5]),
                    cancel: new action("casino.account.transaction.cancel", roles.owner | roles.admin, [1, 2, 3, 5, 6]),
                    acceptOrReject: new action("casino.account.transaction.accept_reject", roles.owner | roles.admin, [2, 3, 5, 6]),
                    preauth: {
                        create: new action("casino.account.transaction.preauth.create", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1]),
                        revoke: new action("casino.account.transaction.preauth.revoke", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1])
                    }
                },
                purchaseOrder: {
                    add: new action("casino.account.purchaseOrder.add", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [2, 3, 5]),
                }
            },
            session: {
                create: new action("casino.session.create", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1]),
                close: new action("casino.session.close", roles.owner | roles.admin | roles.cageManager, [1]),
                end: new action("casino.session.end", roles.owner | roles.admin | roles.cageManager, [1]),
                start: new action("casino.session.start", roles.owner | roles.admin | roles.cageManager, [1]),
                transaction: {
                    create: new action("casino.session.transaction.add", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1]),
                    update: new action("casino.session.transaction.update", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1]),
                    cancel: new action("casino.session.transaction.cancel", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1])
                },
                participant: {
                    add: new action("casino.session.participant.add", roles.owner | roles.admin | roles.chipRunner | roles.cageManager, [1])
                }
            }
        },
        participant: {
            player: {
                edit: new action("participant.player.edit", roles.owner | roles.admin | roles.cageManager, [1])
            },
            staff: {
                edit: new action("participant.staff.edit", roles.owner | roles.admin | roles.cageManager, [1])
            }
        },
        cardroom: {
            security: {
                manageUser: new action("cardroom.security.user.manage", roles.owner | roles.admin, [1, 2, 3, 4, 5, 6])
            },
            properties: {
                edit: new action("cardroom.properties.edit", roles.owner | roles.admin, [1, 2, 3, 4, 5, 6])
            },
            fees: {
                club: {
                    edit: new action("cardroom.fees.club.edit", roles.owner | roles.admin, [2, 3])
                },
                referral: {
                    edit: new action("cardroom.fees.referral.edit", roles.owner | roles.admin, [2, 3, 6])
                },
                defaults: {
                    edit: new action("cardroom.fees.defaults.edit", roles.owner | roles.admin, [2, 3, 6])
                },
                agent: {
                    edit: new action("cardroom.fees.agent.edit", roles.owner | roles.admin, [2, 5, 6])
                },
                player: {
                    edit: new action("cardroom.fees.player.edit", roles.owner | roles.admin, [2, 5, 6])
                },
                rebate: {
                    edit: new action("cardroom.fees.rebate.edit", roles.owner | roles.admin, [2, 5, 6])
                }
            },
            currency: {
                setDefault: new action("cardroom.currency.setDefault", roles.owner | roles.admin, [2, 3, 5, 6]),
                manage: new action("cardroom.currency.manageList", roles.owner | roles.admin, [2, 3, 5, 6])
            }
        },
        club: {
            union: {},
            subunion: {
                edit: new action("club.subunion.edit", roles.owner | roles.admin, [3]),
                invite: new action("club.subunion.invite", roles.owner | roles.admin, [3])
            },
            club: {
                edit: new action("club.club.edit", roles.owner | roles.admin, [2, 3]),
                invite: new action("club.club.invite", roles.owner | roles.admin, [2, 3])
            },
            agency: {
                edit: new action("club.agency.edit", roles.owner | roles.admin, [5]),
                invite: new action("club.agency.invite", roles.owner | roles.admin, [2, 5]) // [5]-- temp dsiabled
            },
            book: {
                edit: new action("club.book.edit", roles.owner | roles.admin, [6]),
                invite: new action("club.book.invite", roles.owner | roles.admin, [6]),
                agent: {
                    edit: new action("club.book.agent.edit", roles.owner | roles.admin, [6]),
                    assign: new action("club.book,agent.assign", roles.owner | roles.admin, [6])
                },
                player: {
                    edit: new action("club.book.player.edit", roles.owner | roles.admin, [6])
                }
            },
            reports: {
                upload: new action("club.reports.upload", roles.owner | roles.admin, []), //[2, 3] --temp disabled
                adhoc: {
                    add: new action("club.report.adhoc.add", roles.owner | roles.admin, [2, 3, 5]),
                    edit: new action("club.report.adhoc.edit", roles.owner | roles.admin, [2, 3, 5]),
                    delete: new action("club.report.adhoc.add", roles.owner | roles.admin, [2, 3, 5])
                },
                snapshot: {
                    publish: new action("club.report.snapshot.publish", roles.owner | roles.admin, [2, 3, 5, 6]),
                    unpublish: new action("club.report.snapshot.unpublish", roles.owner | roles.admin, [2, 3, 5, 6]),
                }
            },
        },
        invitations: {
            edit: new action("invitations.edit", roles.owner | roles.admin, [1, 2, 3, 4, 5, 6])
        }
    };
}

const features = () => {
    return {
        casino: {
            smsNotifications: new action("casino.smsNotifications", 1, [1, 2, 3, 4, 5, 6]),
            offlineSignatureCollection: new action("casino.offlineSignatureCollection", 2, [1]),
            //currencyConversion: new action("casino.currencyConversion", 16, [2, 3, 5, 6]),
            accountServices: new action("casino.accountServices", 128, [1, 2, 3, 4, 5, 6]),
            accountServicesPO: new action("casino.accountServices.po", 128 | 1024, [2, 3, 5]),
            advancedAccountServices: new action("casino.accountServices.advanced", 1024, [1, 2, 3, 4, 5, 6]),
            botServices: new action("casino.botServices", 256, [1, 2, 3, 4, 5, 6])
        },
        currency: {
            conversion: new action("casino.currency.conversion", 16, [2, 3, 5, 6]),
            mask: new action("casino.currency.mask", 2048, [2, 3, 5, 6]),
        },
        club: {
            reporting: {
                agentReport: new action("club.reporting.agentReport", 4, [2, 3, 5, 6]),
                playerReport: new action("club.reporting.playerReport", 8, [2, 3, 5, 6]),
                summaryDetailReport: new action("club.reporting.summaryDetail", 32, [2, 3, 5, 6]),
                selfUpload: new action("club.reporting.upload", 64, [2, 3, 6]),
                ingestion: new action("club.reporting.ingest", 32768, [2, 3, 6]),
                publish: new action("club.reporting.publish", 1024, [1, 2, 3, 6]), //1, 2, 3, 4, 5
            }
        }
    };
}

const useCardroomContext = (cardroom) => {

    const [license, setLicense] = useState((cardroom && cardroom.license) ? cardroom.license : null);

    const isSupported = (types) => {
        if (!types) return false;
        if (types.length === 0) return false;

        return types.includes(cardroom.type);
    }

    const isEnabled = (feature, mask) => {
        if (!feature) return false;
        if (!feature.mask) return false;

        if (!isSupported(feature.types)) return false;

        if (!mask) mask = cardroom.features;

        let effectiveMask = mask | cardroom.features; //this allows us to further restrict cardroom.features

        return (feature.mask & effectiveMask) > 0;
    };

    const isAllowed = (action) => {
        if (!action) return false;
        if (!action.mask) return false;

        if (!isSupported(action.types)) return false;

        return (action.mask & cardroom.myRole) > 0;
    };

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const cardroomRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    useEffect(() => {
        if (cardroom && !license) { //get license
            // [HttpGet("{casinoId:Guid}/license", Name = "GetCasinoLicense")]
            cardroomRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/license").then((response) => {
                if (response) {
                    cardroom.license = response;
                    setLicense(cardroom.license);
                }
            }).catch((ex) => {
                if (retryCounter < 10) {
                    handleRetry();
                }
            });
        }
    }, [cardroomRequest.execute, license, retryCounter]) //cardroomListData 

    const state = {
        cardroom,
        licenese: null,
        app: null,
        roles: cardroom.myRole
    };

    const context = {
        isEnabled,
        isAllowed
    };
    return [context, actions(), features()];
};

export {
    useCardroomContext
};