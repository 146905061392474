
const form = {
    formId: "fee-tier-editor-form",
    formField: {
        scheduleId: {
            name: "scheduleId",
            label: "schedule id",
            type: "hidden"
        },
        name: {
            name: "name",
            label: "Name",
            type: "text",
            placeholder: "name",
            errorMsg: "Name is required.",
        },
        stepCount: {
            name: "stepCount",
            label: "Steps",
            type: "number",
            placeholder: "steps",
            errorMsg: "At least 2 steps must be defined.",
        },
        stepErrors: {
            name: "stepErrors",
            label: "step errors placeholder",
            type: "numbers",
            placeholder: "",
            errorMsg: "Errors in tier step definition(s).",
        }
    },

};

export default form;
