import form from "./form";


const {
    formField: {
        clubId,
        feeSchedule,
        referralSchedule,
        referredClub,
        useTiers,
        feeTierId,
        notificationGroupId
    },
} = form;

const initialValues = {
    [clubId.name]: "",
    [feeSchedule.name]: "",
    [referralSchedule.name]: "",
    [referredClub.name]: "",
    [useTiers.name]: false,
    [feeTierId.name]: "",
    [notificationGroupId.name]: ""
};

export default initialValues;
