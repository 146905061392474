import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { groupId, casinoId, groupName }
} = form;



const validations = {
    editor: Yup.object().shape({
       // [groupId.name]: Yup.string().required(groupId.errorMsg),
        [groupName.name]: Yup.string().required(groupName.errorMsg),
       // [casinoId.name]: Yup.string().required(casinoId.errorMsg)
    }),

};

export default validations;