import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import wavesWhite from "assets/images/shapes/waves-white.svg";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import MiniActionCard from "components/Elements/MiniActionCard";


import { useCardroomContext } from 'features';
import ReportingPeriods from 'layouts/Club/components/Reports/History';
import CardroomLogo from 'components/Elements/CardroomLogo';

import AdaptiveTab from 'components/Elements/AdaptiveTab';
import { useCurrencyConverter } from 'components/Elements/CurrencyConverter/currency';
import BookPlayers from '../BookPlayers';
import BookAgents from '../BookAgents';
import { useBookDataLoader } from '..';
import SoftButton from 'components/SoftButton';
import { Icon } from '@mui/material';
import { useDialog } from 'components/Elements/Dialog/common';
import BookAgentEditorDialog from '../BookAgents/Dialogs';
import BookPlayerEditorDialog from '../BookPlayers/Dialogs';




function BookDetails({ cardroom }) {

    const [cardroomDetailsData, setCardroomDetailsData] = useState(null);
    const [cardroomAccount, setCardroomAccount] = useState({});
    const [book, setBook] = useState(null);

    const cardroomRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const [currencyConverter, currencies] = useCurrencyConverter(cardroom);
    const [currency, setCurrency] = useState(null);

    function onCurrencySelectionChanged(c) {
        setCurrency(c);
    }

    const [currencySymbol, setCurrencySymbol] = useState(null);
    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    const [openNewAgentDialog, newAgentDialogProps] = useDialog();
    const [openNewPlayerDialog, newPlayerDialogProps] = useDialog();

    function onCreateNewAgent() {
        setPendingAgent({});
        openNewAgentDialog();
    }

    function onCreateNewPlayer() {
        setPendingPlayer({});
        openNewPlayerDialog();
    }

    function canManageAgents() {
        if (!context) return false;
        return context.isAllowed(actions.club.book.agent.edit);
    }

    function canManagePlayers() {
        if (!context) return false;
        return context.isAllowed(actions.club.book.player.edit);
    }


    useEffect(() => {
        if (true === isCurrencyMaskingEnabled()) {
            setCurrencySymbol("");
            return;
        }

        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency]);

    const bookRequest = useFetchWithMsal({
        scopes: apiConfig.book.scopes.read,
    });

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading book details...", handleRetry)

    const [context, actions, features] = useCardroomContext(cardroom);

    function refreshAccount() {
        setCardroomAccount(null);
    }

    const [pendingAgent, setPendingAgent] = useState(null);
    const [pendingPlayer, setPendingPlayer] = useState(null);

    const [players, setPlayers] = useState(null);
    const [agents, setAgents] = useState(null);


    function onAgentCreated(agent) {
        //should we update, or kill cache?

        if (agent && book && book.agents) {
            for (var i = 0; i < book.agents.length; i++) {
                if (book.agents[i].id == agent.id) return; //found agent, no need to do anything
            }

            book.agents.push(agent);
        }
    }

    function onPlayerCreated(player) {
        if (player && book && book.players) {
            for (var i = 0; i < book.players.length; i++) {
                if (book.players[i].id == player.id) return; //found agent, no need to do anything
            }

            book.players.push(player);
        }
    }

    const bookDataLoader = useBookDataLoader();

    useEffect(() => {
        if (!cardroomDetailsData || cardroomDetailsData.id != cardroom.id) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading casino details...")
            showProgress();
            cardroomRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id).then((response) => {
                setCardroomDetailsData(response);
                //setCardroomStats(null);
                //progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load casino details, please try again...")
            });
        }
    }, [cardroomRequest.execute, cardroomDetailsData, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (cardroomDetailsData && !book) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading book info...")
            showProgress();


            bookDataLoader.entity.loadBook(bookRequest, cardroom, true, (b) => {
                if (b) {
                    setBook(b);
                    let c = b.currency;
                    if (!c) c = "USD";
                    onCurrencySelectionChanged(currencyConverter.getCurrencyFrom(c));
                }
            }, (ex, t) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load book info, please try again...")
            })
        }
    }, [bookRequest.execute, book, cardroomDetailsData, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (cardroomDetailsData && book && (!cardroomAccount || cardroomAccount.accountHolderId != cardroom.id)) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading book account...")
            showProgress();

            // [HttpGet("{entityId:Guid}/holders/{holderId:Guid}/accounts/{type:int}", Name = "GetEntityAccount")]
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/" + cardroom.id + "/accounts/2").then((response) => {
                setCardroomAccount(response);
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load book account, please try again...")
            });
        }
    }, [accountRequest.execute, cardroomAccount, cardroomDetailsData, book, retryCounter]) //cardroomAccount


    useEffect(() => {
        if (cardroomDetailsData && book && !players) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading players...")
            showProgress();

            bookDataLoader.entity.loadPlayers(bookRequest, cardroom, book, true, (response) => {
                setPlayers(response);
            }, (ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load players, please try again...");
            });
        }
    }, [bookRequest.execute, book, players, cardroomDetailsData, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (cardroomDetailsData && book && players && !agents) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading agents...")
            showProgress();


            bookDataLoader.entity.loadAgents(bookRequest, cardroom, book, true, (response) => {
                setAgents(response);
                
                progressIndicatorProps.close();
            }, (ex, t) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load agents, please try again...")
            })
        }
    }, [bookRequest.execute, book, players, agents, cardroomDetailsData, retryCounter]) //cardroomListData 



    const tabs = [];

    tabs.push({
        title: { text: "Reports", icon: "summarize" },
        header: null,
        body: <ReportingPeriods cardroom={cardroom} book={book} audience="book" />
    });


    tabs.push({
        title: { text: "Players", icon: "group" },
        header: canManagePlayers() ? <SoftButton variant="outlined" size="small" color="dark" onClick={onCreateNewPlayer}><Icon size="lg">add</Icon>&nbsp;New Player</SoftButton> : null,
        body: <BookPlayers cardroom={cardroom} book={book} />
    });


    tabs.push({
        title: { text: "Agents", icon: "support_agent" },
        header: canManageAgents() ? <SoftButton variant="outlined" size="small" color="dark" onClick={onCreateNewAgent}><Icon size="lg">add</Icon>&nbsp;New Agent</SoftButton> : null,
        body: <BookAgents cardroom={cardroom} book={book} />
    });



    return <>
        {cardroomDetailsData && !progressIndicatorProps.visible ? <>
            {/**   {canManagePlayerSchedules() ? <FeeScheduleEditorDialog {...openFeeScheduleEditorDialogProps} cardroom={cardroom} club={agency} /> : null} */}
            <SoftBox mb={3} p={1}>
                <SoftBox
                    position="relative"
                    bgColor="secondary"
                    py={3}
                    px={{ xs: 3, sm: 6, md: 1 }}
                    mt={3}
                    borderRadius="xl"
                    variant="gradient" >

                    <SoftBox
                        component="img"
                        src={wavesWhite}
                        alt="pattern-line"
                        width="100%"
                        position="absolute"
                        left="0"
                        top="0"
                        opacity={0.4}
                    />

                    <Grid container alignItems="center" position="relative">

                        <CardroomLogo cardroom={cardroom} book={book} canOpenProperties={true} />

                        <Grid item xs={12} lg={11}>
                            <Grid container>
                                <Grid item xs={6} lg={3} p={1} >
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Club", fontWeight: "medium" }}
                                        count={{ text: book ? book.name : "", useNumberFormatter: false }}
                                        icon={{ component: "verified" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "AR", fontWeight: "medium", tooltip: "Accounts Receivable: amount yet to be collected." }}
                                        count={{ text: cardroomAccount ? cardroomAccount.accountsReceivable : 0, prefix: currencySymbol, color: "warning", useNumberFormatter: true }}
                                        icon={{ component: "credit_card" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "AP", fontWeight: "medium", tooltip: "Accounts Payable: amount yet to be paid out." }}
                                        count={{ text: cardroomAccount ? cardroomAccount.accountsPayable : 0, prefix: currencySymbol, color: "error", useNumberFormatter: true }}
                                        icon={{ component: "money" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Players / Agents", fontWeight: "medium" }}
                                        count={{ text: book ? book.playerCount.toLocaleString() + " / " + book.agentCount.toLocaleString() : "0 / 0", color: "success", useNumberFormatter: false }}
                                        icon={{ color: "info", component: "group" }}
                                    />

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>

            {canManageAgents() ? <BookAgentEditorDialog cardroom={cardroom} book={book} agent={pendingAgent} agents={agents} onAgentChanged={onAgentCreated} {...newAgentDialogProps} /> : null}
            {canManagePlayers() ? <BookPlayerEditorDialog cardroom={cardroom} book={book} player={pendingPlayer} players={players} agents={agents} onPlayerChanged={onPlayerCreated} {...newPlayerDialogProps} /> : null}
            <AdaptiveTab switchToMobileOnSize="md" tabs={tabs} p={1} />

        </> : <ProgressIndicator {...progressIndicatorProps} />}
    </>;
}

export default BookDetails;