
import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { Card, CardActions, CardContent, Grid, Icon, Tooltip, useMediaQuery, useTheme } from '@mui/material';

import DataTable from 'components/Elements/DataTable';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';

import { useDialog } from "components/Elements/Dialog/common";
import AgentEditorDialog from 'layouts/Club/components/Agent';
import IconCell from 'components/Elements/DataTable/components/Cells/IconCell';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import whiteCurved from "assets/images/curved-images/white-curved.jpeg";
import MiniStatisticsCard from 'components/Elements/MiniStatisticsCard';
import SoftButton from 'components/SoftButton';
import SoftBox from 'components/SoftBox';

const AgencyAgentMobileCard = ({ cardroom, row, agency, agent, canInviteUsers, canEditAgent, onOpenAgentEditor, onInviteAgentUsers }) => {
    const color = "secondary";
    
    const theme = useTheme();
    const isXs = !useMediaQuery(theme.breakpoints.up("sm"));

    function canUsersBeInvited() {

        if (!agent || agent.isActivelyManaged === true)
            return false;

        return canInviteUsers();
    }

    let buttonGridSize = 12;
    if (canEditAgent() && canUsersBeInvited()) buttonGridSize = 6;

    return <Card
        sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
            backgroundImage: gradients[color]
                ? `${linearGradient(
                    rgba(gradients[color].main, 0.85),
                    rgba(gradients[color].state, 0.85)
                )}, url(${whiteCurved})`
                : `${linearGradient(
                    rgba(gradients[color].main, 0.85),
                    rgba(gradients[color].state, 0.85)
                )}, url(${whiteCurved})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "visible",
        })}
    >
        <CardContent>
            <SoftBox pt={2} pb={2} textAlign="center">
                <MiniStatisticsCard
                    bgColor="white"
                    title={{ text: agent.appAgentId, fontWeight: "medium" }}
                    count={agent.name}
                    icon={{ color: "dark", component: "support_agent" }}
                    direction={"left"}
                    useNumericFormatter={false}
                />
            </SoftBox>

            <SoftBox pb={2} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12} >
                    <Grid item xs={6} pr={.5}>
                        <MiniStatisticsCard
                            bgColor="white"
                            title={{ text: "players", fontWeight: "medium" }}
                            count={agent.playerCount}
                            icon={isXs ? null :{ color: "dark", component: "person" }}
                            direction={"left"}
                        />

                    </Grid>
                    <Grid item xs={6} pl={.5}>
                        <MiniStatisticsCard
                            bgColor="white"
                            title={{ text: "agents", fontWeight: "medium" }}
                            count={agent.agentCount}
                            icon={isXs ? null :{ color: "dark", component: "support_agent" }}
                            direction={"right"}
                        />
                    </Grid>
                </Grid>
            </SoftBox>


            <SoftBox>
                <MiniStatisticsCard
                    bgColor="white"
                    title={{ text: "commission", fontWeight: "medium" }}
                    percentage={{ text: agent.feeSchedule ? agent.feeSchedule.name : "Not Configured", color: agent.feeSchedule ? "dark" : "error" }}
                    icon={{ color: "dark", component: "percent" }}
                    direction={"left"}
                />
            </SoftBox>

        </CardContent>
        <CardActions>
            <Grid container ml="auto" mr="auto" xs={12}>
                {canUsersBeInvited() ?
                    <Grid item xs={buttonGridSize} p={1}>
                        <SoftButton ml="auto" mr="auto"
                            variant="gradient"
                            color={"light"}
                            fullWidth
                            disabled={!canUsersBeInvited()}
                            onClick={() => onInviteAgentUsers(agent)}
                        >
                            INVITE USER&nbsp;
                            <Icon sx={{ fontWeight: "bold" }}>person_add</Icon>
                        </SoftButton>
                    </Grid> : null}

                {canEditAgent() ?
                    <Grid item xs={buttonGridSize} p={1}>
                        <SoftButton ml="auto" mr="auto"
                            variant="gradient"
                            color={"dark"}
                            fullWidth
                            disabled={!canEditAgent()}
                            onClick={() => onOpenAgentEditor(agent)}
                        >
                            SETTINGS&nbsp;
                            <Icon sx={{ fontWeight: "bold" }}>settings</Icon>
                        </SoftButton>
                    </Grid> : null}
            </Grid>
        </CardActions>
    </Card>;

}

const AgencyAgents = ({ cardroom, agency }) => {

    function prepareAgentsForPresentation(agents, table) {
        let rows = [];

        //dedupe agents so we can just show 1 record w/ multiple club count...

        const agentMap = [];
        const agentList = [];
        for (var i = 0; i < agents.length; i++) {
            if (!agentMap[agents[i].id]) {
                agentMap[agents[i].id] = agents[i];
                agentList.push(agents[i]);

                agents[i].clubs = [];
            }

            agentMap[agents[i].id].clubs.push(agents[i].club);
        }


        for (var i = 0; i < agentList.length; i++) {
            let agentImage = agentList[i].name.substring(0, 1);

            rows.push({
                name: [agentList[i].name, { image: agentImage }],
                displayName: agentList[i].name,
                displayNameNoCase: agentList[i].name.toLowerCase(),
                appAgentId: agentList[i].appAgentId,
                playerCount: agentList[i].playerCount,
                agentCount: agentList[i].agentCount,
                superAgentName: agentList[i].superAgent ? agentList[i].superAgent.name : "None",
                superAgentId: agentList[i].superAgent ? agentList[i].superAgent.name : "None",
                sourceAppName: agentList[i].app ? agentList[i].app.name : "None",
                agent: agentList[i],
                //club: agents[i].club
                clubCount: agentList[i].clubs.length
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function GetEmptySchema(handler) {
        return {
            columns: [
                {
                    Header: "Agent",
                    accessor: "name",
                    Cell: ({ value: [name, data], row }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} participant={row && row.original && row.original.agent ? row.original.agent : null} onClick={handler} />
                    ),
                },
                { Header: "Agent Id", accessor: "appAgentId", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "# Players", accessor: "playerCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "# Agents", accessor: "agentCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },

                {
                    Header: "Commission", accessor: "agent", Cell: ({ value }) => {

                        let icon = "done", color = "success";
                        let tooltip = "agent properties are properly set", valueText = "configured";

                        if (value.feeSchedule) {
                            return <TextCell value={value.feeSchedule.name} />;
                        }

                        if (!value.feeSchedule) {
                            icon = "error_outline";
                            color = "error";
                            tooltip = "fee schedule not set";
                            valueText = "not configured";
                        }


                        return <Tooltip title={tooltip}><span><IconCell value={valueText} icon={icon} color={color} /></span></Tooltip>;
                    }
                },
                { Header: "Clubs", accessor: "clubCount", Cell: ({ value }) => <MoneyCell value={value} useColorScheme={false} prefix="" /> },
                { Header: "Source", accessor: "sourceAppName", Cell: ({ value }) => <TextCell value={value} /> },
            ],
            rows: []
        };
    }

    const switchToMobileOnSize = "md";

    const theme = useTheme();
    const isMobileUi = !useMediaQuery(theme.breakpoints.up(switchToMobileOnSize));

    function openAgentEditor(agent) {
        if (!canEditAgentProfile()) return;

        if (agent) {
            setCurrentAgent(agent);
            openAgentEditorDialog();
        }
    }

    const [openAgentEditorDialog, openAgentEditorDialogProps] = useDialog();

    const [currentAgent, setCurrentAgent] = useState(null);

    const [agents, setAgents] = useState(null);

    const [agentsTable, setAgentsTable] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading club agents...", handleRetry)


    //
    const [context, actions, features] = useCardroomContext(cardroom);

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    useEffect(() => {
        if (!agents) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading agents...")
            showProgress();
            // [HttpGet("{clubId:Guid}/casino/{casinoId:Guid}/players", Name = "GetClubPlayers")]
            clubRequest.execute("GET", apiConfig.club.endpoint + "/agency/" + agency.id + "/casino/" + cardroom.id + "/agents").then((response) => {
                setAgents(response);
                setAgentsTable(null);
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load agents, please try again...")
            });
        }
    }, [clubRequest.execute, agents, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (agents && !agentsTable) {
            setAgentsTable(prepareAgentsForPresentation(agents, GetEmptySchema(canEditAgentProfile() ? openAgentEditor : null)));
            progressIndicatorProps.close();
        }
    }, [agents, agentsTable]);

    function canEditAgentProfile() {
        if (!context) return false;

        return context.isAllowed(actions.club.agency.edit);
    }

    function canInviteUsers() {
        if (!context) return false;

        return context.isAllowed(actions.club.agency.invite);
    }

    const [openInviteUserDialog, openInviteUserDialogProps] = useDialog();

    function inviteAgencyUsers(agent) {
        if (!canInviteUsers()) return;

        setCurrentAgent(agent);
        openInviteUserDialog();
    }


    function renderMobileRow(columns, row, key, collapsibleDetailAccessor) {
        return <AgencyAgentMobileCard cardroom={cardroom} row={row} agency={agency} agent={row.original.agent} canInviteUsers={canInviteUsers} canEditAgent={canEditAgentProfile}
            onOpenAgentEditor={openAgentEditor} onInviteAgentUsers={inviteAgencyUsers} />
    }




    return <Card>
        <ProgressIndicator {...progressIndicatorProps} />
        {(cardroom && agency) && !progressIndicatorProps.visible ? <>
            {canEditAgentProfile() ?
                <AgentEditorDialog {...openAgentEditorDialogProps} cardroom={cardroom} club={agency} agent={currentAgent} /> : null}
            {agentsTable ?
                <DataTable table={agentsTable} canSearch entriesPerPage={{ defaultValue: 10, visible: !isMobileUi }} pagination={{ color: "dark" }}
                    onRenderRow={renderMobileRow} useCustomRenderSize={switchToMobileOnSize} /> : null}
        </> : null}
    </Card>;
};

export default AgencyAgents