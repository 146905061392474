import { apiConfig } from "config/apiConfig";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { useState } from "react";
import { useEffect } from "react";

import usdcImage from "assets/graphics/currency/usdc.svg";
import bnbImage from "assets/graphics/currency/bnb.svg";

class Currency {
    constructor(name, symbol, icon, flag, rate, precision, mask, image) {
        if (!precision) precision = 2;

        this.name = name;
        this.symbol = symbol;
        this.icon = icon;
        this.flag = flag;
        this.rate = rate;
        this.precision = precision;
        this.mask = mask;
        this.image = image;
    }
}

/*
 public enum Currency
 {
     USD = 1,
     CAD = 2,
     AUD = 4,
     BRL = 8,
     KRW = 16,
     EUR = 32,
     JPY = 64,
     INR = 128,
     GBP = 256,
     CNY = 512,
     BTC = 1024,
     ETH = 2048
 }

*/

const SupportedCurrencyList = {
    USD: new Currency("USD", "$", "attach_money", "", 1, 2, 1),
    CAD: new Currency("CAD", "$", "attach_money", "", 0, 2, 2),
    AUD: new Currency("AUD", "$", "attach_money", "", 0, 2, 4),
    BRL: new Currency("BRL", "R$", "attach_money", "", 0, 2, 8),
    KRW: new Currency("KRW", "₩", "", "", 0, 2, 16),
    EUR: new Currency("EUR", "€", "euro", "", 0, 2, 32),
    JPY: new Currency("JPY", "¥", "currency_yen", "", 0, 2, 64),
    INR: new Currency("INR", "₹", "currency_rupee", "", 0, 2, 128),
    GBP: new Currency("GBP", "£", "currency_pound", "", 0, 2, 256),
    CNY: new Currency("CNY", "¥", "currency_yuan", "", 0, 2, 512),
    BTC: new Currency("BTC", "₿", "currency_bitcoin", "", 0, 8, 1024),
    ETH: new Currency("ETH", "Ξ", "", "", 0, 6, 2048),
    USDC: new Currency("USDC", "$C", "", "", 0, 2, 4096, usdcImage),
    USDT: new Currency("USDT", "₮", "", "", 0, 2, 8192),

    findByName: function (name) {
        if (!name) return null;

        name = name.toUpperCase();

        for (var prop in SupportedCurrencyList) {
            if (SupportedCurrencyList.hasOwnProperty(prop) && (typeof SupportedCurrencyList[prop] !== 'function')) {
                if (SupportedCurrencyList[prop].name === name) return SupportedCurrencyList[prop];
            }
        }

        return null;
    },
    findByMask: function (mask) {
        if (mask < 1) return null;

        for (var prop in SupportedCurrencyList) {
            if (SupportedCurrencyList.hasOwnProperty(prop) && (typeof SupportedCurrencyList[prop] !== 'function')) {
                if (SupportedCurrencyList[prop].mask === mask) return SupportedCurrencyList[prop];
            }
        }
    }
};


const useCurrencyConverter = (cardroom) => {

    const currencies = SupportedCurrencyList;

    function updateCurrencies(rates) {
        if (!rates) return;

        const rateMap = [];

        for (var i = 0; i < rates.length; i++) {
            const key = rates[i].baseCurrency + "-" + rates[i].currency;
            if (!rateMap[key]) rateMap[key] = rates[i];
        }

        setCurrencyMap(rateMap);
    }

    const [currencyRates, setCurrencyRates] = useState(null);
    const [currencyMap, setCurrencyMap] = useState(null);

    const currencyRequest = useFetchWithMsal({
        scopes: apiConfig.currency.scopes.read,
    });

    function getListOfCurrenciesSupportedByCardroom() {
        const list = [];

        if (cardroom) {

            for (var prop in currencies) {
                // if (typeof model[property] == 'function') 
                if (currencies.hasOwnProperty(prop) && (typeof currencies[prop] !== 'function')) {

                    if ((cardroom.currencies & currencies[prop].mask) > 0) {
                        list.push(currencies[prop]);
                    }
                }
            }
        }

        return list;
    }

    useEffect(() => {
        if (!currencyRates && getListOfCurrenciesSupportedByCardroom().length > 1) {

            //  [HttpGet("{baseCurrency}/entity/{entityId:Guid}/rates", Name = "GetCurrencyRates")]
            currencyRequest.execute("GET", apiConfig.currency.endpoint + "/USD/entity/" + cardroom.id + "/rates").then((response) => {
                if (response) {
                    if (response.status && response.errors) {
                        throw new Error(response.errors);
                    }
                    setCurrencyRates(response);
                }

            }).catch((ex) => {

            });
        }
    }, [currencyRequest.execute, currencyRates]);

    useEffect(() => {
        if (currencyRates) {
            //update exchange rates
            updateCurrencies(currencyRates);
        }
    }, [currencyRates])

    useEffect(() => {
        // setFetchMessages(true);
        const timer = setInterval(() => {
            setCurrencyRates(null);
        }, 30000);
        return () => clearInterval(timer);
    }, []);

    function getRateFromMap(currency) {
        if (!currency) return null;
        if (!currencyMap) return null;

        const key = "USD-" + currency.name;
        if (currencyMap[key]) {
            currency.rate = currencyMap[key].exchangeRate;
            return currencyMap[key].exchangeRate;
        }

        return null;
    }

    const calculator = {
        getCurrencySymbol: function (currency) {
            if (!currency) return "?";

            return currency.symbol;
        },
        convertFromUSD: function (amount, currency) {
            if (!currency) return "??";

            //get "fresh" currency rate  --- THERE MAY BE TIMING ISSUES, for example, union default currency is EUR, but currecny hasn't yet loaded...
            //add blocking code to make sure converstaions are taking place
            let rate = getRateFromMap(currency);

            if (!rate) rate = currency.rate;
            return amount * rate;
        },
        convertToUSD: function (amount, currency) {
            if (!currency) return "??";

            //get "fresh" currency rate
            let rate = getRateFromMap(currency);

            if (!rate) rate = currency.rate;

            return amount / currency.rate;
        },
        getCurrencyFrom: function (name) {
            return currencies.findByName(name);
        }
    };

    return [calculator, currencies];;
};


export {
    useCurrencyConverter,
    SupportedCurrencyList
};