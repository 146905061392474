
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { useLocation, useNavigate } from "react-router-dom";

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


import { useDialog } from "components/Elements/Dialog/common";
import { PlayerSessionTransactionsDialog } from '../PlayerTransactions';

import DataTable from "components/Elements/DataTable";
import MoneyCell from "components/Elements/DataTable/components/Cells/MoneyCell";
import DateCell from "components/Elements/DataTable/components/Cells/DateCell";
import ButtonCell from "components/Elements/DataTable/components/Cells/ButtonCell";
import TextCell from "components/Elements/DataTable/components/Cells/TextCell";
import IconCell from "components/Elements/DataTable/components/Cells/IconCell";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';

function convertParticipantGameHistoryToTable(history, table) {
    var rows = [];


    if (history && history.length > 0) {
        for (var i = 0; i < history.length; i++) {

            let startDate = history[i].startedOn;

            let row = {
                id: history[i].id,
                startDate: history[i].startedOn,
                endDate: history[i].endedOn,
                buyinsTotal: history[i].totalBuyIns,
                result: history[i].totalPromos + history[i].totalCashouts - history[i].totalBuyIns,
                balance: (-1) * history[i].amountOwedByPlayers,
                session: history[i]
            };

            rows.push(row);
        }
    }

    // rows.sort((a,b) => {return a.startedOn - b.startedOn;});
    table.rows = rows;

    return table;
}

function GetPlayerGamesTableSchema(historyDetailButtonClickHandler, maskCurrency) {
    const prefix = maskCurrency === true ? "" : "$";

    return {
        columns: [
            { Header: "started on", accessor: "startDate", Cell: ({ value }) => <DateCell value={value} /> },
            { Header: "ended on", accessor: "endDate", Cell: ({ value }) => <DateCell value={value} /> },
            { Header: "balance", accessor: "balance", Cell: ({ value }) => <MoneyCell value={value} prefix={prefix} useFormatter={true} useColorScheme={true} /> },
            { Header: "total buy-in", accessor: "buyinsTotal", Cell: ({ value }) => <MoneyCell value={value} prefix={prefix} useFormatter={true} useColorScheme={false} /> },
            { Header: "net", accessor: "result", Cell: ({ value }) => <MoneyCell value={value} prefix={prefix} useFormatter={true} useColorScheme={true} /> },
            { Header: "transactions", accessor: "id", Cell: ({ value, row }) => <ButtonCell id={row && row.original ? row.original : value} onButtonClicked={historyDetailButtonClickHandler} icon="receipt" color="secondary" /> },
        ],
        rows: [],
    };
}

function GetEmptySchema() {
    return {
        columns: [],
        rows: []
    }
}

const PlayerGames = ({ player, cardroom }) => {
    const navigate = useNavigate();

    const [gamesTable, setGamesTable] = useState(GetEmptySchema());
    const [sessions, setSessions] = useState(null);
    const [selectedSession, setSelectedSession] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);
    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading game history...", handleRetry)

    const [openPlayerTransactionsDialog, openPlayerTransactionsDialogProps] = useDialog();

    function onOpenHistoryRecordClicked(item) {
        if (item && item.session) {
            setSelectedSession(item.session);
            openPlayerTransactionsDialog();
        }
    }

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    //load player sessions
    useEffect(() => {
        if (!sessions) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading game history...")
            showProgress();

            //{casinoId:Guid}/sessions/participants/{participantId:Guid}/{role:long}
            execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/participants/" + player.id + "/1").then((response) => {
                if (response) {
                    setSessions(response);
                    //create dataTable

                    setGamesTable(convertParticipantGameHistoryToTable(response, GetPlayerGamesTableSchema(onOpenHistoryRecordClicked, isCurrencyMaskingEnabled())));
                    progressIndicatorProps.close();
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load game history, please try again...")
            });
        }
    }, [execute, sessions, retryCounter])



    return <Card>
        <Grid container>
            <Grid item m={1} xs={12} mr="auto" ml="auto">
                <ProgressIndicator {...progressIndicatorProps} />
                {!progressIndicatorProps.visible ? <DataTable table={gamesTable} entriesPerPage={{ defaultValue: 10, visible: true }} pagination={{ color: "dark" }} /> : null}
            </Grid>
        </Grid>
        <PlayerSessionTransactionsDialog {...openPlayerTransactionsDialogProps} cardroom={cardroom} player={player} session={selectedSession} />
    </Card>;
};


PlayerGames.defaultProps = {

};

PlayerGames.propTypes = {
    cardroom: PropTypes.object.isRequired,
    playerId: PropTypes.string.isRequired
};

export default PlayerGames;