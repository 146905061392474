import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { name, stepErrors, stepCount },
} = form;



const validations = {
    editor: Yup.object().shape({
        [name.name]: Yup.string().required(name.errorMsg),
        [stepErrors.name]: Yup.number().max(0, stepErrors.errorMsg).required(stepErrors.errorMsg),
        [stepCount.name]: Yup.number().min(2, stepCount.errorMsg).max(25, "Max step count exceeded").required(stepCount.errorMsg),
    }),

};

export default validations;