import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import wavesWhite from "assets/images/shapes/waves-white.svg";

import { useDialog } from "components/Elements/Dialog/common";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import MiniActionCard from "components/Elements/MiniActionCard";


import { useCardroomContext } from 'features';
import ReportingPeriods from 'layouts/Club/components/Reports/History';
import FeeScheduleEditorDialog from 'layouts/Club/components/AgentFees';
import CardroomLogo from 'components/Elements/CardroomLogo';
import AgencyPlayers from '../AgencyPlayers';
import AgencyAgents from '../AgencyAgents';
import AdaptiveTab from 'components/Elements/AdaptiveTab';



function AgencyDetails({ cardroom }) {

    const [cardroomDetailsData, setCardroomDetailsData] = useState(null);
    const [agency, setAgency] = useState(null);

    const cardroomRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading agency details...", handleRetry)

    const [context, actions, features] = useCardroomContext(cardroom);

    useEffect(() => {
        if (!cardroomDetailsData || cardroomDetailsData.id != cardroom.id) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading casino details...")
            showProgress();
            cardroomRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id).then((response) => {
                setCardroomDetailsData(response);
                //setCardroomStats(null);
                //progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load casino details, please try again...")
            });
        }
    }, [cardroomRequest.execute, cardroomDetailsData, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (cardroomDetailsData && !agency) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading agency info...")
            showProgress();

            clubRequest.execute("GET", apiConfig.club.endpoint + "/agency/casino/" + cardroom.id).then((response) => {

                setAgency(response);

                progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load agency info, please try again...")
            });
        }
    }, [clubRequest.execute, agency, cardroomDetailsData, retryCounter]) //cardroomListData 


    //const [openFeeScheduleEditorDialog, openFeeScheduleEditorDialogProps] = useDialog();

  //  function canManagePlayerSchedules() {
  //      return true;
  //  }

    const tabs = [];

    tabs.push({
        title: { text: "Reports", icon: "summarize" },
        header: null,
        body: <ReportingPeriods cardroom={cardroom} agency={agency} audience="agency" />
    });


    tabs.push({
        title: { text: "Players", icon: "group" },
        header: null,
        body: <AgencyPlayers cardroom={cardroom} agency={agency} />
    });


    tabs.push({
        title: { text: "Agents", icon: "support_agent" },
        header: null, // canManagePlayerSchedules() ? <SoftButton variant="outlined" color="info" onClick={openFeeScheduleEditorDialog}><Icon size="lg">percent</Icon>&nbsp;Manage Player Schedules</SoftButton> : null,
        body: <AgencyAgents cardroom={cardroom} agency={agency} />
    });


    return <>
        {cardroomDetailsData && !progressIndicatorProps.visible ? <>
      {/**   {canManagePlayerSchedules() ? <FeeScheduleEditorDialog {...openFeeScheduleEditorDialogProps} cardroom={cardroom} club={agency} /> : null} */}  
            <SoftBox mb={3} p={1}>
                <SoftBox
                    position="relative"
                    bgColor="secondary"
                    py={3}
                    px={{ xs: 3, sm: 6, md: 1 }}
                    mt={3}
                    borderRadius="xl"
                    variant="gradient" >

                    <SoftBox
                        component="img"
                        src={wavesWhite}
                        alt="pattern-line"
                        width="100%"
                        position="absolute"
                        left="0"
                        top="0"
                        opacity={0.4}
                    />

                    <Grid container alignItems="center" position="relative">

                        <CardroomLogo cardroom={cardroom} agency={agency} canOpenProperties={true} />

                        <Grid item xs={12} lg={11}>
                            <Grid container>
                                <Grid item xs={6} lg={3} p={1} >
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Agent ID", fontWeight: "medium" }}
                                        count={{ text: agency ? agency.appAgentId : "", useNumberFormatter: false }}
                                        icon={{ component: "verified" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Clubs", fontWeight: "medium" }}
                                        count={{ text: agency ? agency.clubCount : 0, color: "success", useNumberFormatter: true }}
                                        icon={{ component: "credit_card" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Players", fontWeight: "medium" }}
                                        count={{ text: agency ? agency.playerCount : 0, color: "success", useNumberFormatter: true }}
                                        icon={{ component: "group" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Agents", fontWeight: "medium" }}
                                        count={{ text: agency ? agency.agentCount : 0, color: "success", useNumberFormatter: true }}
                                        icon={{ color: "info", component: "support_agent" }}
                                    />

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>

            <AdaptiveTab switchToMobileOnSize="md" tabs={tabs} p={1} />

        </> : <ProgressIndicator {...progressIndicatorProps} />}
    </>;
}

export default AgencyDetails;