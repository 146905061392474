
const form = {
    formId: "agency-player-profile-editor-form",
    formField: {
        playerId: {
            name: "playerId",
            label: "Player",
            type: "hidden",
            errorMsg: "a player must be selected"
        },
        clubId: {
            name: "clubId",
            label: "Club",
            type: "text",
            placeholder: "club",
            errorMsg: "Club selection is required",
        },
        rebateSchedule: {
            name: "rebateSchedule",
            label: "Rebate Schedule",
            type: "text",
            placeholder: "rebate schedule",
            errorMsg: "Rebate schedule is required",
        },
        rebateScheduleName: {
            name: "rebateScheduleName",
            label: "Rebate Schedule Name",
            type: "text",
            placeholder: "rebate schedule name",
            errorMsg: "Rebate schedule name is required",
        },
        canAssignAgent: {
            name: "canAssignAgent",
            label: "Can Assign Agent",
            type: "bool"
        },
        agentId:{
            name: "agentId",
            label: "Agent",
            type: "text",
            placeholder: "select player agent",
            errorMsg: "Player agent is required",
        },
        superAgentId:{
            name: "superAgentId",
            label: "Super Agent",
            type: "text",
            placeholder: "select player super agent",
            errorMsg: "Player super agent is required",
        }
    },

};

export default form;
