
const form = {
    formId: "sportsbook-agent-playerpool-form",
    formField: {
        playerPoolId: {
            name: "playerPoolId",
            type: "hidden"
        },
        poolName: {
            name: "poolName",
            type: "text",
            label: "Name",
            placeholder: "package name",
            errorMsg: "Package name is required.",
        },
        commissionScheduleId: {
            name: "commissionScheduleId",
            type: "text",
            label: "Comission Schedule",
            placeholder: "commission schedule",
            errorMsg: "Commission schedule is required.",
        },
        makeupAmount: {
            name: "makeupAmount",
            type: "number",
            label: "Makeup Amount",
            placeholder: "makeup amount",
            errorMsg: "Makeup amount is required and must be >= 0.",
        }
    }

};

export default form;
