import form from "./form";


const {
    formField: {
        feeRate,
        feeThreshold,
        feeScheduleScheme,
        aggregate,
        chipOffsetRate,
        taxRebateRate,
        makeupThreshold,
        excludeRevenueFromTax
    },
} = form;

const initialValues = {
    [feeRate.name]: 0,
    [feeThreshold.name]: 0,
    [feeScheduleScheme.name]: "",
    [aggregate.name]: false,
    [chipOffsetRate.name]: 0,
    [taxRebateRate.name]: 0,
    [makeupThreshold.name]: 0,
    [excludeRevenueFromTax.name]: 0
};

export default initialValues;
