
import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { Card, CardActions, CardContent, Grid, Icon, Menu, MenuItem, Tooltip, useMediaQuery, useTheme } from '@mui/material';

import DataTable from 'components/Elements/DataTable';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';

import { useDialog } from "components/Elements/Dialog/common";

import IconCell from 'components/Elements/DataTable/components/Cells/IconCell';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { InvitationEditorDialog } from 'components/Elements/Invitation';

import whiteCurved from "assets/images/curved-images/white-curved.jpeg";
import MiniStatisticsCard from 'components/Elements/MiniStatisticsCard';
import SoftButton from 'components/SoftButton';
import MiniActionCard from 'components/Elements/MiniActionCard';
import { useBookDataLoader } from '..';
import BookAgentEditorDialog from './Dialogs';

const EditableCell = ({ cardroom, agent, value, canInviteUser, onInviteUser }) => {

    const [contextMenu, setContextMenu] = useState(null);
    const openContextMenu = (event) => setContextMenu(event.currentTarget);
    const closeContextMenu = () => setContextMenu(null);


    function onInviteUserClicked() {
        closeContextMenu();
        if (onInviteUser) onInviteUser(agent);
    }


    return <SoftBox display="flex" justifyContent="space-between" alignItems="center">
        <TextCell value={value} />

        {canInviteUser() && agent && agent.isActivelyManaged !== true ? <>
            <SoftTypography
                color="secondary"
                onClick={openContextMenu}
                sx={{
                    width: "16px",
                    cursor: "pointer",
                }}
            >
                <Icon fontSize="default">more_vert</Icon>
            </SoftTypography>

            <Menu anchorEl={contextMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={Boolean(contextMenu)}
                onClose={closeContextMenu}
                keepMounted
            >

                <MenuItem onClick={onInviteUserClicked}>Invite User</MenuItem>

            </Menu></> : null}
    </SoftBox >;
};

const BookAgentPlayerPools = ({ cardroom, book, agent, pools }) => {


    function prepareAgentPoolsForPresentation(book, agent, pools, table) {

        let rows = [];

        for (var i = 0; i < pools.length; i++) {

            rows.push({
                name: pools[i].name,
                displayNameNoCase: pools[i].name.toLowerCase(),
                playerCount: pools[i].playerCount,
                makeup: pools[i].currentMakeupAmount,
                commission: pools[i].commissionSchedule ? pools[i].commissionSchedule.name : "None",
                pool: pools[i],
                agent: agent
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                return 0;
            });
        }

        table.rows = rows;
        return table;
    }

    function GetEmptySchema() {
        return {
            columns: [
                { Header: "Package", accessor: "name", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Commission Schedule", accessor: "commission", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "# Players", accessor: "playerCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "Makeup", accessor: "makeup", Cell: ({ value }) => <MoneyCell value={value} prefix="$" useColorScheme={false} /> }
            ],
            rows: []
        };
    }

    const [poolsTable, setPoolsTable] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading agent packages...", handleRetry)

    useEffect(() => {
        if (book && agent && pools && !poolsTable) {

            setPoolsTable(prepareAgentPoolsForPresentation(book, agent, pools, GetEmptySchema()));

        }

    }, [book, agent, pools]);

    return <Card>
        <ProgressIndicator {...progressIndicatorProps} />
        {(cardroom && book && poolsTable) && !progressIndicatorProps.visible ? <>
            <DataTable table={poolsTable} canSearch={false} entriesPerPage={{ defaultValue: 1000, visible: false }} pagination={{ color: "dark" }} />
        </> : null}
    </Card>;

};

const BookAgentMobileCard = ({ cardroom, row, book, agent, canInviteUsers, canEditAgent,
    onOpenAgentEditor, onInviteAgentUsers }) => {
    const color = "secondary";

    const theme = useTheme();
    const isXs = !useMediaQuery(theme.breakpoints.up("sm"));

    function canUsersBeInvited() {

        if (!agent || agent.isActivelyManaged === true)
            return false;

        return canInviteUsers();
    }

    let buttonGridSize = 12;
    if (canEditAgent() && canUsersBeInvited()) buttonGridSize = 6;

    return <Card
        sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
            backgroundImage: gradients[color]
                ? `${linearGradient(
                    rgba(gradients[color].main, 0.85),
                    rgba(gradients[color].state, 0.85)
                )}, url(${whiteCurved})`
                : `${linearGradient(
                    rgba(gradients[color].main, 0.85),
                    rgba(gradients[color].state, 0.85)
                )}, url(${whiteCurved})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "visible",
        })}
    >
        <CardContent>
            <SoftBox pt={2} pb={2} textAlign="center">
                <MiniStatisticsCard
                    bgColor="white"
                    title={{ text: agent.appAgentId, fontWeight: "medium" }}
                    count={agent.name}
                    icon={{ color: "dark", component: "support_agent" }}
                    direction={"left"}
                    useNumericFormatter={false}
                />
            </SoftBox>

            <SoftBox pb={2} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12} >
                    <Grid item xs={6} pr={.5}>
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "players", fontWeight: "medium" }}
                            count={{ text: agent.playerCount, color: "dark", useNumericFormatter: true, prefix: null }}
                            icon={isXs ? null : { color: "dark", component: "person" }}
                            direction={"left"}
                        />
                    </Grid>
                    <Grid item xs={6} pl={.5}>

                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "packages", fontWeight: "medium" }}
                            count={{ text: row.original.poolCount, color: "dark", useNumericFormatter: true, prefix: null }}
                            icon={isXs ? null : { color: "dark", component: "group" }}
                            direction={"right"}
                        />

                    </Grid>
                </Grid>
            </SoftBox>

            <SoftBox textAlign="center">
                <MiniActionCard
                    bgColor="white"
                    title={{ text: "makeup", fontWeight: "medium" }}
                    count={{ text: row.original.makeup, useNumericFormatter: true, prefix: "$", color: row.original.makeup > 0 ? "warning" : "success" }}
                    icon={{ color: row.original.makeup > 0 ? "warning" : "success", component: row.original.makeup > 0 ? "attach_money" : "money_off" }}
                    direction={"left"}
                />
            </SoftBox>

        </CardContent>
        <CardActions>
            <Grid container ml="auto" mr="auto" xs={12}>
                {canUsersBeInvited() ?
                    <Grid item xs={buttonGridSize} p={1}>
                        <SoftButton ml="auto" mr="auto"
                            variant="gradient"
                            color={"light"}
                            fullWidth
                            disabled={!canUsersBeInvited()}
                            onClick={() => onInviteAgentUsers(agent)}
                        >
                            INVITE USER&nbsp;
                            <Icon sx={{ fontWeight: "bold" }}>person_add</Icon>
                        </SoftButton>
                    </Grid> : null}

                {canEditAgent() ?
                    <Grid item xs={buttonGridSize} p={1}>
                        <SoftButton ml="auto" mr="auto"
                            variant="gradient"
                            color={"dark"}
                            fullWidth
                            disabled={!canEditAgent()}
                            onClick={() => onOpenAgentEditor(agent)}
                        >
                            SETTINGS&nbsp;
                            <Icon sx={{ fontWeight: "bold" }}>settings</Icon>
                        </SoftButton>
                    </Grid> : null}
            </Grid>
        </CardActions>
    </Card>;

}

const BookAgents = ({ cardroom, book }) => {


    function prepareAgentsForPresentation(agents, playerPools, commissions, table) {

        const pools = [];
        const makeup = [];

        if (playerPools) {
            for (var i = 0; i < playerPools.length; i++) {
                if (!pools[playerPools[i].agentId]) {
                    pools[playerPools[i].agentId] = [];
                    makeup[playerPools[i].agentId] = 0;
                }

                pools[playerPools[i].agentId].push(playerPools[i]);
                makeup[playerPools[i].agentId] += playerPools[i].currentMakeupAmount;
            }
        }

        let rows = [];

        for (var i = 0; i < agents.length; i++) {

            //commission is on player pool, so, we really only care about player pool count
            //let commission = commissionMap[players[i].rebateScheduleId] ? rebateMap[players[i].rebateScheduleId] : null;

            let agentPools = pools[agents[i].id];
            let playerMakeup = makeup[agents[i].id];

            let agentImage = agents[i].name.substring(0, 1);

            rows.push({
                name: [agents[i].name, { image: agentImage }],
                displayName: agents[i].name,
                displayNameNoCase: agents[i].name.toLowerCase(),
                appAgentId: agents[i].appAgentId,
                playerCount: agents[i].playerCount,
                agentCount: agents[i].agentCount,
                poolCount: agentPools ? agentPools.length : 0,
                makeup: playerMakeup,
                pools: agentPools,
                agent: agents[i],
                packages: <BookAgentPlayerPools agent={agents[i]} book={book} pools={agentPools} cardroom={cardroom} />
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                return 0;
            });
        }

        table.rows = rows;
        return table;
    }

    function GetEmptySchema(onOpenAgentEdit, canUsersBeInvited, onOpenInvitationEditor) {
        return {
            columns: [
                {
                    Header: "Agent",
                    accessor: "name",
                    Cell: ({ value: [name, data], row }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} participant={row && row.original && row.original.agent ? row.original.agent : null} onClick={onOpenAgentEdit} />
                    ),
                },
                { Header: "Agent Id", accessor: "appAgentId", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "# Packages", accessor: "poolCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "# Players", accessor: "playerCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "# Agents", accessor: "agentCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },

                { Header: "Makeup", accessor: "makeup", Cell: ({ value }) => <IconCell value={value && value != 0 ? "Yes" : "No"} icon={value && value != 0 ? "attach_money" : "money_off"} color={value && value != 0 ? "warning" : "success"} /> },

                //{ Header: "Makeup", accessor: "makeup", Cell: ({ value }) => <MoneyCell value={value} prefix="$" useColorScheme={false} /> },
                /*
                 {
                     Header: "Source", accessor: "sourceAppName", Cell: ({ value, row }) => {
                         return <EditableCell cardroom={cardroom} value={value}
                             agent={row && row.original ? row.original.agent : null}
                             onInviteUser={onOpenInvitationEditor}
                             canInviteUser={canUsersBeInvited} />;
                     }
                 },
                 */
            ],
            collapsibleDetailAccessor: "packages",
            rows: []
        };
    }

    const switchToMobileOnSize = "md";

    const theme = useTheme();
    const isMobileUi = !useMediaQuery(theme.breakpoints.up(switchToMobileOnSize));

    function openAgentEditor(agent) {
        if (!canEditAgentProfile()) return;

        if (agent) {
            setCurrentAgent(agent);
            openAgentEditorDialog();
        }
    }

    const [openAgentEditorDialog, openAgentEditorDialogProps] = useDialog();

    const [currentAgent, setCurrentAgent] = useState(null);

    const [agents, setAgents] = useState(null);
    const [playerPools, setPlayerPools] = useState(null);
    const [commissions, setCommissions] = useState(null);

    const [agentsTable, setAgentsTable] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    //add event handler to agents updated event?

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading agents...", handleRetry)

    const [openInviteUserDialog, openInviteUserDialogProps] = useDialog();

    function inviteAgencyUsers(agent) {
        if (!canInviteUsers()) return;

        setCurrentAgent(agent);
        openInviteUserDialog();
    }

    const [context, actions, features] = useCardroomContext(cardroom);

    function canInviteUsers() {
        if (!context) return false;

        return context.isAllowed(actions.club.agency.invite);
    }

    const bookRequest = useFetchWithMsal({
        scopes: apiConfig.book.scopes.read,
    });

    const bookDataLoader = useBookDataLoader();

    useEffect(() => {
        if (!agents) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading agents...")
            showProgress();

            bookDataLoader.entity.loadAgents(bookRequest, cardroom, book, true, (response) => {
                setAgents(response);
                setAgentsTable(null);
            }, (ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load agents, please try again...")
            });
        }
    }, [bookRequest.execute, agents, retryCounter]) //cardroomListData 


    useEffect(() => {
        if (agents && !playerPools) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading packages...")
            showProgress();

            bookDataLoader.entity.loadAgentsPlayerPools(bookRequest, cardroom, book, agents, true, (response, errCount) => {
                setPlayerPools(response);
            }, (ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load packages, please try again...");
            });

        }
    }, [bookRequest.execute, agents, playerPools, retryCounter])

    useEffect(() => {
        if (agents && playerPools && !commissions) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading commmission schedules...")
            showProgress();

            bookDataLoader.entity.loadAgentCommissionSchedules(bookRequest, cardroom, book, true, (response) => {
                setCommissions(response);
            }, (ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load commmission schedules, please try again...");
            });
        }
    }, [bookRequest.execute, agents, playerPools, commissions, retryCounter])




    useEffect(() => {
        if (agents && playerPools && commissions && !agentsTable) {
            setAgentsTable(prepareAgentsForPresentation(agents, playerPools, commissions, GetEmptySchema(canEditAgentProfile() ? openAgentEditor : null, canInviteUsers, canInviteUsers() ? inviteAgencyUsers : null)));
            progressIndicatorProps.close();
        }
    }, [agents, commissions, playerPools, agentsTable]);

    function canEditAgentProfile() {
        if (!context) return false;

        return context.isAllowed(actions.club.book.agent.edit);
    }

    function renderMobileRow(columns, row, key, collapsibleDetailAccessor) {
        //ClubAgentMobileCard = ({ cardroom, row, club, agent, canInviteUsers, canEditAgent,onOpenAgentEditor, onInviteAgentUsers

        return <BookAgentMobileCard cardroom={cardroom} row={row} book={book} agent={row.original.agent} canInviteUsers={canInviteUsers} canEditAgent={canEditAgentProfile}
            onOpenAgentEditor={openAgentEditor} onInviteAgentUsers={inviteAgencyUsers} />

    }

    return <Card>
        <ProgressIndicator {...progressIndicatorProps} />
        {(cardroom && book) && !progressIndicatorProps.visible ? <>
            {canEditAgentProfile() ?
                <BookAgentEditorDialog {...openAgentEditorDialogProps} cardroom={cardroom} book={book} agent={currentAgent} /> : null}
            {canInviteUsers() ?
                <InvitationEditorDialog {...openInviteUserDialogProps} cardroom={cardroom} union={null} club={null} book={book} agency={currentAgent} /> : null}
            {agentsTable ?
                <DataTable table={agentsTable} canSearch entriesPerPage={{ defaultValue: 10, visible: !isMobileUi }} pagination={{ color: "dark" }}
                    onRenderRow={renderMobileRow} useCustomRenderSize={switchToMobileOnSize} isHierarchical={true} /> : null}
        </> : null}
    </Card>;
};

export default BookAgents