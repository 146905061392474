import form from "./form";


const {
    formField: {
        scheduleId,
        name,
        stepErrors,
        stepCount
    },
} = form;

const initialValues = {
    [scheduleId.name]: "",
    [name.name]: "",
    [stepErrors.name]: 0,
    [stepCount.name]: 0,
};

export default initialValues;
