import * as Yup from "yup";
import "yup-phone";
import po from "./form";

const {
    formField: {
        approvalAction,
        purchaseOrderId,
        buyerAccount,
        sellerAccount,
        quantity,
        itemType,
        estimatedCost,
        actualCost,
        note,
        deliverTo
    },
} = po;



const validations = {
    create: Yup.object().shape({
        [buyerAccount.name]: Yup.string().required(buyerAccount.errorMsg),
        [sellerAccount.name]: Yup.string().required(sellerAccount.errorMsg),
        [quantity.name]: Yup.number().min(0.1, "Quantity must be greater than 0").required(quantity.errorMsg),
        [estimatedCost.name]: Yup.number().min(0.1, "Estimated cost must be greater than 0").required(estimatedCost.errorMsg),
        [itemType.name]: Yup.string().oneOf(["chips", "tokens"]).required(itemType.errorMsg),
        [deliverTo.name]: Yup.string().when([itemType.name], {
            is: (itemTypeV) => (itemTypeV === "tokens"),
            then: Yup.string().min(3, deliverTo.errorMsg).required(deliverTo.errorMsg)
        })
    }),
    manage: Yup.object().shape({
        [approvalAction.name]: Yup.string().oneOf(["approve", "reject", "cancel"]).required(approvalAction.errorMsg),
        [buyerAccount.name]: Yup.string().required(buyerAccount.errorMsg),
        [sellerAccount.name]: Yup.string().required(sellerAccount.errorMsg),
        [quantity.name]: Yup.number().min(0.1, "Quantity must be greater than 0").required(quantity.errorMsg),
        [itemType.name]: Yup.string().oneOf(["chips", "tokens"]).required(itemType.errorMsg),
        [actualCost.name]: Yup.number().test("actualAmountRequiredWhenApproving", actualCost.errorMsg, function (value, ctx) {
            if (ctx.parent.approvalAction !== "approve") return true;
            return Math.abs(value) > 0;
        }),
        [purchaseOrderId.name]: Yup.string().required(purchaseOrderId.errorMsg)
    })
};

export default validations;