
import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';
import SoftTypography from 'components/SoftTypography';

import DataBoundList from 'components/Elements/DataBound/List';


const PlayerRebateSelector = ({ cardroom, agency, club, book, value, readonly, placeholder, emptyValue, onSelectionChanged, type, onBuildListItem, reload, maxHeight }) => {

    let count = 0; if (agency) count++; if (club) count++; if (book) count++;

    if (count != 1) {
        throw new Error("Must specify either book, agency or club, all or none is not supported");
    }
    // if ((!agency && !club && !book) || (agency && club)) {
    //     throw new Error("Must specify either agency or club, both or neither is supported");
    // }

    var entity = agency || club || book;
    const [schedules, setSchedules] = useState(entity ? entity.rebates : null);
    // const [schedules, setSchedules] = useState(agency ? agency.rebates : club ? club.rebates : book ? book.rebates : null);


    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "retrieving rebate schedules...", handleRetry)


    const [availableOptions, setAvailableOptions] = useState(null);

    const [selectedValue, setSelectedValue] = useState(value ? value : emptyValue);
    const [selectedIndex, setSelectedIndex] = useState(-2);

    function getSelectedIndex() {
        let dataSource = availableOptions; // getDataSource();

        if (!dataSource) return -1;
        if (!selectedValue) return -1;

        for (var i = 0; i < dataSource.length; i++) {
            if (dataSource[i].id == selectedValue.id || dataSource[i].id == selectedValue) {
                return i;
            }
        }

        return -1;
    }

    function findValueInDataSource(v) {
        let dataSource = getDataSource();

        if (!dataSource) return null;
        if (!v) return null;

        for (var i = 0; i < dataSource.length; i++) {
            if (dataSource[i].id == v.id || dataSource[i].id == v) {
                return dataSource[i];
            }
        }

        return -1;
    }

    function getDataSource() {

        if (!schedules) return null;

        let rows = [...schedules];

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });
        }

        if (emptyValue) {

            //if (rows.length === 0) rows.push(emptyValue);
            //else 
            rows.unshift(emptyValue);
        }

        return rows;
    }

    function handleScheduleChange({ item, index }) {
        setSelectedIndex(null);
        setSelectedValue(item);

        if (onSelectionChanged) {
            onSelectionChanged(item);
        }
    }

    const { error, execute } = useFetchWithMsal({
        scopes: cardroom.type === 6 ? apiConfig.book.scopes.read : apiConfig.club.scopes.read,
    });

    useEffect(() => {
        if (reload === true) {
            setSchedules(null);
            setSelectedIndex(-1);
        }
    }, [reload]);

    useEffect(() => {
        if (!schedules) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("retrieving rebate schedules...")
            showProgress();

            let url = "";
            if (club || agency) {
                // [HttpGet("{agencyId:Guid}/casino/{casinoId:Guid}/schedules/rebate", Name = "GetPlayerRebateSchedules")]
                url = apiConfig.club.endpoint;
                if (agency) {
                    url += "/agency/" + agency.id + "/casino/" + cardroom.id + "/schedules/rebate";
                }
                else if (club) {
                    url += "/club/" + club.id + "/casino/" + cardroom.id + "/schedules/rebate";
                }
            }
            else if (book) {
                url = apiConfig.book.endpoint + "/sportsbook/" + book.id + "/casino/" + cardroom.id + "/schedules/rebate";
            }

            execute("GET", url).then((response) => {
                if (response) {
                    if (agency) agency.rebates = response;
                    if (club) club.rebates = response;
                    if (book) book.rebates = response;

                    setSchedules(response);
                }
                progressIndicatorProps.close();
            }).catch((err) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to retrieving rebate schedules, please try again...")
            });

        }

    }, [schedules, execute, retryCounter]);

    useEffect(() => {
        if (schedules) {
            setAvailableOptions(getDataSource());

            if (selectedValue && (!selectedIndex || selectedIndex < -1)) {

                setSelectedIndex(getSelectedIndex());
            }
        }

    }, [schedules, selectedIndex, selectedValue]);

    useEffect(() => {
        if (schedules && value) {

            //find in datasource
            setSelectedValue(findValueInDataSource(value));
        }

    }, [schedules, value]);

    useEffect(() => {
        if (value) {
            //find in datasource
            setSelectedIndex(getSelectedIndex());
        }

    }, [value, selectedIndex]);


    return <>
        <ProgressIndicator  {...progressIndicatorProps} />
        {!progressIndicatorProps.visible ? <>
            {readonly ?
                <SoftTypography
                    variant="caption"
                    fontWeight="regular"
                    color="dark"
                > {selectedValue ? selectedValue.name : "[None]"}
                </SoftTypography> : <>{availableOptions ?
                    <>
                        {type === "dropdown" ?
                            <DataBoundSelect placeholder={placeholder} data={availableOptions} valueName="id" textName="name" onChange={handleScheduleChange} size="medium" selectedIndex={selectedIndex} /> : null}
                        {type === "list" ? <DataBoundList data={availableOptions} valueName="id" textName="name" onChange={handleScheduleChange} selectedIndex={selectedIndex} onBuildListItem={onBuildListItem} maxHeight={maxHeight} /> : null}
                    </>
                    : null}
                </>
            }</> : null}
    </>;
};

PlayerRebateSelector.propTypes = {
    cardoom: PropTypes.object.isRequired,
    agency: PropTypes.object,
    club: PropTypes.object,
    readonly: PropTypes.bool,
    placeholder: PropTypes.string,
    emptyValue: PropTypes.object,
    onSelectionChanged: PropTypes.func,
    type: PropTypes.oneOf(["list", "dropdown"]),
    maxHeight: PropTypes.string
};

PlayerRebateSelector.defaultProps = {
    readonly: false,
    placeholder: "please make a selection",
    emptyValue: { id: null, name: "None" },
    type: "dropdown",
    maxHeight: "200px"
};

export default PlayerRebateSelector;