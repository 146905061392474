import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';

import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";

import { Formik, Form } from "formik";
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import AccountTransactionPayForm from './PayForm';
import AccountTransactionReceiveForm from './ReceiveForm';
import AccountTransactionJournalForm from './AdjustementForm';
import { useUnionDataLoader } from 'layouts/Club/Union/components/common';
import { useClubDataLoader } from 'layouts/Club/Union/components/common';
import { useCurrencyConverter } from 'components/Elements/CurrencyConverter/currency';
import PremiumServiceSubscriptionRequired from 'components/Elements/PremiumServiceRequried';
import { useCardroomContext } from 'features';
import AccountExpenseForm from './ExpenseForm';
import AccountTransferForm from './Transfer';


function getFormContent(type, cardroom, account, accounts, staff, players, clubs, unions, agents, mode, isAdvancedEnabled, defaults, currency, currencySymbol, currencyConverter, formData) {

    if (!cardroom || !formData) return null;

    if (cardroom.type == 1 && (!staff || !players)) return null;
    if (cardroom.type == 2 && (!players || !agents || !accounts)) return null;
    if (cardroom.type == 3 && (!clubs || !accounts)) return null;
    if (cardroom.type == 5 && (!agents || !players || !accounts)) return null;
    if (cardroom.type == 6 && (!agents || !players || !clubs || !accounts)) return null;

    if (type != "pay" && false == isAdvancedEnabled) {
        return <PremiumServiceSubscriptionRequired service="Advanced Accounting" />
    }

    switch (type) {
        case "pay":
            return <AccountTransactionPayForm cardroom={cardroom} cardroomAccount={account} staff={staff} players={players}
                clubs={clubs} unions={unions} agents={agents} formData={formData} mode={mode} defaults={defaults} />;
        case "receive":
            return <AccountTransactionReceiveForm cardroom={cardroom} cardroomAccount={account} staff={staff} players={players}
                clubs={clubs} unions={unions} agents={agents} formData={formData} mode={mode} defaults={defaults} />;
        case "journal":
            return <AccountTransactionJournalForm cardroom={cardroom} cardroomAccount={account} staff={staff} players={players}
                clubs={clubs} unions={unions} agents={agents} formData={formData} mode={mode} defaults={defaults} />;
        case "expense":
            return <AccountExpenseForm cardroom={cardroom} cardroomAccount={account} staff={staff} players={players}
                clubs={clubs} unions={unions} agents={agents} formData={formData} mode={mode} defaults={defaults} />;
        case "transfer":
            return <AccountTransferForm cardroom={cardroom} cardroomAccount={account} accounts={accounts} formData={formData} mode={mode} defaults={defaults} />;
    }
}

const AccountTransactionForm = ({ type, cardroom, cardroomAccount, union, club, agency, book, defaults, mode, onClose, onTransactionCreated }) => {

    function mapParticipantAccounts(parties, accounts) {
        const accountMap = [];
        if (accounts && accounts.length > 0) {
            for (var i = 0; i < accounts.length; i++) {
                accountMap[accounts[i].accountHolderId] = accounts[i];
            }
        }

        switch (cardroom.type) {
            case 1:
                mapHomeGameParticipants(parties, accountMap);
                break;
            case 2:
                mapOnlineClubParticipants(parties, accountMap);
                break;
            case 3:
                mapOnlineUnionParticipants(parties, accountMap);
                break;
            case 5:
                mapOnlineAgencyParticipants(parties, accountMap);
                break;
            case 6:
                mapOnlineBookParticipants(parties, accountMap);
                break;
        }

    }

    function sortEntities(entities) {
        if (entities && entities.length > 0) {
            entities.sort(function (a, b) {
                if (a.displayName < b.displayName) { return -1; }
                if (a.displayName > b.displayName) { return 1; }

                return 0;
            });
        }
    }

    function mapHomeGameParticipants(ppl, accountMap) {
        const staff = [], players = [];

        if (ppl && ppl.length > 0) {
            for (var i = 0; i < ppl.length; i++) {
                ppl[i].account = accountMap[ppl[i].id];
                let phone = ppl[i].phoneNumber, name = ppl[i].name, nickName = null;

                if (ppl[i].properties) {
                    if ((ppl[i].properties.participantType & 1) > 0) {
                        players.push(ppl[i]);
                    }

                    if ((ppl[i].properties.participantType & 254) > 0) {
                        staff.push(ppl[i]);
                    }

                    phone = ppl[i].properties.phoneNumber ? ppl[i].properties.phoneNumber : phone;
                    name = ppl[i].properties.name ? ppl[i].properties.name : name;
                    nickName = ppl[i].properties.nickName ? ppl[i].properties.nickName : nickName;
                }

                if (nickName) {
                    name = name + " \"" + nickName + "\"";
                }

                if (phone) {
                    //format phone
                    let formattedPhone = phone;
                    if (phone.length === 10) {
                        formattedPhone = "+1 (" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + " - ****";
                    }

                    name = name + " @ " + formattedPhone + "";
                }
                else if (ppl[i].isAnonymous) {
                    name = name + " (anonymous)";
                }

                ppl[i].displayName = name;
            }
        }

        sortEntities(staff);
        sortEntities(players);

        setStaff(staff);
        setPlayers(players);
    }

    function mapOnlineUnionParticipants(unionEntity, accountMap) {
        const cs = [], us = [];

        if (unionEntity && unionEntity.length > 0) {
            for (var i = 0; i < unionEntity.length; i++) {
                unionEntity[i].account = accountMap[unionEntity[i].id];

                unionEntity[i].displayName = unionEntity[i].name;

                if (unionEntity[i].appUnionId) {
                    us.push(unionEntity[i]);
                }

                if (unionEntity[i].appClubId) {
                    cs.push(unionEntity[i]);
                }
            }
        }

        //the list contains both clubs and umbrellas

        //TODO: how do we handle Umbrella Unions

        sortEntities(cs);
        sortEntities(us);

        setClubs(cs);
        setUnions(us);
    }

    function mapOnlineClubParticipants(clubEntity, accountMap) {
        const ps = [], as = [], us = [];

        if (clubEntity && clubEntity.length > 0) {
            for (var i = 0; i < clubEntity.length; i++) {
                clubEntity[i].account = accountMap[clubEntity[i].id];

                clubEntity[i].displayName = clubEntity[i].name;

                if (clubEntity[i].appPlayerId) {
                    ps.push(clubEntity[i]);
                }

                if (clubEntity[i].appAgentId) {
                    as.push(clubEntity[i]);
                }

                if (clubEntity[i].appUnionId) {
                    us.push(clubEntity[i]);
                }
            }
        }

        sortEntities(ps);
        sortEntities(as);
        sortEntities(us);

        setPlayers(ps);
        setAgents(as);
        setUnions(us);
    }
    function mapOnlineAgencyParticipants(agencyEntity, accountMap) {
        //map is same as Club Map (agents/players)
        mapOnlineClubParticipants(agencyEntity, accountMap);
    }

    function mapOnlineBookParticipants(bookEntity, accountMap) {
        //map is same as Club Map (agents/players)//?
        mapOnlineClubParticipants(bookEntity, accountMap);
    }

    const { formId, formField } = form;
    const currentValidation = validations[type];

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving transaction...", handleRetry)

    const [participants, setParticipants] = useState(null);
    const [accounts, setAccounts] = useState(null);
    const [players, setPlayers] = useState(null);
    const [staff, setStaff] = useState(null);
    const [clubs, setClubs] = useState(null);
    const [unions, setUnions] = useState(null);
    const [agents, setAgents] = useState(null);

    const [pendingTransaction, setPendingTransaction] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);

    function isAdvancedAccountFeatureEnabled() {
        if (!context) return false;
        return context.isEnabled(features.casino.advancedAccountServices, 0);
    }

    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    function getDefaultCurrency() {
        var currency = "USD";
        if (union) currency = union.currency;
        if (club) currency = club.currency;
        if (agency) currency = agency.currency;

        return currencyConverter.getCurrencyFrom(currency);
    }

    const [currencyConverter, currencies] = useCurrencyConverter(cardroom);

    const [currency, setCurrency] = useState(getDefaultCurrency());

    const [currencySymbol, setCurrencySymbol] = useState(null);
    useEffect(() => {
        if (true === isCurrencyMaskingEnabled()) {
            setCurrencySymbol("");
            return;
        }

        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency]);

    const peopleRequest = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    const accountRequest = useFetchWithMsal({
        scopes: [...apiConfig.account.scopes.read, ...apiConfig.account.scopes.write],
    });

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const bookRequest = useFetchWithMsal({
        scopes: apiConfig.book.scopes.read,
    });

    const unionDataLoader = useUnionDataLoader();
    const clubDataLoader = useClubDataLoader();

    //load casino participants
    useEffect(() => {
        if (!participants && cardroom.type === 1) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading participant data...")
            showProgress();

            peopleRequest.execute("GET", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants/255").then((response) => {
                if (response) {
                    setParticipants(response);
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load participant data, please try again...")
            });
        }
    }, [peopleRequest.execute, participants, retryCounter]) //cardroomListData 

    //union participants
    useEffect(() => {
        if (!participants && union && (cardroom.type === 3)) {

            //union load clubs
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading union data...")
            showProgress();


            unionDataLoader.entity.loadEntities(clubRequest, cardroom, union, unionDataLoader.entities.unions | unionDataLoader.entities.clubs, true, (entities) => {
                setParticipants(entities);
            }, (ex, t) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load union " + t + ", please try again...");
            });


        }
    }, [clubRequest.execute, participants, union, retryCounter]) //cardroomListData 

    //club participants
    useEffect(() => {
        if (!participants && ((cardroom.type === 2 || cardroom.type === 6) && club)) {

            //club or agency load players/agents -- CHANGE TO CALL CLUB SERVICE
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading club data...")
            showProgress();

            clubDataLoader.entity.loadEntities(clubRequest, cardroom, club, clubDataLoader.entities.players | clubDataLoader.entities.agents | clubDataLoader.entities.unions, true, (entities) => {
                if (entities) {
                    setParticipants(entities);
                }
            }, (ex, t) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load club " + t + ", please try again...")
            })


        }
    }, [clubRequest.execute, participants, retryCounter]) //cardroomListData 

    //agency participants
    useEffect(() => {
        if (!participants && (cardroom.type === 5 && agency)) {

            //club or agency load players/agents -- CHANGE TO CALL CLUB SERVICE
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading agency data...")
            showProgress();

            alert('impelemnt agency loader');
        }
    }, [clubRequest.execute, participants, retryCounter]) //cardroomListData 

    //load casino accounts
    useEffect(() => {
        if (participants && !accounts) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading participant accounts...")
            showProgress();

            let accountType = 1;
            //club
            if (cardroom.type == 2) accountType = (1 | 8 | 32 | 7168); //club: union || individual || agent
            //union
            if (cardroom.type == 3) accountType = (8 | 16 | 7168); //union: union (umbrella) + club accounts            
            //agency
            if (cardroom.type == 5) accountType = (1 | 16 | 32 | 7168); //agency: club or individual accounts
            //book
            if (cardroom.type == 6) accountType = (1 | 16 | 32 | 7168); //agency: club or individual accounts

            // [HttpGet("{entityId:Guid}/holders/accounts/{type:int}", Name = "GetEntityAccounts")]
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/accounts/" + accountType).then((response) => {
                if (response) {
                    setAccounts(response);
                    progressIndicatorProps.close();
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load participant accounts, please try again...")
            });
        }
    }, [accountRequest.execute, accounts, participants, retryCounter])


    useEffect(() => {
        if (accounts && participants) {
            mapParticipantAccounts(participants, accounts);
        }
    }, [accounts, participants])


    const handleSubmit = (values, actions) => {
        var tx = null;
        function getPaymentMethod(m) {
            if (m === "cash") return 1;
            if (m === "electronic") {
                if (values.provider === "crypto") return 5;
                return 2;
            }
        }

        function getJournalTransactionType(t) {
            if (t === "credit") return 1; //2; //credit to player, means debit to casino
            if (t === "debit") return 2; //1; //debit player, means credit to casino
        }

        function getExpenseTransactionType(t) {
            if (t === "reimburse") return 2;
            if (t === "charge") return 1;
        }

        let paymentMethod = -1;
        if (values.type === "pay") {
            tx = {
                accountId: values.sourceAccount,
                accountHolderId: cardroom.id,
                transaction: {
                    sourceAccount: {
                        id: values.sourceAccount, //casino account
                        casinoId: cardroom.id,
                        name: 'Casino account'
                    },
                    targetAccount: {
                        id: values.targetAccount, //player/staff account
                        casinoId: cardroom.id,
                        name: 'target account'
                    },
                    description: "Payment to " + values.participant,
                    intent: values.isPrePayment === true ? 64 : 1, // payment
                    type: 2 //debit to casino
                }
            };

            paymentMethod = getPaymentMethod(values.method)
        }
        else if (values.type === "collect") {
            tx = {
                accountId: values.targetAccount,
                accountHolderId: cardroom.id,
                transaction: {
                    targetAccount: {
                        id: values.sourceAccount, //player/staff account
                        casinoId: cardroom.id,
                        name: 'Participant account'
                    },
                    sourceAccount: {
                        id: values.targetAccount, //casino account
                        casinoId: cardroom.id,
                        name: 'Casino account'
                    },
                    description: "Payment from " + values.participant,
                    intent: values.isPrePayment === true ? 64 : 1, // payment
                    type: 1 //credit to casino
                }
            };

            paymentMethod = getPaymentMethod(values.method)
        }
        else if (values.type === "journal") {
            tx = {
                accountId: values.sourceAccount,
                accountHolderId: cardroom.id,
                transaction: {
                    sourceAccount: {
                        id: values.sourceAccount, //casino account
                        casinoId: cardroom.id,
                        name: 'Casino account'
                    },
                    targetAccount: {
                        id: values.targetAccount, //player/staff account
                        casinoId: cardroom.id,
                        name: 'Participant account'
                    },
                    description: values.note ? "" : "Journal " + values.journalTxType,
                    intent: 16, // adjustment
                    type: getJournalTransactionType(values.journalTxType)
                }
            };

            paymentMethod = 3;
        }
        else if (values.type === "expense") {
            tx = {
                accountId: values.sourceAccount,
                accountHolderId: cardroom.id,
                transaction: {
                    sourceAccount: {
                        id: values.sourceAccount, //casino account
                        casinoId: cardroom.id,
                        name: 'Casino account'
                    },
                    targetAccount: {
                        id: values.targetAccount, //player/staff account
                        casinoId: cardroom.id,
                        name: 'Participant account'
                    },
                    description: "Expenses " + values.expenseTxType,
                    intent: 32, // expense
                    type: getExpenseTransactionType(values.expenseTxType)
                }
            };

            paymentMethod = 3;
        }
        else if (values.type === "transfer") {
            tx = {
                accountId: values.sourceAccount,
                accountHolderId: cardroom.id,
                transaction: {
                    sourceAccount: {
                        id: values.sourceAccount, //casino account
                        casinoId: cardroom.id,
                        name: 'Source account'
                    },
                    targetAccount: {
                        id: values.targetAccount, //player/staff account
                        casinoId: cardroom.id,
                        name: 'Target account'
                    },
                    description: "Transfer",
                    intent: 128, // expense
                    type: 3 // always debit from->to account
                }
            };

            paymentMethod = 3;
        }

        if (values.note) {
            if (tx.transaction.description) tx.transaction.description += " (" + values.note + ")";
            else tx.transaction.description = values.note;
        }

        tx.transaction.payment = {
            paymentId: "00000000-0000-0000-0000-000000000000",
            paymentMethod: paymentMethod,
            amount: values.amount,
            currency: values.provider === "crypto" ? values.providerOther : "USD",
            ePaymentVendorName: values.provider === "other" || values.provider === "crypto" ? values.providerOther : values.provider,
            ePaymentVendorReferenceNo: values.providerRef ? values.providerRef : null,
            ePaymentVendorReferenceImage: values.providerRefImage ? values.providerRefImage : null
        };


        if (tx) {
            //set state to be saved
            setPendingTransaction(tx);
        }
    };

    useEffect(() => {
        if (pendingTransaction) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving account transaction...")
            showProgress();

            //  [HttpPost("{entityId:Guid}/holders/{holderId:Guid}/accounts/{accountId:Guid}/transactions", Name = "ProcessTransaction")]
            accountRequest.execute("POST", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/" + pendingTransaction.accountHolderId + "/accounts/" + pendingTransaction.accountId + "/transactions", pendingTransaction.transaction).then((response) => {
                if (response) {

                    if (response.status && response.errors) {
                        throw new Error(response.errors);
                    }

                    setPendingTransaction(null);
                    progressIndicatorProps.close();
                    raiseOnTransactionCreated();
                    raiseOnClose();
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save account transaction, please try again...")
            });
        }
    }, [accountRequest.execute, pendingTransaction, retryCounter]);

    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    function raiseOnTransactionCreated() {
        if (onTransactionCreated) {
            onTransactionCreated();
        }
    }

    return !progressIndicatorProps.visible ? <>
        <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
                return <Form id={formId} autoComplete="off">

                    <SoftBox p={2} >
                        <SoftBox >
                            <ProgressIndicator {...progressIndicatorProps} />

                            {progressIndicatorProps.visible ? <></> : <>
                                {getFormContent(type, cardroom, cardroomAccount, accounts, staff, players, clubs, unions, agents, mode, isAdvancedAccountFeatureEnabled(), defaults, currency, currencySymbol, currencyConverter, {
                                    values,
                                    touched,
                                    formField,
                                    errors,
                                    setFieldValue,
                                    setFieldError,
                                    setFieldTouched,
                                    validateForm,
                                    resetForm
                                })}

                                <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                    <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                        cancel
                                    </SoftButton>

                                    <SoftButton
                                        variant="gradient"
                                        color="dark"
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        save
                                    </SoftButton>
                                </SoftBox>
                            </>

                            }

                        </SoftBox>
                    </SoftBox>

                </Form>
            }}
        </Formik></> : <ProgressIndicator {...progressIndicatorProps} />;
};

AccountTransactionForm.defaultProps = {
    type: "receive",
};

AccountTransactionForm.propTypes = {
    type: PropTypes.oneOf([
        "pay",
        "receive",
        "journal",
        "expense"
    ]).isRequired,
    cardroom: PropTypes.object //.isRequired
};

export default AccountTransactionForm;