import * as Yup from "yup";
import "yup-phone";
import agentForm from "./form";

const {
    formField: { agentId, agentName, currency },
} = agentForm;



const validations = {
    edit: Yup.object().shape({
        [agentName.name]: Yup.string().required(agentName.errorMsg)
    })
};

export default validations;