import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Checkbox, Grid, Icon } from '@mui/material';

import { ErrorMessage } from "formik";
import FormField from "components/Elements/Forms/FormField";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import requestAvatar from "assets/graphics/actions/customer_service_256.png";
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';
import { useCardroomContext } from 'features';

const EditPurchaseOrderForm = ({ cardroom, cardroomAccount, union, club, agency, staff, players, clubs, agents, unions, purchaseOrder, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { type, approvalAction, purchaseOrderId, buyerAccount, sellerAccount, quantity, itemType, estimatedCost, actualCost, note, deliverTo } = formField;
    const {
        type: typeV,
        approvalAction: approvalActionV,
        purchaseOrderId: purchaseOrderIdV,
        buyerAccount: buyerAccountV,
        sellerAccount: sellerAccountV,
        quantity: quantityV,
        itemType: itemTypeV,
        estimatedCost: estimatedCostV,
        actualCost: actualCostV,
        note: noteV,
        deliverTo: deliverToV
    } = values;


    const [formDataInitialized, setFormDataInitialized] = useState(false);

    const [buyerAccountHolderName, setBuyerAccountHolderName] = useState(null);
    const [sellerAccountHolderName, setSellerAccountHolderName] = useState(null);

    //const [participantType, setParticipantType] = useState(null);
    //const [sellerAccountList, setSellerAccountList] = useState(null);
    //const [selectedSellerAccountIndex, setSelectedSellerAccountIndex] = useState(-1);
    //const [sellerTypes, setSellerTypes] = useState([]);

    const [actionAccept, setActionAccept] = useState(false);
    const [actionReject, setActionReject] = useState(false);
    const [actionCancel, setActionCancel] = useState(false);

    const [currencySymbol, setCurrencySymbol] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);
    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    function filterParticipants(ds) {
        if (!ds) return ds;
        const result = [];

        for (var i = 0; i < ds.length; i++) {
            //we only want accounts that have a credit, as we can only pay those that we owe $ to...

            if (ds[i].account) {
                result.push(ds[i]);
            }
            else {
                console.warn("account missing, holder id: " + ds[i].id);
            }
        }

        if (result.length > 0) {
            //sort by displayName

            result.sort(function (a, b) {
                if (a.displayName < b.displayName) { return -1; }
                if (a.displayName > b.displayName) { return 1; }
                return 0;
            });
        }

        return result;
    }

    function formatAmount(amount, prefix) {
        var delimiter = ","; // replace comma if desired
        var a = amount.toString().split('.', 2)
        var d = a[1];
        var i = parseInt(a[0]);
        if (isNaN(i)) { return ''; }
        var minus = '';
        if (i < 0) { minus = '-'; }
        i = Math.abs(i);
        var n = new String(i);
        var a = [];
        while (n.length > 3) {
            var nn = n.substring(n.length - 3);
            a.unshift(nn);
            n = n.substring(0, n.length - 3);
        }
        if (n.length > 0) { a.unshift(n); }
        n = a.join(delimiter);
        if (!d || d.length < 1) { amount = n; }
        else { amount = n + '.' + d; }
        amount = minus + prefix + amount;
        return amount;
    }

    function getTokenName() {

        let entity = union || club || agency;

        if (entity == union && entity.isVirtual === true) {
            if (unions && unions.length) {
                for (var i = 0; i < unions.length; i++) {
                    if (unions[i].isVirtual === false) {
                        entity = unions[i];
                        break;
                    }
                }
            }
        }

        if (entity != null) {
            return entity.app.tokenName + "s";
        }

        return "Token";
    }

    function mapItemType(v) {
        if (v === 1) return "chips";
        if (v === 2) return "tokens";
    }

    function getItemName(v) {
        if (v === "chips") return "chips";
        if (v === "tokens") return getTokenName().toLowerCase();

        return "items";
    }

    function getAccountName(a) {
        if (null == a) return null;

        if (a.accountHolderId == cardroom.id) return cardroom.name;

        const us = unions || [], cs = clubs || [], ag = agents || [];

        const entities = [...us, ...cs, ...ag];

        for (var i = 0; i < entities.length; i++) {
            if (entities[i].id == a.accountHolderId) return entities[i].name;
        }
        return null;
    }

    function getStatusName(s) {

        switch (s) {
            case 1:
                return "pending";
            case 2:
                return "completed";
            case 4:
                return "rejected";
            case 8:
                return "canceled";
        }

        return "Unknown";
    }

    function getStatusContent(s) {
        let icon = "", color = "text";
        let valueText = getStatusName(s);

        switch (s) {
            case 1:
                icon = "hourglass_empty_outlined";
                color = "warning";
                valueText = "Awaiting Approval";
                break;
            case 2:
                icon = "check";
                color = "success";
                break;
            case 3:
                icon = "close_outlined";
                color = "error";
                break;
            case 4:
                icon = "thumb_down_off_alt";
                color = "warning";
                break;
        }

        return <SoftBox display={"flex"} alignItems={"center"}  >
            <Icon fontSize="medium" color={color} ml={-.5}>{icon}</Icon>
            <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
            >
                {valueText}
            </SoftTypography>
        </SoftBox >;
    }

    function getSellerAccountName(po) {
        if (null == po) return null;
        return getAccountName(po.sellerAccount);
    }

    function getBuyerAccountName(po) {
        if (null == po) return null;
        return getAccountName(po.buyerAccount);
    }

    useEffect(() => {
        if (!formDataInitialized && purchaseOrder) {

            setCurrencySymbol(isCurrencyMaskingEnabled() === true ? "" : "$");

            setFieldValue(type.name, "manage");

            setFieldValue(purchaseOrderId.name, purchaseOrder.id);
            setFieldValue(quantity.name, purchaseOrder.quantity);
            setFieldValue(estimatedCost.name, purchaseOrder.estimatedCost);
            setFieldValue(actualCost.name, purchaseOrder.actualCost ? purchaseOrder.actualCost : 0);
            setFieldValue(itemType.name, mapItemType(purchaseOrder.type));

            setFieldValue(approvalAction.name, "");

            setFieldValue(deliverTo.name, purchaseOrder.deliverTo ? purchaseOrder.deliverTo : "");

            //look through partipants

            setFieldValue(buyerAccount.name, purchaseOrder.buyerAccount.id)
            setBuyerAccountHolderName(getBuyerAccountName(purchaseOrder));

            setFieldValue(sellerAccount.name, purchaseOrder.sellerAccount.id);
            setSellerAccountHolderName(getSellerAccountName(purchaseOrder));

            setFieldValue(note.name, purchaseOrder.note);


            setFormDataInitialized(true);
        }
    }, [formDataInitialized, purchaseOrder]);

    useEffect(() => {
        let value = "";

        if (actionAccept) {
            value = "approve";
        } else if (actionReject) {
            value = "reject";
        } else if (actionCancel) {
            value = "cancel";
        }

        setFieldValue(approvalAction.name, value);

    }, [actionAccept, actionReject, actionCancel]);

    function onActionChanged(action) {

        setActionAccept(false);
        setActionReject(false);
        setActionCancel(false);

        switch (action) {
            case "accept":
                setActionAccept(!actionAccept);
                break;
            case "reject":
                setActionReject(!actionReject);
                break;
            case "cancel":
                setActionCancel(!actionCancel);
                break;
        }
    }

    function isInReadOnlyState() {
        if (null == purchaseOrder) return true;

        if (getPurchaseOrderRole() != 1) return true;

        return purchaseOrder.status !== 1;
    }

    function getPurchaseOrderRole() {
        if (null == purchaseOrder) return -1;

        let entity = cardroom;
        if (purchaseOrder.sellerAccount.accountHolderId === entity.id) return 1;
        if (purchaseOrder.buyerAccount.accountHolderId === entity.id) return 2;

        //0: Unknown, 1: seller, 2: buyer
        entity = union || club || agency;
        //alert(entity.name);
        if (null == entity) return -2;

        if (purchaseOrder.sellerAccount.accountHolderId === entity.id) return 1;
        if (purchaseOrder.buyerAccount.accountHolderId === entity.id) return 2;

        return 0;
    }

    function getActionExplanation() {
        if (actionAccept) {
            return "Accepting this request signifies that you've provided the requested items, or plan to do so. This will also result in an expense transaction being added to the requestor's account."
        }

        if (actionReject) {
            return "Rejecting this request signifies that you will not be fulfilling this request."
        }

        if (actionCancel) {
            return "Canceling this request signifies that you no longer wish to have your request faciliated. If the request has already be completed, please coordinate with the requestee to resolve the situation."
        }

        return null;
    }

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={requestAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4}>
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {itemType.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8} display={"flex"} alignItems={"center"}>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    {getItemName(itemTypeV)}
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        {getPurchaseOrderRole() == 1 ?
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            Requestor
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8} display={"flex"} alignItems={"center"}>

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {buyerAccountHolderName}
                                    </SoftTypography>
                                </Grid>
                            </Grid> : null}

                        {getPurchaseOrderRole() == 2 ?
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            Requestee
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8} display={"flex"} alignItems={"center"}>

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {sellerAccountHolderName}
                                    </SoftTypography>
                                </Grid>
                            </Grid> : null}


                        {itemTypeV === "tokens" ?
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            Deliver to player id
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8} display={"flex"} alignItems={"center"}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {deliverToV}
                                    </SoftTypography>
                                </Grid>
                            </Grid> : null}


                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {quantity.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8} display={"flex"} alignItems={"center"}>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    {formatAmount(quantityV, "")}
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {estimatedCost.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8} display={"flex"} alignItems={"center"}>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    {formatAmount(estimatedCostV, currencySymbol)}
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        {getPurchaseOrderRole() === 1 ?
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {actualCost.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>

                                    <FormField onFocus={e => e.currentTarget.select()} autoFocus
                                        type={actualCost.type}
                                        disabled={isInReadOnlyState()}
                                        name={actualCost.name}
                                        value={actualCostV}
                                        placeholder={actualCost.placeholder}
                                        error={errors.actualCost && touched.actualCost}
                                        success={actualCostV && actualCostV.toString().length > 0 && !errors.actualCost}
                                    />
                                </Grid>
                            </Grid> : null}

                        {getPurchaseOrderRole() !== 1 && purchaseOrder && purchaseOrder.status !== 1 ?
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {actualCost.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8} display={"flex"} alignItems={"center"}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {formatAmount(actualCostV, currencySymbol)}
                                    </SoftTypography>
                                </Grid>
                            </Grid> : null}

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {note.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8} display={"flex"} alignItems={"center"}>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize">
                                    {noteV}
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        Status
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftBox ml={-0.75} lineHeight={0} display="inline-block" >
                                    {getStatusContent(purchaseOrder.status)}
                                </SoftBox>
                            </Grid>
                        </Grid>


                        {purchaseOrder.status === 1 ? <>
                            {getPurchaseOrderRole() === 2 ? <Grid container xs={12} >
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            Action
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8} display={"flex"} alignItems={"center"}>
                                    <FormControlLabel
                                        label="Cancel"
                                        control={
                                            <Checkbox
                                                checked={actionCancel}
                                                indeterminate={1 != 2}
                                                onChange={e => onActionChanged("cancel")}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid> : <>
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize">
                                                Action
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8} display={"flex"} alignItems={"center"}>
                                        <SoftBox display="flex" pl={1}>
                                            <FormControlLabel
                                                label="Approve"
                                                control={
                                                    <Checkbox
                                                        checked={actionAccept}
                                                        onChange={e => onActionChanged("accept")}
                                                    />
                                                }
                                            />

                                            <FormControlLabel
                                                label="Reject"
                                                control={
                                                    <Checkbox
                                                        checked={actionReject}
                                                        onChange={e => onActionChanged("reject")}
                                                    />
                                                }
                                            />
                                        </SoftBox>

                                    </Grid>
                                </Grid>
                            </>}

                            <Grid container xs={12}>

                                <Grid item xs={4}></Grid>
                                <Grid item xs={8}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={approvalAction.name} />
                                    </SoftTypography>
                                </Grid>

                                <Grid item xs={12} textAlign={"left"}>

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        color="info">
                                        {getActionExplanation()}
                                    </SoftTypography>
                                </Grid>
                            </Grid>
                        </> : null}
                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox>;
};




export default EditPurchaseOrderForm;