import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Card, Grid, Icon, Table, TableBody, TableRow, Tooltip } from '@mui/material';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { apiConfig } from 'config/apiConfig';
import { useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';
import { ProgressIndicator } from 'components/Elements/ProgressIndicator';
import defaultCasino from "assets/graphics/logos/default-casino.png";
import Swal from 'sweetalert2';
import SoftButton from 'components/SoftButton';
import SoftTagInput from 'components/SoftTagInput';


const AccountTransactionReceipt = ({ cardroom, account, transaction, srcEntity, trgEntity, calculator, currency, currencySymbol, currencyConverter, onClose, onTransactionUpdated }) => {

    const { borderWidth } = borders;
    const { light } = colors;
    const borderBottom = `${borderWidth[1]} solid ${light.main}`;

    const [pendingTransaction, setPendingTransaction] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);

    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.write,
    });

    const accountRequestRead = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Updating transaction...", handleRetry)

    function canCancelTransaction() {
        if (!context) return false;
        if (!transaction) return false;

        if (transaction.status !== 2) return false;

        // if (transaction.intent)

        if ((transaction.intent & 28) > 0) return false; //cannot cancel settlement(8) or marker(4) or adjustement (16) for now

        return context.isAllowed(actions.casino.account.transaction.cancel);
    }

    function canAcceptRejectTransaction() {


        if (!context) return false;
        if (!transaction) return false;

        if (transaction.status !== 1) return false;

        if ((transaction.intent & 12) > 0) return false; //cannot cancel settlement(8) or marker(4)

        return context.isAllowed(actions.casino.account.transaction.acceptOrReject);
    }

    function getEntityImage(entity) {
        if (!entity) return null;

        if (entity.myRole) { //casino entity
            if (entity.hasLogo) {
                //apiConfig.images.endpoint + "/casino/" + cardroom.id + ".png" : defaultCasino
                return apiConfig.images.endpoint + "/casino/" + cardroom.id + ".png";
            }
            return defaultCasino;
        }

        if (entity.app) {
            return apiConfig.images.endpoint + "/app/" + entity.app.id + ".png";
        }

        return null;
    }

    function getValueInActiveCurrency(v) {
        if (!v) return calculator.zero();

        var v = currencyConverter.convertFromUSD(v, currency);
        return calculator.formatDecimalAsCurrency(v, currency.precision);
    }


    function getTransactionType(tx) {
        if (!tx) return "N/A";

        /** Credit = 1,
 Debit = 2,
 DebtCancelation = 3,
 Init = 4,
 Journal = 5 */

        switch (tx.type) {
            case 1:
                return "Credit";
            case 2:
                return "Debit";
            case 3:
                return "Debt Cancelation";
            case 4:
                return "Initial Balance";
            case 5:
                return "Adjustment";
        }

        return "UKNOWN";
    }

    function getTransactionPayMethod(tx) {
        if (!tx || !tx.payment) return "N/A";

        /** Cash = 1,
        Electronic = 2,
        Credit = 3
        Account = 4
        CRypto = 5*/

        switch (tx.payment.paymentMethod) {
            case 1:
                return "Cash";
            case 2:
                return "e-Wallet";
            case 3:
                return "Credit";
            case 5:
                return "Crypto";
        }

        return "UKNOWN";
    }

    function getPaymentVendor(vendor) {
        if (vendor === "apple") return "Apple Pay";
        if (vendor === "cashapp") return "CashApp";
        if (vendor === "venmo") return "Venmo";
        if (vendor === "zelle") return "Zelle";
        if (vendor === "paypal") return "PayPal";

        return vendor;
    }

    function raiseOnClose() {

        if (txChanged && onTransactionUpdated) onTransactionUpdated(transaction);

        if (onClose) onClose();
    }

    function onRequestTransactionCancelation() {
        verifyUserAction("Are you sure you want to cancel this transaction?", "This operation cannot be reversed!", "Yes, cancel", "No", "deny", () => {
            performTransactionAction("cancel", "User requested cancelation.");
        });
    }

    function onRequestTransactionAcceptance() {
        verifyUserAction("Are you sure you want to approve this transaction?", "This operation cannot be reversed!", "Yes, accept", "No", "confirm", () => {
            performTransactionAction("accept", "Accepted by user");
        });
    }
    function onRequestTransactionRejection() {

        verifyUserAction("Are you sure you want to reject this transaction?", "This operation cannot be reversed!", "Yes, reject", "No", "deny", () => {
            performTransactionAction("reject", "Rejected by user");
        });

    }

    function verifyUserAction(title, text, yesButton, noButton, button, onVerified) {

        const newSwal = Swal.mixin({
            customClass: {

            },
            buttonsStyling: true,
            //confirmButtonColor: "#009999",
            cancelButtonColor: "#FBCF33",
            denyButtonColor: "#EA0606"
        });

        const { value: note } = newSwal
            .fire({
                inputAttributes: {
                    autocapitalize: "off",
                },
                focusCancel: true,
                reverseButtons: true,
                showCancelButton: true,
                showDenyButton: button === "deny",
                showConfirmButton: button === "confirm",
                confirmButtonText: yesButton,
                denyButtonText: yesButton,
                cancelButtonText: noButton,
                title: title,
                text: text,
                icon: button === "confirm" ? "success" : "warning",
                allowOutsideClick: () => !Swal.isLoading(),
            }).then((result) => {
                //confirm button style didn't work, so using DENY button instead...
                if (result.isDenied && button === "deny") {
                    onVerified();
                }

                if (result.isConfirmed && button === "confirm") {
                    onVerified();
                }
            });
    }

    function performTransactionAction(action, note) {

        if (action === "cancel" && !canCancelTransaction()) return;
        if ((action === "accept" || action === "reject") && !canAcceptRejectTransaction()) return;

        //update transaction by calling api

        setPendingTransaction({
            action: action,
            note: note,
            transaction: transaction
        });

    }

    const [tags, setTags] = useState(getTags());
    const [pendingTags, setPendingTags] = useState(null);
    const [txChanged, setTxChanged] = useState(false);

    const [txReceipt, setTxReceipt] = useState(transaction && transaction.payment ? transaction.payment.ePaymentVendorReferenceImage : null);

    function getTags() {
        if (!transaction || !transaction.tags) return [];
        const t = [];
        transaction.tags.map((tg) => t.push(tg.value));
        return t;
    }

    function onUpdateTags(newTags) {

        const t = [];

        if (newTags) {
            newTags.map((tg) => t.push({ value: tg, id: "00000000-0000-0000-0000-000000000000" }));
        }

        setPendingTags(t);
        //reconcile tags --   
    }

    useEffect(() => {
        if (pendingTags) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Updating transaction tags...")

            let holderId = transaction.sourceAccount && transaction.sourceAccount.accountHolderId ? transaction.sourceAccount.accountHolderId : cardroom.id;
            // [HttpPost("{entityId:Guid}/holders/{holderId:Guid}/accounts/{accountId:Guid}/transactions/{transactionId:Guid}/tags", Name = "SaveTransactionTags")]
            accountRequest.execute("POST", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/" + holderId + "/accounts/" + account.id + "/transactions/" + transaction.id + "/tags", pendingTags).then((response) => {
                if (response) {
                    transaction.tags = response;
                    setTags(getTags());

                    //todo: track changes and raise when closing?
                    setTxChanged(true);
                    //if (onTransactionUpdated) onTransactionUpdated(transaction);
                }
                setPendingTags(null);
                progressIndicatorProps.close();
            }).catch((ex) => {

                showProgress();

                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to update transaction tags, please try again...")
            });

        }
    }, [accountRequest.execute, pendingTags, retryCounter]);

    useEffect(() => {
        if (pendingTransaction) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Updating transaction...")
            showProgress();

            let verb = "GET";
            if (pendingTransaction.action === "accept") verb = "PUT";
            else if (pendingTransaction.action === "cancel" || pendingTransaction.action === "reject") verb = "DELETE";

            let holderId = pendingTransaction.transaction.sourceAccount && pendingTransaction.transaction.sourceAccount.accountHolderId ? pendingTransaction.transaction.sourceAccount.accountHolderId : cardroom.id;

            accountRequest.execute(verb, apiConfig.account.endpoint + "/" + cardroom.id + "/holders/" + holderId + "/accounts/" + account.id + "/transactions/" + pendingTransaction.transaction.id).then((response) => {
                if (response) {
                    //assing tx to transaction
                    //transaction.status = response.status;
                    transaction = Object.assign(transaction, response);

                    if (onTransactionUpdated) onTransactionUpdated(transaction);
                }
                setPendingTransaction(null);
                progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to update transaction, please try again...")
            });

        }
    }, [accountRequest.execute, pendingTransaction, retryCounter]);  // <ProgressIndicator {...progressIndicatorProps} />

    useEffect(() => {

        if (!txReceipt && transaction && transaction.payment && !transaction.payment.ePaymentVendorReferenceImage && transaction.payment.hasEPaymentVendorReferenceImage === true) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading transaction receipt...")
            showProgress();

            // [HttpGet("{entityId:Guid}/holders/{holderId:Guid}/accounts/{accountId:Guid}/transactions/{transactionId:Guid}", Name = "GetAccountTransaction")]
            let holderId = transaction.sourceAccount && transaction.sourceAccount.accountHolderId ? transaction.sourceAccount.accountHolderId : cardroom.id;

            accountRequestRead.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/" + holderId + "/accounts/" + transaction.sourceAccount.id + "/transactions/" + transaction.id + "?loadReceipt=true").then((response) => {
                if (response) {
                    //assing tx to transaction
                    //transaction.status = response.status;
                    transaction = Object.assign(transaction, response);

                    setTxReceipt(transaction && transaction.payment && transaction.payment.ePaymentVendorReferenceImage ? transaction.payment.ePaymentVendorReferenceImage : "empty");

                    //if (onTransactionUpdated) onTransactionUpdated(transaction);
                }
                setPendingTransaction(null);
                progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load transaction receipt, please try again...")
            });

        }

    }, [accountRequestRead.execute, txReceipt, retryCounter]);

    return <Card> {progressIndicatorProps.visible ? <ProgressIndicator {...progressIndicatorProps} /> :
        <>
            {/* Invoice header */}
            <SoftBox p={3}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12} md={4}>
                        <SoftBox width="100%" textAlign={{ xs: "left", md: "left" }} mt={2}>
                            <SoftBox component="img" src={getEntityImage(trgEntity)} style={{ maxHeight: "96px", maxWidth: "96px" }} p={1} mb={1} />
                            <SoftTypography variant="h6" fontWeight="medium">
                                {trgEntity ? trgEntity.name : null}
                            </SoftTypography>
                            <SoftBox mt={1} mb={2}>
                                <SoftTypography display="block" variant="body2" color="secondary">

                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                    </Grid>
                    <Grid item xs={12} md={7} lg={3}>
                        <SoftBox width="100%" textAlign={{ xs: "left", md: "right" }} mt={2}>
                            <SoftBox component="img" src={getEntityImage(srcEntity)} style={{ maxHeight: "96px", maxWidth: "96px" }} p={1} mb={1} />
                            <SoftTypography variant="h6" fontWeight="medium">
                                {srcEntity ? srcEntity.name : null}
                            </SoftTypography>
                            <SoftBox mt={1} mb={2}>
                                <SoftTypography display="block" variant="body2" color="secondary">

                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                    </Grid>
                </Grid>

                <Grid container justifyContent="space-between" mt={2} textAlign={"center"}>
                    <Grid item xs={12}>
                        <SoftTagInput
                            placeholder="Add new tag."
                            tags={tags}
                            onChange={onUpdateTags}
                        />
                    </Grid>
                </Grid>

                <SoftBox mt={{ xs: 5, md: 5 }}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} md={6}>
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                Record Id
                            </SoftTypography>
                            <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                {transaction.id}
                            </SoftTypography>

                            <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                Status
                            </SoftTypography>
                            <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }} textTransform="capitalize">
                                {transaction.context.status}
                            </SoftTypography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <SoftBox
                                width="100%"
                                display="flex"
                                flexDirection={{ xs: "column", md: "row" }}
                                alignItems={{ xs: "flex-start", md: "center" }}
                                textAlign={{ xs: "left", md: "right" }}
                                mt={{ xs: 3, md: 0 }}
                            >
                                <SoftBox width="50%">
                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                        Amount
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox width="50%">
                                    <SoftTypography variant="caption" fontWeight="medium">
                                        <MoneyCell value={getValueInActiveCurrency(transaction.payment.amount)} prefix={currencySymbol} />
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                            <SoftBox
                                width="100%"
                                display="flex"
                                flexDirection={{ xs: "column", md: "row" }}
                                alignItems={{ xs: "flex-start", md: "center" }}
                                textAlign={{ xs: "left", md: "right" }}
                            >
                                <SoftBox width="50%">
                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                        Tx date:
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox width="50%">
                                    <SoftTypography variant="caption" fontWeight="medium">
                                        {new Date(Date.parse(transaction.occuredOn + "Z")).toLocaleDateString()}
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                            <SoftBox
                                width="100%"
                                display="flex"
                                flexDirection={{ xs: "column", md: "row" }}
                                alignItems={{ xs: "flex-start", md: "center" }}
                                textAlign={{ xs: "left", md: "right" }}
                            >
                                <SoftBox width="50%">
                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                        Tx time:
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox width="50%">
                                    <SoftTypography variant="caption" fontWeight="medium">
                                        {new Date(Date.parse(transaction.occuredOn + "Z")).toLocaleTimeString()}
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="space-between" mt={2}>
                        <Grid item xs={12} md={4}>
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                Transaction Type
                            </SoftTypography>
                            <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }} ml={0.5}>
                                {transaction.context.type}
                            </SoftTypography>
                        </Grid>
                        <Grid item xs={12} md={4} textAlign="right">
                            <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                Transaction Intent
                            </SoftTypography>
                            <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }} mr={0.5} textTransform="capitalize">
                                {transaction.context.intent}
                            </SoftTypography>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="space-between" mt={2} textAlign={"center"}>
                        <Grid item xs={12}>
                            <SoftTypography variant="caption" color="info" fontWeight="medium">
                                {transaction.context.description}
                            </SoftTypography>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>

            {/* Invoice table */}
            <SoftBox p={3}>
                <SoftBox width="100%" overflow="auto">
                    <Table sx={{ minWidth: "32rem" }}>
                        <SoftBox component="thead">
                            <TableRow>
                                <SoftBox
                                    component="th"
                                    width={{ xs: "25%", md: "25%" }}
                                    py={1.5}
                                    px={1}
                                    textAlign="left"
                                    borderBottom={borderBottom}
                                >
                                    <SoftTypography variant="caption" color="text" fontWeight="medium">
                                        Transaction Type
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    borderBottom={borderBottom}
                                >
                                    <SoftTypography variant="caption" color="text" fontWeight="medium">
                                        Payment Method
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    borderBottom={borderBottom}
                                >
                                    <SoftTypography variant="caption" color="text" fontWeight="medium">
                                        Payment Vendor
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    borderBottom={borderBottom}
                                >
                                    <SoftTypography variant="caption" color="text" fontWeight="medium">
                                        Currency
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    borderBottom={borderBottom}
                                >
                                    <SoftTypography variant="caption" color="text" fontWeight="medium">
                                        Amount
                                    </SoftTypography>
                                </SoftBox>
                            </TableRow>
                        </SoftBox>
                        <TableBody>
                            <TableRow>
                                <SoftBox component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                    <SoftTypography variant="caption" fontWeight="medium" color="text">
                                        {getTransactionType(transaction)}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    borderBottom={borderBottom}
                                >
                                    <SoftTypography variant="caption" fontWeight="medium" color="text">
                                        {getTransactionPayMethod(transaction)}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    borderBottom={borderBottom}
                                >
                                    <SoftTypography variant="caption" fontWeight="medium" color="text">
                                        {getPaymentVendor(transaction.payment.ePaymentVendorName)}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    borderBottom={borderBottom}
                                >
                                    <SoftTypography variant="caption" fontWeight="medium" color="text" textTransform="uppercase">
                                        {transaction.payment.currency}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    borderBottom={borderBottom}
                                >
                                    <SoftTypography variant="caption" fontWeight="medium" color="text">
                                        <MoneyCell value={transaction.payment.amount} prefix={isCurrencyMaskingEnabled() ? "" : "$"} useColorScheme={false} />
                                    </SoftTypography>
                                </SoftBox>
                            </TableRow>
                            {transaction.payment.paymentMethod == 2 ?
                                <TableRow>
                                    <SoftBox component="td" colSpan="4" textAlign="left" p={1} pr={3} borderBottom={borderBottom}>
                                        <Grid container xs={12}>
                                            <Grid item xs={4}>
                                                <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                    Vendor Reference:
                                                </SoftTypography>
                                            </Grid>
                                            {transaction.payment.ePaymentVendorReferenceNo ?
                                                <Grid item xs={8} textAlign="right">
                                                    <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                        {transaction.payment.ePaymentVendorReferenceNo}
                                                    </SoftTypography>
                                                </Grid> : null}

                                            {txReceipt ?
                                                <Grid item xs={8} textAlign="center">
                                                    <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                        <SoftBox component="img"
                                                            src={txReceipt}
                                                            width={{ lg: "82px", sm: "96px", xl: "96px" }}
                                                        />
                                                    </SoftTypography>
                                                </Grid> : null}
                                        </Grid>

                                    </SoftBox>
                                </TableRow> : null}

                            {transaction.note ?
                                <TableRow>
                                    <SoftBox component="td" colSpan="4" textAlign="right" p={1} pr={3} borderBottom={borderBottom}>
                                        <SoftTypography variant="caption" fontWeight="medium" color="text">
                                            NOTE: </SoftTypography><SoftTypography variant="caption" fontWeight="medium" color="text">{transaction.note}</SoftTypography>
                                    </SoftBox>
                                </TableRow> : null}
                        </TableBody>
                    </Table>
                </SoftBox>
            </SoftBox>

            {/* Invoice footer */}
            <SoftBox p={3} mt={1}>
                <Grid container>
                    <Grid container>
                        <Grid item xs={12} lg={5}>
                            {canCancelTransaction() ?
                                <SoftBox
                                    width="100%"
                                    height={{ xs: "auto", md: "100%" }}
                                    display="flex"
                                    justifyContent={{ xs: "flex-start", md: "flex-start" }}
                                    alignItems="flex-start"
                                    mt={{ xs: 2, md: 0 }}
                                >
                                    <SoftButton
                                        variant="outlined"
                                        color="error"
                                        onClick={onRequestTransactionCancelation}
                                    >
                                        cancel transaction
                                    </SoftButton>
                                </SoftBox> : null}
                            {canAcceptRejectTransaction() ? <SoftBox
                                width="100%"
                                height={{ xs: "auto", md: "100%" }}
                                display="flex"
                                justifyContent={{ xs: "flex-start", md: "flex-start" }}
                                alignItems="flex-start"
                                mt={{ xs: 2, md: 0 }}
                            >
                                <SoftButton
                                    variant="outlined"
                                    color="error"
                                    onClick={onRequestTransactionRejection}
                                >
                                    <Icon size="lg">thumb_down_off_alt</Icon>&nbsp;reject transaction
                                </SoftButton>
                                &nbsp;&nbsp;
                                <SoftButton
                                    variant="outlined"
                                    color="success"
                                    onClick={onRequestTransactionAcceptance}
                                >
                                    <Icon size="lg">thumb_up_off_alt</Icon>&nbsp;accept transaction
                                </SoftButton>
                            </SoftBox> : null}
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <SoftBox
                                width="100%"
                                height={{ xs: "auto", md: "100%" }}
                                display="flex"
                                justifyContent={{ xs: "flex-start", md: "flex-end" }}
                                alignItems="flex-end"
                                mt={{ xs: 2, md: 0 }}
                            >
                                <SoftButton
                                    variant="outlined"
                                    color="dark"
                                    onClick={raiseOnClose}
                                >
                                    close
                                </SoftButton>
                            </SoftBox>
                        </Grid>
                    </Grid>
                </Grid>
            </SoftBox>
        </>}
    </Card>;
};


export default AccountTransactionReceipt;