
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { BootstrapDialog } from "components/Elements/Dialog/common"


import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';
import DataTable from 'components/Elements/DataTable';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import { Backdrop, Card, CardActions, CardContent, DialogActions, Divider, Grid, Icon, Menu, MenuItem, MenuList, SpeedDial, SpeedDialAction, SpeedDialIcon, alpha, styled } from '@mui/material';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import { useDialog } from 'components/Elements/Dialog/common';
import SoftButton from 'components/SoftButton';
import ClubEditorDialog from 'layouts/Club/components/Club';
import { InvitationEditorDialog } from 'components/Elements/Invitation';
import whiteCurved from "assets/images/curved-images/white-curved.jpeg";

import MiniStatisticsCard from 'components/Elements/MiniStatisticsCard';
import { useUnionDataLoader } from '../common';
import { useUnionCalculator } from 'layouts/Club/components/Reports/common/calculator';
import { useNotificationDataLoader } from 'features/NotificationGroups';

const clubManagementUtility = {
    getListOfEligibleUnionTargets: function (union, unions, currentUnion) {

        const rows = []; //[union, ...unions];

        for (var i = 0; i < unions.length; i++) {
            if (unions[i].id != currentUnion.id) {
                rows.push(unions[i]);
            }
        }

        rows.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
        });

        if (union.id != currentUnion.id) {
            rows.unshift(union);
        }

        return rows;
    }
};

//onInviteUser, onMoveClubToUnion 

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

function MoveToUnionButtonGroup({ cardroom, union, club, currentParentUnion, unions, canMoveClubToUnion, onMoveToUnionCompleted }) {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [targetUnion, setTargetUnion] = useState(null);

    const [eligibleUnions, setElegibleUnions] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openMoveClubToUnionDialog, openMoveClubToUnionDialogProps] = useDialog();
    const onMoveToUnionButtonClicked = (event, targetUnion) => {
        setAnchorEl(event.currentTarget);
        //setSelectedIndex(index);
        //setOpen(false);

        //if (onMoveToUnionRequested) onMoveToUnionRequested(club, targetUnion);

        setTargetUnion(targetUnion);
        openMoveClubToUnionDialog();

        handleClose();
    };

    const clubMovedToNewUnion = () => {
        setTargetUnion(null);
        setElegibleUnions(null); // force recalc of menu
        if (onMoveToUnionCompleted) onMoveToUnionCompleted(targetUnion);
    };

    useEffect(() => {
        if (!eligibleUnions && unions && currentParentUnion) {
            setElegibleUnions(clubManagementUtility.getListOfEligibleUnionTargets(union, unions, currentParentUnion));
        }
    }, [eligibleUnions, unions, currentParentUnion]);

    return (
        canMoveClubToUnion() ?
            <div>
                <MoveClubToUnionDialog {...openMoveClubToUnionDialogProps} cardroom={cardroom}
                    union={union} club={club} targetUnion={targetUnion} onMoved={clubMovedToNewUnion} />
                <SoftButton
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}

                >
                    <Icon>edit</Icon>
                </SoftButton>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {eligibleUnions ? eligibleUnions.map((u) => (
                        <MenuItem
                            key={u.id}
                            onClick={(event) => onMoveToUnionButtonClicked(event, u)}
                        >
                            <Icon />
                            {u.name}
                        </MenuItem>
                    )) : null}
                </StyledMenu>
            </div> : null
    );

}

const UnionSelector = ({ cardroom, union, club, currentParentUnion, unions, canMoveClubToUnion, onMoveToUnionCompleted }) => {
    const bgColor = "white", icon = { color: "dark", component: "beach_access" };

    return <Card>
        <SoftBox bgColor={bgColor} variant="gradient">
            <SoftBox p={2}>
                <Grid container alignItems="center">
                    <Grid item xs={1}>
                        <SoftBox
                            variant="gradient"
                            bgColor={bgColor === "white" ? icon.color : "white"}
                            color={bgColor === "white" ? "white" : "dark"}
                            width="3rem"
                            height="3rem"
                            marginLeft="auto"
                            borderRadius="md"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            shadow="md"
                        >
                            <Icon fontSize="small" color="inherit">
                                {icon.component}
                            </Icon>
                        </SoftBox>
                    </Grid>
                    <Grid item xs={8}>
                        <SoftBox ml={2} lineHeight={1}>
                            <SoftTypography
                                variant="button"
                                fontWeight="bold"
                                color={bgColor === "white" ? "dark" : "white"}
                            >
                                {currentParentUnion ? currentParentUnion.name : null}
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                    <Grid item xs={3}>
                        <MoveToUnionButtonGroup cardroom={cardroom} union={union} club={club}
                            currentParentUnion={currentParentUnion} unions={unions} canMoveClubToUnion={canMoveClubToUnion} onMoveToUnionCompleted={onMoveToUnionCompleted} />
                    </Grid>
                </Grid>
            </SoftBox>
        </SoftBox>
    </Card>
}

const EditableCell = ({ cardroom, union, parentUnion, unions, club, canMoveClubToUnion, canInviteUser, onInviteUser }) => {

    const [unionMenu, setUnionMenu] = useState(null);
    const openSetUnionMenu = (event) => setUnionMenu(event.currentTarget);
    const closeSetUnionMenu = () => setUnionMenu(null);

    const [openMoveClubToUnionDialog, openMoveClubToUnionDialogProps] = useDialog();

    const [eligibleUnions, setEligibleUnions] = useState(null);
    const [moveToUnion, setMoveToUnion] = useState(null);
    const [parentUnionLocalCopy, setParentUnionLocalCopy] = useState(parentUnion);

    useEffect(() => {
        if (unions && parentUnionLocalCopy && !eligibleUnions) {
            setEligibleUnions(clubManagementUtility.getListOfEligibleUnionTargets(union, unions, parentUnionLocalCopy));
        }
    }, [unions, parentUnionLocalCopy, eligibleUnions]);

    function onMenuItemClicked(targetUnion) {
        //alert("move" + club.name + "->" + targetUnion.name);
        //open move club dialog
        setMoveToUnion(targetUnion);
        closeSetUnionMenu();
        openMoveClubToUnionDialog();
    }

    function onClubMovedToNewUnion() {
        //this may need to trigger reloading of table??? is there a way to force just cell update?
        //updating parentUnion should do he trick?
        setParentUnionLocalCopy(moveToUnion);
        setMoveToUnion(null);
        setEligibleUnions(null); //force menu refresh

        for (var i = 0; i < unions.length; i++) {
            if (unions[i].clubs) unions[i].clubs = null;
            if (unions[i].unions) unions[i].unions = null;
        }
        if (union.clubs) union.clubs = null;
        if (union.unions) union.unions = null;
    }

    function onInviteUserClicked() {
        closeSetUnionMenu();
        if (onInviteUser) onInviteUser(club);
    }

    if ((!eligibleUnions || eligibleUnions.length == 0) && club.isActivelyManaged === true) {
        return null;
    }

    return <SoftBox display="flex" justifyContent="space-between" alignItems="center">
        {canMoveClubToUnion() ?
            <MoveClubToUnionDialog {...openMoveClubToUnionDialogProps} cardroom={cardroom} union={union} club={club} targetUnion={moveToUnion} onMoved={onClubMovedToNewUnion} /> : null}
        <TextCell value={parentUnionLocalCopy ? parentUnionLocalCopy.name : null} />
        <SoftTypography
            color="secondary"
            onClick={openSetUnionMenu}
            sx={{
                width: "16px",
                cursor: "pointer",
            }}
        >
            <Icon fontSize="default">more_vert</Icon>
        </SoftTypography>

        <Menu anchorEl={unionMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(unionMenu)}
            onClose={closeSetUnionMenu}
            keepMounted
        >
            {canInviteUser() && club.isActivelyManaged !== true ?
                < MenuItem onClick={onInviteUserClicked}>Invite User</MenuItem> : null}
            {canMoveClubToUnion() && eligibleUnions && eligibleUnions.length > 0 ? <>
                {club.isActivelyManaged !== true ?
                    <MenuItem divider={true}></MenuItem> : null}

                <MenuItem disabled={true}>Move to</MenuItem>
                <MenuItem divider={true}></MenuItem>
                {eligibleUnions ? eligibleUnions.map((m) => {
                    return <MenuItem onClick={() => onMenuItemClicked(m)}>{m.name}</MenuItem>
                }) : null}
            </> : null}


        </Menu>
    </SoftBox >;
};

const MoveClubToUnionDialog = ({ open, close, cardroom, union, club, targetUnion, onMoved }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Saving your changes...", handleRetry)
    const [readyToSave, setReadyToSave] = useState(false);


    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.write,
    });

    function canMoveClub() {
        return true;
    }

    function moveClubToNewUnion() {
        //perform the move

        //once service calls succeeds, raise close & onMoved events
        setReadyToSave(true);

    }

    useEffect(() => {
        if (readyToSave) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving your changes...")
            showProgress();
            //[HttpPut("{unionId:Guid}/casino/{casinoId:Guid}/clubs/{clubId:Guid}/parent/{parentUnionId:Guid}", Name = " UpdateClubsParentUnion")]
            clubRequest.execute("PUT", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id + "/clubs/" + club.id + "/parent/" + targetUnion.id).then((response) => {
                // setCardroomDetailsData(response);
                if (response) {
                    club = Object.assign(club, response);

                    if (onMoved) {
                        onMoved();
                    }
                    progressIndicatorProps.close();
                    close();
                }
                setReadyToSave(false);
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save changes, please try again...")
            });
        }
    }, [clubRequest.execute, readyToSave, retryCounter]);

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={close}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.secondary.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Change union affiliation?
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox mt={3}>
                <ProgressIndicator {...progressIndicatorProps} />

                {!progressIndicatorProps.visible && club && targetUnion ? <>
                    <SoftTypography textAlign="center">
                        <SoftTypography color="dark" variant="h6" alignItems="center">
                            Moving a club from one union to another could change previously generated reports.
                        </SoftTypography>
                        <SoftTypography color="dark" variant="h6" alignItems="center">
                            Are you sure you want to move club "{club.name}" to union "{targetUnion.name}"?
                        </SoftTypography>

                    </SoftTypography>
                </> : null}
            </SoftBox>
        </DialogContent>
        <DialogActions>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <SoftButton onClick={close} variant="outlined" color="warning">
                    cancel
                </SoftButton>

                <SoftButton onClick={moveClubToNewUnion} variant="outlined" color="success" disabled={!canMoveClub()}>
                    move
                </SoftButton>
            </SoftBox>
        </DialogActions>
    </BootstrapDialog>;
};

MoveClubToUnionDialog.defaultProps = {

};

MoveClubToUnionDialog.propTypes = {
    cardroom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    targetUnion: PropTypes.object.isRequired
};

export { MoveClubToUnionDialog };

const UnionClubMobileCard = ({ cardroom, union, row, club, parentUnion, unions, canInviteUsers, canEditClub,
    canMoveClubToUnion, onOpenClubEditor, onInviteClubUsers }) => {
    const color = "secondary";

    const theme = useTheme();
    const isXs = !useMediaQuery(theme.breakpoints.up("sm"));

    const [localParentUnion, setLocalParentUnion] = useState(parentUnion);

    function onClubMovedToUnion(newUnion) {
        row.original.union = newUnion;
        setLocalParentUnion(newUnion);
    }

    function canUsersBeInvited() {

        if (!club || club.isActivelyManaged === true)
            return false;

        return canInviteUsers();
    }

    let buttonGridSize = 12;
    if (canEditClub() && canUsersBeInvited()) buttonGridSize = 6;

    return <Card
        sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
            backgroundImage: gradients[color]
                ? `${linearGradient(
                    rgba(gradients[color].main, 0.85),
                    rgba(gradients[color].state, 0.85)
                )}, url(${whiteCurved})`
                : `${linearGradient(
                    rgba(gradients[color].main, 0.85),
                    rgba(gradients[color].state, 0.85)
                )}, url(${whiteCurved})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "visible",
        })}
    >
        <CardContent>
            <SoftBox pt={3} pb={2} textAlign="center">
                <MiniStatisticsCard
                    bgColor="white"
                    title={{ text: club.appClubId, fontWeight: "medium" }}
                    count={club.name}
                    icon={{ color: "dark", component: "nightlife" }}
                    direction={"left"}
                    useNumericFormatter={false}
                />
            </SoftBox>

            {unions && unions.length > 0 ? <SoftBox pb={2} textAlign="center">
                <UnionSelector cardroom={cardroom} union={union} club={club} currentParentUnion={localParentUnion}
                    unions={unions} canMoveClubToUnion={canMoveClubToUnion} onMoveToUnionCompleted={onClubMovedToUnion} />
            </SoftBox> : null}

            <SoftBox textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12} >
                    <Grid item xs={6} pr={.5}>
                        <MiniStatisticsCard
                            bgColor="white"
                            title={{ text: "agents", fontWeight: "medium" }}
                            count={club.agentCount}
                            icon={isXs ? null : { color: "dark", component: "support_agent" }}
                            direction={"right"}
                        />
                    </Grid>
                    <Grid item xs={6} pl={.5}>
                        <MiniStatisticsCard
                            bgColor="white"
                            title={{ text: "players", fontWeight: "medium" }}
                            count={club.playerCount}
                            icon={isXs ? null : { color: "dark", component: "person" }}
                            direction={"right"}
                        />
                    </Grid>
                </Grid>
            </SoftBox>

        </CardContent>
        <CardActions>
            <Grid container ml="auto" mr="auto" xs={12}>
                {canUsersBeInvited() ?
                    <Grid item xs={buttonGridSize} p={1}>
                        <SoftButton ml="auto" mr="auto"
                            variant="gradient"
                            color={"light"}
                            fullWidth
                            disabled={!canUsersBeInvited()}
                            onClick={() => onInviteClubUsers(club)}
                        >
                            <SoftBox whiteSpace="nowrap">INVITE USER&nbsp;<Icon sx={{ fontWeight: "bold" }}>person_add</Icon></SoftBox>
                        </SoftButton>
                    </Grid> : null}

                {canEditClub() ?
                    <Grid item xs={buttonGridSize} p={1}>
                        <SoftButton ml="auto" mr="auto"
                            variant="gradient"
                            color={"dark"}
                            fullWidth
                            disabled={!canEditClub()}
                            onClick={() => onOpenClubEditor(club)}
                        >
                            <SoftBox whiteSpace="nowrap" color="light" >SETTINGS&nbsp;<Icon sx={{ fontWeight: "bold" }}>settings</Icon></SoftBox>
                        </SoftButton>
                    </Grid> : null}
            </Grid>
        </CardActions>
    </Card>;

}

const UnionClubReportHistory = ({ cardroom, union, club, currency, currencySymbol, currencyConverter }) => {

    function getValueInActiveCurrency(v) {
        if (!v) return calculator.zero();

        var v = currencyConverter.convertFromUSD(v, currency);
        return calculator.formatDecimalAsCurrency(v, currency.precision);
    }

    function prepareReportsForPresentation(reports, table, calculator) {
        let rows = [];


        function formatStartEndDates(start, end) {

            function formatDateForDisplay(dt) {
                const year = dt.getFullYear();
                const month = (dt.getMonth() + 1).toString().padStart(2, "0");
                const day = dt.getDate().toString().padStart(2, "0");

                return month + "/" + day;
            }

            let result = "";
            if (start) {
                let sdt = new Date(Date.parse(start));
                result += formatDateForDisplay(sdt); // sdt.toLocaleDateString();
            }

            if (end) {
                let edt = new Date(Date.parse(end));
                if (result) result += " - ";
                result += formatDateForDisplay(edt); //edt.toLocaleDateString();
            }

            return result;
        }

        function getReportingPeriod(report) {
            if (!report) return "";
            return formatStartEndDates(report.startTime, report.endTime);
        }

        for (var i = 0; i < reports.length; i++) {

            rows.push({
                id: reports[i].id,
                startDate: reports[i].startDate,
                period: getReportingPeriod(reports[i]),
                wins: reports[i].winnings,
                fees: reports[i].fees,
                unionDues: calculator.unionDues(reports[i]),
                chipOffset: calculator.chipOffset(reports[i]), //extract file name
                tax: calculator.ecoTax(reports[i]),
                referrals: reports[i].referralBonus,
                settlement: reports[i].settlementAmount,
                report: reports[i]
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {

                let aDate = Date.parse(a.startDate);
                let bDate = Date.parse(b.startDate);

                if (aDate > bDate) { return -1; }
                if (aDate < bDate) { return 1; }
                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function GetEmptySchema() {

        return {
            columns: [
                { Header: "Period", accessor: "period", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Wins", accessor: "wins", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} /> },
                { Header: "Fees", accessor: "fees", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} /> },
                { Header: "Union Dues", accessor: "unionDues", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} /> },
                { Header: "Chip Offset", accessor: "chipOffset", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} /> },
                { Header: "Tax/Rebate", accessor: "tax", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} /> },
                { Header: "Referrals", accessor: "referrals", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} /> },
                { Header: "Settlement", accessor: "settlement", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} /> },
            ],
            rows: []
        };
    }

    const calculator = useUnionCalculator();

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const dataLoader = useUnionDataLoader();

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading report history...", handleRetry)

    const [historyTable, setHistoryTable] = useState(null);
    const [reportHistory, setReportHistory] = useState(null);

    useEffect(() => {
        if (!reportHistory) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading report history...")
            showProgress();

            dataLoader.report.loadClubSummaryHistory(clubRequest, cardroom, union, club, 15, (result) => {
                setReportHistory(result);
            }, (ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load report history, please try again...")
            });

        }
    }, [clubRequest.execute, reportHistory, retryCounter]);

    useEffect(() => {
        if (reportHistory && !historyTable) {
            setHistoryTable(prepareReportsForPresentation(reportHistory, GetEmptySchema(), calculator));
            progressIndicatorProps.close();
        }
    }, [reportHistory, historyTable]);

    //onRenderRow={renderMobileRow} useCustomRenderSize={switchToMobileOnSize}

    return <Card>
        {(cardroom && union && club) && !progressIndicatorProps.visible ? <>
            {historyTable ?
                <DataTable table={historyTable} entriesPerPage={{ defaultValue: 100, visible: false }} showTotalEntries={false}
                    pagination={{ color: "dark" }} />
                : null}
        </> : <ProgressIndicator {...progressIndicatorProps} />}
    </Card>;
};

const UnionClubs = ({ cardroom, union, currency, currencySymbol, currencyConverter }) => {

    function createObjectMap(entities) {
        const map = [];
        if (entities) {
            for (var i = 0; i < entities.length; i++) {
                map[entities[i].id] = entities[i];
            }
        }
        return map;
    }

    function prepareClubsForPresentation(clubs, unions, notificationGroups, table) {
        const unionMap = createObjectMap(unions);
        const groupMap = createObjectMap(notificationGroups);

        if (!unionMap[union.id]) {
            unionMap[union.id] = union;
        }

        let rows = [];

        for (var i = 0; i < clubs.length; i++) {
            let clubImage = clubs[i].name.substring(0, 1);

            let parentUnion = unionMap[clubs[i].parentUnionId];
            let group = groupMap[clubs[i].notificationGroupId];

            rows.push({
                name: [clubs[i].name, { image: clubImage }],
                displayName: clubs[i].name,
                displayNameNoCase: clubs[i].name.toLowerCase(),
                appClubId: clubs[i].appClubId,
                sourceAppName: clubs[i].app ? clubs[i].app.name : "None",
                playerCount: clubs[i].playerCount,
                agentCount: clubs[i].agentCount,
                union: parentUnion,
                notificationGroupName: group ? group.name : clubs[i].notificationGroupId ? "[X]" : "N/A",
                notificationGroup: group,
                history: <UnionClubReportHistory cardroom={cardroom} union={union} club={clubs[i]} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} />,
                club: clubs[i]
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function GetEmptySchema(onOpenClubEdit, onOpenInvitationEditor, onInitiateClubMove) {
        return {
            columns: [
                {
                    Header: "Club",
                    accessor: "name",
                    Cell: ({ value: [name, data], row }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} participant={row && row.original && row.original.club ? row.original.club : null} onClick={onOpenClubEdit} />
                    ),
                },
                { Header: "Club Id", accessor: "appClubId", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "# Players", accessor: "playerCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "# Agents", accessor: "agentCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "Source", accessor: "sourceAppName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Group", accessor: "notificationGroupName", Cell: ({ value }) => <TextCell value={value} /> },
                {
                    Header: "Union", accessor: "union", Cell: ({ value, row }) => {
                        //if value, then we want to paint vertical ... & open context menu
                        return <EditableCell cardroom={cardroom} union={union} unions={unions} parentUnion={value}
                            club={row && row.original ? row.original.club : null} row={row}
                            onInviteUser={onOpenInvitationEditor} onMoveClubToUnion={onInitiateClubMove}
                            canInviteUser={canInviteUsers}
                            canMoveClubToUnion={canMoveClubToUnion} />;
                    }
                },
            ],
            collapsibleDetailAccessor: "history",
            rows: []
        };
    }

    const switchToMobileOnSize = "md";

    const theme = useTheme();
    const isMobileUi = !useMediaQuery(theme.breakpoints.up(switchToMobileOnSize));

    const [unions, setUnions] = useState(null);

    const [clubs, setClubs] = useState(null);

    const [notificationGroups, setNotificationGroups] = useState(null);

    const [clubsTable, setClubsTable] = useState(null);

    const [currentClub, setCurrentClub] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading union clubs...", handleRetry)

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditClub() {
        if (!context) return false;

        return context.isAllowed(actions.club.club.edit);
    }

    function canInviteUsers() {
        if (!context) return false;

        return context.isAllowed(actions.club.club.invite);
    }

    function canMoveClubToUnion() {
        return true;
    }

    function openClubEditor(club) {
        if (!canEditClub()) return;

        if (club) {
            setCurrentClub(club);
            openClubEditorDialog();
        }
    }

    function inviteClubUsers(club) {
        if (!canInviteUsers()) return;

        setCurrentClub(club);
        openInviteUserDialog();
    }

    const [openClubEditorDialog, openClubEditorDialogProps] = useDialog();
    const [openInviteUserDialog, openInviteUserDialogProps] = useDialog();

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const notifyRequest = useFetchWithMsal({
        scopes: apiConfig.notification.scopes.read,
    });

    const dataLoader = useUnionDataLoader();
    const notifyDataLoader = useNotificationDataLoader();

    useEffect(() => {
        if (!clubs) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading union clubs...")
            showProgress();

            //(request, cardroom, union, enableCache, onsuccess, onerror)
            dataLoader.entity.loadClubs(clubRequest, cardroom, union, true, (uclubs) => {
                if (uclubs) {
                    setClubs(uclubs);
                    setClubsTable(null);
                }
            }, (ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load union clubs, please try again...");
            });
        }
    }, [clubRequest.execute, clubs, retryCounter]) //cardroomListData 


    useEffect(() => {
        if (clubs && !unions) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading unions...")
            showProgress();

            //(request, cardroom, union, enableCache, onsuccess, onerror)
            dataLoader.entity.loadUnions(clubRequest, cardroom, union, true, (sunions) => {
                if (sunions) {
                    setUnions(sunions);
                }
            }, (ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load unions, please try again...")
            });
        }
    }, [clubRequest.execute, clubs, unions, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (clubs && unions && !notificationGroups) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading notification groups...")
            showProgress();

            //(request, cardroom, union, enableCache, onsuccess, onerror)
            notifyDataLoader.entity.loadNotificationGroups(notifyRequest, cardroom, true, (groups) => {
                if (groups) {
                    setNotificationGroups(groups);
                }
            }, (ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load notification groups, please try again...")
            });
        }
    }, [notifyRequest.execute, clubs, unions, notificationGroups, retryCounter]) //cardroomListData 




    useEffect(() => {
        if (clubs && unions && notificationGroups && !clubsTable) {
            setClubsTable(prepareClubsForPresentation(clubs, unions, notificationGroups, GetEmptySchema(canEditClub() ? openClubEditor : null, canInviteUsers() ? inviteClubUsers : null)));
            progressIndicatorProps.close();
        }
    }, [clubs, unions, notificationGroups, clubsTable]);


    function renderMobileRow(columns, row, key, collapsibleDetailAccessor) {

        return <UnionClubMobileCard cardroom={cardroom} union={union} row={row} club={row.original.club} parentUnion={row.original.union} unions={unions}
            canInviteUsers={canInviteUsers} canEditClub={canEditClub} canMoveClubToUnion={canMoveClubToUnion}
            onOpenClubEditor={openClubEditor} onInviteClubUsers={inviteClubUsers} />;
    }


    return <Card>
        {canEditClub() ?
            <ClubEditorDialog cardroom={cardroom} union={union} club={currentClub} clubs={clubs} {...openClubEditorDialogProps} /> : null}
        {canInviteUsers() ?
            <InvitationEditorDialog {...openInviteUserDialogProps} cardroom={cardroom} union={union} club={currentClub} /> : null}

        {(cardroom && clubs) && !progressIndicatorProps.visible ? <>
            {clubsTable ?
                <DataTable table={clubsTable} canSearch entriesPerPage={{ defaultValue: 10, visible: !isMobileUi }} isHierarchical={true}
                    pagination={{ color: "dark" }} onRenderRow={renderMobileRow} useCustomRenderSize={switchToMobileOnSize} />
                : null}
        </> : <ProgressIndicator {...progressIndicatorProps} />}
    </Card>;
};

export default UnionClubs