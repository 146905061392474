import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';


import { Formik, Form } from 'formik';
import FormField from 'components/Elements/Forms/FormField';


import { Grid } from '@mui/material';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import { useCardroomContext } from 'features';
import { useNotificationDataLoader } from '..';



const NotificationGroupEditorForm = ({ cardroom, notificationGroup, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { groupId, casinoId, groupName } = formField;
    const { groupId: groupIdV,
        casinoId: casinoIdV,
        groupName: groupNameV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);
    const [currentGroupId, setCurrentGroupId] = useState(notificationGroup ? notificationGroup.id : null);

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditNotificationGroup() {
        if (!context) return false;

        return true;
    }


    useEffect(() => {
        if (!formDataInitialized) {

            setFieldValue(groupId.name, notificationGroup ? notificationGroup.id : "");

            setFieldValue(groupName.name, notificationGroup ? notificationGroup.name : "");

            setFieldValue(casinoId.name, cardroom ? cardroom.id : "");

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    useEffect(() => {
        if (currentGroupId)
            setFormDataInitialized(false);
    }, [currentGroupId]);


    useEffect(() => {
        if (notificationGroup && currentGroupId != notificationGroup.id)
            setCurrentGroupId(notificationGroup.id ? notificationGroup.id : "new");
    }, [notificationGroup]);

    return formDataInitialized ? <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {groupName.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8} >
                                <FormField onFocus={e => e.currentTarget.select()}

                                    type={groupName.type}
                                    name={groupName.name}
                                    value={groupNameV}
                                    placeholder={groupName.placeholder}
                                    error={errors.groupName && touched.groupName}
                                    success={groupNameV && groupNameV.length > 0 && !errors.groupName}
                                />
                            </Grid>
                        </Grid>

                    </SoftBox>
                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox > : null;
};

NotificationGroupEditorForm.propTypes = {
    cardoom: PropTypes.object.isRequired,
    group: PropTypes.object.isRequired
};

const NotificationGroupEditor = ({ cardroom, notificationGroup, onGroupUpdated }) => {

    const { formId, formField } = form;
    const currentValidation = validations.editor;


    const [retryCounter, setRetryCounter] = useState(0);
    const [groupRecord, setGroupRecord] = useState(null);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving notification group...", handleRetry)

    const notifyRequest = useFetchWithMsal({
        scopes: apiConfig.notification.scopes.write,
    });

    const notificationDataLoader = useNotificationDataLoader();

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditNotificationGroup() {
        if (!context) return false;

        return true;
    }

    const handleSubmit = (values, actions) => {

        if (false === canEditNotificationGroup()) return;
        var group = {
            id: notificationGroup && notificationGroup.id ? notificationGroup.id : "00000000-0000-0000-0000-000000000000",
            name: values.groupName
        };

        setGroupRecord(group);

    };


    useEffect(() => {
        if (groupRecord) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving notification group...")
            showProgress();

            notificationDataLoader.entity.saveNotificationGroup(notifyRequest, cardroom, groupRecord, (group) => {
                if (group) {
                    if (group.status && group.errors) {
                        throw new Error(group.errors);
                    }

                    notificationGroup = Object.assign(notificationGroup, group);
                    raiseOnChanged(notificationGroup);
                }

                setGroupRecord(null);
                progressIndicatorProps.close();
                raiseOnClose();

            }, (err) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to retrieve notification groups, please try again...")
            });
        }

    }, [groupRecord, notifyRequest.execute, retryCounter]);


    function raiseOnClose() {
        //  if (onClose) {
        //      onClose();
        //  }
    }

    function raiseOnChanged(g) {
        if (onGroupUpdated) {
            onGroupUpdated(g);
        }
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2}>
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible && notificationGroup ? <></> : <>
                            <NotificationGroupEditorForm cardroom={cardroom} notificationGroup={notificationGroup} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting || !canEditNotificationGroup()}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};

NotificationGroupEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    group: PropTypes.object.isRequired,
    onGroupUpdated: PropTypes.func
};

export default NotificationGroupEditor;