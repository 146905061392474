
const form = {
    formId: "agent-notification-group-form",
    formField: {
        groupId: {
            name: "groupId",
            label: "Notification Group",
            type: "hidden",
            errorMsg: "notification group be selected"
        },
        casinoId: {
            name: "casinoId",
            label: "Cardroom",
            type: "hidden",
            placeholder: "cardroom",
            errorMsg: "cardroom is required",
        },
        groupName: {
            name: "groupName",
            label: "Group Name",
            type: "string",
            placeholder: "group name",
            errorMsg: "group name is required",
        }
    },

};

export default form;
