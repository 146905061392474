import React from 'react';
import PropTypes from "prop-types";


import { apiConfig } from "config/apiConfig";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { useState } from 'react';
import { useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';
import { Form, Formik } from 'formik';
import { ProgressIndicator } from 'components/Elements/ProgressIndicator';
import { Grid } from '@mui/material';
import FormField from 'components/Elements/Forms/FormField';
import SoftAvatar from 'components/SoftAvatar';
import { useEffect } from 'react';
import SoftButton from 'components/SoftButton';
import { SetMealRounded } from '@mui/icons-material';
import Swal from 'sweetalert2';

const InvitationRedemptionForm = ({ cardroom, dense, onRedeemed, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { code, key } = formField;
    const {
        code: codeV,
        key: keyV
    } = values;

    const [invitation, setInvitation] = useState(null);
    const [loadInvitation, setLoadInvitation] = useState(null);
    const [redeemInvitation, setRedeemInvitation] = useState(null);

    const [formDataInitialized, setFormDataInitialized] = useState(false);

    const [retryCounter, setRetryCounter] = useState(0);
    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "looking for invitation...", handleRetry)

    useEffect(() => {
        if (!formDataInitialized) {
            resetForm();

            setInvitation(null);
            setLoadInvitation(null);
            setRedeemInvitation(null);

            setFieldValue(code.name, "");
            setFieldValue(key.name, "");

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);


    const casinoRead = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const casinoWrite = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.write,
    });

    function onLookupInvitation() {
        validateForm();

        if (errors.code || errors.key) return;
        setLoadInvitation({
            code: values.code,
            key: values.key
        });
    }

    function getInvitationRoles(inv) {
        if (!inv) return "";

        let roles = "";

        if (inv.role === 0) return "None";

        if ((inv.role & 1) > 0) {
            roles += " | Owner";
        }

        if ((inv.role & 2) > 0) {
            roles += " | Admin";
        }

        if ((inv.role & 4) > 0) {
            roles += " | Chip Runner";
        }

        if ((inv.role & 8) > 0) {
            roles += " | Cage Manager";
        }

        if ((inv.role & 16) > 0) {
            roles += " | Reader";
        }

        if ((inv.role & 32) > 0) {
            roles += " | Partner";
        }

        if ((inv.role & 64) > 0) {
            roles += " | Agent";
        }

        if ((inv.role & 1024) > 0) {
            roles += " | Participant";
        }

        return roles + " |";
    }

    function getInvitationStatus(inv) {
        if (!inv) return null;

        if (inv.status === 1) return "Active";
        if (inv.status === 2) return "Redeemed";
        if (inv.status === 3) return "Revoked";
        if (inv.status === 4) return "Expired";
        if (inv.status === 5) return "Failed";
        if (inv.status === 6) return "Not Found";
        if (inv.status === 7) return "Awaiting Acceptance";
        if (inv.status === 8) return "Denied";

        return "UNKNOWN";
    }

    function getInvitationStatusColor(inv) {
        if (!inv) return "text";

        if (inv.status === 1) return "success";
        if (inv.status === 2) return "warning";
        if (inv.status === 3) return "warning";
        if (inv.status === 4) return "error";
        if (inv.status === 5) return "error";
        if (inv.status === 6) return "error";
        if (inv.status === 7) return "success";
        if (inv.status === 8) return "error";

        return "error";
    }

    useEffect(() => {
        if (loadInvitation) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("looking for invitation...")
            showProgress();
            //[HttpGet("invitation/{key}/{code}", Name = " RedeemInvitation")]
            casinoRead.execute("GET", apiConfig.casino.endpoint + "/invitation/" + loadInvitation.key + "/" + loadInvitation.code).then((response) => {
                if (response) {
                    setInvitation(response);
                }

                setLoadInvitation(null);
                progressIndicatorProps.close();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to locate your invitation, please try again...")
            });
        }

    }, [casinoRead.execute, loadInvitation, retryCounter]);

    function onAcceptInvitation() {
        if (!canAccept()) return;

        setRedeemInvitation({
            code: values.code,
            key: values.key,
            id: invitation.id
        });
    }
    function onCancelInvitation() {
        setFormDataInitialized(false);
    }

    function onInvitationRedeemed(result) {

        let header = "Your invitation was redeemed";
        let body = "Your invitation has been processed and your user account has been added to '" + ((invitation && invitation.casino) ? invitation.casino.name : "N/A") + "'. Please note, that it may take up to a couple of minutes for your new permissions to take effect.";
        let color = "success";

        switch (result) {
            case 2: //redeemed
                break;
            case 3:
                header = "Your invitation was not processed.";
                body = "Your invitation could not be processed as it has been revoked.";
                color = "warning";
                break;
            case 4:
                header = "Your invitation was not processed.";
                body = "Your invitation could not be processed as it has expired.";
                color = "warning";
                break;
            case 5:
                header = "Your invitation was not processed.";
                body = "Your invitation could not be processed as the operation failed.";
                color = "error";
                break;
            case 6:
                header = "Your invitation was not found.";
                body = "Your invitation could not be processed as we could not locate it.";
                color = "error";
                break;
            case 8:
                header = "Your invitation was denied.";
                body = "Your invitation could not be processed as it was denied by the system.";
                color = "error";
                break;
            default:
                //case 1 or 7 -- should not be active or dispatched...
                header = "Your invitation may not have been processed.";
                body = "The system returned an unexpected result (" + result + ").";
                color = "warning";
                break;
        }


        Swal.fire(
            header,
            body,
            color
        );

        setFormDataInitialized(false);

        if (onRedeemed) {
            onRedeemed(result);
        }
    }

    function canAccept() {
        if (!codeV) return false;
        if (!keyV) return false;
        if (!invitation) return false;

        return (invitation.status === 1 || invitation.status === 7);
    }

    useEffect(() => {
        if (redeemInvitation) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("redeeming invitation...")
            showProgress();
            // [HttpPost("invitation/{key}/{code}", Name = " RedeemInvitation")]
            casinoWrite.execute("POST", apiConfig.casino.endpoint + "/invitation/" + redeemInvitation.key + "/" + redeemInvitation.code).then((response) => {
                if (response) {
                    onInvitationRedeemed(response);
                }
                setRedeemInvitation(null);

                setLoadInvitation(null);
                progressIndicatorProps.close();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to redeem invitation, please try again...")
            });
        }

    }, [casinoWrite.execute, redeemInvitation, retryCounter]);

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                {dense === true ? null :
                    <Grid item xs={12} sm={4} container justifyContent="center">
                        <SoftBox position="relative" height="max-content" mx="auto">
                            <SoftAvatar src={null} size="xxl" variant="rounded" bgColor="transparent" />
                        </SoftBox>
                    </Grid>}
                <Grid item xs={12} sm={dense === true ? 12 : 8}>
                    <SoftBox mb={2} textAlign="left">

                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {code.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField disabled={invitation != null}
                                        type={code.type}
                                        name={code.name}
                                        value={codeV}
                                        placeholder={code.placeholder}
                                        error={errors.code && touched.code}
                                        success={codeV && codeV.length > 0 && !errors.code}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {key.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField disabled={invitation != null}
                                        type={key.type}
                                        name={key.name}
                                        value={keyV}
                                        placeholder={key.placeholder}
                                        error={errors.key && touched.key}
                                        success={keyV && keyV.length > 0 && !errors.key}
                                    />
                                </Grid>
                            </Grid>

                            {!invitation ?
                                <Grid container xs={12}>
                                    <Grid item xs={12} textAlign={"right"}>
                                        <SoftBox mt={3} width="100%" display="block">
                                            <SoftButton
                                                disabled={!(codeV && keyV)}
                                                variant="gradient"
                                                color="dark"
                                                onClick={onLookupInvitation} >
                                                Lookup
                                            </SoftButton>
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                                : <>
                                    <Grid container xs={12}>
                                        <Grid item xs={4} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize">
                                                    Cardroom
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <SoftTypography
                                                variant="caption"
                                                textTransform="capitalize">
                                                {invitation.casino ? invitation.casino.name : "N/A"}
                                            </SoftTypography>
                                        </Grid>
                                    </Grid>

                                    <Grid container xs={12}>
                                        <Grid item xs={4} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize">
                                                    Status
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <SoftTypography
                                                variant="caption"
                                                textTransform="capitalize"
                                                color={getInvitationStatusColor(invitation)}>
                                                {getInvitationStatus(invitation)}
                                            </SoftTypography>
                                        </Grid>
                                    </Grid>

                                    <Grid container xs={12}>
                                        <Grid item xs={4} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize">
                                                    Roles
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <SoftTypography
                                                variant="caption"
                                                textTransform="capitalize">
                                                {getInvitationRoles(invitation)}
                                            </SoftTypography>
                                        </Grid>
                                    </Grid>

                                    <Grid container xs={12}>
                                        <Grid item xs={12} textAlign={"right"}>
                                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                                                <SoftButton
                                                    disabled={!(codeV && keyV)}
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={onCancelInvitation} >
                                                    Cancel
                                                </SoftButton>

                                                <SoftButton
                                                    disabled={!canAccept()}
                                                    variant="outlined"
                                                    color="success"
                                                    onClick={onAcceptInvitation} >
                                                    Accept
                                                </SoftButton>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </>}
                        </>}
                    </SoftBox>
                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox >;
};

const InvitationRedemption = ({ cardroom, open, close, onRedeemed }) => {
    const { formId, formField } = form;
    const currentValidation = validations.editor;

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "loading invitation...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const handleSubmit = (values, actions) => {

    };

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <InvitationRedemptionForm dense={true} onRedeemed={onRedeemed} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />
                        </>
                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;

}

InvitationRedemption.defaultProps = {

};

InvitationRedemption.propTypes = {
    cardroom: PropTypes.object,
    onRedeemed: PropTypes.func
};

export default InvitationRedemption;
