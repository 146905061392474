import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { Card, FormControlLabel, Grid, Icon, ListItemIcon, ListItemText, Radio, RadioGroup, Tooltip, tooltipClasses } from '@mui/material';
import { Formik, Form, ErrorMessage } from 'formik';
import FormField from 'components/Elements/Forms/FormField';
import SoftAvatar from 'components/SoftAvatar';
import FormSwitch from 'components/Elements/Forms/FormSwitch';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import { useCardroomContext } from 'features';


import emailImage from "assets/graphics/icons/email.png";
import smsImage from "assets/graphics/icons/sms.png";
import telegramImage from "assets/graphics/icons/telegram.png";
import InvitationSelector from '../Selector';
import Swal from 'sweetalert2';


const InvitationEditorForm = ({ cardroom, union, club, agency, invitation, formData, dense, readonly }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { invitationId, sendTo, protocol, role } = formField;
    const {
        invitationId: invitationIdV,
        sendTo: sendToV,
        protocol: protocolV,
        role: roleV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);
    const [effectiveRoles, setEffectiveRoles] = useState(null);
    const [invitationStatus, setInvitationStatus] = useState("Checking...");
    const [casinoInvite, setCasinoInvite] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditInvitation() {
        if (!context) return false;

        if (invitation && invitation.id && readonly) return false;

        return context.isAllowed(actions.invitations.edit);
    }

    function getProtocolSpecificAddress(p) {
        switch (p) {
            case 1:
                return "SMS Phone Number";
            case 2:
                return "Email Address";
            case 3:
                return "Handle";
            case 4:
                return "Handle";
        }

        return sendTo.label;
    }

    function getProtocolIcon(p) {
        /*import emailImage from "assets/graphics/icons/email.png";
        import smsImage from "assets/graphics/icons/sms.png";
        import telegramImage from "assets/graphics/icons/telegram.png"; */
        switch (p) {
            case 1:
                return smsImage;
            case 2:
                return emailImage;
            case 3:
                return telegramImage;
            case 4:
                return null;
        }

        return null;
    }

    function onProtocolChanged(e, newValue) {
        setFieldValue(protocol.name, parseInt(newValue));
    }
    function getInvitationStatus() {
        if (!invitation) return null;

        if (invitation.referencedInvitationId === '00000000-0000-0000-0000-000000000000') return "Processing";

        return invitationStatus;
    }

    function getInvitedUnionName() {
        if (!union) return "";

        if (invitation && invitation.id && invitation.unionId) {
            if (invitation.unionId !== union.id) return "ERROR";
        }

        return union.name;
    }

    function getInvitedClubName() {
        if (!club) return "";

        if (invitation && invitation.id && invitation.clubId) {
            if (invitation.clubId !== club.id) return "ERROR";
        }

        return club.name;
    }

    function getInvitedAgencyName() {
        if (!agency) return "";

        if (invitation && invitation.id && invitation.agencyId) {
            if (invitation.agencyId !== agency.id) return "ERROR";
        }

        return agency.name;
    }

    function getInvitationStatusName(inv) {
        if (!inv) return "Unknown";

        function formatDate(d) {
            let dt = new Date(Date.parse(d + "Z"));
            return dt.toLocaleDateString();
        }

        switch (inv.status) {
            case 1: return "Active (Expires:" + formatDate(inv.expiresOnUtc) + ")";
            case 2: return "Redeemed";
            case 3: return "Revoked";
            case 4: return "Expired";
            case 5: return "Failed";
            case 6: return "Not found";
            case 7: return "Awaiting Acceptance  (Expires:" + formatDate(inv.expiresOnUtc) + ")";
            case 8: return "Denied";
        }
        return "?";
    }

    useEffect(() => {
        if (!formDataInitialized) {
            resetForm();

            setCasinoInvite(null);
            setInvitationStatus("Checking...");

            setFieldValue(invitationId.name, invitation && invitation.id ? invitation.id : "");
            setFieldValue(sendTo.name, invitation && invitation.id ? invitation.sendToAddress : "");
            setFieldValue(protocol.name, invitation && invitation.id ? invitation.protocol : 1);
            setFieldValue(role.name, invitation && invitation.id ? invitation.role : 3);


            const roles = [];
            roles.push({ name: "Owner", mask: 1 });
            roles.push({ name: "Admin", mask: 2 });
            roles.push({ name: "Reader", mask: 16 });
            //roles.push({ name: "Agent", mask: 64 });

            setEffectiveRoles(roles);

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    useEffect(() => {
        if (invitation && formDataInitialized) {
            setFormDataInitialized(false);
        }
    }, [invitation])


    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    useEffect(() => {
        if (!casinoInvite && invitation && invitation.referencedInvitationId && invitation.referencedInvitationId !== '00000000-0000-0000-0000-000000000000') {
            //call casino service to get invitation status...

            //[HttpGet("{casinoId:Guid}/invitation/{invitationId:Guid}", Name = "GetInvitationById")]
            execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/invitation/" + invitation.referencedInvitationId).then((response) => {
                if (response) {
                    setCasinoInvite(response);
                    setInvitationStatus(getInvitationStatusName(response));
                }


            }).catch((e) => {
                setInvitationStatus("Failed to get status");
            });

        }
    }, [execute, invitation, casinoInvite]);



    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                {dense === true ? null :
                    <Grid item xs={12} sm={4} container justifyContent="center">
                        <SoftBox position="relative" height="max-content" mx="auto">
                            <SoftAvatar src={getProtocolIcon(protocolV)} size="xxl" variant="rounded" bgColor="transparent" />
                        </SoftBox>
                    </Grid>}
                <Grid item xs={12} sm={dense === true ? 12 : 8}>
                    <SoftBox mb={2} textAlign="left">

                        {invitation && invitation.id ?
                            <Grid container xs={12} mb={.5}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            Status
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography
                                        variant="caption"
                                        textTransform="capitalize">
                                        {getInvitationStatus()}
                                    </SoftTypography>
                                </Grid>
                            </Grid>
                            : null}

                        {!union && !club ?
                            <Grid container xs={12} mb={.5}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            Cardroom
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography
                                        variant="caption"
                                        textTransform="capitalize">
                                        {cardroom.name}
                                    </SoftTypography>
                                </Grid>
                            </Grid> : null}


                        {union ?
                            <Grid container xs={12} mb={.5}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            Union
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography
                                        variant="caption"
                                        textTransform="capitalize">
                                        {getInvitedUnionName()}
                                    </SoftTypography>
                                </Grid>
                            </Grid> : null}

                        {club ?
                            <Grid container xs={12} mb={.5}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            Club
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography
                                        variant="caption"
                                        textTransform="capitalize">
                                        {getInvitedClubName()}
                                    </SoftTypography>
                                </Grid>
                            </Grid> : null}

                        {agency ?
                            <Grid container xs={12} mb={.5}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            Agency
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography
                                        variant="caption"
                                        textTransform="capitalize">
                                        {getInvitedAgencyName()}
                                    </SoftTypography>
                                </Grid>
                            </Grid> : null}

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {protocol.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography
                                    variant="caption"
                                    textTransform="capitalize">
                                    <RadioGroup row={true} defaultValue={protocolV} value={protocolV} onChange={onProtocolChanged} style={{ marginLeft: "1.25em", marginTop: "3px" }}>
                                        <FormControlLabel value="1" control={<Radio disabled={!canEditInvitation()} />} label="SMS" />
                                        <FormControlLabel value="2" control={<Radio disabled={!canEditInvitation()} />} label="Email" />
                                        {/*
                                        <FormControlLabel value="3" control={<Radio />} label="Telegram" />
                                        <FormControlLabel value="4" control={<Radio />} label="Signal" />
                */}
                                    </RadioGroup>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={protocol.name} />
                                    </SoftTypography>
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        <Grid container xs={12} mt={-2}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {getProtocolSpecificAddress(protocolV)}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditInvitation()}
                                    type={sendTo.type}
                                    name={sendTo.name}
                                    value={sendToV}
                                    placeholder={getProtocolSpecificAddress(protocolV)}
                                    error={errors.sendTo && touched.sendTo}
                                    success={sendToV && sendToV.length > 0 && !errors.sendTo}
                                />
                            </Grid>
                        </Grid>


                        <Grid container xs={12} mt={-1.5}>
                            <Grid item xs={12} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        Roles
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>

                            <Grid container xs={12}>

                                {effectiveRoles ? effectiveRoles.map((item) => {
                                    return <Grid item xs={6} md={6} lg={4}>
                                        <SoftBox ml={0.5} mb={0.25}>
                                            <FormSwitch label={item.name} mask={item.mask} value={roleV} name={role.name} variant="bit" readOnly={!canEditInvitation()} {...formData} />
                                        </SoftBox>
                                    </Grid>;
                                }) : null}

                                <Grid item xs={12}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={role.name} />
                                    </SoftTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SoftBox>
                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox >;
};

InvitationEditorForm.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object.isRequired,
    club: PropTypes.object,
    invitation: PropTypes.object,
    dense: PropTypes.bool
};

const InvitationEditor = ({ cardroom, union, club, agency, invitation, onClose, dense, readonly }) => {

    const { formId, formField } = form;
    const currentValidation = validations.editor;

    const [retryCounter, setRetryCounter] = useState(0);
    const [invitationRecord, setInvitationRecord] = useState(null);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving invitation...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: (union || agency) ? apiConfig.club.scopes.write : apiConfig.casino.scopes.write,
    });

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditInvitation() {
        if (!context) return false;

        //(invitation && invitation.id) && readonly === true)

        if (readonly && invitation && invitation.id) return false;

        return context.isAllowed(actions.invitations.edit);
    }


    const handleSubmit = (values, actions) => {

        if (false === canEditInvitation()) return;

        function generateRandomNumber(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
        }


        //construct participant object
        const ns = Object.assign({}, invitation);

        //update schedule...
        if (!ns.id) ns.id = "00000000-0000-0000-0000-000000000000";

        ns.sendToAddress = values.sendTo;
        ns.protocol = values.protocol;
        ns.role = values.role;

        if (!union && !agency) {
            ns.casino = cardroom;
            //ns.casinoId = cardroom.id;
            ns.code = generateRandomNumber(1000, 9999).toString(); // generate random value?
        }

        setInvitationRecord(ns);
    };


    useEffect(() => {
        if (invitationRecord) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Sending invitation...")
            showProgress();

            let url = "";

            if (union || agency) {

                if (union && club) {
                    //[HttpPost("{unionId:Guid}/casino/{casinoId:Guid}/clubs/{clubId:Guid}/invitation", Name = "InviteClubManagementUser")]
                    url = "/union/" + union.id + "/casino/" + cardroom.id + "/clubs/" + club.id + "/invitation"
                }
                else if (club && agency) {
                    //[HttpPost("{clubId:Guid}/casino/{casinoId:Guid}/agencies/{agencyId:Guid}/invitation", Name = "InviteAgencyManagementUser")]
                    url = "/club/" + club.id + "/casino/" + cardroom.id + "/agencies/" + agency.id + "/invitation"
                }
                else {
                    //[HttpPost("{unionId:Guid}/casino/{casinoId:Guid}/invitation", Name = "InviteUnionManagementUser")]
                    url = "/union/" + union.id + "/casino/" + cardroom.id + "/invitation"
                }

                url = apiConfig.club.endpoint + url;
            }
            else {
                //[HttpPost("{casinoId:Guid}/invitation", Name = "InviteCasinoUser")]
                url = "/" + cardroom.id + "/invitation";
                url = apiConfig.casino.endpoint + url;
            }


            execute("POST", url, invitationRecord).then((response) => {
                if (response) {
                    if (response.status && response.errors) {
                        throw new Error(response.errors);
                    }

                    //raiseOnTransactionCreated();
                    if (union) {
                        if (invitation) invitation = Object.assign(invitation, response);
                        else invitation = response;
                    }
                    else {
                        //response is code     

                        Swal.fire(
                            'Invitation saved...',
                            'Your invitation was saved and will be processed shortly.',
                            'success'
                        );
                    }
                }

                setInvitationRecord(null);
                progressIndicatorProps.close();
                raiseOnClose(false);

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to send invitation, please try again...")
            });
        }

    }, [invitationRecord, execute, retryCounter]);


    function onCanceled() {
        raiseOnClose(true);
    }

    function raiseOnClose(canceled) {
        if (onClose) {
            onClose(canceled);
        }
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <InvitationEditorForm cardroom={cardroom} union={union} club={club} agency={agency} invitation={invitation} dense={dense} readonly={readonly} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />

                            {canEditInvitation() ?
                                <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                    <SoftButton variant="gradient" color="light" onClick={onCanceled}>
                                        cancel
                                    </SoftButton>

                                    <SoftButton
                                        variant="gradient"
                                        color="dark"
                                        disabled={isSubmitting || !canEditInvitation()}
                                        type="submit"
                                    >
                                        send
                                    </SoftButton>
                                </SoftBox> : null}
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};

InvitationEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object,
    club: PropTypes.object,
    invitation: PropTypes.object,
    dense: PropTypes.bool
};

const InvitationsEditor = ({ cardroom, union, club, onClose, dense }) => {


    const [context, actions, features] = useCardroomContext(cardroom);

    function canInviteUsers() {
        if (!context) return false;

        return context.isAllowed(actions.invitations.edit);
    }

    const inviteUserPlaceHolder = canInviteUsers() ? { id: null, sendToAddress: "Invite User" } : null;

    const [currentInvitation, setCurrentInvitation] = useState(null);
    const [reloadInvitationsNeeded, setReloadInvitationsNeeded] = useState(false);

    function onInvitationChanged(item) {
        if (item) {
            setReloadInvitationsNeeded(false);
        }

        setCurrentInvitation(item);

    }

    function onInvitationEditDone(canceled) {
        setCurrentInvitation(null);
        setReloadInvitationsNeeded(true);
    }

    function buildListItem(item) {
        if (item.id) {

            return <ListItemText><SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize">
                {item.sendToAddress}
            </SoftTypography></ListItemText>;
        }
        else {
            return <>
                <ListItemIcon style={{ minWidth: "32px" }}>
                    <Icon>add</Icon>
                </ListItemIcon>
                <ListItemText><SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    {item.sendToAddress}
                </SoftTypography></ListItemText></>;
        }
    }


    return <Grid width="100%" xs={12}>
        <Grid container xs={12}>
            <Grid item xs={4} p={2}>
                <InvitationSelector cardroom={cardroom} union={union} club={club} emptyValue={inviteUserPlaceHolder}
                    onSelectionChanged={onInvitationChanged} onBuildListItem={buildListItem} type="list" reload={reloadInvitationsNeeded} maxHeight="375px" />
            </Grid>
            <Grid item xs={8}> <Card style={{ overflow: "auto" }}>
                {currentInvitation ?
                    <InvitationEditor cardroom={cardroom} union={union} club={club} invitation={currentInvitation} dense={true} readonly={true} onClose={onInvitationEditDone} />
                    :
                    <Card sx={{ height: 375 }}>
                        <Grid container xs={12} justifyContent="center" alignItems="center">
                            <Grid item xs={12} textAlign={"center"}>

                                <SoftTypography
                                    component="h5"
                                    variant="caption"
                                    color="info">
                                    Select invitation from the list or tap "invite user" to send a new invitation
                                </SoftTypography>

                            </Grid>
                        </Grid>
                    </Card>
                }</Card>
            </Grid>
        </Grid>
    </Grid>;

};
InvitationsEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object,
    club: PropTypes.object,
    dense: PropTypes.bool
};

export {
    InvitationsEditor,
    InvitationEditor
};