import { Card, DialogActions, DialogContent, DialogTitle, Grid, useMediaQuery, useTheme } from "@mui/material";
import { BootstrapDialog } from "components/Elements/Dialog/common";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { BookAgentPropertiesEditor } from "../Properties";

import AdaptiveTab from "components/Elements/AdaptiveTab";
import BookAgentPlayerPoolEditor from "../PlayerPool";
import { useEffect, useState } from "react";


const BookAgentEditorDialog = ({ open, close, cardroom, book, agent, onAgentChanged }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    function onAgentUpdated(ag) {
        //setLocalAgent(ag);
        if (ag) {
            agent = Object.assign(agent, ag);
            setCanEditPool(Boolean(agent && agent.id && agent.id !== "none"));

        }

        if (onAgentChanged) onAgentChanged(ag);
    }

    const [canEditPool, setCanEditPool] = useState(null);

    useEffect(() => {
        setCanEditPool(Boolean(agent && agent.id && agent.id !== "none"));
    }, [agent]);

    const tabs = [];

    if (agent) {
        tabs.push({
            title: { text: "Properties", icon: "contact_phone" },
            header: null,
            body: <BookAgentPropertiesEditor cardroom={cardroom} book={book} agent={agent} onAgentUpdated={onAgentUpdated} />
        });


        tabs.push({
            title: { text: "Packages", icon: "groups" },
            header: null,
            disabled: !canEditPool,
            body: <BookAgentPlayerPoolEditor cardroom={cardroom} book={book} agent={agent} />
        });
    }

    // useEffect(() => {
    //     if (agent) setLocalAgent(agent);
    // }, [agent]);

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xxl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Agent Properties: {agent ? agent.name : "New Agent"}
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <Card style={{ minHeight: 200 }} mt={3}>
                <Grid container xs={12}>
                    <Grid item xs={12}>
                        <SoftBox pb={0} px={2} textAlign="center" lineHeight={1.25}>
                            <AdaptiveTab switchToMobileOnSize="lg" tabs={tabs} />
                        </SoftBox>
                    </Grid>
                </Grid>
            </Card>
        </DialogContent>
        <DialogActions>
            <SoftButton onClick={close} variant="outlined" color="secondary">
                close
            </SoftButton>
        </DialogActions>
    </BootstrapDialog >
};



export default BookAgentEditorDialog;