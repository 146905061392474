
import React, { useEffect, useState } from 'react';
import SoftBox from 'components/SoftBox';
import { Icon, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { useCardroomContext } from 'features';
import { SupportedCurrencyList } from './currency';



const CurrencyConverterWidget = ({ cardroom, selectedCurrency, onCurrencyChanged }) => {

    const [context, actions, features] = useCardroomContext(cardroom);

    const [currencyList, setCurrencyList] = useState([]);

    const knownCurrencies = SupportedCurrencyList;

    //const supportedCurrencies = ["", ""]; //buildCurrencyList(cardroom);    
    /*
        function buildCurrencyList(room) {
    
            const list = [];
    
            for (var prop in knownCurrencies) {
                // if (typeof model[property] == 'function') 
                if (knownCurrencies.hasOwnProperty(prop) && (typeof knownCurrencies[prop] !== 'function')) {
                    if ((room.currency & knownCurrencies[prop].mask) > 0) {
                        list.push(knownCurrencies[prop]);
                    }
                }
            }
    
            return list;
        }
    */
    function onNewCurrencySelected(currency) {
        if (onCurrencyChanged) onCurrencyChanged(currency);

        setCurrencyList([]);
    }

    useEffect(() => {
        if (cardroom && knownCurrencies && (!currencyList || currencyList.length == 0)) {

            const list = [];

            for (var prop in knownCurrencies) {
                // if (typeof model[property] == 'function') 
                if (knownCurrencies.hasOwnProperty(prop) && (typeof knownCurrencies[prop] !== 'function')) {

                    if ((cardroom.currencies & knownCurrencies[prop].mask) > 0) {

                        if (!selectedCurrency || selectedCurrency.name != knownCurrencies[prop].name) {
                            list.push(knownCurrencies[prop]);
                        }
                        //list.push(knownCurrencies[prop]);
                    }
                }
            }
            // alert('foo');

            setCurrencyList(list);

        }
    }, [selectedCurrency, cardroom, knownCurrencies])


    function isCurrencyConversionEnabled() {
        if (!context) return false;

        return context.isEnabled(features.currency.conversion);
    }


    return isCurrencyConversionEnabled() && currencyList.length > 0 ? <SoftBox sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
        <SpeedDial bgColor="dark"
            ariaLabel="currency converter" sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon icon={<Icon fontSize='medium'>currency_exchange</Icon>} />}>

            {currencyList.map((c) => {
                return <SpeedDialAction
                    onClick={() => onNewCurrencySelected(c)}
                    key={"open.settings." + c.name}
                    icon={c.icon ? <Icon>{c.icon}</Icon> : c.symbol}
                    tooltipTitle={"switch to " + c.name}
                />
            })}

        </SpeedDial>
    </SoftBox> : null;
}


export default CurrencyConverterWidget;