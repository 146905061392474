
import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';
import DataTable from 'components/Elements/DataTable';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import { Card, CardActions, CardContent, Grid, Icon, useMediaQuery, useTheme } from '@mui/material';

import whiteCurved from "assets/images/curved-images/white-curved.jpeg";
import SoftBox from 'components/SoftBox';
import MiniStatisticsCard from 'components/Elements/MiniStatisticsCard';
import { useDialog } from 'components/Elements/Dialog/common';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import SoftButton from 'components/SoftButton';
import { AgencyPlayerEditorDialog } from 'layouts/Club/components/Player';


const AgencyPlayers = ({ cardroom, agency }) => {

    function preparePlayersForPresentation(players, table) {
        let rows = [];


        const playerMap = [];
        const playerList = [];
        for (var i = 0; i < players.length; i++) {
            if (!playerMap[players[i].id]) {
                playerMap[players[i].id] = players[i];
                playerList.push(players[i]);

                players[i].clubs = [];
            }

            playerMap[players[i].id].clubs.push(players[i].club);
        }



        for (var i = 0; i < playerList.length; i++) {
            let playerImage = playerList[i].name.substring(0, 1);

            rows.push({
                name: [playerList[i].name, { image: playerImage }],
                displayName: playerList[i].name,
                displayNameNoCase: playerList[i].name.toLowerCase(),
                nickname: playerList[i].nickname,
                appPlayerId: playerList[i].appPlayerId,
                agentName: playerList[i].agent ? playerList[i].agent.name : "None",
                agentId: playerList[i].agent ? playerList[i].agent.appAgentId : "None",
                superAgentName: playerList[i].agent && playerList[i].agent.superAgent ? playerList[i].agent.superAgent.name : "None",
                superAgentId: playerList[i].agent && playerList[i].agent.superAgent ? playerList[i].agent.superAgent.appAgentId : "None",
                sourceAppName: playerList[i].app ? playerList[i].app.name : "None",
                player: playerList[i],
                clubCount: playerList[i].clubs.length
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                return 0;
            });
        }

        table.rows = rows;

        return table;
    }




    function GetEmptySchema(onOpenPlayerEdit) {
        return {
            columns: [
                {
                    Header: "Player",
                    accessor: "name",
                    Cell: ({ value: [name, data], row }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} participant={row && row.original && row.original.player ? row.original.player : null} onClick={onOpenPlayerEdit} />
                    ),
                },
                { Header: "Player Id", accessor: "appPlayerId", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Player Nickname", accessor: "nickname", Cell: ({ value }) => <TextCell value={value} /> },
                //{ Header: "Agent", accessor: "agentName", Cell: ({ value }) => <TextCell value={value} /> },
                //{ Header: "Agent Id", accessor: "agentId", Cell: ({ value }) => <TextCell value={value} /> },
                // { Header: "Super Agent", accessor: "superAgentName", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "Clubs", accessor: "clubCount", Cell: ({ value }) => <MoneyCell value={value} useColorScheme={false} prefix="" /> },
                { Header: "Source", accessor: "sourceAppName", Cell: ({ value }) => <TextCell value={value} /> },
            ],
            rows: []
        };
    }

    const switchToMobileOnSize = "md";

    const theme = useTheme();
    const isMobileUi = !useMediaQuery(theme.breakpoints.up(switchToMobileOnSize));

    const [players, setPlayers] = useState(null);
    const [playersTable, setPlayersTable] = useState(null);
    const [currentPlayer, setCurrentPlayer] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading players...", handleRetry);

    const [openPlayerEditorDialog, playerEditorDialogProps] = useDialog();

    const [context, actions, features] = useCardroomContext(cardroom);

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    useEffect(() => {
        if (!players) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading players...")
            showProgress();
            //  [HttpGet("{agencyId:Guid}/casino/{casinoId:Guid}/players", Name = "GetAgencyPlayers")]
            clubRequest.execute("GET", apiConfig.club.endpoint + "/agency/" + agency.id + "/casino/" + cardroom.id + "/players").then((response) => {
                setPlayers(response);
                setPlayersTable(null);
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load players, please try again...")
            });
        }
    }, [clubRequest.execute, players, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (players && !playersTable) {
            setPlayersTable(preparePlayersForPresentation(players, GetEmptySchema(onPlayerEditRequested)));
            progressIndicatorProps.close();
        }
    }, [players, playersTable]);

    function canEditPlayer() {
        if (!context) return false;

        return context.isAllowed(actions.cardroom.fees.player.edit);
    }

    function onPlayerEditRequested(p) {
        if (!p) return;

        setCurrentPlayer(p);

        openPlayerEditorDialog();
    }

    function playerHasbeenUpdated(p) { }

    function renderMobileRow(columns, row, key, collapsibleDetailAccessor) {
        const color = "secondary";

        // const hasAgent = true;
        // const hasSuperAgent = Boolean(row.original.player.agent.superAgent);

        return <Card
            sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
                backgroundImage: gradients[color]
                    ? `${linearGradient(
                        rgba(gradients[color].main, 0.85),
                        rgba(gradients[color].state, 0.85)
                    )}, url(${whiteCurved})`
                    : `${linearGradient(
                        rgba(gradients[color].main, 0.85),
                        rgba(gradients[color].state, 0.85)
                    )}, url(${whiteCurved})`,
                backgroundSize: "cover",
                backgroundPosition: "50%",
                overflow: "visible",
            })}
        >
            <CardContent>
                <SoftBox pt={2} textAlign="center">
                    <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: row.cells[1].value, fontWeight: "medium" }}
                        count={row.cells[0].value[0]}
                        icon={{ color: "dark", component: "person" }}
                        direction={"left"}
                        useNumericFormatter={false}
                    />
                </SoftBox>
            </CardContent>
            <CardActions>
                <Grid item xs={12} p={1}>
                    <SoftButton ml="auto" mr="auto"
                        variant="gradient"
                        color={"dark"}
                        fullWidth
                        disabled={!canEditPlayer()}
                        onClick={() => onPlayerEditRequested(row.original.player)}
                    >
                        SETTINGS&nbsp;
                        <Icon sx={{ fontWeight: "bold" }}>settings</Icon>
                    </SoftButton>
                </Grid>
            </CardActions>

        </Card >;

    }

    return <Card>
        <ProgressIndicator {...progressIndicatorProps} />

        {canEditPlayer() ? <AgencyPlayerEditorDialog {...playerEditorDialogProps} cardroom={cardroom} agency={agency} player={currentPlayer} onPlayerUpdated={playerHasbeenUpdated} /> : null}

        {(cardroom && agency) && !progressIndicatorProps.visible ? <>
            {playersTable ?
                <DataTable table={playersTable} canSearch entriesPerPage={{ defaultValue: 10, visible: !isMobileUi }} pagination={{ color: "dark" }}
                    onRenderRow={renderMobileRow} useCustomRenderSize={switchToMobileOnSize} /> : null}
        </> : null}
    </Card>;
};

export default AgencyPlayers