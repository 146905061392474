import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: {
        clubId,
        feeSchedule,
        referralSchedule,
        referredClub,
        useTiers,
        feeTierId }
} = form;



const validations = {
    editor: Yup.object().shape({
        [clubId.name]: Yup.string().required(clubId.errorMsg),
        //[feeSchedule.name]: Yup.string().required(feeSchedule.errorMsg),
        [referralSchedule.name]: Yup.string().required(referralSchedule.errorMsg),
        [referredClub.name]: Yup.string().required(referredClub.errorMsg),
        [feeTierId.name]: Yup.string().when([useTiers.name], {
            is: (useTiersV) => (useTiersV === true),
            then: Yup.string().required(feeTierId.errorMsg)
        }),
    }),

};

export default validations;