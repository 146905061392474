const zero = () => {
    return parseFloat(0).toFixed(2);
};

const formatDecimalValue = (v, precision) => {
    if (!v) return zero();

    if (!precision) precision = 2;

    return v.toFixed(precision);
};

const dynamicExpressionManager = {
    knownExpressions: {
        agent: {
            chipOffset: "35f01888-fddc-411b-9ad8-4823cbf3e626",
            ecoTax: "a8ce0e2e-3cc3-4037-b268-fc51b1390fa8"
        }
    },
    expressions: [],
    loaded: false,
    getExpression: function (expressionId) {

        dynamicExpressionManager.ensureExpressions();

        if (!dynamicExpressionManager.expressions[expressionId]) return null;

        return dynamicExpressionManager.expressions[expressionId].expression;
    },
    evaluate: function (expression, context) {
        //context is refernced inside expression
        return eval(expression);
    },
    ensureExpressions: function () {
        if (dynamicExpressionManager.loaded && dynamicExpressionManager.expressions.length > 0) return;
        dynamicExpressionManager.loadExpressions();
    },
    loadExpressions: function () {
        dynamicExpressionManager.expressions = [];

        dynamicExpressionManager.expressions[dynamicExpressionManager.knownExpressions.agent.chipOffset] = {
            id: dynamicExpressionManager.knownExpressions.agent.chipOffset,
            name: "Default Agent Chipoffset Expression",
            expression: "context.chipoffsetRate * (context.fees + context.results)"
        };

        dynamicExpressionManager.expressions[dynamicExpressionManager.knownExpressions.agent.ecoTax] = {
            id: dynamicExpressionManager.knownExpressions.agent.ecoTax,
            name: "Default Agent ECO Tax Expression",
            expression: "context.taxRebateRate * (context.fees + context.results - context.xmtt)"
        };


        dynamicExpressionManager.loaded = true;
    }
};

const categoryAdhocLines = (period) => {

    const expenses = [];
    const other = [];

    if (period && period.adhocLineItems) {
        for (var i = 0; i < period.adhocLineItems.length; i++) {

            let container = null;
            switch (period.adhocLineItems[i].category) {
                case "Expense":
                    container = expenses;
                    break;
                default:
                    container = other;
                    break;
            }

            if (container) {
                container.push(period.adhocLineItems[i]);
            }
        }
    }

    return {
        expenses,
        other
    }

}


const preparePromotionalLines = (period, promos) => {

    const promoMap = [];
    if (promos) {
        for (var i = 0; i < promos.length; i++) {
            if (!promoMap[promos[i].id]) promoMap[promos[i].id] = promos[i];
        }
    }

    const lines = [];

    if (period && period.promotionalLineItems) {
        for (var i = 0; i < period.promotionalLineItems.length; i++) {
            let line = period.promotionalLineItems[i];
            let promo = promoMap[line.promotionId];

            lines.push({
                name: promo ? promo.name : "N/A",
                amount: line.eligibleAmount * (promo ? promo.allocation : 1), //* promo.rate
                line: line,
                promo: promo
            });
        }
    }

    return lines;
}


const usePlayerCalculator = () => {
    const calculator = {
        earnings: function (player) {
            return -99;
        },

        rebate: function (player) {
            if (!player) return 0;
            if (!player.rebateSchedule) return 0;

            if (player.rebateSchedule.scheme == 0) return 0;

            if (player.rebateSchedule.rebateOnLossOnly === true && player.result > 0) return 0;

            if (player.fees < player.rebateSchedule.threshold) return 0;

            let eligbileAmount = player.fees;

            if (player.rebateSchedule.scheme == 2) eligbileAmount -= player.rebateSchedule.threshold;

            if (eligbileAmount <= 0) return 0;

            return eligbileAmount * player.rebateSchedule.rate;
        },

        zero: zero,
        formatDecimalAsCurrency: formatDecimalValue
    };

    return calculator;

};

const useAgentCalculator = () => {

    function calculateCommission(amount, scheme, threshold, rate) {
        //determine adjusted fee
        let agi = amount - threshold;
        //determing fee
        if (agi <= 0) return 0;
        switch (scheme) {
            case 1: //Flat
                return amount * rate;
            case 2: //Progressive
                return agi * rate;
        }

        return 0;
    }

    const calculator = {
        playerAggregates: function (agent) {
            const numbers = {
                results: 0,
                fees: 0,
                jackpotFee: 0,
                jackpotPayout: 0,
                xmttFee: 0,
                xmttResult: 0
            };

            if (!agent) return numbers;

            let players = agent.effPlayers || agent.players;

            if (!players) return numbers;

            for (var i = 0; i < players.length; i++) {
                numbers.fees += players[i].fees;
                numbers.results += players[i].result;
                numbers.jackpotFee += players[i].jackpotFee;
                numbers.jackpotPayout += players[i].jackpotPayout;
                numbers.xmttFee += players[i].xmttFee;
                numbers.xmttResult += players[i].xmttResult;
                if (players[i].results) {
                    numbers.cash += players[i].results.cash;
                    numbers.sng += players[i].results.sng;
                    numbers.mtt += players[i].results.mtt;
                    numbers.ofc += players[i].results.ofc;
                    numbers.spin += players[i].results.spin;
                    numbers.leaderboard += players[i].results.leaderboard;
                }
            }

            return numbers;

        },
        comission: function (agent, aggregates) {

            if (agent && agent.feeSchedule) {

                //this could occur when report is being presented to someone navigating down, that doesn't have actual permission to club itself
                //union navigating to club level report w/o explicit permissions
                if (isNaN(parseInt(agent.feeSchedule.scheme))) return null;

                //None = 0,
                if (agent.feeSchedule.scheme === 0) return 0;

                if (agent.feeSchedule.aggregate === true) {
                    return calculateCommission(aggregates.fees, agent.feeSchedule.scheme, agent.feeSchedule.threshold, agent.feeSchedule.rate);
                }
                else {
                    let commision = 0;
                    let players = agent.effPlayers || agent.players;

                    if (!players) return 0;

                    for (var i = 0; i < players.length; i++) {
                        commision += calculateCommission(players[i].fees, agent.feeSchedule.scheme, agent.feeSchedule.threshold, agent.feeSchedule.rate);
                    }

                    return commision;
                }
            }
            return null;

        },

        chipOffset: function (agent, aggregates) {
            if (!agent || !aggregates) return 0;

            if (!agent.feeSchedule) return 0;

            let expression = dynamicExpressionManager.getExpression(dynamicExpressionManager.knownExpressions.agent.chipOffset);

            if (agent.feeSchedule.chipoffsetFormula) {
                expression = agent.feeSchedule.chipoffsetFormula.expression;
            }

            if (expression) {
                let context = {
                    chipoffsetRate: agent.feeSchedule.chipoffsetRate,
                    fees: aggregates.fees,
                    results: aggregates.results,
                    commission: calculator.comission(agent, aggregates)
                };

                return dynamicExpressionManager.evaluate(expression, context) * -1;
            }

            //fallback to default equation
            return agent.feeSchedule.chipoffsetRate * (aggregates.fees + aggregates.results) * -1;
        },
        taxRebate: function (agent, aggregates) {
            if (!agent || !aggregates) return 0;
            if (!agent.feeSchedule) return 0;

            if (agent.feeSchedule.taxRebateRate <= 0) return 0;

            let expression = dynamicExpressionManager.getExpression(dynamicExpressionManager.knownExpressions.agent.ecoTax);

            if (agent.feeSchedule.taxRebateFormula) {
                expression = agent.feeSchedule.taxRebateFormula.expression;
            }

            if (expression) {
                const context = {
                    fees: aggregates.fees,
                    results: aggregates.results,
                    commission: calculator.comission(agent, aggregates),
                    xmtt: aggregates.xmttResult,
                    jackpot: aggregates.jackpotFee + aggregates.jackpotPayout,
                    taxRebateRate: agent.feeSchedule.taxRebateRate
                };

                return dynamicExpressionManager.evaluate(expression, context) * -1;
            }

            //default fall through
            //const earnings = aggregates.fees + aggregates.results;
            //const xmtt = aggregates.xmttResult;
            //const jackpotPnL = aggregates.jackpotFee + aggregates.jackpotPayout;
            //const adjustedEarnings = earnings - xmtt; // + jackpotPnL;


            let taxableAmount = aggregates.fees + aggregates.results; 

            /*
                Cash = 1, SNG = 2, MTT = 4, OFC = 8, Spin = 16, BBJ = 32, Leaderboard = 64
            */

            if ((agent.feeSchedule.excludeFromTax & 1) > 0 &&  Math.abs(aggregates.cash) > 0) //cash
            {
                taxableAmount = taxableAmount - aggregates.cash;
            }

            if ((agent.feeSchedule.excludeFromTax & 2) > 0 && Math.abs(aggregates.sng) > 0) //sng
            {
                taxableAmount = taxableAmount - aggregates.sng;
            }

            if ((agent.feeSchedule.excludeFromTax & 4) > 0) //mtt
            {
                if (Math.abs(aggregates.mtt) > 0)
                    taxableAmount = taxableAmount - aggregates.mtt;
            }
            else { //if mtt is not being backed, backout xmtt, mtt already includes xmtt
                if (Math.abs(aggregates.xmttFee + aggregates.xmttResult) > 0)
                    taxableAmount = taxableAmount - (aggregates.xmttFee + aggregates.xmttResult);
            }

            if ((agent.feeSchedule.excludeFromTax & 8) > 0  && Math.abs(aggregates.ofc) > 0)//ofc
            {
                taxableAmount = taxableAmount - aggregates.ofc;
            }

            if ((agent.feeSchedule.excludeFromTax & 16) > 0  && Math.abs(aggregates.spin) > 0) //spin
            {
                taxableAmount = taxableAmount - aggregates.spin;
            }

            if ((agent.feeSchedule.excludeFromTax & 32) > 0 && Math.abs(aggregates.jackpotFee - aggregates.jackpotPayout) > 0) // BBJ
            {
                taxableAmount = taxableAmount + (aggregates.jackpotFee - aggregates.jackpotPayout);
            }

            if ((agent.feeSchedule.excludeFromTax & 64) > 0 && Math.abs(aggregates.leaderboard) > 0) // leaderboard
            {
                taxableAmount = taxableAmount - aggregates.leaderboard;
            }

            return agent.feeSchedule.taxRebateRate * taxableAmount * -1;
        },
        getEffectiveAgentList: function (players, cardroom, agency, bypassAgencyExclusion) {

            const clubs = [];
            const clubMap = [];

            if (players) {

                function getPlayerKey(p) {
                    if (!p) return null;

                    if (p.agent) return p.id + "_" + p.agent.id;

                    return p.id;
                }

                for (var i = 0; i < players.length; i++) {
                    let agent = players[i].agent;
                    if (!agent) continue;

                    const playerKey = getPlayerKey(players[i]);

                    let club = null;

                    if (!clubMap[players[i].club.id]) {
                        club = {
                            agents: [],
                            agentMap: []
                        };

                        clubMap[players[i].club.id] = club;
                        clubs.push(club);
                    }
                    else {
                        club = clubMap[players[i].club.id];
                    }

                    //make sure current agent's refernce is sync'd
                    if (!club.agentMap[agent.id]) {
                        club.agentMap[agent.id] = agent;
                        club.agents.push(agent);
                    } else {
                        agent = club.agentMap[agent.id];
                    }

                    let effAgent = agent.superAgent || agent;

                    //WHEN DEALING WITH AGENCY -- DO NOT FLATTEN MAIN AGENT???
                    //show only subagents of the agent here...

                    if (!bypassAgencyExclusion && cardroom.type == 5 && agency && agent.superAgent && agency.id == agent.superAgent.id) {
                        effAgent = agent;
                    }

                    if (!club.agentMap[effAgent.id]) {
                        club.agentMap[effAgent.id] = effAgent;
                        club.agents.push(effAgent);
                    } else {
                        effAgent = club.agentMap[effAgent.id];
                    }

                    if (!effAgent.effPlayers) {
                        effAgent.effPlayers = [];
                        effAgent.effPlayerMap = [];
                    }

                    if (!effAgent.effPlayerMap[playerKey]) {
                        effAgent.effPlayers.push(players[i]);
                        effAgent.effPlayerMap[playerKey] = players[i];
                    }


                    if (!effAgent.agents) {
                        effAgent.agents = [];
                        effAgent.agentMap = [];
                    }

                    if (effAgent.id != agent.id) {
                        if (!effAgent.agentMap[agent.id]) {
                            effAgent.agentMap[agent.id] = agent;
                            effAgent.agents.push(agent);
                        }
                    }


                    //agent may be a different reference... we should try to add to agent eq inside club
                    if (!agent.players) {
                        agent.players = [];
                        agent.playerMap = [];
                    }

                    if (!agent.playerMap[playerKey]) {
                        agent.players.push(players[i]);
                        agent.playerMap[playerKey] = players[i];
                    }


                }
            }

            const agents = [];
            for (var i = 0; i < clubs.length; i++) {
                //agents = agents.concat(clubs[i].agents);
                for (var j = 0; j < clubs[i].agents.length; j++) {
                    if (clubs[i].agents[j].superAgent && clubs[i].agents[j].superAgent.id != clubs[i].agents[j].id) continue; //if has superagent move on
                    agents.push(clubs[i].agents[j]);
                }
            }

            return agents;
        },
        categorizeLineItems: categoryAdhocLines,
        zero: zero,
        formatDecimalAsCurrency: formatDecimalValue

    };



    return calculator;

};

const useUnionCalculator = () => {
    const calculator = {
        earnings: function (cSummary) {
            if (!cSummary) return 0;
            return (cSummary.winnings + cSummary.fees);
        },
        unionDues: function (cSummary) {
            if (!cSummary) return 0;

            let fixed = cSummary.unionDuesBase;

            if (cSummary.playerCount == 0) {
                fixed = 0;
            }

            let fees = cSummary.fees - calculator.mttBackoff(cSummary);

            return ((fees * cSummary.unionDuesRate * -1) - fixed);

        },
        mttBackoff: function (cSummary) {
            if (!cSummary) return 0;
            let backoff = 0;

            if (cSummary.mttBackoffRate > 0 && cSummary.mttBuyIns > 0) {
                backoff = cSummary.mttBackoffRate * cSummary.mttBuyIns;
            }
            return backoff;

        },
        xmttDues: function (cSummary) {
            if (!cSummary) return 0;
            return (cSummary.xmttFees * cSummary.xmttDuesRate * -1);
        },
        chipOffset: function (cSummary) {
            if (!cSummary) return 0;

            //alert('foo');
            let earnings = calculator.earnings(cSummary);

            //if (cSummary.mttBackoffRate > 0 && cSummary.mttBuyIns > 0) {
            //    earnings = earnings - cSummary.mttBackoffRate * cSummary.mttBuyIns;
            //}

            return (earnings * cSummary.chipOffsetRate * -1);
        },
        ecoTax: function (cSummary) {
            if (!cSummary) return 0;

            let taxableAmount = calculator.earnings(cSummary); // - cSummary.xmttPnL + cSummary.jackpotPnL;

            /*
                Cash = 1, SNG = 2, MTT = 4, OFC = 8, Spin = 16, BBJ = 32, Leaderboard = 64
            */

            if ((cSummary.excludeFromTax & 1) > 0 && cSummary.results && Math.abs(cSummary.results.cash) > 0) //cash
            {
                taxableAmount = taxableAmount - cSummary.results.cash;
            }

            if ((cSummary.excludeFromTax & 2) > 0 && cSummary.results && Math.abs(cSummary.results.sng) > 0) //sng
            {
                taxableAmount = taxableAmount - cSummary.results.sng;
            }

            if ((cSummary.excludeFromTax & 4) > 0) //mtt
            {
                if (cSummary.results && Math.abs(cSummary.results.mtt) > 0)
                    taxableAmount = taxableAmount - cSummary.results.mtt;
            }
            else { //if mtt is not being backed, backout xmtt, mtt already includes xmtt
                if (Math.abs(cSummary.xmttPnL) > 0)
                    taxableAmount = taxableAmount - cSummary.xmttPnL;
            }

            if ((cSummary.excludeFromTax & 8) > 0 && cSummary.results && Math.abs(cSummary.results.ofc) > 0)//ofc
            {
                taxableAmount = taxableAmount - cSummary.results.ofc;
            }

            if ((cSummary.excludeFromTax & 16) > 0 && cSummary.results && Math.abs(cSummary.results.spin) > 0) //spin
            {
                taxableAmount = taxableAmount - cSummary.results.spin;
            }

            if ((cSummary.excludeFromTax & 32) > 0 && Math.abs(cSummary.jackpotPnL) > 0) // BBJ
            {
                taxableAmount = taxableAmount + cSummary.jackpotPnL;
            }

            if ((cSummary.excludeFromTax & 64) > 0 && Math.abs(cSummary.results.leaderboard) > 0) // leaderboard
            {
                taxableAmount = taxableAmount - cSummary.results.leaderboard;
            }

            //backout MTT BACKOFF FROM ECO...
            //if (cSummary.mttBackoffRate > 0 && cSummary.mttBuyIns > 0) {
            //    taxableAmount = taxableAmount + cSummary.mttBackoffRate * cSummary.mttBuyIns;
            //}


            let winnerTax = taxableAmount > 0 ? taxableAmount * cSummary.winTaxRate : 0;

            return ((taxableAmount * cSummary.taxRebateRate * -1) - winnerTax);
        },
        unionSettlement: function (uSummary) {
            if (!uSummary) return 0;
            return uSummary.totalEarnings + uSummary.xmttDues + uSummary.chipOffset + uSummary.tax + uSummary.totalExpenses;
        },
        categorizeLineItems: categoryAdhocLines,
        preparePromotionalLines: preparePromotionalLines,
        zero: zero,
        formatDecimalAsCurrency: formatDecimalValue
    };

    return calculator;

};

export {
    useUnionCalculator,
    useAgentCalculator,
    usePlayerCalculator
};