import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Grid } from '@mui/material';

import { ErrorMessage } from "formik";
import FormField from "components/Elements/Forms/FormField";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import expensesAvatar from "assets/graphics/casino/expenses_256.png";
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';
import { useCardroomContext } from 'features';

const AccountExpenseForm = ({ cardroom, cardroomAccount, staff, players, clubs, agents, unions, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { type, sourceAccount, targetAccount, participant, amount, expenseTxType, accountBalance, note } = formField;
    const {
        type: typeV,
        amount: amountV,
        expenseTxType: expenseTxTypeV,
        accountBalance: accountBalanceV,
        note: noteV,
        sourceAccount: sourceAccountV,
        targetAccount: targetAccountV,
        participant: participantV
    } = values;


    const [formDataInitialized, setFormDataInitialized] = useState(false);

    const [fromAccountHolderName, setFromAccountHolderName] = useState(null);

    const [participantType, setParticipantType] = useState(null);
    const [targetAccountList, setTargetAccountList] = useState(null);
    const [selectedTargetAccountIndex, setSelectedTargetAccountIndex] = useState(-1);
    const [targetTypes, setTargetTypes] = useState([]);

    const [context, actions, features] = useCardroomContext(cardroom);
    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    function onTransactionTypeChanged(e, newValue) {
        setFieldValue(expenseTxType.name, newValue);
    }

    function onTargetAccountSelectionChanged({ item, index }) {
        if (item && item.account) {
            setFieldValue(targetAccount.name, item.account.id);
            setFieldValue(participant.name, item.displayName);
            setSelectedTargetAccountIndex(index);
            setFieldValue(accountBalance.name, item.account.accountsReceivable - item.account.accountsPayable);
        }
    }

    function onAmountChanging(value) {
        if (!formDataInitialized) return;
        //if (value <= markerBalanceV && markerBalanceV > 0 && methodV != "credit") {
        //    setFieldValue(method.name, "credit");
        //}
    }

    function onTargetTypeChanged(e, newValue) {

        setParticipantType(newValue);

        let ds = null;

        switch (newValue) {
            case "player":
                ds = players;
                break;
            case "staff":
                ds = staff;
                break;
            case "club":
                ds = clubs;
                break;
            case "agent":
                ds = agents;
                break;
            case "union":
                ds = unions;
                break;
        }

        setSelectedTargetAccountIndex(-1);
        setTargetAccountList(filterParticipants(ds));
        setFieldValue(targetAccount.name, "");
        setFieldValue(participant.name, "");
        setFieldValue(accountBalance.name, 0);

    }

    function filterParticipants(ds) {
        if (!ds) return ds;
        const result = [];

        for (var i = 0; i < ds.length; i++) {
            //we only want accounts that have a credit, as we can only pay those that we owe $ to...

            if (ds[i].account) {
                result.push(ds[i]);
            }
            else {
                console.warn("account missing, holder id: " + ds[i].id);
            }
        }

        if (result.length > 0) {
            //sort by displayName

            result.sort(function (a, b) {
                if (a.displayName < b.displayName) { return -1; }
                if (a.displayName > b.displayName) { return 1; }
                return 0;
            });
        }

        return result;
    }

    function formatAmount(amount) {
        const prefix = isCurrencyMaskingEnabled() === true ? "" : "$";

        var delimiter = ","; // replace comma if desired
        var a = amount.toString().split('.', 2)
        var d = a[1];
        var i = parseInt(a[0]);
        if (isNaN(i)) { return ''; }
        var minus = '';
        if (i < 0) { minus = '-'; }
        i = Math.abs(i);
        var n = new String(i);
        var a = [];
        while (n.length > 3) {
            var nn = n.substring(n.length - 3);
            a.unshift(nn);
            n = n.substring(0, n.length - 3);
        }
        if (n.length > 0) { a.unshift(n); }
        n = a.join(delimiter);
        if (!d || d.length < 1) { amount = n; }
        else { amount = n + '.' + d; }
        amount = minus + prefix + amount;
        return amount;
    }

    useEffect(() => {
        if (!formDataInitialized) {

            setFieldValue(type.name, "expense");
            setFieldValue(expenseTxType.name, "charge");

            setFieldValue(amount.name, 0);

            setFieldValue(sourceAccount.name, cardroomAccount.id)
            setFromAccountHolderName(cardroom.name);
            setFieldValue(targetAccount.name, "");
            setFieldValue(participant.name, "");
            setFieldValue(note.name, "");

            const types = [];

            let ds = null;
            switch (cardroom.type) {
                case 1: //homegame
                    setParticipantType("player");
                    ds = players;
                    types.push({ key: "player", value: "Player" });
                    types.push({ key: "staff", value: "Staff" });
                    break;
                case 2: //club
                case 6: //book
                    setParticipantType("union");
                    ds = unions;

                    types.push({ key: "union", value: "Union" });

                    if (agents && agents.length > 0)
                        types.push({ key: "agent", value: "Agent" });
                    if (players && players.length > 0)
                        types.push({ key: "player", value: "Player" });

                    break;
                case 3: //union -- do we need to differntiate between virtual and not???
                    if (unions && unions.length > 0) {
                        setParticipantType("union");
                        ds = unions;

                        //if current union is virtual, then value; Union, else "Umbrella Union"
                        types.push({ key: "union", value: "Union" });
                        types.push({ key: "club", value: "Club" });
                    }
                    else {
                        setParticipantType("club");
                        ds = clubs;
                        types.push({ key: "club", value: "Club" });
                    }
                    break;
                case 5: //agency
                    setParticipantType("player");
                    ds = players;

                    types.push({ key: "player", value: "Player" });
                    types.push({ key: "agent", value: "Agent" });
                    break;
            }

            setTargetTypes(types);
            setTargetAccountList(filterParticipants(ds));

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    function areMultipleParticipantTypeSupported() {
        return targetTypes && targetTypes.length > 1;
    }

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={expensesAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {expenseTxType.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={1.5}
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize">
                                    <RadioGroup row={true} value={expenseTxTypeV} onChange={onTransactionTypeChanged}>
                                        <FormControlLabel value="charge" control={<Radio />} label="Charge" />
                                        <FormControlLabel value="reimburse" control={<Radio />} label="Reimburse" />
                                    </RadioGroup>

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={expenseTxType.name} />
                                    </SoftTypography>
                                </SoftTypography>
                            </Grid>
                        </Grid>

                        {expenseTxTypeV ?
                            <>
                                {areMultipleParticipantTypeSupported() ?
                                    <Grid container xs={12}>
                                        <Grid item xs={4} >
                                            <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    Account Type
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <SoftTypography pl={1.5}
                                                component="label"
                                                variant="caption"
                                                textTransform="capitalize">
                                                <RadioGroup row={true} value={participantType} onChange={onTargetTypeChanged}>
                                                    {targetTypes ? targetTypes.map((pt) => {
                                                        return <FormControlLabel value={pt.key} control={<Radio />} label={pt.value} />;
                                                    }) : null}
                                                </RadioGroup>
                                            </SoftTypography>
                                        </Grid>
                                    </Grid> : null}



                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                Account
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <DataBoundSelect placeholder="account" valueName="id" textName="displayName"
                                            data={targetAccountList} selectedIndex={selectedTargetAccountIndex} onChange={onTargetAccountSelectionChanged} />

                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            color="error">
                                            <ErrorMessage name={targetAccount.name} />
                                        </SoftTypography>
                                    </Grid>
                                </Grid>

                                {targetAccountV ?
                                    <>
                                        <Grid container xs={12}>
                                            <Grid item xs={4} >
                                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                    >
                                                        {amount.label}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <FormField onFocus={e => e.currentTarget.select()} autoFocus
                                                    type={amount.type}
                                                    name={amount.name}
                                                    value={amountV}
                                                    validate={onAmountChanging}
                                                    placeholder={amount.placeholder}
                                                    error={errors.amount && touched.amount}
                                                    success={amountV && amountV.toString().length > 0 && !errors.amount}
                                                /></Grid>
                                        </Grid>

                                        <Grid container xs={12}>
                                            <Grid item xs={4} >
                                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize">
                                                        {note.label}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <FormField onFocus={e => e.currentTarget.select()}
                                                    type={note.type}
                                                    name={note.name}
                                                    value={noteV}
                                                    placeholder={note.placeholder}
                                                    error={errors.note && touched.note}
                                                    success={noteV && noteV.length > 0 && !errors.note}
                                                />
                                            </Grid>
                                        </Grid>
                                    </> : null}

                            </> : null}

                        <Grid container xs={12}>
                            <Grid item xs={12} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        color="info"
                                    >
                                        {fromAccountHolderName} will {expenseTxTypeV} {participantV} account {formatAmount(amountV)} for this expense
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                        </Grid>

                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox>;
};


export default AccountExpenseForm;