
const form = {
    formId: "player-rebate-schedule-editor-form",
    formField: {
        scheduleId: {
            name: "scheduleId",
            label: "schedule id",
            type: "hidden"
        },
        name: {
            name: "name",
            label: "Name",
            type: "text",
            placeholder: "name",
            errorMsg: "Name is required.",
        },
        description: {
            name: "description",
            label: "Description",
            type: "text",
            placeholder: "description",
        },
        rebateRate: {
            name: "rebateRate",
            label: "Rebate Rate",
            type: "number",
            placeholder: "rebate percentage",
            errorMsg: "Rebate rate is required",
        },
        rebateThreshold: {
            name: "rebateThreshold",
            label: "Rebate exemption",
            type: "number",
            placeholder: "rebate exemption",
            errorMsg: "Exempt amount is required",
        },
        makeupThreshold: {
            name: "makeupThreshold",
            label: "Max permitted makeup",
            type: "number",
            placeholder: "max makeup",
            errorMsg: "Max permitted makeup amount is required",
        },
        rebateScheduleScheme: {
            name: "rebateScheduleScheme",
            label: "Rebate Type",
            type: "number",
            placeholder: "rebate type",
            errorMsg: "rebate type is required",
        },
        lossOnly: {
            name: "lossOnly",
            label: "Rebate on loss only",
            type: "bool",
            placeholder: "rebate on loss only"
        },
        /*
          chipOffsetRate: {
              name: "chipOffsetRate",
              label: "Chip Offset Rate",
              type: "number",
              placeholder: "chip offset percentage",
              errorMsg: "Chip offset rate is required",
          },
          taxRebateRate: {
              name: "taxRebateRate",
              label: "Tax Rebate Rate",
              type: "number",
              placeholder: "tax rebate percentage",
              errorMsg: "Tax rebate rate is required",
          },
          */
    },

};

export default form;
