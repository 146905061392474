import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { useDialog } from "components/Elements/Dialog/common";
import { AddHouseTransactionDialog } from "layouts/Cardroom/Session/components/Dialogs";
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import DataTable from "components/Elements/DataTable";
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import DateCell from 'components/Elements/DataTable/components/Cells/DateCell';
import ButtonCell from 'components/Elements/DataTable/components/Cells/ButtonCell';
import TransactionDialog from 'layouts/Cardroom/Transaction';
import IconCell from 'components/Elements/DataTable/components/Cells/IconCell';
import { useCardroomContext } from 'features';

function convertHouseTransactionsToTable(transactions, table) {
    var rows = [];

    function getPayType(v) {
        if (v === 1) return "cash";
        if (v === 2) return "ewallet";
        if (v === 3) return "credit";
        if (v === 4) return "account"

        return null;
    }

    function getTxType(v) {
        if (v === 1) return "buyin";
        if (v === 2) return "cashout";
        if (v === 3) return "promo";
        if (v === 4) return "withholding";
        if (v === 5) return "rake";
        if (v === 6) return "seated";
        if (v === 7) return "expense";
        if (v === 8) return "payoff";
        if (v === 9) return "tip";

        return "other";
    }

    function getTxStatus(v) {

        if (v === 1) return "pending";
        if (v === 2) return "committed";
        if (v === 3) return "canceled";
        if (v === 4) return "rejected";
        return "other";

    }

    function getSignedAmountValue(txtype, v) {
        if (txtype === 3 || txtype === 7)
            return -1 * v;

        return v;
    }

    if (transactions && transactions.length > 0) {
        for (var i = 0; i < transactions.length; i++) {
            if (transactions[i].type === 6) continue; //ignore "seated" transaction...

            let row = {
                id: transactions[i].id,
                type: getTxType(transactions[i].type),
                amount: getSignedAmountValue(transactions[i].type, transactions[i].amount),
                participantRole: transactions[i].particpantType,
                payType: getPayType(transactions[i].paymentType),
                status: getTxStatus(transactions[i].status),
                createdOn: transactions[i].transactionDate,
                eWalletVendor: transactions[i].ePaymentVendor,
                eWalletVendorRefNo: transactions[i].ePaymentVendorReferenceNo,
                eWalletVendorRefImg: transactions[i].ePaymentVendorReferenceImage,
                signature: transactions[i].participantSignature,
                note: transactions[i].note,
                transaction: transactions[i]
            };

            rows.push(row);
        }
    }

    table.rows = rows;

    return table;
}
function GetHouseTransactionsTableSchema(detailsIconClickHandler, maskCurrency) {
    const prefix = maskCurrency === true ? "" : "$";

    return {
        columns: [
            { Header: "tx type", accessor: "type", Cell: ({ value }) => <TextCell value={value} /> },
            { Header: "description", accessor: "note", Cell: ({ value }) => <TextCell value={value} /> },
            { Header: "amount", accessor: "amount", Cell: ({ value }) => <MoneyCell value={value} prefix={prefix} useFormatter={true} useColorScheme={true} /> },
            { Header: "created on", accessor: "createdOn", Cell: ({ value }) => <DateCell value={value + "Z"} format="dateAndTime" display="locale" /> },
            {
                Header: "status", accessor: "status",
                Cell: ({ value }) => {
                    let icon = "", color = "text";
                    let valueText = value;

                    switch (value) {
                        case "pending":
                            icon = "hourglass_empty_outlined";
                            color = "warning";
                            break;
                        case "committed":
                            icon = "check";
                            color = "success";
                            break;
                        case "canceled":
                            icon = "close_outlined";
                            color = "info";
                            break;
                        case "rejected":
                            icon = "sync_problem'";
                            color = "error";
                            break;
                        default:
                            icon = "question_mark";
                            color = "error";
                            break;
                    }

                    return <IconCell value={valueText} icon={icon} color={color} />;
                }
            },
            { Header: "details", accessor: "id", Cell: ({ value, row }) => <ButtonCell id={row && row.original ? row.original : value} onButtonClicked={detailsIconClickHandler} icon="launch" color="secondary" /> }
        ],

        rows: [],
    };
}

const SessionHouseTransactions = ({ open, close, cardroom, session, onHouseTransactionStateChanged }) => {

    const handleTransactionDetailClick = (tx) => {
        if (tx && tx.transaction) {
            setCurrentTransaction(tx.transaction);
            openHouseTxDetailDialog();
        }
    };



    const [transactions, setTransactions] = useState(null);

    const [openAddHouseTxDialog, addHouseDialogTxProps] = useDialog();
    const [openHouseTxDetailDialog, openHouseTxDetailProps] = useDialog();

    const [currentTransaction, setCurrentTransaction] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);
    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    const [txTable, setTxTable] = useState(GetHouseTransactionsTableSchema(handleTransactionDetailClick, isCurrencyMaskingEnabled()));
    // const [staff, setStaff] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);
    function handleProgressRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading house transactions...", handleProgressRetry)


    const casinoReq = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read
    });


    const refreshSessionHouseTxData = () => {

        //setTransactions(null);

        if (onHouseTransactionStateChanged) {
            onHouseTransactionStateChanged();
        }
    };

    //load house transactions
    useEffect(() => {

        if (!transactions) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading house transactions...")
            showProgress();

            casinoReq.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/participants/" + cardroom.id + "/transactions/32768").then((response) => {
                if (response) {
                    setTransactions(response);
                    //create dataTable

                    setTxTable(convertHouseTransactionsToTable(response, txTable));
                    progressIndicatorProps.close();
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load house transactions, please try again...")
            });
        }

    }, [casinoReq.execute, transactions, retryCounter])



    return (<>
        <ProgressIndicator {...progressIndicatorProps} />
        <AddHouseTransactionDialog {...addHouseDialogTxProps} cardroom={cardroom} session={session} onAdded={refreshSessionHouseTxData} />
        <TransactionDialog {...openHouseTxDetailProps} cardroom={cardroom} session={session} transaction={currentTransaction} onTransactionUpdated={refreshSessionHouseTxData} />
        {!progressIndicatorProps.visible ? <DataTable table={txTable} canSearch entriesPerPage={{ defaultValue: 10, visible: true }} isHierarchical={false} /> : null}
    </>);
}


export default SessionHouseTransactions;