import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { BootstrapDialog } from "components/Elements/Dialog/common"

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import AccountTransactionForm from '../Forms';
import { Card, Grid } from '@mui/material';
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';


const RecordAccountTransactionDialog = ({ open, close, cardroom, account, union, club, agency, book, mode, defaults, onAdded, onClosed }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    function onTransactionAdded() {
        if (onAdded) {
            onAdded();
        }

        onDialogClosureRequested();
    }

    function onDialogClosureRequested() {
        close();
        if (onClosed) onClosed();
    }


    const [selectedTxTypeIndex, setSelectedTxTypeIndex] = useState(-1);
    const [selectedTxType, setSelectedTxType] = useState(null);
    const [defaultsSelected, setDefaultsSelected] = useState(false);

    useEffect(() => {
        //clear whenever dialog is opened...
        setSelectedTxType(dropDownData[0]);
        setSelectedTxTypeIndex(0);
    }, [open]);


    const dropDownData = [
        { id: 1, key: "pay", text: "Send Payment" },
        { id: 2, key: "receive", text: "Receive Payment" },
        { id: 4, key: "expense", text: "Record Expense" },
        { id: 3, key: "journal", text: "Journal Entry" },
        //{ id: 5, key: "transfer", text: "Transfer" }             
    ];


    const dropDownSelectionChanged = (e) => {
        const item = e.item;
        const index = e.index;

        setSelectedTxTypeIndex(index);
        setSelectedTxType(item);
    }

    useEffect(() => {
        if (defaults && defaults.form && !defaultsSelected) {
            const options = dropDownData;
            for (var i = 0; i < options.length; i++) {
                if (options[i].key == defaults.form) {

                    dropDownSelectionChanged({ item: options[i], index: i });
                    setDefaultsSelected(true);
                    break;
                }
            }
        }

    }, [defaults, defaultsSelected]);

    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}
            onClose={close}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="xl">
            <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.secondary.main }}>
                <SoftBox>
                    <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                        Record Transaction
                    </SoftTypography>
                </SoftBox>
            </DialogTitle>
            <DialogContent dividers>

                <Card style={{ minHeight: 200 }} mt={3}>
                    <Grid container xs={12}>
                        <Grid item xs={12} md={4} lg={3} sx={{ ml: "auto" }} >
                            <SoftBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
                                <DataBoundSelect placeholder="Select transaction type" valueName="id" textName="text"
                                    data={dropDownData} onChange={dropDownSelectionChanged} size="medium" selectedIndex={selectedTxTypeIndex} />
                            </SoftBox>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <SoftBox pb={0} px={2} textAlign="center" lineHeight={1.25}>
                                <AccountTransactionForm cardroom={cardroom} mode={mode} cardroomAccount={account}
                                    union={union} club={club} agency={agency} defaults={defaults}
                                    type={selectedTxType ? selectedTxType.key : null} onClose={onDialogClosureRequested} onTransactionCreated={onTransactionAdded} />
                            </SoftBox></Grid>
                    </Grid>
                </Card>
            </DialogContent>
        </BootstrapDialog>
    );
};

export default RecordAccountTransactionDialog;