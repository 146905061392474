import agentForm from "./form";


const {
    formField: {
        agentId,
        agentName,
        currency
    },
} = agentForm;

const initialValues = {
    [agentId.name]: "",
    [agentName.name]: "",
    [currency.name]: "USD"
};

export default initialValues;
