import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes, { number } from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import defaultCasino from "assets/graphics/logos/default-casino.png";
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';
import SoftBox from 'components/SoftBox';
import { AppBar, Card, Divider, Grid, Icon, Switch, Tab, Tabs, Tooltip } from '@mui/material';
import PremiumServiceSubscriptionRequired from 'components/Elements/PremiumServiceRequried';
import SoftTypography from 'components/SoftTypography';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';

import { ReportAgents, ReportPlayers } from '../common';
import { useAgentCalculator, usePlayerCalculator } from '../common/calculator';
import { useReportDataLoader } from '../common/data';
import { ReportValueRedacted } from 'components/Elements/PremiumServiceRequried';



const ReportSummary = ({ cardroom, agency, reportingPeriod, summary, currency, currencyConverter }) => {

    const cardroomLogo = cardroom && cardroom.hasLogo ? apiConfig.images.endpoint + "/casino/" + cardroom.id + ".png" : defaultCasino;
    const appLogo = agency && agency.app ? apiConfig.images.endpoint + "/app/" + agency.app.id + ".png" : null;

    const [players, setPlayers] = useState(null);
    const [agents, setAgents] = useState(null);
    const [reRender, setReRender] = useState(0);

    const [currencySymbol, setCurrencySymbol] = useState(null);

    useEffect(() => {
        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency, currencyConverter]);


    function getValueInActiveCurrency(v) {
        if (!v) return agentCalculator.zero();

        var v = currencyConverter.convertFromUSD(v, currency);

        return agentCalculator.formatDecimalAsCurrency(v, currency.precision);
    }

    function getAgentCalcs(agentCalc, playerCalc) {
        const aCalc = agentCalc;
        const pCalc = playerCalc

        function calculate(agents) {
            if (!agents) return;

            clear();

            //first, just find the agency primary agent and calc comission, etc
            let agent = null;
            for (var i = 0; i < agents.length; i++) {
                if (agents[i].id !== agency.id) {
                    continue;
                }

                agent = agents[i];

                let numbers = aCalc.playerAggregates(agent);
                results.fees += numbers.fees;
                results.winnings += numbers.results;

                results.commission += aCalc.comission(agent, numbers);
                results.chipOffset += aCalc.chipOffset(agent, numbers);
                results.taxRebate += aCalc.taxRebate(agent, numbers);

            }

            results.settlement = results.commission + results.winnings + results.chipOffset + results.taxRebate; // + numbers.xmttResult + numbers.jackpotPayout

            results.needsCalculations = false;

            setReRender(reRender + 1);
        }

        function updatePlayerNumbers(players) {

            results.playerRebate = 0;
            for (var i = 0; i < players.length; i++) {
                results.playerRebate += pCalc.rebate(players[i]);
            }
        }

        function clear() {
            results.fees = 0;
            results.winnings = 0;
            results.playerRb = 0;
            results.subAgentCommission = 0;
            results.commission = 0;
            results.settlement = 0;
            results.chipOffset = 0;
            results.taxRebate = 0;
        }

        const results = {
            fees: 0,
            winnings: 0,
            playerRebate: 0,
            subAgentCommission: 0,
            commission: 0,
            chipOffset: 0,
            taxRebate: 0,
            settlement: 0,
            needsCalculations: true,
            calculateSummary: calculate,
            updatePlayers: updatePlayerNumbers
        };

        return results;
    }

    const agentCalculator = useAgentCalculator();
    const playerCalculator = usePlayerCalculator();

    const [calcs, setCalcs] = useState(getAgentCalcs(agentCalculator, playerCalculator));

    const [retryCounter, setRetryCounter] = useState(0);
    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading report details...", handleRetry);

    const [context, actions, features] = useCardroomContext(cardroom);
    function isDetailedSummaryEnabled() {
        if (!context) return false;
        return context.isEnabled(features.club.reporting.summaryDetailReport);
    }


    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const dataLoader = useReportDataLoader();

    useEffect(() => {
        if (!players) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading report details...")
            showProgress();

            dataLoader.loadPlayers(clubRequest, cardroom, reportingPeriod, summary, null, agency, true, (response) => {
                setPlayers(response);

                progressIndicatorProps.close();
            }, (err) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load report details, plese try again...");
            });

        }
    }, [clubRequest.execute, players, retryCounter])

    useEffect(() => {
        if (players && !agents) {
            setAgents(agentCalculator.getEffectiveAgentList(players, cardroom, agency, true));
        }
    }, [players, agents]);

    useEffect(() => {
        if (agents && players && calcs.needsCalculations) {
            calcs.calculateSummary(agents);
            calcs.updatePlayers(players);
        }
    }, [agents, players, calcs.needsCalculations]);

    return <Card>
        {!progressIndicatorProps.visible && agency && summary ? <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={8} >
                    <Card>
                        {/* Report header */}
                        <SoftBox p={3}>
                            <Grid container xs={12} justifyContent="space-between">
                                {agency.app ? <>
                                    <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        <SoftBox width="100%" textAlign="left" mt={2}>
                                            <SoftBox textAlign="center" width="max-content">
                                                <SoftBox component="img" src={appLogo} width="96px" style={{}} p={1} mb={1} />
                                                <SoftTypography variant="h6" fontWeight="medium">
                                                    {agency.app.name}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item sm={4} xs={12} >
                                        <SoftBox width="100%" textAlign={{ xs: "center", md: "center" }} mt={2}>
                                            <Tooltip title="Total Earnings - Union Dues + XMTT Dues + Chip Offset + Tax / Rebate + Referral Bonus" placement="top">
                                                <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="uppercase">
                                                    Settelment
                                                </SoftTypography>
                                            </Tooltip>
                                            <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="uppercase">
                                                <MoneyCell value={getValueInActiveCurrency(calcs.settlement)} prefix={currencySymbol} />
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }} >
                                        <SoftBox justifyContent="right" textAlign="right" mt={2}>
                                            <SoftBox ml="auto" textAlign="center" width="max-content">
                                                <SoftBox component="img" src={cardroomLogo} width="96px" style={{}} p={1} mb={1} />
                                                <SoftTypography variant="h6" fontWeight="medium">
                                                    {agency ? agency.name : null}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid></> : null}
                            </Grid>
                            <Grid container justifyContent="space-between" mt={2}>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                            <SoftBox mt={3}>
                                {/* Row 1 */}
                                <Grid container xs={12} justifyContent="space-between">
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"left"}>
                                                <Tooltip title="Total commission earned" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Commission
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"left"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={getValueInActiveCurrency(calcs.commission)} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"center"}>
                                                <Tooltip title="Fees generated by players" placement="top">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Player Fees
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"center"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={getValueInActiveCurrency(calcs.fees)} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"right"}>
                                                <Tooltip title="Total player winnings" placement="right-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Player Results
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"right"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={getValueInActiveCurrency(calcs.winnings)} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent="space-between" >
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>

                                {/* Row 2 */}
                                <Grid container xs={12} justifyContent="space-between">
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"left"}>
                                                <Tooltip title="Ecosystem chip offset cost" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Chip Offset
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"left"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={getValueInActiveCurrency(calcs.chipOffset)} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"center"}>
                                                <Tooltip title="Tax / rebate" placement="top">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Tax / Rebate
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"center"}>
                                                <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                    <MoneyCell value={getValueInActiveCurrency(calcs.taxRebate)} prefix={currencySymbol} />
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>

                                <Grid container justifyContent="space-between" >
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>

                                {/* Row 3 */}

                                <Grid container xs={12} justifyContent="space-between">
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"left"}>
                                                <Tooltip title="Total rebates paid back to players" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Player Rebates
                                                    </SoftTypography>
                                                </Tooltip>

                                            </Grid>
                                            <Grid item sm={12} textAlign={"left"}>
                                                {isDetailedSummaryEnabled() ?
                                                    <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                        <MoneyCell value={getValueInActiveCurrency(calcs.playerRebate * -1)} prefix={currencySymbol} />
                                                    </SoftTypography> : <ReportValueRedacted />}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"center"}>
                                                <Tooltip title="Total commission earned by agents" placement="top">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Agent Commission
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"center"}>
                                                {isDetailedSummaryEnabled() ?
                                                    <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                        <MoneyCell value={getValueInActiveCurrency(calcs.subAgentCommission * -1)} prefix={currencySymbol} />
                                                    </SoftTypography> : <ReportValueRedacted />}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Grid container xs={12} justifyContent="space-between">
                                            <Grid item sm={12} textAlign={"right"}>
                                                <Tooltip title="Total amount refunded to players & agents" placement="top-start">
                                                    <SoftTypography variant="h6" color="secondary" fontWeight="medium" textTransform="capitalize">
                                                        Total Refunds
                                                    </SoftTypography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item sm={12} textAlign={"right"}>
                                                {isDetailedSummaryEnabled() ?
                                                    <SoftTypography variant="caption" fontWeight="medium" style={{ whiteSpace: "nowrap" }}>
                                                        <MoneyCell value={getValueInActiveCurrency(-1 * (calcs.subAgentCommission + calcs.playerRebate))} prefix={currencySymbol} />
                                                    </SoftTypography> : <ReportValueRedacted />}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container xs={12} justifyContent="space-between">
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>

                            </SoftBox>

                        </SoftBox>

                    </Card>
                </Grid>
            </Grid>
        </> : <ProgressIndicator {...progressIndicatorProps} />}
        <Divider />
    </Card>;
}

ReportSummary.propTypes = {
    cardroom: PropTypes.object.isRequired,
    agency: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired
};

const AgencyReport = ({ cardroom, agency, reportingPeriod, summary, currency, currencyConverter }) => {

    const TabPanel = function (props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <SoftBox sx={{ p: 3 }}>
                        <SoftTypography>{children}</SoftTypography>
                    </SoftBox>
                )}
            </div>
        );
    }

    const a11yProps = function (index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    const [context, actions, features] = useCardroomContext(cardroom);

    function isAgentReportEnabled() {
        if (!context) return false;
        return context.isEnabled(features.club.reporting.agentReport);
    }

    function isPlayerReportEnabled() {
        if (!context) return false;
        return context.isEnabled(features.club.reporting.playerReport);
    }

    return <SoftBox>
        <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} md={6} lg={4}>
                <AppBar position="static">
                </AppBar>
            </Grid>
            <Grid item xs={12} md={6} sx={{ ml: "auto" }}>
                <AppBar position="static">
                    <Tabs
                        orientation="horizontal"
                        value={tabValue}
                        onChange={handleSetTabValue}
                        sx={{ background: "transparent" }}>
                        <Tab label="Summary" style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">dashboard</Icon>} {...a11yProps(0)} />
                        <Tab label="Agents" style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">support_agent</Icon>} {...a11yProps(1)} />
                        <Tab label="Players" style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">person</Icon>} {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
            </Grid>

        </Grid>
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", mr: "auto" }}>

                <SoftBox>
                    <TabPanel value={tabValue} index={0}>
                        <ReportSummary cardroom={cardroom} agency={agency} reportingPeriod={reportingPeriod} summary={summary} currency={currency} currencyConverter={currencyConverter} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        {isAgentReportEnabled() ?
                            <ReportAgents cardroom={cardroom} agency={agency} reportingPeriod={reportingPeriod} summary={summary} currency={currency} currencyConverter={currencyConverter} />
                            : <PremiumServiceSubscriptionRequired service="detailed agent reports" />}
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        {isPlayerReportEnabled() ?
                            <ReportPlayers cardroom={cardroom} agency={agency} reportingPeriod={reportingPeriod} summary={summary} currency={currency} currencyConverter={currencyConverter} />
                            : <PremiumServiceSubscriptionRequired service="detailed player reports" />}
                    </TabPanel>

                </SoftBox>
            </Grid>
        </Grid>
    </SoftBox>;
};

AgencyReport.defaultProps = {

};

AgencyReport.propTypes = {
    cardroom: PropTypes.object.isRequired,
    agency: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired,
    currencyConverter: PropTypes.object.isRequired
};

export default AgencyReport;