
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import DataTable from 'components/Elements/DataTable';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import { Card, CardActions, CardContent, DialogActions, DialogContent, DialogTitle, Grid, Icon, Switch, useMediaQuery, useTheme } from '@mui/material';
import IconCell from 'components/Elements/DataTable/components/Cells/IconCell';
import whiteCurved from "assets/images/curved-images/white-curved.jpeg";
import SoftBox from 'components/SoftBox';
import MiniStatisticsCard from 'components/Elements/MiniStatisticsCard';
import SoftTypography from 'components/SoftTypography';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import { useLayoutEffect } from 'react';
import AgentEditorDialog from '../../Agent';
import { useDialog } from 'components/Elements/Dialog/common';
import { useReportDataLoader } from './data';
import { useAgentCalculator, usePlayerCalculator } from './calculator';
import MiniActionCard from 'components/Elements/MiniActionCard';
import SoftButton from 'components/SoftButton';
import { BootstrapDialog } from 'components/Elements/Dialog/common';
import { useCardroomContext } from 'features';

const utilities = {
    getValueInActiveCurrency: function (v, calculator, currency, currencyConverter) {
        if (!v) return calculator.zero();

        var v = currencyConverter.convertFromUSD(v, currency);

        return calculator.formatDecimalAsCurrency(v, currency.precision);
    },
    player: {
        prepareForPresentation: function (players, table, filterPlayersWithNoHands, cardroom, club, agency, summary, reportingPeriod, calculator, currency, currencySymbol, currencyConverter) {
            let rows = [];

            for (var i = 0; i < players.length; i++) {

                if (filterPlayersWithNoHands === true && players[i].hands < 1) continue;

                let playerImage = players[i].name.substring(0, 1);

                let rebate = calculator.rebate(players[i]);

                rows.push({
                    name: [players[i].name, { image: playerImage }],
                    displayName: players[i].name,
                    displayNameNoCase: players[i].name.toLowerCase(),
                    nickname: players[i].nickname,
                    appPlayerId: players[i].appPlayerId,
                    agentName: players[i].agent ? players[i].agent.name : "None",
                    agentId: players[i].agent ? players[i].agent.appAgentId : "None",
                    superAgentName: players[i].agent && players[i].agent.superAgent ? players[i].agent.superAgent.name : "None",
                    superAgentId: players[i].agent && players[i].agent.superAgent ? players[i].agent.superAgent.appAgentId : "None",
                    sourceAppName: players[i].app ? players[i].app.name : "None",
                    result: players[i].result,
                    fees: players[i].fees,
                    rebate: -1 * rebate,
                    settlement: players[i].result + rebate,
                    insurance: players[i].insurance,
                    spinOffset: players[i].spinOffset,
                    jackpotFee: players[i].jackpotFee,
                    jackpotPayout: players[i].jackpotPayout,
                    jackpotPnL: players[i].jackpotFee + players[i].jackpotPayout,
                    xmttFee: players[i].xmttFee,
                    xmttResult: players[i].xmttResult,
                    xmttPnL: players[i].xmttFee + players[i].xmttResult,
                    hands: players[i].hands,
                    chipsSendOut: players[i].chipsSendout,
                    chipsClaimBack: players[i].chipsClaimback,
                    details: <ReportPlayerDetails cardroom={cardroom} club={club} reportingPeriod={reportingPeriod} summary={summary} player={players[i]} calculator={calculator} currency={currency} currencyConverter={currencyConverter} currencySymbol={currencySymbol} />,
                    player: players[i],
                    club: players[i].club
                });
            }

            if (rows.length > 0) {
                rows.sort(function (a, b) {
                    if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                    if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                    return 0;
                });
            }

            table.rows = rows;

            return table;
        },
        getTableSchema: function (showAgent, showClub, calculator, currency, currencySymbol, currencyConverter, showPlayerRefund) {

            function getValueInActiveCurrency(v) {
                return utilities.getValueInActiveCurrency(v, calculator, currency, currencyConverter);
            }

            let schema = {
                columns: [
                    {
                        Header: "Player",
                        accessor: "name",
                        Cell: ({ value: [name, data], row }) => (
                            <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} participant={row && row.original && row.original.player ? row.original.player : null} />
                        ),
                    },
                    { Header: "Player Id", accessor: "appPlayerId", Cell: ({ value }) => <TextCell value={value} /> },
                    { Header: "Hands Played", accessor: "hands", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                    { Header: "Settlement", accessor: "settlement", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                    { Header: "Result", accessor: "result", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                    { Header: "Fees", accessor: "fees", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                    { Header: "Insurance", accessor: "insurance", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                    { Header: "Spin Offset", accessor: "spinOffset", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                    { Header: "Jackpot P&L", accessor: "jackpotPnL", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                    { Header: "XMTT P&L", accessor: "xmttPnL", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                    { Header: "Chips Send Out", accessor: "chipsSendOut", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="" useColorScheme={false} /> },
                    { Header: "Chips Claim Back", accessor: "chipsClaimBack", Cell: ({ value }) => <MoneyCell value={value.toFixed(2)} prefix="" useColorScheme={false} /> },
                ],
                collapsibleDetailAccessor: "details",
                rows: []
            };

            if (showPlayerRefund === true) {
                schema.columns.splice(5, 0, { Header: "Rebate", accessor: "rebate", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> });
            }

            if (showClub === true) {
                schema.columns.push({ Header: "Club", accessor: "club", Cell: ({ value }) => <TextCell value={value.name} /> });
            }

            if (showAgent === true) {
                schema.columns.push({ Header: "Agent", accessor: "agentName", Cell: ({ value }) => <TextCell value={value} /> });
            }

            return schema;
        }
    },
    agent: {
        prepareForPresentation: function (players, table, filterAgentsWithNoFees, cardroom, club, agency, summary, reportingPeriod, agentCalculator, currency, currencySymbol, currencyConverter) {

            function calculateAgentFees(agent) {
                return 0;//no need to calc now, but if we start supporting agent/superagent commission then we want this
            }

            function calculateAgentComission(agent) {
                return 0; //no need to calc now, but if we start supporting agent/superagent commission then we want this
            }

            const agents = agentCalculator.getEffectiveAgentList(players, cardroom, agency, false);
            let rows = [];

            for (var i = 0; i < agents.length; i++) {

                if (cardroom.type == 5 && agency && agency.id == agents[i].id) {
                    continue; //skip self
                }

                let agentImage = agents[i].name.substring(0, 1);

                let numbers = agentCalculator.playerAggregates(agents[i]);

                let agentCommission = calculateAgentComission(agents[i]);
                let playerCommission = agentCalculator.comission(agents[i], numbers);

                let chipOffset = agentCalculator.chipOffset(agents[i], numbers);
                let taxRebate = agentCalculator.taxRebate(agents[i], numbers);

                let row = {
                    name: [agents[i].name, { image: agentImage }],
                    agentId: agents[i].appAgentId,
                    displayName: agents[i].name,
                    displayNameNoCase: agents[i].name.toLowerCase(),
                    agentCount: agents[i].agents ? agents[i].agents.length : 0,
                    playerCount: agents[i].effPlayers ? agents[i].effPlayers.length : 0,
                    playerFees: numbers.fees,
                    playerResults: numbers.results,
                    playerXmtt: numbers.xmttFee + numbers.xmttResult,
                    playerXmttFee: numbers.xmttFee,
                    playerXmttResult: numbers.xmttResult,
                    playerJackpot: numbers.jackpotFee - numbers.jackpotPayout,
                    agentFees: calculateAgentFees(agents[i]),
                    playerCommision: playerCommission,
                    agentCommission: agentCommission,
                    agentSettlement: playerCommission + agentCommission + numbers.results + chipOffset + taxRebate, // + numbers.xmttResult + numbers.jackpotPayout,
                    totalComission: 0,
                    chipOffset: chipOffset,
                    taxRebate: taxRebate,
                    players: <ReportAgentPlayers cardroom={cardroom} club={club} agency={agency} reportingPeriod={reportingPeriod} summary={summary} agent={agents[i]} calculator={agentCalculator} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} />,
                    agent: agents[i]
                };

                row.totalComission = row.agentCommission + row.playerCommision;


                if (filterAgentsWithNoFees && row.playerFees == 0 && row.agentFees == 0 && row.totalComission == 0) {
                    continue;
                }

                rows.push(row);
            }



            if (rows.length > 0) {
                rows.sort(function (a, b) {
                    if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                    if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                    return 0;
                });
            }

            table.rows = rows;

            return table;
        },
        getTableSchema: function (onAgentClick, cardroom, calculator, currency, currencySymbol, currencyConverter) {

            function getValueInActiveCurrency(v) {
                return utilities.getValueInActiveCurrency(v, calculator, currency, currencyConverter);
            }

            //do we know if we're in "root" report or child report? if child report, we shouldn't display comission as that's club level detail, not union
            let schema = {
                columns: [
                    {
                        Header: "Agent",
                        accessor: "name",
                        Cell: ({ value: [name, data], row }) => (
                            <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} onClick={onAgentClick} participant={row && row.original && row.original.agent ? row.original.agent : null} />
                        ),
                    },
                    { Header: "Agent Id", accessor: "agentId", Cell: ({ value }) => <TextCell value={value} /> },
                    { Header: "Fees", accessor: "playerFees", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> },
                ],
                collapsibleDetailAccessor: "players",
                rows: []
            };

            if (cardroom.type === 2 || cardroom.type === 5 || cardroom.type === 6) {
                //show commission only when viewing from CLUB pov, when union's viewing, no commission to display as that's not UNIONs concern
                schema.columns.push({
                    Header: "Commission", accessor: "playerCommision", Cell: ({ value }) => {
                        if (value || value === 0) return <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} />;

                        return <div style={{ whiteSpace: "nowrap" }}>{value}<IconCell value="invalid config" icon="manage_accounts" color="error" /></div>
                    }
                });
            }

            schema.columns.push({ Header: "Results", accessor: "playerResults", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> });
            schema.columns.push({ Header: "Xmtt Result", accessor: "playerXmttResult", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> });
            schema.columns.push({ Header: "Jackpot P&L", accessor: "playerJackpot", Cell: ({ value }) => <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} /> });
            schema.columns.push({ Header: "Players", accessor: "playerCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> });

            if (cardroom.type === 2 || cardroom.type === 5) {
                //show commission only when viewing from CLUB pov, when union's viewing, no commission to display as that's not UNIONs concern

                schema.columns.push({
                    Header: "Chip Offset", accessor: "chipOffset", Cell: ({ value }) => {
                        return <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} />;
                    }
                });

                schema.columns.push({
                    Header: "Tax / Rebate", accessor: "taxRebate", Cell: ({ value }) => {
                        return <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} />;
                    }
                });

                schema.columns.push({
                    Header: "Settlement", accessor: "agentSettlement", Cell: ({ value }) => {
                        return <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} />;
                    }
                });
            }

            return schema;
        }
    }
};


const ReportAgentPlayers = ({ cardroom, club, agency, reportingPeriod, summary, agent, calculator, currency, currencySymbol, currencyConverter }) => {

    const switchToMobileOnSize = "md";

    const theme = useTheme();
    const isMobileUi = !useMediaQuery(theme.breakpoints.up(switchToMobileOnSize));
    const isXs = !useMediaQuery(theme.breakpoints.up("sm"));


    const [playerTable, setPlayerTable] = useState(null);
    const [players, setPlayers] = useState(agent.effPlayers ? agent.effPlayers : agent.players);

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading players...", null);

    const playerClaculator = usePlayerCalculator();

    useLayoutEffect(() => {
        if (players && !playerTable) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading players...")
            showProgress();
            setPlayerTable(utilities.player.prepareForPresentation(players, utilities.player.getTableSchema(cardroom.type !== 5, cardroom.type === 5, playerClaculator, currency, currencySymbol, currencyConverter, true), false, cardroom, club, agency, summary, reportingPeriod, playerClaculator, currency, currencySymbol, currencyConverter));
            progressIndicatorProps.close();

        }
    }, [players, playerTable]);

    function renderMobileRow(columns, row, key, collapsibleDetailAccessor) {
        const color = "secondary";

        function getMoneyColor(v) {
            if (v > 0) return "success";

            if (v < 0) return "error";

            return "secondary";
        }

        function getValueInActiveCurrency(v) {
            return utilities.getValueInActiveCurrency(v, calculator, currency, currencyConverter);
        }



        return <Card
            sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
                backgroundImage: gradients[color]
                    ? `${linearGradient(
                        rgba(gradients[color].main, 0.85),
                        rgba(gradients[color].state, 0.85)
                    )}, url(${whiteCurved})`
                    : `${linearGradient(
                        rgba(gradients[color].main, 0.85),
                        rgba(gradients[color].state, 0.85)
                    )}, url(${whiteCurved})`,
                backgroundSize: "cover",
                backgroundPosition: "50%",
                overflow: "visible",
            })}
        >
            <CardContent>
                <SoftBox pt={1} textAlign="center">
                    <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: row.cells[1].value, fontWeight: "medium" }}
                        count={row.cells[0].value[0]}
                        icon={{ color: "dark", component: "person" }}
                        direction={"left"}
                        useNumericFormatter={false}
                    />
                </SoftBox>

                <SoftBox pt={1} textAlign="center">
                    <Grid container ml="auto" mr="auto" xs={12}>
                        <Grid item xs={6} pr={0.25} >
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "fees", fontWeight: "medium" }}
                                count={{ text: getValueInActiveCurrency(row.original.fees), color: getMoneyColor(row.original.fees), useNumberFormatter: true, prefix: currencySymbol }}
                                icon={isXs ? null : { color: "secondary", component: "percent" }}
                                direction="left"
                            />
                        </Grid>
                        <Grid item xs={6} pl={0.25} pr={0.25} >
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "winnings", fontWeight: "medium" }}
                                count={{ text: getValueInActiveCurrency(row.original.result), color: getMoneyColor(row.original.result), useNumberFormatter: true, prefix: currencySymbol }}
                                icon={isXs ? null : { color: "secondary", component: "emoji_events" }}
                            />
                        </Grid>
                    </Grid>
                </SoftBox>

                <SoftBox pt={1} textAlign="center">
                    <Grid container ml="auto" mr="auto" xs={12}>
                        <Grid item xs={6} pr={0.25} >
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "insurance", fontWeight: "medium" }}
                                count={{ text: getValueInActiveCurrency(row.original.insurance), color: getMoneyColor(row.original.insurance), useNumberFormatter: true, prefix: currencySymbol }}
                                icon={isXs ? null : { color: "secondary", component: "policy" }}
                                direction="left"
                            />
                        </Grid>
                        <Grid item xs={6} pl={0.25} pr={0.25} >
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "spin offset", fontWeight: "medium" }}
                                count={{ text: getValueInActiveCurrency(row.original.spinOffset), color: getMoneyColor(row.original.spinOffset), useNumberFormatter: true, prefix: currencySymbol }}
                                icon={isXs ? null : { color: "secondary", component: "album" }}
                            />
                        </Grid>
                    </Grid>
                </SoftBox>

                <SoftBox pt={1} textAlign="center">
                    <Grid container ml="auto" mr="auto" xs={12}>
                        <Grid item xs={6} pr={0.25} >
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "jackpot", fontWeight: "medium" }}
                                count={{ text: getValueInActiveCurrency(row.original.jackpotPnL), color: getMoneyColor(row.original.jackpotPnL), useNumberFormatter: true, prefix: currencySymbol }}
                                icon={isXs ? null : { color: "secondary", component: "savings" }}
                                direction="left"
                            />
                        </Grid>
                        <Grid item xs={6} pl={0.25} pr={0.25} >
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "xmtt P&L", fontWeight: "medium" }}
                                count={{ text: getValueInActiveCurrency(row.original.xmttPnL), color: getMoneyColor(row.original.xmttPnL), useNumberFormatter: true, prefix: currencySymbol }}
                                icon={isXs ? null : { color: "secondary", component: "diversity_1" }}
                            />
                        </Grid>
                    </Grid>
                </SoftBox>

                <SoftBox pt={1} textAlign="center">
                    <Grid container ml="auto" mr="auto" xs={12}>
                        <Grid item xs={6} pr={0.25} >
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "chips send out", fontWeight: "medium" }}
                                count={{ text: calculator.formatDecimalAsCurrency(row.original.chipsSendOut), color: "secondary", useNumberFormatter: true, prefix: "" }}
                                icon={isXs ? null : { color: "secondary", component: "send" }}
                                direction="left"
                            />
                        </Grid>
                        <Grid item xs={6} pl={0.25} pr={0.25} >
                            <MiniActionCard
                                bgColor="white"
                                title={{ text: "chips claim back", fontWeight: "medium" }}
                                count={{ text: calculator.formatDecimalAsCurrency(row.original.chipsClaimBack), color: "secondary", useNumberFormatter: true, prefix: "" }}
                                icon={isXs ? null : { color: "secondary", component: "call_received" }}
                            />
                        </Grid>
                    </Grid>
                </SoftBox>

                <SoftBox pt={1} textAlign="center">
                    <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: "total hands played", fontWeight: "medium" }}
                        count={row.original.hands}
                        icon={{ color: "secondary", component: "back_hand" }}
                        direction="left"
                        useNumericFormatter={true}
                    />
                </SoftBox>
            </CardContent>
        </Card >;
    }

    return (<Card p={1}>
        <ProgressIndicator {...progressIndicatorProps} />
        {playerTable ? <DataTable table={playerTable} entriesPerPage={{ defaultValue: 5000, visible: false }} showTotalEntries={false} isHierarchical={false}
            onRenderRow={renderMobileRow} useCustomRenderSize={switchToMobileOnSize}
        /> : null}
    </Card>);
}

const ChildContainerDialog = ({ open, close, title, children }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xxl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    {title}
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <SoftBox m={1}>
                <Grid container spacing={1} justifyContent={"center"} xs={12}>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </SoftBox>
        </DialogContent>
        <DialogActions>
            <SoftButton onClick={close} variant="outlined" color="secondary">
                close
            </SoftButton>
        </DialogActions>
    </BootstrapDialog >
};

const ReportAgentMobileCard = ({ cardroom, row, club, agency, agent, reportingPeriod, summary, calculator, canEditAgent, onOpenAgentEditor, currency, currencyConverter, currencySymbol }) => {

    const color = "secondary";
    const theme = useTheme();
    const isXs = !useMediaQuery(theme.breakpoints.up("sm"));

    function getMoneyColor(v) {
        if (v > 0) return "success";

        if (v < 0) return "error";

        return "secondary";
    }

    function getCommissionValue() {
        const value = row.original.playerCommision;

        if (value || value === 0) return getValueInActiveCurrency(value);

        return "Not Configured";
    }

    function getComissionPrefix() {
        return getCommissionValue() === "Not Configured" ? "" : currencySymbol;
    }
    function relyOnCommissionFormatter() {
        return getCommissionValue() !== "Not Configured";
    }

    function getCommissionBadgeColor() {
        return getCommissionValue() === "Not Configured" ? "error" : "secondary";
    }

    function getValueInActiveCurrency(v) {
        return utilities.getValueInActiveCurrency(v, calculator, currency, currencyConverter);
    }

    let buttonGridSize = 12;
    if (canEditAgent()) buttonGridSize = 6;

    const [openPlayerDetailsDialog, playerDetailsDialogProps] = useDialog();

    return <Card
        sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
            backgroundImage: gradients[color]
                ? `${linearGradient(
                    rgba(gradients[color].main, 0.85),
                    rgba(gradients[color].state, 0.85)
                )}, url(${whiteCurved})`
                : `${linearGradient(
                    rgba(gradients[color].main, 0.85),
                    rgba(gradients[color].state, 0.85)
                )}, url(${whiteCurved})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "visible",
        })}
    >
        <CardContent>
            <ChildContainerDialog {...playerDetailsDialogProps} title={"Agent's Players"} children={<ReportAgentPlayers cardroom={cardroom} club={club} agency={agency} reportingPeriod={reportingPeriod} summary={summary} agent={agent} calculator={calculator} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} />} />
            <SoftBox pt={1} textAlign="center">
                <MiniStatisticsCard
                    bgColor="white"
                    title={{ text: row.cells[1].value, fontWeight: "medium" }}
                    count={row.cells[0].value[0]}
                    icon={{ color: "dark", component: "support_agent" }}
                    direction={"left"}
                    useNumericFormatter={false}
                />
            </SoftBox>

            <SoftBox pt={1} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12}>
                    <Grid item xs={6} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "players", fontWeight: "medium" }}
                            count={{ text: row.original.playerCount, color: getMoneyColor(row.original.playerCount), useNumberFormatter: true, prefix: "" }}
                            icon={isXs ? null : { color: "secondary", component: "people" }}
                            direction="left"
                        />
                    </Grid>
                    <Grid item xs={6} pl={0.25} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "agents", fontWeight: "medium" }}
                            count={{ text: row.original.agentCount, color: getMoneyColor(row.original.agentCount), useNumberFormatter: true, prefix: "" }}
                            icon={isXs ? null : { color: "secondary", component: "support_agent" }}
                            direction="right"
                        />
                    </Grid>

                </Grid>
            </SoftBox>

            {(cardroom.type === 2 || cardroom.type === 5 || cardroom.type === 6) ? <SoftBox pt={1} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12}>
                    <Grid item xs={6} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "commission", fontWeight: "medium" }}
                            count={{ text: getCommissionValue(), color: getMoneyColor(row.original.playerCommision), useNumberFormatter: relyOnCommissionFormatter(), prefix: getComissionPrefix() }}
                            icon={isXs ? null : { color: getCommissionBadgeColor(), component: "monetization_on" }}
                            direction="left"
                        />
                    </Grid>
                    <Grid item xs={6} pl={0.25} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "settlement", fontWeight: "medium" }}
                            count={{ text: getValueInActiveCurrency(row.original.agentSettlement), color: getMoneyColor(row.original.agentSettlement), useNumberFormatter: true, prefix: currencySymbol }}
                            icon={isXs ? null : { color: "secondary", component: "balance" }}
                            direction="right"
                        />
                    </Grid>
                </Grid>
            </SoftBox> : null}


            <SoftBox pt={1} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12}>
                    <Grid item xs={6} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "fees", fontWeight: "medium" }}
                            count={{ text: getValueInActiveCurrency(row.original.playerFees), color: getMoneyColor(row.original.playerFees), useNumberFormatter: true, prefix: currencySymbol }}
                            icon={isXs ? null : { color: "secondary", component: "percent" }}
                            direction="left"
                        />
                    </Grid>
                    <Grid item xs={6} pl={0.25} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "winnings", fontWeight: "medium" }}
                            count={{ text: getValueInActiveCurrency(row.original.playerResults), color: getMoneyColor(row.original.playerResults), useNumberFormatter: true, prefix: currencySymbol }}
                            icon={isXs ? null : { color: "secondary", component: "emoji_events" }}
                        />
                    </Grid>
                </Grid>
            </SoftBox>

            <SoftBox pt={1} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12}>
                    <Grid item xs={6} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "xmtt result", fontWeight: "medium" }}
                            count={{ text: getValueInActiveCurrency(row.original.playerXmttResult), color: getMoneyColor(row.original.playerXmttResult), useNumberFormatter: true, prefix: currencySymbol }}
                            icon={isXs ? null : { color: "secondary", component: "diversity_1" }}
                            direction="left"
                        />
                    </Grid>
                    <Grid item xs={6} pl={0.25} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "jackpot P&L", fontWeight: "medium" }}
                            count={{ text: getValueInActiveCurrency(row.original.playerJackpot), color: getMoneyColor(row.original.playerJackpot), useNumberFormatter: true, prefix: currencySymbol }}
                            icon={isXs ? null : { color: "secondary", component: "savings" }}
                        />
                    </Grid>
                </Grid>
            </SoftBox>

            {(cardroom.type === 2 || cardroom.type === 5) ? <SoftBox pt={1} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12}>
                    <Grid item xs={6} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "chip offset", fontWeight: "medium" }}
                            count={{ text: getValueInActiveCurrency(row.original.chipOffset), color: getMoneyColor(row.original.chipOffset), useNumberFormatter: true, prefix: currencySymbol }}
                            icon={isXs ? null : { color: "secondary", component: "calculate" }}
                            direction="left"
                        />
                    </Grid>
                    <Grid item xs={6} pl={0.25} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "tax / rebate", fontWeight: "medium" }}
                            count={{ text: getValueInActiveCurrency(row.original.taxRebate), color: getMoneyColor(row.original.taxRebate), useNumberFormatter: true, prefix: currencySymbol }}
                            icon={isXs ? null : { color: "secondary", component: "account_balance" }}
                            direction="right"
                        />
                    </Grid>
                </Grid>
            </SoftBox> : null}
        </CardContent>
        <CardActions>
            <Grid container xs={12}>
                {canEditAgent() ?
                    <Grid item xs={buttonGridSize} pr={.25} pl={1.25}>
                        <SoftButton ml="auto" mr="auto"
                            variant="gradient"
                            color={"light"}
                            fullWidth
                            disabled={!canEditAgent()}
                            onClick={() => onOpenAgentEditor(agent)}
                        >
                            SETTINGS&nbsp;
                            <Icon sx={{ fontWeight: "bold" }}>settings</Icon>
                        </SoftButton>
                    </Grid> : null}

                <Grid item xs={buttonGridSize} pl={0.25} pr={1.25}>
                    <SoftButton ml="auto" mr="auto"
                        variant="gradient"
                        color={"dark"}
                        fullWidth
                        onClick={openPlayerDetailsDialog}
                    >
                        PLAYERS&nbsp;
                        <Icon sx={{ fontWeight: "bold" }}>group</Icon>
                    </SoftButton>
                </Grid>
            </Grid>
        </CardActions>

    </Card >;
}

const ReportAgents = ({ cardroom, club, agency, reportingPeriod, summary, currency, currencyConverter }) => {

    const switchToMobileOnSize = "md";

    const theme = useTheme();
    const isMobileUi = !useMediaQuery(theme.breakpoints.up(switchToMobileOnSize));

    const [context, actions, features] = useCardroomContext(cardroom);

    const [currencySymbol, setCurrencySymbol] = useState(null);

    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    useEffect(() => {
        if (true === isCurrencyMaskingEnabled()) {
            setCurrencySymbol("");
            return;
        }

        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency, currencyConverter]);

    const [agentTable, setAgentTable] = useState(null);
    const [players, setPlayers] = useState(null);

    const [currentAgent, setCurrentAgent] = useState(null);
    const [openAgentEditorDialog, openAgentEditorDialogProps] = useDialog();

    function openAgentEditor(agent) {
        if (!canEditAgent()) return;

        if (agent) {
            setCurrentAgent(agent);
            openAgentEditorDialog();
        }
    }

    function canEditAgent() {
        //edit only if reportingPeriod casinoId owns this specific club???
        //summary.club must be owed by cardroom?
        //        return cardroom.id === reportingPeriod.casinoId;
        //TODO: Add a way to check for current context to make sure we're inside the owner casino
        //add permissions/features check via useContext(cardroom);

        if (cardroom.type !== 2) return false;

        return true;
    }

    const [retryCounter, setRetryCounter] = useState(0);
    function handleProgressRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading agents...", handleProgressRetry);

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const dataLoader = useReportDataLoader();

    useEffect(() => {
        if (!players) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading agents...")
            showProgress();

            dataLoader.loadPlayers(clubRequest, cardroom, reportingPeriod, summary, club, agency, true, (response) => {
                setPlayers(response);
                setAgentTable(null);
                progressIndicatorProps.close();
            }, (err) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load agents, plese try again...");
            });

        }
    }, [clubRequest.execute, players, retryCounter])

    const agentCalculator = useAgentCalculator();

    useEffect(() => {
        if (players && !agentTable) {
            setAgentTable(utilities.agent.prepareForPresentation(players, utilities.agent.getTableSchema(canEditAgent() ? openAgentEditor : null, cardroom, agentCalculator, currency, currencySymbol, currencyConverter), agentsWithFeesOnly, cardroom, club, agency, summary, reportingPeriod, agentCalculator, currency, currencySymbol, currencyConverter));
            progressIndicatorProps.close();
        }
    }, [players, agentTable])


    const [agentsWithFeesOnly, setAgentsWithFeesOnly] = useState(true);
    const [tableRefreshNeeded, setTableRefreshNeeded] = useState(false);

    function onAgentsWithFeesOnlySwitchToggle() {
        let newValue = !agentsWithFeesOnly;
        setAgentsWithFeesOnly(newValue);
        setTableRefreshNeeded(true);
    }

    function onAgentPropertiesChanged(agent) {

        //setAgentTable(null);
        setTableRefreshNeeded(true);
    }

    useLayoutEffect(() => {
        if (players && agentTable && tableRefreshNeeded) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Filtering agents...")
            showProgress();

            setAgentTable(null);
            setTableRefreshNeeded(false);

            //progressIndicatorProps.close();
        }
    }, [agentsWithFeesOnly, tableRefreshNeeded, players, agentTable])


    function renderMobileRow(columns, row, key, collapsibleDetailAccessor) {
        return <ReportAgentMobileCard cardroom={cardroom} agent={row.original.agent} row={row} club={club} agency={agency} reportingPeriod={reportingPeriod} summary={summary}
            calculator={agentCalculator} canEditAgent={canEditAgent} onOpenAgentEditor={openAgentEditor}
            currency={currency} currencyConverter={currencyConverter} currencySymbol={currencySymbol} />;

    }

    const filter = <Grid container xs={12} justifyContent={isMobileUi ? "left" : "center"} ml={isMobileUi ? 0 : 5}>
        <Grid item>
            <SoftBox mt={0.25} >
                <Switch checked={agentsWithFeesOnly} onChange={onAgentsWithFeesOnlySwitchToggle} />
            </SoftBox>
        </Grid>
        <Grid item>
            <SoftBox ml={1} display="inlineBox">
                <SoftTypography component="label" whiteSpace="nowrap"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    {isMobileUi ? "Agents with fees > 0" : "Show only agents that have earned fees"}
                </SoftTypography>
            </SoftBox></Grid>
    </Grid >;

    return (<SoftBox>
        <ProgressIndicator {...progressIndicatorProps} />
        <AgentEditorDialog {...openAgentEditorDialogProps} cardroom={cardroom} club={club} agent={currentAgent} onAgentUpdated={onAgentPropertiesChanged} />
        {agentTable ? <DataTable table={agentTable} canSearch entriesPerPage={{ defaultValue: 10, visible: !isMobileUi }} isHierarchical={true} widget={filter}
            onRenderRow={renderMobileRow} useCustomRenderSize={switchToMobileOnSize}
        /> : null}
    </SoftBox>);

};

ReportAgents.propTypes = {
    cardroom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired
};


const ReportPlayerDetails = ({ cardroom, reportingPeriod, club, agency, summary, player, calculator, currency, currencySymbol, currencyConverter }) => {

    function prepareForPresentation(details, table) {
        let rows = [];

        if (details && details.details) {
            details = details.details;
        }

        if (!details) return null;



        function createItem(groups, key) {

            function getKeyName(k) {
                switch (k) {
                    case "sixPlus":
                        return "6+";
                    case "leaderboard":
                        return "Leaderboard";
                    case "spin":
                        return "Spin"
                    case "ploHi":
                        return "PLO Hi";
                    case "ploHiLo":
                        return "PLO Hi/Lo";
                    case "floHi":
                        return "FLO Hi";
                    case "floHiLo":
                        return "FLO Hi/Lo";
                }

                if (k) return k.toUpperCase();

                return "";
            }

            function getGroupKeyValue(group, key, groups) {

                if (group == "SpinOffset" && key != "nlh" && key != "ploHi") {
                    return null;
                }

                if (group == "Insurance" && key != "nlh" && key != "ploHi" && key != "flh" && key != "sixPlus") {
                    return null;
                }

                if ((group == "Hands" || group == "Fees") && key == "leaderboard") {
                    return null;
                }

                return groups[group] ? groups[group][key] : null;

            }

            return {
                key: key,
                name: getKeyName(key),
                hands: getGroupKeyValue("Hands", key, groups), //groups["Hands"] ? groups["Hands"][key] : null,
                wins: getGroupKeyValue("Wins", key, groups), //groups["Wins"] ? groups["Wins"][key] : null,
                fees: getGroupKeyValue("Fees", key, groups), //groups["Fees"] ? groups["Fees"][key] : null,
                insurance: getGroupKeyValue("Insurance", key, groups), //groups["Insurance"] ? groups["Insurance"][key] : null,
                spin: getGroupKeyValue("SpinOffset", key, groups) //groups["SpinOffset"] ? groups["SpinOffset"][key] : null
            };
        }

        var map = [];
        for (var i = 0; i < details.length; i++) {
            map[details[i].groupName] = details[i].data;
        }

        let keys = ["nlh", "flh", "sixPlus", "ploHi", "ploHiLo", "floHi", "floHiLo", "mixed", "ofc", "sng", "mtt", "xmtt", "spin", "leaderboard"];
        for (var i = 0; i < keys.length; i++) {
            rows.push(createItem(map, keys[i]));
        }

        table.rows = rows;

        return table;
    }

    function getTableSchema() {

        function getValueInActiveCurrency(v) {
            // if (!playerClaculator) alert("PLAYER CALC");
            // if (!currency) alert("currency");
            // if (!currencyConverter) alert("currencyConverter");
            return utilities.getValueInActiveCurrency(v, calculator, currency, currencyConverter);
        }

        return {
            columns: [
                { Header: "", accessor: "name", Cell: ({ value }) => <TextCell value={value} /> },
                {
                    Header: "Hands Played", accessor: "hands", Cell: ({ value }) => {

                        if (value || value === 0)
                            return <MoneyCell value={value} prefix="" useColorScheme={false} />;

                        return <IconCell icon="block" color="info" />;
                    }
                },
                {
                    Header: "Winnings", accessor: "wins", Cell: ({ value }) => {

                        if (value || value === 0)
                            return <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} />;

                        return <IconCell icon="block" color="info" />;
                    }
                },
                {
                    Header: "Fees", accessor: "fees", Cell: ({ value }) => {

                        if (value || value === 0)
                            return <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} />;

                        return <IconCell icon="block" color="info" />;
                    }
                },
                {
                    Header: "Insurance", accessor: "insurance", Cell: ({ value }) => {

                        if (value || value === 0)
                            return <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} />;

                        return <IconCell icon="block" color="info" />;
                    }
                },
                {
                    Header: "Spin Offset", accessor: "spin", Cell: ({ value }) => {

                        if (value || value === 0)
                            return <MoneyCell value={getValueInActiveCurrency(value)} prefix={currencySymbol} useColorScheme={true} />;

                        return <IconCell icon="block" color="info" />;
                    }
                }
            ],
            rows: []
        };
    }


    const [playerDetailsTable, setPlayerDetailsTable] = useState(null);
    const [playerDetails, setPlayerDetails] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);
    function handleProgressRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading player details...", handleProgressRetry);
    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    useEffect(() => {
        if (!playerDetails) {


            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading player details...")
            showProgress();

            var c = club || player.club;

            //[HttpGet("casino/{casinoId:Guid}/reports/{reportingPeriodId:Guid}/players/{playerId:Guid}", Name = "GetReportingPeriodPlayerDetails")]
            clubRequest.execute("GET", apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/players/" + player.id + (c ? "?clubId=" + c.id : "")).then((response) => {
                if (response) {

                    setPlayerDetails(response);
                    setPlayerDetailsTable(null);
                }
            }).catch((ex) => {
                //setOverlayMessage("An error occured, please refresh");
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load player details, plese try again...");
            });

        }
    }, [clubRequest.execute, playerDetails, retryCounter]);

    useEffect(() => {
        if (playerDetails && !playerDetailsTable) {
            //load tables
            setPlayerDetailsTable(prepareForPresentation(playerDetails, getTableSchema()));
            progressIndicatorProps.close();
        }

    }, [playerDetails, playerDetailsTable]);


    return (<Card p={1}>
        <ProgressIndicator {...progressIndicatorProps} />
        {playerDetailsTable ? <DataTable table={playerDetailsTable} entriesPerPage={{ defaultValue: 5000, visible: false }} showTotalEntries={false} isHierarchical={false} /> : null}
    </Card>);
}

const ReportPlayerMobileCard = ({ cardroom, row, club, agency, player, reportingPeriod, summary, calculator, currency, currencySymbol, currencyConverter }) => {

    const color = "secondary";

    const theme = useTheme();
    const isXs = !useMediaQuery(theme.breakpoints.up("sm"));

    const [openPlayerDetailsDialog, playerDetailsDialogProps] = useDialog();

    function getMoneyColor(v) {
        if (v > 0) return "success";

        if (v < 0) return "error";

        return "secondary";
    }

    function getValueInActiveCurrency(v) {
        return utilities.getValueInActiveCurrency(v, calculator, currency, currencyConverter);
    }

    return <Card
        sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
            backgroundImage: gradients[color]
                ? `${linearGradient(
                    rgba(gradients[color].main, 0.85),
                    rgba(gradients[color].state, 0.85)
                )}, url(${whiteCurved})`
                : `${linearGradient(
                    rgba(gradients[color].main, 0.85),
                    rgba(gradients[color].state, 0.85)
                )}, url(${whiteCurved})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "visible",
        })}
    >
        <CardContent>
            <ChildContainerDialog {...playerDetailsDialogProps} title={"Play Details: " + player.name} children={<ReportPlayerDetails cardroom={cardroom} club={club} agency={agency} reportingPeriod={reportingPeriod} summary={summary} player={player} calculator={calculator} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} />} />
            <SoftBox pt={1} textAlign="center">
                <MiniStatisticsCard
                    bgColor="white"
                    title={{ text: row.cells[1].value, fontWeight: "medium" }}
                    count={row.cells[0].value[0]}
                    icon={{ color: "dark", component: "support_agent" }}
                    direction={"left"}
                    useNumericFormatter={false}
                />
            </SoftBox>


            <SoftBox pt={1} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12}>
                    {cardroom.type === 5 ?
                        <Grid item xs={6} pr={0.25} >
                            <MiniStatisticsCard
                                bgColor="white"
                                title={{ text: "club", fontWeight: "medium" }}
                                count={row.original.player.club.name}
                                icon={isXs ? null : { color: "secondary", component: "nightlife" }}
                                direction={"left"}
                                useNumericFormatter={false}
                            />
                        </Grid> : null}

                    <Grid item xs={cardroom.type === 5 ? 6 : 12} pr={0.25} >
                        <MiniStatisticsCard
                            bgColor="white"
                            title={{ text: "total hands played", fontWeight: "medium" }}
                            count={row.original.hands}
                            icon={cardroom.type === 5 && isXs ? null : { color: "secondary", component: "back_hand" }}
                            direction={cardroom.type === 5 ? "right" : "left"}
                            useNumericFormatter={true}
                        /></Grid>
                </Grid>
            </SoftBox>

            <SoftBox pt={1} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12}>
                    <Grid item xs={12} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "settlement", fontWeight: "medium" }}

                            count={{ text: getValueInActiveCurrency(row.original.settlement), color: getMoneyColor(row.original.settlement), useNumberFormatter: true, prefix: currencySymbol }}
                            icon={{ color: "secondary", component: "payment" }}
                            direction="left"
                        />
                    </Grid>
                </Grid>
            </SoftBox>

            <SoftBox pt={1} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12}>
                    <Grid item xs={6} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "fees", fontWeight: "medium" }}
                            count={{ text: getValueInActiveCurrency(row.original.fees), color: getMoneyColor(row.original.fees), useNumberFormatter: true, prefix: currencySymbol }}
                            icon={isXs ? null : { color: "secondary", component: "percent" }}
                            direction="left"
                        />
                    </Grid>
                    <Grid item xs={6} pl={0.25} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "winnings", fontWeight: "medium" }}
                            count={{ text: getValueInActiveCurrency(row.original.result), color: getMoneyColor(row.original.result), useNumberFormatter: true, prefix: currencySymbol }}
                            icon={isXs ? null : { color: "secondary", component: "emoji_events" }}
                        />
                    </Grid>
                </Grid>
            </SoftBox>

            <SoftBox pt={1} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12}>
                    <Grid item xs={12} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "player rebate", fontWeight: "medium" }}

                            count={{ text: getValueInActiveCurrency(row.original.rebate), color: getMoneyColor(row.original.rebate), useNumberFormatter: true, prefix: currencySymbol }}
                            icon={{ color: "secondary", component: "request_quote" }}
                            direction="left"
                        />
                    </Grid>
                </Grid>
            </SoftBox>


            <SoftBox pt={1} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12}>
                    <Grid item xs={6} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "insurance", fontWeight: "medium" }}
                            count={{ text: getValueInActiveCurrency(row.original.insurance), color: getMoneyColor(row.original.insurance), useNumberFormatter: true, prefix: currencySymbol }}
                            icon={isXs ? null : { color: "secondary", component: "policy" }}
                            direction="left"
                        />
                    </Grid>
                    <Grid item xs={6} pl={0.25} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "spin offset", fontWeight: "medium" }}
                            count={{ text: getValueInActiveCurrency(row.original.spinOffset), color: getMoneyColor(row.original.spinOffset), useNumberFormatter: true, prefix: currencySymbol }}
                            icon={isXs ? null : { color: "secondary", component: "album" }}
                        />
                    </Grid>
                </Grid>
            </SoftBox>

            <SoftBox pt={1} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12}>
                    <Grid item xs={6} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "jackpot", fontWeight: "medium" }}
                            count={{ text: getValueInActiveCurrency(row.original.jackpotPnL), color: getMoneyColor(row.original.jackpotPnL), useNumberFormatter: true, prefix: currencySymbol }}
                            icon={isXs ? null : { color: "secondary", component: "savings" }}
                            direction="left"
                        />
                    </Grid>
                    <Grid item xs={6} pl={0.25} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "xmtt P&L", fontWeight: "medium" }}
                            count={{ text: getValueInActiveCurrency(row.original.xmttPnL), color: getMoneyColor(row.original.xmttPnL), useNumberFormatter: true, prefix: currencySymbol }}
                            icon={isXs ? null : { color: "secondary", component: "diversity_1" }}
                        />
                    </Grid>
                </Grid>
            </SoftBox>

            <SoftBox pt={1} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12}>
                    <Grid item xs={6} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "chips send out", fontWeight: "medium" }}
                            count={{ text: getValueInActiveCurrency(row.original.chipsSendOut), color: "secondary", useNumberFormatter: true, prefix: "" }}
                            icon={isXs ? null : { color: "secondary", component: "send" }}
                            direction="left"
                        />
                    </Grid>
                    <Grid item xs={6} pl={0.25} pr={0.25} >
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "chips claim back", fontWeight: "medium" }}
                            count={{ text: getValueInActiveCurrency(row.original.chipsClaimBack), color: "secondary", useNumberFormatter: true, prefix: "" }}
                            icon={isXs ? null : { color: "secondary", component: "call_received" }}
                        />
                    </Grid>
                </Grid>
            </SoftBox>

        </CardContent>
        <CardActions>
            <Grid container xs={12}>
                <Grid item xs={12} pl={1.5} pr={1.5}>
                    <SoftButton ml="auto" mr="auto"
                        variant="gradient"
                        color={"dark"}
                        fullWidth
                        onClick={openPlayerDetailsDialog}
                    >
                        DETAILS&nbsp;
                        <Icon sx={{ fontWeight: "bold" }}>work_history</Icon>
                    </SoftButton>
                </Grid>
            </Grid>
        </CardActions>
    </Card >;
}

const ReportPlayers = ({ cardroom, club, agency, reportingPeriod, summary, currency, currencyConverter }) => {

    const switchToMobileOnSize = "md";

    const theme = useTheme();
    const isMobileUi = !useMediaQuery(theme.breakpoints.up(switchToMobileOnSize));

    const playerCalculator = usePlayerCalculator();

    const [playerTable, setPlayerTable] = useState(null);
    const [players, setPlayers] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);

    const [currencySymbol, setCurrencySymbol] = useState(null);

    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    useEffect(() => {
        if (true === isCurrencyMaskingEnabled()) {
            setCurrencySymbol("");
            return;
        }

        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency, currencyConverter]);

    const [retryCounter, setRetryCounter] = useState(0);
    function handleProgressRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading players...", handleProgressRetry);

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const dataLoader = useReportDataLoader();

    useEffect(() => {
        if (!players) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading agents...")
            showProgress();

            dataLoader.loadPlayers(clubRequest, cardroom, reportingPeriod, summary, club, agency, true, (response) => {
                setPlayers(response);
                setPlayerTable(null);

                progressIndicatorProps.close();
            }, (err) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load agents, plese try again...");
            });

        }
    }, [clubRequest.execute, players, retryCounter])


    useEffect(() => {
        if (players && !playerTable) {
            //merge
            setPlayerTable(utilities.player.prepareForPresentation(players, utilities.player.getTableSchema(cardroom.type === 5, cardroom.type === 5, playerCalculator, currency, currencySymbol, currencyConverter, true), playersWithHandsOnly, cardroom, club, agency, summary, reportingPeriod, playerCalculator, currency, currencySymbol, currencyConverter));
            progressIndicatorProps.close();
        }
    }, [players, playerTable])

    const [playersWithHandsOnly, setPlayersWitHandsOnly] = useState(true);
    const [tableRefreshNeeded, setTableRefreshNeeded] = useState(false);

    function onPlayersWithHandsOnlySwitchToggle() {
        let newValue = !playersWithHandsOnly;
        setPlayersWitHandsOnly(newValue);
        setTableRefreshNeeded(true);
    }

    useLayoutEffect(() => {
        if (players && playerTable && tableRefreshNeeded) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Filtering players...")
            showProgress();

            setPlayerTable(null);
            //setPlayerTable(preparePlayersForPresentation(players, GetPlayerTableSchema()));
            setTableRefreshNeeded(false);

            //progressIndicatorProps.close();
        }
    }, [playersWithHandsOnly, tableRefreshNeeded, players, playerTable])

    function renderMobileRow(columns, row, key, collapsibleDetailAccessor) {
        return <ReportPlayerMobileCard cardroom={cardroom} player={row.original.player} row={row} club={club} agency={agency} reportingPeriod={reportingPeriod} summary={summary}
            calculator={playerCalculator} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} />;

    }


    const filter = <Grid container xs={12} justifyContent={isMobileUi ? "left" : "center"} ml={isMobileUi ? 0 : 5}>
        < Grid item >
            <SoftBox mt={0.25} >
                <Switch checked={playersWithHandsOnly} onChange={onPlayersWithHandsOnlySwitchToggle} />
            </SoftBox>
        </Grid >
        <Grid item>
            <SoftBox ml={1} display="inlineBox">
                <SoftTypography component="label" whiteSpace="nowrap"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    {isMobileUi ? "Hand count > 0" : "Show only players with hand count > 0"}
                </SoftTypography>
            </SoftBox></Grid>
    </Grid >;

    return (<SoftBox>
        <ProgressIndicator {...progressIndicatorProps} />
        {playerTable ? <DataTable table={playerTable} canSearch entriesPerPage={{ defaultValue: 10, visible: !isMobileUi }} isHierarchical={true} widget={filter}
            onRenderRow={renderMobileRow} useCustomRenderSize={switchToMobileOnSize} /> : null}
    </SoftBox>);
};

ReportPlayers.propTypes = {
    cardroom: PropTypes.object.isRequired,
    club: PropTypes.object,
    agency: PropTypes.object,
    reportingPeriod: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired
};

export { ReportAgents, ReportPlayers };