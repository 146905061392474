import { useState } from "react";
import PropTypes from "prop-types";
import { Badge, Icon, IconButton, Menu, MenuItem } from "@mui/material";
import SoftTypography from "components/SoftTypography";

import {
    navbarIconButton,
} from "components/Elements/Navbar/styles";

import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { apiConfig } from "config/apiConfig";
import SoftSnackbar from "components/SoftSnackbar";
import UserInboxDialog from "..";
import { useDialog } from "components/Elements/Dialog/common";


const UrgentMessage = ({ message, onMessageDismissed }) => {
    const [toastShow, setToastShow] = useState(true);

    function toggleToast() {
        //messageHelper
        if (toastShow && onMessageDismissed) {
            onMessageDismissed(message);
        }
        setToastShow(!toastShow);
    }

    function getToastColor() {
        if (!message) return null;

        switch (message.type) {
            case 1: return "info";
            case 2: return "warning";
            case 3: return "error";
            case 4: return "success";
        }

        return "primary";
    }

    function getMessageAge() {
        if (!message) return null;

        const receivedOn = Date.parse(message.receivedOn + "Z"); //new Date(Date.parse(message.receivedOn + "Z"));
        const now = Date.now(); //Date.UTC(Date.now());
        const age = now - receivedOn;

        let ss = Math.floor(age / 1000);
        let min = Math.floor(ss / 60);
        ss -= min * 60;

        let hh = Math.floor(min / 60);
        min -= hh * 60;

        let dd = Math.floor(hh / 24);
        hh -= dd * 24;

        let str = "";
        if (dd > 0) {
            str = dd + " days ";
        }

        if (hh > 0) {
            str += hh + " hour" + (hh > 1 ? "s" : "") + " ";
        }

        if (min > 0) {
            str += min + " minute" + (min > 1 ? "s" : "") + " ";
        }

        if (!str) {
            str = ss + " seconds" + (ss > 1 ? "s" : "") + " ";
        }

        return str + "ago";
    }

    return message ? <SoftSnackbar color={getToastColor()}
        icon="notifications"
        title={message.title}
        content={message.body}
        dateTime={getMessageAge()}
        open={toastShow}
        close={toggleToast} /> : null;
};

const MessageCenter = ({ account, light }) => {

    //return null;
    const location = useLocation();
    const [cardroom, setCardroom] = useState(location.state?.cardroom);

    const [messages, setMessages] = useState(null);
    const [unreadMessages, setUnreadMessages] = useState(null);
    const [readMessages, setReadMessages] = useState(null);
    const [snoozedMessages, setSnoozedMessages] = useState(null);
    const [deletedMessages, setDeletedMessages] = useState(null);

    const [urgentMessages, setUrgentMessages] = useState(null);

    const [fetching, setFetching] = useState(false);

    const userRequest = useFetchWithMsal({
        scopes: apiConfig.user.scopes.read,
    });

    const [pendingMessageQueue, setPendingMessageQueue] = useState([]);

    const [pendingMessage, setPendingMessage] = useState(null);

    useEffect(() => {
        if (pendingMessage) {
            // [HttpPut("venue/{venueId:Guid}/message/user/{userId:Guid}/message/{messageId:Guid}/{status:int}", Name = "SetMessageStatus")]
            userRequest.execute("PUT", apiConfig.user.endpoint + "/venue/" + cardroom.id + "/message/user/" + account.localAccountId + "/message/" + pendingMessage.messageId + "/" + pendingMessage.status).then((response) => {
                if (response) {

                    let container = null;
                    switch (pendingMessage.oldStatus) {
                        case 1:
                            container = unreadMessages
                            break;
                        case 2:
                            container = readMessages;
                            break;
                        case 4:
                            container = snoozedMessages;
                            break;
                        case 8:
                            container = deletedMessages;
                            break;
                    }

                    if (container) {
                        let index = container.indexOf(pendingMessage);
                        let x = container.splice(index, 1);
                    }

                    switch (pendingMessage.status) {
                        case 1:
                            container = unreadMessages
                            break;
                        case 2:
                            container = readMessages;
                            break;
                        case 4:
                            container = snoozedMessages;
                            break;
                        case 8:
                            container = deletedMessages;
                            break;
                    }

                    if (urgentMessages) {
                        let index = urgentMessages.indexOf(pendingMessage);
                        let x = urgentMessages.splice(index, 1);
                    }

                    if (container) {
                        container.push(pendingMessage);
                    }

                }

                shcheduleNextPendingMessage();
            }).catch((ex) => {

            });
        }
    }, [userRequest.execute, pendingMessage])

    useEffect(() => {
        if (cardroom && !messages) {
            //[HttpGet("venue/{venueId:Guid}/message/user/me/inbox/{status:int}", Name = "GetMyMessages")]
            userRequest.execute("GET", apiConfig.user.endpoint + "/venue/" + cardroom.id + "/message/user/me/inbox/15").then((response) => {
                // setFetchMessages(false);
                //alert(response);
                if (response) {
                    setFetching(false);
                    setMessages(response);
                }
            }).catch((ex) => {

            });

        }
    }, [userRequest.execute, messages, cardroom]);

    useEffect(() => {
        if (fetching === true) return;
        const unread = [], deleted = [], snoozed = [], read = [];
        const urgent = [];
        if (messages) {

            for (var i = 0; i < messages.length; i++) {
                if (messages[i]) {
                    var container = null;
                    switch (messages[i].status) {
                        case 1:
                            container = unread
                            break;
                        case 2:
                            container = read;
                            break;
                        case 3:
                            container = snoozed;
                            break;
                        case 4:
                            container = deleted;
                            break;
                    }

                    if (container) container.push(messages[i]);

                    if (messages[i].priority === 4 && messages[i].status === 1) {
                        urgent.push(messages[i]);
                    }
                }
            }
        }

        setUnreadMessages(unread);
        setReadMessages(read);
        setSnoozedMessages(snoozed);
        setDeletedMessages(deleted);
        setUrgentMessages(urgent);
    }, [messages, fetching]);

    useEffect(() => {
        // setFetchMessages(true);
        const timer = setInterval(() => {
            //setFetchMessages(true);
            setFetching(true);
            setMessages(null);
        }, 30000);
        return () => clearInterval(timer);
    }, []);


    const [openInboxDialog, inboxDialogProps] = useDialog();


    function getUnreadMessageCount() {
        if (!unreadMessages) return 0;

        return unreadMessages.length;
    }

    function onTakeMessageAction(msg, newStatus) {
        if (msg) {
            msg.oldStatus = msg.status;
            msg.status = newStatus;
            enquePendingMessage(msg);
        }
    }

    function enquePendingMessage(msg) {
        if (!msg) return;
        pendingMessageQueue.push(msg);
        shcheduleNextPendingMessage();
    }

    function shcheduleNextPendingMessage() {
        if (!pendingMessageQueue || pendingMessageQueue.length == 0) {
            setPendingMessage(null);
            return;
        }

        const msg = pendingMessageQueue[0];
        pendingMessageQueue.splice(0, 1);

        setPendingMessage(msg);
    }

    function onMessageRead(msg) {
        onTakeMessageAction(msg, 2);
    }

    function onMessageSnoozed(msg) {
        onTakeMessageAction(msg, 4);
    }
    function onMessageDeleted(msg) {
        onTakeMessageAction(msg, 8);
    }
    function onMessageUnread(msg) {
        onTakeMessageAction(msg, 1);
    }

    return <><IconButton
        size="small"
        color="inherit"
        sx={navbarIconButton}
        onClick={openInboxDialog}>
        <Badge color="primary" badgeContent={getUnreadMessageCount()} max={99} >
            <Icon>mail</Icon>
            <SoftTypography
                variant="button"
                fontWeight="medium"
                color={light ? "white" : "dark"}
            >
                <span style={{ width: "15px" }}>&nbsp;</span>
            </SoftTypography>
        </Badge>

        {urgentMessages ? urgentMessages.map((msg) => {
            return <UrgentMessage message={msg} onMessageDismissed={onMessageRead} />
        }) : null}

    </IconButton >
        <UserInboxDialog {...inboxDialogProps} cardroom={cardroom} messages={messages} onRead={onMessageRead} onUnRead={onMessageUnread} onSnoozed={onMessageSnoozed} onDeleted={onMessageDeleted} />
    </>;
};


MessageCenter.defaultProps = {

};

// Typechecking props for the MiniStatisticsCard
MessageCenter.propTypes = {
    account: PropTypes.object,
    light: PropTypes.bool
};


export default MessageCenter;