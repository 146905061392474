import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { scheduleId, name, description, feeRate, feeThreshold, feeScheduleScheme, chipOffsetRate, taxRebateRate, makeupThreshold, excludeRevenueFromTax },
} = form;



const validations = {
    club: Yup.object().shape({
        //[scheduleId.name]: Yup.string(),
        [name.name]: Yup.string().required(name.errorMsg),
        //[description.name]: Yup.string(),
        [feeScheduleScheme.name]: Yup.number().oneOf([0, 1, 2]).required(feeScheduleScheme.errorMsg),
        [feeRate.name]: Yup.number().when([feeScheduleScheme.name], {
            is: (scheduleV) => scheduleV > 0,
            then: Yup.number().min(0, "Commission rate must be between 0 and 100%.").max(100, "Commission rate must be between 0 and 100%.").required(feeRate.errorMsg),
        }),
        [feeThreshold.name]: Yup.number().when([feeScheduleScheme.name], {
            is: (scheduleV) => scheduleV > 0,
            then: Yup.number().min(0, "Commission exemption must be greater than 0.").required(feeThreshold.errorMsg),
        }),
        [chipOffsetRate.name]: Yup.number().min(0, "Chip offset rate must be between 0 and 100%.").max(100, "Chip offset rate must be between 0 and 100%.").required(chipOffsetRate.errorMsg),
        [taxRebateRate.name]: Yup.number().min(0, "Tax rebate rate must be between 0 and 100%.").max(100, "Tax rebate rate must be between 0 and 100%.").required(taxRebateRate.errorMsg),
        [excludeRevenueFromTax.name]: Yup.number().min(0, excludeRevenueFromTax.errorMsg),
    }),
    book: Yup.object().shape({
        //[scheduleId.name]: Yup.string(),
        [name.name]: Yup.string().required(name.errorMsg),
        //[description.name]: Yup.string(),
        [feeScheduleScheme.name]: Yup.number().oneOf([0, 1, 2]).required(feeScheduleScheme.errorMsg),
        [feeRate.name]: Yup.number().when([feeScheduleScheme.name], {
            is: (scheduleV) => scheduleV > 0,
            then: Yup.number().min(0, "Commission rate must be between 0 and 100%.").max(100, "Commission rate must be between 0 and 100%.").required(feeRate.errorMsg),
        }),
        [feeThreshold.name]: Yup.number().when([feeScheduleScheme.name], {
            is: (scheduleV) => scheduleV > 0,
            then: Yup.number().min(0, "Commission exemption must be >= 0.").required(feeThreshold.errorMsg),
        }),
        [makeupThreshold.name]: Yup.number().when([feeScheduleScheme.name], {
            is: (scheduleV) => scheduleV > 0,
            then: Yup.number().min(0, "Max makeup must be >= 0.").required(makeupThreshold.errorMsg),
        })
    }),

};

export default validations;