
import React, { useState, useEffect } from 'react';
import { apiConfig } from 'config/apiConfig'
import { useTheme } from '@mui/material/styles';

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { Divider, Grid } from '@mui/material';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import defaultCasino from "assets/graphics/logos/default-casino.png";
import PhoneCell from 'components/Elements/DataTable/components/Cells/PhoneCell';
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';
import DataTable from 'components/Elements/DataTable';
import DateCell from 'components/Elements/DataTable/components/Cells/DateCell';
import { useCardroomContext } from 'features';


const SessionLiveSummaryReport = ({ cardroom, session, onClose }) => {
    const theme = useTheme();

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading session summary...", handleRetry)

    const utilities = {
        formatParticipantName: function (p) {
            if (!p) return null;
            let name = p.name;
            if (p.properties) {
                name = p.properties.name ? p.properties.name : name;

                if (p.properties.nickName) name += " (" + p.properties.nickName + ")";
            }

            return name;
        },
        formatParticipantImage: function (p) {
            if (!p) return null;

            let hasPhoto = p.hasPhoto;
            if (p.properties) {
                hasPhoto = p.properties.hasPhoto ? p.properties.hasPhoto : hasPhoto;
            }

            if (hasPhoto)
                return apiConfig.images.endpoint + "/player/" + p.id + ".jpg";

            let name = utilities.formatParticipantName(p);
            if (name)
                return name.substring(0, 1);

            return "";

        },
        formatParticipantPhone: function (p) {
            if (!p) return null;
            let phone = p.phoneNumber;

            if (p.properties) {
                phone = p.properties.phoneNumber ? p.properties.phoneNumber : phone;
            }

            return phone;

        },
        calculateTaxLiability: function (p) {
            if (!p) return 0;
            if (p.totalTipsAmount <= 0) return 0;
            if (!p.properties) return 0;

            function calcRoundedTaxAmount(taxedAmount, rate) {
                return Math.round(taxedAmount * rate / 5) * 5;
            }

            let exemption = 0, threshold = 0, taxesOwed = 0, taxedAmount = 0;
            let taxScheme = p.properties.withholdingSchedule, taxExempt = p.properties.withholdingThreshold, taxRate = p.properties.withholdingRate;
            if (taxScheme > 0) {

                switch (taxScheme) {
                    case 1:
                        threshold = taxExempt;
                        break;
                    case 2:
                        exemption = taxExempt;
                        break;
                }

                var totalTaxableIncome = p.totalTipsAmount - exemption;
                var totalTaxLiability = calcRoundedTaxAmount(totalTaxableIncome, taxRate);



                return totalTaxLiability;
            }

            return 0;
        },
        formatDate: function (d) {
            let dt = new Date(Date.parse(d));
            return dt.toLocaleString();
        }
    };


    function prepareParticipantsForPresentation() {

        if (!participants) return; //player names, etc
        if (!sessionParticipants) return; //participant session data

        //do the merge
        let pmap = [];
        for (var i = 0; i < participants.length; i++) {
            if (!pmap[participants[i].id]) {
                pmap[participants[i].id] = participants[i];
            }
        }

        let players = [], staff = [];
        //merge into sessionParticipants
        for (var i = 0; i < sessionParticipants.length; i++) {
            var p = pmap[sessionParticipants[i].id];
            if (p) {
                sessionParticipants[i] = Object.assign(sessionParticipants[i], p);
            }

            sessionParticipants[i].displayName = utilities.formatParticipantName(sessionParticipants[i]);
            sessionParticipants[i].displayPhone = utilities.formatParticipantPhone(sessionParticipants[i]);
            sessionParticipants[i].avatar = utilities.formatParticipantImage(sessionParticipants[i]);

            //add to rows, but only if participant has at least 1 transaction
            if (sessionParticipants[i].transactionCount > 0) {
                if ((sessionParticipants[i].type & 1) > 0) {
                    players.push({
                        id: sessionParticipants[i].id,
                        displayName: sessionParticipants[i].displayName,
                        avatar: sessionParticipants[i].avatar,
                        phone: sessionParticipants[i].displayPhone,
                        totalBuyIns: sessionParticipants[i].totalBuyinAmount,
                        totalCashout: sessionParticipants[i].totalCashoutAmount,
                        balance: -1 * sessionParticipants[i].borrowedAmount,
                        net: sessionParticipants[i].totalCashoutAmount - sessionParticipants[i].totalBuyinAmount,
                        player: sessionParticipants[i]
                    });
                }

                //include only participants w/ tips ?
                if ((sessionParticipants[i].type & 254) > 0 && sessionParticipants[i].totalTipsAmount > 0) {

                    sessionParticipants[i].totalTaxLiability = utilities.calculateTaxLiability(sessionParticipants[i]);
                    if (sessionParticipants[i].totalTaxLiability < 0) sessionParticipants[i].totalTaxLiability = 0;

                    staff.push({
                        id: sessionParticipants[i].id,
                        displayName: sessionParticipants[i].displayName,
                        avatar: sessionParticipants[i].avatar,
                        phone: sessionParticipants[i].displayPhone,
                        totalTipsEarned: sessionParticipants[i].totalTipsAmount,
                        totalTaxPaid: sessionParticipants[i].totalTaxAmount,
                        totalTaxLiability: sessionParticipants[i].totalTaxLiability,
                        balance: -1 * sessionParticipants[i].borrowedAmount,
                        staff: sessionParticipants[i]
                    });
                }
            }
        }

        setSessionParticipantsMapped(true);

        //sort players & staff
        function sort(arr) {
            if (arr) {
                arr.sort(function (a, b) {
                    if (a.displayName < b.displayName) { return -1; }
                    if (a.displayName > b.displayName) { return 1; }
                    return 0;
                });
            }
        }

        sort(players);
        sort(staff);

        setPlayerTable({ rows: players });
        setStaffTable({ rows: staff });
    }

    function prepareRakeForPresentation() {
        if (!rake) return;

        let map = [];

        for (var i = 0; i < rake.length; i++) {
            if (!map[rake[i].note]) {
                map[rake[i].note] = 0;
            }

            map[rake[i].note] += rake[i].amount;
        }

        let rows = [];
        let totalRake = 0;

        for (var t in map) {
            if (map.hasOwnProperty(t)) {
                //console.log(key, dictionary[key]);
                rows.push({
                    table: t,
                    amount: map[t]
                });
                totalRake += map[t];
            }
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.table < b.table) { return -1; }
                if (a.table > b.table) { return 1; }
                return 0;
            });
        }

        setRakeTable({ rows: rows, total: totalRake });
    }
    function prepareTaxesForPresentation() {
        if (!taxes) return;
        if (!participants) return;
        if (!sessionParticipants) return;
        if (!sessionParticipantsMapped) return;

        let pmap = [];
        let totalTaxesOwed = 0, totalTaxesCollected = 0, totalTaxedParticipants = 0;

        for (var i = 0; i < sessionParticipants.length; i++) {
            if (!pmap[sessionParticipants[i].id]) {
                pmap[sessionParticipants[i].id] = sessionParticipants[i];
                totalTaxesOwed += sessionParticipants[i].totalTaxLiability > 0 ? sessionParticipants[i].totalTaxLiability : 0;
                totalTaxesCollected += sessionParticipants[i].totalTaxAmount > 0 ? sessionParticipants[i].totalTaxAmount : 0;
                totalTaxedParticipants += sessionParticipants[i].totalTaxLiability > 0 ? 1 : 0;
            }
        }

        //totalTaxLiability

        let rows = [];
        rows.push({
            taxedStaffMembers: totalTaxedParticipants,
            totalTaxesOwed: totalTaxesOwed,
            totalTaxesPaid: totalTaxesCollected
        });

        setTaxTable({ rows: rows, deficit: totalTaxesCollected - totalTaxesOwed });

    }
    function prepareExpesesForPresentation() {
        if (!expenses) return;
        if (!participants) return;

        let pmap = [];
        for (var i = 0; i < participants.length; i++) {
            if (!pmap[participants[i].id]) {
                pmap[participants[i].id] = participants[i];
            }
        }

        let rows = [];
        let totalExpenses = 0;
        for (var i = 0; i < expenses.length; i++) {
            rows.push({
                id: expenses[i].id,
                paidTo: expenses[i].participantId !== cardroom.id ? utilities.formatParticipantName(pmap[expenses[i].participantId]) : cardroom.name,
                date: expenses[i].transactionDate,
                purpose: expenses[i].note,
                amount: expenses[i].amount,
                transaction: expenses[i]
            });
            totalExpenses += expenses[i].amount;
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.date < b.date) { return -1; }
                if (a.date > b.date) { return 1; }
                return 0;
            });
        }

        setExpenseTable({ rows: rows, total: totalExpenses });
    }

    const [sessionParticipants, setSessionParticipants] = useState(null);
    const [participants, setParticipants] = useState(null);
    const [expenses, setExpenses] = useState(null);
    const [rake, setRake] = useState(null);
    const [taxes, setTaxes] = useState(null);
    const [sessionParticipantsMapped, setSessionParticipantsMapped] = useState(false);

    const [playerTable, setPlayerTable] = useState(null);
    const [staffTable, setStaffTable] = useState(null);

    const [rakeTable, setRakeTable] = useState(null);
    const [taxTable, setTaxTable] = useState(null);
    const [expenseTable, setExpenseTable] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);
    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    function getCurrencyPrefix() {
        return isCurrencyMaskingEnabled() === true ? "" : "$";
    }

    const casinoRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const peopleRequest = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });


    useEffect(() => {
        if (!sessionParticipants) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading session participants...")
            showProgress();

            // [HttpGet("{casinoId:Guid}/sessions/{sessionId:Guid}/participants/{type:int?}", Name = "GetSessionParticipants")]
            casinoRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/participants/255").then((response) => {
                if (response) {
                    setSessionParticipants(response);
                }
                else {
                    progressIndicatorProps.setMode("warnWithRetry");
                    progressIndicatorProps.setMessage("Session participants not available, please try again...");
                }

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load session participants, please try again...")
            });

        }

    }, [casinoRequest.execute, sessionParticipants, retryCounter]);

    useEffect(() => {
        if (sessionParticipants && !participants) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading session participants...")
            showProgress();

            const list = [];
            const map = [];

            for (var i = 0; i < sessionParticipants.length; i++) {
                if (!map[sessionParticipants[i].id]) {
                    list.push(sessionParticipants[i].id);
                    map[sessionParticipants[i].id] = true;
                }
            }

            if (list.length > 0) {
                //[HttpPut("venue/{venueId:Guid}/participants", Name = "FillParticipantList")]
                peopleRequest.execute("PUT", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants", list).then((response) => {
                    if (response) {
                        setParticipants(response);
                    }
                }).catch((e) => {
                    progressIndicatorProps.setMode("errorWithRetry");
                    progressIndicatorProps.setMessage("Unable to load session participants, please try again...")
                });
            }
            else {
                progressIndicatorProps.close();
            }
        }

    }, [peopleRequest.execute, participants, sessionParticipants, retryCounter]);

    useEffect(() => {
        if (participants && sessionParticipants) {
            //merge & separate
            prepareParticipantsForPresentation();
            progressIndicatorProps.close();
        }
    }, [participants, sessionParticipants]);


    useEffect(() => {
        if (!expenses) {
            //  [HttpGet("{casinoId:Guid}/sessions/{sessionId:Guid}/transactions/{type:int}", Name = "GetSessionTransactions")]
            casinoRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/transactions/7").then((response) => {
                if (response) {
                    setExpenses(response);
                }

            }).catch((e) => {
                //progressIndicatorProps.setMode("errorWithRetry");
                //progressIndicatorProps.setMessage("Unable to load session participants, please try again...")
            });
        }

    }, [casinoRequest.execute, expenses])

    useEffect(() => {
        if (!rake) {
            //  [HttpGet("{casinoId:Guid}/sessions/{sessionId:Guid}/transactions/{type:int}", Name = "GetSessionTransactions")]
            casinoRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/transactions/5").then((response) => {
                if (response) {
                    setRake(response);
                }

            }).catch((e) => {
                //progressIndicatorProps.setMode("errorWithRetry");
                //progressIndicatorProps.setMessage("Unable to load session participants, please try again...")
            });
        }

    }, [casinoRequest.execute, rake])

    useEffect(() => {
        if (!taxes) {
            //  [HttpGet("{casinoId:Guid}/sessions/{sessionId:Guid}/transactions/{type:int}", Name = "GetSessionTransactions")]
            casinoRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/" + session.id + "/transactions/4").then((response) => {
                if (response) {
                    setTaxes(response);
                }

            }).catch((e) => {
                //progressIndicatorProps.setMode("errorWithRetry");
                //progressIndicatorProps.setMessage("Unable to load session participants, please try again...")
            });
        }

    }, [casinoRequest.execute, taxes])

    useEffect(() => {
        if (rake) {
            prepareRakeForPresentation();
        }
    }, [rake]);

    useEffect(() => {
        if (expenses && participants) {
            prepareExpesesForPresentation();
        }
    }, [expenses, participants]);

    useEffect(() => {
        if (taxes && participants && sessionParticipants && sessionParticipantsMapped === true) {
            prepareTaxesForPresentation();
        }
    }, [taxes, participants, sessionParticipants, sessionParticipantsMapped]);


    return <Grid container justifyContent="center">
        <Grid item xs={12}>
            <ProgressIndicator {...progressIndicatorProps} />
            {progressIndicatorProps.visible ? <></> :
                <SoftBox>
                    <Grid container justifyContent="space-between">

                        <Grid item xs={12} md={6}>
                            <Grid container xs={12}>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                    color="dark">
                                    Players
                                </SoftTypography>
                            </Grid>
                            <Grid container xs={12}>
                                {playerTable ?
                                    <TableContainer component={Paper} style={{ borderRadius: "0px", margin: "2px", height: "375px", overflow: "auto" }}>
                                        <Table aria-label="customized table">
                                            <TableBody>
                                                <TableRow style={{ backgroundColor: theme.palette.secondary.main }}>
                                                    <TableCell>
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize"
                                                            color="light">
                                                            Player
                                                        </SoftTypography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize"
                                                            color="light">
                                                            Buy In
                                                        </SoftTypography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize"
                                                            color="light">
                                                            Cashout
                                                        </SoftTypography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize"
                                                            color="light">
                                                            Net Result
                                                        </SoftTypography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            fontWeight="bold"
                                                            textTransform="capitalize"
                                                            color="light">
                                                            Debt
                                                        </SoftTypography>
                                                    </TableCell>
                                                </TableRow>
                                                {playerTable.rows.map((row) => {
                                                    return <>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                <ParticipantCell image={row.avatar} color={"dark"} name={row.displayName} selectable={false} checked={false} />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <MoneyCell value={row.totalBuyIns} prefix={getCurrencyPrefix()} useColorScheme={false} />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <MoneyCell value={row.totalCashout} prefix={getCurrencyPrefix()} useColorScheme={false} />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <MoneyCell value={row.net} prefix={getCurrencyPrefix()} useColorScheme={true} />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <MoneyCell value={row.balance} prefix={getCurrencyPrefix()} useColorScheme={true} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    : null}
                            </Grid>

                            <Grid container xs={12} mt={1}>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                    color="dark">
                                    Rake{rakeTable ? <> - Total : <MoneyCell value={rakeTable.total} prefix={getCurrencyPrefix()} /></> : null}
                                </SoftTypography>
                            </Grid>
                            <Grid container xs={12}>
                                {rakeTable ? <TableContainer component={Paper} style={{ borderRadius: "0px", margin: "2px", height: "175px", overflow: "auto" }}>
                                    <Table aria-label="customized table">
                                        <TableBody>
                                            <TableRow style={{ backgroundColor: theme.palette.secondary.main }}>
                                                <TableCell >
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        Table
                                                    </SoftTypography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        Total Rake
                                                    </SoftTypography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        {rakeTable.rows.map((row) => {
                                            return <>
                                                <TableRow>
                                                    <TableCell>
                                                        <TextCell value={row.table} />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <MoneyCell value={row.amount} prefix={getCurrencyPrefix()} useColorScheme={true} />
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        })}
                                    </Table>
                                </TableContainer> : null}
                            </Grid>

                            <Grid container xs={12}>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                    color="dark">
                                    Taxes {taxTable && taxTable.deficit < 0 ? <> - Deficit : <SoftTypography color="error" component="label" variant="caption"><MoneyCell value={taxTable.deficit * -1} prefix={getCurrencyPrefix()} inheritColor={true} useColorScheme={false} /></SoftTypography></> : null}

                                </SoftTypography>
                            </Grid>

                            <Grid container xs={12}>
                                {taxTable ? <TableContainer component={Paper} style={{ borderRadius: "0px", margin: "2px", overflow: "auto" }}>
                                    <Table aria-label="customized table">
                                        <TableBody>
                                            <TableRow style={{ backgroundColor: theme.palette.secondary.main }}>
                                                <TableCell align="left">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        # of Taxed Staff
                                                    </SoftTypography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        Tax Owed
                                                    </SoftTypography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        Tax Collected
                                                    </SoftTypography>
                                                </TableCell>
                                            </TableRow>
                                            {taxTable.rows.map((row) => {
                                                return <>
                                                    <TableRow>
                                                        <TableCell>
                                                            <TextCell value={row.taxedStaffMembers} />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <MoneyCell value={row.totalTaxesOwed} prefix={getCurrencyPrefix()} useColorScheme={true} />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <SoftTypography
                                                                component="label"
                                                                variant="caption"
                                                                fontWeight="bold"
                                                                textTransform="capitalize"
                                                                color={row.totalTaxesOwed > row.totalTaxesPaid ? "warning" : "success"}>
                                                                <MoneyCell value={row.totalTaxesPaid} prefix={getCurrencyPrefix()} useColorScheme={false} inheritColor={true} />
                                                            </SoftTypography>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer> : null}
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container xs={12}>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                    color="dark">
                                    Staff
                                </SoftTypography>
                            </Grid>

                            <Grid container xs={12}>
                                {staffTable ? <TableContainer component={Paper} style={{ borderRadius: "0px", margin: "2px", height: "375px", overflow: "auto" }}>
                                    <Table aria-label="customized table">
                                        <TableBody>
                                            <TableRow style={{ backgroundColor: theme.palette.secondary.main }}>
                                                <TableCell>
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        Staff
                                                    </SoftTypography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        Tips Earned
                                                    </SoftTypography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        Tax Liability
                                                    </SoftTypography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        Tax Collected
                                                    </SoftTypography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        Debt
                                                    </SoftTypography>
                                                </TableCell>
                                            </TableRow>
                                            {staffTable.rows.map((row) => {
                                                return <>
                                                    <TableRow>
                                                        <TableCell align="left" >
                                                            <ParticipantCell image={row.avatar} color={"dark"} name={row.displayName} selectable={false} checked={false} />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <MoneyCell value={row.totalTipsEarned} prefix={getCurrencyPrefix()} useColorScheme={true} />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <MoneyCell value={row.totalTaxLiability} prefix={getCurrencyPrefix()} useColorScheme={false} />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <SoftTypography
                                                                component="label"
                                                                variant="caption"
                                                                fontWeight="bold"
                                                                textTransform="capitalize"
                                                                color={row.totalTaxLiability > row.totalTaxPaid ? "warning" : "success"}>
                                                                <MoneyCell value={row.totalTaxPaid} prefix={getCurrencyPrefix()} useColorScheme={false} inheritColor={true} />
                                                            </SoftTypography>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <MoneyCell value={row.balance} prefix={getCurrencyPrefix()} useColorScheme={true} />
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer> : null}
                            </Grid>

                            <Grid container xs={12} mt={1}>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                    color="dark">
                                    Expenses{expenseTable ? <> - Total : <SoftTypography color="error" component="label" variant="caption"><MoneyCell value={expenseTable.total} prefix={getCurrencyPrefix()} inheritColor={true} useColorScheme={false} /></SoftTypography></> : null}
                                </SoftTypography>
                            </Grid>
                            <Grid container xs={12}>
                                {expenseTable ? <TableContainer component={Paper} style={{ borderRadius: "0px", margin: "2px", height: "294px", overflow: "auto" }}>
                                    <Table aria-label="customized table">
                                        <TableBody>
                                            <TableRow style={{ backgroundColor: theme.palette.secondary.main }}>
                                                <TableCell align="left">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        Expense
                                                    </SoftTypography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        Date
                                                    </SoftTypography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        Reimbursed To
                                                    </SoftTypography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize"
                                                        color="light">
                                                        Amount
                                                    </SoftTypography>
                                                </TableCell>
                                            </TableRow>
                                            {expenseTable.rows.map((row) => {
                                                return <>
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            <TextCell value={row.purpose} />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <DateCell value={row.date + "Z"} format="dateAndTime" display="locale" />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <TextCell value={row.paidTo} />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <SoftTypography
                                                                component="label"
                                                                variant="caption"
                                                                fontWeight="bold"
                                                                textTransform="capitalize"
                                                                color="error">
                                                                <MoneyCell value={row.amount} prefix={getCurrencyPrefix()} useColorScheme={false} inheritColor={true} />
                                                            </SoftTypography>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer> : null}
                            </Grid>


                        </Grid>
                    </Grid>
                </SoftBox>
            }
        </Grid>
    </Grid>
};

export default SessionLiveSummaryReport;