import { apiConfig } from "config/apiConfig";


const dataLoader = {
    loadReportPlayers: function (request, cardroom, reportingPeriod, summary, club, agency, enableCache, onsuccess, onerror) {
        if (summary.players) {
            if (onsuccess) onsuccess(summary.players);
            return;
        }

        let url = apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/players?";

        if (club) {
            url += "clubId=" + club.id;
        }
        else if (agency) {
            url += "agencyId=" + agency.id;
        }

        request.execute("GET", url).then((response) => {
            if (enableCache) summary.players = response;
            if (onsuccess) onsuccess(response);
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });

    },
}


const useReportDataLoader = () => {



    return {
        loadPlayers: dataLoader.loadReportPlayers
    };
}

export {
    useReportDataLoader
}