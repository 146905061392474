
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { Divider, FormControlLabel, Grid, Hidden, Icon, Radio, RadioGroup, TextField, Tooltip, tooltipClasses } from '@mui/material';
import { Formik, Form, ErrorMessage } from 'formik';
import FormField from 'components/Elements/Forms/FormField';
import SoftAvatar from 'components/SoftAvatar';
import FormSwitch from 'components/Elements/Forms/FormSwitch';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import { styled } from '@mui/material/styles';


import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import { useCardroomContext } from 'features';
import SoftInput from 'components/SoftInput';


const TierStepEditor = ({ step, onStepValueChanged }) => {

    function formatCurrency(number, currencyCode = 'USD', locale = 'en-US') {
        return new Intl.NumberFormat(locale, { style: 'currency', currency: currencyCode }).format(number);
    }

    function formatValue(value) {
        return formatCurrency(value, 'USD', 'en-US');
    }

    function onValueChanged(evt) {
        //alert(evt.target.value);
        step.max = parseFloat(evt.target.value);
        if (isNaN(step.max)) step.max = step.min + 100;

        if (step.max < step.min) {
            step.error = {
                msg: "Step max value must be greater than its min value."
            };
        }
        else step.error = null;


        if (onStepValueChanged) onStepValueChanged(step.min, step.max);
    }

    return <Grid container xs={12} spacing={2} display="flex" justifyContent={"space-between"}>
        <Grid item xs={4} lg={2}>
            <SoftTypography pl={1}
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize">
                {formatValue(step.min)}
            </SoftTypography>

        </Grid>
        <Hidden lgDown>
            <Grid item md={6} textAlign={"center"}>
                <Divider />
            </Grid>
        </Hidden>
        <Grid item xs={8} lg={4} >
            {/** renderText={formatCurrency} */}
            <SoftTypography width="100%"
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize" >
                {step.isLast === true ? <SoftBox pl={1.0}>above {formatValue(step.min)}</SoftBox> : <SoftInput icon={{
                    component: "attach_money",
                    direction: "left",
                }} value={step.max} onChange={onValueChanged} type="number" display="inline" success={!Boolean(step.error)} error={Boolean(step.error)} />}
            </SoftTypography>

        </Grid>

        {step.error ? <Grid item xs={12} mt={-2} pl={1} textAlign={"right"}>
            <SoftTypography
                component="label"
                variant="caption"
                color="error">
                {step.error.msg}
            </SoftTypography>
        </Grid> : null}
    </Grid >;
}

const TierStepEditorContainer = ({ step, steps, onDeleted, onRedraw, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { scheduleId, name, stepCount, stepErrors } = formField;

    //find before & after
    if (!step.min) step.min = 0;
    if (!step.max) step.max = step.min + 1000;

    function deleteTierStep(step) {
        if (!step) return;
        if (!steps) return;

        if (!canDeleteTierStep(step, steps)) return;

        //there must be at least 2

        const idx = steps.indexOf(step);
        if (idx < 0) return;
        steps.splice(idx, 1);

        ////recalculate
        const first = steps.length > 0 ? steps[0] : null;

        if (first) {
            updateNeighbors(first);
        }

        if (onDeleted) onDeleted(step);
    }

    function onStepValuesChanges(low, high) {

        // recalculate all other steps???
        step.min = low;
        step.max = high;

        updateNeighbors(step);

        reDraw();
    }

    function updateNeighbors(s) {
        const idx = steps.indexOf(s);

        if (idx < 0) return;

        //if idex > 0 then we have both sides, if idx == 0, we have only 1 side
        const current = steps[idx];
        if (current.error) {
            setFieldValue(stepErrors.name, 9999);
            validateForm();
            return;
        }

        setFieldValue(stepErrors.name, 0); //resent step error

        const previous = idx > 0 ? steps[idx - 1] : null;
        const next = steps.length > idx ? steps[idx + 1] : null;

        if (previous) {
            // do we ever update previous???
            //if (!previous.max) previous.max = previous.min + 1000;

            previous.isLast = false;
        }
        else {
            //we're first, so set min to 0
            s.min = 0;
        }

        if (next) {
            let maintainedDistance = 0;
            if (s.max > next.max) {
                maintainedDistance = next.max - next.min;
            }
            next.min = s.max;
            if (maintainedDistance > 0) {
                next.max = next.min + maintainedDistance;
            }

            s.isLast = false;
            updateNeighbors(next);
        }
        else {
            //last step? set max to null
            s.isLast = true;

            /*
            let errorCount = 0;
            for (var i = 0; i < steps.length; i++) {
                if (steps[i].error) errorCount++;
            }

            setFieldValue(stepErrors.name, errorCount);
            //if (errorCount > 0) setFieldError(stepErrors.name, "Step config errors");
            //else setFieldError(stepErrors.name, null);
            */
        }
    }

    function reDraw() {
        if (onRedraw) onRedraw();
    }

    function canDeleteTierStep(s, ss) {
        if (!s) return false;
        if (ss && ss.length < 3) return false;

        return (s.refCount === 0);
    }

    return <Grid container xs={12} pl={2} pr={2} pt={1}>
        <Grid item xs={12} display="flex" justifyContent={"space-between"} justifyItems={"center"}>

            <TierStepEditor step={step} onStepValueChanged={onStepValuesChanges} />
            <SoftBox pr={0.5} pl={0.5}>&nbsp;</SoftBox>
            <SoftButton onClick={() => deleteTierStep(step)} iconOnly disabled={!canDeleteTierStep(step, steps)}><Icon>delete</Icon></SoftButton>

        </Grid>
    </Grid>;
}

const FeeTierScheduleEditorForm = ({ cardroom, union, club, agency, tierSchedule, formData, dense }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { scheduleId, name, stepCount, stepErrors } = formField;
    const {
        scheduleId: scheduleIdV,
        name: nameV,
        stepCount: stepCountV,
        stepErrors: stepErrorsV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);

    const [context, actions, features] = useCardroomContext(cardroom);

    const [forceRender, setForceRender] = useState(0);


    function canAddTierStep() {
        if (!tierSchedule) return false;
        if (!tierSchedule.steps) tierSchedule.steps = [];

        return tierSchedule.steps.length < 25;
    }

    function addTierStep() {
        if (false == canAddTierStep()) return;

        const last = tierSchedule.steps.length > 0 ? tierSchedule.steps[tierSchedule.steps.length - 1] : { min: 0, max: 0 };

        //find last step and set min/max based on that
        for (var i = 0; i < tierSchedule.steps.length; i++) {
            if (!tierSchedule.steps[i]) continue;
            tierSchedule.steps[i].isLast = false;
        }

        tierSchedule.steps.push({
            id: null,
            min: last.max,
            max: null, //last.max > 0 ? 2 * last.max - last.min : 1000,
            refCount: 0,
            isLast: true
        });

        setFieldValue(stepCount.name, tierSchedule.steps.length);
        validateForm();

        reDraw();
    }

    function onDeleted() {
        setFieldValue(stepCount.name, tierSchedule.steps.length);
        validateForm();
        reDraw();
    }
    function reDraw() {
        //recalculate everything here??? -- this gets called by delete, add, change ?
        setForceRender(forceRender + 1);
    }

    function canEditSchedule() {
        if (!context) return false;

        return true;
        //return context.isAllowed(actions.cardroom.fees.agent.edit);
    }

    useEffect(() => {
        setFormDataInitialized(false);
    }, [tierSchedule]);

    useEffect(() => {
        if (!formDataInitialized) {

            let schedName = "", schedId = "";

            if (tierSchedule) {
                if (tierSchedule.id) {
                    schedId = tierSchedule.id;
                    schedName = tierSchedule.name;
                }
            }

            setFieldValue(scheduleId.name, schedId);
            setFieldValue(name.name, schedName);

            setFieldValue(stepErrors.name, 0);
            setFieldValue(stepCount.name, tierSchedule && tierSchedule.steps ? tierSchedule.steps.length : 0);

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);



    return <SoftBox minHeight={"250px"}>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                {dense === true ? null :
                    <Grid item xs={12} sm={4} container justifyContent="center">
                        <SoftBox position="relative" height="max-content" mx="auto">
                            <SoftAvatar src={null} size="xxl" variant="rounded" bgColor="dark" />
                        </SoftBox>
                    </Grid>}
                <Grid item xs={12} sm={dense === true ? 12 : 8}>

                    <SoftBox mb={2} textAlign="left" >

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {name.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField disabled={!canEditSchedule()}
                                    type={name.type}
                                    name={name.name}
                                    value={nameV}
                                    placeholder={name.placeholder}
                                    error={errors.name && touched.name}
                                    success={nameV && nameV.length > 0 && !errors.name}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={12}  >
                                <SoftBox p={1} width="100%" bgColor="light" display="flex" justifyContent={"space-between"}>
                                    <SoftBox pl={2}></SoftBox>
                                    <SoftBox width="100%">
                                        <Grid container xs={12} spacing={2} display="flex" justifyContent={"space-between"}>
                                            <Grid item xs={4} lg={2}>
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="uppercase">
                                                    from
                                                </SoftTypography>

                                            </Grid>
                                            <Hidden lgDown>
                                                <Grid item xs={6} textAlign={"center"}>
                                                    <Divider />
                                                </Grid>
                                            </Hidden>
                                            <Grid item xs={8} lg={4} textAlign={"left"}>
                                                {/** renderText={formatCurrency} */}
                                                <SoftTypography pl={2}
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="uppercase">
                                                    to
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </SoftBox>
                                    <SoftBox pl={2} pr={1} >
                                        <SoftButton onClick={addTierStep} disabled={!canAddTierStep()} iconOnly><Icon>add</Icon></SoftButton>
                                    </SoftBox>
                                </SoftBox>
                            </Grid>

                            {tierSchedule && tierSchedule.steps ? tierSchedule.steps.map((step) => {
                                return <Grid item xs={12}>
                                    <TierStepEditorContainer steps={tierSchedule.steps} step={step} onDeleted={onDeleted} onRedraw={reDraw} formData={formData} />
                                </Grid>;
                            }) : null}

                            <Grid item xs={12} textAlign={"right"}>
                                {errors.stepCount ? <SoftBox>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={stepCount.name} />
                                    </SoftTypography>
                                </SoftBox> : null}

                                {errors.stepErrors ? <SoftBox>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={stepErrors.name} />
                                    </SoftTypography>
                                </SoftBox> : null}
                            </Grid>
                        </Grid>
                    </SoftBox>
                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox >;
}

FeeTierScheduleEditorForm.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object,
    club: PropTypes.object,
    agency: PropTypes.object,
    tierSchedule: PropTypes.object.isRequired,
    dense: PropTypes.bool
};

const FeeTierScheduleEditor = ({ cardroom, union, club, agency, tierSchedule, onClose, dense }) => {

    const { formId, formField } = form;
    const currentValidation = validations.editor;

    const [retryCounter, setRetryCounter] = useState(0);
    const [tierRecord, setTierRecord] = useState(null);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "fee tier...", handleRetry)

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.club.scopes.write,
    });

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditSchedule() {
        if (!context) return false;

        if (canEditFeeSchedule() === false) return false;

        return context.isAllowed(actions.cardroom.fees.club.edit);
    }

    function canEditFeeSchedule() {
        if (!context) return false;

        // ClubFeeScheduleEdit = 1,
        if ((union.features & 1) === 0) return false;

        return true;
    }

    const handleSubmit = (values, actions) => {

        if (false === canEditSchedule()) return;

        var payload = Object.assign({}, tierSchedule);

        if (!payload.id) {
            payload.id = values.scheduleId;
        }

        if (!payload.id) {
            payload.id = "00000000-0000-0000-0000-000000000000";
        }

        payload.name = values.name;

        if (!payload.steps || payload.steps.length < 2) {
            actions.setFieldError(form.formField.steps.name, form.formField.steps.errorMsg);
            //isSubmitting = false;
            return;
        }

        var lastStep = null;
        for (var i = 0; i < payload.steps.length; i++) {
            lastStep = payload.steps[i];
            if (!lastStep.id) lastStep.id = "00000000-0000-0000-0000-000000000000";

            if (lastStep.isLast) lastStep.max = null;
            delete lastStep.isLast;
        }

        // lastStep.max = null;

        setTierRecord(payload);
    };

    useEffect(() => {
        if (tierRecord) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving fee tier schedule...")
            showProgress();

            let url = apiConfig.club.endpoint;
            if (union) {
                url += "/union/" + union.id + "/casino/" + cardroom.id + "/tiers";
            }
            else if (club) {
                url += "/club/" + club.id + "/casino/" + cardroom.id + "/tiers";
            }
            else if (agency) {
                url += "/agency/" + agency.id + "/casino/" + cardroom.id + "/tiers";
            }

            // [HttpPost("{unionId:Guid}/casino/{casinoId:Guid}/tiers", Name = "SaveUnionMonetaryTier")]
            execute("POST", url, tierRecord).then((response) => {
                if (response) {
                    if (tierSchedule) tierSchedule = Object.assign(tierSchedule, response);
                    else {

                        tierSchedule = response;
                    }

                }

                setTierRecord(null);
                progressIndicatorProps.close();
                raiseOnClose(false);

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save fee tier schedule, please try again...")
            });
        }

    }, [tierRecord, execute, retryCounter]);


    function onCanceled() {
        raiseOnClose(true);
    }

    function raiseOnClose(canceled) {
        if (onClose) {
            onClose(canceled);
        }
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <FeeTierScheduleEditorForm cardroom={cardroom} union={union} tierSchedule={tierSchedule} dense={dense} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={onCanceled}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting || !canEditSchedule()}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};

FeeTierScheduleEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object,
    club: PropTypes.object,
    agency: PropTypes.object,
    dense: PropTypes.bool
};

export default FeeTierScheduleEditor;