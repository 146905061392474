import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import wavesWhite from "assets/images/shapes/waves-white.svg";

import { useDialog } from "components/Elements/Dialog/common";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import MiniActionCard from "components/Elements/MiniActionCard";


import { useCardroomContext } from 'features';
import ClubPlayers from '../ClubPlayers';
import ReportingPeriods from 'layouts/Club/components/Reports/History';
import ClubAgents from '../ClubAgents';
import CardroomLogo from 'components/Elements/CardroomLogo';
import ReportUploadDialog from 'layouts/Club/components/Reports/ReportUpload';
import AdaptiveTab from 'components/Elements/AdaptiveTab';

import RecordAccountTransactionDialog from 'layouts/Cardroom/Details/components/Accounting/Dialogs';
import CurrencyConverterWidget from 'components/Elements/CurrencyConverter';
import { useCurrencyConverter } from 'components/Elements/CurrencyConverter/currency';
import AccountingTransactionsCard from 'layouts/Cardroom/Details/components/Accounting/Transactions/List';
import AccountingAccountsCard from 'layouts/Cardroom/Details/components/Accounting/Accounts';
import PurchaseOrderCard from 'layouts/Cardroom/Details/components/PurchaseOrders';
import PurchaseOrderDialog from 'layouts/Cardroom/Details/components/PurchaseOrders/Dialogs';
import AgentEditorDialog from 'layouts/Club/components/Agent';




function ClubDetails({ cardroom }) {

    const [cardroomDetailsData, setCardroomDetailsData] = useState(null);
    const [club, setClub] = useState(null);

    const [cardroomStats, setCardroomStats] = useState({});
    const [cardroomAccount, setCardroomAccount] = useState({});

    const [currencyConverter, currencies] = useCurrencyConverter(cardroom);
    const [currency, setCurrency] = useState(null);

    function onCurrencySelectionChanged(c) {
        setCurrency(c);
    }

    const [currencySymbol, setCurrencySymbol] = useState(null);

    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    useEffect(() => {
        if (true === isCurrencyMaskingEnabled()) {
            setCurrencySymbol("");
            return;
        }

        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency]);

    const cardroomRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    const peopleRequest = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    function refreshCardroomProperties() {
        setCardroomStats(null);
    }

    function refreshAccount() {
        setCardroomAccount(null);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading club details...", handleRetry)

    const [context, actions, features] = useCardroomContext(cardroom);

    useEffect(() => {
        if (!cardroomDetailsData || cardroomDetailsData.id != cardroom.id) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading casino details...")
            showProgress();

            cardroomRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id).then((response) => {
                setCardroomDetailsData(response);
                //setCardroomStats(null);
                //progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load casino details, please try again...")
            });
        }
    }, [cardroomRequest.execute, cardroomDetailsData, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (cardroomDetailsData && !club) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading club info...")
            showProgress();

            clubRequest.execute("GET", apiConfig.club.endpoint + "/club/casino/" + cardroom.id).then((response) => {
                if (response) {
                    setClub(response);
                    let c = response.currency;
                    if (!c) c = "USD";
                    onCurrencySelectionChanged(currencyConverter.getCurrencyFrom(c));
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load club info, please try again...")
            });
        }
    }, [clubRequest.execute, club, cardroomDetailsData, retryCounter]) //cardroomListData 


    useEffect(() => {
        if (cardroomDetailsData && club && !cardroomStats) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading casino stats...")
            showProgress();

            peopleRequest.execute("GET", apiConfig.people.endpoint + "/venue/" + cardroom.id).then((response) => {
                setCardroomStats(response);
                setCardroomAccount(null);
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load casino stats, please try again...")
            });
        }
    }, [peopleRequest.execute, cardroomStats, cardroomDetailsData, club, retryCounter]) //cardroomStats

    useEffect(() => {
        if (cardroomDetailsData && cardroomStats && club && (!cardroomAccount || cardroomAccount.accountHolderId != cardroom.id)) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading casino account...")
            showProgress();

            // [HttpGet("{entityId:Guid}/holders/{holderId:Guid}/accounts/{type:int}", Name = "GetEntityAccount")]
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/" + cardroom.id + "/accounts/2").then((response) => {
                setCardroomAccount(response);

                progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load casino account, please try again...")
            });
        }
    }, [accountRequest.execute, cardroomAccount, cardroomStats, cardroomDetailsData, club, retryCounter]) //cardroomAccount

    const [openRecordTxDialog, recordTxDialogProps] = useDialog();
    const [openFileUploadDialog, fileUploadDialogProps] = useDialog();
    const [openPurchaseOrderTxDialog, recordPurchaseOrderDialogProps] = useDialog();

    const [openAgentEditorDialog, openAgentEditorDialogProps] = useDialog();

    const [newAgent, setNewAgent] = useState(null);
    // function canManageCommissionSchedules() {
    //     return true;
    // }

    function canUploadReport() {
        if (!club || !context) return false;

        if (!context.isEnabled(features.club.reporting.selfUpload)) return false;

        return context.isAllowed(actions.club.reports.upload);
    }

    function onFileUploaded() { }


    function isAccountFeatureEnabled() {
        if (!context) return false;
        return context.isEnabled(features.casino.accountServices, 0) || context.isEnabled(features.casino.advancedAccountServices, 0);

    }

    function isPoFeatureEnabled() {
        if (!context) return false;
        if (!club) return false;

        return context.isEnabled(features.casino.accountServicesPO, 0);
    }

    function canAddPurchaseOrder() {
        if (false == isPoFeatureEnabled()) return false;
        if (!context) return false;
        return context.isAllowed(actions.casino.account.purchaseOrder.add);
    }

    function canAddAccountTransaction() {
        if (false == isAccountFeatureEnabled()) return false;
        if (!context) return false;
        return context.isAllowed(actions.casino.account.transaction.add);
    }

    function canAddAgent() {
        if (!context) return false;
        if (!club) return false;
        if (!club.app) return false;

        return (club.app.features & 1) > 0;
    }

    function addNewAgent() {
        if (!canAddAgent()) return;

        setNewAgent({});
        openAgentEditorDialog();
    }

    const tabs = [];

    tabs.push({
        title: { text: "Reports", icon: "summarize" },
        header: canUploadReport() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openFileUploadDialog}><Icon size="lg">cloud_upload</Icon>&nbsp;Upload File</SoftButton> : null,
        body: <>   {canUploadReport() ? <ReportUploadDialog {...fileUploadDialogProps} cardroom={cardroom} onFileUploaded={onFileUploaded} /> : null}
            <ReportingPeriods cardroom={cardroom} club={club} audience="club" /></>
    });


    tabs.push({
        title: { text: "Players", icon: "group" },
        header: null,
        body: <ClubPlayers cardroom={cardroom} club={club} />
    });


    tabs.push({
        title: { text: "Agents", icon: "support_agent" },
        header: canAddAgent() ? <SoftButton variant="outlined" color="info" onClick={addNewAgent}><Icon size="lg">add</Icon>&nbsp;New Agent</SoftButton> : null,
        body: <ClubAgents cardroom={cardroom} club={club} />
    });

    if (isPoFeatureEnabled()) {
        tabs.push({
            title: { text: "Requests", icon: "request_quote", badge: cardroomAccount && cardroomAccount.pendingPurchasOrderCount > 0 ? { content: cardroomAccount.pendingPurchasOrderCount, color: "warning" } : null },
            header: canAddPurchaseOrder() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openPurchaseOrderTxDialog}><Icon size="lg">receipt_long</Icon>&nbsp;New Request</SoftButton> : null,
            body:
                <Grid xs={12} container>
                    <Grid item xs={12}>
                        <PurchaseOrderCard cardroom={cardroomDetailsData} account={cardroomAccount} club={club} currency={currency} currencySymbol={currencySymbol}
                            currencyConverter={currencyConverter} onOrderCreatedOrChanged={refreshAccount} />
                    </Grid>
                    <Grid item xs={12}>
                        <CurrencyConverterWidget cardroom={cardroom} selectedCurrency={currency} onCurrencyChanged={onCurrencySelectionChanged} />
                    </Grid>
                </Grid>

        });
    }

    if (isAccountFeatureEnabled()) {
        tabs.push({
            title: { text: "Accounts", icon: "account_balance" },
            header: canAddAccountTransaction() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openRecordTxDialog}><Icon size="lg">receipt_long</Icon>&nbsp;Record Transaction</SoftButton> : null,
            body:
                <Grid xs={12} container>
                    <Grid item xs={12}>
                        <AccountingAccountsCard cardroom={cardroomDetailsData} account={cardroomAccount} club={club} currency={currency} currencySymbol={currencySymbol}
                            currencyConverter={currencyConverter} onAccountUpdated={refreshAccount} />
                    </Grid>
                    <Grid item xs={12}>
                        <CurrencyConverterWidget cardroom={cardroom} selectedCurrency={currency} onCurrencyChanged={onCurrencySelectionChanged} />
                    </Grid>
                </Grid>

        });
        tabs.push({
            title: { text: "Debt Service", icon: "payments", badge: cardroomAccount && cardroomAccount.pendingTransactionCount > 0 ? { content: cardroomAccount.pendingTransactionCount, color: "warning" } : null },
            header: canAddAccountTransaction() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openRecordTxDialog}><Icon size="lg">receipt_long</Icon>&nbsp;Record Transaction</SoftButton> : null,
            body: <Grid xs={12} container>
                <Grid item xs={12}>
                    <AccountingTransactionsCard cardroom={cardroomDetailsData} account={cardroomAccount} club={club} currency={currency} currencySymbol={currencySymbol}
                        currencyConverter={currencyConverter} onAccountUpdated={refreshAccount} />
                </Grid>
                <Grid item xs={12}>
                    <CurrencyConverterWidget cardroom={cardroom} selectedCurrency={currency} onCurrencyChanged={onCurrencySelectionChanged} />
                </Grid>
            </Grid>
        });
    }

    return <>
        {cardroomDetailsData && !progressIndicatorProps.visible ? <>

            {canAddAccountTransaction() && cardroomAccount ?
                <RecordAccountTransactionDialog {...recordTxDialogProps} cardroom={cardroom} account={cardroomAccount} club={club}
                    onAdded={refreshAccount} mode="venue" />
                : null}

            {canAddPurchaseOrder() && cardroomAccount ?
                <PurchaseOrderDialog  {...recordPurchaseOrderDialogProps} cardroom={cardroom} account={cardroomAccount} club={club}
                    onChanged={refreshAccount} action="create" /> : null}

            {canAddAgent() && club ?
                <AgentEditorDialog {...openAgentEditorDialogProps} cardroom={cardroom} club={club} agent={newAgent} />
                : null}



            <SoftBox mb={3} p={1}>
                <SoftBox
                    position="relative"
                    bgColor="secondary"
                    py={3}
                    px={{ xs: 3, sm: 6, md: 1 }}
                    mt={3}
                    borderRadius="xl"
                    variant="gradient" >

                    <SoftBox
                        component="img"
                        src={wavesWhite}
                        alt="pattern-line"
                        width="100%"
                        position="absolute"
                        left="0"
                        top="0"
                        opacity={0.4}
                    />

                    <Grid container alignItems="center" position="relative">

                        <CardroomLogo cardroom={cardroom} club={club} canOpenProperties={true} />

                        <Grid item xs={12} lg={11}>
                            <Grid container>
                                <Grid item xs={6} lg={3} p={1} >
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Club ID", fontWeight: "medium" }}
                                        count={{ text: club ? club.appClubId : "", useNumberFormatter: false }}
                                        icon={{ component: "verified" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "AR", fontWeight: "medium", tooltip: "Accounts Receivable: amount yet to be collected." }}
                                        count={{ text: cardroomAccount ? cardroomAccount.accountsReceivable : 0, prefix: currencySymbol, color: "warning", useNumberFormatter: true }}
                                        icon={{ component: "credit_card" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "AP", fontWeight: "medium", tooltip: "Accounts Payable: amount yet to be paid out." }}
                                        count={{ text: cardroomAccount ? cardroomAccount.accountsPayable : 0, prefix: currencySymbol, color: "error", useNumberFormatter: true }}
                                        icon={{ component: "money" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "Players / Agents", fontWeight: "medium" }}
                                        count={{ text: club ? club.playerCount.toLocaleString() + " / " + club.agentCount.toLocaleString() : "0 / 0", color: "success", useNumberFormatter: false }}
                                        icon={{ color: "info", component: "group" }}
                                    />

                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </SoftBox>
            </SoftBox>


            <AdaptiveTab switchToMobileOnSize="md" tabs={tabs} p={1} />

        </> : <ProgressIndicator {...progressIndicatorProps} />}
    </>;
}

export default ClubDetails;