import * as Yup from "yup";
import "yup-phone";
import agentForm from "./form";

const {
    formField: { playerPoolId, poolName, commissionScheduleId, makeupAmount },
} = agentForm;



const validations = {
    edit: Yup.object().shape({
        [poolName.name]: Yup.string().required(poolName.errorMsg),
        [makeupAmount.name]:Yup.number().min(0, makeupAmount.errorMsg).required(makeupAmount.errorMsg)
    })
};

export default validations;