
const form = {
    formId: "agent-profile-editor-form",
    formField: {
        agentId: {
            name: "agentId",
            label: "Agent",
            type: "hidden",
            errorMsg: "an agent must be selected"
        },
        canEdit: {
            name: "canEdit",
            label: "Can Edit",
            type: "bool"
        },
        agentName: {
            name: "agentName",
            label: "Name",
            type: "text",
            errorMsg: "Agent name is required"
        },
        agentNickname: {
            name: "agentNickname",
            label: "Nickname",
            type: "text",
            errorMsg: "Agent nickname is required"
        },
        feeSchedule: {
            name: "feeSchedule",
            label: "Commission Schedule",
            type: "text",
            placeholder: "commission schedule",
            errorMsg: "Commission schedule is required",
        }
    },

};

export default form;
