
import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';
import DataTable from 'components/Elements/DataTable';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import { Card, CardActions, CardContent, Grid, Icon } from '@mui/material';


import { useDialog } from 'components/Elements/Dialog/common';
import UnionEditorDialog from '../SubUnion';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import SoftButton from 'components/SoftButton';
import SoftBox from 'components/SoftBox';
import whiteCurved from "assets/images/curved-images/white-curved.jpeg";

import MiniChartItemCard from 'components/Elements/MiniChartItemCard';
import MiniStatisticsCard from 'components/Elements/MiniStatisticsCard';
import { useUnionDataLoader } from '../common';

const UnionUnions = ({ cardroom, union }) => {

    function prepareUnionsForPresentation(unions, table) {
        let rows = [];

        for (var i = 0; i < unions.length; i++) {
            let unionImage = unions[i].name.substring(0, 1);

            rows.push({
                name: [unions[i].name, { image: unionImage }],
                displayName: unions[i].name,
                displayNameNoCase: unions[i].name.toLowerCase(),
                appUnionId: unions[i].appUnionId,
                playerCount: unions[i].playerCount,
                agentCount: unions[i].agentCount,
                clubCount: unions[i].clubCount,
                sourceAppName: unions[i].app ? unions[i].app.name : "None",
                union: unions[i]
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function GetEmptySchema(manageClubsHandler) {

        let schema = {
            columns: [
                {
                    Header: "Union",
                    accessor: "name",
                    Cell: ({ value: [name, data], row }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} onClick={manageClubsHandler} participant={row && row.original && row.original.union ? row.original.union : null} />
                    ),
                },
                { Header: "Union Id", accessor: "appUnionId", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "# Clubs", accessor: "clubCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "# Players", accessor: "playerCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "# Agents", accessor: "agentCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "Source", accessor: "sourceAppName", Cell: ({ value }) => <TextCell value={value} /> }
            ],
            rows: []
        };

        //if (manageClubsHandler) {
        //    schema.columns.push({ Header: "Manage", accessor: "union", Cell: ({ value, row }) => <IconCell icon="manage_accounts" color="text" clickHandler={manageClubsHandler} id={row && row.original ? row.original.union : null} /> });
        //}

        return schema;
    }

    const [unions, setUnions] = useState(null);
    const [unionsTable, setUnionsTable] = useState(null);
    const [currentUnion, setCurrentUnion] = useState(null);

    const [openUnionEditorDialog, openUnionEditorDialogProps] = useDialog();
    const [context, actions, features] = useCardroomContext(cardroom);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading unions...", handleRetry)

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const dataLoader = useUnionDataLoader();

    function manageUnionDetails(u) {
        if (u) {
            setCurrentUnion(u);

            if (canManageUnion()) openUnionEditorDialog();
        }
    }
    function canManageUnion() {
        if (!context) return false;

        return context.isAllowed(actions.club.subunion.edit);
    }

    useEffect(() => {
        if (!unions) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading unions...")
            showProgress();

            //(request, cardroom, union, enableCache, onsuccess, onerror)
            dataLoader.entity.loadUnions(clubRequest, cardroom, union, true, (sunions) => {
                if (sunions) {
                    setUnions(sunions);
                    setUnionsTable(null);
                }
            }, (ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load unions, please try again...")
            });
        }
    }, [clubRequest.execute, unions, retryCounter]) //unions 

    useEffect(() => {
        if (unions && !unionsTable) {
            setUnionsTable(prepareUnionsForPresentation(unions, GetEmptySchema(canManageUnion() ? manageUnionDetails : null)));
            progressIndicatorProps.close();
        }
    }, [unions, unionsTable]);

    function renderMobileRow(columns, row, key, collapsibleDetailAccessor) {

        const color = "secondary";
        return <Card
            sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
                backgroundImage: gradients[color]
                    ? `${linearGradient(
                        rgba(gradients[color].main, 0.85),
                        rgba(gradients[color].state, 0.85)
                    )}, url(${whiteCurved})`
                    : `${linearGradient(
                        rgba(gradients[color].main, 0.85),
                        rgba(gradients[color].state, 0.85)
                    )}, url(${whiteCurved})`,
                backgroundSize: "cover",
                backgroundPosition: "50%",
                overflow: "visible",
            })}
        >
            <CardContent>
                <SoftBox pt={2} textAlign="center">
                    <MiniStatisticsCard
                        bgColor="white"
                        title={{ text: row.cells[1].value, fontWeight: "medium" }}
                        count={row.cells[0].value[0]}
                        icon={{ color: "dark", component: "beach_access" }}
                        direction={"left"}
                        useNumericFormatter={false}
                    />
                </SoftBox>

                <SoftBox pt={2}>
                    <Grid container ml="auto" mr="auto" xs={12}>
                        <Grid item xs={6} sm={4} pr={0.25} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>

                            <MiniChartItemCard
                                color="dark"
                                icon={{ color: "primary", component: "nightlife" }}
                                label="Clubs"
                                progress={{ content: row.cells[2].value, percentage: 100 * row.cells[2].value / union.clubCount }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} pl={0.25} pr={{ xs: 0, sm: 0.25 }} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                            <MiniChartItemCard
                                color="dark"
                                icon={{ color: "primary", component: "support_agent" }}
                                label="agents"
                                progress={{ content: row.cells[4].value, percentage: 100 * row.cells[4].value / union.agentCount }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} pl={{ xs: 0, sm: 0.25 }} pt={{ xs: 1, sm: 0 }} pb={{ xs: 1, sm: 0 }}>
                            <MiniChartItemCard
                                color="dark"
                                icon={{ color: "primary", component: "person" }}
                                label="players"
                                progress={{ content: row.cells[3].value, percentage: 100 * row.cells[3].value / union.playerCount }}
                            />

                        </Grid>
                    </Grid>
                </SoftBox>
            </CardContent>
            <CardActions>
                <Grid container ml="auto" mr="auto" xs={12}>
                    <Grid item xs={12} p={1}>
                        <SoftButton ml="auto" mr="auto"
                            variant="gradient"
                            color={"dark"}
                            fullWidth
                            disabled={!canManageUnion()}
                            onClick={() => manageUnionDetails(row.original.union)}
                        >
                            SETTINGS&nbsp;
                            <Icon sx={{ fontWeight: "bold" }}>settings</Icon>
                        </SoftButton>
                    </Grid>

                </Grid>
            </CardActions>
        </Card >;

    }



    return <Card>
        <UnionEditorDialog {...openUnionEditorDialogProps} cardroom={cardroom} union={union} subunion={currentUnion} />
        {(cardroom && unions) && !progressIndicatorProps.visible ? <>
            {unionsTable ?
                <DataTable table={unionsTable} entriesPerPage={{ defaultValue: 10, visible: false }}
                    pagination={{ color: "dark" }} onRenderRow={renderMobileRow} useCustomRenderSize="md" /> : null}
        </> : <ProgressIndicator {...progressIndicatorProps} />}
    </Card>;
};

export default UnionUnions