import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig';
import useFetchWithMsal from 'hooks/useFetchWithMsal';

import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";

import { Formik, Form } from "formik";
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import { useUnionDataLoader } from 'layouts/Club/Union/components/common';
import { useClubDataLoader } from 'layouts/Club/Union/components/common';
import { useCurrencyConverter } from 'components/Elements/CurrencyConverter/currency';
import PremiumServiceSubscriptionRequired from 'components/Elements/PremiumServiceRequried';
import { useCardroomContext } from 'features';
import CreatePurchaseOrderForm from './CreateForm';
import EditPurchaseOrderForm from './EditForm';


function getFormContent(type, cardroom, account, union, club, agency, staff, players, clubs, unions, agents, purchaseOrder, mode, isAdvancedEnabled, currency, currencySymbol, currencyConverter, formData) {

    if (!cardroom || !formData) return null;

    if (cardroom.type == 1 && (!staff || !players)) return null;
    if (cardroom.type == 2 && (!players || !agents)) return "null";
    if (cardroom.type == 3 && (!clubs)) return null;
    if (cardroom.type == 5 && (!agents || !players)) return null;

    // if (type != "create" && false == isAdvancedEnabled) {
    //     return <PremiumServiceSubscriptionRequired service="Advanced Accounting" />
    // }


    switch (type) {
        case "create":
            return <CreatePurchaseOrderForm cardroom={cardroom} cardroomAccount={account} staff={staff} players={players}
                union={union} club={club} agency={agency}
                clubs={clubs} unions={unions} agents={agents} formData={formData} mode={mode} />;
        case "manage":
            //cardroom, cardroomAccount, union, club, agency, staff, players, clubs, agents, unions, purchaseOrder, formData
            return <EditPurchaseOrderForm cardroom={cardroom} cardroomAccount={account} staff={staff} players={players}
                union={union} club={club} agency={agency}
                clubs={clubs} unions={unions} agents={agents} formData={formData} mode={mode} purchaseOrder={purchaseOrder} />;
    }
}

const PurchaseOrderForm = ({ type, cardroom, cardroomAccount, union, club, agency, mode, po, onClose, onPurchaseOrderCreated }) => {

    function mapParticipantAccounts(parties, accounts) {
        const accountMap = [];
        if (accounts && accounts.length > 0) {
            for (var i = 0; i < accounts.length; i++) {
                accountMap[accounts[i].accountHolderId] = accounts[i];
            }
        }

        switch (cardroom.type) {
            case 1:
                mapHomeGameParticipants(parties, accountMap);
                break;
            case 2:
                mapOnlineClubParticipants(parties, accountMap);
                break;
            case 3:
                mapOnlineUnionParticipants(parties, accountMap);
                break;
            case 5:
                mapOnlineAgencyParticipants(parties, accountMap);
                break;
        }

    }

    function mapHomeGameParticipants(ppl, accountMap) {
        const staff = [], players = [];

        if (ppl && ppl.length > 0) {
            for (var i = 0; i < ppl.length; i++) {
                ppl[i].account = accountMap[ppl[i].id];
                let phone = ppl[i].phoneNumber, name = ppl[i].name, nickName = null;

                if (ppl[i].properties) {
                    if ((ppl[i].properties.participantType & 1) > 0) {
                        players.push(ppl[i]);
                    }

                    if ((ppl[i].properties.participantType & 254) > 0) {
                        staff.push(ppl[i]);
                    }

                    phone = ppl[i].properties.phoneNumber ? ppl[i].properties.phoneNumber : phone;
                    name = ppl[i].properties.name ? ppl[i].properties.name : name;
                    nickName = ppl[i].properties.nickName ? ppl[i].properties.nickName : nickName;
                }

                if (nickName) {
                    name = name + " \"" + nickName + "\"";
                }

                if (phone) {
                    //format phone
                    let formattedPhone = phone;
                    if (phone.length === 10) {
                        formattedPhone = "+1 (" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + " - ****";
                    }

                    name = name + " @ " + formattedPhone + "";
                }
                else if (ppl[i].isAnonymous) {
                    name = name + " (anonymous)";
                }

                ppl[i].displayName = name;
            }
        }

        setStaff(staff);
        setPlayers(players);
    }

    function mapOnlineUnionParticipants(unionEntity, accountMap) {
        const cs = [], us = [];

        if (unionEntity && unionEntity.length > 0) {
            for (var i = 0; i < unionEntity.length; i++) {
                unionEntity[i].account = accountMap[unionEntity[i].id];

                unionEntity[i].displayName = unionEntity[i].name;

                if (unionEntity[i].appUnionId) {
                    us.push(unionEntity[i]);
                }

                if (unionEntity[i].appClubId) {
                    cs.push(unionEntity[i]);
                }
            }
        }

        //the list contains both clubs and umbrellas

        //TODO: how do we handle Umbrella Unions

        setClubs(cs);
        setUnions(us);
    }

    function mapOnlineClubParticipants(clubEntity, accountMap) {
        const ps = [], as = [], us = [];

        if (clubEntity && clubEntity.length > 0) {
            for (var i = 0; i < clubEntity.length; i++) {
                clubEntity[i].account = accountMap[clubEntity[i].id];

                clubEntity[i].displayName = clubEntity[i].name;

                if (clubEntity[i].appPlayerId) {
                    ps.push(clubEntity[i]);
                }

                if (clubEntity[i].appAgentId) {
                    as.push(clubEntity[i]);
                }

                if (clubEntity[i].appUnionId) {
                    us.push(clubEntity[i]);
                }
            }
        }

        setPlayers(ps);
        setAgents(as);
        setUnions(us);
    }
    function mapOnlineAgencyParticipants(agencyEntity, accountMap) {
        //map is same as Club Map (agents/players)
        mapOnlineClubParticipants(agencyEntity, accountMap);
    }

    const { formId, formField } = form;
    const currentValidation = validations[type];

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving transaction...", handleRetry)

    const [participants, setParticipants] = useState(null);
    const [accounts, setAccounts] = useState(null);
    const [players, setPlayers] = useState(null);
    const [staff, setStaff] = useState(null);
    const [clubs, setClubs] = useState(null);
    const [unions, setUnions] = useState(null);
    const [agents, setAgents] = useState(null);

    const [pendingRequest, setPendingRequest] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);

    function isAdvancedAccountFeatureEnabled() {
        if (!context) return false;
        return context.isEnabled(features.casino.advancedAccountServices, 0);
    }

    function getDefaultCurrency() {
        var currency = "USD";
        if (union) currency = union.currency;
        if (club) currency = club.currency;
        if (agency) currency = agency.currency;

        return currencyConverter.getCurrencyFrom(currency);
    }

    const [currencyConverter, currencies] = useCurrencyConverter(cardroom);

    const [currency, setCurrency] = useState(getDefaultCurrency());

    const [currencySymbol, setCurrencySymbol] = useState(null);
    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }


    useEffect(() => {
        if (true === isCurrencyMaskingEnabled()) {
            setCurrencySymbol("");
            return;
        }

        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency]);

    const peopleRequest = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    const accountRequest = useFetchWithMsal({
        scopes: [...apiConfig.account.scopes.read, ...apiConfig.account.scopes.write],
    });

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const unionDataLoader = useUnionDataLoader();
    const clubDataLoader = useClubDataLoader();

    //load casino participants
    useEffect(() => {
        if (!participants && cardroom.type === 1) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading participant data...")
            showProgress();

            peopleRequest.execute("GET", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants/255").then((response) => {
                if (response) {
                    setParticipants(response);
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load participant data, please try again...")
            });
        }
    }, [peopleRequest.execute, participants, retryCounter]) //cardroomListData 

    //union participants
    useEffect(() => {
        if (!participants && union && (cardroom.type === 3)) {

            //union load clubs
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading union data...")
            showProgress();


            unionDataLoader.entity.loadEntities(clubRequest, cardroom, union, unionDataLoader.entities.unions | unionDataLoader.entities.clubs, true, (entities) => {
                setParticipants(entities);
            }, (ex, t) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load union " + t + ", please try again...");
            });


        }
    }, [clubRequest.execute, participants, union, retryCounter]) //cardroomListData 

    //club participants
    useEffect(() => {
        if (!participants && (cardroom.type === 2 && club)) {

            //club or agency load players/agents -- CHANGE TO CALL CLUB SERVICE
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading club data...")
            showProgress();

            clubDataLoader.entity.loadEntities(clubRequest, cardroom, club, clubDataLoader.entities.players | clubDataLoader.entities.agents | clubDataLoader.entities.unions, true, (entities) => {
                if (entities) {
                    setParticipants(entities);
                }
            }, (ex, t) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load club " + t + ", please try again...")
            })


        }
    }, [clubRequest.execute, participants, club, retryCounter]) //cardroomListData 

    //agency participants
    useEffect(() => {
        if (!participants && (cardroom.type === 5 && agency)) {

            //club or agency load players/agents -- CHANGE TO CALL CLUB SERVICE
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading agency data...")
            showProgress();

            alert('impelemnt agency loader');
        }
    }, [clubRequest.execute, participants, retryCounter]) //cardroomListData 

    //load casino accounts
    useEffect(() => {
        if (participants && !accounts) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading participant accounts...")
            showProgress();

            let accountType = 1;
            //club
            if (cardroom.type == 2) accountType = 9; //club: union || individual 
            //union
            if (cardroom.type == 3) accountType = 24; //union: union (umbrella) + club accounts            
            //agency
            if (cardroom.type == 5) accountType = 17; //agency: club or individual accounts

            // [HttpGet("{entityId:Guid}/holders/accounts/{type:int}", Name = "GetEntityAccounts")]
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/accounts/" + accountType).then((response) => {
                if (response) {
                    setAccounts(response);
                    progressIndicatorProps.close();
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load participant accounts, please try again...")
            });
        }
    }, [accountRequest.execute, accounts, participants, retryCounter])


    useEffect(() => {
        if (accounts && participants) {
            mapParticipantAccounts(participants, accounts);
        }
    }, [accounts, participants])


    const handleSubmit = (values, actions) => {
        var request = null;

        function getRequestType(v) {
            if (v == "chips") return 1;
            if (v == "tokens") return 2;

            return 0;
        }

        if (values.type === "create") {
            request = {
                accountId: values.sellerAccount,
                accountHolderId: cardroom.id,
                action: "create",
                po: {
                    sellerAccount: {
                        id: values.sellerAccount, //casino account
                        casinoId: cardroom.id,
                        name: 'Participant account'
                    },
                    buyerAccount: {
                        id: values.buyerAccount, //player/staff account
                        casinoId: cardroom.id,
                        name: 'Casino account'
                    },
                    quantity: values.quantity,
                    type: getRequestType(values.itemType),
                    estimatedCost: values.estimatedCost,
                    deliverTo: values.itemType === "tokens" ? values.deliverTo : null,
                    actualCost: null,
                    status: 1,
                    note: values.note ? values.note : null
                }
            };

        } else if (values.type === "manage") {
            request = {
                accountId: values.buyerAccount,
                accountHolderId: cardroom.id,
                action: "manage",
                po: Object.assign({}, po)
            };

            request.po.actualCost = values.actualCost;
            switch (values.approvalAction) {
                case "approve":
                    request.po.status = 2;
                    request.accountId = values.sellerAccount;
                    break;
                case "reject":
                    request.po.status = 4;
                    request.accountId = values.sellerAccount;
                    break;
                case "cancel":
                    request.po.status = 8;
                    request.accountId = values.buyerAccount;
                    break;
            }
        }

        if (request) {
            //set state to be saved
            setPendingRequest(request);
        }
    };

    useEffect(() => {
        if (pendingRequest) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving request...")
            showProgress();

            let verb = pendingRequest.action == "create" ? "POST" : "PUT";

            //  [HttpPost("{entityId:Guid}/accounts/{accountId:Guid}/po", Name = "CreatePurchaseOrder")]
            //  [HttpPut("{entityId:Guid}/accounts/{accountId:Guid}/po", Name = "UpdatedPurchaseOrder")]
            accountRequest.execute(verb, apiConfig.account.endpoint + "/" + cardroom.id + "/accounts/" + pendingRequest.accountId + "/po", pendingRequest.po).then((response) => {
                if (response) {

                    if (response.status && response.errors) {
                        throw new Error(response.errors);
                    }

                    setPendingRequest(null);
                    progressIndicatorProps.close();
                    raiseOnPOCreated();
                    raiseOnClose();
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save request, please try again...")
            });
        }
    }, [accountRequest.execute, pendingRequest, retryCounter]);

    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    function raiseOnPOCreated() {
        if (onPurchaseOrderCreated) {
            onPurchaseOrderCreated();
        }
    }

    return !progressIndicatorProps.visible ? <>
        <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
                return <Form id={formId} autoComplete="off">

                    <SoftBox p={2} >
                        <SoftBox >
                            <ProgressIndicator {...progressIndicatorProps} />

                            {progressIndicatorProps.visible ? <></> : <>
                                {getFormContent(type, cardroom, cardroomAccount, union, club, agency, staff, players, clubs, unions, agents, po, mode, isAdvancedAccountFeatureEnabled(), currency, currencySymbol, currencyConverter, {
                                    values,
                                    touched,
                                    formField,
                                    errors,
                                    setFieldValue,
                                    setFieldError,
                                    setFieldTouched,
                                    validateForm,
                                    resetForm
                                })}

                                <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                    <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                        cancel
                                    </SoftButton>

                                    <SoftButton
                                        variant="gradient"
                                        color="dark"
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        save
                                    </SoftButton>
                                </SoftBox>
                            </>

                            }

                        </SoftBox>
                    </SoftBox>

                </Form>
            }}
        </Formik></> : <ProgressIndicator {...progressIndicatorProps} />;
};

PurchaseOrderForm.defaultProps = {
    type: "create",
};

PurchaseOrderForm.propTypes = {
    type: PropTypes.oneOf([
        "create",
        "approve",
        "reject",
        "cancel"
    ]).isRequired,
    cardroom: PropTypes.object //.isRequired
};

export default PurchaseOrderForm;