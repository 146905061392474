import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { FormControlLabel, Grid, Icon, Radio, RadioGroup, Tooltip, tooltipClasses } from '@mui/material';
import { Formik, Form, ErrorMessage } from 'formik';
import FormField from 'components/Elements/Forms/FormField';
import SoftAvatar from 'components/SoftAvatar';
import FormSwitch from 'components/Elements/Forms/FormSwitch';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import { styled } from '@mui/material/styles';


import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import { useCardroomContext } from 'features';
import AgentFeeSelector from '../../AgentFees/Selector';


const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
});


const AgentEditorForm = ({ cardroom, club, agent, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { agentId, feeSchedule, canEdit, agentName, agentNickname } = formField;
    const { agentId: agentIdV,
        feeSchedule: feeScheduleV,
        canEdit: canEditV,
        agentName: agentNameV,
        agentNickname: agentNicknameV
    } = values;

    const [formDataInitialized, setFormDataInitialized] = useState(false);

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditAgent() {
        if (!context) return false;

        return true;
    }

    function canAddAgent() {
        if (!context) return false;

        if (!club) return false;
        if (!club.app) return false;

        return (club.app.features & 1) > 0;
    }

    function onAgentFeeSchedChanged(schedule) {
        setFieldValue(feeSchedule.name, schedule && schedule.id ? schedule.id : "");
    }

    useEffect(() => {
        if (!formDataInitialized && agent) {

            setFieldValue(agentId.name, agent.id ? agent.id : "00000000-0000-0000-0000-000000000000");

            setFieldValue(feeSchedule.name, agent.feeSchedule ? agent.feeSchedule.id : "");

            setFieldValue(agentName.name, agent.name);
            setFieldValue(agentNickname.name, agent.nickname);
            setFieldValue(canEdit.name, canAddAgent());

            setFormDataInitialized(true);
        }
    }, [formDataInitialized, agent]);

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={null} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">
                        {canEditV ? <>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {agentName.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={8}>
                                    <FormField onFocus={e => e.currentTarget.select()}
                                        disabled={!canEditV}
                                        type={agentName.type}
                                        name={agentName.name}
                                        value={agentNameV}
                                        placeholder={agentName.placeholder}
                                        error={(errors.agentName && touched.agentName)}
                                        success={agentNameV && agentNameV.toString().length > 0 && !errors.playerAppId}
                                    />
                                </Grid>
                            </Grid>
                        </> :
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {agentId.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {agent ? agent.name : ""}
                                    </SoftTypography>
                                </Grid>
                            </Grid>}

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {feeSchedule.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <AgentFeeSelector cardroom={cardroom} club={club} value={feeScheduleV} placeholder={feeSchedule.placeholder} emptyValue={null} type="dropdown" onSelectionChanged={onAgentFeeSchedChanged} readonly={!canEditAgent()} />
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    color="error">
                                    <ErrorMessage name={feeSchedule.name} />
                                </SoftTypography>
                            </Grid>
                        </Grid>


                    </SoftBox>
                </Grid>
            </Grid >
        </SoftBox >
    </SoftBox >;
};

AgentEditorForm.propTypes = {
    cardoom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    agent: PropTypes.object.isRequired
};

const AgentEditor = ({ cardroom, club, agent, onClose, onAgentUpdated }) => {

    const { formId, formField } = form;
    const currentValidation = validations.editor;


    const [retryCounter, setRetryCounter] = useState(0);
    const [agentRecord, setAgentRecord] = useState(null);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving agent...", handleRetry)

    const clubWrite = useFetchWithMsal({
        scopes: apiConfig.club.scopes.write,
    });

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditAgent() {
        if (!context) return false;

        return true;
    }

    const handleSubmit = (values, actions) => {

        if (false === canEditAgent()) return;

        var pa = {
            profile: {
                id: values.agentId,
                feeSchedule: {
                    id: values.feeSchedule //(!values.feeSchedule || values.feeSchedule === "none") ? "00000000-0000-0000-0000-000000000000" : values.feeSchedule
                },
                currency: club.currency
            }
        };

        if (values.canEdit === true) {
            pa.agent = Object.assign({}, pa.profile);
            pa.agent.name = values.agentName;
        }
        setAgentRecord(pa);

    };


    useEffect(() => {
        if (agentRecord) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Saving agent profile...")
            showProgress();

            function onAgentSaved(ag) {
                if (ag && agentRecord && agentRecord.profile) {

                    agentRecord.profile.id = ag.id;

                    if (agent.feeSchedule && agentRecord.profile.feeSchedule.id === agent.feeSchedule.id) {
                        onAgentConfigSaved(agent);
                    }
                    else {
                        // [HttpPut("{clubId:Guid}/casino/{casinoId:Guid}/agents/{agentId:Guid}", Name = "SaveAgentProfile")]
                        clubWrite.execute("PUT", apiConfig.club.endpoint + "/club/" + club.id + "/casino/" + cardroom.id + "/agents/" + ag.id, agentRecord.profile)
                            .then(onAgentConfigSaved)
                            .catch(onAgentConfigError);
                    }
                }
            }
            function onAgentSaveError(err) {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save agent, please try again...");
            }

            function onAgentConfigSaved(cfg) {
                if (cfg) {
                    if (cfg.status && cfg.errors) {
                        throw new Error(cfg.errors);
                    }

                    agent = Object.assign(agent, cfg);
                    raiseOnChanged(agent);
                }

                setAgentRecord(null);
                progressIndicatorProps.close();
                raiseOnClose();

            }

            function onAgentConfigError(err) {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to save agent profile, please try again...");

            }

            if (agentRecord.agent) {
                //[HttpPost("casino/{casinoId:Guid}/club/{clubId:Guid}/agent", Name = "SaveAgent")]
                clubWrite.execute("POST", apiConfig.club.endpoint + "/agent/casino/" + cardroom.id + "/club/" + club.id + "/agent", agentRecord.agent)
                    .then(onAgentSaved)
                    .catch(onAgentSaveError);
            }
            else {
                onAgentSaved(agentRecord.profile);
            }

        }

    }, [agentRecord, clubWrite.execute, retryCounter]);


    function raiseOnClose() {
        if (onClose) {
            onClose();
        }
    }

    function raiseOnChanged(a) {
        if (onAgentUpdated) {
            onAgentUpdated(a);
        }
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
    >
        {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
            return <Form id={formId} autoComplete="off">

                <SoftBox p={2} >
                    <SoftBox >
                        <ProgressIndicator {...progressIndicatorProps} />

                        {progressIndicatorProps.visible ? <></> : <>
                            <AgentEditorForm cardroom={cardroom} club={club} agent={agent} formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                                validateForm,
                                resetForm
                            }} />

                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">

                                <SoftButton variant="gradient" color="light" onClick={raiseOnClose}>
                                    cancel
                                </SoftButton>

                                <SoftButton
                                    variant="gradient"
                                    color="dark"
                                    disabled={isSubmitting || !canEditAgent()}
                                    type="submit"
                                >
                                    save
                                </SoftButton>
                            </SoftBox>
                        </>

                        }

                    </SoftBox>
                </SoftBox>

            </Form>
        }}
    </Formik>;
};

AgentEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    agent: PropTypes.object.isRequired,
    onAgentUpdated: PropTypes.func,
    onClose: PropTypes.func
};

export default AgentEditor;