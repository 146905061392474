
import { useState } from "react";

import { Card, Grid, Icon, ListItemIcon, ListItemText } from "@mui/material";

import SoftTypography from "components/SoftTypography";

import { useCardroomContext } from "features";

import BookAgentPlayerPoolPropertiesEditor from "./Editor";
import BookAgentPlayerPoolSelector from "./Selector";



const BookAgentPlayerPoolEditor = ({ cardroom, book, agent }) => {

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditPlayerPool() {
        if (!context) return false;

        return context.isAllowed(actions.club.book.agent.edit);
    }

    const addPlayerPoolPlaceHolder = canEditPlayerPool() ? { id: null, name: "New Package" } : null;


    const [currentPlayerPool, setCurrentPlayerPool] = useState(null);
    const [reloadPlayerPoolsNeeded, setReloadPlayerPoolsNeeded] = useState(false);
    const [firstLoad, setFirstLoad] = useState(0);

    function onPlayerPoolChanged(item) {
        if (firstLoad < 2 && (!item || !item.id)) {
            setFirstLoad(firstLoad + 1); //really hacky!!!!!!
            return;
        }

        setReloadPlayerPoolsNeeded(false);
        setCurrentPlayerPool(item);
    }

    function onPlayerPoolEditDone(pool) {
        setCurrentPlayerPool(null);
        setReloadPlayerPoolsNeeded(true);
    }

    function buildListItem(item) {
        if (item.id) {
            return <ListItemText><SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize">
                {item.name}
            </SoftTypography></ListItemText>;
        }
        else {
            return <>
                <ListItemIcon style={{ minWidth: "32px" }}>
                    <Icon>add</Icon>
                </ListItemIcon>
                <ListItemText><SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    {item.name}
                </SoftTypography></ListItemText></>;
        }
    }


    return <Grid width="100%" xs={12}>
        <Grid container xs={12}>
            <Grid item xs={4} p={2}>
                <BookAgentPlayerPoolSelector cardroom={cardroom} book={book} agent={agent} emptyValue={addPlayerPoolPlaceHolder}
                    onSelectionChanged={onPlayerPoolChanged} onBuildListItem={buildListItem} type="list" reload={reloadPlayerPoolsNeeded} maxHeight="375px" />
            </Grid>
            <Grid item xs={8}> <Card style={{ overflow: "auto" }}>
                {currentPlayerPool ?
                    <BookAgentPlayerPoolPropertiesEditor cardroom={cardroom} book={book} agent={agent} playerPool={currentPlayerPool} dense={true} onPoolUpdated={onPlayerPoolEditDone} />
                    :
                    <Card sx={{ height: 375 }}>
                        <Grid container xs={12} justifyContent="center" alignItems="center">
                            <Grid item xs={12} textAlign={"center"}>

                                <SoftTypography
                                    component="h5"
                                    variant="caption"
                                    color="info">
                                    Select package from the list or tap "add new" to create a new pool
                                </SoftTypography>

                            </Grid>
                        </Grid>
                    </Card>
                }</Card>
            </Grid>
        </Grid>
    </Grid>;
};


export default BookAgentPlayerPoolEditor;