import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from 'components/SoftBadge';

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

import SessionPlayers from './Players';
import SessionStaff from './Staff';
import SoftButton from 'components/SoftButton';
import SessionDashboard from '../Dashboard';
import SessionHouseTransactions from './House';
import { useCardroomContext } from 'features';
import AdaptiveTab from 'components/Elements/AdaptiveTab';

const Participants = ({ cardroom, session, onParticipantStateChanged, addPlayerHandler, addStaffHandler, addHouseTxHandler, reconcileHandler, summaryHandler, liveSummaryHandler }) => {

    const [context, actions, features] = useCardroomContext(cardroom);

    function playerStateChanged() {
        if (onParticipantStateChanged) {
            onParticipantStateChanged("player");
        }
    }
    function staffStateChanged() {
        if (onParticipantStateChanged) {
            onParticipantStateChanged("staff");
        }
    }

    function houseTxStateChanged() {
        if (onParticipantStateChanged) {
            onParticipantStateChanged("house");
        }
    }

    function canAddParticipant() {
        if (!context) return false;
        return context.isAllowed(actions.casino.session.participant.add);
    }

    function canCreateTransaction() {
        if (!context) return false;
        return context.isAllowed(actions.casino.session.transaction.create);
    }

    let playerTabLabel = "Players";
    let staffTabLabel = "Staff";
    if (session) {
        //playerTabLabel = <SoftBox>Players<SoftBadge badgeContent={session.totalPlayers} size="xs" color="info" variant="contained"><Icon fontSize="small" color="inherit"></Icon></SoftBadge></SoftBox>;
        playerTabLabel = "Players (" + session.totalPlayers + ")";
        staffTabLabel = "Staff (" + session.totalStaff + ")";
    }


    const tabs = [];

    tabs.push({
        title: { text: "Dashboard", icon: "dashboard" },
        header: session.status === 4 ? <SoftButton variant="outlined" color="info" onClick={summaryHandler}><Icon size="lg">assessment</Icon>&nbsp;Summary</SoftButton> : null,
        body: <SessionDashboard cardroom={cardroom} session={session} />
    });

    tabs.push({
        title: { text: playerTabLabel, icon: "group" },
        header: canAddParticipant() && session.status < 3 ? <SoftButton variant="outlined" color="info" onClick={addPlayerHandler}><Icon size="lg">person_add_alt_sharp</Icon>&nbsp;Add Player</SoftButton> : null,
        body: <SessionPlayers cardroom={cardroom} session={session} onPlayerStateChanged={playerStateChanged} />
    });


    tabs.push({
        title: { text: staffTabLabel, icon: "badge" },
        header: canAddParticipant() && session.status < 3 ? <SoftButton variant="outlined" color="info" onClick={addStaffHandler}><Icon size="lg">badge</Icon>&nbsp;Add Staff</SoftButton> : null,
        body: <SessionStaff cardroom={cardroom} session={session} onStaffStateChanged={staffStateChanged} />
    });


    tabs.push({
        title: { text: "House", icon: "house" },
        header: canCreateTransaction() && session && (session.status < 4) ? <SoftButton variant="outlined" color="info" onClick={addHouseTxHandler}><Icon size="lg">receipt</Icon>&nbsp;Add Transaction</SoftButton> : null,
        body: <SessionHouseTransactions cardroom={cardroom} session={session} onHouseTransactionStateChanged={houseTxStateChanged} />
    });

    return <SoftBox pt={2}><AdaptiveTab switchToMobileOnSize="md" tabs={tabs} /></SoftBox>;

}



export default Participants;