/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components

import Icon from "@mui/material/Icon";

import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

import TermsOfServiceDialog from "./components/Terms";
import PrivacyDialog from "./components/Privacy";

import { useDialog } from "components/Elements/Dialog/common";
import { Link } from "@mui/material";
function Footer({ company, links }) {
    const { href, name } = company;
    const { size } = typography;

    const [openTocDialog, tocDialogProps] = useDialog();
    const [openPrivacyDialog, privacyDialogProps] = useDialog();

    return (
        <SoftBox
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="space-between"
            alignItems="center"
            px={1.5}
        >
            <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                color="text"
                fontSize={size.sm}
                px={1.5}
            >
                &copy; {new Date().getFullYear()}, made with
                <SoftBox fontSize={size.md} color="text" mb={-0.5} mx={0.25}>
                    <Icon color="inherit" fontSize="inherit">
                        favorite
                    </Icon>
                </SoftBox>
                by
                <Link href={href} target="_blank">
                    <SoftTypography variant="button" fontWeight="medium">
                        &nbsp;{name}&nbsp;
                    </SoftTypography>
                </Link>
                for accountable gambling.
            </SoftBox>
            <SoftBox
                component="ul"
                sx={({ breakpoints }) => ({
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    listStyle: "none",
                    mt: 3,
                    mb: 0,
                    p: 0,

                    [breakpoints.up("lg")]: {
                        mt: 0,
                    },
                })}
            >

                <SoftBox component="li" px={2} lineHeight={1}>
                    <a href="https://web.telegram.org/k/#@GamboolerSupport" target="_blank" rel="noopener" >
                        <Link component="button" color="secondary">
                            <TelegramIcon />
                        </Link></a>
                </SoftBox>
                <SoftBox component="li" px={2} lineHeight={1}>
                    <a component="button" href="https://twitter.com/gamboolerapp" target="_blank" rel="noopener">
                        <Link component="button" color="secondary">
                            <TwitterIcon />
                        </Link></a>
                </SoftBox>
                <SoftBox component="li" px={2} lineHeight={1} >
                    <a href="https://www.instagram.com/gamboolerapp" target="_blank" rel="noopener">
                        <Link component="button" color="secondary">
                            <InstagramIcon />
                        </Link>
                    </a>
                </SoftBox>

                <SoftBox component="li" px={2} lineHeight={1}>
                    <Link component="button" onClick={openPrivacyDialog}>
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                            privacy
                        </SoftTypography>
                    </Link>
                </SoftBox>

                <SoftBox component="li" px={2} lineHeight={1}>
                    <Link component="button" onClick={openTocDialog}>
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                            terms
                        </SoftTypography>
                    </Link>
                </SoftBox>
            </SoftBox>
            <TermsOfServiceDialog {...tocDialogProps} />
            <PrivacyDialog {...privacyDialogProps} />
        </SoftBox>
    );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
    company: { href: "https://www.gambooler.com/", name: "gambooler.com" },
    links: [
        { href: "https://www.gambooler.com/", name: "gambooler.com" },
        //{ href: "https://www.creative-tim.com/presentation", name: "About Us" },
        //{ href: "https://www.creative-tim.com/blog", name: "Blog" },
        //{ href: "https://www.creative-tim.com/license", name: "License" },
    ],
};

// Typechecking props for the Footer
Footer.propTypes = {
    company: PropTypes.objectOf(PropTypes.string),
    links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
