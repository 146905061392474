import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { lineId, title, category, amount, splitWithSub }
} = form;



const validations = {
    editor: Yup.object().shape({
        // [lineId.name]: Yup.string().required(lineId.errorMsg),
        [title.name]: Yup.string().required(title.errorMsg),
        [category.name]: Yup.string().required(category.errorMsg),
        [amount.name]: Yup.number().min(0, "Transaction account must be greater than 0").required(amount.errorMsg),
        [splitWithSub.name]: Yup.bool().required(splitWithSub.errorMsg)
    }),
    
};

export default validations;