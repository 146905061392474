
import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import { Card, CardActions, CardContent, Grid, Icon, Menu, MenuItem, Tooltip, useMediaQuery, useTheme } from '@mui/material';

import DataTable from 'components/Elements/DataTable';
import TextCell from 'components/Elements/DataTable/components/Cells/TextCell';
import ButtonCell from 'components/Elements/DataTable/components/Cells/ButtonCell';
import ParticipantCell from 'components/Elements/DataTable/components/Cells/ParticipantCell';

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { useCardroomContext } from 'features';

import { useDialog } from "components/Elements/Dialog/common";
import FeeScheduleEditorDialog from 'layouts/Club/components/AgentFees';
import AgentEditorDialog from 'layouts/Club/components/Agent';
import IconCell from 'components/Elements/DataTable/components/Cells/IconCell';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { InvitationEditorDialog } from 'components/Elements/Invitation';

import whiteCurved from "assets/images/curved-images/white-curved.jpeg";
import MiniStatisticsCard from 'components/Elements/MiniStatisticsCard';
import SoftButton from 'components/SoftButton';
import MiniActionCard from 'components/Elements/MiniActionCard';
import { useClubDataLoader } from 'layouts/Club/Union/components/common';

const EditableCell = ({ cardroom, agent, value, canInviteUser, onInviteUser }) => {

    const [contextMenu, setContextMenu] = useState(null);
    const openContextMenu = (event) => setContextMenu(event.currentTarget);
    const closeContextMenu = () => setContextMenu(null);


    function onInviteUserClicked() {
        closeContextMenu();
        if (onInviteUser) onInviteUser(agent);
    }


    return <SoftBox display="flex" justifyContent="space-between" alignItems="center">
        <TextCell value={value} />

        {canInviteUser() && agent && agent.isActivelyManaged !== true ? <>
            <SoftTypography
                color="secondary"
                onClick={openContextMenu}
                sx={{
                    width: "16px",
                    cursor: "pointer",
                }}
            >
                <Icon fontSize="default">more_vert</Icon>
            </SoftTypography>

            <Menu anchorEl={contextMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={Boolean(contextMenu)}
                onClose={closeContextMenu}
                keepMounted
            >

                <MenuItem onClick={onInviteUserClicked}>Invite User</MenuItem>

            </Menu></> : null}
    </SoftBox >;
};


const ClubAgentMobileCard = ({ cardroom, row, club, agent, canInviteUsers, canEditAgent,
    onOpenAgentEditor, onInviteAgentUsers }) => {
    const color = "secondary";

    const theme = useTheme();
    const isXs = !useMediaQuery(theme.breakpoints.up("sm"));

    function canUsersBeInvited() {

        if (!agent || agent.isActivelyManaged === true)
            return false;

        return canInviteUsers();
    }

    let buttonGridSize = 12;
    if (canEditAgent() && canUsersBeInvited()) buttonGridSize = 6;

    return <Card
        sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
            backgroundImage: gradients[color]
                ? `${linearGradient(
                    rgba(gradients[color].main, 0.85),
                    rgba(gradients[color].state, 0.85)
                )}, url(${whiteCurved})`
                : `${linearGradient(
                    rgba(gradients[color].main, 0.85),
                    rgba(gradients[color].state, 0.85)
                )}, url(${whiteCurved})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "visible",
        })}
    >
        <CardContent>
            <SoftBox pt={2} pb={2} textAlign="center">
                <MiniStatisticsCard
                    bgColor="white"
                    title={{ text: agent.appAgentId, fontWeight: "medium" }}
                    count={agent.name}
                    icon={{ color: "dark", component: "support_agent" }}
                    direction={"left"}
                    useNumericFormatter={false}
                />
            </SoftBox>

            <SoftBox pb={2} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12} >
                    <Grid item xs={6} pr={.5}>
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "players", fontWeight: "medium" }}
                            count={{ text: agent.playerCount, color: "dark", useNumericFormatter: true, prefix: null }}
                            icon={isXs ? null : { color: "dark", component: "person" }}
                            direction={"left"}
                        />
                    </Grid>
                    <Grid item xs={6} pl={.5}>

                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "agents", fontWeight: "medium" }}
                            count={{ text: agent.agentCount, color: "dark", useNumericFormatter: true, prefix: null }}
                            icon={isXs ? null : { color: "dark", component: "support_agent" }}
                            direction={"right"}
                        />

                    </Grid>
                </Grid>
            </SoftBox>


            <SoftBox textAlign="center">
                <MiniStatisticsCard
                    bgColor="white"
                    title={{ text: "commission", fontWeight: "medium" }}
                    percentage={{ text: agent.feeSchedule ? agent.feeSchedule.name : "Not Configured", color: agent.feeSchedule ? "dark" : "error" }}
                    icon={{ color: "dark", component: "percent" }}
                    direction={"left"}
                />
            </SoftBox>

        </CardContent>
        <CardActions>
            <Grid container ml="auto" mr="auto" xs={12}>
                {canUsersBeInvited() ?
                    <Grid item xs={buttonGridSize} p={1}>
                        <SoftButton ml="auto" mr="auto"
                            variant="gradient"
                            color={"light"}
                            fullWidth
                            disabled={!canUsersBeInvited()}
                            onClick={() => onInviteAgentUsers(agent)}
                        >
                            INVITE USER&nbsp;
                            <Icon sx={{ fontWeight: "bold" }}>person_add</Icon>
                        </SoftButton>
                    </Grid> : null}

                {canEditAgent() ?
                    <Grid item xs={buttonGridSize} p={1}>
                        <SoftButton ml="auto" mr="auto"
                            variant="gradient"
                            color={"dark"}
                            fullWidth
                            disabled={!canEditAgent()}
                            onClick={() => onOpenAgentEditor(agent)}
                        >
                            SETTINGS&nbsp;
                            <Icon sx={{ fontWeight: "bold" }}>settings</Icon>
                        </SoftButton>
                    </Grid> : null}
            </Grid>
        </CardActions>
    </Card>;

}

const ClubAgents = ({ cardroom, club }) => {

    function prepareAgentsForPresentation(agents, table) {
        let rows = [];

        for (var i = 0; i < agents.length; i++) {
            let agentImage = agents[i].name.substring(0, 1);

            rows.push({
                name: [agents[i].name, { image: agentImage }],
                displayName: agents[i].name,
                displayNameNoCase: agents[i].name.toLowerCase(),
                appAgentId: agents[i].appAgentId,
                playerCount: agents[i].playerCount,
                agentCount: agents[i].agentCount,
                superAgentName: agents[i].superAgent ? agents[i].superAgent.name : "None",
                superAgentId: agents[i].superAgent ? agents[i].superAgent.name : "None",
                sourceAppName: agents[i].app ? agents[i].app.name : "None",
                agent: agents[i]
            });
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.displayNameNoCase < b.displayNameNoCase) { return -1; }
                if (a.displayNameNoCase > b.displayNameNoCase) { return 1; }
                return 0;
            });
        }

        table.rows = rows;

        return table;
    }

    function GetEmptySchema(onOpenAgentEdit, canUsersBeInvited, onOpenInvitationEditor) {
        return {
            columns: [
                {
                    Header: "Agent",
                    accessor: "name",
                    Cell: ({ value: [name, data], row }) => (
                        <ParticipantCell image={data.image} color={data.color || "dark"} name={name} selectable={false} checked={false} participant={row && row.original && row.original.agent ? row.original.agent : null} onClick={onOpenAgentEdit} />
                    ),
                },
                { Header: "Agent Id", accessor: "appAgentId", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "# Players", accessor: "playerCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },
                { Header: "# Agents", accessor: "agentCount", Cell: ({ value }) => <MoneyCell value={value} prefix="" useColorScheme={false} /> },

                {
                    Header: "Commission", accessor: "agent", Cell: ({ value }) => {

                        let icon = "done", color = "success";
                        let tooltip = "agent properties are properly set", valueText = "configured";

                        if (value.feeSchedule) {
                            return <TextCell value={value.feeSchedule.name} />;
                        }

                        if (!value.feeSchedule) {
                            icon = "error_outline";
                            color = "error";
                            tooltip = "fee schedule not set";
                            valueText = "not configured";
                        }


                        return <Tooltip title={tooltip}><span><IconCell value={valueText} icon={icon} color={color} /></span></Tooltip>;
                    }
                },
                {
                    Header: "Source", accessor: "sourceAppName", Cell: ({ value, row }) => {
                        return <EditableCell cardroom={cardroom} value={value}
                            agent={row && row.original ? row.original.agent : null}
                            onInviteUser={onOpenInvitationEditor}
                            canInviteUser={canUsersBeInvited} />;
                    }
                },
            ],
            rows: []
        };
    }

    const switchToMobileOnSize = "md";

    const theme = useTheme();
    const isMobileUi = !useMediaQuery(theme.breakpoints.up(switchToMobileOnSize));

    function openAgentEditor(agent) {
        if (!canEditAgentProfile()) return;

        if (agent) {
            setCurrentAgent(agent);
            openAgentEditorDialog();
        }
    }

    const [openAgentEditorDialog, openAgentEditorDialogProps] = useDialog();

    const [currentAgent, setCurrentAgent] = useState(null);

    const [agents, setAgents] = useState(null);

    const [agentsTable, setAgentsTable] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading club agents...", handleRetry)

    const [openInviteUserDialog, openInviteUserDialogProps] = useDialog();

    function inviteAgencyUsers(agent) {
        if (!canInviteUsers()) return;

        setCurrentAgent(agent);
        openInviteUserDialog();
    }

    const [context, actions, features] = useCardroomContext(cardroom);

    function canInviteUsers() {
        if (!context) return false;

        return context.isAllowed(actions.club.agency.invite);
    }

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const clubDataLoader = useClubDataLoader();

    useEffect(() => {
        if (!agents) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading club agents...")
            showProgress();

            clubDataLoader.entity.loadAgents(clubRequest, cardroom, club, true, (response) => {
                setAgents(response);
                setAgentsTable(null);
            }, (ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load club agents, please try again...")
            });

           
        }
    }, [clubRequest.execute, agents, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (agents && !agentsTable) {
            setAgentsTable(prepareAgentsForPresentation(agents, GetEmptySchema(canEditAgentProfile() ? openAgentEditor : null, canInviteUsers, canInviteUsers() ? inviteAgencyUsers : null)));
            progressIndicatorProps.close();
        }
    }, [agents, agentsTable]);

    function canEditAgentProfile() {
        if (!context) return false;

        return context.isAllowed(actions.club.club.edit);
    }

    function renderMobileRow(columns, row, key, collapsibleDetailAccessor) {
        //ClubAgentMobileCard = ({ cardroom, row, club, agent, canInviteUsers, canEditAgent,onOpenAgentEditor, onInviteAgentUsers

        return <ClubAgentMobileCard cardroom={cardroom} row={row} club={club} agent={row.original.agent} canInviteUsers={canInviteUsers} canEditAgent={canEditAgentProfile}
            onOpenAgentEditor={openAgentEditor} onInviteAgentUsers={inviteAgencyUsers} />

    }

    return <Card>
        <ProgressIndicator {...progressIndicatorProps} />
        {(cardroom && club) && !progressIndicatorProps.visible ? <>
            {canEditAgentProfile() ?
                <AgentEditorDialog {...openAgentEditorDialogProps} cardroom={cardroom} club={club} agent={currentAgent} /> : null}
            {canInviteUsers() ?
                <InvitationEditorDialog {...openInviteUserDialogProps} cardroom={cardroom} union={null} club={club} agency={currentAgent} /> : null}
            {agentsTable ?
                <DataTable table={agentsTable} canSearch entriesPerPage={{ defaultValue: 10, visible: !isMobileUi }} pagination={{ color: "dark" }}
                    onRenderRow={renderMobileRow} useCustomRenderSize={switchToMobileOnSize} /> : null}
        </> : null}
    </Card>;
};

export default ClubAgents