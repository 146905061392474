import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { AppBar, Grid, Icon, Menu, MenuItem, Tab, Tabs, alpha, styled, useMediaQuery, useTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SoftButton from 'components/SoftButton';
import SoftBadge from 'components/SoftBadge';

const { default: SoftBox } = require("components/SoftBox");
const { default: SoftTypography } = require("components/SoftTypography");



const TabPanel = function (props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <SoftBox sx={{ p: 3 }}>
                    <SoftTypography>{children}</SoftTypography>
                </SoftBox>
            )}
        </div>
    );
}

const a11yProps = function (index) {
    return {
        id: `adaptive-tab-${index}`,
        'aria-controls': `adaptive-tabpanel-${index}`,
    };
}



const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

function TabSelector({ tabs, onTabChanged }) {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [selectedTab, setSelectedTab] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onTabSelectionMade = (event, tab, tabIndex) => {
        setSelectedTab(tab);

        if (onTabChanged) onTabChanged(tabIndex);
        handleClose();
    };

    useEffect(() => {
        if (tabs && tabs.length > 0 && !selectedTab) {
            setSelectedTab(tabs[0]);
        }
    }, [tabs, selectedTab]);

    function getLabel(t) {
        if (t.title.badge)
            return <><span>{t.title.text}</span>&nbsp;<SoftBadge badgeContent={t.title.badge.content} color={t.title.badge.color ? t.title.badge.color : "info"} container size="xs"></SoftBadge></>; //;
        else
            return t.title.text;
    }

    return (<Grid container xs={12} justifyContent={"right"} pr={3}>
        <Grid item>
            <SoftButton
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
            >
                {selectedTab ? <>
                    {selectedTab.title.icon ? <Icon>{selectedTab.title.icon}</Icon> : null}&nbsp;{getLabel(selectedTab)}
                </> : "select a tab"}
                <KeyboardArrowDownIcon />
            </SoftButton>
            <StyledMenu
                id="tab-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {tabs ? tabs.map((tab, index) => (
                    < MenuItem
                        key={index}
                        onClick={(event) => onTabSelectionMade(event, tab, index)}
                    >
                        {tab.title.icon ?
                            <Icon>{tab.title.icon}</Icon> : null}&nbsp;{getLabel(tab)}
                    </MenuItem>
                )) : null}
            </StyledMenu>
        </Grid>
    </Grid >
    );

}


const AdaptiveTab = ({ tabs, switchToMobileOnSize }) => {

    const theme = useTheme();
    const isMobileUi = !useMediaQuery(theme.breakpoints.up(switchToMobileOnSize));

    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    const tabChanged = (index) => {
        setTabValue(index);
    }

    return <SoftBox>
        <Grid container alignItems="center" xs={12}>
            <Grid item xs={6} lg={4}>
                <AppBar position="static">
                    {tabs.map((tab, index) => {
                        if (index !== tabValue) return null;

                        const p = tab.header ? 3 : 0;
                        return <SoftBox pr={p} pl={p}>{tab.header}</SoftBox>;
                    })}
                </AppBar>
            </Grid>
            <Grid item xs={6} lg={8} sx={{ ml: "auto" }}>
                <AppBar position="static">
                    {isMobileUi ? <TabSelector tabs={tabs} onTabChanged={tabChanged} /> :
                        <Tabs
                            orientation="horizontal"
                            value={tabValue}
                            onChange={handleSetTabValue}
                            sx={{ background: "transparent" }}>

                            {tabs.map((tab, index) => {
                                function getLabel(t) {
                                    if (t.title.badge)
                                        return <><span>{t.title.text}</span>&nbsp;<SoftBadge badgeContent={t.title.badge.content} color={t.title.badge.color ? t.title.badge.color : "info"} container size="xs"></SoftBadge></>; //;
                                    else
                                        return t.title.text;
                                }
                                return <Tab disabled={tab.disabled} label={getLabel(tab)} style={{ whiteSpace: "nowrap" }} icon={<Icon fontSize="small" color="inherit">{tab.title.icon}</Icon>} {...a11yProps(index)} />;
                            })}
                        </Tabs>
                    }
                </AppBar>
            </Grid>

        </Grid>
        <Grid container alignItems="center" xs={12}>
            <Grid item xs={12} sx={{ ml: "auto", mr: "auto" }}>
                <SoftBox>

                    {tabs.map((tab, index) => {
                        return <TabPanel value={tabValue} index={index}>
                            {tab.body}
                        </TabPanel>;
                    })}
                </SoftBox>
            </Grid>
        </Grid>
    </SoftBox>;
};

/*
tab: PropTypes.PropTypes.shape({
    title: PropTypes.shape({
        text: PropTypes.string,
        icon: PropTypes.string
    }),
    header: PropTypes.node,
    body: PropTypes.node
})*/

AdaptiveTab.propTypes = {
    tabs: PropTypes.array.isRequired,
    switchToMobileOnSize: PropTypes.string.isRequired,

};

export default AdaptiveTab;