/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { menuItem, menuImage } from "./styles";
import SoftBadge from "components/SoftBadge";


const InboxFolderItem = forwardRef(({ color, image, title, messageCount, ...rest }, ref) => (
    <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
        <SoftBox
            width="2.25rem"
            height="2.25rem"
            mt={0.25}
            mr={2}
            mb={0.25}
            borderRadius="lg"
            sx={(theme) => menuImage(theme, { color })}
        >
            {image}
        </SoftBox>
        <SoftBox whiteSpace="nowrap">
            <SoftTypography variant="button" textTransform="capitalize" fontWeight="regular">
                <strong>{title}</strong> ({messageCount}) </SoftTypography>
        </SoftBox>
    </MenuItem>
));

// Setting default values for the props of NotificationItem
InboxFolderItem.defaultProps = {
    color: "dark",
};

// Typechecking props for the NotificationItem
InboxFolderItem.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    image: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    messageCount: PropTypes.string.isRequired,
};

export default InboxFolderItem;
