

import { Grid, Icon, ListItemIcon, ListItemText } from "@mui/material";


import SoftTypography from "components/SoftTypography";
import { useCardroomContext } from "features";

import { useState } from "react";

import Card from "@mui/material/Card";
import NotificationGroupSelector from "./Selector";
import NotificationGroupEditor from "./Editor";


import { apiConfig } from "config/apiConfig";


function loader(method, type) {
    this.method = method;
    this.type = type;
}


const notifyDataLoader = {
    loadNotificationGroups: function (request, cardroom, enableCache, onsuccess, onerror) {
        if (enableCache && cardroom && cardroom.notificationGroups) {
            if (onsuccess) onsuccess(cardroom.notificationGroups);
            return;
        }

        request.execute("GET", apiConfig.notification.endpoint + "/venue/" + cardroom.id + "/groups").then((groups) => {
            if (groups) {
                if (enableCache) cardroom.notificationGroups = groups;
            }

            if (onsuccess) onsuccess(groups);
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    saveNotificationGroup: function (request, cardroom, notificationGroup, onsuccess, onerror) {
        request.execute("POST", apiConfig.notification.endpoint + "/venue/" + cardroom.id + "/groups", notificationGroup).then((group) => {
            if (onsuccess) onsuccess(group);
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    }
}

const entitiesEnum = {

};

const useNotificationDataLoader = () => {
    return {
        entity: notifyDataLoader,
        entities: entitiesEnum
    };
};


export {
    useNotificationDataLoader
}

const CardroomNotificationGroupEditor = ({ cardroom }) => {

    const [context, actions, features] = useCardroomContext(cardroom);

    function canManageNotificationGroup() {
        if (!context) return false;

        return true;
    }


    const addGroupPlaceHolder = canManageNotificationGroup() ? { id: null, name: "New Group" } : null;

    const [currentNotificationGroup, setCurrentNotificationGroup] = useState(null);
    const [reloadGroupsNeeded, setReloadGroupsNeeded] = useState(false);

    function onNotificationGroupChanged(item) {
        //if (item) {
        //    setReloadGroupsNeeded(false);
        // }

        setCurrentNotificationGroup(item);
    }

    function onNotificationGroupEditDone(canceled) {
        setCurrentNotificationGroup(null);
        setReloadGroupsNeeded(true);
    }

    function buildListItem(item) {
        if (item.id) {
            if (item.id === "403") {
                return <>
                    <ListItemIcon style={{ minWidth: "32px" }}>
                        <Icon color={"error"}>block</Icon>
                    </ListItemIcon>
                    <ListItemText><SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize">
                        {item.name}
                    </SoftTypography></ListItemText></>;
            }
            else {
                return <ListItemText><SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    {item.name}
                </SoftTypography></ListItemText>;
            }
        }
        else {
            return <>
                <ListItemIcon style={{ minWidth: "32px" }}>
                    <Icon>add</Icon>
                </ListItemIcon>
                <ListItemText><SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    {item.name}
                </SoftTypography></ListItemText></>;
        }
    }

    return <Grid width="100%" xs={12}>
        <Grid container xs={12}>
            <Grid item xs={4} p={2}>
                <NotificationGroupSelector cardroom={cardroom} emptyValue={addGroupPlaceHolder}
                    onSelectionChanged={onNotificationGroupChanged} onBuildListItem={buildListItem} type="list" reload={reloadGroupsNeeded} maxHeight="375px" />
            </Grid>
            <Grid item xs={8}> <Card style={{ overflow: "auto" }}>
                {currentNotificationGroup ?
                    <NotificationGroupEditor cardroom={cardroom} notificationGroup={currentNotificationGroup} dense={true} onGroupUpdated={onNotificationGroupEditDone} />
                    :
                    <Card sx={{ height: 375 }}>
                        <Grid container xs={12} justifyContent="center" alignItems="center">
                            <Grid item xs={12} textAlign={"center"}>

                                <SoftTypography
                                    component="h5"
                                    variant="caption"
                                    color="info">
                                    Select notification group from the list or tap "new group" to create a new notification group
                                </SoftTypography>

                            </Grid>
                        </Grid>
                    </Card>
                }</Card>
            </Grid>
        </Grid>
    </Grid>;
};

export default CardroomNotificationGroupEditor;