
import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from "prop-types";

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';
import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';
import SoftTypography from 'components/SoftTypography';

import DataBoundList from 'components/Elements/DataBound/List';


const FeeTierScheduleSelector = ({ cardroom, union, club, agency, value, readonly, placeholder, emptyValue, onSelectionChanged, type, onBuildListItem, reload, maxHeight }) => {

    function getEffectiveTiers() {
        if (union && union.tiers) return union.tiers;
        if (club && club.tiers) return club.tiers;
        if (agency && agency.tiers) return agency.tiers;

        return null;
    }

    const [tiers, setTiers] = useState(getEffectiveTiers());


    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "retrieving fee tiers...", handleRetry)


    const [availableOptions, setAvailableOptions] = useState(null);

    const [selectedValue, setSelectedValue] = useState(value ? value : emptyValue);
    const [selectedIndex, setSelectedIndex] = useState(-2);

    const [recalcSelectedIndex, setRecalcSelectedIndex] = useState(false);


    function getSelectedIndex() {
        let dataSource = availableOptions || getDataSource();

        if (!dataSource) return -1;
        if (!selectedValue) return -1;

        for (var i = 0; i < dataSource.length; i++) {
            if (dataSource[i].id == selectedValue.id || dataSource[i].id == selectedValue) {
                return i;
            }
        }

        return -1;
    }

    function findValueInDataSource(v) {
        let dataSource = getDataSource();

        if (!dataSource) return null;
        if (!v) return null;

        for (var i = 0; i < dataSource.length; i++) {
            if (dataSource[i].id == v.id || dataSource[i].id == v) {
                return dataSource[i];
            }
        }

        return -1;
    }

    function getDataSource() {

        if (!tiers) return null;

        let rows = [...tiers];

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });
        }


        if (emptyValue) {
            //if (rows.length === 0) rows.push(emptyValue);
            //else 
            rows.unshift(emptyValue);
        }

        return rows;
    }

    function handleScheduleChange({ item, index }) {
        setSelectedIndex(null);
        setSelectedValue(item);

        raiseSelectionChanged(item);
    }

    function raiseSelectionChanged(item) {
        if (onSelectionChanged) {
            onSelectionChanged(item);
        }
    }

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    useEffect(() => {
        if (reload === true) {
            setTiers(null);
            setSelectedIndex(-1);
        }
    }, [reload]);

    useEffect(() => {
        if (!tiers) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("retrieving fee tiers...")
            showProgress();

            // [HttpGet("{unionId:Guid}/casino/{casinoId:Guid}/tiers", Name = "GetUnionMonetaryTiers")]
            let url = apiConfig.club.endpoint;
            if (union) {
                url += "/union/" + union.id + "/casino/" + cardroom.id + "/tiers";
            }
            else if (club) {
                url += "/club/" + club.id + "/casino/" + cardroom.id + "/tiers";
            }
            else if (agency) {
                url += "/agency/" + agency.id + "/casino/" + cardroom.id + "/tiers";
            }

            execute("GET", url).then((response) => {
                if (response) {

                    if (union) union.tiers = response;
                    if (agency) agency.tiers = response;
                    if (club) club.tiers = response;

                    setTiers(response);
                }
                progressIndicatorProps.close();

            }).catch((e) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to retrieve fee tiers, please try again...")
            });

        }

    }, [tiers, execute, retryCounter]);

    useEffect(() => {

        if (tiers) {
            setAvailableOptions(getDataSource());

            if (selectedValue && (!selectedIndex || selectedIndex < -1)) {
                setRecalcSelectedIndex(true);
            }

        }

    }, [tiers, selectedIndex, selectedValue]);

    useEffect(() => {
        if (tiers && value) {

            //find in datasource
            const v = findValueInDataSource(value);
            setSelectedValue(v);
            if (v) raiseSelectionChanged(v);
        }

    }, [tiers, value]);

    useEffect(() => {
        if (value) {
            //find in datasource
            setRecalcSelectedIndex(true);
        }

    }, [value, selectedIndex]);

    useEffect(() => {
        if (recalcSelectedIndex && availableOptions) {
            setSelectedIndex(getSelectedIndex());

            setRecalcSelectedIndex(false);
        }
    }, [recalcSelectedIndex, availableOptions]);

    return <>
        <ProgressIndicator  {...progressIndicatorProps} />
        {!progressIndicatorProps.visible ? <>
            {readonly ?
                <SoftTypography
                    variant="caption"
                    fontWeight="regular"
                    color="dark"
                > {selectedValue ? selectedValue.name : "[None]"}
                </SoftTypography> : <>{availableOptions ?
                    <>
                        {type === "dropdown" ?
                            <DataBoundSelect placeholder={placeholder} data={availableOptions} valueName="id" textName="name" onChange={handleScheduleChange} size="medium" selectedIndex={selectedIndex} /> : null}
                        {type === "list" ? <DataBoundList data={availableOptions} valueName="id" textName="name" onChange={handleScheduleChange} selectedIndex={selectedIndex} onBuildListItem={onBuildListItem} maxHeight={maxHeight} /> : null}
                    </>
                    : null}
                </>
            }</> : null}
    </>;
};

FeeTierScheduleSelector.propTypes = {
    cardoom: PropTypes.object.isRequired,
    union: PropTypes.object,
    club: PropTypes.object,
    agency: PropTypes.object,
    readonly: PropTypes.bool,
    placeholder: PropTypes.string,
    emptyValue: PropTypes.object,
    onSelectionChanged: PropTypes.func,
    type: PropTypes.oneOf(["list", "dropdown"]),
    maxHeight: PropTypes.string
};

FeeTierScheduleSelector.defaultProps = {
    readonly: false,
    placeholder: "please make a selection",
    emptyValue: { id: null, name: "None" },
    type: "dropdown",
    maxHeight: "200px"
};

export default FeeTierScheduleSelector;