import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import MoneyCell from "components/Elements/DataTable/components/Cells/MoneyCell";
import TextCell from "components/Elements/DataTable/components/Cells/TextCell";


import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { Grid, Card, CardActions, CardContent, Icon, Menu, MenuItem, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import DataTable from 'components/Elements/DataTable';
import DateCell from 'components/Elements/DataTable/components/Cells/DateCell';
import { useUnionDataLoader } from 'layouts/Club/Union/components/common';
import { useClubDataLoader } from 'layouts/Club/Union/components/common';
import { useAgencyDataLoader } from 'layouts/Club/Union/components/common';

import apple from "assets/graphics/logos/paynet/96px/apple.png";
import cashapp from "assets/graphics/logos/paynet/96px/cashapp.png";
import paypal from "assets/graphics/logos/paynet/96px/paypal.png";
import venmo from "assets/graphics/logos/paynet/96px/venmo.png";
import zelle from "assets/graphics/logos/paynet/96px/zelle.png";
import crypto from "assets/graphics/logos/paynet/96px/crypto.png";
import IconCell from 'components/Elements/DataTable/components/Cells/IconCell';
import SoftBox from 'components/SoftBox';
import { usePlayerCalculator } from 'layouts/Club/components/Reports/common/calculator';

import { useDialog } from 'components/Elements/Dialog/common';
import ButtonCell from 'components/Elements/DataTable/components/Cells/ButtonCell';


import whiteCurved from "assets/images/curved-images/white-curved.jpeg";
import MiniActionCard from 'components/Elements/MiniActionCard';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import AccountTransactionReceiptDialog from '..';
import { useCardroomContext } from 'features';
import RecordAccountTransactionDialog from '../../Dialogs';


function convertTransactionsToTable(transactions, holders, cardroom, account, table, calculator, currency, currencySymbol, currencyConverter) {

    const holderMap = [];
    if (holders) {
        for (var i = 0; i < holders.length; i++) {
            if (!holderMap[holders[i].id]) {
                holderMap[holders[i].id] = holders[i];
            }
        }
    }

    if (!holderMap[cardroom.id]) {
        holderMap[cardroom.id] = cardroom;
    }

    //add known accounts: Expense, Distribution, Contribution
    const knownAccounts = [
        { id: "628702ed-0a11-4287-a04e-bebd33593d70", name: "Expenses", knownAccount: true },
        { id: "8e65e26c-ad25-49df-a73e-b0bf31dcd35c", name: "Contribution", knownAccount: true },
        { id: "133f0f7e-9635-45b2-b3d9-0ba2bebf97a6", name: "Distribution", knownAccount: true }
    ];

    for (var i = 0; i < knownAccounts.length; i++) {
        if (!holderMap[knownAccounts[i].id]) {

            const clone = Object.assign({}, cardroom);
            clone.name = knownAccounts[i].name;
            clone.knownAccountId = knownAccounts[i].id;
            holderMap[knownAccounts[i].id] = clone;
        }
    }

    const txPrefix = currencySymbol ? "$" : "";

    function getTxType(v, swap) {

        if (swap) {
            switch (v) {
                case 1:
                    return getTxType(2, false);
                case 2:
                    return getTxType(1, false);
            }
        }

        switch (v) {
            case 1:
                return "Credit";
            case 2:
                return "Debit";
            case 3:
                return "Debt Cancelation";
            case 4:
                return "Initial Balance";
        }

        return "Unknown";
    }

    function getTxIntent(v) {
        let intent = "";

        if (v === 0) {
            return "Unknown";
        }

        if ((v & 8) > 0) intent = "Settlement";
        if ((v & 4) > 0) intent = "Marker";

        if ((v & 1) > 0) intent += (intent ? " p" : "P") + "ayment";

        if ((v & 2) > 0) intent += (intent ? " r" : "R") + "econciliation";

        if ((v & 16) > 0) intent += (intent ? " a" : "A") + "djustment";

        if ((v & 32) > 0) intent += (intent ? " e" : "E") + "xpense";

        if ((v & 64) > 0) intent += (intent ? " p" : "P") + "re-payment";

        if ((v & 128) > 0) intent += (intent ? " t" : "T") + "ransfer";

        return intent;
    }

    function getTxStatus(v) {

        switch (v) {
            case 1:
                return "pending";
            case 2:
                return "completed";
            case 4:
                return "rejected";
            case 8:
                return "canceled";
        }

        return "Unknown";
    }

    function getPayMethod(v) {
        if (v === 1) return "cash";
        if (v === 2) return "ewallet";
        if (v === 3) return "credit";
        if (v === 4) return "account"
        if (v === 5) return "crypto"

        return null;
    }

    function getSignedAmount(v, amount, swap) {

        if (swap) {
            switch (v) {
                case 1:
                case 4:
                    return getSignedAmount(2, amount, false);
                case 2:
                case 3:
                    return getSignedAmount(1, amount, false);
            }
        }

        amount = Math.abs(amount);

        switch (v) {
            case 1:
            case 4:
                return amount;;
            case 2:
            case 3:
                return -1 * amount;
        }

        return amount;
    }

    function getParticipantName(p) {
        if (!p) return "[Unknown]";

        let name = p.name;

        if (p.properties) {
            name = p.properties.name ? p.properties.name : name;
            if (p.properties.nickName) {
                name += "(" + p.properties.nickName + ")";
            }
        }

        return name;
    }

    function getDescription(tx) {
        if (!tx) return "";

        function getCreditDescription(t) {

            return getDebitCreditDescription(t, "credited", "debited");
        }

        function getDebitDescription(t) {
            return getDebitCreditDescription(t, "debited", "credited");
        }

        function getActionDescription(intent, action) {
            if (action === "debited") {
                if ((intent & 1) > 0) { return "had made a payment"; }
                if ((intent & 16) > 0) { return "had made an adjustment"; }

                if ((intent & 64) > 0) { return "has pre-paid"; }

                if ((intent & 32) > 0) { return "was charged for an expense"; }

                if ((intent & 4) > 0) { return "had marker debt applied"; }
                if ((intent & 8) > 0) { return "had settlment debt applied"; }

                if ((intent & 2) > 0) { return "had " + action; }
            }
            else if (action === "credited") {
                if ((intent & 1) > 0) { return "received a payment"; }
                if ((intent & 16) > 0) { return "received an adjustment"; }

                if ((intent & 64) > 0) { return "received a pre-payment"; }

                if ((intent & 32) > 0) { return "was credited for an expense"; }

                if ((intent & 4) > 0) { return "marker credit applied"; }
                if ((intent & 8) > 0) { return "settlment credit applied"; }

                if ((intent & 2) > 0) { return action; }
            }


            return "";
        }

        function getDebitCreditDescription(t, srcAction, trgAction) {

            let descr = "";

            if (t.sourceAccount.accountHolderId == t.sourceAccount.casinoId) {
                descr = cardroom.name;
            }
            else {
                descr = getParticipantName(holderMap[t.sourceAccount.accountHolderId]);
            }


            //(srcAction === "debited" ? " debt added " : " received a payment") +
            descr += "'s account " + getActionDescription(t.intent, srcAction) + " and ";

            if (t.targetAccount.accountHolderId == t.targetAccount.casinoId) {
                descr += cardroom.name;
            }
            else {
                descr += getParticipantName(holderMap[t.targetAccount.accountHolderId]);
            }

            descr += "'s account " + getActionDescription(t.intent, trgAction) + " in the amount of " + formatAmount(t.payment.amount);

            return descr;
        }

        function getDebtForgivenDescription(t) {
            return getDebitCreditDescription(t, "debited", "credited") + " as debt forgiveness";
        }
        function getInitDescription(t) {
            return getDebitCreditDescription(t, "")
            //return getDebitCreditDescription(t, "credited", "credited") + " as debt forgiveness";
        }

        let d = '';
        switch (tx.type) {
            case 1:
                d = getCreditDescription(tx);
                break;
            case 2:
                d = getDebitDescription(tx);
                break;
            case 3:
                d = getDebtForgivenDescription(tx);
                break;
            case 4:
                d = getInitDescription(tx);
                break;
        }

        if (tx.description) {
            d += " (" + tx.description + ")";
        }
        return d;
    }

    function formatAmount(amount) {
        var delimiter = ","; // replace comma if desired
        var a = amount.toString().split('.', 2)
        var d = a[1];
        var i = parseInt(a[0]);
        if (isNaN(i)) { return ''; }
        var minus = '';
        if (i < 0) { minus = '-'; }
        i = Math.abs(i);
        var n = new String(i);
        var a = [];
        while (n.length > 3) {
            var nn = n.substring(n.length - 3);
            a.unshift(nn);
            n = n.substring(0, n.length - 3);
        }
        if (n.length > 0) { a.unshift(n); }
        n = a.join(delimiter);
        if (!d || d.length < 1) { amount = n; }
        else { amount = n + '.' + d; }
        amount = minus + txPrefix + amount;
        return amount;
    }

    function getTags(tx) {
        if (!tx || !tx.tags) return "";
        const t = [];
        tx.tags.map((tg) => t.push(tg.value));

        return t.join(", ");
    }

    var rows = [];

    if (transactions && transactions.length > 0) {
        for (var i = 0; i < transactions.length; i++) {
            let payment = transactions[i].payment || {};

            let srcEntity = null, trgEntity = null;

            if (transactions[i].sourceAccount && holderMap[transactions[i].sourceAccount.accountHolderId]) {
                srcEntity = holderMap[transactions[i].sourceAccount.accountHolderId];
            }

            if (transactions[i].targetAccount && holderMap[transactions[i].targetAccount.accountHolderId]) {
                trgEntity = holderMap[transactions[i].targetAccount.accountHolderId];
            }

            let swap = false; //trgEntity.id != account.accountHolderId;

            transactions[i].context = {
                srcEntity: srcEntity,
                trgEntity: trgEntity,
                description: getDescription(transactions[i]),
                type: getTxType(transactions[i].type, swap),
                status: getTxStatus(transactions[i].status),
                intent: getTxIntent(transactions[i].intent),
                method: getPayMethod(payment.paymentMethod),
                amount: getSignedAmount(transactions[i].type, payment.amount, swap)
            };

            var row = {
                id: transactions[i].id,
                occuredOn: transactions[i].occuredOn + "Z",
                type: transactions[i].context.type,
                intent: transactions[i].context.intent,
                status: transactions[i].context.status,
                method: transactions[i].context.method,
                description: transactions[i].context.description,
                amount: transactions[i].context.amount,
                from: transactions[i].sourceAccount,
                to: transactions[i].targetAccount,
                tags: getTags(transactions[i]),
                payment: payment,
                transaction: transactions[i]
            };

            rows.push(row);
        }
    }

    table.rows = rows;

    return table;
}

function GetTransactionTableSchema(onOpenTransaction, onForwardTransactin, isEmbedded, currencySymbol) {

    function getTxAccountName(tx) {
        if (tx && tx.context && tx.context.trgEntity) {
            return tx.context.trgEntity.name;
        }
        return "?";
    }

    const txPrefix = currencySymbol ? "$" : "";

    return {
        columns: [
            { Header: "date", accessor: "occuredOn", Cell: ({ value }) => <DateCell value={value} format="dateAndTime" display="locale" /> },
            { Header: "type", accessor: "type", Cell: ({ value }) => <TextCell value={value} /> },
            { Header: "intent", accessor: "intent", Cell: ({ value }) => <TextCell value={value} /> },
            {
                Header: "payment type", accessor: "method",
                Cell: ({ value, row }) => {
                    let icon = "", color = "text";
                    let valueText = value;
                    let vendor = row.original.payment.ePaymentVendorName;


                    switch (value) {
                        case "cash":
                            icon = "money";
                            color = "success";
                            break;
                        case "ewallet":
                            icon = "local_atm";
                            color = "success";
                            if (vendor) {

                                switch (vendor) {
                                    case "zelle":
                                        valueText = "Zelle";
                                        //icon =zelle; // 
                                        icon = <SoftBox component="img" src={zelle} width="16px" />;
                                        break;
                                    case "venmo":
                                        valueText = "Venmo";
                                        //icon = venmo; //
                                        icon = <SoftBox component="img" src={venmo} width="16px" />;
                                        break;
                                    case "apple":
                                        valueText = "Apple Pay";
                                        //icon = apple; //
                                        icon = <SoftBox component="img" src={apple} width="16px" />;
                                        break;
                                    case "paypal":
                                        valueText = "PayPal";
                                        //icon = paypal; //
                                        icon = <SoftBox component="img" src={paypal} width="16px" />;
                                        break;
                                    case "cashapp":
                                        valueText = "Cash App";
                                        //icon = cashapp; //
                                        icon = <SoftBox component="img" src={cashapp} width="16px" />;
                                        break;
                                    default:
                                        valueText = vendor ? vendor : valueText;
                                        break;
                                }
                            }
                            break;
                        case "credit":
                            icon = "credit_card";
                            color = "warning";
                            break;
                        case "crypto":
                            valueText = "Crypto";
                            //icon = cashapp; //
                            icon = <SoftBox component="img" src={crypto} width="16px" />;
                            break;
                        case "account":
                            icon = "account_balance";
                            color = "success";
                            break;
                        default:
                            color = "error";
                            icon = "question_mark";
                            break;
                    }

                    return <IconCell value={valueText} icon={icon} color={color} />; //<ImageCell height="16px" value={valueText} img={icon} width={null} />;
                }
            },
            {
                Header: "status", accessor: "status",
                Cell: ({ value }) => {
                    let icon = "", color = "text";
                    let valueText = value;

                    switch (value) {
                        case "pending":
                            icon = "hourglass_empty_outlined";
                            color = "warning";
                            break;
                        case "completed":
                            icon = "check";
                            color = "success";
                            break;
                        case "canceled":
                            icon = "close_outlined";
                            color = "error";
                            break;
                        case "rejected":
                            icon = "thumb_down_off_alt";
                            color = "warning";
                            break;
                    }

                    return <IconCell value={valueText} icon={icon} color={color} />; //clickHandler={statusIconClickHandler}
                }
            },

            { Header: "amount", accessor: "amount", Cell: ({ value }) => <MoneyCell value={value} prefix={txPrefix} useFormatter={true} useColorScheme={true} /> },
            { Header: "account", accessor: "to", Cell: ({ value, row }) => <TextCell value={getTxAccountName(row.original.transaction)} /> },
            { Header: "tags", accessor: "tags", Cell: ({ value }) => <TextCell value={value} /> },
            {
                Header: "details", accessor: "transaction", Cell: ({ value, row }) => {


                    function canForward() {
                        if (isEmbedded === true) return false;

                        if (!value) return false;

                        if (value.status != 2) return false;

                        const validIntent = 1 | 64; //payment & pre-payemtn

                        return ((value.intent & validIntent) > 0);

                    }

                    return <SoftBox >
                        <Tooltip title="view receipt">
                            <span style={{ marginRight: "25px" }}>
                                <ButtonCell id={value} onButtonClicked={onOpenTransaction} icon="launch" color="secondary" />
                            </span>
                        </Tooltip>
                        {canForward() ? <Tooltip title="forward transaction">
                            <span style={{ marginRight: "25px" }}>
                                <ButtonCell id={value} onButtonClicked={onForwardTransactin} icon="arrow_forward" color="secondary" />
                            </span>
                        </Tooltip> : null}
                    </SoftBox>;
                }
            }
        ],
        rows: [],
    };
}

function GetEmptySchema() {
    return {
        columns: [],
        rows: []
    }
}


const AccountTransactionMobileCard = ({ cardroom, account, row, transaction, onOpenDetails }) => {
    const color = "secondary";

    const theme = useTheme();
    const isXs = !useMediaQuery(theme.breakpoints.up("sm"));

    function getPaymentMethodScheme(tx) {
        let icon = "", color = "text";
        let valueText = tx.context.method;

        let vendor = tx.payment.ePaymentVendorName;

        switch (tx.context.method) {
            case "cash":
                icon = "money";
                color = "success";
                valueText = "Cash";
                break;
            case "ewallet":
                icon = "local_atm";
                color = "success";
                if (vendor) {

                    switch (vendor) {
                        case "zelle":
                            valueText = "Zelle";
                            //icon =zelle; // 
                            icon = <SoftBox component="img" src={zelle} width="16px" />;
                            break;
                        case "venmo":
                            valueText = "Venmo";
                            //icon = venmo; //
                            icon = <SoftBox component="img" src={venmo} width="16px" />;
                            break;
                        case "apple":
                            valueText = "Apple Pay";
                            //icon = apple; //
                            icon = <SoftBox component="img" src={apple} width="16px" />;
                            break;
                        case "paypal":
                            valueText = "PayPal";
                            //icon = paypal; //
                            icon = <SoftBox component="img" src={paypal} width="16px" />;
                            break;
                        case "cashapp":
                            valueText = "Cash App";
                            //icon = cashapp; //
                            icon = <SoftBox component="img" src={cashapp} width="16px" />;
                            break;
                        default:
                            valueText = vendor ? vendor : valueText;
                            break;
                    }
                }
                break;
            case "credit":
                icon = "credit_card";
                color = "warning";
                valueText = "Credit";
                break;
            case "crypto":
                valueText = "Crypto";
                //icon = cashapp; //
                icon = <SoftBox component="img" src={crypto} width="16px" />;
                break;
            case "account":
                icon = "account_balance";
                color = "success";
                valueText = "Account";
                break;
            default:
                color = "error";
                icon = "question_mark";
                valueText = "Unknown";
                break;
        }

        return {
            text: valueText,
            color: color,
            icon: icon
        }; //clickHandler={statusIconClickHandler}


    }

    function getPaymentStatusScheme(status) {
        let icon = "", color = "text";
        let valueText = status;

        switch (status) {
            case "pending":
                icon = "hourglass_empty_outlined";
                color = "warning";
                valueText = "Pending";
                break;
            case "completed":
                icon = "check";
                color = "success";
                valueText = "Completed";
                break;
            case "canceled":
                icon = "close_outlined";
                color = "error";
                valueText = "Canceled";
                break;
            case "rejected":
                icon = "thumb_down_off_alt";
                color = "warning";
                valueText = "Rejected";
                break;
        }

        return {
            text: valueText,
            color: color,
            icon: icon
        }; //clickHandler={statusIconClickHandler}


    }

    const [context, actions, features] = useCardroomContext(cardroom);

    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    const paymentStatus = getPaymentStatusScheme(transaction.context.status);
    const paymentMethod = getPaymentMethodScheme(transaction);

    return <Card
        sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
            backgroundImage: gradients[color]
                ? `${linearGradient(
                    rgba(gradients[color].main, 0.85),
                    rgba(gradients[color].state, 0.85)
                )}, url(${whiteCurved})`
                : `${linearGradient(
                    rgba(gradients[color].main, 0.85),
                    rgba(gradients[color].state, 0.85)
                )}, url(${whiteCurved})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "visible",
        })}
    >
        <CardContent>
            <SoftBox pt={2} pb={2} textAlign="center">

                <MiniActionCard
                    bgColor="white"
                    title={{ text: "amount", fontWeight: "medium" }}
                    count={{ text: transaction.payment.amount, color: "dark", useNumericFormatter: true, prefix: isCurrencyMaskingEnabled() ? "" : "$" }}
                    icon={{ color: "success", component: "paid" }}
                    direction={"left"}
                />
            </SoftBox>

            <SoftBox pb={2} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12} >
                    <Grid item xs={6} pr={.5}>
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "type", fontWeight: "medium" }}
                            count={{ text: transaction.context.type, color: "dark", useNumericFormatter: false, prefix: null }}
                            icon={isXs ? null : { color: "dark", component: "credit_score" }}
                            direction={"left"}
                        />
                    </Grid>
                    <Grid item xs={6} pl={.5}>

                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "intent", fontWeight: "medium" }}
                            count={{ text: transaction.context.intent, color: "dark", useNumericFormatter: false, prefix: null }}
                            icon={isXs ? null : { color: "dark", component: "payments" }}
                            direction={"right"}
                        />

                    </Grid>
                </Grid>
            </SoftBox>

            <SoftBox pb={2} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12} >
                    <Grid item xs={6} pr={.5}>
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "payment method", fontWeight: "medium" }}
                            count={{ text: paymentMethod.text, color: "dark", useNumericFormatter: false, prefix: null }}
                            icon={isXs ? null : { color: "dark", component: paymentMethod.icon }}
                            direction={"left"}
                        />
                    </Grid>
                    <Grid item xs={6} pl={.5}>

                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "status", fontWeight: "medium" }}
                            count={{ text: paymentStatus.text, color: paymentStatus.color, useNumericFormatter: false, prefix: null }}
                            icon={isXs ? null : { color: paymentStatus.color, component: paymentStatus.icon }}
                            direction={"right"}
                        />

                    </Grid>
                </Grid>
            </SoftBox>


            <SoftBox pb={2} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12} >
                    <Grid item xs={12}>
                        <Card><SoftBox bgColor={"white"} variant="gradient">
                            <SoftBox p={2} textAlign="center">
                                <SoftTypography variant="caption" color="dark" fontWeight="medium">
                                    {transaction.context.description}
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>

        </CardContent>
        <CardActions>
            <Grid container ml="auto" mr="auto" xs={12}>
                <Grid item xs={12} p={1}>
                    <SoftButton ml="auto" mr="auto"
                        variant="gradient"
                        color={"light"}
                        fullWidth
                        onClick={() => onOpenDetails(transaction)}
                    >
                        RECEIPT&nbsp;
                        <Icon sx={{ fontWeight: "bold" }}>receipt</Icon>
                    </SoftButton>
                </Grid>
            </Grid>
        </CardActions>
    </Card>;
}

const AccountingTransactionsList = ({ cardroom, transactions, participants, account, union, club, agency, book, embedded, currency, currencySymbol, currencyConverter, onTransactionUpdated, tableChrome }) => {

    const switchToMobileOnSize = "md";
    const theme = useTheme();
    const isMobileUi = !useMediaQuery(theme.breakpoints.up(switchToMobileOnSize));

    const [openAccountTransactionDialog, openAccountTransactionDialogProps] = useDialog();
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [transactionsTable, setTransactionsTable] = useState(null);

    const [openAccountTransactionForwardDialog, fwdAccountTransactionDialogProps] = useDialog();
    const [fwdDialogDefaults, setFwdDialogDefaults] = useState(null);

    const [pendingFwdDefaults, setPendingFwdDefaults] = useState(null);

    const calculator = usePlayerCalculator();


    function onOpenTransaction(tx) {
        setSelectedTransaction(tx);
        openAccountTransactionDialog();
    }

    function onTransactionChanged(tx) {
        //update table???
        setTransactionsTable(null);
        //if (onTransactionUpdated) onTransactionUpdated(tx);
    }

    function onForwardTransaction(tx) {
        let formName = null;
        if (tx) {
            switch (tx.type) {
                case 1: //credit -> fwd form is pay
                    formName = "pay";
                    break;
                case 2: // debit -> fwd form is receive
                    formName = "receive";
                    break;
            }
        }

        if (formName) {

            var acct = tx.sourceAccount;
            if (acct.accountHolderId != cardroom.id) {
                acct = tx.targetAccount;
            }

            if (acct.accountHolderId != cardroom.id) {
                acct = account; //should never happen ???
            }

            var d = {
                form: formName,
                payment: tx.payment,
                account: acct,
                prepayment: true,
                originalTx: tx
            };

            setPendingFwdDefaults(d);
        }
    }

    const accountRequestRead = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    useEffect(() => {
        if (pendingFwdDefaults) {

            if (pendingFwdDefaults.payment && !pendingFwdDefaults.payment.ePaymentVendorReferenceImage && pendingFwdDefaults.payment.hasEPaymentVendorReferenceImage === true) {

                // [HttpGet("{entityId:Guid}/holders/{holderId:Guid}/accounts/{accountId:Guid}/transactions/{transactionId:Guid}", Name = "GetAccountTransaction")]
                let holderId = pendingFwdDefaults.account.accountHolderId;

                accountRequestRead.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/" + holderId + "/accounts/" + pendingFwdDefaults.account.id + "/transactions/" + pendingFwdDefaults.originalTx.id + "?loadReceipt=true").then((response) => {
                    if (response) {
                        pendingFwdDefaults.payment = response.payment;
                        pendingFwdDefaults.originalTx.payment = response.payment;
                    }

                    setFwdDialogDefaults(pendingFwdDefaults);
                    openAccountTransactionForwardDialog();

                }).catch((ex) => {
                    setFwdDialogDefaults(null);
                });
            }
            else {
                setFwdDialogDefaults(pendingFwdDefaults);
                openAccountTransactionForwardDialog();
            }
            setPendingFwdDefaults(null);
        }
    }, [accountRequestRead.execute, pendingFwdDefaults]);


    function onTransactionForwarded(tx) {
        //refresh list???
    }

    function onTransactionForwardClosed() {
        setFwdDialogDefaults(null);
        setSelectedTransaction(null);
    }

    function renderMobileRow(columns, row, key, collapsibleDetailAccessor) {

        // AccountTransactionMobileCard = ({ cardroom, account, row, transaction, onOpenDetails })
        return <AccountTransactionMobileCard cardroom={cardroom} row={row} account={account} transaction={row.original.transaction} onOpenDetails={onOpenTransaction} />

    }
    useEffect(() => {
        if (transactions && participants && !transactionsTable) {
            setTransactionsTable(convertTransactionsToTable(transactions, participants, cardroom, account, GetTransactionTableSchema(onOpenTransaction, onForwardTransaction, embedded, currencySymbol), calculator, currency, currencySymbol, currencyConverter));
        }
    }, [transactions, participants, transactionsTable])

    return <>
        {selectedTransaction ? <AccountTransactionReceiptDialog  {...openAccountTransactionDialogProps} cardroom={cardroom} account={account}
            transaction={selectedTransaction} calculator={calculator} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter}
            onChanged={onTransactionChanged}
        /> : null}

        {fwdDialogDefaults ? <RecordAccountTransactionDialog  {...fwdAccountTransactionDialogProps} cardroom={cardroom} account={account}
            union={union} club={club} agency={agency} book={book} mode="venue" defaults={fwdDialogDefaults}
            onAdded={onTransactionForwarded} onClosed={onTransactionForwardClosed}
        /> : null}

        {transactionsTable ?
            <DataTable table={transactionsTable} canSearch={tableChrome} entriesPerPage={{ defaultValue: tableChrome ? 10 : 100, visible: !isMobileUi && tableChrome }}
                pagination={{ color: "dark" }} onRenderRow={tableChrome ? renderMobileRow : null} useCustomRenderSize={switchToMobileOnSize} /> : null}
    </>;
};

const AccountingTransactionsCard = ({ cardroom, account, union, club, agency, book, currency, currencySymbol, currencyConverter, onAccountUpdated }) => {

    const [transactions, setTransactions] = useState(null);
    const [participants, setParticipants] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading account transactions...", handleRetry)

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    const peopleRequest = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const unionDataLoader = useUnionDataLoader();
    const clubDataLoader = useClubDataLoader();
    const agencyDataLoader = useAgencyDataLoader();


    function onTransactionChanged(tx) {

        //todo: update transaction in list???

        setTransactions(null);

        if (onAccountUpdated) onAccountUpdated();
    }

    useEffect(() => {
        if (!transactions && !participants) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading account transactions...")
            showProgress();

            //  [HttpGet("{entityId:Guid}/holders/{holderId:Guid}/accounts/{accountId:Guid}/transactions/{status:long}", Name = "GetAccountTransactions")] 1 or 2 or 4 or 8 = 15
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/" + account.accountHolderId + "/accounts/" + account.id + "/transactions/15?loadReceipts=false").then((response) => {
                if (response) {
                    setTransactions(response);
                    //setTransactionsTable(null);
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load account transactions, please try again...")
            });
        }
    }, [accountRequest.execute, transactions, retryCounter])

    useEffect(() => {
        if (!participants && transactions) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading account information...")
            showProgress();

            const idmap = [];
            const ids = [];

            function copyAccountHolder(accnt) {
                if (!accnt) return;
                const holderId = accnt.accountHolderId;
                if (holderId != accnt.casinoId && !idmap[holderId]) {
                    ids.push(holderId);
                    idmap[holderId] = holderId;
                }
            }

            for (var i = 0; i < transactions.length; i++) {
                if (transactions[i]) {
                    copyAccountHolder(transactions[i].sourceAccount);
                    copyAccountHolder(transactions[i].targetAccount);
                }
            }

            //TODO: Depending on casino type, we may need to get PEOPLE, CLUBS, UNIONS, or AGENCIES

            function onSuccess(response) {
                if (response) {
                    setParticipants(response);
                }
                progressIndicatorProps.close();
            }

            function onError(ex) {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load account information, please try again...")
            }

            if (cardroom.type === 3) {
                //union
                unionDataLoader.entity.loadEntities(clubRequest, cardroom, union, unionDataLoader.entities.unions | unionDataLoader.entities.clubs, true, onSuccess, onError);
            }
            else if (cardroom.type === 2) {
                //club
                clubDataLoader.entity.loadEntities(clubRequest, cardroom, club, clubDataLoader.entities.players | clubDataLoader.entities.agents | clubDataLoader.entities.unions, true, onSuccess, onError);
            }
            else if (cardroom.type === 5) {
                //agency
                agencyDataLoader.entity.loadEntities(clubRequest, cardroom, agency, agencyDataLoader.entities.players | clubDataLoader.agencyDataLoader.agents | clubDataLoader.agencyDataLoader.clubs, true, onSuccess, onError);
            }
            else if (cardroom.type === 6) {
                //club
                clubDataLoader.entity.loadEntities(clubRequest, cardroom, club, clubDataLoader.entities.players | clubDataLoader.entities.agents | clubDataLoader.entities.clubs, true, onSuccess, onError);
            }
            else {
                peopleRequest.execute("PUT", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants", ids).then(onSuccess).catch(onError);
            }


        }
    }, [peopleRequest.execute, clubRequest.execute, participants, transactions, retryCounter])



    return <Card>
        <Grid container>
            <Grid item m={1} xs={12} mr="auto" ml="auto">
                <ProgressIndicator {...progressIndicatorProps} />

                {!progressIndicatorProps.visible && transactions && participants ?
                    <AccountingTransactionsList cardroom={cardroom} transactions={transactions} participants={participants} account={account} tableChrome={true}
                        union={union} club={club} agency={agency} book={book} currency={currency} currencySymbol={currencySymbol} embedded={false}
                        currencyConverter={currencyConverter} onTransactionUpdated={onTransactionChanged} /> : null}
            </Grid>
        </Grid>
    </Card>;
};



export { AccountingTransactionsList };

export default AccountingTransactionsCard;