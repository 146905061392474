
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { Grid } from '@mui/material';

import { ErrorMessage } from "formik";
import FormField from "components/Elements/Forms/FormField";
import FormSwitch from "components/Elements/Forms/FormSwitch";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import chipsAvatar from "assets/graphics/casino/cash_256.png";
import { NumericFormat } from 'react-number-format';
import MoneyCell from 'components/Elements/DataTable/components/Cells/MoneyCell';
import FormImageField from 'components/Elements/Forms/FormImageField';
import { useCardroomContext } from 'features';



const CashoutTransactionForm = ({ cardroom, player, session, formData }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { type, amount, method, verified, markerBalance, accountBalance, note, provider, providerOther, providerRef, providerRefMethod, providerRefImage, } = formField;
    const {
        type: typeV,
        amount: amountV,
        method: methodV,
        markerBalance: markerBalanceV,
        accountBalance: accountBalanceV,
        provider: providerV,
        providerOther: providerOtherV,
        providerRef: providerRefV,
        verified: verifiedV,
        providerRefImage: providerRefImageV,
        providerRefMethod: providerRefMethodV,
        note: noteV
    } = values;

    function onPaymentMethodRadioGroupChanged(e, newValue) {
        setFieldValue(method.name, newValue);
    }

    function onVendorRefMethodRadioGroupChanged(e, newValue) {
        setFieldValue(providerRefMethod.name, newValue);
    }

    function onAmountChanging(value) {
        if (!formDataInitialized) return;
        if (value <= markerBalanceV && markerBalanceV > 0 && methodV != "credit") {
            setFieldValue(method.name, "credit");
        }
    }

    function onEWalletProviderChanged(e, newValue) {
        setFieldValue(provider.name, newValue);
    }

    const [context, actions, features] = useCardroomContext(cardroom);
    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    const [currencySymbol, setCurrencySymbol] = useState(null);
    const [formDataInitialized, setFormDataInitialized] = useState(false);

    useEffect(() => {
        if (!formDataInitialized) {

            setCurrencySymbol(isCurrencyMaskingEnabled() === true ? "" : "$");

            setFieldValue(type.name, 2);
            setFieldValue(verified.name, true);

            //accountBalanceV
            let accntBalance = player && player.account ? player.account.accountsReceivable - player.account.accountsPayable : 0;
            setFieldValue(accountBalance.name, accntBalance);

            //set markerBalance
            let balance = player && player.borrowedAmount ? player.borrowedAmount : 0;
            setFieldValue(markerBalance.name, balance);
            if (balance > 0) {
                setFieldValue(method.name, "credit");
            }

            //validateForm();

            setFormDataInitialized(true);
        }
    }, [formDataInitialized]);

    return <SoftBox>
        <SoftBox mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={chipsAvatar} size="xxl" variant="rounded" bgColor="dark" />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SoftBox mb={2} textAlign="left">
                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {amount.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()} autoFocus
                                    type={amount.type}
                                    name={amount.name}
                                    value={amountV}
                                    validate={onAmountChanging}
                                    placeholder={amount.placeholder}
                                    error={errors.amount && touched.amount}
                                    success={amountV && amountV.toString().length > 0 && !errors.amount}
                                /></Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Player Balance
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>

                                <SoftTypography pl={1.5}
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize">
                                    <MoneyCell value={parseFloat(markerBalanceV) * (-1)} prefix={currencySymbol} useColorScheme={true} />
                                </SoftTypography>

                            </Grid>
                        </Grid>


                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        {method.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={1.5}
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize">
                                    <RadioGroup row={false} value={methodV} onChange={onPaymentMethodRadioGroupChanged}>
                                        {(markerBalanceV <= 0 && accountBalanceV >= 0) || amountV > (markerBalanceV + Math.abs(accountBalanceV)) ? <>
                                            <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                                            <FormControlLabel value="electronic" control={<Radio />} label="e-Wallet" /> </> : null}
                                        <FormControlLabel value="credit" control={<Radio />} label="Credit" />
                                    </RadioGroup>

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={method.name} />
                                    </SoftTypography>
                                </SoftTypography>

                            </Grid>
                        </Grid>

                        {methodV === "electronic" && amountV > markerBalanceV ? <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {provider.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <SoftTypography pl={1.5}
                                    component="label"
                                    variant="caption"
                                    textTransform="capitalize">
                                    <RadioGroup row value={providerV} onChange={onEWalletProviderChanged}>
                                        <FormControlLabel value="zelle" control={<Radio />} label="Zelle" />
                                        <FormControlLabel value="paypal" control={<Radio />} label="PayPal" />
                                        <FormControlLabel value="venmo" control={<Radio />} label="Venmo" />
                                        <FormControlLabel value="cashapp" control={<Radio />} label="CashApp" />
                                        <FormControlLabel value="apple" control={<Radio />} label="ApplePay" />
                                        <FormControlLabel value="other" control={<Radio />} label="Other" />
                                    </RadioGroup>

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={provider.name} />
                                    </SoftTypography>
                                </SoftTypography>
                            </Grid>
                        </Grid> : null}

                        {methodV === "electronic" && providerV === "other" && amountV > markerBalanceV ? <Grid container xs={12}>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {providerOther.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormField type={providerOther.type} onFocus={e => e.currentTarget.select()}
                                        name={providerOther.name}
                                        value={providerOtherV}
                                        placeholder={providerOther.placeholder}
                                        error={errors.providerOther && touched.providerOther}
                                        success={providerOtherV && providerOtherV.length > 0 && !errors.providerOther}
                                    />
                                </Grid>
                            </Grid>
                        </Grid> : null}


                        {methodV !== "credit" ?
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            Payment {methodV === "electronic" ? "sent" : "made"} and verified
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>

                                    <FormSwitch mr="auto"
                                        name={verified.name}
                                        value={verifiedV}
                                        {...formData} />

                                </Grid>
                            </Grid> : null}

                        {methodV === "electronic" && verifiedV ? <>
                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize">
                                            {providerRefMethod.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={8}>
                                    <SoftTypography
                                        variant="caption"
                                        textTransform="capitalize">
                                        <RadioGroup row={true} value={providerRefMethodV} onChange={onVendorRefMethodRadioGroupChanged} style={{ marginLeft: "0.75em" }}>
                                            <FormControlLabel value="number" control={<Radio />} label="Number" />
                                            <FormControlLabel value="image" control={<Radio />} label="Image" />
                                        </RadioGroup>
                                    </SoftTypography>
                                </Grid>
                            </Grid>

                            {providerRefMethodV === "number" ?
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                {providerRef.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormField type={providerRef.type} onFocus={e => e.currentTarget.select()}
                                            name={providerRef.name}
                                            value={providerRefV}
                                            placeholder={providerRef.placeholder}
                                            error={errors.providerRef && touched.providerRef}
                                            success={providerRefV && providerRefV.toString().length > 0 && !errors.providerRef}
                                        />

                                    </Grid>
                                </Grid> : null}

                            {providerRefMethodV === "image" ?
                                <Grid container xs={12}>
                                    <Grid item xs={4} >
                                        <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize">
                                                {providerRefImage.label}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormImageField name={providerRefImage.name} imgWidth="120px" {...formData} />
                                    </Grid>
                                </Grid> : null}
                        </> : null}


                        {(markerBalanceV > 0 || accountBalanceV < 0) && (amountV > markerBalanceV || amountV > Math.abs(accountBalanceV)) && methodV !== "credit" ?
                            <Grid container xs={12} mt={0.5}>
                                <Grid item xs={12}>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        color="info"
                                    >
                                        Because of an existing account and/or marker balance, this transaction will be split between credit and {methodV}. <NumericFormat value={markerBalanceV + Math.abs(accountBalanceV)} displayType="text" thousandSeparator={true} prefix={currencySymbol} />  will be applied to satisfy existing balance,
                                        with the reaming <NumericFormat value={amountV - markerBalanceV - Math.abs(accountBalanceV)} displayType="text" thousandSeparator={true} prefix={currencySymbol} /> paid out via selected method.
                                    </SoftTypography>
                                </Grid>

                            </Grid> : null}

                        <Grid container xs={12}>
                            <Grid item xs={4} >
                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize">
                                        {note.label}
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={8}>
                                <FormField onFocus={e => e.currentTarget.select()}
                                    type={note.type}
                                    name={note.name}
                                    value={noteV}
                                    placeholder={note.placeholder}
                                    error={errors.note && touched.note}
                                    success={noteV && noteV.length > 0 && !errors.note}
                                />
                            </Grid>
                        </Grid>


                        {/* Hidden Fields */}

                        <FormField type={markerBalance.type}
                            name={markerBalance.name}
                            value={markerBalanceV} />

                        <FormField type="hidden"
                            name={verified.name}
                            value={verifiedV} />

                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    </SoftBox>;

};



export default CashoutTransactionForm;