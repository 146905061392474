/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftProgress from "components/SoftProgress";
import { Card, CardContent } from "@mui/material";
import { NumericFormat } from "react-number-format";

function MiniChartItemCard({ color, icon, label, progress, useNumericFormatter }) {
    const { size } = typography;

    return (
        <Card>
            <CardContent>
                <SoftBox width="100%">
                    <SoftBox display="flex" alignItems="center" mb={2}>
                        <SoftBox
                            bgColor={icon.color}
                            width="1.25rem"
                            height="1.25rem"
                            borderRadius="sm"
                            color="white"
                            fontSize={size.xs}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            shadow="md"
                            mr={1}
                            variant="gradient"
                        >
                            <Icon>{icon.component}</Icon>
                        </SoftBox>
                        <SoftTypography
                            variant="caption"
                            textTransform="capitalize"
                            fontWeight="medium"
                            color="text"
                        >
                            {label}
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox mt={1}>
                        <SoftTypography variant="h4" fontWeight="bold" color={color}>
                            {useNumericFormatter ? <NumericFormat value={progress.content} displayType="text" thousandSeparator={true} /> : progress.content}
                        </SoftTypography>
                        <SoftBox width="75%" mt={0.5}>
                            <SoftProgress value={progress.percentage} color={color} />
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            </CardContent>
        </Card>
    );
}

// Setting default values for the props of ReportsBarChartItem
MiniChartItemCard.defaultProps = {
    color: "dark",
    useNumericFormatter: true
};

// Typechecking props for the ReportsBarChartItem
MiniChartItemCard.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    icon: PropTypes.shape({
        color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"])
            .isRequired,
        component: PropTypes.node.isRequired,
    }).isRequired,
    label: PropTypes.string.isRequired,
    progress: PropTypes.shape({
        content: PropTypes.string.isRequired,
        percentage: PropTypes.number.isRequired,
    }).isRequired,
    useNumericFormatter: PropTypes.bool
};

export default MiniChartItemCard;
