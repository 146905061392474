
const form = {
    formId: "adhoc-reporting-line-editor-form",
    formField: {
        lineId: {
            name: "lineId",
            label: "Line",
            type: "hidden",
            errorMsg: ""
        },
        title: {
            name: "title",
            label: "Title",
            type: "text",
            placeholder: "title",
            errorMsg: "Title is required",
        },
        category: {
            name: "category",
            label: "Category",
            type: "string",
            placeholder: "category",
            errorMsg: "Category is required",
        },
        amount: {
            name: "amount",
            label: "Transaction Amount",
            type: "number",
            placeholder: "transaction amount",
            errorMsg: "Transaction amount is required",
        },
        splitWithSub: {
            name: "splitWithSub",
            label: "Split",
            type: "bool",
            placeholder: "split",
            errorMsg: "Split must be specified"
        }
    },

};

export default form;
