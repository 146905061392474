import form from "./form";


const {
    formField: {
        rebateRate,
        rebateThreshold,
        rebateScheduleScheme,
        lossOnly,
        makeupThreshold,
        //taxRebateRate
    },
} = form;

const initialValues = {
    [rebateRate.name]: 0,
    [rebateThreshold.name]: 0,
    [rebateScheduleScheme.name]: "",
    [lossOnly.name]: false,
    [makeupThreshold.name]: 0,
    //[taxRebateRate.name]: 0
};

export default initialValues;
