import form from "./form";


const {
    formField: {
        lineId,
        title,
        category,
        amount,
        splitWithSub
    },
} = form;

const initialValues = {
    [lineId.name]: "",
    [title.name]: "",
    [category.name]: "Expense",
    [amount.name]: 0,
    [splitWithSub.name]: false
};

export default initialValues;
