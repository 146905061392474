import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { playerId, clubId, rebateSchedule }
} = form;



const validations = {
    editor: Yup.object().shape({
        [playerId.name]: Yup.string().required(playerId.errorMsg),
        [clubId.name]: Yup.string().required(clubId.errorMsg),
        [rebateSchedule.name]: Yup.string().required(rebateSchedule.errorMsg)
    }),

};

export default validations;