import { Grid, Icon, ListItemIcon, ListItemText } from "@mui/material";

import Card from "@mui/material/Card";

import SoftTypography from "components/SoftTypography";
import { useCardroomContext } from "features";
import FeeTierScheduleEditor from "layouts/Club/components/Tiers/Editor";
import FeeTierScheduleSelector from "layouts/Club/components/Tiers/Selector";

import { useState } from "react";


const FeeTierScheduleSettingsEditor = ({ cardroom, union, club, agency }) => {

    const [context, actions, features] = useCardroomContext(cardroom);

    function canEditTiers() {
        if (!context) return false;

        return true;
        //return context.isAllowed(actions.cardroom.fees.agent.edit);
    }

    const addSchedulePlaceHolder = canEditTiers() ? { id: null, name: "New Tier" } : null;

    const [currentTierSchedule, setCurrentTierSchedule] = useState(null);
    const [reloadSchedulesNeeded, setReloadSchedulesNeeded] = useState(false);

    function onTierScheduleChanged(item) {
        if (!item) return;

        setReloadSchedulesNeeded(false);

        if (!item.id) { //create new object for new items
            item = {
                id: null, name: "", steps: [
                    {
                        id: null,
                        refCount: 0,
                        min: 0,
                        max: 1000,
                        isLast: false
                    },
                    {
                        id: null,
                        refCount: 0,
                        min: 1000,
                        max: null,
                        isLast: true
                    }
                ]
            }
        }

        if (item.steps && item.steps.length > 0) {

            //we should order by minValue
            item.steps.sort(function (a, b) {
                return a.min - b.min;
            });

            for (var i = 0; i < item.steps; i++) {
                item[i].isLast = false;
            }

            item.steps[item.steps.length - 1].isLast = true;
        }

        setCurrentTierSchedule(item);
    }

    function onScheduleEditDone(canceled) {
        setCurrentTierSchedule(null);
        setReloadSchedulesNeeded(true);
    }

    function buildListItem(item) {
        if (item.id) {
            return <ListItemText><SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize">
                {item.name}
            </SoftTypography></ListItemText>;
        }
        else {
            return <>
                <ListItemIcon style={{ minWidth: "32px" }}>
                    <Icon>add</Icon>
                </ListItemIcon>
                <ListItemText><SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    {item.name}
                </SoftTypography></ListItemText></>;
        }
    }

    return <Grid width="100%" xs={12}>
        <Grid container xs={12}>
            <Grid item xs={4} p={2}>
                <FeeTierScheduleSelector cardroom={cardroom} union={union} club={club} agency={agency} emptyValue={addSchedulePlaceHolder}
                    onSelectionChanged={onTierScheduleChanged} onBuildListItem={buildListItem} type="list" reload={reloadSchedulesNeeded} maxHeight="375px" />
            </Grid>
            <Grid item xs={8}> <Card style={{ overflow: "auto" }}>
                {currentTierSchedule ?
                    <FeeTierScheduleEditor cardroom={cardroom} union={union} club={club} agency={agency} tierSchedule={currentTierSchedule} dense={true} onClose={onScheduleEditDone} />
                    :
                    <Card sx={{ height: 375 }}>
                        <Grid container xs={12} justifyContent="center" alignItems="center">
                            <Grid item xs={12} textAlign={"center"}>

                                <SoftTypography
                                    component="h5"
                                    variant="caption"
                                    color="info">
                                    Select a tier schedule from the list or tap "add new" to create a new one
                                </SoftTypography>

                            </Grid>
                        </Grid>
                    </Card>
                }</Card>
            </Grid>
        </Grid>
    </Grid>;
};

export default FeeTierScheduleSettingsEditor;