
const form = {
    formId: "account-transactions-form",
    formField: {
        type: {
            name: "type",
            type: "hidden"
        },
        sourceAccount: {
            name: "sourceAccount",
            type: "text",
            label: "Payer",
            placeholder: "Payer account",
            errorMsg: "Payer account is required.",
        },
        targetAccount: {
            name: "targetAccount",
            type: "text",
            label: "Payee",
            placeholder: "Payee account",
            errorMsg: "Payee account is required.",
        },
        participant: {
            name: "participant",
            type: "text"
        },
        journalTxType: {
            name: "journalTxType",
            label: "Transaction Type",
            type: "string",
            placeholder: "transaction type",
            errorMsg: "Transaction type must be selected",
        },
        expenseTxType: {
            name: "expenseTxType",
            label: "Action",
            type: "string",
            placeholder: "expense action",
            errorMsg: "Expense action must be selected",
        },
        amount: {
            name: "amount",
            label: "Amount (USD)",
            type: "number",
            placeholder: "amount",
            errorMsg: "Amount must be greater than 0.",
        },
        isPrePayment: {
            name: "isPrePayment",
            label: "Pre-Payment",
            type: "bool",
            placeholder: "pre-payment",
            errorMsg: "Pre-payment must be specified.",
        },
        method: {
            name: "method",
            label: "Payment method",
            type: "text",
            placeholder: "payment method",
            errorMsg: "Payment method is required",
        },
        accountBalance: {
            name: "accountBalance",
            type: "hidden"
        },
        provider: {
            name: "provider",
            label: "e-Wallet vendor",
            type: "text",
            placeholder: "eWallet vendor",
            errorMsg: "eWallet vendor is required when using eWallet payment method"
        },
        providerOther: {
            name: "providerOther",
            label: "e-Wallet vendor name",
            type: "text",
            placeholder: "eWallet vendor name",
            errorMsg: "eWallet vendor name is required when using eWallet payment method with 'other' provider"
        },
        providerRefMethod: {
            name: "providerRefMethod",
            label: "Vendor reference type",
            type: "text",
            placeholder: "vendor reference type",
            errorMsg: "Vendor reference type is required when payment is verified"
        },
        providerRef: {
            name: "providerRef",
            label: "Vendor reference #",
            type: "text",
            placeholder: "vendor reference #",
            errorMsg: "Vendor reference is required when payment is verified"
        },
        providerRefImage: {
            name: "providerRefImage",
            label: "Vendor reference document",
            type: "hidden",
            placeholder: "vendor reference doc",
            errorMsg: "Vendor ref document is required when using eWallet"
        },
        note: {
            name: "note",
            label: "Note",
            type: "text",
            placeholder: "Note",
            errorMsg: "Note is required"
        }
    },

};

export default form;
