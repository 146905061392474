import * as Yup from "yup";
import "yup-phone";
import agentForm from "./form";

const {
    formField: { agentId, playerId, agentPlayerPoolId, rebateScheduleId, makeupAmount, playerAppId, playerName, playerNickName, validatedPlayerId,
        referrerId,
        referredId,
        referralScheduleId,
        referralScheduleName,
        referrerType,
        referredType
    },
} = agentForm;



const validations = {
    edit: Yup.object().shape({
        [agentId.name]: Yup.string().required(agentId.errorMsg),
        [agentPlayerPoolId.name]: Yup.string().when([agentId.name], {
            is: (agentIdV) => (agentIdV && agentIdV !== "none"),
            then: Yup.string().notOneOf(["none"], agentPlayerPoolId.errorMsg).required(agentPlayerPoolId.errorMsg)
        }),
        [makeupAmount.name]: Yup.number().when([agentId.name], {
            is: (agentIdV) => (agentIdV && agentIdV !== "none"),
            then: Yup.number().min(0, makeupAmount.errorMsg).required(makeupAmount.errorMsg)
        }),
        [playerAppId.name]: Yup.string().min(3).required(playerAppId.errorMsg),
        [playerName.name]: Yup.string().min(2).required(playerName.errorMsg),
        [validatedPlayerId.name]: Yup.string().test("IsValidPlayerId", "App player id is already in use", function (value, ctx) {
            return value !== "error";
        }),
    }),
    referrals: Yup.object().shape({
        [referrerId.name]: Yup.string().required(referrerId.errorMsg),
        [referredId.name]: Yup.string().required(referredId.errorMsg),
        [referralScheduleId.name]: Yup.string().required(referralScheduleId.errorMsg)
    })
};

export default validations;