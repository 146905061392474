import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { BootstrapDialog } from "components/Elements/Dialog/common"

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Card, Grid } from '@mui/material';
import { useCardroomContext } from 'features';
import SoftButton from 'components/SoftButton';
import { useProgressIndicator } from 'components/Elements/ProgressIndicator';
import { ProgressIndicator } from 'components/Elements/ProgressIndicator';


const SnapshotReportingPeriodDialog = ({ open, close, cardroom, report, union, club, agency, onUpdated }) => { 

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    const [snapshotActionRequest, setSnapshotActionRequest] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);
    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "performing snapshot action...", handleRetry)

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.write,
    });

    function onClosureRequested() {
        setSnapshotActionRequest(null);
        progressIndicatorProps.close();
        close();
    }
    function onSnapshotUpdated() {
        if (onUpdated) {
            onUpdated(report);
        }
        close();
    }

    function onProcessSnapshotRequest() {

        //do work, call onSnapshotUpdated
        const action = getSnapshotAction();
        if (action > 0 && canPerformSnapshotAction()) {
            setSnapshotActionRequest(action);
        }

    }

    useEffect(() => {
        if (snapshotActionRequest && snapshotActionRequest > 0) {
            progressIndicatorProps.setMode("wait");

            let msg = "Publishing reporting period snapshot...";

            let method = "POST", url = apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/" + report.id + "/snapshots";

            if (snapshotActionRequest === 2) {
                msg = "Rolling back reporting period snapshot...";
                method = "DELETE";
                url += "/" + report.reportingPeriodSnapshotId;
            }

            progressIndicatorProps.setMessage(msg)
            showProgress();

            clubRequest.execute(method, url).then((response) => {
                if (snapshotActionRequest == 1 && response) {
                    report.reportingPeriodSnapshotId = response;
                }
                else if (snapshotActionRequest == 2 && response) {
                    report.reportingPeriodSnapshotId = null;
                }

                setSnapshotActionRequest(null);
                onSnapshotUpdated();
                progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");

                let err = "Unable to publish reporting period snapshot";
                if (snapshotActionRequest === 2) {
                    err = "Unable to roll back reporting period snapshot";
                }
                progressIndicatorProps.setMessage(err + ", please try again...")
            });
        }
    }, [clubRequest.execute, snapshotActionRequest, retryCounter]);

    const [context, actions, features] = useCardroomContext(cardroom);

    function isReportPublishingEnabled(){
        if (!context) return false;
        return context.isEnabled(features.club.reporting.publish, 0);
    }

    function canPerformSnapshotAction() {

        if (false == isReportPublishingEnabled()) return false;
        if (!context) return false;
        //check action

        switch (getSnapshotAction()) {
            case 1:
                return context.isAllowed(actions.club.reports.snapshot.unpublish);
            case 2:
                return context.isAllowed(actions.club.reports.snapshot.publish);
        }
        
        return false;
    }


    function getSnapshotAction() {
        if (!report) return 0;
        if (report.publicationStatus === 1) return 1;
        if (report.publicationStatus === 3) return 2;

        return 0;
    }

    function getSnapshotActionName() {
        switch (getSnapshotAction()) {
            case 1:
                return "Publish";
            case 2:
                return "Unpublish";
        }

        return null;
    }

    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}
            onClose={close}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="md">
            <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.secondary.main }}>
                <SoftBox>
                    <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                        {getSnapshotActionName()} Report
                    </SoftTypography>
                </SoftBox>
            </DialogTitle>
            <DialogContent dividers>

                <Card sx={{ height: 250 }} mt={3}>
                    {isReportPublishingEnabled() ? <Grid container xs={12} justifyContent="center" alignItems="center">
                        <Grid item xs={12} textAlign={"center"}>
                            {!progressIndicatorProps.visible ? <>
                                {getSnapshotAction() === 1 ? <>

                                    <SoftBox>
                                        <SoftTypography variant="button" color="text">
                                            Publishing a report will transfer all settlement amounts to their respective entities' ledgers.
                                        </SoftTypography>
                                    </SoftBox>

                                    <SoftBox>
                                        <SoftTypography variant="button" color="text">
                                            Once published, it may take Gambooler several minutes to apply ledger changes to all of the affected entities. You will need to refresh your screen to see the updated ledger details.
                                        </SoftTypography>
                                    </SoftBox>

                                    <SoftBox>
                                        <SoftTypography variant="button" color="error">
                                            This action cannot be undone. Please ensure that the numbers you are publishing are correct and accurate.
                                        </SoftTypography>
                                    </SoftBox>

                                    <SoftBox>
                                        <SoftTypography variant="h5" color="text">
                                            Are you sure want to publish?
                                        </SoftTypography>
                                    </SoftBox>

                                </> : <> Are you sure you want to unpublish?</>}

                            </> : <ProgressIndicator {...progressIndicatorProps} />}
                        </Grid>
                    </Grid> :
                        <Grid container xs={12}> 
                            <Grid item xs={12}>
                                This feature is not currently enabled for your cardroom. Please check with your admin or Gambooler rep.
                            </Grid>
                        </Grid>}
                </Card>
            </DialogContent>
            <DialogActions>
                <SoftButton onClick={onClosureRequested} variant="outlined" color="warning">
                    cancel
                </SoftButton>
                <SoftButton onClick={onProcessSnapshotRequest} variant="outlined" color="success" disabled={!canPerformSnapshotAction}>
                    {getSnapshotActionName()}
                </SoftButton>
            </DialogActions>
        </BootstrapDialog>
    );
};

export default SnapshotReportingPeriodDialog;