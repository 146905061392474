import { Card, DialogActions, DialogContent, DialogTitle, Grid, useMediaQuery, useTheme } from "@mui/material";
import { BootstrapDialog } from "components/Elements/Dialog/common";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import BookPlayerEditor from "../Editor";


const BookPlayerEditorDialog = ({ open, close, cardroom, book, player, players, agents, onPlayerChanged }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    function onPlayerUpdated(p) {
        if (onPlayerChanged) onPlayerChanged(p);
    }

    return <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xxl">
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.dark.main }}>
            <SoftBox>
                <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                    Player Properties: {player ? player.name : "<UNKNOWN>"}
                </SoftTypography>
            </SoftBox>
        </DialogTitle>
        <DialogContent dividers>
            <Card style={{ minHeight: 200 }} mt={3}>
                <Grid container xs={12}>
                    <Grid item xs={12}>
                        <SoftBox pb={0} px={2} textAlign="center" lineHeight={1.25}>
                            <BookPlayerEditor cardroom={cardroom} book={book} player={player} players={players} agents={agents} onPlayerUpdated={onPlayerUpdated} dense={false} onClose={close} />
                        </SoftBox>
                    </Grid>
                </Grid>
            </Card>
        </DialogContent>
        <DialogActions>
            <SoftButton onClick={close} variant="outlined" color="secondary">
                close
            </SoftButton>
        </DialogActions>
    </BootstrapDialog >
};



export default BookPlayerEditorDialog;