import React, { useState, useEffect } from 'react';

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import wavesWhite from "assets/images/shapes/waves-white.svg";

import defaultCasino from "assets/graphics/logos/default-casino.png";

import { useDialog } from "components/Elements/Dialog/common";
import { AddPlayerDialog, AddStaffDialog } from "layouts/Cardroom/Session/components/Dialogs";

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import MiniActionCard from "components/Elements/MiniActionCard";

import CasinoPlayersCard from '../CasinoPlayers';
import GameHistoryCard from '../GameHistory';
import CasinoStaffCard from '../CasinoStaff';
import RecordAccountTransactionDialog from '../Accounting/Dialogs';
import NewSessionToolbar from '../Session/NewSession';
import ExistingSessionToolbar from '../Session/ExistingSession';
import { useCardroomContext } from 'features';
import { Link } from '@mui/material';
import { CasinoSettingsDialog } from 'components/Elements/Dialog/settings';
import { DebtServiceDialog } from '../Dialogs';
import CardroomLogo from 'components/Elements/CardroomLogo';
import AdaptiveTab from 'components/Elements/AdaptiveTab';
import SoftBadge from 'components/SoftBadge';
import { useCurrencyConverter } from 'components/Elements/CurrencyConverter/currency';
import AccountingTransactionsCard from '../Accounting/Transactions/List';
import AccountingAccountsCard from '../Accounting/Accounts';


//import { hasAccess } from 'state/security/securitySlice';
//import CasinoSecurityContext from 'components/Elements/Security';
//import { useDispatch } from 'react-redux';


function CardroomDetailsDetails({ cardroom }) {


    const [openAddPlayerDialog, addPlayerDialogProps] = useDialog();

    const [openAddStaffDialog, addStaffDialogProps] = useDialog();

    const [openRecordTxDialog, recordTxDialogProps] = useDialog();


    const [openDebtServiceDialog, debtServiceDialogProps] = useDialog();

    const [currencyConverter, currencies] = useCurrencyConverter(cardroom);
    const [currency, setCurrency] = useState(currencyConverter.getCurrencyFrom("USD"));

    const [currencySymbol, setCurrencySymbol] = useState(null);

    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    useEffect(() => {
        if (true === isCurrencyMaskingEnabled()) {
            setCurrencySymbol("");
            return;
        }
        if (currency) setCurrencySymbol(currency.symbol);
    }, [currency]);


    const [cardroomDetailsData, setCardroomDetailsData] = useState(null);
    const [cardroomStats, setCardroomStats] = useState({});
    const [cardroomAccount, setCardroomAccount] = useState({});

    const cardroomRequest = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    const peopleRequest = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading cardroom details...", handleRetry)

    function refreshCardroomProperties() {
        setCardroomStats(null);
        //setCardroomAccount(null);
    }

    function refreshAccount() {
        setCardroomAccount(null);
    }

    const [context, actions, features] = useCardroomContext(cardroom);

    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    useEffect(() => {
        if (!cardroomDetailsData || cardroomDetailsData.id != cardroom.id) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading cardroom details...")
            showProgress();
            cardroomRequest.execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id).then((response) => {
                setCardroomDetailsData(response);
                setCardroomStats(null);
                //progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load casino details, please try again...")
            });
        }
    }, [cardroomRequest.execute, cardroomDetailsData, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (cardroomDetailsData && !cardroomStats) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading casino stats...")
            showProgress();
            peopleRequest.execute("GET", apiConfig.people.endpoint + "/venue/" + cardroom.id).then((response) => {
                setCardroomStats(response);
                setCardroomAccount(null);
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load casino stats, please try again...")
            });
        }
    }, [peopleRequest.execute, cardroomStats, cardroomDetailsData, retryCounter]) //cardroomListData 

    useEffect(() => {
        if (cardroomDetailsData && cardroomStats && (!cardroomAccount || cardroomAccount.accountHolderId != cardroom.id)) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading casino account...")
            showProgress();
            // [HttpGet("{entityId:Guid}/holders/{holderId:Guid}/accounts/{type:int}", Name = "GetEntityAccount")]
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/" + cardroom.id + "/accounts/2").then((response) => {
                setCardroomAccount(response);
                progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load casino account, please try again...")
            });
        }
    }, [accountRequest.execute, cardroomAccount, cardroomStats, cardroomDetailsData, retryCounter]) //cardroomListData 

    function canAddParticipants() {
        if (!context) return false;
        return context.isAllowed(actions.casino.participant.add);
    }
    function canAddAccountTransaction() {
        if (!context) return false;
        return context.isAllowed(actions.casino.account.transaction.add);
    }

    const tabs = [];

    tabs.push({
        title: { text: "Games", icon: "casino" },
        header: cardroomDetailsData && cardroomDetailsData.currentSession && cardroomDetailsData.currentSession.status < 3 ? <ExistingSessionToolbar cardroom={cardroomDetailsData} /> : <NewSessionToolbar cardroom={cardroomDetailsData} />,
        body: <GameHistoryCard cardroom={cardroomDetailsData} />
    });

    tabs.push({
        title: { text: "Players", icon: "group" },
        header: canAddParticipants() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openAddPlayerDialog}><Icon size="lg">person_add_alt_sharp</Icon>&nbsp;Add Player</SoftButton> : null,
        body: <CasinoPlayersCard cardroom={cardroomDetailsData} />
    });

    tabs.push({
        title: { text: "Staff", icon: "local_bar" },
        header: canAddParticipants() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openAddStaffDialog}><Icon size="lg">badge</Icon>&nbsp;Add Staff</SoftButton> : null,
        body: <CasinoStaffCard cardroom={cardroomDetailsData} />
    });

    tabs.push({
        title: { text: "Accounts", icon: "account_balance" },
        header: canAddAccountTransaction() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openRecordTxDialog}><Icon size="lg">receipt_long</Icon>&nbsp;Record Transaction</SoftButton> : null,
        body: <AccountingAccountsCard cardroom={cardroomDetailsData} account={cardroomAccount} currency={currency} currencySymbol={currencySymbol}
            currencyConverter={currencyConverter} onAccountUpdated={refreshAccount} />
    });

    tabs.push({
        title: { text: "Debt Service", icon: "payments", badge: cardroomAccount && cardroomAccount.pendingTransactionCount > 0 ? { content: cardroomAccount.pendingTransactionCount, color: "warning" } : null },
        header: canAddAccountTransaction() ? <SoftButton variant="outlined" size="small" color="dark" onClick={openRecordTxDialog}><Icon size="lg">receipt_long</Icon>&nbsp;Record Transaction</SoftButton> : null,
        body: <AccountingTransactionsCard cardroom={cardroomDetailsData} account={cardroomAccount} currency={currency} currencySymbol={currencySymbol}
            currencyConverter={currencyConverter} onAccountUpdated={refreshAccount} />
    });


    {/* Accounts receivable (AR), Accounts payable (AP)*/ }
    return <>
        {cardroomDetailsData && !progressIndicatorProps.visible ? <>

            <DebtServiceDialog {...debtServiceDialogProps} cardroom={cardroom} />
            {canAddParticipants() ? <>
                <AddStaffDialog {...addStaffDialogProps} cardroom={cardroom} mode="venue" onAdded={refreshCardroomProperties} />
                <AddPlayerDialog {...addPlayerDialogProps} cardroom={cardroom} mode="venue" onAdded={refreshCardroomProperties} />
            </> : null}
            {canAddAccountTransaction() && cardroomAccount ?
                <RecordAccountTransactionDialog {...recordTxDialogProps} cardroom={cardroom} account={cardroomAccount}
                    onAdded={refreshAccount} mode="venue" />
                : null}

            <SoftBox mb={3} p={1}>
                <SoftBox
                    position="relative"
                    bgColor="secondary"
                    py={3}
                    px={{ xs: 3, sm: 6, md: 1 }}
                    mt={3}
                    borderRadius="xl"
                    variant="gradient" >

                    <SoftBox
                        component="img"
                        src={wavesWhite}
                        alt="pattern-line"
                        width="100%"
                        position="absolute"
                        left="0"
                        top="0"
                        opacity={0.4}
                    />

                    <Grid container alignItems="center" position="relative">

                        <CardroomLogo cardroom={cardroomDetailsData} canOpenProperties={true} />

                        <Grid item xs={12} lg={11}>
                            <Grid container>
                                <Grid item xs={6} lg={3} p={1} >
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "AR", fontWeight: "medium", tooltip: "Accounts Receivable: amount yet to be collected." }}
                                        count={{ text: cardroomAccount ? cardroomAccount.accountsReceivable : 0, prefix: isCurrencyMaskingEnabled() ? "" : "$", color: "warning", useNumberFormatter: true }}
                                        icon={{ component: "credit_card" }}
                                        action={openDebtServiceDialog} />

                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "AP", fontWeight: "medium", tooltip: "Accounts Payable: amount yet to be paid out." }}
                                        count={{ text: cardroomAccount ? cardroomAccount.accountsPayable : 0, prefix: isCurrencyMaskingEnabled() ? "" : "$", color: "error", useNumberFormatter: true }}
                                        icon={{ component: "money" }}
                                        action={openDebtServiceDialog} />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "# of players / staff", fontWeight: "medium" }}
                                        count={{ text: cardroomStats ? cardroomStats.venuePlayers + " / " + cardroomStats.venueStaff : "0 / 0" }}
                                        icon={{ component: "group" }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} p={1}>
                                    <MiniActionCard
                                        bgColor="dark"
                                        title={{ text: "# of games", fontWeight: "medium" }}
                                        count={{ text: cardroomDetailsData ? cardroomDetailsData.totalSessions : 0, useNumberFormatter: true }}
                                        icon={{ color: "info", component: "casino" }}
                                    />

                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </SoftBox>
            </SoftBox>


            <AdaptiveTab switchToMobileOnSize="lg" tabs={tabs} />

        </> : <ProgressIndicator {...progressIndicatorProps} />}
    </>;
}

export default CardroomDetailsDetails;