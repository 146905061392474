import agentForm from "./form";


const {
    formField: {
        playerPoolId,
        poolName,
        commissionScheduleId,
        makeupAmount
    },
} = agentForm;

const initialValues = {
    [playerPoolId.name]: "",
    [poolName.name]: "",
    [commissionScheduleId.name]: "",
    [makeupAmount.name]: 0
};

export default initialValues;
