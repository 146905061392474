import React, { useState, useEffect } from "react";

import { Navigate, Link } from "react-router-dom";

import { apiConfig } from 'config/apiConfig'

import Grid from "@mui/material/Grid";

import PageLayout from "components/Elements/PageLayout";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import wavesWhite from "assets/images/shapes/waves-white.svg";
import defaultCasino from "assets/graphics/logos/default-casino.png";

import CardroomSelect from "components/Elements/DataBound/CardroomSelect";
import SoftTypography from "components/SoftTypography";
import { InvitationRedemptionDialog } from "components/Elements/Invitation";
import { useDialog } from "components/Elements/Dialog/common";
import CardroomLogo from "components/Elements/CardroomLogo";


function getNavLink(cardroom) {
    if (cardroom) {
        switch (cardroom.type) {
            case 1:
                return "/dashboards/cardroom";
            case 2:
                return "/dashboards/club";
            case 3:
                return "/dashboards/union";
            case 5:
                return "/dashboards/agency";
            case 6:
                return "/dashboards/book";
        }
    }

    return "/dashboards/cardroom";
}

const NoCardroomsFound = ({ }) => {

    const [openRedeemInvitationDialog, redeemInvitationDialogProps] = useDialog();

    function onRedeemClicked() {
        openRedeemInvitationDialog();
    }

    function onRedeemed(result) {
        redeemInvitationDialogProps.close();
        //reload page?
        //redeemed
        if (result === 2) window.location.reload(false);
    }

    return <SoftBox>
        <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize">
            We were unable to locate any cardrooms that have granted you access.
        </SoftTypography>
        <SoftBox padding={1} mt={1}>
            <InvitationRedemptionDialog {...redeemInvitationDialogProps} onRedeemed={onRedeemed} />
            <SoftButton variant="contained" onClick={onRedeemClicked}>Redeem Invitation</SoftButton>
        </SoftBox>
    </SoftBox>;
};

function MultipleCardroomNav(props) {
    const cardroom = props.cardroom;

    return <Link to={getNavLink(cardroom)} state={{ cardroom: cardroom }}>
        <SoftButton>Go to: {cardroom.name}</SoftButton>
    </Link>;
}

function SingleCardroomNav(props) {
    const cardroom = props.cardroom;

    return <Navigate to={getNavLink(cardroom)} state={{ cardroom: cardroom }} />;
}

function NoCardroomNav() {
    //return <div style={{ paddingLeft: '5px', whiteSpace: 'nowrap' }}>No cardrooms found</div>;
    return <NoCardroomsFound />;
}

function SetContextSection({ absolute, light }) {

    const [cardroom, setCardroom] = useState(null);
    const [cardroomCount, setCardroomCount] = useState(0);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    function handleCardroomChange(room) {
        setCardroom(room);
    };


    function handleDataLoaded(state) {
        setCardroomCount(state.count);
        setIsDataLoaded(true);
    };


    return (
        <PageLayout>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <SoftBox p={1}>
                    <SoftBox alignItems="center" textAlign="center"
                        position="relative"
                        bgColor="secondary"
                        py={3}
                        px={{ xs: 3, sm: 6 }}
                        mt={3}
                        borderRadius="xl"
                        variant="gradient">

                        <SoftBox
                            component="img"
                            src={wavesWhite}
                            alt="pattern-line"
                            width="100%"
                            position="absolute"
                            left="0"
                            top="0"
                            opacity={0.4} />

                        <Grid container xs={10} sm={12} alignItems="center" position="relative" ml="auto" mr="auto">
                            <Grid item xs={12} lg={3}><></></Grid>
                            <Grid item xs={12} lg={6}>
                                <SoftBox textAlign="center">
                                    <SoftBox component="img"
                                        src={cardroom && cardroom.hasLogo ? apiConfig.images.endpoint + "/casino/" + cardroom.id + ".png" : defaultCasino}
                                        alt="gambooler logo"
                                        display="flex"
                                        width="256px"
                                        mt={{ xs: 0, lg: -16 }}
                                        ml="auto"
                                        mr="auto" />
                                </SoftBox>

                                <Grid container>
                                    {isDataLoaded && cardroomCount > 1 ?
                                        <Grid item xs={10} md={12} m={4}>
                                            <SoftBox textAlign="center">
                                                {isDataLoaded ? <>select your cardroom</> : <div style={{ minWidth: '480px' }}>loading, please wait...</div>}
                                            </SoftBox>
                                        </Grid> : <></>}

                                    <Grid item xs={10} md={12} m={isDataLoaded && cardroomCount > 1 ? 4 : 0}>
                                        <SoftBox textAlign="center">
                                            <CardroomSelect onDataLoad={handleDataLoaded} onChange={handleCardroomChange} />
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={10} md={12} m={isDataLoaded && cardroomCount > 1 ? 4 : 0}>
                                        <SoftBox textAlign="center">
                                            {isDataLoaded ? (cardroomCount > 0 ? (cardroomCount == 1 ? <SingleCardroomNav cardroom={cardroom} /> : <MultipleCardroomNav cardroom={cardroom} />) : <NoCardroomNav />) : <></>}

                                        </SoftBox>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={3}><></></Grid>
                        </Grid>
                    </SoftBox>
                </SoftBox>
            </div>
        </PageLayout>
    );
}

export default SetContextSection;