import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Card, Grid, Icon, ListItemIcon, ListItemText, Switch } from "@mui/material";
import FormField from "components/Elements/Forms/FormField";
import { useProgressIndicator } from "components/Elements/ProgressIndicator";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { Formik, Form, ErrorMessage } from 'formik';


import { ProgressIndicator } from "components/Elements/ProgressIndicator";
import SoftButton from "components/SoftButton";

import form from "./schemas/form";
import validations from "./schemas/validations";
import initialValues from "./schemas/initialValues";

import playerAvatar from "assets/graphics/personas/sportsbettor_gpt.png";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { apiConfig } from "config/apiConfig";
import BookAgentSelector from "../../BookAgents/Selector";
import BookAgentPlayerPoolSelector from "../../BookAgents/PlayerPool/Selector";
import PlayerRebateSelector from "layouts/Club/components/RebateFees/Selector";
import AdaptiveTab from "components/Elements/AdaptiveTab";
import DataBoundList from "components/Elements/DataBound/List";
import ReferralFeeSelector from "layouts/Club/components/ReferralFees/Selector";
import BookPlayerSelector from "../Selector";
import ReferralEditorForm from "../../ReferralConfig";

const emptyGuid = "00000000-0000-0000-0000-000000000000";


const TabPanel = function (props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <SoftBox >
                    <SoftTypography>{children}</SoftTypography>
                </SoftBox>
            )}
        </div>
    );
}




const BookPlayerEditorForm = ({ cardroom, book, player, players, agents, formData, activeTab, state, dense }) => {

    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { agentId, playerId, agentPlayerPoolId, rebateScheduleId, makeupAmount, playerAppId, playerName, playerNickName, validatedPlayerId } = formField;
    const {
        agentId: agentIdV,
        playerId: playerIdV,
        agentPlayerPoolId: agentPlayerPoolIdV,
        rebateScheduleId: rebateScheduleIdV,
        makeupAmount: makeupAmountV,
        playerAppId: playerAppIdV,
        playerName: playerNameV,
        playerNickName: playerNickNameV,
        validatedPlayerId: validatedPlayerIdV
    } = values;


    const [formDataInitialized, setFormDataInitialized] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [selectedPool, setSelectedPool] = useState(null);
    const [reloadAgentPool, setReloadAgentPool] = useState(false);

    const [allowMakeupUpdate, setAllowMakeupUpdate] = useState(false);
    function allowMakeupToggleChanged() {
        let newVal = !allowMakeupUpdate;

        setAllowMakeupUpdate(newVal);
        if (false === newVal) {
            setFieldValue(makeupAmount.name, player ? player.currentMakeupAmount : 0);
        }
    }

    const bookRequest = useFetchWithMsal({
        scopes: apiConfig.book.scopes.read,
    });

    const emptyEntity = { id: "none", "name": "None" };

    useEffect(() => {
        if (!formDataInitialized && book && player) {

            setFieldValue(agentId.name, (player && player.agentId != emptyGuid) ? player.agentId : "none");
            setFieldValue(agentPlayerPoolId.name, (player && player.agentPlayerPoolId != emptyGuid) ? player.agentPlayerPoolId : "none");

            setFieldValue(validatedPlayerId.name, "");

            setFieldValue(playerId.name, player ? player.id : "");

            setFieldValue(playerAppId.name, player ? player.appPlayerId : "");
            setFieldValue(playerName.name, player ? player.name : "");
            setFieldValue(playerNickName.name, player ? player.nickname : "");


            setFieldValue(makeupAmount.name, player ? player.currentMakeupAmount : 0);
            setFieldValue(rebateScheduleId.name, player ? player.rebateScheduleId : "");

            setFormDataInitialized(true);
        }
    }, [formDataInitialized, book, player]);


    useEffect(() => {
        setFormDataInitialized(false);

    }, [book, player]);

    function canEditPlayer() {
        return true;
    }

    function onAgentSelectionChanged(ag) {
        setFieldValue(agentId.name, (ag || emptyEntity).id);
        setSelectedAgent(ag || emptyEntity);

        setReloadAgentPool(true);
    }

    function onAgentPlayerPoolSelectionChanged(pool) {
        //alert(pool);
        setFieldValue(agentPlayerPoolId.name, (pool || emptyEntity).id);
        setSelectedPool(pool || emptyEntity);
    }

    function onPlayerRebateScheduleSelectionChanged(schedule) {
        setFieldValue(rebateScheduleId.name, schedule.id);
    }


    useEffect(() => {
        if (reloadAgentPool) setReloadAgentPool(false);
    }, [reloadAgentPool]);


    useEffect(() => {
        if (!playerIdV && playerAppIdV) {
            // [HttpGet("{bookId:Guid}/casino/{casinoId:Guid}/players/{appPlayerId}/id", Name = "GetPlayerIdFromAppId")]
            bookRequest.execute("GET", apiConfig.book.endpoint + "/player/" + book.id + "/casino/" + cardroom.id + "/players/" + playerAppIdV + "/id").then((response) => {
                if (response && response != emptyGuid) {
                    setFieldValue(validatedPlayerId.name, "error");
                }
                else {
                    setFieldValue(validatedPlayerId.name, "");
                    // setFieldError(playerAppId.name, null);
                }
            }).catch((err) => { });
        }
    }, [playerAppIdV, playerIdV, bookRequest.execute]);


    return <SoftBox>
        <SoftBox mt={2}>
            <TabPanel value={activeTab} index={0}>
                <Grid container spacing={1}>
                    {dense === true ? null :
                        <Grid item xs={12} sm={4} container justifyContent="center">
                            <SoftBox position="relative" height="max-content" mx="auto">
                                <SoftAvatar src={playerAvatar} size="xxl" variant="rounded" bgColor="dark" />
                            </SoftBox>
                        </Grid>}

                    <Grid item xs={12} sm={dense === true ? 12 : 8}>
                        <SoftBox mb={2} textAlign="left">


                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {playerAppId.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={8}>

                                    <FormField onFocus={e => e.currentTarget.select()}
                                        disabled={player && player.id}
                                        type={playerAppId.type}
                                        name={playerAppId.name}
                                        value={playerAppIdV}
                                        placeholder={playerAppId.placeholder}
                                        error={(errors.playerAppId && touched.playerAppId) || (errors.validatedPlayerId && touched.validatedPlayerId)}
                                        success={playerAppIdV && playerAppIdV.toString().length > 0 && !errors.playerAppId && !errors.validatedPlayerId}
                                    />

                                    {errors.validatedPlayerId ?
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            color="error">
                                            <ErrorMessage name={validatedPlayerId.name} />
                                        </SoftTypography> : null}

                                </Grid>
                            </Grid>

                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {playerName.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={8}>

                                    <FormField onFocus={e => e.currentTarget.select()}

                                        type={playerName.type}
                                        name={playerName.name}
                                        value={playerNameV}
                                        placeholder={playerName.placeholder}
                                        error={errors.playerName && touched.playerName}
                                        success={playerNameV && playerNameV.toString().length > 0 && !errors.playerName}
                                    />

                                </Grid>
                            </Grid>

                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {playerNickName.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={8}>

                                    <FormField onFocus={e => e.currentTarget.select()}

                                        type={playerNickName.type}
                                        name={playerNickName.name}
                                        value={playerNickNameV}
                                        placeholder={playerNickName.placeholder}
                                        error={errors.playerNickName && touched.playerNickName}
                                        success={playerNickNameV && playerNickNameV.toString().length > 0 && !errors.playerNickName}
                                    />

                                </Grid>
                            </Grid>


                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {rebateScheduleId.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={8}>

                                    <PlayerRebateSelector cardroom={cardroom} book={book} value={rebateScheduleIdV} readonly={!canEditPlayer()}
                                        placeholder={rebateScheduleId.placeholder} emptyValue={emptyEntity}
                                        onSelectionChanged={onPlayerRebateScheduleSelectionChanged} type="dropdown" />

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={rebateScheduleId.name} />
                                    </SoftTypography>

                                </Grid>
                            </Grid>

                            <Grid container xs={12} mt={-2}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={.75} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            Update Player Makeup
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={8}>
                                    <SoftBox textAlign="left" >
                                        <SoftBox display="flex" py={0.5} mb={0.25}>
                                            <SoftBox mt={0.25}>
                                                <Switch checked={allowMakeupUpdate} onChange={allowMakeupToggleChanged} />
                                            </SoftBox>
                                        </SoftBox>
                                    </SoftBox>

                                </Grid>
                            </Grid>

                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {makeupAmount.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={8}>

                                    <FormField onFocus={e => e.currentTarget.select()}
                                        disabled={!allowMakeupUpdate}
                                        type={makeupAmount.type}
                                        name={makeupAmount.name}
                                        value={makeupAmountV}
                                        placeholder={makeupAmount.placeholder}
                                        error={errors.makeupAmount && touched.makeupAmount}
                                        success={makeupAmountV && makeupAmountV.toString().length > 0 && !errors.makeupAmount}
                                    />

                                </Grid>
                            </Grid>

                            <Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {agentId.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={8}>
                                    <BookAgentSelector cardroom={cardroom} book={book} value={agentIdV} readonly={!canEditPlayer()}
                                        placeholder={agentId.placeholder} emptyValue={emptyEntity}
                                        onSelectionChanged={onAgentSelectionChanged} type="dropdown" />
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={agentId.name} />
                                    </SoftTypography>

                                </Grid>
                            </Grid>

                            {agentIdV && agentIdV !== 'none' && selectedAgent ? <><Grid container xs={12}>
                                <Grid item xs={4} >
                                    <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                        <SoftTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                        >
                                            {agentPlayerPoolId.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={8}>

                                    <BookAgentPlayerPoolSelector cardroom={cardroom} book={book} agent={selectedAgent} value={agentPlayerPoolIdV} readonly={!canEditPlayer()} reload={reloadAgentPool}
                                        placeholder={agentPlayerPoolId.placeholder} emptyValue={emptyEntity} onSelectionChanged={onAgentPlayerPoolSelectionChanged} type="dropdown" />

                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        color="error">
                                        <ErrorMessage name={agentPlayerPoolId.name} />
                                    </SoftTypography>

                                </Grid>
                            </Grid>

                            </> : null}

                        </SoftBox>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
                <Grid item xs={12}>
                    <SoftBox mb={2} textAlign="left">
                        <ReferralEditorForm cardroom={cardroom} book={book} referrer={player}
                            players={players} agents={agents} type="player" state={state}
                            formData={formData} />
                    </SoftBox>
                </Grid>
            </TabPanel>
        </SoftBox>
    </SoftBox>;
}

BookPlayerEditorForm.propTypes = {
    cardoom: PropTypes.object.isRequired,
    book: PropTypes.object.isRequired,
    player: PropTypes.object,
    players: PropTypes.array.isRequired,
    agents: PropTypes.array.isRequired,
    formData: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired
};

const BookPlayerSubEditor = ({ cardroom, book, player, players, agents, activeTab, onPlayerUpdated, dense, onClose }) => {

    const { formId, formField } = form;
    const currentValidation = validations.edit;

    const [retryCounter, setRetryCounter] = useState(0);

    let state = player ? player.state : null;

    if (!state) {
        state = {
            referrals: [],
            changes: {
                referrals: false
            }
        };

        if (player) player.state = state;
    }


    const [referralState, setReferralState] = useState(state);


    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    function playerPropertiesUpdated(p) {
        if (onPlayerUpdated) onPlayerUpdated(p);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "saving player properties...", handleRetry)

    const [pendingPlayer, setPendingPlayer] = useState(null);
    const [refreshPlayerPool, setRefreshPlayerPool] = useState(true);

    function onPlayerChanged(p) {
        if (onPlayerUpdated) onPlayerUpdated(p);
        if (onClose) onClose();
    }

    const handleSubmit = (values, actions) => {

        //var p = Object.assign({}, player);

        var p = {
            playerId: player.id,
            playerName: values.playerName,
            playerNickname: values.playerNickName,
            appPlayerId: values.playerAppId,
            rebateScheduleId: values.rebateScheduleId,
            makeup: values.makeupAmount
        };

        if (!p.rebateScheduleId || p.rebateScheduleId === "none") {
            p.rebateScheduleId = emptyGuid;
        }

        if (values.agentId && values.agentPlayerPoolId && values.agentId !== "none") {
            //&& values.agentPlayerPoolId !== "none" -- should be caught by validation
            p.action = "add";
            p.agentId = values.agentId;
            p.poolId = values.agentPlayerPoolId;
            //p.poolId = (!values.agentPlayerPoolId || values.agentPlayerPoolId === "none") ? emptyGuid : values.agentPlayerPoolId;
        }
        else {
            //delete from pool, but we need current pool assignment - so we either need method to see current pools, or method to simply clear player from any pool
            if (player.agentPlayerPoolId && player.agentPlayerPoolId != emptyGuid) {
                p.action = "remove";
                p.agentId = player.agentId;
                p.poolId = player.agentPlayerPoolId;
            }
        }

        // if (p.action) {
        setPendingPlayer(p);
        // }

    };

    const bookWriteRequest = useFetchWithMsal({
        scopes: apiConfig.book.scopes.write,
    });

    useEffect(() => {
        if (pendingPlayer) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Updating player configuration...");
            showProgress();

            function onPoolSaveError(err) {
                var msg = "Unable to save player, please try again...";

                switch (pendingPlayer.action) {
                    case "add":
                        msg = "Unable to add player to package, please try again..."
                        break;
                    case "remove":
                        msg = "Unable to remove player from package, please try again..."
                        break;
                }

                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage(msg);
            }

            function onPoolSaveSuccess(response) {
                if (response) {
                    //load player pool
                    if (pendingPlayer.action === "remove") {
                        player.agentId = emptyGuid;
                        player.agentPlayerPoolId = emptyGuid;
                    }
                    else {
                        player.agentId = pendingPlayer.agentId;
                        player.agentPlayerPoolId = pendingPlayer.poolId;
                    }
                    setRefreshPlayerPool(false);

                    if (!referralState || !referralState.changes || !referralState.changes.referrals !== true) {
                        onPlayerReferralSave(true);
                        return;
                    }

                    //[HttpPut("{bookId:Guid}/casino/{casinoId:Guid}/players/{playerId:Guid}/referral", Name = "SetPlayerReferralsAsync")]
                    const url = apiConfig.book.endpoint + "/player/" + book.id + "/casino/" + cardroom.id + "/players/" + player.id + "/referral";

                    progressIndicatorProps.setMessage("Saving player referrals...");
                    bookWriteRequest.execute("PUT", url).then(onPlayerReferralSave).catch(onPlayerReferralError);

                }

            }


            function onPlayerConfigSuccess(response) {
                if (response) {
                    player.rebateScheduleId = response.rebateScheduleId;
                    player.currentMakeupAmount = response.currentMakeupAmount;
                    player.name = response.name;
                    player.nickname = response.nickname;
                    player.appPlayerId = response.appPlayerId;


                    if (!pendingPlayer.action || (player.agentId == pendingPlayer.agentId && player.agentPlayerPoolId == pendingPlayer.poolId)) {
                        onPoolSaveSuccess(true);
                        return;
                    }

                    const url = apiConfig.book.endpoint + "/agent/" + book.id + "/casino/" + cardroom.id + "/agents/" + pendingPlayer.agentId + "/pools/" + pendingPlayer.poolId + "/players/" + pendingPlayer.playerId;
                    switch (pendingPlayer.action) {
                        case "add":

                            progressIndicatorProps.setMessage("Adding player to package...");
                            bookWriteRequest.execute("PUT", url).then(onPoolSaveSuccess).catch(onPoolSaveError);
                            break;
                        case "remove":
                            progressIndicatorProps.setMessage("Removing player from package...");
                            bookWriteRequest.execute("DELETE", url).then(onPoolSaveSuccess).catch(onPoolSaveError);
                            break;
                    }
                }

                setPendingPlayer(null);
                // progressIndicatorProps.close();
            }

            function onPlayerConfigError(err) {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to update player configuration, please try again...");
            }

            function onPlayerReferralSave(response) {

                progressIndicatorProps.close();
                onPlayerChanged(player);
            }

            function onPlayerReferralError(err) {
                var msg = "Unable to save player referral configuration, please try again...";

                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage(msg);
            }

            var plr = {
                id: pendingPlayer.playerId,
                name: pendingPlayer.playerName,
                nickname: pendingPlayer.playerNickname,
                appPlayerId: pendingPlayer.appPlayerId,
                currentMakeupAmount: pendingPlayer.makeup,
                rebateScheduleId: pendingPlayer.rebateScheduleId
            };
            //[HttpPost("{bookId:Guid}/casino/{casinoId:Guid}/players", Name = "SavePlayer")]
            bookWriteRequest.execute("POST", apiConfig.book.endpoint + "/player/" + book.id + "/casino/" + cardroom.id + "/players", plr)
                .then(onPlayerConfigSuccess)
                .catch(onPlayerConfigError);
            //}
        }

    }, [bookWriteRequest.execute, pendingPlayer, retryCounter]);


    //TODO: GET playerConfig

    return !progressIndicatorProps.visible ? <>

        <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm, isSubmitting }) => {
                return <Form id={formId} autoComplete="off">
                    <SoftBox p={2} >
                        <SoftBox >
                            <ProgressIndicator {...progressIndicatorProps} />

                            {progressIndicatorProps.visible ? <></> : <>

                                <BookPlayerEditorForm cardroom={cardroom} book={book} player={player}
                                    players={players} agents={agents} state={referralState}
                                    activeTab={activeTab} dense={dense} formData={{
                                        values,
                                        touched,
                                        formField,
                                        errors,
                                        setFieldValue,
                                        setFieldError,
                                        setFieldTouched,
                                        validateForm,
                                        resetForm
                                    }} />


                                <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                                    <div></div>

                                    <SoftButton
                                        variant="gradient"
                                        color="dark"
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        save
                                    </SoftButton>
                                </SoftBox>
                            </>

                            }

                        </SoftBox>
                    </SoftBox>

                </Form>
            }}
        </Formik></> : <ProgressIndicator {...progressIndicatorProps} />;
};

const BookPlayerEditor = ({ cardroom, book, player, players, agents, onPlayerUpdated, dense, onClose }) => {
    const tabs = [];

    tabs.push({
        title: { text: "Properties", icon: "contact_phone" },
        header: null,
        body: <BookPlayerSubEditor cardroom={cardroom} book={book} player={player} players={players} agents={agents} onPlayerUpdated={onPlayerUpdated} onClose={onClose} activeTab={0} />
    });

    tabs.push({
        title: { text: "Referral Fees", icon: "recommend" },
        header: null,
        body: <BookPlayerSubEditor cardroom={cardroom} book={book} player={player} players={players} agents={agents} onPlayerUpdated={onPlayerUpdated} onClose={onClose} activeTab={1} />
    });

    return <SoftBox><AdaptiveTab switchToMobileOnSize="md" tabs={tabs} /></SoftBox>;
};

BookPlayerEditor.propTypes = {
    cardoom: PropTypes.object.isRequired,
    book: PropTypes.object.isRequired,
    player: PropTypes.object,
    players: PropTypes.array.isRequired,
    agents: PropTypes.array.isRequired,
    onPlayerUpdated: PropTypes.func,
    dense: PropTypes.bool,
    onClose: PropTypes.func
};

export default BookPlayerEditor;