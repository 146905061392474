
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Card, Grid, Icon, ListItemIcon, ListItemText, Switch } from "@mui/material";
import FormField from "components/Elements/Forms/FormField";
import { useProgressIndicator } from "components/Elements/ProgressIndicator";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { Formik, Form, ErrorMessage } from 'formik';


import { ProgressIndicator } from "components/Elements/ProgressIndicator";
import SoftButton from "components/SoftButton";

import playerAvatar from "assets/graphics/personas/sportsbettor_gpt.png";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { apiConfig } from "config/apiConfig";
import AdaptiveTab from "components/Elements/AdaptiveTab";
import DataBoundList from "components/Elements/DataBound/List";
import ReferralFeeSelector from "layouts/Club/components/ReferralFees/Selector";
import BookPlayerSelector from "../BookPlayers/Selector";


const ReferralConfigSelector = ({ value, readonly, emptyValue, onSelectionChanged, onBuildListItem, maxHeight, referrals, candidates, reload }) => {


    const [availableOptions, setAvailableOptions] = useState(null);

    const [selectedValue, setSelectedValue] = useState(value ? value : emptyValue);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const candidateMap = [];

    function getSelectedIndex() {
        let dataSource = availableOptions; // getDataSource();

        if (!dataSource) return -1;
        if (!selectedValue) return -1;

        for (var i = 0; i < dataSource.length; i++) {
            if (dataSource[i].id == selectedValue.id || dataSource[i].id == selectedValue) {
                return i;
            }
        }

        return -1;
    }

    function findValueInDataSource(v) {
        let dataSource = getDataSource();

        if (!dataSource) return null;
        if (!v) return null;

        for (var i = 0; i < dataSource.length; i++) {
            if (dataSource[i].id == v.id || dataSource[i].id == v) {
                return dataSource[i];
            }
        }

        return -1;
    }

    function getDataSource() {
        let r = referrals || [];

        if (candidateMap.length === 0) {
            for (var i = 0; i < candidates.length; i++) {
                if (!candidateMap[candidates[i].id]) {
                    candidateMap[candidates[i].id] = candidates[i];
                }
            }
            for (var i = 0; i < r.length; i++) {
                r[i].referrer = candidateMap[r[i].referrerId];
                r[i].referred = candidateMap[r[i].referredId];
                r[i].id = r[i].referredId;
                r[i].name = r[i].referred ? r[i].referred.name : "Unknown";
            }
        }

        let rows = [...r];

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                var aName = a.referred ? a.referred.name.toLowerCase() : "";
                var bName = b.referred ? b.referred.name.toLowerCase() : "";

                if (aName < bName) { return -1; }
                if (aName > bName) { return 1; }
                return 0;
            });
        }

        if (emptyValue) {
            rows.unshift(emptyValue);
        }

        return rows;
    }

    function handleScheduleChange({ item, index }) {
        setSelectedIndex(null);
        setSelectedValue(item);

        if (onSelectionChanged) {
            onSelectionChanged(item);
        }
    }


    useEffect(() => {
        if (candidates) {
            setAvailableOptions(getDataSource());

            if (selectedValue && !selectedIndex) {

                setSelectedIndex(getSelectedIndex());
            }
        }

    }, [candidates, selectedIndex, selectedValue]);

    useEffect(() => {
        if (candidates && value) {
            //find in datasource
            setSelectedValue(findValueInDataSource(value));
        }

    }, [candidates, value]);

    useEffect(() => {
        if (reload && referrals) {
            setAvailableOptions(getDataSource());
        }
    }, [referrals, reload]);

    return <>
        {readonly ?
            <SoftTypography
                variant="caption"
                fontWeight="regular"
                color="dark"
            > {selectedValue ? selectedValue.name : "[None]"}
            </SoftTypography> : <>{availableOptions ?
                <DataBoundList data={availableOptions} valueName="id" textName="name" onChange={handleScheduleChange}
                    selectedIndex={selectedIndex} onBuildListItem={onBuildListItem} maxHeight={maxHeight} />
                : null}
            </>
        }
    </>;
};

const ReferredParticipantSelector = ({ cardroom, book, candidate, candidates, placeholder, onCandidateSelected, readonly }) => {


    //TODO: add swith based on type
    return <BookPlayerSelector placeholder={placeholder} valueName="id" textName="name" cardroom={cardroom} book={book} eligblePlayers={candidates}
        value={candidate} onSelectionChanged={onCandidateSelected} readonly={readonly} type="dropdown" />;
};


const ReferralEditorForm = ({ cardroom, book, referrer, formData, players, agents, state, type }) => {

    const bookReadRequest = useFetchWithMsal({
        scopes: apiConfig.book.scopes.read,
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading referrals...", handleRetry)


    const { formField, values, errors, touched, setFieldValue, setFieldError, setFieldTouched, validateForm, resetForm } = formData;
    const { referrerId,
        referredId,
        referralScheduleId,
        referralScheduleName,
        referrerType,
        referredType } = formField;

    const [candidates, setCandidates] = useState(players || agents || []);


    const [currentReferral, setCurrentReferral] = useState(null);
    const [availableCandidates, setAvailableCandidates] = useState(null);

    const [referrals, setReferrals] = useState(state && state.referrals ? state.referrals : null);

    const [dataLoading, setDataLoading] = useState(false);

    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [selectedReferralSchedule, setSelectedReferralSchedule] = useState(null);
    const [reloadSelectorList, setReloadSelectorList] = useState(false);

    //const emptyEntity = { id: "none", "name": "None" };


    useEffect(() => {
        if (!referrals) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading referrals...")
            showProgress();
            //[HttpGet("{bookId:Guid}/casino/{casinoId:Guid}/players/{playerId:Guid}/referral", Name = "GetPlayerReferralsAsync")]
            // [HttpGet("{bookId:Guid}/casino/{casinoId:Guid}/agents/{agentId:Guid}/referral", Name = "GetAgentReferralsAsync")]

            let url = apiConfig.book.endpoint + "/" + type + "/" + book.id + "/casino/" + cardroom.id + "/" + type + "s/" + referrer.id + "/referral";

            bookReadRequest.execute("GET", url).then((response) => {

                setReferrals(response);

                if (state) {
                    state.referrals = referrals;
                }

                setAvailableCandidates(getCandidatesEligibleForSelection());
                progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load referrals, please try again...")
            });
        }
    }, [bookReadRequest.execute, referrals, retryCounter]) //cardroomListData 


    function buildListItem(item) {
        if (item.id) {
            if (item.id === "403") {
                return <>
                    <ListItemIcon style={{ minWidth: "32px" }}>
                        <Icon color={"error"}>block</Icon>
                    </ListItemIcon>
                    <ListItemText><SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize">
                        {item.name}
                    </SoftTypography></ListItemText></>;
            }
            else {
                return <ListItemText><SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    {item.name}
                </SoftTypography></ListItemText>;
            }
        }
        else {
            return <>
                <ListItemIcon style={{ minWidth: "32px" }}>
                    <Icon>add</Icon>
                </ListItemIcon>
                <ListItemText><SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    {item.name}
                </SoftTypography></ListItemText></>;
        }
    }

    function onReferralConfigSelectionChanged(referral) {
        if (referral && referral.id === "403") referral = null;

        if (referral && !referral.id) {
            //new referral
            referral = {
                referred: {
                    placeholder: "New Referral"
                },
                schedule: null,
                name: "New Referral"
            };
        }

        setSelectedCandidate(referral.referred);
        setSelectedReferralSchedule(referral.schedule);
        setCurrentReferral(referral);

        /*  - handled inside useEffect logic watching currentReferral
        if (referral) {
            setSelectedReferredClub(referral.referredClub);
            setSelectedReferralSchedule(referral.referralSchedule);

            setFieldValue(referredClub.name, referral.referredClub ? referral.referredClub.id : "");
            setFieldValue(referralSchedule.name, referral.referralSchedule ? referral.referralSchedule.id : "");
        }
        */
    }

    function onReferredCandidateChanged(item) {
        if (item) {
            setSelectedCandidate(item);
            setFieldValue(referredId.name, item.id);
        }
    }
    function onReferralScheduleChanged(item) {
        if (item) {
            setSelectedReferralSchedule(item);
            setFieldValue(referralScheduleId.name, item.id);
            setFieldValue(referralScheduleName.name, item.text);
        }
    }

    function updateReferral() {
        if (!currentReferral) return;

        if (!canReferredBeSaved()) {
            setFieldError(referredId.name);
            setFieldTouched(referredId.name);
        }

        if (canScheduleBeSaved()) {
            setFieldError(referralScheduleId.name);
            setFieldTouched(referralScheduleId.name);
        }
        if (errors.referredId || errors.referralScheduleId || errors.referredId) return;


        let referral = currentReferral;
        let isNew = false;
        if (!referral.id) {
            referral = {
                id: selectedCandidate.id,
                name: selectedCandidate.name
            };
            isNew = true;
        }

        referral.referred = selectedCandidate;
        referral.referrer = referrer;

        referral.referrerId = referral.referrer.id;
        referral.referredId = referral.referred.id;

        referral.schedule = selectedReferralSchedule;

        //referral.referrerType = "";


        if (isNew) {
            referrals.push(referral);
        }

        setFieldValue(referredId.name, "123");
        setFieldValue(referralScheduleId.name, "123");

        setReloadSelectorList(true);

        if (state) {
            if (!state.changes) {
                state.changes = {};
            }
            state.changes.referrals = true;
        }
    }

    function getCandidatesEligibleForSelection() {
        let exclusions = []; // add referrals.referred in here
        if (referrals) {
            for (var i = 0; i < referrals.length; i++) {
                if (referrals[i].referred) {
                    exclusions.push(referrals[i].referred);
                }
            }
        }
        exclusions.push(referrer);

        //todo: fix logi here
        if (type == "player") {
            // filter players, remove selected player
            return filterInelgibelCandidates(players, exclusions);
        }
        if (type == "agent") {
            return filterInelgibelCandidates(agents, exclusions);;

        }

        return filterInelgibelCandidates(candidates, exclusions);
    }

    function filterInelgibelCandidates(list, exclusions) {
        let excluded = [];

        if (exclusions) {
            for (var i = 0; i < exclusions.length; i++) {
                if (!excluded[exclusions[i].id]) excluded[exclusions[i].id] = true;
            }
        }

        let result = [];

        if (list) {
            for (var i = 0; i < list.length; i++) {
                if (excluded[list[i].id]) continue;
                result.push(list[i]);
            }
        }
        return result;

    }

    function canSave() {
        return canSetReferrals() && canReferredBeSaved() && canScheduleBeSaved();
    }

    function canReferredBeSaved() {
        if (!selectedCandidate || !selectedCandidate.id) {
            return false;
        }

        return !isReferralUnchanged();
    }
    function canScheduleBeSaved() {
        if (!selectedReferralSchedule || !selectedReferralSchedule.id) {
            return false;
        }

        return !isReferralUnchanged();
    }

    function isReferralUnchanged() {
        //todo: add logic to see if referral has changed or is new
        return false;
    }

    function canSetReferrals() {
        //if (!context) return false;

        // ReferralScheduleSet = 8
        //if ((union.features & 8) === 0) return false;

        return true;
    }

    const emptyReferralValue = canSetReferrals() ? { id: null, name: "New Referral" } : { id: "403", name: "Not Supported" };

    useEffect(() => {
        if (reloadSelectorList) {
            setReloadSelectorList(false);
            setCurrentReferral(null);
        }

    }, [reloadSelectorList]);

    useEffect(() => {

        resetForm();

        setFieldValue(referrerId.name, referrer.id);

        if (currentReferral) {

            setSelectedCandidate(currentReferral.referred);
            setSelectedReferralSchedule(currentReferral.referralSchedule);

            setFieldValue(referralScheduleId.name, currentReferral.referralSchedule ? currentReferral.referralSchedule.id : "");
            setFieldValue(referredId.name, currentReferral.referred ? currentReferral.referred.id : "");

        }
        else {
            setFieldValue(referralScheduleId.name, "123");
            setFieldValue(referredId.name, "123");
        }
        setAvailableCandidates(getCandidatesEligibleForSelection());


    }, [currentReferral]);


    return <Grid width="100%" xs={12}>
        <Grid container xs={12}>
            <Grid item xs={4} pr={2} sx={{ height: 375 }}>
                {!progressIndicatorProps.visible ?
                    <ReferralConfigSelector referrals={referrals} candidates={candidates} maxHeight={"375px"}
                        onBuildListItem={buildListItem} onSelectionChanged={onReferralConfigSelectionChanged} emptyValue={emptyReferralValue} reload={reloadSelectorList} />
                    : <ProgressIndicator {...progressIndicatorProps} />}
            </Grid>
            <Grid item xs={8}>
                <Card style={{ overflow: "auto" }}>
                    <Card sx={{ height: 375 }}>
                        {currentReferral ?
                            <Grid container spacing={1} mt={2}>
                                <Grid item xs={12} sm={12} m={2}>
                                    <SoftBox mb={2} textAlign="left">
                                        <Grid container xs={12}>
                                            <Grid item xs={4} >
                                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize">
                                                        {referredId.label} {type}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {dataLoading ? <></> : <>

                                                    <ReferredParticipantSelector placeholder={"select a " + type}
                                                        candidate={selectedCandidate} candidates={availableCandidates}
                                                        onCandidateSelected={onReferredCandidateChanged} readonly={!canSetReferrals()} />

                                                    {errors.referredId && touched.referredId ?
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            color="error">
                                                            <ErrorMessage name={referredId.name} />
                                                        </SoftTypography> : null}
                                                </>}
                                            </Grid>
                                        </Grid>

                                        <Grid container xs={12} mt={2}>
                                            <Grid item xs={4} >
                                                <SoftBox mb={1} mt={1.25} ml={0.5} lineHeight={0} display="inline-block" >
                                                    <SoftTypography
                                                        component="label"
                                                        variant="caption"
                                                        fontWeight="bold"
                                                        textTransform="capitalize">
                                                        {referralScheduleId.label}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {dataLoading ? <></> : <>
                                                    <ReferralFeeSelector cardroom={cardroom} book={book} value={selectedReferralSchedule} emptyValue={{ id: "none", name: "None" }}
                                                        placeholder={referralScheduleId.placeholder} onSelectionChanged={onReferralScheduleChanged} readonly={!canSetReferrals()} />
                                                    {errors.referralScheduleId && touched.referralScheduleId ?
                                                        <SoftTypography
                                                            component="label"
                                                            variant="caption"
                                                            color="error">
                                                            <ErrorMessage name={referralScheduleId.name} />
                                                        </SoftTypography> : null}
                                                </>}
                                            </Grid>
                                        </Grid>

                                    </SoftBox>

                                    <Grid container xs={12} display={"flex"} flexDirection={"row-reverse"}>
                                        <SoftButton variant="outlined" color={canSave() ? "dark" : "secondary"} onClick={updateReferral} disabled={!canSave()}>
                                            OK
                                        </SoftButton>
                                    </Grid>
                                </Grid>
                            </Grid > :
                            <Grid container xs={12} justifyContent="center" alignItems="center">
                                <Grid item xs={12} textAlign={"center"}>

                                    <SoftTypography
                                        component="h5"
                                        variant="caption"
                                        color="info">
                                        Select referral from the list or tap "add new" to create a new referral
                                    </SoftTypography>

                                </Grid>
                            </Grid>
                        }</Card>
                </Card>
            </Grid>
        </Grid>
    </Grid>;
};

ReferralEditorForm.propTypes = {
    cardoom: PropTypes.object.isRequired,
    book: PropTypes.object.isRequired,
    referrer: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired,
    players: PropTypes.array,
    agents: PropTypes.array
};

export default ReferralEditorForm;