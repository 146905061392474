import { apiConfig } from "config/apiConfig";

function loader(method, type) {
    this.method = method;
    this.type = type;
}

const loaderExec = {
    execute: function (queue, request, cardroom, entity, enableCache, result, onsuccess, onerror) {
        if (!queue) {
            if (onsuccess) onsuccess(null);
            return;
        }

        if (!result) result = [];

        if (queue.length === 0) {
            if (onsuccess) onsuccess(result);
        }
        else {
            let l = queue.shift();
            if (l && l.method) {
                l.method(request, cardroom, entity, enableCache, (items) => {
                    let entities = [];
                    if (items) {
                        entities = [...result, ...items];
                    }
                    loaderExec.execute(queue, request, cardroom, entity, enableCache, entities, onsuccess, onerror);

                }, (ex) => {
                    if (onerror) onerror(ex, l.type);
                });
            }
        }
    }
};

const reportDataLoader = {
    loadUnions: function (request, cardroom, union, enableCache, onsuccess, onerror) {
        if (union && union.unions) {
            if (onsuccess) onsuccess(union.unions);
            return;
        }

        // [HttpGet("{unionId:Guid}/casino/{casinoId:Guid}/unions", Name = "GetUnionUnions")]
        request.execute("GET", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id + "/unions").then((response) => {
            if (enableCache) union.unions = response;
            if (onsuccess) onsuccess(response);
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    loadClubs: function (request, cardroom, reportingPeriod, union, enableCache, onsuccess, onerror) {

        if (reportingPeriod && reportingPeriod.clubs) {
            if (onsuccess) onsuccess(reportingPeriod.clubs);
            return;
        }

        let url = apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/clubs";

        // if (union && union.id) url += "?unionId=" + union.id; --- DO NOT USE THIS AS IT CAN MESS UP PRESENTATION, INSTEAD, FILTER OFF CLUBS WE DON'T WANT IN UI

        request.execute("GET", url).then((response) => {
            if (enableCache) reportingPeriod.clubs = response;
            if (onsuccess) onsuccess(response);
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });

    },
    loadTokens: function (request, cardroom, reportingPeriod, enableCache, onsuccess, onerror) {
        if (reportingPeriod && reportingPeriod.tokens) {
            if (onsuccess) onsuccess(reportingPeriod.tokens);
            return;
        }

        request.execute("GET", apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/tokens").then((response) => {
            if (enableCache) reportingPeriod.tokens = response;
            if (onsuccess) onsuccess(response);
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    loadXUnionSummary: function (request, cardroom, union, reportingPeriod, enableCache, onsuccess, onerror) {

        if (reportingPeriod && reportingPeriod.xUnionSummaries) {

            let result = null;
            if ((!union || !union.id) && reportingPeriod.xUnionSummaries["default"]) result = reportingPeriod.xUnionSummaries["default"];
            if (union && union.id && reportingPeriod.xUnionSummaries[union.id]) result = reportingPeriod.xUnionSummaries[union.id];

            if (result) {
                if (onsuccess) onsuccess(result);
                return;
            }
        }

        //get xunion summary
        let url = apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/" + reportingPeriod.id + "/xunion";
        if (union) url += "?unionId=" + union.id;

        request.execute("GET", url).then((response) => {
            if (enableCache) {
                if (!reportingPeriod.xUnionSummaries) reportingPeriod.xUnionSummaries = [];
                if (!union || !union.id) {
                    reportingPeriod.xUnionSummaries["default"] = response;
                }
                else {
                    reportingPeriod.xUnionSummaries[union.id] = response;
                }
            }
            if (onsuccess) onsuccess(response);
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    loadClubSummaryHistory: function (request, cardroom, union, club, rows, onsuccess, onerror) {
        //[HttpGet("casino/{casinoId:Guid}/reports/unions/{unionId:Guid}/clubs/{clubId:Guid}/{rows:int}", Name = "GetClubReportingSummaryHistory")]  
        request.execute("GET", apiConfig.club.endpoint + "/reporting/casino/" + cardroom.id + "/reports/unions/" + union.id + "/clubs/" + club.id + "/" + rows).then((response) => {
            if (onsuccess) onsuccess(response);
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    }
};

const unionDataLoader = {

    //we need a way to load PARENT union?

    loadUnions: function (request, cardroom, union, enableCache, onsuccess, onerror) {
        if (union && union.unions) {
            if (onsuccess) onsuccess(union.unions);
            return;
        }

        request.execute("GET", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id + "/unions").then((unions) => {
            if (unions) {
                if (enableCache) union.unions = unions;
                if (onsuccess) onsuccess(unions);
            }
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    loadClubs: function (request, cardroom, union, enableCache, onsuccess, onerror) {

        if (union && union.clubs) {
            if (onsuccess) onsuccess(union.clubs);
            return;
        }

        request.execute("GET", apiConfig.club.endpoint + "/union/" + union.id + "/casino/" + cardroom.id + "/clubs").then((clubs) => {
            if (clubs) {
                if (enableCache) union.clubs = clubs;
                if (onsuccess) onsuccess(clubs);
            }
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    loadEntities: function (request, cardroom, union, entities, enableCache, onsuccess, onerror) {
        const q = [];

        if ((entities & entitiesEnum.clubs) > 0) q.push(new loader(unionDataLoader.loadClubs, "clubs"));
        if ((entities & entitiesEnum.unions) > 0) q.push(new loader(unionDataLoader.loadUnions, "unions"));

        loaderExec.execute(q, request, cardroom, union, enableCache, null, onsuccess, onerror);

    }
};

const clubDataLoader = {
    loadPlayers: function (request, cardroom, club, enableCache, onsuccess, onerror) {
        if (club && club.players) {
            if (onsuccess) onsuccess(club.players);
            return;
        }

        request.execute("GET", apiConfig.club.endpoint + "/club/" + club.id + "/casino/" + cardroom.id + "/players").then((players) => {
            if (players) {
                if (enableCache) club.players = players;
                if (onsuccess) onsuccess(players);
            }
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    loadAgents: function (request, cardroom, club, enableCache, onsuccess, onerror) {

        if (club && club.agents) {
            if (onsuccess) onsuccess(club.agents);
            return;
        }

        request.execute("GET", apiConfig.club.endpoint + "/club/" + club.id + "/casino/" + cardroom.id + "/agents").then((agents) => {
            if (agents) {
                if (enableCache) club.agents = agents;
                if (onsuccess) onsuccess(agents);
            }
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },
    loadUnions: function (request, cardroom, club, enableCache, onsuccess, onerror) {
        if (club && club.unions) {
            if (onsuccess) onsuccess(club.unions);
            return;
        }

        request.execute("GET", apiConfig.club.endpoint + "/club/" + club.id + "/casino/" + cardroom.id + "/unions").then((unions) => {
            if (unions) {
                if (enableCache) club.unions = unions;
                if (onsuccess) onsuccess(unions);
            }
        }).catch((ex) => {
            if (onerror) onerror(ex);
        });
    },

    loadEntities: function (request, cardroom, club, entities, enableCache, onsuccess, onerror) {
        const q = [];

        if ((entities & entitiesEnum.players) > 0) q.push(new loader(clubDataLoader.loadPlayers, "players"));
        if ((entities & entitiesEnum.agents) > 0) q.push(new loader(clubDataLoader.loadAgents, "agents"));
        if ((entities & entitiesEnum.unions) > 0) q.push(new loader(clubDataLoader.loadUnions, "unions"));

        loaderExec.execute(q, request, cardroom, club, enableCache, null, onsuccess, onerror);
    }
}

const entitiesEnum = {
    players: 1,
    agents: 2,
    clubs: 4,
    unions: 8
};

const useUnionDataLoader = () => {
    return {
        report: reportDataLoader,
        entity: unionDataLoader,
        entities: entitiesEnum
    };
};

const useClubDataLoader = () => {
    return {
        report: null,
        entity: clubDataLoader,
        entities: entitiesEnum
    };
};

const useAgencyDataLoader = () => {
    return {
        entities: entitiesEnum
    };
};

export {
    useUnionDataLoader,
    useClubDataLoader,
    useAgencyDataLoader
}