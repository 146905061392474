

import React, { useState, useEffect } from 'react';

import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { BootstrapDialog } from "components/Elements/Dialog/common"

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import AccountTransactionForm from '../Forms';
import { Card, Grid } from '@mui/material';
import DataBoundSelect from 'components/Elements/DataBound/Dropdown';
import AccountTransactionReceipt from './Receipt';
import SoftButton from 'components/SoftButton';


const AccountTransactionReceiptDialog = ({ open, close, cardroom, account, transaction, calculator, currency, currencySymbol, currencyConverter, onChanged }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //size at which dialog goes full screen

    function onTransactionChanged(tx) {
        if (onChanged) {
            onChanged(tx);
        }
        onDialogClosureRequested();
    }

    function onDialogClosureRequested() {
        //setSrcEntity(null);
        //setTrgEntity(null);
        close();
    }

    // const [srcEntity, setSrcEntity] = useState(transaction.context ? transaction.context.srcEntity : null);
    // const [trgEntity, setTrgEntity] = useState(transaction.context ? transaction.context.trgEntity : null);

    const srcEntity = transaction.context ? transaction.context.srcEntity : null;
    const trgEntity = transaction.context ? transaction.context.trgEntity : null;

    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}
            onClose={close}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="xl">
            <DialogTitle sx={{ m: 0, p: 2 }} style={{ backgroundColor: theme.palette.secondary.main }}>
                <SoftBox>
                    <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                        Account Transaction
                    </SoftTypography>
                </SoftBox>
            </DialogTitle>
            <DialogContent dividers>

                <Card style={{ minHeight: 200 }} mt={3}>
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <AccountTransactionReceipt cardroom={cardroom} account={account} srcEntity={srcEntity} trgEntity={trgEntity} transaction={transaction}
                                calculator={calculator} currency={currency} currencyConverter={currencyConverter} currencySymbol={currencySymbol}
                                onClose={onDialogClosureRequested} onTransactionUpdated={onTransactionChanged} />
                        </Grid>
                    </Grid>
                </Card>
            </DialogContent>
        </BootstrapDialog>
    );
};

export default AccountTransactionReceiptDialog;