import * as Yup from "yup";
import "yup-phone";
import form from "./form";

const {
    formField: { defaultCurrency, supportedCurrencies },
} = form;



const validations = {
    //add logic to account for markerBalance
    editor: Yup.object().shape({
        [defaultCurrency.name]: Yup.string().required(defaultCurrency.errorMsg),
        [supportedCurrencies.name]: Yup.number().min(1, supportedCurrencies.errorMsg).required(supportedCurrencies.errorMsg)
    }),

};

export default validations;