import { AccountBoxTwoTone } from "@mui/icons-material";
import { Card, Grid, useMediaQuery, useTheme } from "@mui/material";
import DataTable from "components/Elements/DataTable";
import ButtonCell from "components/Elements/DataTable/components/Cells/ButtonCell";
import DateCell from "components/Elements/DataTable/components/Cells/DateCell";
import MoneyCell from "components/Elements/DataTable/components/Cells/MoneyCell";
import TextCell from "components/Elements/DataTable/components/Cells/TextCell";
import { ProgressIndicator } from "components/Elements/ProgressIndicator";
import { useProgressIndicator } from "components/Elements/ProgressIndicator";
import { apiConfig } from "config/apiConfig";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { useClubDataLoader } from "layouts/Club/Union/components/common";
import { useAgencyDataLoader } from "layouts/Club/Union/components/common";
import { useUnionDataLoader } from "layouts/Club/Union/components/common";
import { usePlayerCalculator } from "layouts/Club/components/Reports/common/calculator";
import { useEffect, useState } from "react";
import PurchaseOrderDialog from "./Dialogs";
import { useDialog } from "components/Elements/Dialog/common";
import IconCell from "components/Elements/DataTable/components/Cells/IconCell";

const tableHelper = {
    convertPurchaseOrdersToTable: function (purchaseOrders, holders, cardroom, table, currency, currencySymbol, currencyConverter) {

        const holderMap = [];
        if (holders) {
            for (var i = 0; i < holders.length; i++) {
                if (!holderMap[holders[i].id]) {
                    holderMap[holders[i].id] = holders[i];
                }
            }
        }

        if (!holderMap[cardroom.id]) {
            holderMap[cardroom.id] = cardroom;
        }


        function getPoType(v) {
            if (v === 1) return "Chips";
            if (v === 2) return "Tokens";

            return null;
        }

        function getPoStatus(v) {
            switch (v) {
                case 1:
                    return "pending";
                case 2:
                    return "completed";
                case 4:
                    return "rejected";
                case 8:
                    return "canceled";
            }

            return "Unknown";
        }

        var rows = [];

        if (purchaseOrders && purchaseOrders.length > 0) {
            for (var i = 0; i < purchaseOrders.length; i++) {

                let buyerEntity = null, sellerEntity = null;

                if (purchaseOrders[i].sellerAccount && holderMap[purchaseOrders[i].sellerAccount.accountHolderId]) {
                    sellerEntity = holderMap[purchaseOrders[i].sellerAccount.accountHolderId];
                }

                if (purchaseOrders[i].buyerAccount && holderMap[purchaseOrders[i].buyerAccount.accountHolderId]) {
                    buyerEntity = holderMap[purchaseOrders[i].buyerAccount.accountHolderId];
                }

                purchaseOrders[i].context = {
                    sellerEntity: sellerEntity,
                    buyerEntity: buyerEntity,
                    type: getPoType(purchaseOrders[i].type),
                    status: getPoStatus(purchaseOrders[i].status)
                };


                var row = {
                    id: purchaseOrders[i].id,
                    requestedOn: purchaseOrders[i].requestedOnUtc,
                    deliverTo: purchaseOrders[i].deliverTo ? purchaseOrders[i].deliverTo : "N/A",
                    type: purchaseOrders[i].context.type,
                    status: purchaseOrders[i].context.status,
                    seller: purchaseOrders[i].context.sellerEntity ? purchaseOrders[i].context.sellerEntity.name : "[NOT FOUND]",
                    buyer: purchaseOrders[i].context.buyerEntity ? purchaseOrders[i].context.buyerEntity.name : "[NOT FOUND]",
                    quantity: purchaseOrders[i].quantity,
                    estimatedCost: purchaseOrders[i].estimatedCost,
                    finalCost: purchaseOrders[i].actualCost,
                    purchaseOrder: purchaseOrders[i]
                };

                rows.push(row);
            }
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.requestedOn < b.requestedOn) { return 1; }
                if (a.requestedOn > b.requestedOn) { return -1; }

                return 0;
            });
        }

        table.rows = rows;

        return table;
    },
    getPurchaseOrderTableSchema: function (calculator, currency, currencySymbol, currencyConverter, onOpenPurchaseOrder) {

        function formatCurrencyValue(v) {
            if (!v) return calculator.zero();

            var v = currencyConverter.convertFromUSD(v, currency);

            return calculator.formatDecimalAsCurrency(v, currency.precision);
        }

        return {
            columns: [
                { Header: "date", accessor: "requestedOn", Cell: ({ value }) => <DateCell value={value + "Z"} format="dateAndTime" display="locale" /> },
                { Header: "type", accessor: "type", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "quantity", accessor: "quantity", Cell: ({ value }) => <MoneyCell value={value} prefix="" useFormatter={true} useColorScheme={false} /> },
                { Header: "estimated cost", accessor: "estimatedCost", Cell: ({ value }) => <MoneyCell value={formatCurrencyValue(value)} prefix={currencySymbol} useFormatter={true} useColorScheme={true} /> },
                { Header: "final cost", accessor: "finalCost", Cell: ({ value }) => <MoneyCell value={formatCurrencyValue(value)} prefix={currencySymbol} useFormatter={true} useColorScheme={true} /> },
                { Header: "deliver to", accessor: "deliverTo", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "requestor", accessor: "buyer", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "requestee", accessor: "seller", Cell: ({ value }) => <TextCell value={value} /> },
                {
                    Header: "status", accessor: "status",
                    Cell: ({ value }) => {
                        let icon = "", color = "text";
                        let valueText = value;

                        switch (value) {
                            case "pending":
                                icon = "hourglass_empty_outlined";
                                color = "warning";
                                break;
                            case "completed":
                                icon = "check";
                                color = "success";
                                break;
                            case "canceled":
                                icon = "close_outlined";
                                color = "error";
                                break;
                            case "rejected":
                                icon = "thumb_down_off_alt";
                                color = "warning";
                                break;
                        }

                        return <IconCell value={valueText} icon={icon} color={color} />;
                    }
                },
                { Header: "details", accessor: "purchaseOrder", Cell: ({ value, row }) => <ButtonCell id={value} onButtonClicked={onOpenPurchaseOrder} icon="launch" color="secondary" /> }
            ],
            rows: [],
        };
    },
    getEmptySchema: function () {
        return {
            columns: [],
            rows: []
        }
    }
};


const PurchaseOrderCard = ({ cardroom, account, union, club, agency, currency, currencySymbol, currencyConverter, onOrderCreatedOrChanged }) => {
    const switchToMobileOnSize = "md";
    const theme = useTheme();
    const isMobileUi = !useMediaQuery(theme.breakpoints.up(switchToMobileOnSize));

    const [poTable, setPoTable] = useState(null); //tableHelper.getEmptySchema());
    const [purchaseOrders, setPurchaseOrders] = useState(null);
    const [participants, setParticipants] = useState(null);
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading accounts...", handleRetry)


    const [openPurchaseOrderTxDialog, openPurchaseOrderDialogProps] = useDialog();

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    const peopleRequest = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const unionDataLoader = useUnionDataLoader();
    const clubDataLoader = useClubDataLoader();
    const agencyDataLoader = useAgencyDataLoader();
    const calculator = usePlayerCalculator();

    function onOpenPO(po) {
        if (po) {
            setSelectedPurchaseOrder(po);
            openPurchaseOrderTxDialog();
        }
    }


    useEffect(() => {
        if (!purchaseOrders && !participants) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading requests...")
            showProgress();


            //[HttpGet("{entityId:Guid}/accounts/{accountId:Guid}/po/status/{status:int}", Name = "GetPurchaseOrders")]
            // Pending = 1,Accepted = 2,Rejected = 4,Canceled = 8
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/accounts/" + account.id + "/po/status/15").then((response) => {
                if (response) {
                    setPurchaseOrders(response);
                    setPoTable(null);
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load requests, please try again...")
            });
        }
    }, [accountRequest.execute, purchaseOrders, retryCounter]);

    useEffect(() => {
        if (!participants && purchaseOrders && !poTable) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading request details...")
            showProgress();

            const idmap = [];
            const ids = [];

            function copyAccountHolder(accnt) {
                if (!accnt) return;
                const holderId = accnt.accountHolderId;
                if (holderId != accnt.casinoId && !idmap[holderId]) {
                    ids.push(holderId);
                    idmap[holderId] = holderId;
                }
            }

            for (var i = 0; i < purchaseOrders.length; i++) {
                if (purchaseOrders[i]) {
                    copyAccountHolder(purchaseOrders[i].buyerAccount);
                    copyAccountHolder(purchaseOrders[i].sellerAccount);
                }
            }

            //TODO: Depending on casino type, we may need to get PEOPLE, CLUBS, UNIONS, or AGENCIES

            function onSuccess(response) {
                if (response) {
                    setParticipants(response);
                    setPoTable(null);
                }
                progressIndicatorProps.close();
            }

            function onError(ex) {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load request details, please try again...")
            }

            if (cardroom.type === 3) {
                //union
                unionDataLoader.entity.loadEntities(clubRequest, cardroom, union, unionDataLoader.entities.unions | unionDataLoader.entities.clubs, true, onSuccess, onError);
            }
            else if (cardroom.type === 2) {
                //club
                clubDataLoader.entity.loadEntities(clubRequest, cardroom, club, clubDataLoader.entities.players | clubDataLoader.entities.agents | clubDataLoader.entities.unions, true, onSuccess, onError);
            }
            else if (cardroom.type === 5) {
                //agency
                agencyDataLoader.entity.loadEntities(clubRequest, cardroom, agency, agencyDataLoader.entities.players | clubDataLoader.agencyDataLoader.agents | clubDataLoader.agencyDataLoader.clubs, true, onSuccess, onError);
            }
            else {
                peopleRequest.execute("PUT", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants", ids).then(onSuccess).catch(onError);
            }

        }
    }, [peopleRequest.execute, clubRequest.execute, participants, purchaseOrders, poTable, retryCounter])

    useEffect(() => {
        if (participants && purchaseOrders && !poTable) {
            setPoTable(tableHelper.convertPurchaseOrdersToTable(purchaseOrders, participants, cardroom, tableHelper.getPurchaseOrderTableSchema(calculator, currency, currencySymbol, currencyConverter, onOpenPO), currency, currencySymbol, currencyConverter));
        }
    }, [participants, purchaseOrders, poTable]);

    useEffect(() => {
        setPoTable(null);
    }, [currency]);

    function renderMobileRow(columns, row, key, collapsibleDetailAccessor) {
        return null;
        // return <AccountingAccountMobileCard cardroom={cardroom} row={row} account={row.original.account}
        //     calculator={calculator} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} />
    }

    return <Card>
        <Grid container>
            <Grid item m={1} xs={12} mr="auto" ml="auto">
                <ProgressIndicator {...progressIndicatorProps} />

                {selectedPurchaseOrder ? <PurchaseOrderDialog  {...openPurchaseOrderDialogProps} cardroom={cardroom} account={account} union={union} club={club} agency={agency}
                    onChanged={onOrderCreatedOrChanged} po={selectedPurchaseOrder} action="manage" /> : null}

                {!progressIndicatorProps.visible && poTable ?
                    <DataTable table={poTable} canSearch entriesPerPage={{ defaultValue: 10, visible: !isMobileUi }} pagination={{ color: "dark" }}
                        onRenderRow={renderMobileRow} useCustomRenderSize={switchToMobileOnSize} isHierarchical={false} /> : null}
            </Grid>
        </Grid>
    </Card>;

};

export default PurchaseOrderCard;
