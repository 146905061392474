import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { loginRequest } from "config/authConfig";
import { apiConfig } from 'config/apiConfig'

import useFetchWithMsal from 'hooks/useFetchWithMsal';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import DataTable from "components/Elements/DataTable";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import NewSessionToolbar from 'layouts/Cardroom/Details/components/Session/NewSession';
import ExistingSessionToolbar from 'layouts/Cardroom/Details/components/Session/ExistingSession';

import { ProgressIndicator, useProgressIndicator } from 'components/Elements/ProgressIndicator';

import MoneyCell from "components/Elements/DataTable/components/Cells/MoneyCell";
import DateCell from "components/Elements/DataTable/components/Cells/DateCell";
import ButtonCell from "components/Elements/DataTable/components/Cells/ButtonCell";
import TextCell from "components/Elements/DataTable/components/Cells/TextCell";
import IconCell from "components/Elements/DataTable/components/Cells/IconCell";
import { useCardroomContext } from 'features';

function convertGameHistoryToTable(history, table) {
    var rows = [];

    function calcDuration(start, end) {
        let startMs = Date.parse(start);
        let endMs = Date.parse(end);

        let ms = endMs - startMs;

        let seconds = Math.floor(ms / 1000);
        ms -= seconds * 1000;
        let minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        let hours = Math.floor(minutes / 60);
        minutes -= hours * 60;

        let days = Math.floor(hours / 24);
        hours -= days * 24

        let str = "";
        if (days > 0) str = days + ".";

        str += hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');

        return str;
        //return days + "." + hours + ":" + minutes + ":" + seconds + "." + ms;  
    }


    if (history && history.length > 0) {
        for (var i = 0; i < history.length; i++) {
            let status = "open";
            switch (history[i].status) {
                case 1:
                    status = "scheduled";
                    break;
                case 2:
                    status = "open";
                    break;
                case 3:
                    status = "ended";
                    break;
                case 4:
                    status = "closed";
                    break;
                case 5:
                    status = "canceled";
                    break;
            }

            let startDate = history[i].startedOn + "Z";
            let duration = null;
            if (history[i].status === 2 || history[i].status === 3 || history[i].status === 4) {
                let endDate = history[i].endDate ? history[i].endDate + "Z" : new Date(Date.now());
                duration = calcDuration(startDate, endDate);
            }

            let sortOrder = history[i].status;
            if (sortOrder === 1) //scheduled
            {
                sortOrder = 3;
            }
            else if (sortOrder === 2) //open
            {
                sortOrder = 1;
            }
            else if (sortOrder === 3) {
                sortOrder = 2;
            }


            let row = {
                id: history[i].id,
                startDate: startDate,
                duration: duration,
                buyinsTotal: history[i].totalBuyIns,
                playerCount: history[i].totalPlayers,
                status: status,
                staffCount: history[i].totalStaff,
                //collected: history[i].
                session: history[i],
                sortOrder: sortOrder
            };

            rows.push(row);
        }
    }

    rows.sort((a, b) => { if (a.sortOrder != b.sortOrder) return a.sortOrder - b.sortOrder; else return new Date(b.startDate) - new Date(a.startDate); });
    table.rows = rows;

    return table;
}

function GetGameHistoryTableSchema(historyDetailButtonClickHandler, maskCurrency) {
    const prefix = maskCurrency === true ? "" : "$";

    return {
        columns: [
            { Header: "started on", accessor: "startDate", Cell: ({ value }) => <DateCell value={value} format="date" /> },
            { Header: "duration", accessor: "duration", Cell: ({ value }) => <TextCell value={value} /> },
            { Header: "$ buy-ins", accessor: "buyinsTotal", Cell: ({ value }) => <MoneyCell value={value} prefix={prefix} useFormatter={true} useColorScheme={false} /> },
            { Header: "# players", accessor: "playerCount", Cell: ({ value }) => <TextCell value={value} /> },
            { Header: "# staff", accessor: "staffCount", Cell: ({ value }) => <TextCell value={value} /> },
            {
                Header: "status", accessor: "status",
                Cell: ({ value }) => {
                    let icon = "", color = "text";
                    let valueText = value;

                    switch (value) {
                        case "canceled":
                            icon = "close";
                            color = "info";
                            break;
                        case "ended":
                            icon = "done";
                            color = "warning";
                            break;
                        case "closed":
                            icon = "done";
                            color = "success";
                            valueText = "finalized"
                            break;
                        case "scheduled":
                            icon = "schedule";
                            color = "info";
                            break;
                        case "open":
                            icon = "launch";
                            color = "error";
                            valueText = "current";
                            break;
                    }
                    //TODO: clickHandler={() => { }} add handler to icon to launch session action dialog to close/start/stop session from main screen
                    return <IconCell value={valueText} icon={icon} color={color} />;
                }
            },
            { Header: "details", accessor: "id", Cell: ({ value, row }) => <ButtonCell id={row && row.original ? row.original : value} onButtonClicked={historyDetailButtonClickHandler} icon="launch" color="secondary" /> },
        ],

        rows: [],
    };
}

function GetEmptySchema() {
    return {
        columns: [],
        rows: []
    }
}

const GameHistoryCard = ({ cardroom }) => {
    const navigate = useNavigate();

    const [gamesTable, setGamesTable] = useState(GetEmptySchema());
    const [sessions, setSessions] = useState(null);

    const [context, actions, features] = useCardroomContext(cardroom);
    function isCurrencyMaskingEnabled() {
        if (!context) return false;
        return context.isEnabled(features.currency.mask, 0);
    }

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }
    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading recent games...", handleRetry)

    function onOpenHistoryRecordClicked(row) {
        if (row && row.session) {
            navigate("/dashboards/cardroom/session", { state: { session: row.session, cardroom: cardroom } });
            return;
        }

        //open dialog or nav to session page? that would load session details for specific session
    }

    const { error, execute } = useFetchWithMsal({
        scopes: apiConfig.casino.scopes.read,
    });

    //load casino sessions
    useEffect(() => {
        if (!sessions) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading recent games...")
            showProgress();

            execute("GET", apiConfig.casino.endpoint + "/" + cardroom.id + "/sessions/recent/250").then((response) => {
                if (response) {
                    setSessions(response);
                    //create dataTable

                    setGamesTable(convertGameHistoryToTable(response, GetGameHistoryTableSchema(onOpenHistoryRecordClicked, isCurrencyMaskingEnabled())));
                    progressIndicatorProps.close();
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load recent games, please try again...")
            });
        }
    }, [execute, sessions, retryCounter])


    return <Card>
        <Grid container>
            <Grid item m={1} xs={12} mr="auto" ml="auto">
                <ProgressIndicator {...progressIndicatorProps} />
                {!progressIndicatorProps.visible ? <DataTable table={gamesTable} entriesPerPage={{ defaultValue: 10, visible: false }} pagination={{ color: "dark" }} /> : null}
            </Grid>
        </Grid>
    </Card>;
};


export default GameHistoryCard;