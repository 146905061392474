import { useTheme } from "@emotion/react";
import { Card, CardContent, Grid, useMediaQuery } from "@mui/material";
import { ProgressIndicator } from "components/Elements/ProgressIndicator";
import DataTable from "components/Elements/DataTable";
import { useEffect, useState } from "react";
import { useProgressIndicator } from "components/Elements/ProgressIndicator";
import useFetchWithMsal from "hooks/useFetchWithMsal";
import { apiConfig } from "config/apiConfig";
import { useUnionDataLoader } from "layouts/Club/Union/components/common";
import { useClubDataLoader } from "layouts/Club/Union/components/common";
import { useAgencyDataLoader } from "layouts/Club/Union/components/common";

import apple from "assets/graphics/logos/paynet/96px/apple.png";
import cashapp from "assets/graphics/logos/paynet/96px/cashapp.png";
import paypal from "assets/graphics/logos/paynet/96px/paypal.png";
import venmo from "assets/graphics/logos/paynet/96px/venmo.png";
import zelle from "assets/graphics/logos/paynet/96px/zelle.png";
import crypto from "assets/graphics/logos/paynet/96px/crypto.png";


import whiteCurved from "assets/images/curved-images/white-curved.jpeg";
import MoneyCell from "components/Elements/DataTable/components/Cells/MoneyCell";
import TextCell from "components/Elements/DataTable/components/Cells/TextCell";
import { usePlayerCalculator } from "layouts/Club/components/Reports/common/calculator";
import MiniActionCard from "components/Elements/MiniActionCard";
import SoftBox from "components/SoftBox";
import AccountTransactionReceiptDialog from "../Transactions";
import IconCell from "components/Elements/DataTable/components/Cells/IconCell";
import ButtonCell from "components/Elements/DataTable/components/Cells/ButtonCell";
import { useUnionCalculator } from "layouts/Club/components/Reports/common/calculator";
import { useDialog } from "components/Elements/Dialog/common";
import DateCell from "components/Elements/DataTable/components/Cells/DateCell";
import { AccountingTransactionsList } from "../Transactions/List";

const tableHelper = {
    convertTransactionsToTable: function (accounts, holders, cardroom, union, club, agency, book, table, currency, currencySymbol, currencyConverter) {

        const holderMap = [];
        if (holders) {
            for (var i = 0; i < holders.length; i++) {
                if (!holderMap[holders[i].id]) {
                    holderMap[holders[i].id] = holders[i];
                }
            }
        }

        if (!holderMap[cardroom.id]) {
            holderMap[cardroom.id] = cardroom;
        }


        function getAccountType(v) {
            if (v === 1) return "Individual";
            if (v === 2) return "Primary";
            if (v === 4) return "Partner";
            if (v === 8) return "Union";
            if (v === 16) return "Club";
            if (v === 32) return "Agent";
            if (v === 128) return "Promotional";

            return null;
        }

        function getAccountSortOrder(v) {
            if (v === 1) return 100;
            if (v === 2) return 1;
            if (v === 4) return 101;
            if (v === 8) return 2;
            if (v === 16) return 3;
            if (v === 32) return 99;
            if (v === 128) return 101;
            if (v === 1024) return 4; //expense
            if (v === 4096) return 5; //distribution
            if (v === 2048) return 6; //contribution


            return null;
        }

        var rows = [];

        if (accounts && accounts.length > 0) {
            for (var i = 0; i < accounts.length; i++) {

                let entity = null;

                if (holderMap[accounts[i].accountHolderId]) {
                    entity = holderMap[accounts[i].accountHolderId];
                }

                let swap = accounts[i].accountHolderId != cardroom.id; //is this enough or should we just look at account type???

                var row = {
                    id: accounts[i].id,
                    name: entity ? entity.name : accounts[i].name,
                    type: getAccountType(accounts[i].accountType),
                    sortOrder: getAccountSortOrder(accounts[i].accountType),
                    ap: swap ? accounts[i].accountsReceivable : accounts[i].accountsPayable,
                    ar: swap ? accounts[i].accountsPayable : accounts[i].accountsReceivable,
                    balance: accounts[i].accountsReceivable - accounts[i].accountsPayable,
                    account: accounts[i],
                    transactions: <AccountTransactionHistory cardroom={cardroom} account={accounts[i]}
                        union={union} club={club} agency={agency} book={book}
                        participants={holders} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} />,
                };

                rows.push(row);
            }
        }

        if (rows.length > 0) {
            rows.sort(function (a, b) {
                if (a.sortOrder < b.sortOrder) { return -1; }
                if (a.sortOrder > b.sortOrder) { return 1; }

                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }

                return 0;
            });
        }

        table.rows = rows;

        return table;
    },
    getTransactionTableSchema: function (calculator, currency, currencySymbol, currencyConverter) {
        function formatDecimalValue(v) {
            if (!v) return calculator.zero();

            var v = currencyConverter.convertFromUSD(v, currency);

            return calculator.formatDecimalAsCurrency(v, currency.precision);
        }

        return {
            columns: [
                { Header: "account", accessor: "name", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "type", accessor: "type", Cell: ({ value }) => <TextCell value={value} /> },
                { Header: "credit", accessor: "ar", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(value)} prefix={currencySymbol} useFormatter={true} useColorScheme={true} /> },
                { Header: "debt", accessor: "ap", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(-1 * value)} prefix={currencySymbol} useFormatter={true} useColorScheme={true} /> },
                { Header: "net balance", accessor: "balance", Cell: ({ value }) => <MoneyCell value={formatDecimalValue(value)} prefix={currencySymbol} useFormatter={true} useColorScheme={true} /> }
            ],
            collapsibleDetailAccessor: "transactions",
            rows: [],
        };
    },
    getEmptySchema: function () {
        return {
            columns: [],
            rows: []
        }
    }
};

const AccountTransactionHistory = ({ cardroom, account, union, club, agency, book, participants, currency, currencySymbol, currencyConverter }) => {

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading account transactions...", handleRetry)

    const [transactions, setTransactions] = useState(null);

    useEffect(() => {
        if (!transactions) {
            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading account transactions...")
            showProgress();

            //[HttpGet("{entityId:Guid}/holders/{holderId:Guid}/accounts/{accountId:Guid}/transactions/{status:long}", Name = "GetAccountTransactions")]
            // Pending = 1, Accepted = 2,  Rejected = 4,  Canceled = 8 -> 15
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/" + account.accountHolderId + "/accounts/" + account.id + "/transactions/15?rows=100").then((response) => {
                if (response) {
                    setTransactions(response);
                    //setTransactionTable(null);
                }
                progressIndicatorProps.close();
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load account transactions, please try again...")
            });

        }
    }, [accountRequest.execute, transactions, retryCounter]);

    function onTransactionChanged(tx) {
        setTransactions(null);
    }

    return <Card>
        {(cardroom && account) && !progressIndicatorProps.visible ? <>
            <AccountingTransactionsList cardroom={cardroom} transactions={transactions} participants={participants} account={account} tableChrome={false}
                union={union} club={club} agency={agency} book={book} embedded={true}
                currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} onTransactionUpdated={onTransactionChanged} />
        </> : <ProgressIndicator {...progressIndicatorProps} />}
    </Card>;


};

const AccountingAccountMobileCard = ({ cardroom, account, row, calculator, currency, currencySymbol, currencyConverter }) => {

    const color = "secondary";

    const theme = useTheme();
    const isXs = !useMediaQuery(theme.breakpoints.up("sm"));

    function formatAmount(amount, symbol) {
        var delimiter = ","; // replace comma if desired
        var a = amount.toString().split('.', 2)
        var d = a[1];
        var i = parseInt(a[0]);
        if (isNaN(i)) { return ''; }
        var minus = '';
        if (i < 0) { minus = '-'; }
        i = Math.abs(i);
        var n = new String(i);
        var a = [];
        while (n.length > 3) {
            var nn = n.substring(n.length - 3);
            a.unshift(nn);
            n = n.substring(0, n.length - 3);
        }
        if (n.length > 0) { a.unshift(n); }
        n = a.join(delimiter);
        if (!d || d.length < 1) { amount = n; }
        else { amount = n + '.' + d; }


        amount = symbol + amount;

        if (minus) {
            amount = "(" + amount + ")";
        }
        return amount;
    }

    function formatDecimalValue(v) {
        if (!v) return calculator.zero();

        var v = currencyConverter.convertFromUSD(v, currency);

        return formatAmount(calculator.formatDecimalAsCurrency(v, currency.precision), currencySymbol);
    }

    return <Card
        sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
            backgroundImage: gradients[color]
                ? `${linearGradient(
                    rgba(gradients[color].main, 0.85),
                    rgba(gradients[color].state, 0.85)
                )}, url(${whiteCurved})`
                : `${linearGradient(
                    rgba(gradients[color].main, 0.85),
                    rgba(gradients[color].state, 0.85)
                )}, url(${whiteCurved})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "visible",
        })}
    >
        <CardContent>
            <SoftBox pt={2} pb={2} textAlign="center">

                <MiniActionCard
                    bgColor="white"
                    title={{ text: "account", fontWeight: "medium" }}
                    count={{ text: row.original.name, color: "dark", useNumericFormatter: false, prefix: null }}
                    icon={{ color: "dark", component: "account_balance" }}
                    direction={"left"}
                />
            </SoftBox>
            <SoftBox pt={2} pb={2} textAlign="center">

                <MiniActionCard
                    bgColor="white"
                    title={{ text: "net balance", fontWeight: "medium" }}
                    count={{ text: formatDecimalValue(row.original.balance), color: row.original.balance == 0 ? "dark" : row.original.balance > 0 ? "success" : "error", useNumericFormatter: false, prefix: null }}
                    icon={{ color: "dark", component: "paid" }}
                    direction={"left"}
                />
            </SoftBox>

            <SoftBox pb={2} textAlign="center">
                <Grid container ml="auto" mr="auto" xs={12} >
                    <Grid item xs={6} pr={.5}>
                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "credit", fontWeight: "medium" }}
                            count={{ text: formatDecimalValue(row.original.ar), color: row.original.ar == 0 ? "dark" : "success", useNumericFormatter: true, prefix: null }}
                            icon={isXs ? null : { color: "dark", component: "credit_score" }}
                            direction={"left"}
                        />
                    </Grid>
                    <Grid item xs={6} pl={.5}>

                        <MiniActionCard
                            bgColor="white"
                            title={{ text: "debt", fontWeight: "medium" }}
                            count={{ text: formatDecimalValue(-1 * row.original.ap), color: row.original.ap == 0 ? "dark" : "error", useNumericFormatter: true, prefix: null }}
                            icon={isXs ? null : { color: "dark", component: "credit_card" }}
                            direction={"right"}
                        />

                    </Grid>
                </Grid>
            </SoftBox>

        </CardContent>
    </Card>;
};

const AccountingAccountsCard = ({ cardroom, account, union, club, agency, book, currency, currencySymbol, currencyConverter, onAccountUpdated }) => {

    const switchToMobileOnSize = "md";
    const theme = useTheme();
    const isMobileUi = !useMediaQuery(theme.breakpoints.up(switchToMobileOnSize));

    const [accountsTable, setAccountsTable] = useState(null); //tableHelper.getEmptySchema());
    const [accounts, setAccounts] = useState(null);
    const [participants, setParticipants] = useState(null);

    const [retryCounter, setRetryCounter] = useState(0);

    function handleRetry() {
        setRetryCounter(retryCounter + 1);
    }

    const [showProgress, progressIndicatorProps] = useProgressIndicator("wait", "Loading accounts...", handleRetry)

    const accountRequest = useFetchWithMsal({
        scopes: apiConfig.account.scopes.read,
    });

    const peopleRequest = useFetchWithMsal({
        scopes: apiConfig.people.scopes.read,
    });

    const clubRequest = useFetchWithMsal({
        scopes: apiConfig.club.scopes.read,
    });

    const unionDataLoader = useUnionDataLoader();
    const clubDataLoader = useClubDataLoader();
    const agencyDataLoader = useAgencyDataLoader();
    const calculator = usePlayerCalculator();

    useEffect(() => {
        if (!accounts && !participants) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading accounts...")
            showProgress();


            //   [HttpGet("{entityId:Guid}/holders/accounts/{type:int}", Name = "GetEntityAccounts")]     
            // Individual = 1,  Casino = 2,  Partner = 4,  Union = 8, Club = 16 - 1 | 2 | 8 | 16 = 27 | 32 = 59 | 128 (promo) = 187  | 1024|2048|4096 = 7227
            accountRequest.execute("GET", apiConfig.account.endpoint + "/" + cardroom.id + "/holders/accounts/187").then((response) => {
                if (response) {
                    setAccounts(response);
                    setAccountsTable(null);
                }
            }).catch((ex) => {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load accounts, please try again...")
            });
        }
    }, [accountRequest.execute, accounts, retryCounter]);

    useEffect(() => {
        if (!participants && accounts && !accountsTable) {

            progressIndicatorProps.setMode("wait");
            progressIndicatorProps.setMessage("Loading account information...")
            showProgress();

            const idmap = [];
            const ids = [];

            function copyAccountHolder(accnt) {
                if (!accnt) return;
                const holderId = accnt.accountHolderId;
                if (holderId != accnt.casinoId && !idmap[holderId]) {
                    ids.push(holderId);
                    idmap[holderId] = holderId;
                }
            }

            for (var i = 0; i < accounts.length; i++) {
                if (accounts[i]) {
                    copyAccountHolder(accounts[i]);
                }
            }

            //TODO: Depending on casino type, we may need to get PEOPLE, CLUBS, UNIONS, or AGENCIES

            function onSuccess(response) {
                if (response) {
                    setParticipants(response);
                    setAccountsTable(null);
                }
                progressIndicatorProps.close();
            }

            function onError(ex) {
                progressIndicatorProps.setMode("errorWithRetry");
                progressIndicatorProps.setMessage("Unable to load account information, please try again...")
            }

            if (cardroom.type === 3) {
                //union
                unionDataLoader.entity.loadEntities(clubRequest, cardroom, union, unionDataLoader.entities.unions | unionDataLoader.entities.clubs, true, onSuccess, onError);
            }
            else if (cardroom.type === 2) {
                //club
                clubDataLoader.entity.loadEntities(clubRequest, cardroom, club, clubDataLoader.entities.players | clubDataLoader.entities.agents | clubDataLoader.entities.unions, true, onSuccess, onError);
            }
            else if (cardroom.type === 5) {
                //agency
                agencyDataLoader.entity.loadEntities(clubRequest, cardroom, agency, agencyDataLoader.entities.players | clubDataLoader.agencyDataLoader.agents | clubDataLoader.agencyDataLoader.clubs, true, onSuccess, onError);
            }
            else if (cardroom.type === 6) {
                //book
                agencyDataLoader.entity.loadEntities(clubRequest, cardroom, club, agencyDataLoader.entities.players | clubDataLoader.agencyDataLoader.agents | clubDataLoader.agencyDataLoader.clubs, true, onSuccess, onError);
            }
            else {
                peopleRequest.execute("PUT", apiConfig.people.endpoint + "/venue/" + cardroom.id + "/participants", ids).then(onSuccess).catch(onError);
            }

        }
    }, [peopleRequest.execute, clubRequest.execute, participants, accounts, accountsTable, retryCounter])

    useEffect(() => {
        if (participants && accounts && !accountsTable) {
            setAccountsTable(tableHelper.convertTransactionsToTable(accounts, participants, cardroom, union, club, agency, book, tableHelper.getTransactionTableSchema(calculator, currency, currencySymbol, currencyConverter), currency, currencySymbol, currencyConverter));
        }
    }, [participants, accounts, accountsTable]);

    useEffect(() => {
        setAccountsTable(null);
    }, [currency]);

    function renderMobileRow(columns, row, key, collapsibleDetailAccessor) {
        return <AccountingAccountMobileCard cardroom={cardroom} row={row} account={row.original.account}
            calculator={calculator} currency={currency} currencySymbol={currencySymbol} currencyConverter={currencyConverter} />
    }

    return <Card>
        <Grid container>
            <Grid item m={1} xs={12} mr="auto" ml="auto">
                <ProgressIndicator {...progressIndicatorProps} />

                {!progressIndicatorProps.visible && accountsTable ?
                    <DataTable table={accountsTable} canSearch entriesPerPage={{ defaultValue: 10, visible: !isMobileUi }} pagination={{ color: "dark" }}
                        onRenderRow={renderMobileRow} useCustomRenderSize={switchToMobileOnSize} isHierarchical={true} /> : null}
            </Grid>
        </Grid>
    </Card>;
}

export default AccountingAccountsCard;